import { Box, Divider, Grid } from "@mui/material";
import { LazyLoadImageCust, MobileStoreButton } from "../../widgets";
import { TrustPilot, TrustPilotType } from "../../common";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";
import styles from "./S1_Footer.module.scss";
type Props = {
  isPostActive: boolean;
  handleAssuranceCard: () => {};
  footerContent: any;
  onClickHandler: any;
};
const {
  footer_t1,
  header_text,
  sub_links,
  line,
  copyright_style,
  sublink_hightlight,
  mobile_button_store_main,
  mobile_button_ios,
  trustpilot_footer,
  version_indicator,
  logo_mobile_apps,
} = styles;
const DividerComp = () => {
  return (
    <Divider className={line} sx={{ borderTop: { xs: "1px", sm: "2px" } }} />
  );
};
const FooterComponent = (props: Props) => {
  const { isPostActive, handleAssuranceCard, footerContent, onClickHandler } =
    props;

  const {
    section1,
    section2,
    section3,
    section4,
    logo_desktop,
    logo_tab,
    logo_mobile,
    assuredCard,
    footerList1,
    footerList2,
    footerList3,
    footerList4,
    copyright,
    socialMediaIons,
    iosMobileStore,
    androidMobileStore,
    showAssuredCard,
    showTrustPilot,
    showVersion,
    versionNumber,
    newText,
  } = footerContent || {};
  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();

  const footerListFuntn = (val: any) => {
    return (
      <Box
        mt={1}
        key={val.keyProp}
        className={sub_links}
        onClick={() =>
          onClickHandler(val.navigationPath, val.redirectionPage, val.gaName)
        }
      >
        {val.name}
        {val.isNew && (
          <Box
            component="span"
            className={sublink_hightlight}
            borderRadius={1}
            px={0.5}
            mx={0.5}
          >
            {newText}
          </Box>
        )}
      </Box>
    );
  };

  const assuredCardSection = () => {
    return (
      <Box
        sx={{
          my: { xs: 2, sm: "0px" },
          cursor: "pointer",
        }}
        onClick={() => handleAssuranceCard()}
      >
        <Box>
          <LazyLoadImageCust
            src={assuredCard?.data?.attributes?.url || ""}
            alt={assuredCard?.data?.attributes?.alternativeText || ""}
            width={"145px"}
            height={"36px"}
          />
        </Box>
      </Box>
    );
  };
  const displayVersion = () => {
    return (
      showVersion && <Box className={version_indicator}>{versionNumber}</Box>
    );
  };

  return (
    <Grid
      container
      display={{ xs: "flex" }}
      flexDirection={{ xs: "column" }}
      position="static"
      className={footer_t1}
    >
      <Grid
        item
        pt={{ xs: 1, md: 0 }}
        pb={{ xs: "30px", sm: 2 }}
        display={{ sm: "none", xs: "block", md: "block" }}
      >
        {DividerComp()}
      </Grid>
      <Grid
        container
        display={{ sm: "flex" }}
        flexDirection={{ xs: "row" }}
        rowSpacing={1}
        justifyContent="space-between"
        marginTop={{ xs: 0, md: 1 }}
        p={{
          xs: "0px 10px 30px 10px",
          sm: "0px 35px 30px 38px",
          md: "0px 20px 30px 20px",
        }}
      >
        {/* Images- logo, google play, ios app store */}
        <Grid
          container
          item
          pr={2}
          lg={3}
          md={3}
          xs={12}
          display="flex"
          flexDirection={{ sm: "row" }}
          sx={{
            flexFlow: { xs: "row", md: "column" },
            justifyContent: { md: "space-between", xs: "flex-start" },
          }}
          className={logo_mobile_apps}
        >
          {/* Logo */}
          <Grid item>
            <Grid item>
              {(largeDesktop || desktop) &&
                generateImageContainer({
                  data: logo_desktop, attrs: {
                  height: "35px",
                  width: "81px",
                }
              })}
              {ipad &&
                generateImageContainer({
                  data: logo_tab, attrs: {
                  height: "33px",
                  width: "76px",
                }
              })}
              {mobile &&
                generateImageContainer(
                  {
                    data: logo_mobile, attrs: {
                    height: "33px",
                    width: "76px",
                  }
                })}
            </Grid>
          </Grid>
          {/* google play, ios app store */}
          <Grid
            item
            display="flex"
            paddingLeft={{ sm: "1.5rem", md: "0px", xs: "0.7rem" }}
            className={mobile_button_store_main}
          >
            {androidMobileStore && (
              <Grid item>
                <MobileStoreButton
                  width={100}
                  height={35}
                  store={androidMobileStore.storeName}
                  url={androidMobileStore.navigationPath}
                  linkprops={{ title: androidMobileStore.title }}
                  imagelink={
                    androidMobileStore?.img?.data?.attributes?.url || ""
                  }
                />
              </Grid>
            )}
            {iosMobileStore && (
              <Grid item paddingLeft={{ xs: 2 }} className={mobile_button_ios}>
                <MobileStoreButton
                  width={100}
                  height={35}
                  store={iosMobileStore.storeName}
                  url={iosMobileStore.navigationPath}
                  linkprops={{ title: iosMobileStore.title }}
                  imagelink={iosMobileStore?.img?.data?.attributes?.url || ""}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* Footer List Section */}
        <Grid container item md={9} sm={12} marginTop={{ sm: 2, md: "0px" }}>
          <Grid container item sm={6} xs={12}>
            <Grid item xs={6}>
              <Box className={header_text}>{section1}</Box>
              {footerList1?.map((val: any) => {
                if (val.keyProp === 1) {
                  return !isPostActive ? footerListFuntn(val) : null;
                } else return footerListFuntn(val);
              })}
            </Grid>
            <Grid item xs={6}>
              <Box className={header_text}>{section2}</Box>
              {footerList2?.map((val: any) => {
                return footerListFuntn(val);
              })}
            </Grid>
          </Grid>
          <Grid container item sm={6} xs={12} mt={{ xs: "20px", sm: "0px" }}>
            <Grid item xs={6}>
              <Box className={header_text}>{section3}</Box>
              {footerList3?.map((val: any) => {
                return footerListFuntn(val);
              })}
            </Grid>
            <Grid item xs={6}>
              <Box className={header_text}>{section4}</Box>
              {footerList4?.map((val: any) => {
                return footerListFuntn(val);
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item py={{ xs: 2, sm: 2, lg: 3 }}>
        {DividerComp()}
      </Grid>
      <Grid
        container
        item
        display="flex"
        flexDirection={{ md: "row", xs: "column" }}
        justifyContent={{ md: "space-between" }}
        sm={12}
        md={12}
        p={{
          xs: "0px 10px 30px 10px",
          sm: "0px 35px 30px 38px",
          md: "0px 20px 30px 20px",
        }}
      >
        <Grid item display="flex" justifyContent="space-between">
          <Grid item display="flex">
            {socialMediaIons?.map((val: any) => {
              return (
                <Box
                  mr={1}
                  key={val.keyProp}
                  onClick={() =>
                    onClickHandler(
                      val.navigationPath,
                      val.redirectionPage,
                      val.name
                    )
                  }
                  sx={{ cursor: "pointer" }}
                >
                  <Box>
                    <LazyLoadImageCust
                      src={val?.icon?.data?.attributes?.url || ""}
                      alt={val?.icon?.data?.attributes?.alternativeText || ""}
                      width={val?.name === "YouTube" ? "30px" : "20px"}
                      height={"20px"}
                    />
                  </Box>
                </Box>
              );
            })}
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "none" }}
            paddingTop={{ xs: "0.2rem" }}
          >
            {displayVersion()}
          </Grid>
          <Grid
            item
            sx={{
              display: { md: "none", xs: "block" },
            }}
            paddingTop={{ xs: "0.2rem" }}
          >
            <Box className={copyright_style}>{copyright}</Box>
          </Grid>
        </Grid>
        <Grid
          item
          display={{ xs: "none", md: "block" }}
          paddingTop={{ md: "0.5rem" }}
        >
          {displayVersion()}
        </Grid>
        <Grid
          item
          display="flex"
          flexDirection={{
            md: "row",
            xs: "column-reverse",
            sm: "row-reverse",
          }}
          justifyContent={{
            sm: "space-between",
            md: "flex-end",
            xs: "flex-start",
          }}
          sx={{
            padding: { sm: "1.5rem 0rem 0rem", md: "0px" },
          }}
        >
          {showTrustPilot && (
            <Box
              className={trustpilot_footer}
              sx={{ display: { sm: "block", xs: "none" } }}
              paddingTop={{ md: "0.5rem", sm: "0.6rem" }}
            >
              <TrustPilot type={TrustPilotType.FOOTER} />
            </Box>
          )}
          {showAssuredCard && assuredCardSection()}
          <Box
            className={copyright_style}
            sx={{ display: { md: "block", xs: "none" } }}
            paddingTop={{ sm: "0.5rem" }}
          >
            {copyright}
          </Box>
        </Grid>
        <Grid
          item
          className={trustpilot_footer}
          sx={{ display: { sm: "none", xs: "flex" } }}
          alignItems="flex-start"
        >
          {showTrustPilot && <TrustPilot type={TrustPilotType.FOOTER} />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FooterComponent;
