import { PlanFeaturesProps } from "./PlanFeatures";
import styles from "./S2_PlanFeature.module.scss";
import { Box, Grid, Typography } from "@mui/material";
const PlanFeaturesComponent = (props: PlanFeaturesProps) => {
  return (
    <Grid
      container
      display="flex"
      justifyContent="space-around"
      className={styles.plan_features}
    >
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: "64px",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Box
          component={"h3"}
          className={styles.featureHeader}
          sx={{
            p: "20px 0",
            textAlign: "center",
            m: { xs: "-45px 0 0 " },
            width: { sm: "485px", xs: "100%" },
          }}
        >
          {props?.featureContent?.header}
        </Box>
      </Grid>
      <Grid
        container
        display="flex"
        justifyContent="center"
        xs={12}
        sx={{ marginBottom: { md: "74px" } }}
      >
        {props?.featureContent &&
          props.featureContent?.plan_feature.map((content: any) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              textAlign="center"
              key={content.title}
              sx={{ marginBottom: { md: "0px", xs: "31px" } }}
            >
              <Box
                sx={{ marginBottom: { xs: "31px" } }}
                component="img"
                alt="feature"
                src={content?.image?.data?.attributes?.url || ""}
                className={styles.plan_features_image}
                height={"78"}
              ></Box>
              <Typography
                variant="body1"
                className={styles.plan_features_title}
                sx={{ marginBottom: "9px" }}
              >
                {content.title}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};
export default PlanFeaturesComponent;
