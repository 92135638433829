import styles from "./StepperCust.module.scss";
import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  StepIconProps,
  styled,
  stepConnectorClasses,
} from "@mui/material";
import { OrderStatus } from "../../../features/trackorder/trackOrder";

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "var(--success)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "var(--success)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "var(--lavendar_color)",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color:
      theme.palette.mode === "dark"
        ? theme.palette.grey[700]
        : "var(--lavendar_color)",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "var(--success)",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "var(--success)",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 12,
      height: 12,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
    "& .QontoStepIcon-circleActive": {
      width: 12,
      height: 12,
      borderRadius: "50%",
      backgroundColor: "var(--success)",
    },
  })
);

export type stepperProps = {
  activeStep: number;
  steps: any;
  stepOneExtraContentTR?: boolean;
  sx?: any;
  orderStatus?: string;
  content?: any;
};
export const StepperCust = (props: stepperProps) => {
  const { steps, stepOneExtraContentTR, sx, orderStatus, content } = props;
  const activeStep = props.activeStep ? props.activeStep : 0;
  const QontoStepIcon = (props: StepIconProps) => {
    const { active, completed, className, icon } = props;
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          orderStatus === OrderStatus.Cancelled && activeStep + 1 === icon ? (
            <img
              src={content?.cancel_img?.data?.attributes?.url}
              alt={content?.cancel_img?.data?.attributes?.alternativeText}
              className={styles.cancle_icon}
            />
          ) : (
            <div className="QontoStepIcon-circleActive" />
          )
        ) : orderStatus === OrderStatus.Cancelled && activeStep + 1 === icon ? (
          <img
            src={content?.cancel_img?.data?.attributes?.url}
            alt={content?.cancel_img?.data?.attributes?.alternativeText}
            className={styles.cancle_icon}
          />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  };
  return (
    <>
      <Box className={styles.main_container}>
        <Box className={styles.stepperComp} sx={{ ...sx }}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
          >
            {steps.map((item: any, index: number) => {
              return (
                <Step key={item.label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    <Box className={styles.label}>{item.label}</Box>
                  </StepLabel>
                  <Box
                    className={
                      index <= activeStep
                        ? styles.textBelow + " " + styles.step_centent
                        : styles.textBelow + " " + styles.step_centent
                    }
                  >
                    {item.text &&
                      item.text.split("\n").map((i: any) => {
                        return <Box>{i}</Box>;
                      })}
                    <Box>
                      {stepOneExtraContentTR && item.keyProp === 0
                        ? item.simcards &&
                          item.simCardLabel &&
                          ` ${item.simCardLabel}:  ${item.simcards}`
                        : null}
                    </Box>
                  </Box>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        <Box className={styles.stepperCompVertical}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            sx={{ ...sx }}
            connector={<QontoConnector />}
          >
            {steps.map((item: any, index: number) => (
              <Step key={item.label}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <Box className={styles.label}>{item.label}</Box>
                </StepLabel>
                <Box
                  className={
                    index < activeStep && index < steps.length - 1
                      ? styles.textBelow +
                        " " +
                        styles.borderLeftCompleted +
                        " " +
                        styles.step_centent
                      : index === activeStep && index < steps.length - 1
                      ? styles.textBelow +
                        " " +
                        styles.borderLeft +
                        " " +
                        styles.step_centent
                      : index === steps.length - 1
                      ? styles.textBelow +
                        " " +
                        styles.borderBottom +
                        " " +
                        styles.step_centent
                      : styles.textBelow +
                        " " +
                        styles.borderLeft +
                        " " +
                        styles.step_centent
                  }
                >
                  <Box  className={orderStatus === OrderStatus.Cancelled ? styles.mb_text_below : ""}>
                    {" "}
                    {stepOneExtraContentTR && item.keyProp !== 0
                      ? item.text &&
                        item.text.split("\n").map((i: any) => {
                          return <Box>{i}</Box>;
                        })
                      : null}
                  </Box>
                  <Box className={orderStatus === OrderStatus.Cancelled ? styles.mb_text_below : ""}>
                    {" "}
                    {stepOneExtraContentTR && item.keyProp === 0
                      ? item.time
                      : null}
                  </Box>
                  <Box className={orderStatus === OrderStatus.Cancelled ? styles.mb_text_below : ""}>
                    {" "}
                    {stepOneExtraContentTR && item.keyProp === 0
                      ? item.date
                      : null}
                  </Box>
                  <Box className={orderStatus === OrderStatus.Cancelled ? styles.mb_text_below : ""}>
                    {stepOneExtraContentTR && item.keyProp === 0
                      ? item.simcards &&
                        item.simCardLabel &&
                        ` ${item.simCardLabel}:  ${item.simcards}`
                      : null}
                  </Box>
                </Box>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
    </>
  );
};
