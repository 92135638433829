import { useEffect, useState } from "react";
import { ServiceCheckProps } from "./ServiceCheck";
import styles from "./S2_ServiceCheck.module.scss";
import { Container, Typography, CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setOpenCoverageModal } from "../../../features/planpage/planpageSlice";
import { AutoComplete_T3, ButtonCust_T2, SimpleModal } from "../../widgets";
import { setCustomerAddress } from "../../../features/homepage/homepageSlice";

const CheckCoverageComponent = (props: ServiceCheckProps) => {
  const dispatch = useAppDispatch();
  const {
    checkAddressHandler,
    handleSelect,
    checkCoverageHandler,
    coverageContent,
    checkAddress,
    coverageLoading,
    coverageAddress,
    coverageError,
    coverageResponse,
    searchAgainHandler,
    setCoverageResponse,
  } = props;

  const {
    heading,
    service_desc,
    place_holder,
    check_btn,
    confirm_btn,
    cancel_btn,
    success_text,
    error,
  } = coverageContent || {};
  const { openCoverageModal } = useAppSelector((state: any) => state.planpage);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const closeModal = () => {
    dispatch(setOpenCoverageModal(false));
  };
  useEffect(() => {
    setCoverageResponse(null);
    setDisableConfirm(false);
  }, []);

  useEffect(() => {
    if (openCoverageModal) {
      searchAgainHandler();
    }
  }, [openCoverageModal]);
  useEffect(() => {
    if (coverageError) {
      setDisableConfirm(true);
    }
    if (!coverageResponse?.data?.data?.isValid) {
      setDisableConfirm(true);
    } else {
      setDisableConfirm(false);
    }
  }, [coverageResponse, coverageError]);
  useEffect(() => {
    if (checkAddress === "") {
      setDisableConfirm(true);
    }
  }, [checkAddress]);

  return (
    coverageContent &&
    openCoverageModal && (
      <SimpleModal
        isShowModal={openCoverageModal}
        onCloseModal={closeModal}
        showClose={true}
        className={styles.coverage_modal}
        sx={{
          width: { xs: "90%", sm: "40%" },
          minWidth: { xs: "90%", sm: "40%" },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            mb: 0.75,
            fontSize: { xs: "20px", sm: "26px" },
            color: "var(--text_color_8)",
          }}
          className={styles.coverage_check_heading}
        >
          {heading}
        </Typography>
        <Typography
          variant="subtitle2"
          className={styles.coverage_check_subheading}
          sx={{ fontFamily: "var(--font_family_Semibold)" }}
        >
          {service_desc}
        </Typography>
        <div
          className={styles.address_holder}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              checkCoverageHandler(coverageAddress);
            }
          }}
        >
          <AutoComplete_T3
            value={checkAddress}
            onChange={checkAddressHandler}
            onSelect={(e: any) => {
              handleSelect(e);
            }}
            placeholder={place_holder}
            className={styles.textField}
          />
          <ButtonCust_T2
            variantType="primary_underlined"
            className={styles.submit_btn}
            startIcon={coverageLoading ? <CircularProgress /> : check_btn}
            onClick={() => checkCoverageHandler(coverageAddress)}
            disabled={checkAddress === "" ? true : false}
          ></ButtonCust_T2>
        </div>

        {coverageError && (
          <Container style={{ padding: 0 }} className={styles.coverage_error}>
            {error}
          </Container>
        )}
        {coverageResponse?.data?.data?.isValid &&
          !coverageError &&
          checkAddress !== "" && (
            <Container style={{ padding: 0 }} className={styles.coverage_error}>
              {success_text}
            </Container>
          )}
        <ButtonCust_T2
          sx={{ my: 1, width: "180px", margin: "20px" }}
          variantType={"primary_contained_square"}
          onClick={() => {
            dispatch(setCustomerAddress(checkAddress));

            localStorage.setItem("CustomerAddress", checkAddress);
            localStorage.setItem(
              "CustomerAddressObj",
              JSON.stringify(coverageAddress)
            );
            closeModal();
          }}
          disabled={disableConfirm}
        >
          {confirm_btn}
        </ButtonCust_T2>
        <ButtonCust_T2
          variantType="primary_underlined"
          className={styles.cancel_btn}
          onClick={closeModal}
        >
          {cancel_btn}
        </ButtonCust_T2>
      </SimpleModal>
    )
  );
};

export default CheckCoverageComponent;
