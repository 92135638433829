import {
  addMultipleCacheData,
  getCache,
  isTemplateExisits,
  useNonInitialEffect,
} from "../../../utils/commonFunctions";
import Config from "../../../config/app.config.json";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { cancelInstallation } from "../../../features/fsm/fsmSlice";
import { getCancelInstallContent } from "../../../features/strapi/paymentLandingSlice";
import { getActivityTypes } from "../../../features/checkout/checkoutSlice";

const CancelInstallationTemplate = isTemplateExisits(
  Config.PaymentLanding.CancelInstallation
)
  ? require(`./${Config.PaymentLanding.CancelInstallation.template}_CancelInstallation`)
      .default
  : null;

type Props = {
  openCancelModal: boolean;
  setOpenCancelModal: any;
  activityInfo?: any;
  isFromFieldServices?: any;
};
export interface CancelInstallationProps extends Props {
  cancelInstallActivity: any;
  content: any;
  activityInfo?: any;
}
const CancelInstallation = (props: Props) => {
  const { activityInfo } = props;
  const dispatch = useAppDispatch();

  const { cancelInstallContent } = useAppSelector(
    (state: any) => state.strapi.paymentLanding
  );
  const [cacheCancelContent, setCacheCancelContent] = useState<any>();

  useEffect(() => {
    getCache(
      "CancelInstallation",
      getCancelInstallContent,
      setCacheCancelContent,
      dispatch
    );
  }, []);

  useEffect(() => {
    dispatch(getActivityTypes());
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("CancelInstallation", cancelInstallContent);
  }, [cancelInstallContent]);

  const cancelInstallActivity = () => {
    dispatch(cancelInstallation(activityInfo));
  };
  return (
    (cacheCancelContent || cancelInstallContent) && (
      <CancelInstallationTemplate
        cancelInstallActivity={cancelInstallActivity}
        content={cacheCancelContent ? cacheCancelContent : cancelInstallContent}
        activityInfo={activityInfo}
        {...props}
      />
    )
  );
};

export default CancelInstallation;
