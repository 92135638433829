import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { array, object, string } from "yup";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Config from "../../../../config/app.config.json";
import data from "../../../../config/data.json";
import {
  addLineMcLoader,
  checkEmailAvailability,
  customerGroup,
} from "../../../../features/account/accountSlice";
import {
  CreateCustomer,
  getOneTimeSimTaxCharges,
  getPlanTaxCharges,
} from "../../../../features/checkout/checkoutSlice";
import { manageContentSlice } from "../../../../features/strapi/myAccountSlice";
import { addMultipleCacheData } from "../../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
import { emailPattern, namePattern } from "../../../../utils/regexPatterns";
import { ShippingComponent } from "../AddLinesDialog/ShippingAddress";
import firebase from "../../../../adapters/firebase";
import {
  getShipmentOrderById,
  shipmentOrder,
} from "../../../../features/trackorder/trackorderSlice";
import { useNavigate } from "react-router-dom";
import EnvConfig from "../../../../config/env.config.json";
import ESimIMEI from "../../../common/EsimIMEI/EsimIMEI";
import { ReviewMonthlyStat } from "../AddLinesDialog/ReviewMonthlyStatementDialog/ReviewMonthlyStat";
import { EstimatedMonthlyBillDialog } from "../AddLinesDialog/EstimatedMonthlyBillDialog/EstimatedMonthlyBillDialog";
import { OrderSuccessDialog } from "../AddLinesDialog/OrderSuccessDialog/OrderSuccessDialog";
import { ManageCard } from "../../../widgets";
import { SimTypes } from "../../../../features/checkout/Types";

const ManageLinesComponent = isTemplateExisits(Config.Account.ManageLines)
  ? require(`./${Config.Account.ManageLines.template}_ManageLines`).default
  : null;
export type ManageLinesComponentProps = {
  content?: any;
};
type mangeLinesProps = {
  setCurrentSection: Function;
};
export type AddLineProps = {
  formik: any;
  index: any;
  removeFormFields: any;
  handleFormikChange: (e: Event) => any;
  addUserError: string;
};
export interface ManageLinesProps extends AddLineProps {
  setCurrentSection: any;
  counter: number;
  pendingLines: any;
  user: any;
  handleAddLine: any;
  additionalCost: any;
  content: any;
  isSecondaryUserJourney: boolean;
  isEmailAVailable: boolean;
  handleLinesTab: any;
  stopAddLinesFlow?: boolean;
  setStopAddLinesFlow?: any;
}

export const ManageLines = ({ setCurrentSection }: mangeLinesProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { manageContent } = useAppSelector(
    (state: any) => state.strapi.myAccount
  );
  const { emailAvailability, errorEmailAvailability, getCCDetails } =
    useAppSelector((state: any) => state.account);
  const { shipmentOrderSuccess, errorShipmentOrder } = useAppSelector(
    (state: any) => state.trackorder
  );
  const {
    getCustomer,
    planTaxCharges,
    oneTimeSIMTaxCharges,
    createCustomer,
    errorCreateCustomer,
  } = useAppSelector((state: any) => state.checkout);

  const [counter, setCounter] = useState(0);
  const [pendingLines, setPendingLines] = useState(0);
  const [user, setUser] = useState<any>(null);
  const [cacheContent, setCacheContent] = useState<any>(null);
  const [sendSimOptions, setSendSimOptions] = useState<any>([]);
  const [shipPlan, setShipPlan] = useState<any>(null);
  const [isSecondaryUserJourney, setIsSecondaryUserJourney] = useState(false);
  const [addUserError, setAddUserError] = useState("");
  const [isEmailAVailable, setIsEmailAVailable] = useState(true);
  const [lineStep, setLineStep] = useState(1);
  const [shippingAddress, setShippingAddress] = useState<any>(null);
  const [secondaryLineAddress, setSecondaryLineAddress] = useState<any>(null);
  const [loader, setLoader] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<any>(null);
  const [showReviewModal1, setShowReviewModal1] = useState(false);
  const [firstTax2Call, setFirstTax2Call] = useState(true);
  const [pricingTax, setPricingTax] = useState<any>({
    currentPricingTax: null,
    newPricingTax: null,
  });
  const [reviewDetails, setReviewDetails] = useState<any>({
    currentPricing: null,
    newPricing: null,
    previousAdditionalLines: null,
    currentAdditioinalLines: null,
    selectedPlanName: "",
  });
  const [reviewModalLoader, setReviewModalLoader] = useState(false);
  const [showEstBillModal, setShowEstBillModal] = useState(false);
  const [kitBasePrice, setKitBasePrice] = useState(0);
  const [secondarySimPrice, setSecondarySimPrice] = useState(0);
  const [estBillLoader, setEstBillLoader] = useState(false);
  const [createCustomerCounter, setCreateCustomerCounter] = useState(0);
  const [createdPsimCustomersId, setCreatedPsimCustomersId] = useState<any>([]);
  const [createdEsimCustomersId, setCreatedEsimCustomersId] = useState<any>([]);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [showCheckIMEIModal, setShowCheckIMEIModal] = useState(false);
  const [cardsFg, setCardsFg] = useState(false);
  const [eSimKitBasePrice, setESimKitBasePrice] = useState(0);
  const [eSimSecondarySimPrice, setESimSecondarySimPrice] = useState(0);
  const [stopAddLinesFlow, setStopAddLinesFlow] = useState(false);
  const esimCount = useRef(0);
  const psimCount = useRef(0);
  const numOfAddLines = useRef(0);

  useEffect(() => {
    getCache("ManageLines", manageContentSlice, setCacheContent, dispatch);
    fetchShipmentPlans();
    let currentuser: any = localStorage.getItem("currentuser");
    currentuser = JSON.parse(currentuser);

    let currentdetails: any = localStorage.getItem("customerDetail");
    currentdetails = JSON.parse(currentdetails);
    let shippingAddressDetails =
      currentdetails &&
      currentdetails.addresses &&
      currentdetails.addresses.find((el: any) => el.type === "shipping");
    setShippingAddress(shippingAddressDetails);

    let plan: any =
      data &&
      data.data &&
      data.data.length > 0 &&
      data.data.find(
        (el: any) => el.name === (currentdetails && currentdetails.reachPlanId)
      );
    setCurrentPlan(plan);
    setPendingLines(plan?.maxLines - (currentdetails?.additionalLines + 1));
    setUser(currentdetails);
    setPendingLines(plan?.maxLines - (currentdetails?.additionalLines + 1));
    localStorage.removeItem("secondaryCustomerDetail");
    localStorage.removeItem("simId");
    dispatch(getShipmentOrderById({ id: currentdetails && currentdetails.id }));
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("ManageLines", manageContent);
  }, [manageContent]);

  useNonInitialEffect(() => {
    if (sendSimOptions.length > 0) setShipPlan(sendSimOptions[0]);
  }, [sendSimOptions]);

  useNonInitialEffect(() => {
    if (oneTimeSIMTaxCharges) {
      setReviewModalLoader(false);
      setShowReviewModal1(false);
      setShowEstBillModal(true);
    }
  }, [oneTimeSIMTaxCharges]);

  const updateSourceToFirebase = async (email: any) => {
    await firebase
      .database()
      .ref("source/")
      .update({ [window.btoa(email)]: EnvConfig.OPERATOR }, (_error) => {
        return null;
      });
  };

  useNonInitialEffect(() => {
    if (
      createCustomer &&
      createCustomer.status === "SUCCESS" &&
      createCustomer.data
    ) {
      updateSourceToFirebase(createCustomer.data.emailId);
      if (createCustomer.data.esim)
        setCreatedEsimCustomersId((ids: any) => [
          ...ids,
          createCustomer.data.id,
        ]);
      else
        setCreatedPsimCustomersId((ids: any) => [
          ...ids,
          createCustomer.data.id,
        ]);
      setCreateCustomerCounter(createCustomerCounter + 1);
      if (numOfAddLines.current < counter) confirmPurchaseHandler();
    }
  }, [createCustomer]);

  useNonInitialEffect(() => {
    if (createCustomerCounter === counter) {
      dispatch(
        customerGroup({
          groupId: getCustomer?.groupId,
        })
      );
      CallShipmentOrder();
    }
  }, [createCustomerCounter]);

  useNonInitialEffect(() => {
    if (
      shipmentOrderSuccess?.status === "SUCCESS" &&
      shipmentOrderSuccess?.data
    ) {
      setEstBillLoader(false);
      if (shipmentOrderSuccess.data?.shipmentType === "ESIM") {
        localStorage.setItem(
          "secondaryCustomerDetail",
          JSON.stringify(createCustomer?.data)
        );
        navigate("/activate-esim", {
          state: {
            pathname: "/activate-esim",
            emailId: createCustomer?.data?.emailId,
            orderId: shipmentOrderSuccess?.data?.orderId,
          },
        });
      } else {
        showSuccessPopup();
      }
    }
  }, [shipmentOrderSuccess]);

  useNonInitialEffect(() => {
    if (errorShipmentOrder) {
      setEstBillLoader(false);
    }
  }, [errorShipmentOrder]);
  useNonInitialEffect(() => {
    if (emailAvailability?.status === "SUCCESS" && emailAvailability?.data) {
      setAddUserError("");
      if (formik.values.length > 0) {
        const updateFormik = formik.values.map((data: any) => {
          return {
            ...data,
            isEmailAvalable: true,
          };
        });
        formik.setValues(updateFormik);
        setIsEmailAVailable(true);
      }
    } else if (
      emailAvailability?.status === "SUCCESS" &&
      !emailAvailability.data
    ) {
      setAddUserError(manageContent?.existing_email_err);
    }
  }, [emailAvailability]);

  useNonInitialEffect(() => {
    if (planTaxCharges) {
      if (firstTax2Call) {
        setPricingTax((prevState: any) => ({
          ...prevState,
          currentPricingTax: planTaxCharges,
        }));
        setFirstTax2Call(false);
        newPriceTaxCalc();
      } else {
        setPricingTax((prevState: any) => ({
          ...prevState,
          newPricingTax: planTaxCharges,
        }));
        setLoader(false);
        setShowCheckIMEIModal(false);
        setShowReviewModal1(true);
      }
    }
  }, [planTaxCharges]);

  const showSuccessPopup = () => {
    setShowEstBillModal(false);
    setPurchaseSuccess(true);
    setTimeout(() => {
      navigate("/post-activation-home", { state: "newPhysicalLineAdded" });
    }, 2000);
  };

  const CallShipmentOrder = () => {
    let { address1, city, country, name, state, zip } = shippingAddress;
    if (secondaryLineAddress) {
      let billingAddress = secondaryLineAddress.billingAddress;
      ({
        name = `${secondaryLineAddress.firstName} ${secondaryLineAddress.lastName}`,
        address1,
        city,
        country,
        state,
        zip,
      } = billingAddress);
    }

    let primaryAccount: any = localStorage.getItem("customerDetail");
    primaryAccount = JSON.parse(primaryAccount);

    let sendOption: any = sendSimOptions.filter(
      (el: any) => el.cost == shipPlan.cost
    );
    let totalAmount = shipPlan?.shippingAmount + shipPlan?.taxAmount;

    let estimatedDeliveryDate = new Date();
    estimatedDeliveryDate.setDate(
      estimatedDeliveryDate.getDate() + sendOption[0]?.deliveryDays
    );
    let mainCard: any =
      getCCDetails?.length > 0 &&
      getCCDetails.find((el: any) => el.isAutoPay === true);
    const psimTax: any = { totalTax: 0 };
    const psimPayload: any = {
      cId: primaryAccount.id,
      reachCarrierId: sendOption[0]?.reachCarrierId,
      deliveryDays: sendOption[0]?.deliveryDays,
      serviceType: sendOption[0]?.serviceType,
      serviceCode: sendOption[0]?.serviceCode,
      carrierCode: sendOption[0]?.carrierCode,
      shipmentType: "PHYSICAL",
      category: "SIM",
      shippingAmount: primaryAccount?.selectedShippingMethod?.shippingAmount,
      estimatedDeliveryDate: estimatedDeliveryDate,
      taxAmount: primaryAccount?.selectedShippingMethod?.taxAmount,
      totalAmount: totalAmount,
      taxId: oneTimeSIMTaxCharges?.taxId
        ? oneTimeSIMTaxCharges?.taxId
        : psimTax,
      taxInfo: oneTimeSIMTaxCharges ? oneTimeSIMTaxCharges : psimTax,
      ccUUID: mainCard?.uuid,
      secondaryCustomers: createdPsimCustomersId,
      shipTo: {
        city: city,
        country: "US",
        name: name,
        phone: primaryAccount?.addresses[0]?.phone,
        postalCode: zip,
        residential: "false",
        state: state,
        street1: address1,
      },
      items: [
        {
          quantity: psimCount.current,
          unitPrice: 0,
        },
      ],
    };

    const esimTax = { totalTax: 0 };
    const esimPayload: any = {
      cId: primaryAccount.id,
      reachCarrierId: sendSimOptions[0]?.reachCarrierId,
      deliveryDays: 0,
      serviceType: "email",
      serviceCode: "email",
      carrierCode: sendSimOptions[0]?.carrierCode,
      shipmentType: "ESIM",
      category: "SIM",
      shippingAmount: 0,
      estimatedDeliveryDate: estimatedDeliveryDate,
      taxAmount: 0,
      totalAmount: 0,
      taxId: oneTimeSIMTaxCharges?.taxId
        ? oneTimeSIMTaxCharges?.taxId
        : esimTax?.totalTax,
      taxInfo: oneTimeSIMTaxCharges ? oneTimeSIMTaxCharges : esimTax,
      ccUUID: mainCard?.uuid,
      secondaryCustomers: createdEsimCustomersId,
      shipTo: {
        city: city,
        country: "US",
        name: name,
        phone: primaryAccount?.addresses[0]?.phone,
        postalCode: zip,
        residential: "false",
        state: state,
        street1: address1,
      },
      items: [
        {
          quantity: esimCount.current,
          unitPrice: 0,
        },
      ],
    };

    if (esimCount.current > 0) {
      dispatch(shipmentOrder(esimPayload)).then(() => {
        dispatch(addLineMcLoader(false));
      });
    }
    if (psimCount.current > 0) {
      dispatch(shipmentOrder(psimPayload)).then(() => {
        dispatch(addLineMcLoader(false));
      });
    }
  };

  let jsonData = data;
  let currentUser: any = localStorage.getItem("customerDetail");
  currentUser = JSON.parse(currentUser);
  let additionalCost;
  jsonData?.data.map((plan) => {
    if (plan?.name === currentUser?.reachPlanId) {
      additionalCost = plan?.additionalLinePrice;
    }
  });

  const fetchShipmentPlans = async () => {
    const shippingRate: any = await firebase.database().ref("/shippingRates");
    shippingRate.on("value", (snapshot: any) => {
      const data = snapshot.val();
      let shipmentPlansList: any = [];
      data.length > 0 &&
        data.map((el: any) => {
          return shipmentPlansList.push({
            label: `${el && el.name} - $${el && el.shippingAmount}`,
            value: el && el.serviceCode,
            cost: el && el.shippingAmount,
            ...el,
          });
        });
      setSendSimOptions(shipmentPlansList);
    });

    const perCost: any = await firebase.database().ref("/perSimCost");
    let kitBasePrice = 0;
    let secondarySimPrice = 0;
    let eSimKitBasePrice = 0;
    let eSimSecondarySimPrice = 0;

    perCost.on("value", (snapshot: any) => {
      const data = snapshot.val();

      //sim price
      kitBasePrice =
        data &&
        data.USA &&
        ((100 - data.USA.discountPctg) * data.USA.cost) / 100;
      secondarySimPrice = kitBasePrice;
      if (data && data.USA && data.USA.subsequentCost) {
        secondarySimPrice =
          ((100 - data.USA.discountPctg) * data.USA.subsequentCost) / 100;
      }

      //eSim price
      eSimKitBasePrice =
        data &&
        data.USA &&
        ((100 - data.USA.eSimDiscountPctg) * data.USA.eSimCost) / 100;
      eSimSecondarySimPrice = eSimKitBasePrice;
      if (data && data.USA && data.USA.eSimSubsequentCost) {
        eSimSecondarySimPrice =
          ((100 - data.USA.eSimDiscountPctg) * data.USA.eSimSubsequentCost) /
          100;
      }

      setKitBasePrice(kitBasePrice);
      setSecondarySimPrice(secondarySimPrice);
      setESimKitBasePrice(eSimKitBasePrice);
      setESimSecondarySimPrice(eSimSecondarySimPrice);
    });
  };

  const addLineSchema = array().of(
    object({
      firstName: string()
        .trim()
        .min(2, manageContent?.first_name_err)
        .max(24, manageContent?.first_name_err)
        .required(manageContent?.first_name_err2)
        .matches(namePattern, manageContent?.first_name_err),
      lastName: string()
        .trim()
        .min(2, manageContent?.last_name_err)
        .max(24, manageContent?.last_name_err)
        .required(manageContent?.last_name_err2)
        .matches(namePattern, manageContent?.last_name_err),
      email: string()
        .trim()
        .required(manageContent?.email_err1)
        .matches(emailPattern, manageContent?.email_err2),
    })
  );

  const initialUser = {
    firstName: "",
    lastName: "",
    email: "",
    isEmailAvalable: false,
  };

  const userData: any = [];

  const formik = useFormik({
    initialValues: userData,
    validationSchema: addLineSchema,
    onSubmit: async (values: any) => {
      let email = values[values.length - 1].email;
      dispatch(checkEmailAvailability({ email }));
    },
    enableReinitialize: true,
  });

  const handleAddLine = () => {
    setIsEmailAVailable(false);
    setIsSecondaryUserJourney(true);
    setCounter((prevCounter) => prevCounter + 1);
    formik.setValues([...formik.values, initialUser]);
  };

  const removeFormFields = (index: number) => {
    setIsEmailAVailable(true);
    let updatedConnections = formik.values;
    updatedConnections.splice(index, 1);
    formik.setValues([...updatedConnections]);
    let updatedConnectionTouched = formik.touched;
    delete updatedConnectionTouched[index];
    const propertyValues: any = Object.values(updatedConnectionTouched);
    formik.setTouched({ ...propertyValues });
    setCounter((prevCounter) => prevCounter - 1);
    if (updatedConnections.length <= 0) {
      setIsSecondaryUserJourney(false);
    }
  };

  const handleFormikChange = (e: any) => {
    formik.handleChange(e);
    let keyValue = e.target.name.split(".")[0];
    let innerTouchedObj: any = formik.touched[keyValue];
    formik.setTouched({
      ...formik.touched,
      [keyValue]: {
        ...innerTouchedObj,
        [e.target.name.split(".")[1]]: false,
      },
    });
  };

  const handleLinesTab = (step: number, isEsim?: boolean) => {
    if (EnvConfig.IS_ESIM_SUPPORTED && isEsim)
      return setShowCheckIMEIModal(true);
    setLineStep(step);
  };

  const updateAddress = (e: any) => {
    setSecondaryLineAddress(e);
  };
  const taxCalculationType2 = (
    type: string,
    amount: any = null,
    additionalLines: any = null
  ) => {
    let currentUser: any = localStorage.getItem("customerDetail");
    currentUser = JSON.parse(currentUser);
    dispatch(
      getPlanTaxCharges({
        address: {
          name: currentUser?.addresses[0]?.name,
          address1: currentUser?.addresses[0]?.address1,
          city: currentUser?.addresses[0]?.city,
          country: "USA",
          state: currentUser?.addresses[0]?.state,
          type: type,
          zip: currentUser?.addresses[0]?.zip,
        },
        amount: amount ? amount : currentPlan?.baseLinePrice,
        reachPlanId: currentUser.reachPlanId,
        additionalLines: additionalLines
          ? additionalLines
          : currentUser?.additionalLines,
      })
    );
  };
  const taxCalculationType2WithCounter = (type: string, amount: any = null) => {
    let currentUser: any = localStorage.getItem("customerDetail");
    currentUser = JSON.parse(currentUser);

    dispatch(
      getPlanTaxCharges({
        address: {
          name: currentUser?.addresses[0]?.name,
          address1: currentUser?.addresses[0]?.address1,
          city: currentUser?.addresses[0]?.city,
          country: "USA",
          state: currentUser?.addresses[0]?.state,
          type: type,
          zip: currentUser?.addresses[0]?.zip,
        },
        amount: amount ? amount : currentPlan?.baseLinePrice,
        reachPlanId: currentUser.reachPlanId,
        additionalLines: currentUser?.additionalLines + counter,
      })
    );
  };

  const newPriceTaxCalc = () => {
    let currentUser: any = localStorage.getItem("customerDetail");
    currentUser = JSON.parse(currentUser);
    let currentPricing: any = data.data.find(
      (x) => x.name === currentUser.reachPlanId
    );
    let currentPrice =
      currentPricing.baseLinePrice +
      currentUser.additionalLines * currentPricing.additionalLinePrice;
    let newPricing =
      currentPricing.baseLinePrice +
      currentPricing.additionalLinePrice *
        (currentUser.additionalLines + counter);

    setReviewDetails((prevState: any) => ({
      ...prevState,
      currentPricing: currentPrice,
      newPricing: newPricing,
      previousAdditionalLines: currentUser.additionalLines,
      currentAdditioinalLines: currentUser.additionalLines + counter,
      selectedPlanName: currentUser.reachPlanId,
    }));
    taxCalculationType2WithCounter("billing", newPricing);
  };

  const checkHandler = () => {
    let currentUser: any = localStorage.getItem("customerDetail");
    currentUser = JSON.parse(currentUser);
    let currentPricing: any = data.data.find(
      (x) => x.name === currentUser.reachPlanId
    );
    currentPricing =
      currentPricing.baseLinePrice +
      currentUser.additionalLines * currentPricing.additionalLinePrice;

    setLoader(true);
    taxCalculationType2("billing", currentPricing);
  };

  const taxCalculationType1 = (type: string, amount: any = null) => {
    let { address1, city, country, name, state, zip } = shippingAddress;
    if (secondaryLineAddress) {
      let billingAddress = secondaryLineAddress.billingAddress;
      ({
        name = `${secondaryLineAddress.firstName} ${secondaryLineAddress.lastName}`,
        address1,
        city,
        country,
        state,
        zip,
      } = billingAddress);
    }

    let oneTmeCost = kitBasePrice + secondarySimPrice * (counter - 1);

    let totalCost: number = shipPlan.cost + oneTmeCost;

    let currentUser: any = localStorage.getItem("customerDetail");
    currentUser = JSON.parse(currentUser);

    dispatch(
      getOneTimeSimTaxCharges({
        address: {
          name: name,
          address1: address1,
          city: city,
          country: "USA",
          state: state,
          type: type,
          zip: zip,
        },
        amount: amount ? amount : totalCost,
        reachPlanId: currentUser.reachPlanId,
      })
    );
  };

  const gotItHandler = () => {
    setReviewModalLoader(true);
    let simPreferenceStorage: any = localStorage.getItem("simPreference");
    simPreferenceStorage = JSON.parse(simPreferenceStorage);
    const isEsimSelected =
      simPreferenceStorage &&
      simPreferenceStorage?.every((data: any) => data.value === SimTypes.eSim);
    const isPhysicalSelected =
      simPreferenceStorage &&
      simPreferenceStorage?.every((data: any) => data.value === SimTypes.sim);
    const numOfEsim = simPreferenceStorage?.filter(
      (data: any) => data?.eSimCompatible
    ).length;
    const numOfPsim = simPreferenceStorage?.filter(
      (data: any) => !data?.eSimCompatible
    ).length;
    esimCount.current = numOfEsim ? numOfEsim : 0;
    psimCount.current = numOfPsim ? numOfPsim : 0;
    let oneTmeCost: number =
      !isEsimSelected && isPhysicalSelected
        ? kitBasePrice + secondarySimPrice * (numOfPsim - 1)
        : isEsimSelected && !isPhysicalSelected
        ? eSimKitBasePrice + eSimSecondarySimPrice * (numOfEsim - 1)
        : eSimKitBasePrice +
          eSimSecondarySimPrice * (numOfEsim - 1) +
          (kitBasePrice + secondarySimPrice * (numOfPsim - 1));
    let totalCost: number =
      numOfPsim > 0 ? shipPlan?.cost + oneTmeCost : oneTmeCost;

    if (totalCost > 0) {
      taxCalculationType1("shipping", totalCost);
    } else {
      setReviewModalLoader(false);
      setShowReviewModal1(false);
      setShowEstBillModal(true);
    }
  };
  const confirmPurchaseHandler = () => {
    setEstBillLoader(true);
    let shippingMethod = sendSimOptions.find(
      (data: any) => data.label === shipPlan.label
    );
    let address: any = null;
    if (secondaryLineAddress) {
      let billingAddress = secondaryLineAddress.billingAddress;
      address = {
        name: `${secondaryLineAddress.firstName} ${secondaryLineAddress.lastName}`,
        address1: billingAddress.address1,
        city: billingAddress.city,
        country: billingAddress.country,
        state: billingAddress.state,
        zip: billingAddress.zip,
      };
    }

    let eSim: any = JSON.parse(localStorage.getItem("simSelection") || "null");
    let simPreference: any = localStorage.getItem("simPreference");
    simPreference = JSON.parse(simPreference);
    const linePreferenceData: any = {};
    simPreference &&
      simPreference.map((data: any, index: any) => {
        linePreferenceData["line" + (index + 1)] = {
          imei: data?.imei?.toString(),
          simPreference:
            data?.value === SimTypes.eSim ? "ESIM" : "PHYSICAL_SIM",
        };
      });
    const numOfEsimOrdered = simPreference?.filter(
      (data: any) => data?.eSimCompatible
    ).length;
    const numOfPsimOrdered = simPreference?.filter(
      (data: any) => !data?.eSimCompatible
    ).length;

    dispatch(
      CreateCustomer({
        countryCode: "USA",
        zipcode: user?.zipcode ? user.zipcode : "01824",
        firstName: formik.values[numOfAddLines.current].firstName,
        lastName: formik.values[numOfAddLines.current].lastName,
        parentId: user?.id,
        isMNP: false,
        isPrimary: false,
        primaryNumber: user?.primaryNumber,
        addresses: address ? [address] : [shippingAddress],
        status: 0,
        emailId: formik.values[numOfAddLines.current].email.toLowerCase(),
        reachPlanId: user?.reachPlanId,
        isSimInHand: false,
        extraLines: 0,
        selectedShippingMethod: shippingMethod,
        userName: formik.values[numOfAddLines.current].email.toLowerCase(),
        esim: simPreference?.[numOfAddLines.current].eSimCompatible,
        activationJourneyStatus: "NOT_STARTED",
        physicalSimsOrdered: numOfPsimOrdered,
        eSimsOrdered: numOfEsimOrdered,
        linePreferences: linePreferenceData,
        flowVersion: 1,
        imei: simPreference?.[numOfAddLines.current]?.imei,
        make: simPreference?.[numOfAddLines.current]?.make,
        model: simPreference?.[numOfAddLines.current]?.model,
      })
    );
    numOfAddLines.current = numOfAddLines.current + 1;
  };

  const showStep2 = () => {
    window.scroll(0, 0);
    setShowCheckIMEIModal(false);
    setLineStep(2);
  };

  const showManageCard = () => {
    setShowEstBillModal(false);
    setCardsFg(true);
  };

  const showCardChangeModalHandler = (fg?: boolean) => {
    setCardsFg(fg ?? false);
    dispatch(addLineMcLoader(false));
  };
  const ProceedPayment = () => {
    dispatch(addLineMcLoader(true));
    confirmPurchaseHandler();
  };

  return (
    <>
      {lineStep === 1 && (
        <ManageLinesComponent
          setCurrentSection={setCurrentSection}
          counter={counter}
          pendingLines={pendingLines}
          user={user}
          handleAddLine={handleAddLine}
          additionalCost={additionalCost}
          content={cacheContent ? cacheContent : manageContent}
          formik={formik}
          removeFormFields={removeFormFields}
          isSecondaryUserJourney={isSecondaryUserJourney}
          handleFormikChange={handleFormikChange}
          addUserError={addUserError}
          userData={userData}
          isEmailAVailable={isEmailAVailable}
          handleLinesTab={handleLinesTab}
          stopAddLinesFlow={stopAddLinesFlow}
          setStopAddLinesFlow={setStopAddLinesFlow}
        />
      )}
      {lineStep === 2 && (
        <ShippingComponent
          shippingAddress={shippingAddress}
          sendSimOptions={sendSimOptions}
          shipPlan={shipPlan}
          setShipPlan={setShipPlan}
          updateAddress={updateAddress}
          secondaryLineAddress={secondaryLineAddress}
          checkHandler={checkHandler}
          loader={loader}
          handleLinesTab={handleLinesTab}
        />
      )}
      {showCheckIMEIModal && (
        <ESimIMEI
          showCheckIMEIModal={showCheckIMEIModal}
          hideCheckIMEIModal={() => setShowCheckIMEIModal(false)}
          fromAddLine={true}
          isAccountScreen={true}
          showStep2={showStep2}
          numOfNewLines={counter}
          loader={loader}
          showReviewModal={() => null}
          setShowReviewModal1={checkHandler}
        />
      )}
      {showReviewModal1 && (
        <ReviewMonthlyStat
          showReviewModal1={showReviewModal1}
          pricingTax={pricingTax}
          reviewDetails={reviewDetails}
          setShowReviewModal1={setShowReviewModal1}
          reviewModalLoader={reviewModalLoader}
          gotItHandler={gotItHandler}
        />
      )}
      {showEstBillModal && (
        <EstimatedMonthlyBillDialog
          showEstBillModal={showEstBillModal}
          reviewDetails={reviewDetails}
          currentPlan={currentPlan}
          pricingTax={pricingTax}
          oneTmeCost={
            kitBasePrice + secondarySimPrice * (psimCount.current - 1)
          }
          counter={counter}
          oneTimeSIMTaxCharges={oneTimeSIMTaxCharges}
          shipPlan={shipPlan}
          setShowEstBillModal={setShowEstBillModal}
          estBillLoader={estBillLoader}
          confirmPurchaseHandler={confirmPurchaseHandler}
          showManageCard={showManageCard}
          numOfEsim={esimCount.current}
          numOfPsim={psimCount.current}
          eSimCostPerLine={
            eSimKitBasePrice + eSimSecondarySimPrice * (esimCount.current - 1)
          }
        />
      )}
      <OrderSuccessDialog
        purchaseSuccess={purchaseSuccess}
        content={manageContent}
      />
      <ManageCard
        cardData={getCCDetails}
        showCardChangeModal={cardsFg}
        setShowCardChangeModal={showCardChangeModalHandler}
        isModalRequired={true}
        pageName={"addlines"}
        isDescription={false}
        payType={false}
        ProceedPayment={ProceedPayment}
        isPayConfirm={true}
      />
    </>
  );
};
