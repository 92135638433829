import { Grid } from "@mui/material";
import styles from "../ReviewPlansDetails/S1_ReviewPlanDetails.module.scss"

type Props = {
  estimatedMonthlyBillAmount?: any;
  totalDueAmount?: any;
  content?:any;
};

export const MIASection = (props: Props) => {
  const { estimatedMonthlyBillAmount, totalDueAmount, content } = props;
  const {your_line} = styles;
  return (
    <Grid
      item
      container
      display="flex"
      sx={{
        justifyContent: { sm: "space-between", xs: "initial", ms: "initial" },
        border: "1px dashed #313131",
        borderRadius: "5px",
        p: "10px",
        m: "20px 0"
      }}
    >
        <Grid
            item
            container
            xs={12}
            sx={{ pl: "10px" }}
            className={your_line}
          >
            <Grid item xs={8} textAlign="left">
            {content?.MIA_l1}
            </Grid>
            <Grid item xs={4} textAlign="right">
            {estimatedMonthlyBillAmount !== 0
            ? `$${estimatedMonthlyBillAmount.toFixed(2)}`
            : "$0.00"}
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{ pl: "10px" }}
            className={your_line}
          >
            <Grid item xs={8} textAlign="left">
              {content?.MIA_l2}
            </Grid>
            <Grid item xs={4} textAlign="right">
            {totalDueAmount !== 0 ? `$${totalDueAmount.toFixed(2)}` : "$0.00"}
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{ pl: "10px", mt:"10px",fontSize:"14px", fontFamily:"var(--font_family_Semibold)"}}
          >
            <Grid item xs={8} textAlign="left" sx={{fontFamily:"inherit"}}>
              {content?.MIA_l3}
            </Grid>
            <Grid item xs={4} textAlign="right" sx={{fontFamily:"inherit"}}>
            {`$${(estimatedMonthlyBillAmount + totalDueAmount).toFixed(2)}`}
            </Grid>
          </Grid>
    </Grid>
  );
};
