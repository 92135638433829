const CSS_KEYWORDS = {
  UNSET: "unset",
  COLUMN: "column",
  COLUMN_REVERSE: "column-reverse",
  COLUMN_NOWRAP: "column nowrap",
  FLEX: "flex",
  SPACE_BETWEEN: "space-between",
  AUTO: "auto",
  CENTER: "center",
  LEFT: "left",
  ABSOLUTE: "absolute",
  RELATIVE: "relative",
};

Object.freeze(CSS_KEYWORDS);
export default CSS_KEYWORDS;
