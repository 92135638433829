import { Box } from "@mui/material";
import { ButtonCust } from "../../widgets";
import EnvConfig from "../../../config/env.config.json";
import { PaymentProps } from "../PaymentButtons/PaymentButtons";

const T2_PaymentButtons = (props: PaymentProps) => {
  return (
    <Box
      style={{
        cursor: props.paymentClicked ? "not-allowed" : "pointer",
      }}
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        my: "10px",
      }}
    >
      <ButtonCust
        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
        onClick={() => props.navigate("/home")}
        sx={{
          width: { xs: "100%", sm: "45%" },
        }}
      >
        {props.backButtonTxt}
      </ButtonCust>
      <ButtonCust
        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
        onClick={props.formik}
        disabled={props.paymentClicked}
        sx={{
          width: { xs: "100%", sm: "45%" },
          mt: { xs: "10px", sm: "0px" },
        }}
        loading={props.paymentClicked}
      >
        {props.submitBtnTxt}
      </ButtonCust>
    </Box>
  );
};
export default T2_PaymentButtons;
