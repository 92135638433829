import Config from "../../../../config/app.config.json";
import EnvConfig from "../../../../config/env.config.json";
import { PurchaseFlow } from "./PurchaseFlow/PurchaseFlow";
import { useState } from "react";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
const PlanCardComponent = isTemplateExisits(Config.Home.Plans)
  ? require(`./${Config.Home.Plans.template}_PlanCard`).default
  : null;

export type PlanProps = {
  plandata?: any;
  navigate?: any;
  isSimPaid?: boolean;
  planContent?: any;
  selectedLine?: any;
  borderColorAdd?: any;
  tagLabel?: any;
  tagBgColor?: any;
  tagTxtColor?: any;
  isSelected?: boolean;
  PurchaseJourneyCheck?: any;
};

export const PlanCard = (props: PlanProps) => {
  const [purchaseJourney, setPurchaseJourney] = useState(false);
  const [coverageModal, setCoverageModal] = useState(false);
  const [showCompatibilityModal, setShowCompatibilityModal] = useState(false);
  const [coverageError, setCoverageError] = useState(false);
  const [checkAddress, setCheckAddress] = useState("");
  const [coverageMessage, setCoverageMessage] = useState("");
  const [finalSuccessModal, setFinalSuccessModal] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [emailCompatability, setEmailCompatability] = useState("");
  const [imeiNumber, setImeiNumber] = useState("");
  const [coverageAddress, setCoverageAddress] = useState({
    address1: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });
  const PurchaseJourneyCheck = () => {
    setEmailCompatability("");
    setImeiNumber("");
    setCoverageError(false);
    setFinalSuccessModal(false);
    setIsFail(false);
    setCoverageMessage("");
    setCoverageAddress({
      address1: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    });
    setCheckAddress("");
    if (
      EnvConfig.ADDRESS_CHECK_DURING_PURCHASE &&
      EnvConfig.DEVICE_CHECK_DURING_PURCHASE && !EnvConfig.IS_ESIM_SUPPORTED
    ) {
      setCoverageModal(true);
      setPurchaseJourney(true);
      setShowCompatibilityModal(false);
    } else if (
      EnvConfig.ADDRESS_CHECK_DURING_PURCHASE &&
      !EnvConfig.DEVICE_CHECK_DURING_PURCHASE && !EnvConfig.IS_ESIM_SUPPORTED
    ) {
      setCoverageModal(true);
      setPurchaseJourney(true);
      setShowCompatibilityModal(false);
    } else if (
      !EnvConfig.ADDRESS_CHECK_DURING_PURCHASE &&
      EnvConfig.DEVICE_CHECK_DURING_PURCHASE && !EnvConfig.IS_ESIM_SUPPORTED
    ) {
      setShowCompatibilityModal(true);
      setCoverageModal(false);
      setPurchaseJourney(true);
    } else if (EnvConfig.IS_ESIM_SUPPORTED && !EnvConfig.ADDRESS_CHECK_DURING_PURCHASE &&
      !EnvConfig.DEVICE_CHECK_DURING_PURCHASE) {
      setCoverageModal(true);
      setPurchaseJourney(true);
      setShowCompatibilityModal(false);
    } else {
      navigate("/checkout");
      setPurchaseJourney(false);
    }
  };
  const {
    plandata,
    navigate,
    isSimPaid,
    planContent,
    selectedLine,
    isSelected,
  } = props;
  
  return (
    <>
      <PlanCardComponent
        plandata={plandata}
        navigate={navigate}
        isSimPaid={isSimPaid}
        planContent={planContent}
        selectedLine={selectedLine}
        isSelected={isSelected}
        PurchaseJourneyCheck={PurchaseJourneyCheck}
      />
      {purchaseJourney && (
        <PurchaseFlow
          coverageModal={coverageModal}
          setCoverageModal={setCoverageModal}
          showCompatibilityModal={showCompatibilityModal}
          setShowCompatibilityModal={setShowCompatibilityModal}
          coverageError={coverageError}
          setCoverageError={setCoverageError}
          checkAddress={checkAddress}
          setCheckAddress={setCheckAddress}
          coverageMessage={coverageMessage}
          setCoverageMessage={setCoverageMessage}
          coverageAddress={coverageAddress}
          setCoverageAddress={setCoverageAddress}
          finalSuccessModal={finalSuccessModal}
          setFinalSuccessModal={setFinalSuccessModal}
          isFail={isFail}
          setIsFail={setIsFail}
          emailCompatability={emailCompatability}
          setEmailCompatability={setEmailCompatability}
          imeiNumber={imeiNumber}
          setImeiNumber={setImeiNumber}
        />
      )}
    </>
  );
};
