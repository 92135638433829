import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import {
  getCustomerHeaderContent,
  strapiHeaderContent,
} from "../../../features/strapi/componentSlice";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import firebase from "../../../adapters/firebase";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { useNavigate } from "react-router-dom";
import { resetDataGetCustomer } from "../../../features/checkout/checkoutSlice";
const HeaderComponent = isTemplateExisits(Config.Header)
  ? require(`./${Config.Header.template}_Header`).default
  : null;

export type HeaderProps = {
  cacheHeader?: any;
  cacheCustomHeader?: any;
  logoutHandler?: any;
};
export const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { commonPage } = useAppSelector((state: any) => state.strapi);
  const { headerContent, customerHeaderContent } = commonPage;
  const [cacheHeader, setCacheHeader] = useState<any>(null);
  const [cacheCustomHeader, setCacheCustomHeader] = useState<any>(null);
  useEffect(() => {
    getCache("Header", strapiHeaderContent, setCacheHeader, dispatch);
    getCache(
      "customHeader",
      getCustomerHeaderContent,
      setCacheCustomHeader,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("Header", headerContent);
  }, [headerContent]);
  useNonInitialEffect(() => {
    addMultipleCacheData("customHeader", customerHeaderContent);
  }, [customerHeaderContent]);

  const logoutHandler = (redirectURL?: string) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.clear();
        navigate(redirectURL ? redirectURL : RoutingURLs.home);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    HeaderComponent &&
    (cacheHeader || headerContent) &&
    (cacheCustomHeader || customerHeaderContent) && (
      <HeaderComponent
        cacheHeader={cacheHeader ? cacheHeader : headerContent}
        cacheCustomHeader={
          cacheCustomHeader ? cacheCustomHeader : customerHeaderContent
        }
        logoutHandler={logoutHandler}
      />
    )
  );
};
