import { Box, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../Types/GoogleAnalytics";
import {
  pushTrackingEventsToGAAndFB,
  useNonInitialEffect,
} from "../../../utils/commonFunctions";
import { ButtonCust, ButtonCust_T2, SimpleModal } from "../../widgets";
import { InstallationTrackerProps } from "./InstallationTrackerComp";
import EnvConfig from "../../../config/env.config.json";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { useEffect, useState } from "react";
import {
  activitySubType,
  activityType,
  installationStatus,
  installationSubject,
  uiStatuses,
} from "../../../features/fsm/fsm";
import CancelInstallation from "../CancelInstallation/CancelInstallation";
import DynamicStepper from "../../widgets/DynamicStepper/DynamicStepper";
import ScheduleInstallationComponent from "../ScheduleInstallation/ScheduleInstallationComponent";
import {
  bookingSlots,
  bookingSlotsWithCustomDates,
  getActivityStatus,
  reOpenInstallationActivity,
  rescheduleInstallationActivity,
  resetCancelState,
} from "../../../features/fsm/fsmSlice";

const InstallationTrackerTemp = (props: InstallationTrackerProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    cancelInstall,
    rescheduleInstallationSuccess,
    rescheduleInstallationError,
    reOpenInstallationSuccess,
    reOpenInstallationError,
  } = useAppSelector((state: any) => state.fsm);

  const {
    content,
    activityStatus,
    stepperData,
    setStepperData,
    disableHandler,
    setDisableHandler,
    completedHandler,
    SetCompletedhandler,
    isFromFieldServices,
    setTrackerData,
    serviceInfo,
  } = props || {};
  const {
    cancel_title,
    cancel_desc,
    refund_desc,
    requestID,
    set_up_modem,
    book_service_note,
    cancel_icon,
    tick_icon,
    scheduled_title,
    scheduled_desc,
    contact_icon,
    any_questions,
    raise_ticket,
    reOpening_title,
    reOpening_desc,
    reOpened_title,
    reOpened_desc,
    suspended_title,
    suspended_desc1,
    suspended_desc2,
    suspended_desc3,
    auto_cancelled_title,
    auto_cancelled_desc1,
    stepper_titles,
    cancel_button,
    track_title,
    track_cancel_desc,
    track_btn_cont,
    track_btn_resc,
    track_reopen_req,
    track_cancel_inst,
    reOpen_modem_popup,
  } = content;
  const [installStatus, setInstallStatus] = useState("");
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDateCal, setSelectedDateCal] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [loader, setLoader] = useState(false);
  const [reOpenInstallationModal, setReOpenInstallationModal] = useState(false);
  const [uiStatus, setUiStatus] = useState(""); //scheduled, reOpening, reOpened, suspended, cancelled
  const [isInstallationReopened, setIsInstallationReopened] = useState(false);

  let activityInfo: any = localStorage.getItem("activityInfo");
  if (activityInfo) activityInfo = JSON.parse(activityInfo);
  let localInstallationStatus: any = localStorage.getItem("installationStatus");
  let customerDetails: any = localStorage.getItem("customerDetail");
  if (customerDetails) customerDetails = JSON.parse(customerDetails);

  useEffect(() => {
    if (localInstallationStatus && activityInfo) {
      localInstallationStatus === "cancelled"
        ? setInstallStatus("cancelled")
        : setInstallStatus(activityInfo?.data?.status);
    }
    isFromFieldServices ? null : fetchBookingSlots();
    return () => {
      localStorage.removeItem("installationStatus");
      dispatch(resetCancelState());
    };
  }, []);

  useNonInitialEffect(() => {
    if (selectedDateCal) fetchBookingSlots(true);
  }, [selectedDateCal]);

  useNonInitialEffect(() => {
    if (cancelInstall?.status === "SUCCESS") {
      isFromFieldServices &&
        setTrackerData((prevState: any) => ({
          ...prevState,
          currentStatus: "canceled",
        }));
      localStorage.setItem("installationStatus", "cancelled");
      setInstallStatus("cancelled");
    }
  }, [cancelInstall]);

  useEffect(() => {
    if (activityStatus && !isFromFieldServices) {
      constructTracker();
    }
  }, [activityStatus]);

  useEffect(() => {
    if (activityStatus && isFromFieldServices) {
      SetCompletedhandler(false);
      fetchBookingSlots();
      constructTracker();
    }
  }, [activityStatus]);

  useNonInitialEffect(() => {
    if (rescheduleInstallationSuccess?.status === "SUCCESS") {
      dispatch(
        getActivityStatus({
          activityId: rescheduleInstallationSuccess?.data?.reachActivityId,
        })
      );
    }
  }, [rescheduleInstallationSuccess]);

  useNonInitialEffect(() => {
    if (reOpenInstallationSuccess?.status === "SUCCESS") {
      localStorage.setItem(
        "activityInfo",
        JSON.stringify(reOpenInstallationSuccess)
      );

      dispatch(
        getActivityStatus({
          activityId: reOpenInstallationSuccess?.data?.reachActivityId,
        })
      );
      setIsInstallationReopened(true);
    }
  }, [reOpenInstallationSuccess]);

  const constructTracker = () => {
    if (activityStatus) {
      setLoader(false);
      setShowRescheduleModal(false);
      setSelectedDate("");
      setSelectedDateCal("");
      setSelectedTime("");

      let step1 = {
          title: stepper_titles.serviceCreated,
          subTitle: "",
          isTickEnabled: true,
          color: "successFill",
          levels: 4,
          currentLevel: 1,
          filling: "successFill",
        },
        step2 = {
          ...step1,
          title: stepper_titles.serviceAssigned,
          isTickEnabled: false,
          color: "fadedFill",
          currentLevel: 0,
          filling: "fadedFill",
        },
        step3 = { ...step2, title: stepper_titles.installationStarted },
        step4 = { ...step2, title: stepper_titles.serviceCompleted };

      const activityData = activityStatus?.data;
      if (
        activityData?.status === installationStatus.pending &&
        activityData?.subject === undefined
      ) {
        isFromFieldServices &&
          setTrackerData((prevState: any) => ({
            ...prevState,
            currentStatus: "open",
          }));
        setStepperData(null);
        setUiStatus(
          isInstallationReopened ? uiStatuses.reOpened : uiStatuses.scheduled
        );
        setDisableHandler((prevState: any) => ({
          ...prevState,
          status: false,
        }));
      } else if (
        activityData?.status === installationStatus.pending &&
        activityData?.subject === installationSubject.unAssigned
      ) {
        isFromFieldServices &&
          setTrackerData((prevState: any) => ({
            ...prevState,
            currentStatus: "open",
          }));
        setUiStatus(
          isInstallationReopened ? uiStatuses.reOpened : uiStatuses.scheduled
        );
        const step1Obj: any = getSubTitle(
          activityData?.eventTimeline,
          installationSubject.unAssigned
        );
        setStepperData([
          { ...step1, subTitle: step1Obj?.eventDescription },
          step2,
          step3,
          step4,
        ]);
        setDisableHandler((prevState: any) => ({
          ...prevState,
          status: true,
        }));
      } else if (
        activityData?.status === installationStatus.pending &&
        activityData?.subject === installationSubject.assigned
      ) {
        isFromFieldServices &&
          setTrackerData((prevState: any) => ({
            ...prevState,
            currentStatus: "open",
          }));
        setUiStatus(
          isInstallationReopened ? uiStatuses.reOpened : uiStatuses.scheduled
        );
        const step1Obj: any = getSubTitle(
          activityData?.eventTimeline,
          installationSubject.unAssigned
        );
        setStepperData([
          { ...step1, subTitle: step1Obj?.eventDescription, currentLevel: 4 },
          {
            ...step2,
            subTitle: stepper_titles?.serviceAssignedDesc,
            isTickEnabled: true,
            color: "successFill",
          },
          step3,
          step4,
        ]);
        setDisableHandler((prevState: any) => ({
          ...prevState,
          status: true,
          isDisabled: true,
        }));
      } else if (
        activityData?.status === installationStatus.started &&
        activityData?.subject === installationSubject.started
      ) {
        isFromFieldServices &&
          setTrackerData((prevState: any) => ({
            ...prevState,
            currentStatus: "open",
          }));
        setUiStatus(
          isInstallationReopened ? uiStatuses.reOpened : uiStatuses.scheduled
        );
        const step1Obj: any = getSubTitle(
          activityData?.eventTimeline,
          installationSubject.unAssigned
        );
        setStepperData([
          { ...step1, subTitle: step1Obj?.eventDescription, currentLevel: 4 },
          {
            ...step2,
            subTitle: stepper_titles?.serviceAssignedDesc,
            isTickEnabled: true,
            color: "successFill",
            currentLevel: 4,
            filling: "successFill",
          },
          {
            ...step3,
            subTitle: stepper_titles?.serviceStartedDesc,
            isTickEnabled: true,
            color: "successFill",
          },
          step4,
        ]);
        setDisableHandler((prevState: any) => ({
          ...prevState,
          status: true,
          isDisabled: true,
        }));
      } else if (
        activityData?.status === installationStatus.complete &&
        activityData?.subject === installationSubject.completed
      ) {
        isFromFieldServices &&
          setTrackerData((prevState: any) => ({
            ...prevState,
            currentStatus: "closed",
          }));
        setUiStatus(
          isInstallationReopened ? uiStatuses.reOpened : uiStatuses.scheduled
        );
        const step1Obj: any = getSubTitle(
          activityData?.eventTimeline,
          installationSubject.unAssigned
        );
        const step4Obj: any = getSubTitle(
          activityData?.eventTimeline,
          installationSubject.completed
        );
        setStepperData([
          { ...step1, subTitle: step1Obj?.eventDescription, currentLevel: 4 },
          {
            ...step2,
            subTitle: stepper_titles?.serviceAssignedDesc,
            isTickEnabled: true,
            color: "successFill",
            currentLevel: 4,
            filling: "successFill",
          },
          {
            ...step3,
            subTitle: stepper_titles?.serviceStartedDesc,
            isTickEnabled: true,
            color: "successFill",
            currentLevel: 4,
            filling: "successFill",
          },
          {
            ...step4,
            subTitle: step4Obj?.eventDescription,
            isTickEnabled: true,
            color: "successFill",
          },
        ]);
        setDisableHandler((prevState: any) => ({
          ...prevState,
          status: true,
          isDisabled: false,
        }));
        SetCompletedhandler(true);
      } else if (
        activityData?.status === installationStatus.suspended &&
        activityData?.subject === installationSubject.suspended
      ) {
        isFromFieldServices &&
          setTrackerData((prevState: any) => ({
            ...prevState,
            currentStatus: "open",
          }));
        setUiStatus(uiStatuses.suspended);
        const step1Obj: any = getSubTitle(
          activityData?.eventTimeline,
          installationSubject.unAssigned
        );
        setStepperData([
          {
            ...step1,
            subTitle: step1Obj?.eventDescription,
            currentLevel: 4,
            color: "fadedFill",
            filling: "fadedFill",
          },
          {
            ...step2,
            subTitle: stepper_titles?.serviceAssignedDesc,
            isTickEnabled: true,
            color: "fadedFill",
            currentLevel: 4,
            filling: "fadedFill",
          },
          {
            ...step3,
            subTitle: stepper_titles?.serviceStartedDesc,
            isTickEnabled: true,
            color: "fadedFill",
            currentLevel: 4,
            filling: "errorFill",
          },
          {
            ...step4,
            title: stepper_titles?.serviceSuspended,
            subTitle: stepper_titles?.serviceSuspendedDesc,
            isTickEnabled: true,
            color: "errorFill",
          },
        ]);
        setDisableHandler((prevState: any) => ({
          ...prevState,
          status: true,
          isDisabled: false,
        }));
      } else if (
        activityData?.status === installationStatus.cancelled &&
        activityData?.subject === installationSubject.cancelled
      ) {
        isFromFieldServices &&
          setTrackerData((prevState: any) => ({
            ...prevState,
            currentStatus: "canceled",
          }));
        setUiStatus(uiStatuses.cancelled);
        const step1Obj: any = getSubTitle(
          activityData?.eventTimeline,
          installationSubject.unAssigned
        );
        setStepperData([
          {
            ...step1,
            subTitle: step1Obj?.eventDescription,
            currentLevel: 4,
            color: "fadedFill",
            filling: "fadedFill",
          },
          {
            ...step2,
            subTitle: stepper_titles?.serviceAssignedDesc,
            isTickEnabled: true,
            color: "fadedFill",
            currentLevel: 4,
            filling: "fadedFill",
          },
          {
            ...step3,
            subTitle: stepper_titles?.serviceStartedDesc,
            isTickEnabled: true,
            color: "fadedFill",
            currentLevel: 4,
            filling: "errorFill",
          },
          {
            ...step4,
            title: stepper_titles.serviceCancelled,
            subTitle: stepper_titles?.serviceCancelledDesc,
            isTickEnabled: true,
            color: "errorFill",
          },
        ]);
        setDisableHandler((prevState: any) => ({
          ...prevState,
          status: true,
          isDisabled: false,
        }));
      }
    }
  };

  const getSubTitle = (eventTimeline: any, subject: string) =>
    eventTimeline.findLast((data: any) => data.subject === subject);

  const handleRaiseATicket = () => {
    if (customerDetails) {
      let custAdd: any = [];
      for (const key in customerDetails?.addresses[1]) {
        if (key !== "name" && key !== "type" && key !== "phone")
          custAdd.push(customerDetails?.addresses[1][key]);
      }
      custAdd = custAdd.join(", ");
      window.open(
        `${raise_ticket.navigationPath}?firstname=${customerDetails?.firstName}&lastname=${customerDetails?.lastName}&address=${custAdd}&reach_number=${customerDetails?.primaryNumber}&email=${customerDetails?.emailId}`,
        "_blank"
      );
    }
  };

  const buttonHandler = () => {
    completedHandler
      ? navigate(RoutingURLs.account)
      : setShowRescheduleModal(true);
  };

  const textCtaHandler = () => {
    completedHandler
      ? setReOpenInstallationModal(true)
      : setOpenCancelModal(true);
  };

  const closeRescheduleModal = () => {
    setSelectedDate("");
    setSelectedDateCal("");
    setSelectedTime("");
    setShowRescheduleModal(false);
  };

  const fetchBookingSlots = (isDate?: boolean) => {
    let payload = {
      customerID: customerDetails.id,
      bookingSlotObject: {
        activityType: activityType.Install,
        activitySubType: activitySubType.firstType,
        ...(isDate && { dates: selectedDateCal }),
      },
    };
    if (isFromFieldServices) {
      payload = {
        customerID: customerDetails.id,
        bookingSlotObject: {
          activityType: activityStatus?.data?.activityType,
          activitySubType:
            activityStatus?.data?.activityType === activityType.Install
              ? activitySubType.firstType
              : activityStatus?.data?.activityCode,
          ...(isDate && { dates: selectedDateCal }),
        },
      };
    }
    isDate
      ? dispatch(bookingSlotsWithCustomDates(payload))
      : dispatch(bookingSlots(payload));
  };

  const callRescheduleOrReOpenInstallActivity = (type?: string) => {
    setLoader(true);

    const data = {
      customerId: customerDetails.id,
      activityId: isFromFieldServices
        ? activityStatus?.data?.reachActivityId
        : activityInfo?.data?.reachActivityId,
      activityType: isFromFieldServices
        ? activityStatus?.data?.activityType
        : activityType.Install,
      moveToDate: selectedDate || selectedDateCal,
      timeSlot: selectedTime,
    };
    type === "reOpen"
      ? dispatch(reOpenInstallationActivity(data))
      : dispatch(rescheduleInstallationActivity(data));
  };

  const cancelContent = (
    <>
      <Box
        component={"img"}
        alt={cancel_icon?.data?.attributes?.alternativeText}
        src={cancel_icon?.data?.attributes?.url}
        sx={{ marginTop: "50px" }}
      />
      <Typography
        sx={{
          fontSize: "38px",
          fontFamily: "var(--font_family_Bold)",
          margin: "24px auto 30px",
        }}
      >
        {cancel_title}
      </Typography>
      <Typography
        sx={{
          fontSize: "18px",
          fontFamily: "var(--font_family_Semibold)",
          width: { xs: "90%", sm: "40%" },
          margin: "auto",
        }}
      >
        {cancel_desc} {cancelInstall?.data?.totalAmount > 0 ? refund_desc : ""}
      </Typography>
      <ButtonCust_T2
        variantType="primary_contained_square"
        sx={{
          my: "50px",
          width: "300px",
          fontSize: "20px !important",
        }}
        onClick={() => {
          pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
            appName: EnvConfig.brand,
            screenName: gaScreenNames.installationTracker,
            category: gaCategory.setupModem,
            buttonValue: set_up_modem,
          });
          navigate(RoutingURLs.account);
        }}
      >
        {cancel_button}
      </ButtonCust_T2>
      <Typography
        sx={{
          fontSize: "18px",
          fontFamily: "var(--font_family_Semibold)",
          width: "100%",
          margin: "  auto ",
          paddingBottom: "150px",
        }}
      >
        {book_service_note}
      </Typography>
    </>
  );

  const uiStatushandler = (data: string) => {
    //scheduled, reOpening, reOpened, suspended, cancelled
    if (data === "alternativeText" || data === "url") {
      return uiStatus === uiStatuses.suspended ||
        uiStatus === uiStatuses.cancelled
        ? cancel_icon?.data?.attributes?.[data]
        : tick_icon?.data?.attributes?.[data];
    }
    if (data === "title") {
      switch (uiStatus) {
        case uiStatuses.scheduled:
          return scheduled_title;
        case uiStatuses.reOpening:
          return reOpening_title;
        case uiStatuses.reOpened:
          return reOpened_title;
        case uiStatuses.suspended:
          return suspended_title;
        case uiStatuses.cancelled:
          return auto_cancelled_title;

        default:
          break;
      }
    }
    if (data === "desc") {
      switch (uiStatus) {
        case uiStatuses.scheduled:
          let bookingDate =
            new Date(activityStatus?.data?.bookingDetails?.bookingDate)
              .toString()
              .substring(4, 7) +
            " " +
            new Date(
              activityStatus?.data?.bookingDetails?.bookingDate
            ).getDate() +
            ", " +
            new Date(
              activityStatus?.data?.bookingDetails?.bookingDate
            ).getFullYear();

          let desc: string = scheduled_desc.replace("$date", bookingDate);
          desc = desc.replace(
            "$time",
            activityStatus?.data?.bookingDetails?.bookingTimeSlot
          );
          return desc;
        case uiStatuses.reOpening:
          return reOpening_desc;
        case uiStatuses.reOpened:
          return reOpened_desc;
        case uiStatuses.suspended:
          return <>{suspended_desc1}</>;
        case uiStatuses.cancelled:
          return <>{auto_cancelled_desc1}</>;

        default:
          break;
      }
    }
  };

  const reOpenRequestModalConfirm = () => {
    setUiStatus(uiStatuses.reOpening);
    setReOpenInstallationModal(false);
  };

  const successContent = uiStatus && (
    <>
      {!isFromFieldServices && (
        <>
          <Box
            component={"img"}
            alt={uiStatushandler("alternativeText")}
            src={uiStatushandler("url")}
            sx={{ marginTop: "50px" }}
          />
          <Typography
            sx={{
              fontSize: "38px",
              fontFamily: "var(--font_family_Bold)",
              margin: "24px auto 30px",
            }}
          >
            {uiStatushandler("title")}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontFamily: "var(--font_family_Semibold)",
              width: { xs: "100%", sm: "50%" },
              margin: "auto",
            }}
          >
            {uiStatushandler("desc")}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--text_color_8)",
              margin: "35px auto 55px",
            }}
          >
            {requestID} &nbsp; {activityInfo?.data?.activityId}
          </Typography>
        </>
      )}

      {/* Stepper section */}
      {uiStatus === uiStatuses.reOpening ? (
        <ScheduleInstallationComponent
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          selectedDateCal={selectedDateCal}
          setSelectedDateCal={setSelectedDateCal}
          callRescheduleOrReOpenInstallActivity={() =>
            callRescheduleOrReOpenInstallActivity("reOpen")
          }
          isReopening={true}
          loader={loader}
        />
      ) : (
        <Box
          sx={{
            textAlign: "center",
            boxShadow: `${
              isFromFieldServices ? "unset" : "3px 3px 6px 0 rgba(0,0,0,0.09)"
            }`,
            height: "auto",
            width: { xs: "100%", sm: isFromFieldServices ? "85%" : "65%" },
            backgroundColor: "var(--white)",
            margin: "auto",
            padding: { xs: "30px 0px", sm: "40px 45px" },
            boxSizing: "border-box",
          }}
        >
          <Typography
            sx={{
              color: "var(--text_color_8)",
              fontSize: { md: "22px" },
              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {track_title}
          </Typography>
          {stepperData && (
            <Box sx={{ mt: "50px" }}>
              <DynamicStepper
                steps={stepperData}
                stepperBg="var(--soft-peach)"
                successFill="var(--text_color_9)"
                fadedFill="var(--faded_grey1)"
                errorFill="var(--shocking-orange)"
              />
            </Box>
          )}
          {disableHandler.status &&
            uiStatus &&
            (uiStatus === uiStatuses.cancelled ? (
              <Box
                sx={{
                  my: { xs: "40px", sm: "15px" },
                  width: { xs: "90%", sm: "60%" },
                  mx: "auto",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "16px",
                    lineHeight: "17px",
                    color: "var(--text_color_8)",
                    fontFamily: "var(--font_family_Semibold)",
                  }}
                >
                  {track_cancel_desc}
                </Typography>
              </Box>
            ) : (
              <>
                <ButtonCust_T2
                  variantType="primary_contained_square"
                  sx={{
                    my: { xs: "30px", sm: "20px" },
                    width: `${completedHandler ? " 300px" : "220px"}`,
                    fontSize: "20px !important",
                  }}
                  disabled={disableHandler.isDisabled}
                  onClick={buttonHandler}
                >
                  {completedHandler ? track_btn_cont : track_btn_resc}
                </ButtonCust_T2>
                <Box sx={{ textAlign: "center", my: "15px" }}>
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "18px",
                      lineHeight: "28px",
                      color: "var(--primary_brand_color)",
                      fontFamily: "var(--font_family_Semibold)",
                      textDecoration: "underline",
                      opacity: `${disableHandler.isDisabled ? "40%" : "unset"}`,
                      cursor: `${
                        disableHandler.isDisabled ? "default" : "pointer"
                      }`,
                    }}
                    onClick={() => {
                      disableHandler.isDisabled ? null : textCtaHandler();
                    }}
                  >
                    {completedHandler ? track_reopen_req : track_cancel_inst}
                  </Typography>
                </Box>
              </>
            ))}
        </Box>
      )}

      {uiStatus === uiStatuses.reOpening ? null : (
        <Box
          sx={{
            fontSize: "18px",
            fontFamily: "var(--font_family_Semibold)",
            mt: "50px",
            paddingBottom: "150px",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Box
            component={"img"}
            alt={contact_icon?.data?.attributes?.alternativeText}
            src={contact_icon?.data?.attributes?.url}
          />
          <Typography
            sx={{
              fontSize: "18px",
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--text_color_8)",
            }}
          >
            {any_questions}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--primary_color)",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => handleRaiseATicket()}
          >
            {raise_ticket?.itemName}
          </Typography>
        </Box>
      )}
      <CancelInstallation
        openCancelModal={openCancelModal}
        setOpenCancelModal={setOpenCancelModal}
        activityInfo={{
          customerId: customerDetails.id,
          activityId: isFromFieldServices
            ? activityStatus?.data?.reachActivityId
            : activityInfo?.data?.reachActivityId,
          activityType: isFromFieldServices
            ? activityStatus?.data?.activityType
            : activityInfo?.data?.activityType,
          amount: isFromFieldServices
            ? activityStatus?.data?.amount
            : activityInfo?.data?.amount,
        }}
        {...(isFromFieldServices && {
          isFromFieldServices: isFromFieldServices,
        })}
      />
    </>
  );

  return (
    <>
      <Box
        sx={{
          width: { xs: "90%", sm: "90%", md: "100%" },
          margin: {
            xs: "70px auto 0",
            sm: isFromFieldServices ? "10px auto 0" : "100px auto 0",
          },
          textAlign: "center",
        }}
      >
        {installStatus && installStatus === "cancelled"
          ? cancelContent
          : successContent}
        {showRescheduleModal ? (
          <SimpleModal
            isShowModal={showRescheduleModal}
            onCloseModal={closeRescheduleModal}
            showClose={true}
            sx={{
              width: { xs: "90%", sm: "40%", lg: "42%" },
              minWidth: { xs: "90%", sm: "40%" },
            }}
          >
            <ScheduleInstallationComponent
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
              selectedDateCal={selectedDateCal}
              setSelectedDateCal={setSelectedDateCal}
              callRescheduleOrReOpenInstallActivity={
                callRescheduleOrReOpenInstallActivity
              }
              loader={loader}
              isReschedule
              isModal
              closeRescheduleModal={closeRescheduleModal}
            />
          </SimpleModal>
        ) : null}
        {reOpenInstallationModal ? (
          <SimpleModal
            isShowModal={reOpenInstallationModal}
            onCloseModal={() => setReOpenInstallationModal(false)}
            showClose={true}
            sx={{
              width: { xs: "90%", sm: "35%" },
              minWidth: { xs: "90%", sm: "30%" },
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "var(--font_family_Bold)",
                  fontSize: "26px",
                  lineHeight: "28px",
                  color: "var(--text_color_8)",
                  textAlign: "center",
                  my: "10px",
                }}
              >
                {reOpen_modem_popup?.title}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: "16px",
                  lineHeight: "26px",
                  color: "var(--text_color_8)",
                  textAlign: "center",
                  px: "30px",
                }}
              >
                {reOpen_modem_popup?.desc}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <ButtonCust
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  sx={{ my: "15px", mx: "auto" }}
                  onClick={() => reOpenRequestModalConfirm()}
                >
                  {reOpen_modem_popup?.btn}
                </ButtonCust>
              </Box>
            </Box>
          </SimpleModal>
        ) : null}
      </Box>
    </>
  );
};

export default InstallationTrackerTemp;
