import { ButtonCust, SimpleModal } from "../../widgets";
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import EnvConfig from "../../../config/env.config.json";

type Props = {
  showAutoPayConsentModal: boolean;
  setShowAutoPayConsentModal: any;
  autoPaymentDetails: any;
  autoPayConsent: boolean;
  setAutoPayConsent: any;
  isLoading: boolean;
  addNewCardHandler: any;
};

export const AutoPayConsentModal = (props: Props) => {
  const {
    showAutoPayConsentModal,
    setShowAutoPayConsentModal,
    autoPaymentDetails,
    autoPayConsent,
    setAutoPayConsent,
    isLoading,
    addNewCardHandler,
  } = props;

  const {
    title,
    card1_title,
    card1_desc,
    card2_title,
    card2_desc,
    confirm_btn,
  } = autoPaymentDetails?.autopay_consent || {};
  return (
    <SimpleModal
      isShowModal={showAutoPayConsentModal}
      onCloseModal={() => setShowAutoPayConsentModal(false)}
      showClose={true}
      sx={{
        width: {
          xs: "95%",
          sm: "80%",
          md: "80%",
          lg: "40%",
          xl: "30%",
        },
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontSize: "24px",
          my: "5px",
          fontFamily: "var(--font_family_Bold)",
          color: "var(--text_color)",
        }}
      >
        {title}
      </Typography>
      <Card
        elevation={3}
        variant="elevation"
        sx={{ mt: 3, mb: 2, width: "98%", borderRadius: "0px" }}
      >
        <CardHeader
          title={card1_title}
          titleTypographyProps={{
            variant: "h5",
            fontSize: "16px",
            fontFamily: "var(--font_family_Medium)",
            color: "var(--primary_brand_color)",
          }}
          sx={{
            border: "5px solid var(--lead_generation_bg_color)",
            p: "8px",
          }}
        />
        <CardContent>
          <Typography variant="subtitle1">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      color: "var(--primary_color)",
                    }}
                    checked={autoPayConsent}
                    sx={{
                      "&, & + .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        fontFamily: "var(--font_family_Semibold)",
                      },
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setAutoPayConsent(e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontFamily: "var(--font_family_Semibold)",
                      color: "var(--text_color_11)",
                    }}
                  >
                    {card1_desc}
                  </Typography>
                }
              />
            </FormGroup>
          </Typography>
        </CardContent>
      </Card>
      <Card
        elevation={1}
        variant="elevation"
        sx={{ mt: 1, mb: 3, width: "98%", borderRadius: "0px" }}
      >
        <CardHeader
          title={card2_title}
          titleTypographyProps={{
            variant: "h5",
            fontSize: "16px",
            fontFamily: "var(--font_family_Medium)",
            color: "var(--text_color_8)",
          }}
          sx={{
            borderBottom: "5px solid var(--white)",
            background: "var(--lightgrey_6)",
            p: "8px",
          }}
        />
        <CardContent sx={{ background: "var(--lightgrey_6)" }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "14px",
              lineHeight: "22px",
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--text_color)",
            }}
          >
            {card2_desc}
          </Typography>
        </CardContent>
      </Card>

      <ButtonCust
        sx={{ my: 1, width: "250px", height: "48px" }}
        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
        loading={isLoading}
        onClick={addNewCardHandler}
      >
        {confirm_btn}
      </ButtonCust>
    </SimpleModal>
  );
};
