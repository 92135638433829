import { useState, useEffect } from "react";
import { Avatar, Box, Grid, Paper, Typography } from "@mui/material";
import { string, object } from "yup";
import { useFormik } from "formik";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ProfileDetailsDialog } from "./ProfileDetailsDialog";
import { DialogTypes } from "./ProfileDialogTypes";
import {
  emailPattern,
  namePattern,
  PhoneNoPattern,
} from "../../../utils/regexPatterns";
import { addressType } from "../../../features/checkout/Types";
import {
  displayPhoneNumberFormat,
  findStateByCode,
} from "../../../utils/commonFunctions/ReusableFunctions";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  customerData,
  verifyCustomerEmail,
} from "../../../features/profile/profileSlice";
import firebase from "../../../adapters/firebase";
import {
  GetCustomer,
  UpdateCustomerSource,
} from "../../../features/checkout/checkoutSlice";
import { NotificationCust, TextFieldCust, ButtonCust } from "../../widgets";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../Types/GoogleAnalytics";
import EnvConfig from "../../../config/env.config.json";
import { CustomerStatus } from "../../../enums";
import { SimpleModal } from "../../widgets";

const labelStyles = {
  fontSize: "16px",
  fontFamily: "var(--font_family_Medium)",
  color: "var(--text_color_8)",
};
const labelUpdateStyle = {
  fontSize: "16px",
  color: "var(--primary_color)",
  textDecoration: "underline",
  fontFamily: "var(--font_family_Semibold)",
  cursor: "pointer",
};
const styles: any = {};
export const ProfileSuccessNotifications = (msg: string) => {
  NotificationCust({
    message: msg,
    duration: 2000,
    id: "profileUpdate",
  });
};

export const ProfileErrorNotifications = (msg: string) => {
  NotificationCust({
    message: msg,
    type: "danger",
    duration: 2000,
    id: "profileUpdate",
  });
};
export const SuborUnsubNotification = (msg1: string, msg2: string) => {
  NotificationCust({
    message: (
      <>
        <Typography
          component={"div"}
          sx={{
            fontFamily: "var(--font_family_Medium)",
            fontSize: "18px",
          }}
        >
          {msg1}
        </Typography>
        <Typography
          component={"div"}
          sx={{
            fontFamily: "var(--font_family_Semibold)",
            fontSize: "14px",
          }}
        >
          {msg2}
        </Typography>
      </>
    ),
    duration: 2000,
    id: "profileUpdate",
  });
};

const ProfileDetails = (props: any) => {
  const [state, setState] = useState<any>({
    open: false,
    getDOBDate: "",
    setNewEmail: false,
    dialogType: "",
  });
  const [currentUser, setCurrentUser] = useState<any>({});
  const [profileImg, setProfileImg] = useState({
    imgUrl: "",
    profileImageURL: {},
  });
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingEmailBtn, setLoadingEmailBtn] = useState(false);
  const [serviceAddress, setServiceAddress] = useState({
    street: "",
    aptOrUnitOrFloor: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });

  const [billingAddress, setBillingAddress] = useState({
    street: "",
    aptOrUnitOrFloor: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });

  const [isEditable, setIsEditable] = useState(false);
  const [subOrUnsubConfirmModal, setSubOrUnsubConfirmModal] = useState(false);
  const [subOrUnsubLoader, setSubOrUnsubLoader] = useState(false);

  const { content } = props;

  const { profileDetails } = useAppSelector(
    (state: any) => state.profileDetails
  );
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const dispatch = useAppDispatch();

  const profileSchema = object({
    firstName: string()
      .trim()
      .required(content?.prf_err?.fst_req)
      .min(2, content?.prf_err?.fst_req)
      .max(24, content?.prf_err?.fst_req),
    lastName: string()
      .trim()
      .required(content?.prf_err?.lst_req)
      .min(2, content?.prf_err?.lst_req)
      .max(24, content?.prf_err?.lst_req),
    phoneNumber: string()
      .trim()
      .required(content?.prf_err?.num_err)
      .min(10, content?.prf_err?.num_err),
  });

  const emailSchema = object({
    newEmail: string()
      .trim()
      .required(content?.prf_err?.email_req)
      .matches(emailPattern, content?.prf_err?.email_valid),
  });

  const formik = useFormik({
    initialValues: profileDetails,
    validationSchema: profileSchema,
    onSubmit: () => {
      setLoadingBtn(true);
      handleUpdateProfileDetails();
    },
    enableReinitialize: true,
  });
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.profile,
      category: category,
      buttonValue: value,
    });
  };

  const emailForm = useFormik({
    initialValues: profileDetails,
    validationSchema: emailSchema,
    onSubmit: (values: any) => {
      setLoadingEmailBtn(true);
      handleChangeEmail(values);
    },
    enableReinitialize: true,
  });

  const handleChangeEmail = (values: any) => {
    const { newEmail, firstName, lastName } = values;
    dispatch(verifyCustomerEmail({ username: encodeURIComponent(newEmail) }))
      .then((res: any) => {
        if (res.payload.status === 200) {
          setLoadingEmailBtn(false);
          ProfileErrorNotifications(content?.notifications?.email_use);
        } else {
          const user1 = firebase.auth().currentUser;
          if (user1) {
            user1
              .updateEmail(newEmail)
              .then(async () => {
                dispatch(
                  UpdateCustomerSource({
                    customerId: profileDetails?.customerDetails?.id,
                    data: { firstName, lastName, emailId: newEmail },
                  })
                )
                  .then(() => {
                    setIsEditable(false);
                    localStorage.setItem("currentuser", JSON.stringify(user1));
                    firebase
                      .database()
                      .ref("source/")
                      .update(
                        { [btoa(newEmail)]: EnvConfig.OPERATOR },
                        (error) => {
                          if (error) {
                            ProfileErrorNotifications(
                              content?.notifications?.try_again
                            );
                          } else {
                            ProfileSuccessNotifications(
                              content?.notifications?.profile_updated
                            );
                          }
                          setLoadingEmailBtn(false);
                          setState({ ...state, setNewEmail: false });
                          emailForm.resetForm();
                        }
                      );
                    dispatch(
                      GetCustomer({ username: encodeURIComponent(newEmail) })
                    );
                  })
                  .catch(() => {
                    setLoadingEmailBtn(false);
                    ProfileErrorNotifications(
                      content?.notifications?.try_again
                    );
                  });
              })
              .catch(() => {
                setLoadingEmailBtn(false);
                ProfileErrorNotifications(content?.notifications?.email_use);
              });
          } else {
            ProfileErrorNotifications(content?.notifications?.try_again);
          }
        }
      })
      .catch(() => {
        setLoadingEmailBtn(false);
        ProfileErrorNotifications(content?.notifications?.try_again);
      });
  };

  const handleUpdateProfileDetails = () => {
    const { firstName, lastName, email, phoneNumber } = formik.values;
    const user1 = firebase.auth().currentUser;
    if (user1) {
      user1
        .updateProfile({
          displayName: `${firstName} ${lastName}`,
        })
        .then(async () => {
          dispatch(
            UpdateCustomerSource({
              customerId: profileDetails?.customerDetails?.id,
              data: {
                firstName,
                lastName,
                emailId: email,
                primaryNumber: phoneNumber,
              },
            })
          )
            .then((res: any) => {
              setIsEditable(false);
              setLoadingBtn(false);
              if (res.payload.status === 200) {
                localStorage.setItem("currentuser", JSON.stringify(user1));
                ProfileSuccessNotifications(
                  content?.notifications?.profile_updated
                );
                dispatch(
                  GetCustomer({
                    username: encodeURIComponent(profileDetails.email),
                  })
                );
              } else {
                ProfileErrorNotifications(content?.notifications?.try_again);
              }
            })
            .catch(() => {
              setLoadingBtn(false);
              ProfileErrorNotifications(content?.notifications?.try_again);
            });
        })
        .catch(() => {
          setLoadingBtn(false);
          ProfileErrorNotifications(content?.notifications?.try_again);
        });
    }
    updateProfileImage();
  };

  const updateProfileImage = () => {
    let file: any = profileImg.profileImageURL;
    if (file) {
      const storageRef = firebase
        .storage()
        .ref("profile_pics/uploaded/" + firebase.auth().currentUser?.uid);
      const thisRef = storageRef.child(file.name);
      thisRef.put(file).then((snapshot) => {
        firebase
          .database()
          .ref("profileInfo/" + btoa(currentUser?.uid))
          .update({ profilePic: snapshot.metadata.fullPath });
      });
    }
  };

  const handleClose = () => {
    setState({ ...state, open: false, setNewEmail: false, dialogType: "" });
    formik.setErrors({});
  };

  const handleOpenDialog = (dialogType: string) => {
    setState({
      ...state,
      open: true,
      setNewEmail: false,
      dialogType: dialogType,
    });
  };

  const handleSave = (dialogType: string) => {
    if (dialogType === DialogTypes.changeEmail) {
      setState({ ...state, open: false, setNewEmail: true, dialogType: "" });
    } else {
      setState({ ...state, open: false, setNewEmail: false, dialogType: "" });
    }
  };

  const handleBackEmailForm = () => {
    setState({ ...state, setNewEmail: false });
    emailForm.resetForm();
  };

  useEffect(() => {
    if (getCustomer) {
      const {
        firstName,
        lastName,
        emailId,
        primaryNumber,
        marketingComEnabled,
      } = getCustomer;
      const address = getCustomer?.addresses?.find(
        (item: any) => item.type === addressType.billing
      );
      const serviceAddress = getCustomer?.addresses?.find(
        (item: any) => item.type !== addressType.billing
      );

      setServiceAddress((prevState) => ({
        ...prevState,
        ["street"]: serviceAddress?.address1,
        ["aptOrUnitOrFloor"]: serviceAddress?.address2,
        ["city"]: serviceAddress?.city,
        ["state"]: serviceAddress?.state,
        ["zipCode"]: serviceAddress?.zip,
        ["country"]: serviceAddress?.country,
      }));
      setBillingAddress((prevState) => ({
        ...prevState,
        ["street"]: address?.address1,
        ["aptOrUnitOrFloor"]: address?.address2,
        ["city"]: address?.city,
        ["state"]: address?.state,
        ["zipCode"]: address?.zip,
        ["country"]: address?.country,
      }));
      const fullAddress = `${address?.address1}, ${address?.city}, ${address?.state}, ${address?.country}, ${address?.zip}`;
      dispatch(
        customerData({
          ...profileDetails,
          firstName,
          lastName,
          email: emailId,
          phoneNumber: primaryNumber,
          fullAddress,
          customerDetails: getCustomer,
          marketingComEnabled: marketingComEnabled,
        })
      );
    }
  }, [getCustomer]);

  const onChangeImage = (e: any) => {
    if (e.target.files.length > 0) {
      const ImgURL = URL.createObjectURL(e.target.files[0]);
      setProfileImg({ profileImageURL: e.target.files[0], imgUrl: ImgURL });
    }
  };

  useEffect(() => {
    let currentUserInfo: any = localStorage.getItem("currentuser");
    currentUserInfo = JSON.parse(currentUserInfo);
    setCurrentUser(currentUserInfo);
    // calls get customer details api
    dispatch(
      GetCustomer({ username: encodeURIComponent(currentUserInfo?.email) })
    );
    // firebase call for profile image and date of birth
    firebase
      .database()
      .ref(`profileInfo/${btoa(currentUserInfo?.uid)}`)
      .once("value")
      .then((snapshot: any) => {
        let data: any = snapshot.val();
        if (data?.profilePic) {
          let storage: any = firebase.storage();
          storage
            .ref(snapshot.val().profilePic)
            .getDownloadURL()
            .then((url: any) => {
              if (url) {
                setProfileImg({ ...profileImg, imgUrl: url });
              } else {
                setProfileImg({
                  ...profileImg,
                  imgUrl: content?.profile_img?.data?.attributes?.url,
                });
              }
            });
        }
      })
      .catch(() => {
        setProfileImg({
          ...profileImg,
          imgUrl: content?.profile_img?.data?.attributes?.url,
        });
      });
  }, []);

  const subscriptionHandler = (value: boolean) => {
    setSubOrUnsubLoader(true);
    dispatch(
      UpdateCustomerSource({
        customerId: profileDetails?.customerDetails?.id,
        data: {
          marketingComEnabled: value,
        },
      })
    )
      .then((res: any) => {
        setSubOrUnsubLoader(false);
        setSubOrUnsubConfirmModal(false);
        const marketingComValue = res?.payload?.data?.data?.marketingComEnabled;
        if (res.payload.status === 200) {
          SuborUnsubNotification(
            content?.notifications?.[
              `${marketingComValue ? "sub_success" : "unsub_success"}`
            ],
            content?.notifications?.[
              `${marketingComValue ? "sub_success_desc" : "unsub_success_desc"}`
            ]
          );
          dispatch(
            GetCustomer({
              username: encodeURIComponent(profileDetails.email),
            })
          );
        } else {
          ProfileErrorNotifications(content?.notifications?.try_again);
        }
      })
      .catch(() => {
        setSubOrUnsubLoader(false);
        setSubOrUnsubConfirmModal(false);
        setLoadingBtn(false);
        ProfileErrorNotifications(content?.notifications?.try_again);
      });
  };

  return (
    <>
      <Box
        sx={{
          paddingTop: { md: "50px" },
          background: "var(--main_route_background)",
          pb: "1px",
        }}
      >
        <Grid
          py={"25px"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: { xs: "91%", md: "85%" },
            mx: "auto",
            alignItems: "center",
          }}
        >
          <Typography
            component={"h5"}
            sx={{
              color: "var(--text_color_8)",
              fontFamily: "var(--font_family_Medium)",
              fontSize: { xs: "26px", sm: "28px", md: "38px" },
            }}
          >
            {content?.heading}
          </Typography>
          {getCustomer?.status === CustomerStatus.ACTIVE && (
            <ButtonCust
              variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
              onClick={() => (!isEditable ? setIsEditable(true) : null)}
              sx={{
                opacity: isEditable ? "0.5" : "unset",
                cursor: isEditable ? "default" : "pointer",
                minWidth: "108px !important",
              }}
            >
              {content?.edit_btn}
            </ButtonCust>
          )}
        </Grid>
        <Paper
          variant="outlined"
          square
          sx={{ width: { xs: "91%", md: "85%" }, mx: "auto", mb: "45px" }}
        >
          {!state?.setNewEmail ? (
            <Grid container>
              <Grid
                item
                md={1.5}
                sx={{
                  background: {
                    xs: `url(${content?.bg_img_mbl?.data?.attributes?.url})`,
                    sm: `url(${content?.bg_img_tab?.data?.attributes?.url})`,
                    md: `url(${content?.left_bg_img?.data?.attributes?.url})`,
                  },
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  width: "inherit",
                }}
              >
                <Avatar
                  sx={{
                    width: "100px",
                    height: "100px",
                    top: "40px",
                    margin: "auto",
                  }}
                  alt={content?.profile_img?.data?.attributes?.alternativeText}
                  src={
                    profileImg?.imgUrl
                      ? profileImg?.imgUrl
                      : content?.profile_img?.data?.attributes?.url
                  }
                />
                <label
                  htmlFor="file inp"
                  style={{ marginTop: "-30px", height: "44px", zIndex: 10 }}
                >
                  <Box
                    component="img"
                    sx={{
                      cursor: "pointer",
                      ml: { xs: "200px", sm: "398px", md: "100px" },
                      mt: "15px",
                      width: "35px",
                      height: "35px",
                    }}
                    alt={
                      content?.profile_img?.data?.attributes?.alternativeText
                    }
                    src={content?.edit_icon?.data?.attributes?.url}
                  />
                </label>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="file inp"
                  accept="image/*"
                  onChange={(e: any) => {
                    onChangeImage(e);
                  }}
                />
                <Typography
                  component="div"
                  sx={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontFamily: "var(--font_family_Medium)",
                    color: "var(--text_color_8)",
                  }}
                >
                  {formik.values.firstName}
                </Typography>
              </Grid>
              <Grid item md={10.5} sx={{ p: { xs: "20px", md: "45px" } }}>
                <Typography
                  component={"div"}
                  sx={{
                    color: "var(--text_color_8)",
                    fontSize: { xs: "22px", md: "24px" },
                    fontFamily: "var(--font_family_Medium)",
                  }}
                >
                  {content?.personal_details_title}
                </Typography>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>
                      {content?.first_name}
                    </Typography>
                    <TextFieldCust
                      type="text"
                      name="firstName"
                      fullWidth
                      value={formik.values.firstName}
                      onBlur={formik.handleBlur}
                      label={content?.first_name_plc}
                      id="First Name"
                      onChange={(e: any) => {
                        if (
                          namePattern.test(
                            e.target.value || e.target.value === ""
                          )
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                      disabled={!isEditable}
                      error={formik.errors?.firstName ? true : false}
                      helperText={<>{formik.errors?.firstName}</>}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>
                      {content?.last_name}
                    </Typography>
                    <TextFieldCust
                      fullWidth
                      type="text"
                      value={formik.values.lastName}
                      onBlur={formik.handleBlur}
                      name="lastName"
                      label={content?.last_name_plc}
                      onChange={(e: any) => {
                        if (
                          namePattern.test(
                            e.target.value || e.target.value === ""
                          )
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                      disabled={!isEditable}
                      error={formik.errors?.lastName ? true : false}
                      helperText={<>{formik.errors?.lastName}</>}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={labelStyles}>{content?.pwd}</Typography>
                      {isEditable ? (
                        <Typography
                          sx={labelUpdateStyle}
                          onClick={() => {
                            handleOpenDialog(DialogTypes.changePassword);
                            GAAndFBEventsHandler(
                              gaCategory.editProfile,
                              content?.change_pwd
                            );
                          }}
                        >
                          {content?.change_pwd}
                        </Typography>
                      ) : null}
                    </Box>

                    <TextFieldCust
                      fullWidth
                      value={"12345678"}
                      disabled
                      type="password"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={labelStyles}>{content?.email}</Typography>
                      {isEditable ? (
                        <Typography
                          sx={labelUpdateStyle}
                          onClick={() => {
                            handleOpenDialog(DialogTypes.changeEmail);
                            GAAndFBEventsHandler(
                              gaCategory.editProfile,
                              content?.change_email
                            );
                          }}
                        >
                          {content?.change_email}
                        </Typography>
                      ) : null}
                    </Box>
                    <TextFieldCust
                      fullWidth
                      disabled
                      value={formik.values.email}
                      type="email"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>
                      {content?.phone_num}
                    </Typography>
                    <TextFieldCust
                      fullWidth
                      type="string"
                      maxlength={10}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { value } = e.target;
                        if (
                          (PhoneNoPattern.test(value) && Number(value) >= 0) ||
                          value === ""
                        ) {
                          if (value.length === 10) {
                            e.target.value = value
                              .replace(/\D+/g, "")
                              .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                          }
                          formik.handleChange(e);
                        }
                        if (value.length > 10) {
                          const contactNumber: string = String(
                            value.match(/\d/g)?.join("") || ""
                          );
                          e.target.value = contactNumber;
                          formik.handleChange(e);
                        }
                      }}
                      value={
                        formik.values.phoneNumber
                          ? displayPhoneNumberFormat(formik.values.phoneNumber)
                          : ""
                      }
                      disabled={!isEditable}
                      onBlur={formik.handleBlur}
                      name="phoneNumber"
                      label={content?.phone_num_plc}
                      error={formik.errors?.phoneNumber ? true : false}
                      helperText={<>{formik.errors?.phoneNumber}</>}
                    />
                  </Grid>
                </Grid>

                <Typography
                  component={"div"}
                  sx={{
                    color: "var(--text_color_8)",
                    fontSize: { xs: "22px", md: "24px" },
                    fontFamily: "var(--font_family_Medium)",
                    my: "15px",
                  }}
                >
                  {content?.service_add_title}
                </Typography>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>{content?.street}</Typography>
                    <TextFieldCust
                      type="text"
                      name="street"
                      fullWidth
                      value={serviceAddress.street}
                      label={content?.street_plc}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>
                      {content?.apt_unit}
                    </Typography>
                    <TextFieldCust
                      type="text"
                      name="aptOrUnitOrFloor"
                      fullWidth
                      value={serviceAddress.aptOrUnitOrFloor}
                      label={content?.apt_unit_plc}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>{content?.city}</Typography>
                    <TextFieldCust
                      type="text"
                      name="city"
                      fullWidth
                      value={serviceAddress.city}
                      label={content?.city_plc}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>{content?.state}</Typography>
                    <TextFieldCust
                      type="text"
                      name="state"
                      fullWidth
                      value={
                        serviceAddress.state &&
                        findStateByCode(serviceAddress.state)
                      }
                      label={content?.state_plc}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>{content?.zipcode}</Typography>
                    <TextFieldCust
                      type="text"
                      name="zipCode"
                      fullWidth
                      value={serviceAddress.zipCode}
                      label={content?.zipcode_plc}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>{content?.country}</Typography>
                    <TextFieldCust
                      type="text"
                      name="country"
                      fullWidth
                      value={serviceAddress.country}
                      label={content?.country_plc}
                      disabled={true}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ my: "15px" }}>
                  <Typography
                    component={"span"}
                    sx={{
                      color: "var(--text_color_8)",
                      fontSize: { xs: "22px", md: "24px" },
                      fontFamily: "var(--font_family_Medium)",
                    }}
                  >
                    {content?.billing_add_title}
                  </Typography>
                  {isEditable ? (
                    <Typography
                      component={"span"}
                      sx={labelUpdateStyle}
                      ml="10px"
                      onClick={() => {
                        handleOpenDialog(DialogTypes.changeAddress);
                        GAAndFBEventsHandler(
                          gaCategory.editProfile,
                          content?.update_billing_addr
                        );
                      }}
                    >
                      {content?.update_billing_addr}
                    </Typography>
                  ) : null}
                </Box>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>{content?.street}</Typography>
                    <TextFieldCust
                      type="text"
                      name="street"
                      fullWidth
                      value={billingAddress.street}
                      label={content?.state_plc}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>
                      {content?.apt_unit}
                    </Typography>
                    <TextFieldCust
                      type="text"
                      name="aptOrUnitOrFloor"
                      fullWidth
                      value={billingAddress.aptOrUnitOrFloor}
                      label={content?.apt_unit_plc}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>{content?.city}</Typography>
                    <TextFieldCust
                      type="text"
                      name="city"
                      fullWidth
                      value={billingAddress.city}
                      label={content?.city_plc}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>{content?.state}</Typography>
                    <TextFieldCust
                      type="text"
                      name="state"
                      fullWidth
                      value={
                        billingAddress.state &&
                        findStateByCode(billingAddress.state)
                      }
                      label={content?.state_plc}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>{content?.zipcode}</Typography>
                    <TextFieldCust
                      type="text"
                      name="zipCode"
                      fullWidth
                      value={billingAddress.zipCode}
                      label={content?.zipcode_plc}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={labelStyles}>{content?.country}</Typography>
                    <TextFieldCust
                      type="text"
                      name="country"
                      fullWidth
                      value={billingAddress.country}
                      label={content?.country_plc}
                      disabled={true}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ my: "15px" }}>
                  <Typography
                    component={"div"}
                    sx={{
                      color: "var(--text_color_8)",
                      fontSize: { xs: "22px", md: "24px" },
                      fontFamily: "var(--font_family_Medium)",
                    }}
                  >
                    {content?.communication_title}
                  </Typography>
                  {profileDetails && profileDetails?.marketingComEnabled ? (
                    <>
                      <Typography
                        component={"span"}
                        sx={{
                          fontSize: "16px",
                          fontFamily: "var(--font_family_Semibold)",
                          color: "text_color_8",
                        }}
                      >
                        {content?.communication_desc}{" "}
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={labelUpdateStyle}
                        onClick={() => setSubOrUnsubConfirmModal(true)}
                      >
                        {content?.communication_unsub}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        component={"span"}
                        sx={labelUpdateStyle}
                        onClick={() => setSubOrUnsubConfirmModal(true)}
                      >
                        {content?.communication_sub}{" "}
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          fontSize: "16px",
                          fontFamily: "var(--font_family_Semibold)",
                          color: "text_color_8",
                        }}
                      >
                        {content?.communication_desc2}
                      </Typography>
                    </>
                  )}
                </Box>

                {isEditable ? (
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "space-between", sm: "flex-end" },
                      alignItems: "center",
                      my: "25px",
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={labelUpdateStyle}
                      onClick={() => {
                        formik.resetForm();
                        setIsEditable(false);
                      }}
                      mx="10px"
                    >
                      {content?.discard_changes}
                    </Typography>
                    <ButtonCust
                      variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                      onClick={() => {
                        formik.handleSubmit();
                        GAAndFBEventsHandler(
                          gaCategory.editProfile,
                          content?.btn
                        );
                      }}
                      sx={{ minWidth: "108px !important" }}
                      loading={loadingBtn}
                    >
                      {content?.save_btn}
                    </ButtonCust>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          ) : (
            <Box className={styles.profile_email_main}>
              <Box className={styles.profile_email_block}>
                <Box className={styles.profile_email_head}>
                  <Box
                    className={styles.back_button}
                    onClick={handleBackEmailForm}
                  >
                    <ArrowBackIcon className={styles.arrow_icon} />
                  </Box>
                  <Box className={styles.profile_email_changeHead}>
                    {content?.change_email}
                  </Box>
                </Box>
                <Box className={styles.profile_email_content} px={"20px"}>
                  <Grid
                    container
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid item xs={7} sm={5} md={4}>
                      <TextFieldCust
                        name="newEmail"
                        value={emailForm.values.newEmail}
                        label={content?.new_email}
                        onChange={emailForm.handleChange}
                        error={emailForm.errors?.newEmail ? true : false}
                        helperText={<>{emailForm.errors?.newEmail}</>}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={styles.profile_email_text}>
                    <Grid
                      item
                      className={styles.profile_email_note}
                      xs={10}
                      sm={8}
                      md={7}
                    >
                      {content?.change_email_desc1}
                      {formik.values.email}
                      {content?.change_email_desc2}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: "30px",
                    }}
                  >
                    <ButtonCust
                      onClick={() => {
                        emailForm.handleSubmit();
                        GAAndFBEventsHandler(
                          gaCategory.editProfile,
                          content?.btn1
                        );
                      }}
                      loading={loadingEmailBtn}
                    >
                      {content?.btn1}
                    </ButtonCust>
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
          <ProfileDetailsDialog
            content={content}
            open={state.open}
            dialogType={state.dialogType}
            onClick={handleSave}
            onClose={handleClose}
          />
        </Paper>
      </Box>
      {subOrUnsubConfirmModal && (
        <SimpleModal
          isShowModal={subOrUnsubConfirmModal}
          onCloseModal={() => setSubOrUnsubConfirmModal(false)}
          showClose={true}
          sx={{
            width: {
              xs: "95%",
              sm: "85%",
              md: "90%",
              lg: "50%",
              xl: "30%",
            },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: "26px",
              mb: "25px",
              mt: "10px",
              fontFamily: "var(--font_family_Bold)",
              color: "var(--text_color_8)",
              textAlign: { xs: "center", sm: "unset" },
            }}
          >
            {profileDetails && profileDetails?.marketingComEnabled
              ? content?.unsub_modal_title
              : content?.sub_modal_title}
          </Typography>
          <Typography
            component="div"
            sx={{
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--text_color)",
              mb: "20px",
            }}
          >
            {profileDetails && profileDetails?.marketingComEnabled
              ? content?.unsub_modal_desc
              : content?.sub_modal_desc}
          </Typography>

          <ButtonCust
            sx={{ my: 1 }}
            variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            onClick={() =>
              subscriptionHandler(
                profileDetails && profileDetails?.marketingComEnabled
                  ? false
                  : true
              )
            }
            loading={subOrUnsubLoader}
          >
            {profileDetails && profileDetails?.marketingComEnabled
              ? content?.unsub_modal_btn
              : content?.sub_modal_btn}
          </ButtonCust>
          <Typography
            component={"span"}
            sx={labelUpdateStyle}
            onClick={() => setSubOrUnsubConfirmModal(false)}
          >
            {profileDetails && profileDetails?.marketingComEnabled
              ? content?.unsub_modal_cancel
              : content?.sub_modal_cancel}
          </Typography>
        </SimpleModal>
      )}
    </>
  );
};

export default ProfileDetails;
