import styles from "./S1_NegResultBottom.module.scss";
import { Box, Typography } from "@mui/material";
import { ButtonCust } from "../../widgets";
import Config from "../../../config/env.config.json";
import { PlanCard } from "../PlanCard";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { useAppSelector } from "../../../app/hooks";
import { gaEventTypes, gaScreenNames } from "../../../Types/GoogleAnalytics";
import { useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { RoutingURLs } from "../../../config/RoutingURLs";
const T2_CompareResult = (p: any) => {
  const props = p?.content;

  const navigate = useNavigate();

  const GAAndFBEventsHandler = (categyName: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.BillCalculator,
      category: categyName,
      buttonValue: value,
    });
  };

  const onClickHandler = (
    navigationPath: string,
    scrollToPath: string,
    categyName: string,
    gaName: string
  ) => {
    navigate(navigationPath);
    setTimeout(function () {
      scroller.scrollTo(scrollToPath, {
        duration: 1000,
        delay: 0,
        offset: -85,
        smooth: true,
      });
    }, 1500);
    GAAndFBEventsHandler(categyName, gaName);
  };

  const { planData, saveAmt } = useAppSelector(
    (state: any) => state.billCalculator
  );
  return (
    <>
      <Box
        sx={{
          width: { md: "60%", sm: "(100%-60px)", xs: "90%" },
        }}
        margin={{ sm: "0px auto", xs: "20px" }}
      >
        <Box
          textAlign={{ xs: "center" }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mt: { xs: "50px", sm: "45px" },
          }}
        >
          <Typography
            variant="h3"
            component={"h3"}
            fontSize="23px"
            className={styles.page_title}
          >
            {props.title}
          </Typography>
          <Typography
            variant="body1"
            className={styles.sub_title}
            sx={{ lineHeight: "30px" }}
          >
            {props.sub_title}
          </Typography>
          <Box className={styles.page_line}></Box>
        </Box>
        <PlanCard content={props} plan={planData} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            fontSize: "14px",
            fontFamily: "var(--font_family_Medium)",
            fontWeight: "500",
          }}
          margin={{
            xs: "0 0 30px",
          }}
        >
          {planData?.addLineSplitPrice ? (
            <>
              {props?.save_text}&nbsp;
              <Box
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  color: "var(--text_color)",
                  fontSize: "14px",
                  fontWeight: "var(--font_weight_2)",
                }}
                component="span"
              >
                {GetCurrencyFixedToTwo(saveAmt, planData?.currency)}&nbsp;
                {props?.per_line}
              </Box>
              &nbsp;{props?.every_line}
            </>
          ) : (
            <>
              {props?.add_line}&nbsp;
              <Box
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  color: "var(--text_color)",
                  fontSize: "14px",
                  fontWeight: "var(--font_weight_2)",
                }}
                component="span"
              >
                {GetCurrencyFixedToTwo(saveAmt, planData?.currency)}
                {props?.per_month_short}
              </Box>
            </>
          )}
        </Box>
        <Box className={styles.btn}>
          <ButtonCust
            style={{ width: "100%" }}
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={() => {
              navigate(RoutingURLs.checkout);
            }}
          >
            {props.btn_text}
          </ButtonCust>
        </Box>
        <Box
          fontWeight="700"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            color: "var(--primary_color)",
            fontSize: "14px",
            fontFamily: "var(--font_family_Bold)",
            fontWeight: "var(--font_weight_5)",
            cursor: "pointer",
          }}
          margin={{
            xs: "25px 0px",
          }}
          onClick={() =>
            onClickHandler(
              RoutingURLs.default,
              "bestCellPhonePlan",
              "Plan",
              props.view_plans
            )
          }
        >
          {props.view_plans}
        </Box>
        <Box
          className={styles.font_weight}
          textAlign={{ xs: "center" }}
          sx={{
            color: "var(--text_color)",
            fontSize: "14px",
            fontFamily: "var(--font_family_Medium)",
            marginBottom: "40px",
          }}
        >
          {props.compatibility_desc}
          <Box
            sx={{
              color: "var(--primary_color)",
              fontSize: "14px",
              fontFamily: "var(--font_family_Bold)",
              fontWeight: "var(--font_weight_5)",
              cursor: "pointer",
            }}
            component="span"
            onClick={() =>
              onClickHandler(
                "/imei-compatibility-check",
                "",
                "Compatibility",
                props.check_compatibility
              )
            }
          >
            &nbsp; {props.check_compatibility}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default T2_CompareResult;
