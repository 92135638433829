import { useEffect, useState } from "react";
import { Cpbdp } from "./CPBDP";
import { NotificationCust } from "../../widgets";
import { DataDetails } from "./DataDetails";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { ManageLines } from "./ManageLines";
import { ViewCredits } from "./ViewCredits";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import PlansResponse from "../../../config/data.json";
import {
  configInfoCall,
  customerGroup,
  getCC,
} from "../../../features/account/accountSlice";
import { GetCustomer } from "../../../features/checkout/checkoutSlice";
import { accountLandingPageSectionContent } from "../../../features/strapi/accountSlice";
import { Store } from "react-notifications-component";
import { Typography } from "@mui/material";

export const LandingPage = () => {
  const { configInfo, group, getCCDetails } = useAppSelector(
    (state: any) => state.account
  );
  const { accountLandingPageContent } = useAppSelector(
    (state: any) => state.strapi.account
  );
  const { activateLineStatus, activationStatus } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const [landingPageContent, setLandingPageContent] = useState<any>(null);
  const dispatch = useAppDispatch();
  const [currentSection, setCurrentSection] = useState<any>("");
  const [userRecurringTopUp, setUserRecurringTopUp] = useState<any>(null);
  const [primaryUser, setPrimaryUser] = useState<any>(null);
  const [topupSet, setTopupSet] = useState(false);
  const [topup, setTopup] = useState<any>(null);
  const [unlimited, setUnlimited] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [mainCard, setMainCard] = useState<any>(null);
  const [isCardExpired, setIsCardExpired] = useState(false);
  const [selectedLine, setSelectedLine] = useState<any>(null);
  const [showCardChangeModal, setShowCardChangeModal] = useState(false);
  const tabsContent = [
    { id: 1, name: "Change Plan" },
    { id: 2, name: "Buy Data Pack" },
  ];
  const [activeTab, setActiveTab] = useState(tabsContent[0].id);
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = JSON.parse(currentdetails);
  useEffect(() => {
    const activePlan = currentdetails?.reachPlanId;
    const selectedCurrentPlan = PlansResponse.data.filter(
      (plan) => plan.name === activePlan
    );
    setUnlimited(selectedCurrentPlan[0]?.isUnlimited);
    dispatch(
      getCC({
        customerId: currentdetails && currentdetails.id,
      })
    );

    dispatch(
      GetCustomer({
        username: encodeURIComponent(currentdetails && currentdetails.emailId),
      })
    );
    if (currentdetails) {
      setUser(currentdetails);
    }
    dispatch(
      customerGroup({
        groupId: currentdetails && currentdetails?.groupId,
      })
    );
    dispatch(configInfoCall({ topupPulseDetails: true }));

    getCache(
      "accountLandingPageSectionContent",
      accountLandingPageSectionContent,
      setLandingPageContent,
      dispatch
    );

    let addCardAccount = localStorage.getItem("addCardAccount");
    const addCardMl = localStorage.getItem("addCardManageLines");
    if (addCardAccount) {
      setCurrentSection("CPBDP");
    } else if (addCardMl) {
      setCurrentSection("ManageLines");
      localStorage.removeItem("addCardManageLines");
    }
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData(
      "accountLandingPageSectionContent",
      accountLandingPageContent
    );
  }, [accountLandingPageContent]);

  useEffect(() => {
    if (
      currentdetails?.isThrottled &&
      (accountLandingPageContent || landingPageContent)
    ) {
      if (currentdetails?.throttleReason === 1) {
        NotificationCust({
          message: landingPageContent
            ? landingPageContent?.bill_due_throttle
            : accountLandingPageContent?.bill_due_throttle,
          type: "info",
          duration: 0,
          showIcon: true,
          id: "dataThrottle",
        });
      } else if (currentdetails?.throttleReason === -1) {
        NotificationCust({
          message: landingPageContent
            ? landingPageContent?.out_data
            : accountLandingPageContent?.out_data,
          type: "info",
          duration: 0,
          showIcon: true,
          id: "dataThrottle",
        });
      } else if (currentdetails?.throttleReason === 0) {
        NotificationCust({
          message: landingPageContent
            ? landingPageContent?.overuse_data
            : accountLandingPageContent?.overuse_data,
          type: "info",
          duration: 0,
          showIcon: true,
          id: "dataThrottle",
        });
      }
    }
    return () => {
      Store.removeNotification("dataThrottle");
    };
  }, [accountLandingPageContent, landingPageContent]);
  useNonInitialEffect(() => {
    sessionStorage.setItem("accountPageCurrentSection", currentSection);
    return () => sessionStorage.removeItem("accountPageCurrentSection");
  }, [currentSection]);

  useNonInitialEffect(() => {
    if (getCustomer) {
      setUser(getCustomer);
      dispatch(
        customerGroup({
          groupId: getCustomer && getCustomer?.groupId,
        })
      );
    }
  }, [getCustomer]);

  useNonInitialEffect(() => {
    if (getCCDetails && getCCDetails?.length > 0) {
      let autoPayEnabledCard: any =
        getCCDetails?.length > 0 &&
        getCCDetails?.find((el: any) => el.isAutoPay === true);
      if (autoPayEnabledCard) {
        setMainCard(autoPayEnabledCard);
        let today, cardExpiryDay;
        let exMonth = autoPayEnabledCard?.dateOfExpiry?.substring(0, 2);
        let exYear = autoPayEnabledCard?.dateOfExpiry?.substring(2);
        exYear = exYear && `20${exYear}`;
        today = new Date();
        cardExpiryDay = new Date();
        cardExpiryDay.setFullYear(exYear, exMonth, 1);
        if (cardExpiryDay < today) {
          setIsCardExpired(true);
        } else {
          setIsCardExpired(false);
        }
      }
    }
  }, [getCCDetails]);

  useNonInitialEffect(() => {
    if (group && group.status === "SUCCESS" && group.data) {
      let primary = group?.data?.find((el: any) => el?.isPrimary === true);
      let topup = group?.data?.find((el: any) => el?.autoTopUpStatus === 1);
      let userStatus: any = [];
      group?.data?.map((el: any) => {
        if (el.status === 2) {
          userStatus.push({
            userId: el.id,
            status: el.autoTopUpStatus === 1 ? true : false,
          });
        }
      });
      setUserRecurringTopUp(userStatus);
      setPrimaryUser(primary);
      setTopupSet(topup ? true : false);
    }
  }, [group]);
  const { topupPulseDetails } = configInfo || {};
  switch (currentSection) {
    case "CPBDP":
      return (
        <Cpbdp
          setCurrentSection={setCurrentSection}
          topupPulseDetails={topupPulseDetails}
          unlimited={unlimited}
          setUnlimited={setUnlimited}
          user={user}
          setUser={setUser}
          setOpen={setOpen}
          setTopup={setTopup}
          mainCard={mainCard}
          setShowCardChangeModal={setShowCardChangeModal}
          showCardChangeModal={showCardChangeModal}
          isCardExpired={isCardExpired}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabsContent={tabsContent}
          selectedLine={selectedLine}
          setSelectedLine={setSelectedLine}
        />
      );
    case "ManageLines":
      return <ManageLines setCurrentSection={setCurrentSection} />;
    case "ViewCredits":
      return <ViewCredits setCurrentSection={setCurrentSection} />;
    default:
      return (
        <DataDetails
          setCurrentSection={setCurrentSection}
          topupPulseDetails={topupPulseDetails}
          userRecurringTopUp={userRecurringTopUp}
          primaryUser={primaryUser}
          topupSet={topupSet}
          unlimited={unlimited}
          user={user}
          topup={topup}
          setTopup={setTopup}
          group={group}
          setUserRecurringTopUp={setUserRecurringTopUp}
          setOpen={setOpen}
          open={open}
          setActiveTab={setActiveTab}
          tabsContent={tabsContent}
          isCardExpired={isCardExpired}
          setSelectedLine={setSelectedLine}
        />
      );
  }
};
