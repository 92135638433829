import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import styles from "./ActivationStep4.module.scss";
import { LinearProgressBarCust, ButtonCust } from "../../../widgets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { activateLine } from "../../../../features/activation/activationSlice";
import { HelpSupportModal } from "../ActivationStep2/SupportModal";
import { ActivationFailedModal } from "./ActivationFailedModal";
import Config from "../../../../config/env.config.json";
import { ActivationIsMnp } from "./ActivationIsMnp";
import { updateSecondaryUser } from "../../../../features/checkout/checkoutSlice";

export const ActivationStep4 = (props: any) => {
  const { handleStep, currentLine, content, modalContent } = props;
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [openSupportDialog, setOpenSupportDialog] = useState(false);
  const [activateBtnLd, setActivateBtnLd] = useState(false);
  const [showActionIsMnp, setShowActionIsMnp] = useState(false);
  const navigate = useNavigate();

  const openSupportModal = () => {
    setShowModal(false);
    setOpenSupportDialog(true);
  };

  const closeActivationFailModal = () => {
    setShowModal(false);
  };

  const onCloseSupportDialog = () => {
    setShowModal(true);
    setOpenSupportDialog(false);
  };

  const { step4, step_txt, sp4_title, c_sup_title } = content;
  const { id, reachPlanId, imei, make, model, isMNP, mnpInfo } =
    currentLine || {};
  const { operator, oldZip, oldPassword, oldNumber, acctNum } = mnpInfo || {};
  const simID = localStorage.getItem("simId");
  const activateHandler = () => {
    setActivateBtnLd(true);
    const newNumPy: any = {
      customerId: id,
      reachPlanId: reachPlanId,
      iccId: simID,
      imei: imei,
      make: make,
      model: model,
      skipHold: true,
    };
    const mnpPy = {
      ...newNumPy,
      skipHold: false,
      isPort: true,
      numberToPort: oldNumber,
      oldCarrier: operator,
      oldCarrierAccountNumber: acctNum,
      password: oldPassword,
      oldZip: oldZip,
      portUpdate: false,
    };
    dispatch(activateLine({ payload: isMNP ? mnpPy : newNumPy })).then(
      (res: any) => {
        activateLineResults(res);
      }
    );
  };
  const activateLineResults = (res: any) => {
    if (res?.payload?.status === "FAILURE") {
      setShowModal(true);
      setActivateBtnLd(false);
    } else if (res?.payload?.data?.status === "SUCCESS") {
      if (isMNP) {
        setShowActionIsMnp(true);
      } else {
        redirection();
      }
    } else {
      setActivateBtnLd(false);
    }
  };
  const redirection = () => {
    dispatch(updateSecondaryUser(null));
    navigate("/post-activation-home");
    localStorage.removeItem("secondaryCustomerDetail");
    localStorage.removeItem("simId");
  };

  const lineDetails = (
    imgName: any,
    altName: string,
    labelText: string,
    labelVal: any
  ) => {
    return (
      <Grid item container sm={5} xs={6} py={1.5}>
        <Box display="flex" alignItems="center">
          <Box
            component="img"
            src={imgName}
            className={styles.icon_size}
            alt={altName}
          />
          <Grid item container className={styles.labels_grid}>
            <Typography className={styles.labels} textOverflow="break-word">
              {" "}
              {labelText}
            </Typography>
            <Typography className={styles.details}> {labelVal}</Typography>
          </Grid>
        </Box>
      </Grid>
    );
  };
  return (
    <Box className={styles.step4_main}>
      <Box className={styles.heading_container}>
        <ArrowBackIcon
          sx={{ ml: { md: "30px", sm: "25px", xs: "4%" } }}
          className={styles.arrow_icon}
          onClick={() => (!showActionIsMnp ? handleStep(false) : null)}
        />
      </Box>
      {showActionIsMnp ? (
        <ActivationIsMnp
          {...props}
          redirection={redirection}
          step4Content={content}
        />
      ) : (
        <>
          <Box className={styles.heading_container}>
            <Typography className={styles.title_main}>{sp4_title}</Typography>
          </Box>
          <Box className={styles.stepper_content}>
            <Typography className={styles.stepper_txt}>{step_txt}</Typography>
            <Box width={{ xs: "90%", sm: "50%", md: "35%" }} mb="25px">
              <LinearProgressBarCust value={100} variant={"determinate"} />
            </Box>
          </Box>
          <Box
            className={styles.activation_box}
            width={{ md: "60%", xs: "85%", sm: "90%" }}
            p={{ xs: "30px 15px", md: "30px" }}
            alignItems="center"
          >
            <Box className={styles.activation_final_heading}>
              {step4?.title}
            </Box>
            <Box className={styles.activation_final_sub_heading}>
              {step4?.desc}
            </Box>

            <Grid
              item
              container
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              md={8.5}
              sm={9}
              xs={12}
              mx="auto"
              px={0}
            >
              {lineDetails(
                step4?.dev_img?.data?.attributes?.url,
                step4?.dev_img?.data?.attributes?.alternativeText,
                step4?.dev,
                currentLine?.model
              )}
              {lineDetails(
                step4?.devId_img?.data?.attributes?.url,
                step4?.devId_img?.data?.attributes?.alternativeText,
                step4?.devId,
                currentLine?.imei
              )}
              {isMNP &&
                lineDetails(
                  step4?.cur_no_img?.data?.attributes?.url,
                  step4?.cur_no_img?.data?.attributes?.alternativeText,
                  step4?.cur_no,
                  currentLine?.model
                )}
              {lineDetails(
                step4?.simId_img?.data?.attributes?.url,
                step4?.simId_img?.data?.attributes?.alternativeText,
                step4?.simId,
                simID
              )}
              {isMNP &&
                lineDetails(
                  step4?.acc_num_img?.data?.attributes?.url,
                  step4?.acc_num_img?.data?.attributes?.alternativeText,
                  step4?.acc_num,
                  acctNum
                )}

              {isMNP &&
                lineDetails(
                  step4?.pin_img?.data?.attributes?.url,
                  step4?.pin_img?.data?.attributes?.alternativeText,
                  step4?.pin,
                  oldPassword
                )}

              {isMNP &&
                lineDetails(
                  step4?.zip_code_img?.data?.attributes?.url,
                  step4?.zip_code_img?.data?.attributes?.alternativeText,
                  step4?.zip_code,
                  oldZip
                )}
            </Grid>

            <Box className={styles.activate_button}>
              <ButtonCust
                sx={{ width: { xs: "200px" } }}
                onClick={() => activateHandler()}
                loading={activateBtnLd}
                variantType={Config.PRIMARY_BUTTON_TYPE}
              >
                {step4?.act_btn}
              </ButtonCust>
            </Box>
          </Box>
        </>
      )}
      {showModal && (
        <ActivationFailedModal
          showModal={showModal}
          openSupportModal={openSupportModal}
          closeActivationFailModal={closeActivationFailModal}
          content={content}
          currentLine={currentLine}
        />
      )}
      <HelpSupportModal
        open={openSupportDialog}
        onClose={() => onCloseSupportDialog()}
        title={c_sup_title}
        showClose={false}
        showBackBtn={true}
        content={modalContent}
      />
    </Box>
  );
};
