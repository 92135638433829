import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import { object, string } from "yup";
import {
  Divider,
  TextFieldCust,
  ButtonCust,
  SimpleModal,
} from "../../../widgets";
import styles from "./NewNumberModal.module.scss";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { refreshZipcode } from "../../../../features/activation/activationSlice";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../../../config/env.config.json";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";

type InewNumberProps = {
  open: boolean;
  onClose: any;
  content: any;
  handleSubmit: any;
  numChangeHandler: any;
  currentLine: any;
};

export const NewNumberModal = (props: InewNumberProps) => {
  const { open, onClose, handleSubmit, numChangeHandler, currentLine, content } = props;
  const dispatch = useAppDispatch();

  const { zipCodeStatus, zipCodeLoader } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const { title, desc, zc_ph, zc_btn, msgs } = content?.zp_md;
  const { isValid } = zipCodeStatus || {};
  // formik
  interface formikintitaltypes {
    zipCode: string;
  }
  const numberFieldData: formikintitaltypes = {
    zipCode: currentLine?.zipcode || "",
  };
  const zipcodeSchema = object({
    zipCode: string().trim().min(5, msgs?.empty_msg),
  });
  const formik = useFormik({
    initialValues: numberFieldData,
    validationSchema: zipcodeSchema,
    onSubmit: (values: any) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
  });
  const zipcodeChangeHandler = (e: any) => {
    numChangeHandler(e, formik);
    isValid === false && dispatch(refreshZipcode());
  };
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.activation,
      category: category,
      buttonValue: value,
    });
  };

  return (
    <SimpleModal
      isShowModal={open}
      onCloseModal={onClose}
      showClose={true}
      className={styles.new_num_modal}
      sx={{
        borderRadius: "4.8px",
        maxWidth: { xs: "95%", sm: "500px" },
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Box className={styles.dialog_main}>
        <Typography className={styles.dialog_heading}>{title}</Typography>
        <Divider />
        <Typography
          className={styles.dialog_text}
          width={{ xs: "100%", sm: "75%" }}
        >
          {desc}
        </Typography>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          className={styles.textField_limit_container}
        >
          <Box sx={{ width: { xs: "100%", sm: "75%" }, alignSelf: "center" }}>
            <TextFieldCust
              placeholder={zc_ph}
              maxlength={5}
              name="zipCode"
              value={formik.values.zipCode}
              onChange={(e) => zipcodeChangeHandler(e)}
              error={formik.errors?.zipCode || isValid === false ? true : false}
              helperText={
                formik.errors?.zipCode ||
                (isValid === false && msgs?.invalid_msg)
              }
            />
          </Box>
          <Typography
            sx={{ alignSelf: "center", width: { xs: "100%", sm: "75%" } }}
            className={styles.limit_text}
          >
            {formik.values.zipCode?.length}/5
          </Typography>
        </Box>
        <ButtonCust
          sx={{ padding: "13px 23px", height: "45px !important", mt: "10px" }}
          onClick={() => {
            formik.handleSubmit();
            GAAndFBEventsHandler(gaCategory.activation, "Check ZIP Code");
          }}
          disabled={
            formik.values?.zipCode.length === 0 ? true : !formik?.isValid
          }
          loading={zipCodeLoader}
        >
          {zc_btn}
        </ButtonCust>
      </Box>
    </SimpleModal>
  );
};
