import { Grid, Modal, Box } from "@mui/material";
import {
  AccountCircle,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { useState } from "react";
import styles from "./HeaderModal.module.scss";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";

interface ModalProps {
  display: boolean;
  onClose: any;
  headerContent: any;
  handleNavigation: any;
  authUser?: any;
  isCheckoutHeader?: boolean;
  primaryHeaders?: Array<any>;
  secondaryHeaders?: Array<any>;
  loginItem: any;
  logoutHandler?: any;
}
interface LinkItemI {
  children: any;
  onClickItem: any;
  showActiveIndicator: boolean;
  addClass?: any;
}
const {
  link_item,
  active_link_item,
  header_modal,
  dialog_container,
  header_container,
  secondary_header,
  secondary_header_text_style,
} = styles;

const LinkItem = (props: LinkItemI) => {
  const { children, onClickItem, showActiveIndicator, addClass } = props;
  return (
    <Box
      className={
        showActiveIndicator
          ? `${link_item} ${active_link_item} ${addClass}`
          : `${link_item} ${addClass}`
      }
      pt={3.7}
      onClick={onClickItem}
    >
      {children}
    </Box>
  );
};
const ExpandableLinkItem = (props: any) => {
  const { handleToggleItem, itemName, menuItems, isToggeledItem, closeMenu } =
    props;
  return (
    <>
      <Box
        className={
          menuItems
            .map((item: any) => item.navigationPath)
            .includes(window.location.pathname)
            ? `${link_item} ${active_link_item}`
            : link_item
        }
        onClick={handleToggleItem}
        pt={3.7}
      >
        {itemName}
        {isToggeledItem ? (
          <KeyboardArrowUp onClick={closeMenu} />
        ) : (
          <KeyboardArrowDown />
        )}
      </Box>
      {isToggeledItem && (
        <Box>
          {menuItems.length > 0 &&
            menuItems.map((item: any, index: number) => {
              return (
                <Box
                  key={index}
                  className={
                    window.location.pathname === item.navigationPath
                      ? `${link_item} ${active_link_item}`
                      : link_item
                  }
                  pt={3.7}
                  onClick={item.action}
                >
                  {item.label}
                </Box>
              );
            })}
        </Box>
      )}
    </>
  );
};
export const HeaderModal = (props: ModalProps) => {
  const { seoData } = useAppSelector(
    (state: any) => state?.strapi?.commonPage || {}
  );
  const {
    display,
    onClose,
    headerContent = {},
    handleNavigation,
    authUser,
    isCheckoutHeader,
    primaryHeaders,
    secondaryHeaders,
    loginItem,
    logoutHandler,
  } = props;
  const [selectedItem, setSelectedItem] = useState<string | null>("");
  const navigate = useNavigate();
  const renderHeaderItemContent = (
    item: any,
    index: number,
    showActiveIndicator: boolean = true,
    addClass?: any
  ) => {
    if (item.subMenu && item.subMenu.length > 0) {
      const menuActionItems = item.subMenu.map((subItem: any) => ({
        label: subItem.itemName,
        action: () => {
          setSelectedItem(null);
          handleNavigation(subItem);
        },
        navigationPath: subItem.navigationPath,
      }));
      return (
        <ExpandableLinkItem
          key={`header-item-${index}`}
          itemName={item.itemName}
          isToggeledItem={item.itemName === selectedItem}
          handleToggleItem={() => {
            if (selectedItem) {
              setSelectedItem(null);
            } else {
              setSelectedItem(item.itemName);
            }
          }}
          handleNavigation={handleNavigation}
          menuItems={menuActionItems}
          closeMenu={() => {
            setSelectedItem(null);
          }}
          buttonId={`btn-key${index}`}
          menuId={`menu-key${index}`}
        />
      );
    } else {
      return (
        <LinkItem
          onClickItem={() => {
            handleNavigation(item);
          }}
          showActiveIndicator={
            showActiveIndicator &&
            window.location.pathname === item.navigationPath
          }
          key={`header-item-${index}`}
          addClass={addClass}
        >
          {item.itemName}
        </LinkItem>
      );
    }
  };
  return (
    <Modal
      open={display}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={header_modal}
      sx={{ ouline: "none", overflow: "scroll" }}
      disableAutoFocus
    >
      <Grid
        display="flex"
        flexDirection="column"
        className={header_container}
        justifyContent="space-between"
        padding="30px 30px 60px 40px"
      >
        <Grid>
          <Close
            sx={{
              float: "right",
              height: "14px",
              width: "14px",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
        </Grid>
        <Grid
          container
          display="flex"
          flexDirection="column"
          className={dialog_container}
        >
          <Grid item>
            {(primaryHeaders &&
              primaryHeaders.map((headerItem: any, index: number) => {
                return renderHeaderItemContent(headerItem, index);
              })) ||
              null}
          </Grid>
          <Grid item className={secondary_header}>
            {(secondaryHeaders &&
              secondaryHeaders?.map((headerItem: any, index: number) =>
                renderHeaderItemContent(
                  headerItem,
                  index,
                  false,
                  secondary_header_text_style
                )
              )) ||
              null}
            {isCheckoutHeader ? (
              <>
                <AccountCircle style={{ color: "var(--text_color)" }} />
                <Box className={styles.label}>
                  {authUser?.displayName?.split(" ")[0]}
                </Box>
                <Box
                  className={styles.label}
                  mt="30px"
                  onClick={() => navigate(RoutingURLs.home)}
                >
                  {seoData?.brandName}
                </Box>
              </>
            ) : null}
            {authUser
              ? loginItem?.subMenu?.map((sItem: any) => (
                  <LinkItem
                    onClickItem={() => {
                      handleNavigation(sItem);
                      if (sItem.itemName.toLowerCase() === "logout") {
                        logoutHandler(RoutingURLs.home);
                      }
                    }}
                    showActiveIndicator={
                      false //window.location.pathname === sItem.navigationPath
                    }
                    key={`sub-header-item-${sItem.id}`}
                    addClass={secondary_header_text_style}
                  >
                    {sItem?.itemName}
                  </LinkItem>
                ))
              : headerContent?.nav_link1 && (
                  <LinkItem
                    onClickItem={() => {
                      handleNavigation(headerContent?.nav_link1);
                    }}
                    showActiveIndicator={false}
                    addClass={secondary_header_text_style}
                  >
                    {headerContent?.nav_link1?.itemName}
                  </LinkItem>
                )}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
