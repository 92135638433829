import { Typography, Box } from "@mui/material";
import { ButtonCust, SimpleModal } from "../../../widgets";
import config from "../../../../config/env.config.json";
import { displayPhoneNumberFormat } from "../../../../utils/commonFunctions/ReusableFunctions";

interface IDialogProps {
  content: any;
  displayDialog: boolean;
  handleClose: Function;
  user: any;
  load: any;
  onClickButton: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const ReachModalMain = (props: IDialogProps) => {
  const { content, displayDialog, handleClose, user, load, onClickButton } =
    props;
  return (
    <>
      <SimpleModal
        isShowModal={displayDialog}
        onCloseModal={handleClose}
        showClose={true}
        sx={{ width: { xs: "90%" } }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              padding: "0px 0px 30px",
              fontSize: "22px",
              fontWeight: "var(--font_weight_4)",
              fontFamily: "var(--font_family)",
            }}
          >
            {content?.title}
          </Typography>

          <Box
            component="img"
            alignSelf={"center"}
            height={"62px"}
            width={"62px"}
            src={content?.userIcon}
          />
          <Typography
            sx={{
              pt: "5px",
              fontFamily: "var(--font_family_Medium)",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {user?.firstName}
          </Typography>
          <Typography
            sx={{
              pt: "5px",
              my: "7px",
              fontFamily: "var(--font_family_Medium)",
              fontSize: "14px",
            }}
          >
            {user?.phoneNumber
              ? displayPhoneNumberFormat(user.phoneNumber)
              : "-"}
          </Typography>
          <Typography
            sx={{
              pt: "5px",
              fontSize: "14px",
              color: "var(--text_color)",
              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {content.desc}
          </Typography>
          <ButtonCust
            variantType={config.PRIMARY_BUTTON_TYPE}
            sx={{ m: "10px auto", width: "200px", height: "45px" }}
            loading={load}
            onClick={onClickButton}
          >
            {content?.btn_txt}
          </ButtonCust>
        </Box>
      </SimpleModal>
    </>
  );
};
