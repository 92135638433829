import { Box } from "@mui/material";
import classes from "./IRILDServices.module.scss";
import { Divider } from "../widgets";
interface IRILDServicesProps {
  onClickButton: any;
  activeButton: string;
  ISContent: any;
}
export const IRILDServices = ({
  onClickButton,
  activeButton,
  ISContent,
}: IRILDServicesProps) => {
  const roamingContent =
    activeButton === "travel" ? ISContent?.r_type1 : ISContent?.r_type2;
  return (
    <div>
      <Box className={classes.btn_container}>
        <Box
          onClick={() => onClickButton("travel")}
          className={
            activeButton === "travel"
              ? `${classes.active_btn} ${classes.left}`
              : classes.inactive_btn
          }
        >
          {ISContent?.btn1_text}
        </Box>
        <Box
          onClick={() => onClickButton("call")}
          className={
            activeButton === "call"
              ? `${classes.active_btn} ${classes.right}`
              : `${classes.inactive_btn}`
          }
        >
          {ISContent?.btn2_text}
        </Box>
      </Box>
      <Box
        m="auto"
        display="flex"
        justifyContent="center"
        flexDirection={"column"}
      >
        <Box
          className={classes.description_secondary_txt_int}
          fontSize="20px"
          textAlign="center"
          mt={3}
        >
          {roamingContent?.description}
        </Box>
        <Divider styles={{ margin: "10px auto 20px" }} />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {roamingContent?.roaming_steps?.map((step: any) => (
            <Box
              display="flex"
              justifyContent="center"
              key={step.id}
              width={"fit-content"}
            >
              <Box className={classes.oval}></Box>
              <Box
                key={step.id}
                lineHeight={2.14}
                className={classes.description_secondary_txt_int}
                width={"390px"}
                maxWidth={"calc(100vw - 30px)"}
              >
                {step.item}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};
