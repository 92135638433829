import { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import styles from "../S1_ViewCredits.module.scss";
import { ButtonCust, SimpleModal, NotificationCust } from "../../../../widgets";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { PendingCreditListItem } from "./PendingCreditListItem";
import { getInTouchApi } from "../../../../../features/homepage/homepageSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { useNonInitialEffect } from "../../../../../utils/commonFunctions/UseNonInitialEffectHook";
import Config from "../../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import EnvConfig from "../../../../../config/env.config.json";
import { globalVal } from "../../../../../utils/globalValues/globalvalues";

type Props = {
  setCurrentCard: Function;
  currentCard?: string;
  pendingUsers: any;
  content: any;
};
export const PendingCredit = ({
  setCurrentCard,
  currentCard,
  pendingUsers,
  content,
}: Props) => {
  const [reminderModal, setReminderModal] = useState<any>({
    modal: false,
    item: "null",
  });
  const [success, setSuccess] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch: any = useAppDispatch();
  const { getInTouch }: any = useAppSelector((state: any) => state.homepage);

  useNonInitialEffect(() => {
    setSuccess(null);
    setIsLoading(false);
  }, [reminderModal]);

  useNonInitialEffect(() => {
    setSuccess(getInTouch?.data?.status);
  }, [getInTouch]);

  let modal_heading_text = content?.mdl_ttl?.replace(
    "{name}",
    reminderModal?.item?.name
  );
  let error_message = content?.err_msg?.replace(
    "{name}",
    reminderModal?.item?.name
  );
  let modal_text = content?.mdl_txt
    ?.replace("{name}", reminderModal?.item?.name)
    .replace(
      "{referral_amount}",
      GetCurrencyFixedToTwo(Config.REFERRER_DISC_IN_DOLLAR)
    );
  let success_message = content?.success_txt?.replace(
    "{emailId}",
    reminderModal?.item?.emailId
  );

  const handleReminderModal = () => {
    setReminderModal(false);
  };
  const errorReminderResponse = () => {
    setReminderModal(false);
    NotificationCust({
      message: error_message,
      type: "danger",
      showIcon: true,
      id: "accountPendingCreditsReminderFailure",
    });
  };
  const reminderHandler = () => {
    setIsLoading(true);
    dispatch(
      getInTouchApi({
        data: {
          emailId: reminderModal.item && reminderModal.item.emailId,
          hubspotMap: {
            email: reminderModal.item && reminderModal.item.emailId,
            referral_send_reminder: true,
            is_test: globalVal.hubspot_is_test,
            is_qa: globalVal.hubspot_is_qa,
          },
        },
      })
    ).then((result: any) => {
      if (!result.hasOwnProperty("error")) {
        setTimeout(handleReminderModal, 3000);
      } else {
        errorReminderResponse();
      }
    });
  };
  return (
    <Grid
      container
      item
      display="flex"
      flexDirection="column"
      alignItems="center"
      width={currentCard === "ReferralCards" ? { xs: "90%", sm: "48%" } : {}}
      justifyContent="center"
      className={styles.card_item}
    >
      <Typography
        className={styles.card_heading}
        fontWeight="var(--font_weight_2)"
        fontFamily="var(--font_family_Medium)"
        mb="20px"
      >
        {content?.ttl}
      </Typography>
      {pendingUsers?.length ? (
        <PendingCreditListItem
          setReminderModal={setReminderModal}
          viewMore={currentCard === "ReferralCards" ? true : false}
          pendingUsers={pendingUsers}
          content={content}
        />
      ) : (
        <Typography>{content?.no_content}</Typography>
      )}
      {pendingUsers?.length > EnvConfig?.NUMBER_OF_USERS_PENDING_REFERRALS ? (
        <Typography
          className={styles.view_more}
          onClick={() => setCurrentCard("PendingCreditsList")}
          display={currentCard === "ReferralCards" ? "block" : "none"}
        >
          {content?.btn_txt}
          <ArrowRightIcon sx={{ mt: "0px", height: "36px" }} />
        </Typography>
      ) : (
        ""
      )}
      {reminderModal && (
        <SimpleModal
          isShowModal={reminderModal.modal}
          onCloseModal={handleReminderModal}
          showClose={true}
          sx={{
            top: { xs: "40%", lg: "30%" },
            width: { xs: "90%", sm: "65%", md: "60%", lg: "50%" },
          }}
          style={{ borderRadius: "5px" }}
        >
          <Typography
            fontFamily="var(--font_family_Bold)"
            fontSize="22px"
            my="20px"
            fontWeight="var(--font_weight_0)"
            textAlign="center"
          >
            {modal_heading_text}
          </Typography>
          <Typography
            textAlign="center"
            fontFamily="var(--font_family_Medium)"
            fontSize="14px"
            mb="25px"
          >
            {modal_text}
          </Typography>
          {success?.toLowerCase() === "success" &&
          content?.tick_icon?.data?.attributes?.url ? (
            <Grid
              mx="auto"
              textAlign="center"
              display="flex"
              fontSize="14px"
              justifyContent="center"
              color="var(--primary_color)"
            >
              <Typography
                fontSize="14px"
                fontFamily="var(--font_family_semiBold)"
              >
                <Box
                  component="img"
                  src={content?.tick_icon?.data?.attributes?.url || ""}
                  mr="5px"
                  height="10px"
                  width="10px"
                  alt={
                    content?.tick_icon?.data?.attributes?.alternativeText || ""
                  }
                ></Box>
                {success_message}
              </Typography>
            </Grid>
          ) : (
            <ButtonCust onClick={reminderHandler} loading={isLoading}>
              {content?.mdl_btn_txt}
            </ButtonCust>
          )}
        </SimpleModal>
      )}
    </Grid>
  );
};
