import { validateEmail } from "../../../utils/commonFunctions/ValidateEmail";
import { ButtonCust, TextFieldCust } from "../../widgets";
import Config from "../../../config/env.config.json";
import { Box, Grid } from "@mui/material";
import styles from "./S3_LeadGeneration.module.scss";
import { LeadGenProps } from "./LeadGeneration";

const LeadGenerationComponent = (props: LeadGenProps) => {
  const {
    heading,
    sub_heading,
    place_holder,
    button_text,
    success_text,
    intouch_text,
    check_icon
  } = props.leadContent || {};

  return (
    props.leadContent && (
      <Grid
        container
        className={styles.lead_generation}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          py: 8.75,
        }}
      >
        <Box
          data-testid="title"
          className={styles.lead_generation_title}
          sx={{ fontSize: { xs: "22px", sm: "26px" } }}
        >
          {heading}
        </Box>
        <Box
          className={styles.lead_generation_subtitle}
          sx={{
            fontSize: { xs: "16px", sm: "20px" },
            textAlign: "center",
            mt: 2,
          }}
        >
          {sub_heading}
        </Box>
        <Box sx={{ my: 2.5 }} className={styles.lead_generation_line}></Box>
        {props.emailSharedCoverage ? (
          <>
            <Grid
              container
              xs={11}
              sm={6}
              md={4.5}
              lg={3.8}
              xl={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={styles.email_success}
              >
                <Box
                  component={"img"}
                  sx={{ width: "20px", height: "20px", mx: 0.5 }}
                  src={check_icon?.data?.attributes?.url}
                  alt="check_icon"
                />
                <Box
                  className={styles.email_success_text}
                  sx={{ fontSize: "18px" }}
                >
                  {success_text}
                </Box>
              </Box>
              <Box
                className={styles.keep_in_touch_text}
                sx={{ fontSize: "14px", textAlign: "center" }}
              >
                {intouch_text}
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              sx={{
                mb: 3,
                width:{xs:"299px", sm:"442px"}
              }}
              onKeyPress={(e: any) => {
                if (e.key === "Enter") {
                  props.submitHandler(e);
                }
              }}
            >
              <TextFieldCust
                value={props.email}
                label={place_holder}
                onChange={props.emailHandler}
                error={
                  (props.submitBtnClick && !props.email) ||
                  (props.submitBtnClick && props.email !== "" && !validateEmail(props.email))
                }
                helperText={props.submitBtnClick && props.helperTextHandler()}
              />
            </Grid>
            <Box>
              <ButtonCust
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={(e: any) => props.submitHandler(e)}
                loading={props.submitClick}
              >
                {button_text}
              </ButtonCust>
            </Box>
          </>
        )}
      </Grid>
    )
  );
};

export default LeadGenerationComponent;
