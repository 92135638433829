import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { brandFeaturesContent } from "../../../features/strapi/homePageSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import { Box, Typography } from "@mui/material";
import { ButtonCust } from "../../widgets";
import EnvConfig from "../../../config//env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";

const BrandFeaturesComponent = isTemplateExisits(Config.Home.BrandFeatures)
  ? require(`./${Config.Home.BrandFeatures.template}_BrandFeatures`).default
  : null;

export type brandFeaturesProps = {
  featureDetails?: any;
  buttonHandler?: any;
  cardChangeHandler?: any;
  currentCard?: any;
  cards?: number;
  renderCardDetails?: any;
  featureMainContent?: any;
};
export const BrandFeatures = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { brandFeatureContent, errorBrandFeature } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  const { featureDetails } = brandFeatureContent || {};
  const [cacheDataBrandFeat, setCacheDataBrandFeat] = useState<any>(null);
  const [currentCard, setCurrentCard] = useState();
  const [cards, setCards] = useState(0);
  const cardChangeHandler = (k: number) => {
    setCards(k);
    setCurrentCard(
      cacheDataBrandFeat ? cacheDataBrandFeat?.[k] : featureDetails?.[k]
    );
  };
  useEffect(() => {
    getCache(
      "BrandFeaturesHome",
      brandFeaturesContent,
      setCacheDataBrandFeat,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("BrandFeaturesHome", brandFeatureContent);
    setCurrentCard(
      cacheDataBrandFeat ? cacheDataBrandFeat?.[0] : featureDetails?.[0]
    );
  }, [featureDetails]);

  useNonInitialEffect(() => {
    cacheDataBrandFeat && setCurrentCard(cacheDataBrandFeat?.[0]);
  }, [cacheDataBrandFeat]);

  const buttonHandler = (item: any) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.bestFit,
      category: gaCategory.brandFeatures,
      buttonValue: item.buttonName,
    });
    if (item.redirectionPage === "_self") {
      navigate(item.navigationPath);
    } else {
      window.open(item.navigationPath, item.redirectionPage);
    }
  };
  //Common for all templates
  const renderCardDetails = (
    cardItem: any,
    classes: any,
    showImg?: boolean
  ) => {
    const { tab_mobile_icons, line } = classes || {};
    return (
      <>
        {showImg && (
          <Box
            component="img"
            src={cardItem?.tabMobileIcon?.data?.attributes?.url}
            alt={cardItem?.tabMobileIcon?.data?.attributes?.alternativeText}
            className={tab_mobile_icons}
            mb={2.5}
          ></Box>
        )}
        <Box textAlign={{ xs: "center", sm: "left" }}>
          <Typography
            variant="h3"
            sx={{
              lineHeight: "1.15",
            }}
          >
            {cardItem?.featureHeader}
          </Typography>
        </Box>
        <Box
          className={line}
          mb={2.5}
          mt={1}
          sx={{ width: 80, height: 5 }}
        ></Box>
        <Box
          textAlign={{ xs: "center", sm: "left" }}
          className="description_text"
        >
          {cardItem?.featureDescription}
        </Box>
        {cardItem?.showButton && (
          <Box sx={{ pt: 3.5 }}>
            <ButtonCust
              sx={{ width: "200px" }}
              variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
              onClick={() => buttonHandler(cardItem)}
            >
              {cardItem?.buttonName}
            </ButtonCust>
          </Box>
        )}
      </>
    );
  };
  return featureDetails || cacheDataBrandFeat ? (
    <BrandFeaturesComponent
      featureDetails={cacheDataBrandFeat ? cacheDataBrandFeat : featureDetails}
      buttonHandler={buttonHandler}
      cardChangeHandler={cardChangeHandler}
      currentCard={currentCard}
      cards={cards}
      renderCardDetails={renderCardDetails}
      featureMainContent={
        cacheDataBrandFeat ? cacheDataBrandFeat : brandFeatureContent
      }
    />
  ) : errorBrandFeature ? (
    <div>{errorBrandFeature}</div>
  ) : null;
};
