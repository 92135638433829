import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Box, Typography } from "@mui/material";
import { Menu } from "@mui/icons-material";
import styles from "./S2_Header.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { HeaderToggleMenuItem } from "./HeaderToggleMenuItem";
import { HeaderNavigateItem } from "./HeaderNavigateItem";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { scroller } from "react-scroll";
import { logout } from "../../../services/Firebase";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { GetCustomer } from "../../../features/checkout/checkoutSlice";
import { CustomerStatus } from "../../../enums";
import { HeaderProps } from "./Header";
import { isTemplateExisits } from "../../../utils/commonFunctions";
import Config from "../../../config/app.config.json";

const HeaderModalComponent = isTemplateExisits(Config.Header)
  ? require(`./${Config.Header.template}_HeaderModal`).default
  : null;

const HeaderComponent = ({ cacheHeader, cacheCustomHeader }: HeaderProps) => {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch: any = useAppDispatch();
  const { isRenderLogin, getCustomer } = useAppSelector(
    (state: any) => state.checkout
  );

  const [isButtonToggeled, setIsButtonToggeled] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [authUser, setAuthUser] = useState<any>(null);
  const [primaryHeaders, setPrimaryHeaders] = useState([]);
  const [secondaryHeaders, setSecondaryHeaders] = useState([]);
  const [loginItem, setLoginItem] = useState();
  useEffect(() => {
    return () => {
      setDisplayModal(false);
    };
  }, [location]);

  useEffect(() => {
    getAuthData();
  }, [isRenderLogin]);
  let customer: any = localStorage.getItem("customerDetail");
  customer = customer ? JSON.parse(customer) : null;
  useEffect(() => {
    getHeaders();
  }, [location, cacheHeader, cacheCustomHeader, getCustomer]);
  const { user_logo } = cacheCustomHeader;
  const renderHeaderItemContent = (
    item: any,
    index: number,
    showActiveIndicator: boolean = true,
    itemName?: string,
    customStyle?: any,
    showUserIcon?: boolean
  ) => {
    if (item?.subMenu && item?.subMenu.length > 0) {
      const headerItemName = item?.itemName || itemName?.trim()?.split(" ")[0];
      const menuActionItems = item?.subMenu.map((subItem: any) => ({
        label: subItem?.itemName,
        action: () => {
          setAnchorEl(null);
          setIsButtonToggeled("");
          handleNavigation(subItem);
          if (subItem.itemName.toLowerCase() === "sign out") {
            logout(RoutingURLs.home);
          }
        },
        navigationPath: subItem.navigationPath,
      }));
      return (
        <HeaderToggleMenuItem
          key={`header-item-${index}`}
          itemName={headerItemName}
          isToggeledItem={headerItemName === isButtonToggeled}
          anchorEl={anchorEl}
          onRequestOpenMenu={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            setIsButtonToggeled(headerItemName);
          }}
          menuItems={menuActionItems}
          closeMenu={() => {
            setAnchorEl(null);
            setIsButtonToggeled("");
          }}
          buttonId={`btn-key${index}`}
          menuId={`menu-key${index}`}
          headerStyle={customStyle}
          showArrowIcon={false}
          showActiveIndicator={showActiveIndicator}
          userIcon={showUserIcon ? user_logo : null}
        />
      );
    } else {
      return (
        <HeaderNavigateItem
          onClickItem={() => {
            handleNavigation(item);
          }}
          showActiveIndicator={
            showActiveIndicator &&
            window.location.pathname === item?.navigationPath
          }
          key={`header-item-${index}`}
          headerStyle={customStyle}
        >
          {item?.itemName}
        </HeaderNavigateItem>
      );
    }
  };
  const handleNavigation = (item: any) => {
    let customerDetails: any = localStorage.getItem("customerDetail");
    if (customerDetails) customerDetails = JSON.parse(customerDetails);

    if (item?.isNewWindow) {
      if (item?.itemName?.toLowerCase() === "contact us") {
        if (customerDetails) {
          let custAdd: any = [];
          for (const key in customerDetails?.addresses[1]) {
            if (key !== "name" && key !== "type" && key !== "phone")
              custAdd.push(customerDetails?.addresses[1][key]);
          }
          custAdd = custAdd.join(", ");
          window.open(
            `${item.navigationPath}?firstname=${customerDetails?.firstName}&lastname=${customerDetails?.lastName}&address=${custAdd}&reach_number=${customerDetails?.primaryNumber}&email=${customerDetails?.emailId}`,
            "_blank"
          );
        } else if (authUser) {
          let firstName = authUser?.displayName?.split(" ")[0],
            lastName = authUser?.displayName?.split(" ")[1],
            email = authUser?.email;
          window.open(
            `${item.navigationPath}?firstname=${firstName}&lastname=${lastName}&email=${email}`,
            "_blank"
          );
        } else window.open(item.navigationPath, "_blank");
      } else window.open(item.navigationPath, "_blank");
    } else {
      if (item?.itemName?.toLowerCase() === "plans") {
        navigate(item.navigationPath);
        setTimeout(function () {
          scroller.scrollTo("bestCellPhonePlan", {
            duration: 1000,
            delay: 0,
            offset: -100,
            smooth: true,
          });
        }, 1000);
      } else {
        navigate(item?.navigationPath);
      }
    }
  };
  const showHeaderModal = () => {
    setDisplayModal(true);
  };
  const hideHeaderModal = () => {
    setDisplayModal(false);
  };
  const getAuthData = () => {
    const auth = getAuth();
    /**
     * The onAuthStateChanged function will be executed when firebase auth gets changed
     * @param1 auth - Firebase configuration
     * @param2 callback function - @param1 user - logged user data
     */
    onAuthStateChanged(auth, (user: any) => {
      if (user?.displayName) {
        setAuthUser(user);
        dispatch(GetCustomer({ username: encodeURIComponent(user.email) }));
      }
    });
  };
  const isCheckoutHeader = location.pathname === RoutingURLs.checkout;
  const isMyAccountPage = location.pathname === RoutingURLs.myAccount;
  const isTrackOrderPage = location.pathname === RoutingURLs.trackOrder;
  const isActivationPage = location.pathname === RoutingURLs.activation;
  const { mobile_logo, web_logo, tab_logo } = cacheHeader || {};
  const getHeaders = () => {
    let loginHeaderItem = null,
      primaryItems = [],
      secondaryItems = [];
    if (isCheckoutHeader && !authUser) {
      secondaryItems = cacheHeader?.public_secondary_items;
      loginHeaderItem = cacheHeader?.login_item;
    } else if (isCheckoutHeader && authUser) {
      secondaryItems = cacheHeader?.pl_secondary_items;
      loginHeaderItem = cacheHeader?.login_item;
    } else if (isMyAccountPage) {
      primaryItems = cacheCustomHeader?.pra_primary_items;
      secondaryItems = cacheCustomHeader?.pra_secondary_items;
      loginHeaderItem = cacheCustomHeader?.pra_login_item;
    } else if (isActivationPage) {
      secondaryItems = cacheHeader?.pl_secondary_items;
      loginHeaderItem = cacheCustomHeader?.poa_login_item;
    } else if (!authUser) {
      primaryItems = cacheHeader?.public_primary_items;
      secondaryItems = cacheHeader?.public_secondary_items;
      loginHeaderItem = cacheHeader?.login_item;
    } else if (authUser && !customer) {
      primaryItems = cacheHeader?.pl_primary_items;
      secondaryItems = cacheHeader?.pl_secondary_items;
      loginHeaderItem = cacheHeader?.login_item;
    } else if (
      authUser &&
      customer?.isPrimary === false &&
      (customer?.status === CustomerStatus.ACTIVE ||
        customer?.status === CustomerStatus.PORTING_FAILED ||
        customer?.status === CustomerStatus.ACTIVATION_FAILED ||
        customer?.status === CustomerStatus.DISCONNECTED ||
        customer?.status === CustomerStatus.SUSPENDED)
    ) {
      primaryItems = cacheCustomHeader?.s_u_p_h_i;
      secondaryItems = cacheCustomHeader?.s_u_s_h_i;
      loginHeaderItem = cacheCustomHeader?.s_u_login_i;
    } else if (
      authUser &&
      customer?.simPaid && //As part of ETA direct flow change, Account, Service and Billing should be accessible post purchase even if customer is not active
      (customer?.status === CustomerStatus.INACTIVE ||
        customer?.status === CustomerStatus.ACTIVE ||
        customer?.status === CustomerStatus.PORTING_FAILED ||
        customer?.status === CustomerStatus.ACTIVATION_FAILED ||
        customer?.status === CustomerStatus.DISCONNECTED ||
        customer?.status === CustomerStatus.SUSPENDED)
    ) {
      primaryItems = cacheCustomHeader?.poa_primary_items;
      secondaryItems = cacheCustomHeader?.poa_secondary_items;
      loginHeaderItem = cacheCustomHeader?.poa_login_item;
    } else if (authUser && customer?.simPaid) {
      primaryItems = cacheCustomHeader?.pra_primary_items;
      loginHeaderItem = cacheCustomHeader?.pra_login_item;
      secondaryItems = cacheHeader?.pl_secondary_items;
    } else if (authUser && customer && !customer?.simPaid) {
      primaryItems = cacheHeader?.public_primary_items;
      secondaryItems = cacheHeader?.public_secondary_items;
      loginHeaderItem = cacheHeader?.login_item;
    }
    setPrimaryHeaders(primaryItems);
    setSecondaryHeaders(secondaryItems);
    setLoginItem(loginHeaderItem);
  };

  const iPadPreLoginItem: any = {
    itemName: cacheHeader?.nav_link1?.itemName,
    subMenu: [
      {
        id: 1,
        itemName: cacheHeader?.nav_link1?.itemName,
        navigationPath: cacheHeader?.nav_link1?.navigationPath,
        isNewWindow: false,
      },
    ],
  };

  return (
    <div className={styles.Header_Wrapper}>
      <AppBar
        /*position="fixed"*/
        className={`${styles.Header_Container}`}
        sx={{
          height:
            authUser &&
            customer?.simPaid &&
            (customer?.status === CustomerStatus.INACTIVE ||
              customer?.status === CustomerStatus.ACTIVE ||
              customer?.status === CustomerStatus.PORTING_FAILED ||
              customer?.status === CustomerStatus.ACTIVATION_FAILED ||
              customer?.status === CustomerStatus.DISCONNECTED ||
              customer?.status === CustomerStatus.SUSPENDED)
              ? "126px"
              : "76px",
        }}
      >
        {/* Should be displayed only for post activation */}
        {/* As Part of ETA direct flow change post Activation headers should be shown post purchase */}
        {authUser &&
          customer?.simPaid &&
          (customer?.status === CustomerStatus.INACTIVE ||
            customer?.status === CustomerStatus.ACTIVE ||
            customer?.status === CustomerStatus.PORTING_FAILED ||
            customer?.status === CustomerStatus.ACTIVATION_FAILED ||
            customer?.status === CustomerStatus.DISCONNECTED ||
            customer?.status === CustomerStatus.SUSPENDED) && (
            <Typography
              component="div"
              sx={{
                height: "50px",
                backgroundColor: "var(--primary_color)",
              }}
            >
              <Box
                className={styles.secondary_header}
                display="flex"
                alignItems="center"
                sx={{
                  color: "var(--white)",
                  fontFamily: "var(--font_family_Semibold)",
                  float: "right",
                  height: "50px",
                }}
              >
                {secondaryHeaders?.length > 0 &&
                  secondaryHeaders.map((headerItem: any, index: number) =>
                    renderHeaderItemContent(
                      headerItem,
                      index,
                      true,
                      undefined,
                      {
                        color: "var(--white)",
                      }
                    )
                  )}
                {authUser?.displayName ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    mr="15px"
                    sx={{
                      color: "var(--white)",
                      fontFamily: "var(--font_family_Semibold)",
                    }}
                  >
                    {renderHeaderItemContent(
                      loginItem,
                      1,
                      false,
                      authUser.displayName || "",
                      { color: "var(--white)" }
                    )}
                  </Box>
                ) : (
                  // !isCheckoutHeader &&
                  cacheHeader?.nav_link1 && (
                    <HeaderNavigateItem
                      onClickItem={() => {
                        handleNavigation(cacheHeader.nav_link1);
                      }}
                      showActiveIndicator={false}
                    >
                      {cacheHeader?.nav_link1?.itemName}
                    </HeaderNavigateItem>
                  )
                )}
              </Box>
            </Typography>
          )}

        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "76px",
            width: "100%",
          }}
          disableGutters
        >
          <Box pl={{ md: 3 }}>
            <Box
              component="img"
              src={web_logo?.data?.attributes?.url || ""}
              alt={web_logo?.data?.attributes?.alternativeText || ""}
              paddingLeft={"70px"}
              sx={{ display: { xs: "none", md: "block" }, cursor: "pointer" }}
              onClick={() => {
                navigate(RoutingURLs.home);
              }}
            ></Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginRight="240px"
            gap="140px"
          >
            {isCheckoutHeader ? (
              <Box className={styles.header_txt}>{cacheHeader?.header1}</Box>
            ) : null}
            {primaryHeaders?.length > 0 &&
              primaryHeaders.map((headerItem: any, index: number) => {
                return renderHeaderItemContent(
                  headerItem,
                  index,
                  true,
                  undefined,
                  {
                    color: "var(--primary_color)",
                    textTransform: "capitalize",
                    fontSize: "16px",
                    fontFamily: "var(--font_family_Medium)",
                  }
                );
              })}
          </Box>
          {/* //secondaryHeaders : should not be displayed for post activation only*/}
          {/* As Part of ETA direct flow change post Activation headers should be shown post purchase */}
          {
            // ((authUser && !customer) || !authUser) &&
            (!authUser ||
              (authUser && !customer) ||
              !customer?.simPaid ||
              customer?.status === CustomerStatus.ACTIVATION_IN_PROGRESS) && (
              <Box
                className={styles.secondary_header}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                textTransform="capitalize"
              >
                {secondaryHeaders?.length > 0 &&
                  secondaryHeaders.map((headerItem: any, index: number) =>
                    renderHeaderItemContent(headerItem, index)
                  )}
                {authUser?.displayName ? (
                  <Box display="flex" alignItems="center" mr="15px">
                    {renderHeaderItemContent(
                      loginItem,
                      1,
                      false,
                      authUser.displayName || ""
                    )}
                  </Box>
                ) : (
                  cacheHeader?.nav_link1 && (
                    <HeaderNavigateItem
                      onClickItem={() => {
                        handleNavigation(cacheHeader.nav_link1);
                      }}
                      showActiveIndicator={false}
                    >
                      {cacheHeader?.nav_link1?.itemName}
                    </HeaderNavigateItem>
                  )
                )}
              </Box>
            )
          }
        </Toolbar>
      </AppBar>
      <AppBar position="fixed" className={styles.d_view_port}>
        <Toolbar disableGutters sx={{ height: "100%" }}>
          <Box>
            <Menu className={styles.menu_icon} onClick={showHeaderModal} />
          </Box>
          <Box
            pl={1.2}
            sx={{ width: "90%", display: "flex", justifyContent: "center" }}
          >
            <Box
              component="img"
              src={tab_logo?.data?.attributes?.url || ""}
              alt={tab_logo?.data?.attributes?.alternativeText || ""}
              sx={{
                display: { xs: "none", sm: "block", lg: "none" },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(RoutingURLs.home);
              }}
            ></Box>
            <Box
              component="img"
              src={mobile_logo?.data?.attributes?.url || ""}
              alt={mobile_logo?.data?.attributes?.alternativeText || ""}
              height="33px"
              width="auto"
              sx={{ display: { xs: "block", sm: "none" }, cursor: "pointer" }}
              onClick={() => {
                navigate(RoutingURLs.home);
              }}
            ></Box>
          </Box>

          {authUser?.displayName ? (
            <Box display="flex" alignItems="center" mr="15px">
              {renderHeaderItemContent(
                loginItem,
                1,
                false,
                authUser?.displayName || "",
                { color: "var(--primary_color)" },
                true
              )}
            </Box>
          ) : (
            cacheHeader?.nav_link1 && (
              <Box display="flex" alignItems="center" mr="15px">
                {renderHeaderItemContent(
                  iPadPreLoginItem,
                  1,
                  false,
                  "",
                  { color: "var(--primary_color)" },
                  true
                )}
              </Box>
            )
          )}
        </Toolbar>
      </AppBar>
      <HeaderModalComponent
        display={displayModal}
        onClose={hideHeaderModal}
        headerContent={cacheHeader}
        handleNavigation={handleNavigation}
        authUser={authUser}
        primaryHeaders={primaryHeaders}
        secondaryHeaders={secondaryHeaders}
        loginItem={loginItem}
        isCheckoutHeader={isCheckoutHeader}
        cacheCustomHeader={cacheCustomHeader}
      />
    </div>
  );
};

export default HeaderComponent;
