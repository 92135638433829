import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
    serviceData: any;
    errorServiceData: any;
};

const initialState: InitialState = {
    serviceData: null,
    errorServiceData: null
};

// Generates pending, fulfilled and rejected action types
export const serviceContent = createAsyncThunk(
    "service/serviceContent",
    () => {
        return apiJunction.makeRequest({
            method: "get",
            url: `/${Config.PostActivationServices.ServicesDetails.template}${StrapiApiEndPoints.services}`,
            isStrapiApi: true,
        });
    }
);

const strapiServiceSlice = createSlice({
    name: "services",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(
            serviceContent.fulfilled,
            (state: InitialState, action: PayloadAction<any>) => {
                state.serviceData = action.payload?.data?.data?.attributes;
                state.errorServiceData = null;
            }
        );
        builder.addCase(serviceContent.rejected, (state: InitialState, action: any) => {
            state.serviceData = null;
            state.errorServiceData = action.error || "Something went wrong";
        });
    },
});

export default strapiServiceSlice.reducer;