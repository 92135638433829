import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";
import { InitialState, TaxTypes, addressType } from "./Types";

const getLocalStorage = (flag: number) => {
  let userDetails: any;
  if (flag === 1) {
    userDetails = localStorage.getItem("customerDetail");
  } else {
    userDetails = localStorage.getItem("secondaryCustomerDetail");
  }
  return JSON.parse(userDetails) || null;
};
const setLocalStorage = (action: any) => {
  const user2: any = localStorage.getItem("secondaryCustomerDetail") || null;
  //stops update if secondary line exists
  if (user2 === null) {
    localStorage.setItem(
      "customerDetail",
      JSON.stringify(action.payload?.data?.data)
    );
  }

  if (!action.payload?.data?.data?.isPrimary) {
    localStorage.setItem(
      "secondaryCustomerDetail",
      JSON.stringify(action.payload?.data?.data)
    );
  }
};
const initialState: InitialState = {
  loginNumberSuccess: null,
  errorLoginNumber: null,
  getInTouch: null,
  userData: {},
  resetPasswordData: null,
  errorResetPasswordData: null,
  stateCityByZip: null,
  errorStateCityByZipcode: null,
  getCustomer: null,
  errorGetCustomer: null,
  createCustomer: null,
  errorCreateCustomer: null,
  updateCustomer: null,
  errorUpdateCustomer: null,
  errorPlanTaxCharges: null,
  errorOneTimeSimTaxCharges: null,
  errorOneTimeShippingTaxCharges: null,
  updateCustomerSource: null,
  errorUpdateCustomerSource: null,
  coupon: null,
  errorCoupon: null,
  couponReserve: null,
  errorCouponReserve: null,
  eligibility: null,
  errorEligibility: null,
  isLoaded: false,
  isLoadedShippingTaxes: false,
  isLoadedPlanTaxCharges: false,
  formValues: {
    firstName: "",
    lastName: "",
    phone: "",
    shipmentPlan: "",
    isShippingSameAsBilling: true,
    billingAddress: {
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      type: addressType.billing,
    },
    shippingAddress: {
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      type: addressType.shipping,
    },
    autopay: true,
  },
  planDetails: {
    numberOfLines: null,
    selectedPlan: null,
  },
  oneTimeSIMTaxCharges: null,
  oneTimeShippingTaxCharges: null,
  planTaxCharges: null,
  referrelCoupon: null,
  buttonActionLoader: false,
  isRenderLogin: false,
  primaryUser: getLocalStorage(1),
  secondaryUser: getLocalStorage(2),
  errorGetCouponReserve: null,
  isPrimaryLineEsim: false,
  openAutopayConsent: false,
  updateMacSuccess: null,
  updateMacFailure: null,
  deviceTax: null,
  errDeviceTax: null,
  activityTypes: null,
  errActivityTypes: null,
  installTax: null,
  errInstallTax: null,
  troubleCallTax: null,
  errTroubleCallTax: null,
};
export const getActivityTypes = createAsyncThunk(
  "checkout/getActivityTypes",
  async () => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.activityTypes}`,
      });
      return response;
    } catch (err: any) {
      return err?.response?.data;
    }
  }
);
export const getInstallTaxCharges = createAsyncThunk(
  "checkout/getInstallTaxCharges",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.installation },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTroubleCallTaxCharges = createAsyncThunk(
  "checkout/getTroubleCallTaxCharges",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.troubleCall },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getDeviceTaxCharges = createAsyncThunk(
  "checkout/getDeviceTaxCharges",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.device },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPlanTaxCharges = createAsyncThunk(
  "checkout/getPlanTaxCharges",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.plan },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOneTimeSimTaxCharges = createAsyncThunk(
  "checkout/getOneTimeSimTaxCharges",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.sim },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOneTimeShippingTaxCharges = createAsyncThunk(
  "checkout/getOneTimeShippingTaxCharges",
  async (action: any, { rejectWithValue }) => {
    try {
      const response: any = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.shipment },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const UpdateCustomer = createAsyncThunk(
  "checkout/UpdateCustomer",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "put",
        url: `${ApiEndPoints.updateCustomer}${action.customerId}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const UpdateMacAddress = createAsyncThunk(
  "checkout/UpdateMacAddress",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "put",
        url: `${ApiEndPoints.updateMacAddress}${action.customerId}?macId=${action.iccId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const CreateCustomer = createAsyncThunk(
  "checkout/CreateCustomer",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.createCustomer}USA/customers`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const loginWithNumber = createAsyncThunk(
  "checkout/loginWithNumber",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.loginWithNumber}${action.loginNumber}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "checkout/resetPassword",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.resetPassword}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const GetCustomer = createAsyncThunk(
  "checkout/GetCustomer",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.customerDetails}${action.username}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const UpdateCustomerSource = createAsyncThunk(
  "checkout/UpdateCustomerSource",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "put",
        url: `${ApiEndPoints.updateCustomerSource}${action.customerId}`,
        body: action.data,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const Coupon = createAsyncThunk(
  "checkout/Coupon",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.coupon}${action.couponCode}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const CouponReserve = createAsyncThunk(
  "checkout/CouponReserve",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.couponReserve}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getCustomerCouponReserve = createAsyncThunk(
  "checkout/getCustomerCouponReserve",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.couponReserve}/${customerId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const Eligibility = createAsyncThunk(
  "checkout/Eligibility",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.eligibility}${action.emailId}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    storeLoginData: (state, action) => {
      state.userData = action.payload;
    },
    updatePlanDetails: (state, action) => {
      state.planDetails = Object.assign({}, state.planDetails, action.payload);
    },
    updateFormValues: (state, action) => {
      state.formValues = Object.assign({}, state.formValues, action.payload);
    },
    updateCouponDetails: (state, action) => {
      state.coupon = Object.assign({}, action.payload);
    },
    resetCoupon: (state) => {
      state.coupon = null;
    },
    setLoginStatus: (state) => {
      state.isRenderLogin = true;
    },
    updateSecondaryUser: (state, action) => {
      state.secondaryUser = action.payload;
    },
    updatePrimaryLineType: (state, action) => {
      state.isPrimaryLineEsim = action.payload;
    },
    resetDataGetCustomer: (state) => {
      localStorage.removeItem("customerDetail");
      state.getCustomer = null;
    },
    setOpenAutopayConsent: (state, action) => {
      state.openAutopayConsent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loginWithNumber.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loginNumberSuccess = action.payload.data;
        state.errorLoginNumber = null;
      }
    );
    builder.addCase(loginWithNumber.rejected, (state, action) => {
      state.loginNumberSuccess = null;
      state.errorLoginNumber = action.payload || "Something went wrong";
    });

    builder.addCase(
      resetPassword.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.resetPasswordData = action.payload.data;
        state.errorResetPasswordData = null;
      }
    );
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.resetPasswordData = null;
      state.errorResetPasswordData = action.payload;
    });
    builder.addCase(
      GetCustomer.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getCustomer = action.payload?.data?.data || null;
        state.errorGetCustomer = null;
        setLocalStorage(action);
        state.primaryUser = action.payload?.data?.data?.isPrimary
          ? action.payload?.data?.data
          : null;
      }
    );
    builder.addCase(GetCustomer.rejected, (state, action) => {
      state.getCustomer = null;
      state.errorGetCustomer = action.payload || "Something went wrong";
    });
    builder.addCase(
      CreateCustomer.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createCustomer = action.payload.data;
        state.errorCreateCustomer = null;
      }
    );
    builder.addCase(CreateCustomer.rejected, (state, action) => {
      state.createCustomer = null;
      state.errorCreateCustomer = action.payload;
    });
    builder.addCase(
      UpdateCustomer.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateCustomer = action.payload.data;
        state.errorUpdateCustomer = null;
      }
    );
    builder.addCase(UpdateCustomer.rejected, (state, action) => {
      state.updateCustomer = null;
      state.errorUpdateCustomer = action.payload;
    });
    builder.addCase(
      getInstallTaxCharges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.installTax = action.payload;
        state.errInstallTax = null;
      }
    );
    builder.addCase(
      getInstallTaxCharges.rejected,
      (state, action: PayloadAction<any>) => {
        state.installTax = null;
        state.errInstallTax = action.payload;
      }
    );
    builder.addCase(
      getTroubleCallTaxCharges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.troubleCallTax = action.payload;
        state.errTroubleCallTax = null;
      }
    );
    builder.addCase(
      getTroubleCallTaxCharges.rejected,
      (state, action: PayloadAction<any>) => {
        state.troubleCallTax = null;
        state.errTroubleCallTax = action.payload;
      }
    );

    builder.addCase(
      getDeviceTaxCharges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deviceTax = action.payload.data.data.totalTax;
        state.errDeviceTax = null;
      }
    );
    builder.addCase(
      getDeviceTaxCharges.rejected,
      (state, action: PayloadAction<any>) => {
        state.deviceTax = null;
        state.errDeviceTax = action.payload;
      }
    );
    builder.addCase(getPlanTaxCharges.pending, (state) => {
      state.isLoadedPlanTaxCharges = true;
    });
    builder.addCase(
      getPlanTaxCharges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.planTaxCharges = action.payload.data.data.totalTax;
        state.errorPlanTaxCharges = null;
        state.isLoadedPlanTaxCharges = false;
      }
    );
    builder.addCase(getPlanTaxCharges.rejected, (state, action) => {
      state.planTaxCharges = null;
      state.errorPlanTaxCharges = action.payload;
      state.isLoadedPlanTaxCharges = false;
    });
    builder.addCase(getOneTimeSimTaxCharges.pending, (state) => {
      state.oneTimeSIMTaxCharges = null;
      state.errorOneTimeSimTaxCharges = "";
      state.isLoaded = true;
    });
    builder.addCase(
      getOneTimeSimTaxCharges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.oneTimeSIMTaxCharges = action.payload.data.data;
        state.errorOneTimeSimTaxCharges = null;
        state.isLoaded = false;
      }
    );
    builder.addCase(getOneTimeSimTaxCharges.rejected, (state, action) => {
      state.oneTimeSIMTaxCharges = null;
      state.errorOneTimeSimTaxCharges = action.payload;
      state.isLoaded = false;
    });
    builder.addCase(getOneTimeShippingTaxCharges.pending, (state) => {
      state.oneTimeShippingTaxCharges = null;
      state.errorOneTimeShippingTaxCharges = "";
      state.isLoadedShippingTaxes = true;
    });
    builder.addCase(
      getOneTimeShippingTaxCharges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.oneTimeShippingTaxCharges = action.payload.data.data;
        state.errorOneTimeShippingTaxCharges = null;
        state.isLoadedShippingTaxes = false;
      }
    );
    builder.addCase(getOneTimeShippingTaxCharges.rejected, (state, action) => {
      state.oneTimeShippingTaxCharges = null;
      state.errorOneTimeShippingTaxCharges = action.payload;
      state.isLoadedShippingTaxes = false;
    });
    builder.addCase(
      UpdateCustomerSource.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateCustomerSource = action.payload.data;
        state.errorUpdateCustomerSource = null;
        setLocalStorage(action);
        state.primaryUser = action.payload?.data?.data?.isPrimary
          ? action.payload?.data?.data
          : null;
        state.secondaryUser = action.payload?.data?.data?.isPrimary
          ? null
          : action.payload?.data?.data;
      }
    );
    builder.addCase(UpdateCustomerSource.rejected, (state, action) => {
      state.updateCustomerSource = null;
      state.errorUpdateCustomerSource = action.payload;
    });
    builder.addCase(Coupon.fulfilled, (state, action: PayloadAction<any>) => {
      state.coupon = action.payload.data.data;
      state.errorCoupon = null;
      state.isLoaded = false;
    });
    builder.addCase(Coupon.pending, (state) => {
      state.coupon = null;
      state.errorCoupon = "";
      state.isLoaded = true;
    });
    builder.addCase(Coupon.rejected, (state, action: any) => {
      state.coupon = null;
      state.errorCoupon = action.payload?.message.replace(/{|}/g, "");
      state.isLoaded = false;
    });
    builder.addCase(
      CouponReserve.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.couponReserve = action.payload.data;
        state.errorCouponReserve = null;
      }
    );
    builder.addCase(CouponReserve.rejected, (state, action) => {
      state.couponReserve = null;
      state.errorCouponReserve = action.payload;
    });
    builder.addCase(
      Eligibility.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.eligibility = action.payload.data;
        state.errorEligibility = null;
      }
    );
    builder.addCase(Eligibility.rejected, (state, action) => {
      state.eligibility = null;
      state.errorEligibility = action.payload;
    });
    builder.addCase(
      UpdateMacAddress.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateMacSuccess = action.payload.data;
        state.updateMacFailure = null;
      }
    );
    builder.addCase(UpdateMacAddress.rejected, (state, action) => {
      state.updateMacSuccess = null;
      state.updateMacFailure = action.payload;
    });
    builder.addCase(
      getActivityTypes.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.activityTypes = action.payload.data;
        state.errActivityTypes = null;
      }
    );
    builder.addCase(getActivityTypes.rejected, (state, action) => {
      state.activityTypes = null;
      state.errActivityTypes = action.payload;
    });
  },
});
export const {
  storeLoginData,
  updatePlanDetails,
  updateCouponDetails,
  resetCoupon,
  setLoginStatus,
  updateSecondaryUser,
  updateFormValues,
  updatePrimaryLineType,
  resetDataGetCustomer,
  setOpenAutopayConsent,
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
