import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { LineComponent } from "../LineComponent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./S1_ManageLine.module.scss";
import { ManageLinesProps } from "./ManageLines";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import getSymbolFromCurrency from "currency-symbol-map";
import { AddLines } from "../AddLines";
import { ButtonCust } from "../../../widgets/Button/ButtonCust";
import Config from "../../../../config/env.config.json";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import { LinearProgressBarCust } from "../../../widgets";
import { CustomerStatus } from "../../../../enums/CustomerStatus";

const ManageLinesComponent = (props: ManageLinesProps) => {
  const {
    setCurrentSection,
    counter,
    pendingLines,
    user,
    handleAddLine,
    additionalCost,
    content,
    formik,
    handleFormikChange,
    removeFormFields,
    isSecondaryUserJourney,
    addUserError,
    isEmailAVailable,
    handleLinesTab,
    stopAddLinesFlow,
    setStopAddLinesFlow,
  } = props;
  const GAAndFBEventsHandler = (categoryName: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.activation,
      category: categoryName,
      buttonValue: value,
    });
  };

  return (
    <>
      <Grid
        container
        sx={{
          py: {
            xs: "var(--mobile_section_padding)",
            sm: "var(--ipad_section_padding)",
            md: "var(--desktop_section_padding)",
          },
          px: "20px",
        }}
      >
        <Box onClick={() => setCurrentSection("DataDetails")}>
          <ArrowBackIcon
            sx={{ ml: { md: "30px", sm: "10px", xs: "20px" }, width: "18px" }}
            className={styles.arrow_icon}
          />
        </Box>
        <Grid
          item
          xs={12}
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            className={styles.title_main}
            sx={{
              fontSize: "22px",
              fontFamily: "var(--font_family_Bold)",
              lineHeight: 1,
            }}
          >
            {isSecondaryUserJourney ? content?.heading_2 : content?.heading}
          </Typography>
        </Grid>
        {isSecondaryUserJourney ? (
          <Box width={{ xs: "100%", sm: "50%", md: "35%" }} margin="auto">
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "var(--font_family_Medium)",
                lineHeight: 1.5,
                textAlign: "center",
                padding: "20px 0px",
              }}
            >
              {content?.step_prog}
            </Typography>
            <LinearProgressBarCust value={50} variant={"determinate"} />
          </Box>
        ) : null}
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "35px 0px 30px",
          }}
        >
          <LineComponent
            content={content}
            setStopAddLinesFlow={setStopAddLinesFlow}
          />
          <Typography
            component={"span"}
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              fontSize: "14px",
              fontWeight: "var(--font_weight_1)",
              color: "var(--grey_shade)",
              py: 1,
            }}
          >
            <Typography
              component={"span"}
              sx={{
                fontWeight: "var(--font_weight_2)",
                fontSize: "inherit",
              }}
            >
              {content?.note}&nbsp;
            </Typography>
            {content?.note_description}&nbsp;
            <Link
              href={content?.link_url}
              target="_blank"
              rel="noopener"
              color={"inherit"}
              fontFamily="inherit"
              onClick={() => {
                GAAndFBEventsHandler(gaCategory.activation, content?.link_text);
              }}
            >
              {content?.link_text}
            </Link>
          </Typography>

          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              isEmailAVailable ? handleLinesTab(2, true) : null;
            }}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {formik.values.map((_: any, index: any) => {
              return (
                <AddLines
                  key={index}
                  formik={formik}
                  handleFormikChange={handleFormikChange}
                  index={index}
                  removeFormFields={removeFormFields}
                  addUserError={addUserError}
                />
              );
            })}

            {counter < pendingLines ? (
              <Stack
                pt={"24px"}
                alignItems={{ xs: "center", sm: "end" }}
                mr={{ xs: "unset", sm: "50px" }}
              >
                <Typography
                  onClick={() => {
                    if (
                      ![
                        CustomerStatus.SUSPENDED,
                        CustomerStatus.DISCONNECTED,
                      ].includes(user.status)
                    ) {
                      if (user.extraLines > user.secondaryCustomers.length)
                        return;
                      if (isEmailAVailable) {
                        handleAddLine();
                      }
                    }
                  }}
                  component="div"
                  sx={{
                    fontSize: "14px",
                    color: "var(--text_color)",
                    fontFamily: "var(--font_family_Semibold)",
                    display: "flex",
                    alignItems: "center",
                    cursor: `${
                      stopAddLinesFlow ? "" : isEmailAVailable ? "pointer" : ""
                    }`,
                    opacity: `${
                      stopAddLinesFlow ? 0.5 : isEmailAVailable ? "unset" : 0.5
                    }`,
                  }}
                >
                  {content?.add_line}&nbsp;
                  <Typography
                    component={"span"}
                    sx={{
                      fontFamily: "var(--font_family_Bold)",
                      fontSize: "inherit",
                    }}
                  >
                    {getSymbolFromCurrency(Config.COUNTRY_CURRENCY)}
                    {additionalCost}
                    {content?.per_month}&nbsp;
                  </Typography>
                  {content?.per_line}
                  <AddCircleIcon
                    sx={{
                      pl: "20px",
                      width: "36px",
                      height: "36px",
                      opacity: `${stopAddLinesFlow ? "0.4" : ""}`,
                      color: "var(--primary_color)",
                    }}
                  />
                </Typography>
                {/* Info message block */}
                {stopAddLinesFlow && (
                  <Typography
                    component={"span"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: "15px",
                    }}
                  >
                    <Box
                      component="img"
                      src={content?.addline_icon?.data?.attributes?.url}
                      alt="addLineNote"
                    />
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "var(--font_family_Semibold)",
                        fontSize: "14px",
                        color: "var(--grey_shade)",
                      }}
                    >
                      {content?.add_new_line}
                    </Typography>
                  </Typography>
                )}
              </Stack>
            ) : null}
            {isSecondaryUserJourney ? (
              <ButtonCust
                type="submit"
                variantType={"secondary_contained_rounded"}
                sx={{
                  m: "55px auto",
                  opacity: isEmailAVailable ? "" : 0.4,
                  cursor: isEmailAVailable ? "pointer" : "default",
                }}
                disabled={!isEmailAVailable}
              >
                {content?.continue_btn}
              </ButtonCust>
            ) : null}
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default ManageLinesComponent;
