import { Box, Typography } from "@mui/material";
import { privacyProps } from "./Privacy";
import styles from "./S1_privacy.module.scss";
import EnvConfig from "../../config/env.config.json";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";

const PrivacyComponent = (props: privacyProps) => {
  const { content } = props;
  const handleClick = (event: any) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.privacy,
      category: gaCategory.privacy,
      buttonValue: event.target.innerText,
    });
  };
  return (
    <Box className={styles.privacy_main_container}>
      <Box className={styles.privacy_content}>
        <Typography variant="h4" className={styles.privacy_title}>
          {content?.title}
        </Typography>
        <Box className={styles.privacy_line} />
        <Box
          className={styles.privacy_text + " " + styles.privacy_top_para}
          dangerouslySetInnerHTML={{
            __html: content?.privacy_top_content,
          }}
        />
        {content &&
          content?.privacy_content.length > 0 &&
          content?.privacy_content.map((items: any, index: string) => {
            return (
              <Box key={index}>
                <Typography variant="h3" className={styles.privacy_heading}>
                  {index + 1}. {items?.heading}
                </Typography>
                <Typography variant="h4" className={styles.privacy_heading}>
                  {items?.sub_heading}
                </Typography>
                <Box
                  className={styles.privacy_text}
                  onClick={(e) => {
                    handleClick(e);
                  }}
                  dangerouslySetInnerHTML={{
                    __html: items?.description,
                  }}
                />
                <Typography variant="h4" className={styles.privacy_heading}>
                  {items?.sub_heading1}
                </Typography>
                <Box
                  className={styles.privacy_text}
                  dangerouslySetInnerHTML={{
                    __html: items?.description1,
                  }}
                />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default PrivacyComponent;
