import { sortByKey } from "../../../utils/commonFunctions/SortByKey";
import data from "../../../config/data.json";
import { globalVal } from "../../../utils/globalValues/globalvalues";
export const formSubmit = (submitHandler: any, formik: any, userDetails: any) => {
  const { dataUsage, currentAmount, rangeSlider, mailID } = formik.values;
  let plans =
    data?.data?.length > 0 ? sortByKey(data && data.data, "planData") : [];
  let plan: any;
  const highestPlan = plans[plans.length - 1];
  if (dataUsage > highestPlan?.planData) {
    plan = highestPlan || null;
  } else {
    plans =
      dataUsage && plans?.length > 0
        ? plans.filter((el: any) => (el && el.planData) >= dataUsage)
        : plans;
    plan = plans.length > 0 && plans[0] ? plans[0] : null;
    plans =
      plans.length > 0 && plan
        ? plans.filter(
            (el: any) => (el && el.planData) === (plan && plan.planData)
          )
        : plans;
    plan =
      plans.length > 1
        ? sortByKey(plans, "planData")
        : plans.length === 1
        ? plans
        : [];
    plan = plan?.[0] || null;
  }
  let cost: number = 0;
  if (plan) {
    cost = costCaluation(plan, rangeSlider);
    let payload = {
      emailId: mailID,
      hubspotMap: {
        email: mailID,
        is_test: globalVal.hubspot_is_test,
        is_qa: globalVal.hubspot_is_qa,
        firstname: userDetails?.firstname,
        lastname: userDetails?.lastname,
        bill_page_number_of_lines: parseInt(rangeSlider.toString()),
        bill_page_usage_reported: dataUsage,
        bill_page_amount_reported: currentAmount,
        bill_page_recommended_plan: plan?.name || "",
        bill_page_was_plan_recommended: false,
      },
    };
    let positive: boolean = false;
    if (currentAmount && cost > currentAmount) {
      payload.hubspotMap = {
        ...payload.hubspotMap,
        bill_page_was_plan_recommended: false,
      };
    } else if (currentAmount && cost <= currentAmount) {
      positive = true;
      payload.hubspotMap = {
        ...payload.hubspotMap,
        bill_page_was_plan_recommended: true,
      };
    }
    const saveAmt: number = savingAmount(plan);
    const py = {
      planData: plan,
      planCost: cost,
      isExpensive: dataUsage > highestPlan?.planData ? false : positive,
      saveAmt: saveAmt,
    };
    submitHandler(payload, py, formik);
  }
};

export const costCaluation = (plan: any, rangeSlider: any) => {
  let cost = plan && plan.baseLinePrice;
  if (
    plan && 
    plan.addLineSplitPrice && 
    rangeSlider > 1 &&
    rangeSlider <= plan.maxLines && 
    plan.baseLinePrice &&
    plan.addLineSplitPrice[rangeSlider - 1]
  ) {
    cost = Math.ceil(
      plan.baseLinePrice +
        plan.addLineSplitPrice[rangeSlider - 1] * (rangeSlider - 1)
    );
  } else if (
    plan &&
    plan.additionalLinePrice &&
    plan.baseLinePrice && 
    rangeSlider > 1 &&
    rangeSlider <= plan.maxLines
  ) {
    cost = Math.ceil(
      plan.baseLinePrice + plan.additionalLinePrice * (rangeSlider - 1)
    );
  }
  return cost;
};
export const savingAmount = (plan: any) => {
  return plan &&
    plan.addLineSplitPrice &&
    plan.addLineSplitPrice[1] &&
    plan.baseLinePrice
    ? plan.baseLinePrice -
        Math.ceil((plan.baseLinePrice + plan.addLineSplitPrice[1]) / 2)
    : plan && plan.additionalLinePrice
    ? plan.additionalLinePrice
    : 0;
};