import { Box, Grid, Typography } from "@mui/material";
import { ButtonCust } from "../../../widgets/Button/ButtonCust";
import { GivePlanProps } from "./GivePlan";
import styles from "./S1_GivePlan.module.scss";

const GivePlanComponent = (props: GivePlanProps) => {
  const { btn_txt, disc_txt, img, title, footer, nav_path, to_pg } =
    props?.content || {};
  return (
    <>
      <Grid
        container
        sx={{
          textAlign: "center",
          p: {
            xs: "calc(var(--mobile_section_padding)/2) 0",
            sm: "calc(var(--ipad_section_padding)/2) 0",
            md: "calc(var(--desktop_section_padding)/2) 0",
          },
        }}
        display={"flex"}
        justifyContent="space-around"
        flexDirection="row-reverse"
        padding={{ xs: "20px 0px", md: "50px 0px" }}
      >
        <Grid
          item
          sm={12}
          md={6}
          key={0}
          sx={{ padding: "0px 40px 0px 0px" }}
          margin="0px auto"
        >
          <Box
            component="img"
            src={img?.data?.attributes?.url || ""}
            alt={img?.data?.attributes?.alternativeText}
            sx={{
              width: { sm: "100%", md: "68%", xs: "100%" },
              margin: { xs: "0px 20px 20px", md: "0px 50px 50px" },
              height: { xs: "350px", sm: "720px", md: "765px", lg: "485px" },
            }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Grid
            item
            xs={12}
            textAlign="center"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "baseline",
              padding: "0px 35px",
              textAlign: { xs: "center", md: "left", sm: "center", lg: "left" },
            }}
          >
            <Typography
              className={styles.title}
              variant="h3"
              sx={{
                width: "100%",
              }}
            >
              {title}
            </Typography>
            <Box
              className={styles.current_line}
              sx={{
                margin: {
                  lg: "20px 0px 0px",
                  sm: "20px auto",
                  xs: "20px auto",
                },
              }}
            />
            <Typography
              className={styles.details}
              sx={{
                lineHeight: "1.5",
                margin: "20px 0px 25px",
                fontSize: "20px",
                fontFamily: "SharpSansDispNo1-Medium",
              }}
            >
              {disc_txt}
            </Typography>
            <Typography
              sx={{
                margin: "-10px 0 25px",
                fontSize: "16px",
                lineHeight: "1.88",
                fontFamily: "SharpSansDispNo1-Medium",
                width: "100%",
              }}
            >
              {footer}
            </Typography>

            <ButtonCust
              sx={{ margin: { xs: "0px auto", sm: "0px auto", md: "0px" } }}
              onClick={() => window.open(nav_path, to_pg)}
            >
              {btn_txt}
            </ButtonCust>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GivePlanComponent;
