import { useMediaQuery, useTheme } from "@mui/material";
import { usaState } from "../../config/USAStateList";
import { IAddress } from "../../features/checkout/Types";

export const formatPhoneNumber = (phone: string) => {
  var cleaned = ("" + phone).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ")", match[3], "-", match[4]].join("");
  }
  return null;
};

export const isInvalid = (value: undefined | string | number | object) => {
  return value === undefined || value === null || value === "";
};

export const isInValidAddressInfo = (address: IAddress) => {
  return (
    isInvalid(address.address1) ||
    isInvalid(address.city) ||
    isInvalid(address.state) ||
    isInvalid(address.country) ||
    isInvalid(address.zip)
  );
};

export const getFormattedPhoneNumber = (
  phoneNumber: string,
  isIncludeCountryCode = false
) => {
  let phone = phoneNumber?.replace(/[^\d]/g, "");
  return isIncludeCountryCode ? `+91${phone}` : phone;
};

export const displayPhoneNumberFormat = (phoneNumber: string) => {
  let phone = getFormattedPhoneNumber(phoneNumber);
  return phone
    ?.replace(/\D+/g, "")
    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

export const getNumberWithOrdinal = (n: any) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getIsCardExpired = (data: any) => {
  let today, someday;
  let exMonth = data?.dateOfExpiry.substring(0, 2);
  let exYear = data?.dateOfExpiry.substring(2);
  exYear = exYear && `20${exYear}`;
  today = new Date();
  someday = new Date();
  someday.setFullYear(exYear, exMonth, 1);
  if (someday < today) {
    return true;
  }
  return false;
};

export const getResolutionDetails = () => {
  const theme = useTheme();
  const largeDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const desktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return {
    largeDesktop,
    desktop,
    ipad,
    mobile,
  };
};

export function toTitleCase(str: string) {
  if (!str) {
    return "";
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

export const modemFormattedVal = (value: string) => {
  if (!value || value?.length < 1) {
    return "";
  }
  const replacedExceptNum = value.replace(/[^a-z0-9]/gi, "");
  const valWithDashes = replacedExceptNum?.match(/.{1,2}/g)?.join(":");
  return valWithDashes ? valWithDashes : "";
};

export const findStateByCode = (code: string) => {
  let state = usaState.find(
    (el: any) => el.value.toLowerCase() === code.toLowerCase()
  );
  return state && state.label;
};

export const formatDateFn = (date: any) => {
  //Will give YYYY-MM-dd format
  const selectedDate: any = new Date(date);
  return (
    selectedDate.getFullYear() +
    "-" +
    parseInt(selectedDate.getMonth() + 1) +
    "-" +
    selectedDate.getDate()
  );
};

export const renderCommonStyle = (xs: any, sm: any, md?: any) => ({
  xs: xs,
  sm: sm,
  ...(md && { md: md }),
});
