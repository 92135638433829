import { useState } from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import styles from "./S1_Donations.module.scss";
import { SimpleModal, Share } from "../../../widgets";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import EnvConfig from "../../../../config/env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
const T1_Donations = (props: any) => {
  const { content } = props;
  const [showModal, setShowModal] = useState(false);
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.postActivationHome,
      category: category,
      buttonValue: value,
    });
  };

  const peopleConnectedText = {
    paddingBottom: "5px",
    fontFamily: "var(--font_family)",
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: 1,
    letterSpacing: "normal",
  };
  const peopleConnectedSubInfoText = {
    paddingBottom: "25px",
    fontFamily: "var(--font_family_Medium)",
    fontSize: "14px",
    fontWeight: "var(--font_weight_1)",
    lineWeight: 1.43,
  };

  const facebookURL = {
    url: content?.share_url,
    quote:
      content?.m_text1 +
      " " +
      content?.m_sub_text1 +
      " " +
      content?.m_text2 +
      " " +
      content?.m_sub_text1 +
      ". " +
      content?.m_msg,
  };

  const twitterURL = {
    url: content?.share_url,
    title:
      content?.m_text1 +
      " " +
      content?.m_sub_text1 +
      " " +
      content?.m_text2 +
      " " +
      content?.m_sub_text1 +
      ". " +
      content?.m_msg,
  };

  const emailAction = {
    url: content?.share_url,
    subject: content?.m_text1 + " " + content?.m_sub_text1 + ".",
    body:
      content?.m_text1 +
      " " +
      content?.m_sub_text1 +
      " " +
      content?.m_text2 +
      " " +
      content?.m_sub_text1 +
      ". " +
      content?.m_msg,
  };
  let shareButton: any = (
    <Share
      share_text={props.content?.share_text}
      share_icon={content?.share_icon?.data?.attributes?.url}
      invite_friends_text={props.content?.popover_title}
      facebook_icon={content?.fb_icon?.data?.attributes?.url}
      twitter_icon={content?.twitter_icon?.data?.attributes?.url}
      mail_icon={content?.mail_icon?.data?.attributes?.url}
      facebook_label={content?.fb_text}
      twitter_label={content?.twitter_text}
      mail_label={content?.mail_text}
      facebook_action={facebookURL}
      twitter_action={twitterURL}
      email_action={emailAction}
    />
  );
  const openShareModal = () => {
    GAAndFBEventsHandler(gaCategory.donations, content?.btn_text);
    setShowModal(!showModal);
  };
  return (
    <Box
      className={styles.making_difference_div}
      sx={{
        p:{
          xs: "calc(var(--mobile_section_padding)/2) 0",
          sm: "calc(var(--ipad_section_padding)/2) 0",
          md: "calc(var(--desktop_section_padding)/2) 0",
        },
      }}
    >
      <Box className={styles.diff_img_div}>
        <img
          src={content?.d_img?.data?.attributes?.url}
          alt={content?.d_img?.data?.attributes?.alternativeText}
          width="56px"
          height="60px"
        />
      </Box>
      <Box className={styles.home_post_activation_title}>{content?.title}</Box>
      <Box className={styles.home_post_activation_sub_title}>
        {content?.sub_title}
      </Box>
      <Box className={styles.home_post_activation_line}></Box>

      <Box className={styles.make_difference_text}>{content?.text1}</Box>
      <Box className={styles.make_difference_sub_text}>
        {content?.sub_text1}
      </Box>
      <Box className={styles.make_difference_text}>{content?.text2}</Box>
      <Box className={styles.make_difference_sub_text}>
        {content?.sub_text2}
      </Box>
      <Box className={styles.share_now_btn}>
        <Button className={styles.share_now_btn1} onClick={openShareModal}>
          {content?.btn_text}
        </Button>
      </Box>
      {showModal && (
        <SimpleModal
          onCloseModal={openShareModal}
          showClose={true}
          isShowModal={showModal}
          sx={{
            width: { xs: "94%", sm: "70%", md: "70%", lg: "56%", xl: "50%" },
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "4.8px",
          }}
        >
          <Grid
            container
            display="flex"
            flexDirection="column"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              alt={content?.m_img?.data?.attributes?.alternativeText}
              component="img"
              sx={{
                width: {
                  xs: "80%",
                  lg: "60%",
                },
                height: "Auto",
                pb: "40px",
                pt: "10px",
              }}
              src={content?.m_img?.data?.attributes?.url}
            />
            <Typography sx={peopleConnectedText}>{content?.m_text1}</Typography>
            <Typography sx={peopleConnectedSubInfoText}>
              {content?.m_sub_text1}
            </Typography>
            <Typography sx={peopleConnectedText}>{content?.m_text2}</Typography>
            <Typography sx={peopleConnectedSubInfoText}>
              {content?.m_sub_text2}
            </Typography>
            <Typography
              sx={{
                fontFamily: "var(--font_family_Semibold)",
                fontSize: "14px",
                fontWeight: "var(--font_weight_2)",
                lineHeight: 1.43,
                paddingBottom: "20px",
              }}
            >
              {content?.m_msg}
            </Typography>

            <span
              onClick={() => {
                GAAndFBEventsHandler(
                  gaCategory.donations,
                  shareButton.props.share_text
                );
              }}
            >
              {shareButton}
            </span>
          </Grid>
        </SimpleModal>
      )}
    </Box>
  );
};

export default T1_Donations;
