import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  manageCardContent: any;
  errorManageCardContent: any;
};

const initialState: InitialState = {
  manageCardContent: null,
  errorManageCardContent: null,
};

// Generates pending, fulfilled and rejected action types
export const manageCard = createAsyncThunk("manageCard", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Billing.ManageCards.template}${StrapiApiEndPoints.manageCard}`,
    isStrapiApi: true,
  });
});

const strapiManageCardSlice = createSlice({
  name: "manageCard",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      manageCard.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.manageCardContent = action.payload?.data?.data?.attributes;
        state.errorManageCardContent = null;
      }
    );
    builder.addCase(manageCard.rejected, (state: InitialState, action: any) => {
      state.manageCardContent = null;
      state.errorManageCardContent =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default strapiManageCardSlice.reducer;
