import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Config from "../../../../config/app.config.json";
import { topUpUpdate } from "../../../../features/account/accountSlice";
import { Store } from "react-notifications-component";
import { GetCustomer } from "../../../../features/checkout/checkoutSlice";
import { dataDetailsContent } from "../../../../features/strapi/accountSlice";
import { addMultipleCacheData } from "../../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
import { getDataUsageContent } from "../../../../features/strapi/postActivationHomeSlice";
import { OutOfData } from "../../../outOfData";
import { NotificationCust } from "../../../widgets";
const DataDetailsComponent = isTemplateExisits(Config.Account.AccountDetails)
  ? require(`./${Config.Account.AccountDetails.template}_DataDetails`).default
  : null;

export type dataDetailsProps = {
  content?: any;
  setCurrentSection: Function;
  topupPulseDetails: any;
  userRecurringTopUp: any;
  setUserRecurringTopUp: any;
  primaryUser: any;
  topupSet: boolean;
  unlimited: boolean;
  user: any;
  topup: boolean;
  setTopup: any;
  group: any;
  setOpen: any;
  open: boolean;
  setActiveTab?: any;
  tabsContent?: any;
  dataUsageContent?: any;
  isCardExpired: any;
  setSelectedLine: any;
};
export const DataDetails = (props: dataDetailsProps) => {
  const { updateTopUp, topUpUpdateLoader } = useAppSelector(
    (state: any) => state.account
  );
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const { outOfDataContent } = useAppSelector(
    (state: any) => state.strapi.myAccount
  );
  const { dataContent } = useAppSelector((state: any) => state.strapi.account);
  const [displayOutOfData, setDisplayOutOfData] = useState(false);
  const [nameChanges, setNameChanges] = useState<string>("");
  const [dataDetailContent, setDataDetailContent] = useState<any>(null);
  const [cacheDataUsage, setCacheDataUsage] = useState<any>(null);
  const { dataUsage } = useAppSelector(
    (state: any) => state.strapi.postActivation
  );
  const dispatch = useAppDispatch();
  const {
    setCurrentSection,
    topupPulseDetails,
    userRecurringTopUp,
    primaryUser,
    topupSet,
    unlimited,
    user,
    setTopup,
    setOpen,
    tabsContent,
    setActiveTab,
    isCardExpired,
  } = props;

  const recurringBtnHandler = () => {
    let payload: any = {};
    userRecurringTopUp?.length > 0 &&
      userRecurringTopUp?.map((el: any) => (payload[el.userId] = el.status));

    dispatch(
      topUpUpdate({
        primaryId: primaryUser?.id,
        customerAutoTopUpMap: unlimited
          ? payload
          : { [primaryUser?.id]: topupSet ? false : true },
      })
    );
  };
  useEffect(() => {
    getCache(
      "DataUsageAccount",
      getDataUsageContent,
      setCacheDataUsage,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("DataUsageAccount", dataUsage);
  }, [dataUsage]);
  useNonInitialEffect(() => {
    if (updateTopUp?.status === "SUCCESS" && updateTopUp?.data) {
      setOpen(false);
      setTopup((ps: any) => !ps);
      window.scroll(0, 0);
      let name = nameChanges;
      let message = unlimited
        ? `${
            dataDetailContent
              ? dataDetailContent?.setting_changed
              : dataContent?.setting_changed
          }${name}`
        : topupSet
        ? `${
            dataDetailContent
              ? dataDetailContent?.rec_data
              : dataContent?.rec_data
          }${
            topupPulseDetails?.pulse
          }GB ($${(topupPulseDetails?.pulseCost).toFixed(2)})${
            dataDetailContent
              ? dataDetailContent?.disabled
              : dataContent?.disabled
          }`
        : `${
            dataDetailContent
              ? dataDetailContent?.rec_data
              : dataContent?.rec_data
          } ${
            topupPulseDetails?.pulse
          }GB ($${(topupPulseDetails?.pulseCost).toFixed(2)})${
            dataDetailContent
              ? dataDetailContent?.enabled
              : dataContent?.enabled
          }`;
      NotificationCust({
        message: message,
        type: "info",
        id: "accountSetRecurringTopupMsg",
        showIcon: true,
      });
      setNameChanges("");
      dispatch(
        GetCustomer({
          username: encodeURIComponent(user && user.emailId),
        })
      );
    }
  }, [updateTopUp]);
  useNonInitialEffect(() => {
    if (getCustomer) {
      handleOutOfDataDialog();
    }
  }, [getCustomer]);
  const handleOutOfDataDialog = () => {
    if (
      getCustomer?.isDataCutoff === true &&
      getCustomer?.addedDataCutoff === false
    ) {
      window.scroll(0, 0);
      setDisplayOutOfData(true);
    }
    if (user && user.isThrottled === true && user.throttleReason === 0) {
      window.scroll(0, 0);
      dataDetailContent?.tr_0_msg &&
        NotificationCust({
          message: dataDetailContent?.tr_0_msg,
          type: "info",
          id: "dataUsageNotification",
          showIcon: true,
        });
    }
    if (user && user.isThrottled === true && user.throttleReason === 1) {
      window.scroll(0, 0);
      dataDetailContent?.tr_1_msg &&
        NotificationCust({
          message: dataDetailContent?.tr_1_msg,
          type: "info",
          id: "dataUsageNotification",
          showIcon: true,
        });
    }
    if (user && user.isThrottled === true && user.throttleReason === -1) {
      window.scroll(0, 0);
      dataDetailContent?.tr_2_msg &&
        dataDetailContent?.tr_2_msg_2 &&
        NotificationCust({
          message: user?.reachPlanChangeDate
            ? dataDetailContent?.tr_2_msg
            : dataDetailContent?.tr_2_msg_2,
          type: "info",
          id: "dataUsageNotification",
          showIcon: true,
        });
    }
  };
  const onCloseDataUsageDialog = () => {
    setDisplayOutOfData(false);
    Store.removeNotification("OUTOFDATANOTIFIER");
  };
  const navigateToBuyDataPack = () => {
    setCurrentSection("CPBDP");
    setActiveTab(tabsContent[1]?.id);
    onCloseDataUsageDialog();
  };
  useEffect(() => {
    getCache("DataDetails", dataDetailsContent, setDataDetailContent, dispatch);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("DataDetails", dataContent);
  }, [dataContent]);
  return (
    (dataDetailContent || dataContent) && (
      <>
        <DataDetailsComponent
          recurringBtnHandler={recurringBtnHandler}
          topUpUpdateLoader={topUpUpdateLoader}
          setNameChanges={setNameChanges}
          nameChanges={nameChanges}
          content={dataDetailContent ? dataDetailContent : dataContent}
          dataUsageContent={cacheDataUsage ? cacheDataUsage : dataUsage}
          {...props}
        />
        <OutOfData
          open={displayOutOfData}
          handleSubmit={navigateToBuyDataPack}
          closeDialog={onCloseDataUsageDialog}
        />
      </>
    )
  );
};
