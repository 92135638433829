import Config from "../../../config/app.config.json";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";

const AddressInfoComponent = isTemplateExisits(Config.Checkout.AddressInfo)
  ? require(`./${Config.Checkout.AddressInfo.template}_AddressInfo`).default
  : null;

export type AddressFieldProps = {
  updateUserInFirebaseDatabase?: any;
  sendSimOptions?: any;
  paymentClicked?: any;
  proceedToPaymentHandler?: any;
  errorPayment?: any;
  navigate?: any;
  user?: any;
  formik?: any;
  isLoggedIn: boolean;
  line: any;
  setUserDetails: any;
  content: any;
  simPreference: any;
  setSimPreference: any;
};

export const AddressInfo = (props: AddressFieldProps) => {
  return <AddressInfoComponent {...props} />;
};
