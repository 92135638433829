import { Box, Grid, Typography } from "@mui/material";
import styles from "./S2_BrandFeatures.module.scss";
import { brandFeaturesProps } from "./BrandFeatures";

const BrandFeaturesComponent = (props: brandFeaturesProps) => {
  const {
    featureDetails,
    cardChangeHandler,
    currentCard,
    cards,
    renderCardDetails,
  } = props;

  const {
    brand_features,
    feature_card,
    high_light_cards,
    web_icons,
    img_name,
  } = styles;
  const cardDescriptions = (cardItem: any, showImg?: boolean) => {
    return (
      <Grid
        item
        key={cardItem?.keyProp}
        mb={{ md: 1 }}
        px={{ xs: 0, sm: 5, md: 3 }}
        py={{ xs: 3, sm: 3.7, md: 0 }}
        display="flex"
        flexDirection="column"
        alignItems={{ sm: "flex-start", xs: "center" }}
        justifyContent="center"
      >
        <>{renderCardDetails(cardItem, styles, showImg)}</>
      </Grid>
    );
  };
  return (
    <Grid
      container
      className={brand_features}
      sx={{ px: 2.5, display: "flex", mt: { md: 12, xs: 8 } }}
    >
      <Grid
        item
        sx={{
          display: { md: "flex", xs: "none" },
          flexWrap: "wrap",
        }}
        md={6}
      >
        {featureDetails?.map((item: any, index: number) => {
          const displayImage =
            cards !== index
              ? item?.featureImg?.data?.attributes?.url
              : item?.featureHoverImg?.data?.attributes?.url;
          const alternateText =
            cards !== index
              ? item?.featureImg?.data?.attributes?.alternativeText
              : item?.featureHoverImg?.data?.attributes?.alternativeText;
          return (
            <Grid
              item
              key={item.keyProp}
              className={`${feature_card} ${
                cards === index ? high_light_cards : null
              } `}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 220,
                borderRadius: 0.5,
              }}
              md={4.5}
              m={2}
              onMouseEnter={() => cardChangeHandler(index)}
            >
              <Box
                component="img"
                src={displayImage}
                alt={alternateText}
                className={web_icons}
              ></Box>

              <Typography variant="h6" textAlign="center" className={img_name}>
                {item.imageName}
              </Typography>
            </Grid>
          );
        })}
      </Grid>

      <Grid item display={{ md: "flex", xs: "none" }} md={6}>
        {cardDescriptions(currentCard)}
      </Grid>
      <Grid item display={{ md: "none", sm: "block" }} mb={{ xs: 3 }}>
        {featureDetails?.map((items: any) => {
          return cardDescriptions(items, true);
        })}
      </Grid>
    </Grid>
  );
};

export default BrandFeaturesComponent;
