import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./ActivationStep2.module.scss";
import {
  LinearProgressBarCust,
  TextFieldCust,
  SelectCust,
  Divider,
  NotificationCust,
  ButtonCust,
} from "../../../widgets";
import { string, object } from "yup";
import { useFormik } from "formik";
import {
  numberAlphabetPattern,
  PhoneNoPattern,
} from "../../../../utils/regexPatterns";
import { displayPhoneNumberFormat } from "../../../../utils/commonFunctions/ReusableFunctions";
import { NewNumberModal } from "./NewNumberModal";
import { HelpSupportModal } from "./SupportModal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useAppDispatch } from "../../../../app/hooks";
import {
  zipcodeAvaliability,
  getConfigKeys,
  refreshZipcode,
  port,
  portResult,
} from "../../../../features/activation/activationSlice";
import firebase from "../../../../adapters/firebase";
import Config from "../../../../config/env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";

interface formikintitaltypes {
  phoneNumber: string;
  carrier: string;
  zip: string;
  accNumber: string;
  password: string;
}

export const ActivationStep2 = (props: any) => {
  const { handleStep, gaHandler, currentLine, updatingCustomer, content } =
    props;
  const [currentNum, setCurrentNum] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSupportDialog, setOpenSupportDialog] = useState(false);
  const [currentOperator, setCurrentOperator] = useState<any>({});
  const [operators, setOperators] = useState<any>({});
  const [btnLoader, setBtnLoader] = useState(false);
  const dispatch = useAppDispatch();
  const {
    sp2_title,
    c_num,
    step_txt,
    num_pref,
    info_heading,
    extra_info,
    msgs,
    c_sup_title,
  } = content;
  const initialCarrierInfo: formikintitaltypes = {
    phoneNumber: currentLine?.mnpInfo?.oldNumber || "",
    carrier: currentLine?.mnpInfo?.operator || "",
    zip: currentLine?.mnpInfo?.oldZip || "",
    accNumber: currentLine?.mnpInfo?.acctNum || "",
    password: currentLine?.mnpInfo?.oldPassword || "",
  };
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.activation,
      category: category,
      buttonValue: value,
    });
  };

  const carrierSchema = object({
    phoneNumber: string()
      .trim()
      .required(msgs?.ph_empty)
      .min(10, msgs?.ph_valid),
    carrier: string().trim().required(msgs?.op_msg),
    zip: string()
      .trim()
      .required(msgs?.zip_empty)
      .test("len", msgs?.zip_valid, (val) => val?.length === 5),
    accNumber: string().trim().required(msgs?.acc_empty),
    password: string().trim().required(msgs?.pin_empty),
  });
  const formik = useFormik({
    initialValues: initialCarrierInfo,
    validationSchema: carrierSchema,
    onSubmit: (values: any) => {
      setBtnLoader(true);
      const py: any = {
        countryCode: Config.COUNTRY_CODE,
        number: values.phoneNumber,
        isPrimary: currentLine?.isPrimary,
      };

      gaHandler("Current Carrier Information");
      dispatch(port({ payload: py })).then((res) => {
        if (res?.payload?.status === 200) {
          const payload: any = {
            data: res?.payload?.data?.data,
          };
          dispatch(portResult({ payload: payload })).then((res) => {
            setBtnLoader(false);
            if (res?.payload?.status === 200) {
              handleStep(true);
              updatingCustomer({
                mnpInfo: {
                  oldNumber: values.phoneNumber,
                  operator:
                    values.carrier === "current" ? "Others" : values.carrier,
                  oldZip: values.zip,
                  acctNum: values.accNumber,
                  oldPassword: values.password,
                },
              });
            } else {
              getNotification("activationInvalidNumberFail", msgs?.port_noti);
            }
          });
        } else {
          setBtnLoader(false);
          if (res?.payload?.status === "FAILURE") {
            if (res?.payload?.resCode === 40093) {
              getNotification("activationPostError", msgs?.num_noti);
            } else if (res?.payload?.resCode === 40227) {
              getNotification("activationPostError", res?.payload?.message);
            } else {
              getNotification("activationPostErrorDefault", msgs?.fail_noti);
            }
          }
        }
      });
    },
    enableReinitialize: true,
  });

  const getNotification = (id: string, msg: string) => {
    return NotificationCust({
      message: msg,
      type: "danger",
      duration: 5000,
      id: id,
      showIcon: true,
    });
  };

  const onClickHandler = () => {
    setCurrentNum(true);
    gaHandler("Keep My Current Number");
    updatingCustomer({
      isMNP: true,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const carrierList = await firebase.database().ref("operators/");
    carrierList.on("value", (snapshot: any) => {
      const data = snapshot.val();
      if (data) {
        let data1: any = [];
        data.map((el: any) => {
          return data1.push({
            ...el,
            value: el.displayName,
            label: el.displayName,
          });
        });
        data1.push({
          value: "current",
          label: "Others",
        });
        setOperators(data1);
        if (currentLine?.mnpInfo?.operator) {
          data1?.forEach((operator: any) => {
            if (currentLine?.mnpInfo?.operator === operator.value) {
              setCurrentOperator(operator);
            }
          });
        }
      }
    });
  };
  const handleBack = () => {
    if (currentNum) {
      setCurrentNum(false);
    } else {
      handleStep(false);
    }
  };

  const handleChangeCarrier = (e: any) => {
    formik.handleChange(e);
    operators?.forEach((operator: any) => {
      if (e.target.value === operator.value) {
        setCurrentOperator(operator);
      }
    });
  };
  const newNumHandler = () => {
    setOpenDialog(true);
    dispatch(refreshZipcode());
    updatingCustomer({ isMNP: false });
  };
  const newModalHandler = (values: any) => {
    dispatch(zipcodeAvaliability({ zipcode: values?.zipCode })).then(
      (res: any) => {
        if (res.payload?.data?.data?.isValid === true) {
          handleStep(true);
          dispatch(getConfigKeys());
          updatingCustomer({ zipcode: values?.zipCode });
        }
      }
    );
  };
  return (
    <Box className={styles.step2_main}>
      <Box className={styles.heading_container}>
        <ArrowBackIcon
          sx={{ ml: { md: "30px", sm: "25px", xs: "4%" } }}
          className={styles.arrow_icon}
          onClick={handleBack}
        />
        <Typography className={styles.title_main}>
          {currentNum ? c_num.title : sp2_title}
        </Typography>
      </Box>
      <Box className={styles.stepper_content}>
        <Typography className={styles.stepper_txt}>{step_txt}</Typography>
        <Box width={{ xs: "90%", sm: "50%", md: "35%" }} mb="25px">
          <LinearProgressBarCust value={50} variant={"determinate"} />
        </Box>
      </Box>
      <Box width={{ xs: "90%", sm: "65%" }} m="0 auto 40px">
        <Box
          className={styles.inner_container}
          p={{ xs: "30px 15px", sm: "30px 50px" }}
        >
          {!currentNum ? (
            <>
              <Typography className={styles.card_title}>
                {num_pref?.desc}
              </Typography>
              <Box mb="16px" display="flex" justifyContent="center">
                <ButtonCust
                  sx={{ width: "220px" }}
                  onClick={() => {
                    onClickHandler();
                    GAAndFBEventsHandler(
                      gaCategory.activation,
                      num_pref?.c_num_btn
                    );
                  }}
                >
                  {num_pref?.c_num_btn}
                </ButtonCust>
              </Box>
              <Typography className={styles.card_description} mb="30px">
                {num_pref?.txt1}
              </Typography>
              <Box mb="16px" display="flex" justifyContent="center">
                <ButtonCust
                  sx={{ width: "220px" }}
                  onClick={() => {
                    newNumHandler(),
                      GAAndFBEventsHandler(
                        gaCategory.activation,
                        num_pref?.new_num_btn
                      );
                  }}
                >
                  {num_pref?.new_num_btn}
                </ButtonCust>
              </Box>
              <Typography className={styles.card_description}>
                {num_pref?.txt2}
              </Typography>
            </>
          ) : (
            <Box className={styles.form_container}>
              <Typography className={styles.form_title}>
                {c_num?.sub_title}
              </Typography>
              <Typography
                width={{ xs: "100%", sm: "80%", md: "50%" }}
                className={styles.form_description}
              >
                {c_num?.desc}
              </Typography>
              <Box width={{ xs: "100%", sm: "80%", md: "65%" }}>
                <TextFieldCust
                  name="phoneNumber"
                  type="text"
                  value={displayPhoneNumberFormat(formik.values.phoneNumber)}
                  label={c_num?.ph_num_ph}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = e.target;
                    if (
                      (PhoneNoPattern.test(value) && Number(value) >= 0) ||
                      value === ""
                    ) {
                      formik.handleChange(e);
                    }
                    if (value.length > 10) {
                      const contactNumber: string = String(
                        value.match(/\d/g)?.join("") || ""
                      );
                      e.target.value = contactNumber;
                      formik.handleChange(e);
                    }
                  }}
                  maxlength={10}
                  sx={{ my: "10px" }}
                  error={
                    formik.touched?.phoneNumber && formik.errors?.phoneNumber
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched?.phoneNumber && formik.errors?.phoneNumber
                  }
                />
                {operators && (
                  <SelectCust
                    value={formik.values.carrier}
                    options={operators}
                    name="carrier"
                    placeholder={c_num?.carrier_ph}
                    onChange={handleChangeCarrier}
                    helperText={
                      formik.touched?.carrier && formik.errors?.carrier
                    }
                    error={
                      formik.touched?.carrier && formik.errors?.carrier
                        ? true
                        : false
                    }
                    formStyles={{ my: "10px" }}
                  />
                )}
                <TextFieldCust
                  name="zip"
                  type="text"
                  value={formik.values.zip}
                  label={c_num?.zip_ph}
                  onChange={(e) => {
                    if (
                      PhoneNoPattern.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      formik.handleChange(e);
                    }
                  }}
                  sx={{ my: "10px" }}
                  maxlength={5}
                  error={
                    formik.touched?.zip && formik.errors?.zip ? true : false
                  }
                  helperText={formik.touched?.zip && formik.errors?.zip}
                />
                <Typography className={styles.limit_text}>
                  {formik.values?.zip?.length}/5
                </Typography>
                <TextFieldCust
                  name="accNumber"
                  type="text"
                  value={formik.values.accNumber}
                  label={
                    formik.values.carrier
                      ? `${formik.values.carrier} ${c_num?.acc_num_ph}`
                      : `${c_num?.cur_txt} ${c_num?.acc_num_ph}`
                  }
                  onChange={(e) => {
                    if (
                      numberAlphabetPattern.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      formik.handleChange(e);
                    }
                  }}
                  sx={{ my: "10px" }}
                  maxlength={20}
                  error={
                    formik.touched?.accNumber && formik.errors?.accNumber
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched?.accNumber && formik.errors?.accNumber
                  }
                />
                <TextFieldCust
                  name="password"
                  type="text"
                  value={formik.values.password}
                  label={
                    formik.values.carrier
                      ? `${formik.values.carrier} ${c_num?.pin_ph}`
                      : `${c_num?.cur_txt} ${c_num?.pin_ph}`
                  }
                  onChange={(e) => {
                    if (
                      numberAlphabetPattern.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      formik.handleChange(e);
                    }
                  }}
                  sx={{ my: "10px" }}
                  maxlength={15}
                  error={
                    formik.touched?.password && formik.errors?.password
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched?.password && formik.errors?.password
                  }
                />
                {currentOperator?.pinLabel && (
                  <Typography className={styles.pin_label}>
                    {currentOperator.pinLabel}
                  </Typography>
                )}
              </Box>
              <ButtonCust
                sx={{ width: "220px", mt: "20px" }}
                onClick={() => {
                  formik.handleSubmit();
                }}
                loading={btnLoader}
              >
                {c_num?.next_btn}
              </ButtonCust>
            </Box>
          )}
        </Box>
      </Box>
      {currentNum && !currentOperator?.acctNumMsg && (
        <Box width={{ xs: "90%", sm: "50%" }} m="0 auto">
          <Box mb="20px">
            <Typography className={styles.btm_title} sx={{ mb: "5px" }}>
              {c_num?.pin}
            </Typography>
            <Typography className={styles.btm_description}>
              {c_num?.pin_txt}
            </Typography>
          </Box>
          <Box>
            <Typography className={styles.btm_title} sx={{ mb: "5px" }}>
              {c_num?.car_info}
            </Typography>
            <Typography className={styles.btm_description}>
              {c_num?.car_info_txt},&nbsp;
              <span
                className={styles.btm_title}
                style={{ color: "var(--dusk)", cursor: "pointer" }}
                onClick={() => setOpenSupportDialog(true)}
              >
                {c_num?.help_txt}
              </span>
            </Typography>
          </Box>
        </Box>
      )}
      {currentNum && (
        <Box width={{ xs: "90%", sm: "50%" }} m="0 auto">
          {currentOperator?.acctNumMsg && (
            <>
              <Box>
                <Typography
                  className={styles.btm_title}
                  sx={{ mb: "20px", textAlign: "center" }}
                >
                  {c_num?.find_txt} {currentOperator.displayName}{" "}
                  {c_num?.acc_pin_txt}
                </Typography>
                <Divider />
              </Box>
              <Box mb="20px">
                <Typography className={styles.btm_title} sx={{ mb: "5px" }}>
                  {c_num?.acc_num}
                </Typography>
                <Typography className={styles.btm_description}>
                  {currentOperator?.acctNumMsg}
                </Typography>
              </Box>
              <Box mb="20px">
                <Typography className={styles.btm_title} sx={{ mb: "5px" }}>
                  {c_num?.pin}
                </Typography>
                <Typography className={styles.btm_description}>
                  {currentOperator.pinMsg.replace(
                    "{0}",
                    currentOperator.pinTel
                  )}
                </Typography>
              </Box>
              {currentOperator?.extraPinInfo === true && (
                <Box mt="30px">
                  <Typography className={styles.btm_title} sx={{ mb: "20px" }}>
                    {info_heading}
                  </Typography>
                  <Box>
                    {extra_info?.length > 0 &&
                      extra_info?.map((item: any, index: number) => {
                        return (
                          <Accordion
                            className={styles.accordian_main}
                            disableGutters
                            sx={{ boxShadow: "none" }}
                            key={index}
                          >
                            <AccordionSummary
                              sx={{ p: "0", mb: "0px" }}
                              expandIcon={<ArrowDropDownIcon />}
                            >
                              <Typography className={styles.acdn_title}>
                                {item?.title}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: "0" }}>
                              {item?.points?.length > 0 &&
                                item?.points?.map((data: any, ind: number) => {
                                  return (
                                    <Typography
                                      sx={{ mt: "10px" }}
                                      className={styles.btm_description}
                                      key={ind}
                                    >
                                      {data?.desc}
                                    </Typography>
                                  );
                                })}
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </Box>
                </Box>
              )}
            </>
          )}
          <Box
            mt="20px"
            mb="60px"
            width="fit-content"
            display="flex"
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpenSupportDialog(true);
              GAAndFBEventsHandler(gaCategory.activation, c_num.stil_need_help);
            }}
          >
            <img
              src={c_num?.chat_img?.data?.attributes?.url}
              alt={c_num?.chat_img?.data?.attributes?.alternativeText}
              className={styles.chat_box_img}
            />
            <Typography className={styles.btm_title} ml="5px">
              {c_num?.stil_need_help}
            </Typography>
          </Box>
        </Box>
      )}
      <NewNumberModal
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        handleSubmit={newModalHandler}
        content={content}
        {...props}
      />
      <HelpSupportModal
        open={openSupportDialog}
        onClose={() => setOpenSupportDialog(false)}
        title={c_sup_title}
        showClose={true}
        showBackBtn={false}
        content={content}
      />
    </Box>
  );
};
