import { Typography } from "@mui/material";
import { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";
import { useAppSelector } from "../../../app/hooks";
import { globalVal } from "../../../utils/globalValues/globalvalues";

interface HelmetProps {
  data: any;
  brandName: string;
  children?: ReactNode;
}
export const RenderHeaderTag = ({ h1_tag }: any) => {
  return (
    <Typography variant="h1" component="h1" hidden>
      {h1_tag}
    </Typography>
  );
};

export const HelmetCust = ({ data, brandName, children }: HelmetProps) => {
  const { title, description, keywords, robots } = data || {};
  const { seoData } = useAppSelector(
    (state: any) => state?.strapi?.commonPage || {}
  );

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="icon" href={seoData?.brandLogo?.data?.attributes?.url} />
        <title>{title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" name="title" content={title} />
        <meta
          property="og:description"
          name="description"
          content={description}
        />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta property="og:site_name" name="site_name" content={brandName} />
        <meta name="keywords" content={keywords} />
        <meta name="description" content={description} />
        <meta
          name="robots"
          content={`${globalVal.is_prod_env ? robots : "NO INDEX NO FOLLOW"}`}
        />
        {children}
      </Helmet>
    </HelmetProvider>
  );
};
