import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import { appSectionContent } from "../../../features/strapi/homePageSlice";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
const AppSectionCmp = isTemplateExisits(Config.Home.AppSection) ?
  require(`./${Config.Home.AppSection.template}_AppSection`).default : null;

export const AppSection = () => {
  const {
    homepage: { appSection },
  } = useAppSelector((state: any) => state.strapi);
  const [cacheDataApp, setCacheDataApp] = useState<any>(null)
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache('AppSecHome', appSectionContent, setCacheDataApp, dispatch)
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData('AppSecHome', appSection)
  }, [appSection])
  return <AppSectionCmp appSection={cacheDataApp ? cacheDataApp : appSection} />;
}
