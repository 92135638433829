import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch } from "../../../app/hooks";
import { ourPartnersContent } from "../../../features/strapi/giveBackslice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
export type whereWeGiveProps = {
  content?: any;
};

const WhereWeGiveComponent = isTemplateExisits(Config.Home.PlanFeatures)
  ? require(`./${Config.Home.PlanFeatures.template}_WhereWeGive`).default
  : null;

export const WhereWeGive = () => {
  const [cacheDataGbWhereWeGive, setCacheDataGbWhereWeGive] = useState<any>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("OurPartnersGiveBack", ourPartnersContent, setCacheDataGbWhereWeGive, dispatch)
  }, []);
 
  return (cacheDataGbWhereWeGive) && 
  <WhereWeGiveComponent content={cacheDataGbWhereWeGive} />;
};