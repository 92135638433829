import { AppBar } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import styles from "./S2_Footer.module.scss";

type Props = {
  isFixed?: boolean;
  GAAndFBEventsHandler: any;
  customerDetail: any;
  footerContent: any;
  onClickHandler: any;
};

const FooterComponent = (props: Props) => {
  const location = useLocation();
  const { customerDetail, footerContent, GAAndFBEventsHandler } = props;
  const {
    footer_t2,
    logo_style,
    subLinks,
    disclaimer_text,
    copyright_text,
    version_indicator,
    subLinkHightlight,
    footerList_st,
  } = styles;

  const {
    footerListDetails = [],
    copyright,
    versionNumber,
    disclaimer,
    logo,
    showDisclaimer,
    footerText,
    newText,
    showVersion,
  } = footerContent || {};

  const footerList = (val: any) => {
    const returnSec = (
      <div className={subLinks} role="button">
        {val.name}
        {val.isNew && (
          <span className={`${subLinkHightlight} px-1 mx-1 rounded-1`}>
            {newText}
          </span>
        )}
      </div>
    );
    const resultSet = (
      <>
        {val.isLink ? (
          <Link
            to={val.navigationPath}
            onClick={() => GAAndFBEventsHandler(footerText, val.gaName)}
          >
            {returnSec}
          </Link>
        ) : (
          <a href={val.navigationPath} target="_blank" rel="noreferrer">
            {returnSec}
          </a>
        )}
      </>
    );
    return (
      <>
        <div>{resultSet}</div>
        <div className={footerList_st}>{resultSet}</div>
      </>
    );
  };

  const imageSection = () => {
    return (
      <div>
        <div>
          <img
            alt=""
            src={logo?.data?.attributes?.url || ""}
            className={logo_style}
          />
        </div>
      </div>
    );
  };
  return location.pathname === "/checkout" ? (
    <></>
  ) : (
    <AppBar position={"static"} className={footer_t2}>
      <div>
        <div>
          <div>
            {imageSection()}
            <div>
              {footerListDetails?.map((val: any, index: number) => {
                if (index === 0) {
                  return customerDetail && customerDetail?.simPaid
                    ? null
                    : footerList(val);
                } else return footerList(val);
              })}
            </div>
          </div>
          <div className={copyright_text}>{copyright}</div>
          {showDisclaimer && (
            <div className={disclaimer_text}>{disclaimer}</div>
          )}
        </div>
      </div>
      {showVersion && <div className={version_indicator}>{versionNumber}</div>}
    </AppBar>
  );
};

export default FooterComponent;
