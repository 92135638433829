import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

type Props = {
  src: any;
  alt: string;
  width?: any;
  height?: any;
  classNames?: any;
  style?: any;
  effect?: string;
};

export const LazyLoadImageCust = (props: Props) => {
  const { alt, height, src, width, classNames, style, effect } = props;
  return (
    <LazyLoadImage
      src={src}
      alt={alt}
      {...(width && { width: width })}
      {...(height && { height: height })}
      className={classNames}
      effect={effect || "blur"}
      style={{ verticalAlign: "bottom", ...style }}
    />
  );
};
