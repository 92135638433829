import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import Config from "../../config/app.config.json";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import get from "lodash/get";

type InitialState = {
  strapiContent: any;
  customerHeaderContent: any;
  errorCustomerHeaderContent: any;
  errorStrapiContent: any;
  headerContent: any;
  errorStrapiHeaderContent: any;
  strapiFooterContent: any;
  errorStrapiFooterContent: any;
  footerContent: any;
  BannerContent: any;
  errorBannerContent: any;
  seoData: any;
  errorSeoData: any;
  notification: any;
  errorNotification: any;
};
const initialState: InitialState = {
  strapiContent: null,
  errorStrapiContent: null,
  headerContent: null,
  errorStrapiHeaderContent: null,
  strapiFooterContent: null,
  errorStrapiFooterContent: null,
  footerContent: null,
  BannerContent: null,
  errorBannerContent: null,
  customerHeaderContent: null,
  errorCustomerHeaderContent: null,
  seoData: null,
  errorSeoData: null,
  notification: null,
  errorNotification: null,
};

// Generates pending, fulfilled and rejected action types
export const strapiPageContent = createAsyncThunk(
  "strapi/strapiPageContentName",
  (_action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${_action}-pages?populate=*`,
      isStrapiApi: true,
    });
  }
);
export const strapiHeaderContent = createAsyncThunk(
  "strapi/strapiHeaderContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Header.template}${StrapiApiEndPoints.header}`,
      isStrapiApi: true,
    });
  }
);
export const getCustomerHeaderContent = createAsyncThunk(
  "strapi/getCustomerHeaderContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Header.template}${StrapiApiEndPoints.headerCustomer}`,
      isStrapiApi: true,
    });
  }
);
export const strapiFooterContent = createAsyncThunk(
  "strapi/strapiFooterContent",
  () => {
    const tempName: any = `${Config.Footer.template}_footer`.toLowerCase();
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Footer.template}${StrapiApiEndPoints[tempName]}`,
      isStrapiApi: true,
    });
  }
);

export const strapiBannerContent = createAsyncThunk(
  "strapi/BannerContent",
  (action: any) => {
    let { pageName, apiEndPoint } = action;
    let template = get(Config, `${pageName}.Banner.template`);
    return apiJunction.makeRequest({
      method: "get",
      url: `/${template}${StrapiApiEndPoints[`${apiEndPoint}`]}`,
      isStrapiApi: true,
    });
  }
);

export const getSeoDataContent = createAsyncThunk(
  "strapi/getSeoDataContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.SeoData.template}${StrapiApiEndPoints.seoData}`,
      isStrapiApi: true,
    });
  }
);

export const notificationsContent = createAsyncThunk(
  "strapi/notificationsContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Header.template}${StrapiApiEndPoints.notifications}`,
      isStrapiApi: true,
    });
  }
);

const componentSlice = createSlice({
  name: "strapi",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      strapiPageContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.strapiContent = action.payload;
        state.errorStrapiContent = null;
      }
    );
    builder.addCase(strapiPageContent.rejected, (state, action) => {
      state.strapiContent = null;
      state.errorStrapiContent = action.error || "Something went wrong";
    });
    builder.addCase(
      strapiFooterContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.footerContent = action.payload?.data?.data?.attributes || null;
        state.errorStrapiContent = null;
      }
    );
    builder.addCase(strapiFooterContent.rejected, (state, action) => {
      state.footerContent = null;
      state.errorStrapiContent = action.error || "Something went wrong";
    });
    builder.addCase(strapiHeaderContent.pending, () => {});
    builder.addCase(
      strapiHeaderContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.headerContent = action.payload.data.data.attributes;
        // state.errorCoverageStatus = null;
        // state.coverageLoading = false;
      }
    );
    builder.addCase(strapiHeaderContent.rejected, () => {
      // state.coverageStatus = {};
      // state.errorCoverageStatus = action.error || "Something went wrong";
      // state.coverageLoading = false;
    });
    builder.addCase(getCustomerHeaderContent.pending, (state: any) => {
      (state.customerHeaderContent = null),
        (state.errorCustomerHeaderContent = null);
    });
    builder.addCase(
      getCustomerHeaderContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        (state.customerHeaderContent = action.payload.data.data.attributes),
          (state.errorCustomerHeaderContent = null);
      }
    );
    builder.addCase(getCustomerHeaderContent.rejected, (state) => {
      (state.customerHeaderContent = null),
        (state.errorCustomerHeaderContent = null);
    });
    builder.addCase(
      strapiBannerContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.BannerContent = action.payload?.data?.data?.attributes || null;
        state.errorBannerContent = null;
      }
    );
    builder.addCase(strapiBannerContent.rejected, (state, action) => {
      state.BannerContent = null;
      state.errorBannerContent = action.error || "Something went wrong";
    });
    builder.addCase(
      getSeoDataContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.seoData = action.payload?.data?.data?.attributes || null;
        state.errorSeoData = null;
      }
    );
    builder.addCase(getSeoDataContent.rejected, (state, action) => {
      state.seoData = null;
      state.errorSeoData = action.error || "Something went wrong";
    });
    builder.addCase(
      notificationsContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.notification = action.payload?.data?.data?.attributes || null;
        state.errorNotification = null;
      }
    );
    builder.addCase(notificationsContent.rejected, (state, action) => {
      state.notification = null;
      state.errorNotification = action.error || "Something went wrong";
    });
  },
});

export default componentSlice.reducer;
