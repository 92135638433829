import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Stack,
  Divider,
} from "@mui/material";
import { getIsCardExpired } from "../../../utils/commonFunctions";
import { ButtonCust } from "../Button";
import { ManageProps } from "./ManageCards";
import EnvConfig from "../../../config/env.config.json";

const T2_ManageCards = (props: ManageProps) => {
  const {
    manageCardDetails,
    cardData,
    payType,
    selectOpenHandler,
    getCardsInOrder,
    openHandler,
    setShowAutoPayConsentModal,
  } = props;

  const {
    tile_heading,
    tile_description,
    saved_cards_title,
    no_saved_cards,
    visa,
    master_card,
    jcb,
    american_express,
    discover,
    update_card,
    remove_card,
    make_card_default,
    enable_autopay,
    default_text,
    autopay_pref_title,
    autopay_pref_desc,
    autopay_pref_cc_team,
    add_card_btn,
    autopay_pref_cc_url,
  } = manageCardDetails;

  const getLogo = (data: any, type?: string) => {
    switch (data?.type?.toLowerCase()) {
      case "visa":
        return type === "src"
          ? visa?.data.attributes.url
          : visa?.data.attributes.alternativeText;
      case "mastercard":
        return type === "src"
          ? master_card?.data.attributes.url
          : master_card?.data.attributes.alternativeText;
      case "jcb":
        return type === "src"
          ? jcb?.data.attributes.url
          : jcb?.data.attributes.alternativeText;
      case "american express":
        return type === "src"
          ? american_express?.data.attributes.url
          : american_express?.data.attributes.alternativeText;
      case "discover":
        return type === "src"
          ? discover?.data.attributes.url
          : discover?.data.attributes.alternativeText;
      default:
        break;
    }
  };

  const getDefaultText = (card: any) => {
    if (cardData?.length === 1)
      return !card.isAutoPay ? enable_autopay : make_card_default;
    else if (cardData?.length > 1) {
      let autoPayCards = cardData.filter((el: any) => {
        return el.isAutoPay === true;
      }).length;
      return autoPayCards ? make_card_default : enable_autopay;
    }
  };

  const AvailableCards = (props: any) => {
    const { each, cardIsExpired } = props;

    return (
      <Card
        variant="outlined"
        sx={{
          height: "114px",
          p: "5px 15px",
          fontSize: "14px",
          color: "var(--text_color_8)",
          fontFamily: "var(--font_family_Medium)",
          border: ` ${
            each.isDefault
              ? "1px solid var(--primary_color)"
              : "1px solid var(--border_color_2)"
          }  `,
          borderRadius: "0",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            my: "12px",
          }}
        >
          <Typography
            sx={{ fontSize: "inherit", fontFamily: "inherit" }}
            component="span"
          >
            XXXX-XXXX-XXXX-{each.ccNumberMask}
          </Typography>
          <Box
            component="img"
            src={getLogo(each, "src")}
            alt={getLogo(each)}
            width="45px"
          />
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            my: "12px",
          }}
        >
          <Typography
            sx={{ fontSize: "inherit", fontFamily: "inherit" }}
            component="span"
          >
            {each?.nameOnCard}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography
            sx={{ fontSize: "inherit", fontFamily: "inherit" }}
            component="span"
          >
            {cardIsExpired ? "Expired date" : "Expires on"}&nbsp;
            {each && each.dateOfExpiry && each.dateOfExpiry.slice(0, 2)}/
            {each && each.dateOfExpiry && each.dateOfExpiry.slice(2)}
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            my: "12px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontFamily: "inherit",
              color: "var(--primary_color)",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => openHandler(each)}
            component="span"
          >
            {update_card}
          </Typography>
          {!each.isAutoPay && (
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "inherit",
                color: "var(--primary_color)",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() =>
                selectOpenHandler(manageCardDetails?.remove_card, each, false)
              }
              component="span"
            >
              {remove_card}
            </Typography>
          )}
          {!each.isAutoPay && (
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "inherit",
                color: "var(--primary_color)",
                cursor: `${
                  cardIsExpired ? "not-allowed !important" : "pointer"
                }`,
                textDecoration: "underline",
              }}
              onClick={() => {
                if (!cardIsExpired)
                  selectOpenHandler(manageCardDetails?.set_default, each, true);
              }}
              component="span"
            >
              {getDefaultText(each)}
            </Typography>
          )}
          {each.isDefault && payType && (
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "inherit",
                color: "var(--text_color_8)",
              }}
              component="span"
            >
              {default_text}
            </Typography>
          )}
        </Stack>
      </Card>
    );
  };
  const raiseTicketHandler = (url: any) => {
    let customerDetails: any = localStorage.getItem("customerDetail");
    if (customerDetails) customerDetails = JSON.parse(customerDetails);
    if (customerDetails) {
      let custAdd: any = [];
      for (const key in customerDetails?.addresses[1]) {
        if (key !== "name" && key !== "type" && key !== "phone")
          custAdd.push(customerDetails?.addresses[1][key]);
      }
      custAdd = custAdd.join(", ");
      window.open(
        `${url}?firstname=${customerDetails?.firstName}&lastname=${customerDetails?.lastName}&address=${custAdd}&reach_number=${customerDetails?.primaryNumber}&email=${customerDetails?.emailId}`,
        "_blank"
      );
    }
  };
  return (
    manageCardDetails && (
      <Container
        sx={{
          backGround: "var(--lead_generation_bg_color)",
          width: "100%",
          height: "100%",
          p: { xs: "5px", sm: "0px 60px 60px 60px" },
        }}
      >
        <Paper
          variant="outlined"
          square
          sx={{
            p: {
              xs: "12px",
              sm: "25px 50px",
              border: "2px solid var(--border_color_2)",
            },
          }}
        >
          <Typography
            variant="h5"
            component={"h5"}
            sx={{
              fontSize: "24px",
              fontFamily: "var(--font_family_Medium)",
              textAlign: "center",
            }}
          >
            {tile_heading}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              textAlign: "center",
              my: "10px",
              fontFamily: "var(--font_family_Semibold)",
            }}
          >
            {tile_description}
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--font_family_Medium)",
              color: "var(--text_color_8)",
              fontSize: "20px",
              mt: "20px",
              mb: "10px",
            }}
          >
            {saved_cards_title}
          </Typography>
          <Grid container spacing={3}>
            {cardData?.length > 0 ? (
              getCardsInOrder(cardData).map((each: any, ind: number) => {
                let cardIsExpired = getIsCardExpired(each);
                return (
                  <Grid item xs={12} sm={6} md={4} key={ind}>
                    <AvailableCards each={each} cardIsExpired={cardIsExpired} />{" "}
                  </Grid>
                );
              })
            ) : (
              <Typography
                component={"div"}
                sx={{
                  fontSize: "16px",
                  fontFamily: "var(--font_family_Medium)",
                  color: "var(--text_color_8)",
                  justifyContent: "center",
                  my: "20px",
                  mx: "auto",
                }}
              >
                {no_saved_cards}
              </Typography>
            )}
          </Grid>
          <Card elevation={3} variant="elevation" sx={{ my: 3 }}>
            <CardHeader
              title={autopay_pref_title}
              titleTypographyProps={{
                variant: "h5",
                fontSize: "20px",
                fontFamily: "var(--font_family_Semibold)",
                color: "var(--text_color_8)",
              }}
              sx={{
                border: "5px solid var(--lead_generation_bg_color)",
                p: "8px",
              }}
            />
            <CardContent>
              <Typography
                variant="subtitle1"
                pb={"35px"}
                sx={{
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontFamily: "var(--font_family_Semibold)",
                }}
              >
                {autopay_pref_desc}&nbsp;
                <Typography
                  variant="subtitle1"
                  component={"span"}
                  sx={{
                    color: "var(--primary_color)",
                    cursor: "pointer",
                    fontSize: "inherit",
                    fontFamily: "inherit",
                    textDecoration: "underline",
                  }}
                  onClick={() => raiseTicketHandler(autopay_pref_cc_url)}
                >
                  {autopay_pref_cc_team}
                </Typography>
              </Typography>
            </CardContent>
          </Card>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ButtonCust
              variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
              sx={{
                my: "15px",
                width: "20%",
              }}
              onClick={() => setShowAutoPayConsentModal(true)}
            >
              {add_card_btn}
            </ButtonCust>
          </Stack>
        </Paper>
      </Container>
    )
  );
};

export default T2_ManageCards;
