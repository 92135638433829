import { Box, Grid, Typography } from "@mui/material";
import { ButtonCust } from "../../../widgets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import styles from "./InitialActivation.module.scss";
import clsx from "clsx";
import { ActivationStatus } from "../../../../enums";

export const InitialSecondayLine = (props: any) => {
  const { handleStep, currentLine, content, updatingCustomer } = props;
  const [displayFAQ, setDisplayFAQ] = useState(false);
  const letsGoHandler = () => {
    if (currentLine?.esim) {
      updatingCustomer({
        activationJourneyStatus: ActivationStatus.IN_PROGRESS,
      });
    }
    handleStep(true);
  };
  const {
    intro_btn,
    sec_title1,
    sec_title2,
    sec_desc1,
    sec_desc2,
    faq_txt,
    dataObject,
    infoData,
    tick_icon,
    arrowRt_icon,
    esim_points,
  } = content || {};
  const introPoints = currentLine?.esim ? esim_points : infoData;

  return (
    <>
      <Box className={styles.activation_main}>
        <Box className={styles.activation_header}>
          <ArrowBackIcon
            sx={{ ml: { md: "30px", sm: "25px", xs: "25px" } }}
            className={styles.arrow_icon}
            onClick={() => handleStep(false)}
          />
          <Typography className={styles.title_main}>
            {sec_title1} {currentLine?.firstName}
            {sec_title2}
          </Typography>
        </Box>
        <Box className={styles.initial_activation_main}>
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} textAlign="center">
              <Typography
                sx={{ display: { xs: "block", sm: "inline" } }}
                className={styles.title_main_activation}>
                {sec_desc1} {currentLine?.firstName} {sec_desc2}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className={styles.activation_body_content}
            mt={{ xs: "20px" }}>
            {introPoints?.map((data: any) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={6}
                  lg={5}
                  className={styles.activation_info}
                  key={data.id}>
                  <img
                    className={styles.check_icon}
                    src={tick_icon?.data?.attributes?.url}
                    alt={tick_icon?.data?.attributes?.alternativeText}
                  />
                  <Typography className={styles.activation_body_text}>
                    {data?.desc}
                    {data?.desc2 && (
                      <Box
                        component="span"
                        className={styles.link_text}
                        onClick={() => window.open(data.link_url, "_blank")}>
                        {data?.desc2}
                      </Box>
                    )}
                  </Typography>
                </Grid>
              );
            })}
            <Box display="flex">
              <Box className={styles.title}>{faq_txt}</Box>
              <Box
                component="img"
                src={arrowRt_icon?.data?.attributes?.url}
                alt={arrowRt_icon?.data?.attributes?.alternativeText}
                className={styles.arrow_icon_right}
                onClick={() => setDisplayFAQ(!displayFAQ)}></Box>
            </Box>

            {displayFAQ &&
              dataObject?.map((dataObj: any) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    lg={5}
                    className={styles.activation_info}
                    key={dataObj.id}>
                    <img
                      className={styles.check_icon}
                      src={tick_icon?.data?.attributes?.url}
                      alt={tick_icon?.data?.attributes?.alternativeText}
                    />
                    <Box pt={0.2}>
                      <Typography
                        className={clsx(
                          styles.activation_body_text,
                          styles.question_text
                        )}>
                        {dataObj.text1}
                      </Typography>
                      <Typography
                        sx={{ paddingTop: "4px" }}
                        className={styles.activation_body_text}>
                        {dataObj.text2}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            <Box className={styles.btn}>
              <ButtonCust onClick={() => letsGoHandler()}>
                {intro_btn}
              </ButtonCust>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
