import { Grid, Typography, Box } from "@mui/material";
import { useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { ButtonCust, SimpleModal } from "../../../widgets";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import EnvConfig from "../../../../config/env.config.json";

type ChangePlanProceedDialogProps = {
  open1: boolean;
  selectedPlan: any;
  unlimitedPlanSelected: boolean;
  sharedPlanSelected: boolean;
  lowerPlanSelected: boolean;
  setOpen1: any;
  ChangePlanProceedHandler: any;
  cppdContent?: any;
};

export const ChangePlanProceedDialog = (
  props: ChangePlanProceedDialogProps
) => {
  const {
    open1,
    unlimitedPlanSelected,
    sharedPlanSelected,
    lowerPlanSelected,
    setOpen1,
    ChangePlanProceedHandler,
    cppdContent,
  } = props;

  const [load, setLoad] = useState(false);
  const { isLoadedPlanTaxCharges } = useAppSelector((state) => state.checkout);
  const closeModal = () => {
    setOpen1(false);
  };
  const { less_than_plan, greater_than_plan, unlimited_plan, subtext_arr } =
    cppdContent;
  const UnlimitedPlanContent = {
    title: unlimited_plan?.title,
    sub_title: unlimited_plan?.sub_title,
    subTextArray: [
      {
        text: subtext_arr[0]?.item,
      },
      {
        text: subtext_arr[1]?.item,
      },
      {
        text: subtext_arr[2]?.item,
      },
    ],
    note_text: unlimited_plan?.note_text,
    button: unlimited_plan?.button,
  };
  const ChangePlanProceed = (props: any) => {
    const { title, subText, button, sub_title, subTextArray, note_text } =
      props;
    const GAAndFBEventsHandler = (category: string, value: string) => {
      pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
        appName: EnvConfig.brand,
        screenName: gaScreenNames.account,
        category: category,
        buttonValue: value,
      });
    };
    return (
      <Grid>
        <Grid
          item
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: " var(--font_weight_4)",
              fontFamily: " var(--font_family_Bold)",
              padding: "0px 0px 15px",
            }}
          >
            {title}
          </Typography>
          {sub_title && (
            <Typography
              sx={{
                fontFamily: "var(--font_family_Medium)",
                fontSize: "14px",
                fontWeight: "var(--font_weight_1)",
              }}
            >
              {sub_title}
            </Typography>
          )}
        </Grid>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontSize: "14px",
              padding: "5px 0px 20px",
              fontWeight: "var(--font_weight_0)",
              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {subText}
          </Typography>
          {subTextArray && (
            <Box sx={{ pb: "15px" }}>
              {subTextArray.map((subTextArray: any, index: any) => {
                return (
                  <Typography
                    key={index}
                    sx={{
                      textAlign: "left",
                      pt: "5px",
                      pb: "10px",
                      fontFamily: "var(--font_family_Medium)",
                      fontSize: "14px",
                      fontWeight: "var(--font_weight_1)",
                    }}
                  >
                    {index + 1}. {subTextArray.text}
                  </Typography>
                );
              })}
            </Box>
          )}
          {note_text && (
            <Typography
              sx={{
                textAlign: "center",
                pt: "5px",
                pb: "20px",
                fontFamily: "var(--font_family_Medium)",
                fontSize: "14px",
                fontWeight: "var(--font_weight_1)",
              }}
            >
              <span
                style={{
                  fontFamily: "var(--font_family_Bold)",
                  fontSize: "14px",
                }}
              >
                Note:
              </span>
              {note_text}
            </Typography>
          )}
          <ButtonCust
            variantType="primary_outlined_rounded"
            loading={isLoadedPlanTaxCharges}
            onClick={() => {
              ChangePlanProceedHandler();
              GAAndFBEventsHandler(gaCategory.changePlan, button);
            }}
          >
            {button}
          </ButtonCust>
        </Box>
      </Grid>
    );
  };
  return (
    <SimpleModal
      isShowModal={open1}
      onCloseModal={closeModal}
      showClose={true}
      sx={{
        width: {
          xs: "93%",
          sm: "69%",
          md: "48%",
          lg: "38%",
          xl: "30%",
        },
      }}
    >
      {/* change plan confirmation dialog based on selection */}
      {lowerPlanSelected ? <ChangePlanProceed {...less_than_plan} /> : null}
      {sharedPlanSelected ? <ChangePlanProceed {...greater_than_plan} /> : null}
      {unlimitedPlanSelected ? (
        <ChangePlanProceed {...UnlimitedPlanContent} />
      ) : null}
    </SimpleModal>
  );
};
