import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  paymentLandingContent: any;
  errorpaymentLandingContent: any;
  cancelInstallContent: any;
  errCancelInstallContent: any;
};

const initialState: InitialState = {
  paymentLandingContent: null,
  errorpaymentLandingContent: null,
  cancelInstallContent: null,
  errCancelInstallContent: null,
};

// Generates pending, fulfilled and rejected action types
export const getPaymentLandingContent = createAsyncThunk(
  "paymentLanding",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.PaymentLanding.PaymentLanding.template}${StrapiApiEndPoints.paymentLanding}`,
      isStrapiApi: true,
    });
  }
);

export const getCancelInstallContent = createAsyncThunk(
  "paymentLanding/getCancelInstallContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.PaymentLanding.CancelInstallation.template}${StrapiApiEndPoints.cancelInstallation}`,
      isStrapiApi: true,
    });
  }
);

const strapiPaymentLandingSlice = createSlice({
  name: "paymentLanding",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      getPaymentLandingContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.paymentLandingContent = action.payload;
        state.errorpaymentLandingContent = null;
      }
    );
    builder.addCase(
      getPaymentLandingContent.rejected,
      (state: InitialState, action: any) => {
        state.paymentLandingContent = null;
        state.errorpaymentLandingContent =
          action.error || "Something went wrong";
      }
    );
    builder.addCase(
      getCancelInstallContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.cancelInstallContent =
          action?.payload?.data?.data?.attributes || null;
        state.errCancelInstallContent = null;
      }
    );
    builder.addCase(
      getCancelInstallContent.rejected,
      (state: InitialState, action: any) => {
        state.cancelInstallContent = null;
        state.errCancelInstallContent = action.error || "Something went wrong";
      }
    );
  },
});

export default strapiPaymentLandingSlice.reducer;
