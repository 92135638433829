import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { Divider, ButtonCust } from "../../../widgets";
import styles from "./PayNow.module.scss";
import { CardTypes } from "../../../../enums";
import { monthInAdvance } from "../../../../features/activation/activationSlice";
import { getCC } from "../../../../features/account/accountSlice";
import { getIsCardExpired } from "../../../../utils/commonFunctions/ReusableFunctions";

export const ActivationPayNow = (props: any) => {
  const { currentLine, payNowConfirm, payBtnLoader, manageCardFg, content } =
    props;
  const [autoPayCard, setAutoPayCard] = useState<any>({});
  const { monthInAdvanceData } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const {
    title,
    info_txt,
    chng_btn,
    due_txt,
    fee_txt,
    discount_txt,
    pay_btn,
    visa,
    american_ex,
    promo_img,
  } = content?.pay_now;
  const { getCCDetails } = useAppSelector((state: any) => state?.account || {});
  const dispatch = useAppDispatch();
  let isCardExpired = false;
  let actData: any[] = [
    {
      text: currentLine?.reachPlanId,
      value: monthInAdvanceData?.amount ?? "-",
      id: 1,
    },
    {
      text: discount_txt,
      value:
        monthInAdvanceData?.promoDiscount > 0
          ? monthInAdvanceData?.promoDiscount
          : null,
      id: 3,
    },
    {
      text: fee_txt,
      value: monthInAdvanceData?.taxAmount ?? "-",
      id: 2,
    },
    {
      text: due_txt,
      value: monthInAdvanceData?.amountToPay ?? "-",
      id: 4,
    },
  ];

  useEffect(() => {
    if (getCCDetails) {
      getCCDetails.map((card: any) => {
        if (card.isAutoPay) {
          setAutoPayCard(card);
          return null;
        } else {
          return null;
        }
      });
    }
  }, [getCCDetails]);

  if (autoPayCard?.dateOfExpiry) {
    isCardExpired = getIsCardExpired(autoPayCard);
  }
  useEffect(() => {
    let addCardActivation = localStorage.getItem("addCardActivation");
    if (!getCCDetails || !monthInAdvanceData) {
      const py: any = {
        customerId: currentLine?.id,
      };
      dispatch(monthInAdvance({ payload: py })).then((res) => {
        if (res?.payload?.status === 200) {
          if (addCardActivation) {
            manageCardFg();
            localStorage.removeItem("addCardActivation");
          }
        }
      });
      const customerDetail: any = JSON.parse(
        localStorage?.getItem("customerDetail") || "null"
      );
      dispatch(getCC({ customerId: customerDetail?.id }));
    }
  }, []);
  return (
    <Stack>
      {currentLine?.reachPlanId && (
        <>
          <Box
            className={styles.activation_paynow_card}
            m="20px auto 50px"
            sx={{ width: { xs: "90%", sm: "90%", md: "80%", lg: "80%" } }}>
            <Box
              className={styles.main_container}
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center">
              <Typography className={styles.heading} mt="20px">
                {title}
              </Typography>
              <Divider
                styles={{
                  width: "75px !important",
                  height: "5px",
                  borderRadius: "25px !important",
                  m: "10px 0 35px 0",
                }}
              />
              {!monthInAdvanceData ? (
                <Box
                  className={styles.loaderCheck}
                  sx={{ marginBottom: "20px" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {actData?.map((each) => (
                    <>
                      {each?.id === 3 && each?.value <= 0 ? null : (
                        <Box
                          className={styles.row}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p="15px"
                          m="10px"
                          sx={{
                            width: {
                              xs: "80%",
                              sm: "75%",
                              md: "60%",
                              lg: "45%",
                            },
                          }}
                          key={each.id}>
                          <Typography className={styles.row_text}>
                            {each.text}
                          </Typography>
                          <Typography className={styles.row_value}>
                            {each.id === 3
                              ? `-${GetCurrencyFixedToTwo(each.value)}`
                              : GetCurrencyFixedToTwo(each.value)}
                          </Typography>
                        </Box>
                      )}
                    </>
                  ))}
                  {monthInAdvanceData?.couponName && (
                    <Box
                      className={styles.promo_coupon}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      p="16px"
                      m="20px"
                      sx={{
                        width: {
                          xs: "80%",
                          sm: "75%",
                          md: "60%",
                          lg: "45%",
                        },
                      }}>
                      <img
                        src={promo_img?.data?.attributes?.url}
                        alt={promo_img?.data?.attributes?.alternativeText}
                        style={{
                          width: "22px",
                          height: "22px",
                          marginRight: "10px",
                        }}
                      />
                      {monthInAdvanceData?.couponName}
                    </Box>
                  )}
                  <Typography
                    className={styles.text1}
                    mb="16px"
                    sx={{
                      width: { xs: "90%", sm: "75%", md: "60%", lg: "45%" },
                    }}>
                    {info_txt}
                  </Typography>
                  {autoPayCard?.ccNumberMask ? (
                    <>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={styles.image_text_container}>
                        <img
                          src={
                            autoPayCard && autoPayCard.type === CardTypes.VISA
                              ? visa?.data?.attributes?.url
                              : american_ex?.data?.attributes?.url
                          }
                          alt={
                            autoPayCard && autoPayCard.type === CardTypes.VISA
                              ? visa?.data?.attributes?.alternativeText
                              : american_ex?.data?.attributes?.alternativeText
                          }
                          style={{ width: "42px", height: "27px" }}
                        />
                        <Typography className={styles.text2} pl="10px">
                          XXXX-XXXX-XXXX- {autoPayCard.ccNumberMask}
                        </Typography>
                        <Typography
                          className={styles.text3}
                          ml="10px"
                          onClick={manageCardFg}>
                          {chng_btn}
                        </Typography>
                      </Box>

                      <ButtonCust
                        sx={{ m: "20px 0 30px 0", padding: "13px 23px" }}
                        onClick={payNowConfirm}
                        loading={payBtnLoader}
                        disabled={isCardExpired}>
                        {pay_btn}
                      </ButtonCust>
                    </>
                  ) : (
                    <Box sx={{ pt: 4 }}></Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </Stack>
  );
};
