import { BestFitTpProps } from "./";
import styles from "./S1_BestFitTp.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { TrustPilot, TrustPilotType } from "../../common";
const BestFitTpComponent = (props: BestFitTpProps) => {
  const { heading, post_title } = props?.content || {};
  const { isTitleRequired } = props;
  return props?.content  && (
    <Grid
      container
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      className={styles.best_fit_tp}
    >
      <Grid
        item
        xs={12}
        className={styles.trsut_pilot_content}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          pt: "50px",
        }}
      >
        <Box sx={{ px: { xs: "5px", sm: "0px" } }}>
          <Typography
            variant="body1"
            className={styles.trsut_pilot_content_title}
            sx={{ fontSize: { xs: "20px", sm: "26px" } }}
          >
            {isTitleRequired ? post_title : heading || ""}
          </Typography>
        </Box>
        <Box
          sx={{ m: "20px 0 40px" }}
          className={styles.trsut_pilot_line}
        ></Box>
      </Grid>
      <Grid item xs={12}>
        <TrustPilot type={TrustPilotType.LIST} />;
      </Grid>
    </Grid>
  );
};
export default BestFitTpComponent;
