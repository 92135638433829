import { Box, Link, Typography } from "@mui/material";
import styles from "./SupportModal.module.scss";
import { SimpleModal } from "../../../widgets";
import { globalVal } from "../../../../utils/globalValues/globalvalues";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { useAppSelector } from "../../../../app/hooks";

type IsupportModalProps = {
  open: boolean;
  onClose: any;
  title: string;
  showClose: boolean;
  showBackBtn: boolean;
  content: any;
};

export const HelpSupportModal = (props: IsupportModalProps) => {
  const { open, onClose, title, showClose, showBackBtn, content } = props;
  const [closeBtn, setCloseBtn] = useState(showClose);
  const { chat_txt, day_tm, chat_img, week_tm, call_img, mail_img, mail_id } =
    content?.sup_md;
  const data = [
    {
      title: chat_txt,
      subtitle: day_tm,
      img: chat_img?.data?.attributes?.url,
      link: "#hs-chat-open",
      rel: "noopener noreferrer",
    },
    {
      title: globalVal.phoneNumber,
      subtitle: week_tm,
      img: call_img?.data?.attributes?.url,
      link: `tel:${globalVal.phoneNumber}`,
    },
  ];
  return (
    <SimpleModal
      isShowModal={open}
      onCloseModal={onClose}
      showClose={closeBtn}
      sx={{
        borderRadius: "4.8px",
        maxWidth: { xs: "95%", sm: "500px" },
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      className={styles.help_modal_main}
    >
      <Box className={styles.dialog_main}>
        <Box className={styles.back_arrow}>
          {showBackBtn ? (
            <ArrowBackIcon className={styles.arrow_icon} onClick={onClose} />
          ) : null}
        </Box>
        <Typography className={styles.dialog_activation_modal_heading}>
          {title}
        </Typography>
        <Box className={styles.dialog_activation_modal__bottom_border}></Box>
        <Box
          className={styles.support_center_row}
          justifyContent={{
            xs: "start !important",
            sm: "center !important",
          }}
          marginLeft={{ xs: "15px" }}
          flexDirection={{
            xs: "column",
            sm: "row",
          }}
        >
          {data.map((item: any) => {
            return (
              <Box
                className={styles.support_individual_row}
                justifyContent={{
                  xs: "start !important",
                  sm: "center !important",
                }}
                key={item.title}
              >
                <img className={styles.sub_title_img} src={item.img} alt="" />
                <Box className={styles.subheading}>
                  <Link
                    href={item.link}
                    className={styles.dialog_sub_title_one}
                    underline="none"
                    rel={item.rel ? item.rel : null}
                  >
                    {item.title}
                  </Link>
                  <Typography className={styles.dialog_sub_title_two}>
                    {item.subtitle}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box
          className={styles.dialog_support_bottom_row}
          justifyContent={{ xs: "start !important", sm: "center !important" }}
        >
          <img
            className={styles.sub_title_img}
            src={mail_img?.data?.attributes?.url}
            alt={mail_img?.data?.attributes?.alternativeText}
          />
          <Box height={"24px"}>
            <a
              href="mailto:reach@reachmobile.com"
              className={styles.dialog_sub_title_two}
            >
              {mail_id}
            </a>
          </Box>
        </Box>
      </Box>
    </SimpleModal>
  );
};
