import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  installTrackerContent: any;
  errInstallTrackerContent: any;
};

const initialState: InitialState = {
  installTrackerContent: null,
  errInstallTrackerContent: null,
};

export const getInstallTrackerContent = createAsyncThunk(
  "installTracker/getInstallTrackerContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.InstallationTracker.InstallationTracker.template}${StrapiApiEndPoints.installationTracker}`,
      isStrapiApi: true,
    });
  }
);

const strapiInstallTrackerSlice = createSlice({
  name: "installTracker",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      getInstallTrackerContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.installTrackerContent =
          action?.payload?.data?.data?.attributes || null;
        state.errInstallTrackerContent = null;
      }
    );
    builder.addCase(
      getInstallTrackerContent.rejected,
      (state: InitialState, action: any) => {
        state.installTrackerContent = null;
        state.errInstallTrackerContent = action.error || "Something went wrong";
      }
    );
  },
});

export default strapiInstallTrackerSlice.reducer;
