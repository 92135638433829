import { ButtonCust, TextFieldCust } from "../widgets";
import { ForgotPasswordProps } from ".";
import {
  Box,
  Divider,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const ForgotPasswordT1 = (props: ForgotPasswordProps) => {
  const theme = useTheme();

  const {
    email,
    emailError,
    onChangeHandler,
    handleToggle,
    handleForgotPassword,
    isForgotClicked,
    content,
  } = props;

  const {
    heading_forgot_pwd,
    email_label_forgot,
    btn_forgot,
    return_text1,
    return_text2,
    forgot_pwd_logo,
  } = content;
  return (
    <Box>
      <Typography
        component={"h5"}
        variant={"h5"}
        fontSize="20px"
        fontFamily={"var(--font_family_Bold)"}
        pt={{ xs: "25px", sm: 0 }}
        pb={{ xs: "25px", sm: "15px" }}
        textAlign={{ xs: "center", sm: "unset" }}
      >
        {heading_forgot_pwd}
      </Typography>

      <Divider
        sx={{
          bgcolor: "var(--ford-grey-color)",
          opacity: "0.5",
          marginBottom: "20px",
        }}
      />

      <Container disableGutters={!useMediaQuery(theme.breakpoints.only("xs"))}>
        <TextFieldCust
          value={email}
          label={email_label_forgot}
          name="email"
          onChange={onChangeHandler}
          error={emailError !== "" ? true : false}
          helperText={emailError ? emailError : ""}
        />

        <ButtonCust
          fullWidth
          sx={{ my: "25px" }}
          onClick={handleForgotPassword}
          loading={isForgotClicked}
        >
          {btn_forgot}
        </ButtonCust>

        <Typography
          component={"div"}
          textAlign="center"
          fontSize={"14px"}
          fontFamily={"var(--font_family_Semibold)"}
          pb="25px"
        >
          {return_text1}&nbsp;
          <Typography
            component={"span"}
            fontSize={"inherit"}
            color={"var(--primary_color)"}
            onClick={handleToggle}
            fontFamily={"inherit"}
            sx={{ cursor: "pointer" }}
          >
            {return_text2}
          </Typography>
        </Typography>
      </Container>

      <Box
        component="img"
        width={"250px"}
        height="200px"
        alt={forgot_pwd_logo?.data.attributes.alternativeText}
        src={forgot_pwd_logo?.data.attributes.url}
        display="flex"
        margin="auto"
      />
    </Box>
  );
};

export default ForgotPasswordT1;
