import styles from "./AutoComplete_T3.module.scss";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import { TextFieldCust_T2 } from "../Textfield_T2";
import { useEffect, useRef, useState } from "react";
import {
  toTitleCase,
  useNonInitialEffect,
} from "../../../utils/commonFunctions";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { lookUp } from "../../../features/homepage/homepageSlice";
import { scroller } from "react-scroll";
type iprops = {
  value?: string;
  onChange?: any;
  onSelect?: (val: any) => void;
  onChangeVal?: () => void;
  setOpenLeadGenerationModal?: (val: boolean) => void;
  placeholder?: string;
  inputTitle?: string;
  className?: string;
  InputLabelProps?: any;
  disabled?: any;
  error?: boolean;
  helperText?: string;
  onBlurAuto?: any;
  autoComplete?: string;
  fromCheckout?: boolean;
  fromProfile?: boolean;
};

export const AutoComplete_T3 = ({
  setOpenLeadGenerationModal = (val: boolean) => {},
  ...props
}: iprops) => {
  const dispatch: any = useAppDispatch();

  const { lookUpSuccess, lookUpLoading } = useAppSelector(
    (state: any) => state.homepage
  );
  const wrapperRef = useRef<any>(null);

  const [suggestions, setSuggestions] = useState<any>([]);
  const [search, setSearch] = useState<string>("");
  const [value, setValue] = useState<any>(
    props.fromCheckout ? props.value : ""
  );
  const [display, setDisplay] = useState(false);

  const getAddress = () => {
    if (search !== "") {
      dispatch(lookUp(search));
    }
  };

  useNonInitialEffect(() => {
    if (lookUpSuccess?.data && lookUpSuccess?.data?.data.length > 0) {
      setDisplay(true);
      setSuggestions(lookUpSuccess.data.data);
    }
  }, [lookUpSuccess]);

  useNonInitialEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      await getAddress();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (event: any) => {
    const { current } = wrapperRef;
    if (current && !current?.contains(event.target)) {
      setSuggestions([]);
    }
  };
  return (
    <Stack
      ref={wrapperRef}
      sx={{ width: `${props.fromProfile ? "100%" : "90%"}` }}
    >
      <Container
        sx={{ position: "relative", mx: 0 }}
        style={{ padding: "0px" }}
        onBlur={props.onBlurAuto}
      >
        <TextFieldCust_T2
          label={props.placeholder}
          error={props.error}
          helperText={props.helperText}
          disabled={props.disabled}
          autoComplete={props.autoComplete}
          onChange={(event) => {
            setValue(event.target.value);
            setSearch(event.target.value);
          }}
          value={value}
          className={props.fromProfile ? "" : `${styles.textField}`}
        />

        {lookUpLoading && (
          <Container
            sx={{
              height: "60px",
              border: "1px solid",
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
            className={styles.autocomplete_dropdown_container}
          >
            {<CircularProgress sx={{ color: "var(--primary_color)" }} />}
          </Container>
        )}
        {display && (
          <Container
            sx={{
              height: "215px",
              border: "1px solid",
              width: { xs: "350px", sm: "450px", md: "100%" },
              overflowX: "scroll",
              ...(props.fromCheckout && { width: "420px" }),
            }}
            className={styles.autocomplete_dropdown_container}
          >
            {suggestions.map((suggestion: any, index: number) => {
              const className = suggestion.active
                ? `suggestion-item--active ${styles.autocomplete_dropdown}`
                : `suggestion-item ${styles.autocomplete_dropdown}`;
              if (display) {
                return (
                  <Typography
                    component={"div"}
                    sx={{
                      height: "30px",
                      width: "100%",
                      borderBottom: "1px solid var(--grey)",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    key={index}
                    onClick={() => {
                      props.onChange(suggestion);
                      setDisplay(false);
                      if (props.fromCheckout) {
                        setValue(
                          `${suggestion?.street_number} ${toTitleCase(
                            suggestion?.prefix_direction
                          )} ${toTitleCase(
                            suggestion?.street_name
                          )} ${toTitleCase(suggestion?.suffix_direction)}`
                        );
                      } else {
                        setValue(
                          toTitleCase(suggestion.street_number) +
                            " " +
                            (suggestion.prefix_direction
                              ? suggestion.prefix_direction + " "
                              : "") +
                            toTitleCase(suggestion.street_name) +
                            (suggestion?.suffix_direction
                              ? " " + toTitleCase(suggestion?.suffix_direction)
                              : "") +
                            ", " +
                            (suggestion.apt_des
                              ? toTitleCase(suggestion.apt_des) + " "
                              : "") +
                            (suggestion.apt_unit
                              ? `${toTitleCase(suggestion.apt_unit)}, `
                              : "") +
                            toTitleCase(suggestion.city) +
                            ", " +
                            suggestion.state_abbreviation +
                            ", " +
                            suggestion.zip.substring(0, 5)
                        );
                      }
                    }}
                  >
                    {/* <span> */}
                    <LocationOnIcon className={styles.location_icon} />
                    {/* </span> */}

                    <Typography
                      component={"span"}
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "16px",
                        fontFamily: "var(--font_family_Semibold)",
                        paddingLeft: "5px",
                      }}
                    >
                      {toTitleCase(suggestion.street_number) +
                        " " +
                        (suggestion.prefix_direction
                          ? suggestion.prefix_direction + " "
                          : "") +
                        toTitleCase(suggestion.street_name) +
                        (suggestion?.suffix_direction
                          ? " " + toTitleCase(suggestion?.suffix_direction)
                          : "") +
                        ", " +
                        (suggestion.apt_des
                          ? toTitleCase(suggestion.apt_des) + " "
                          : "") +
                        (suggestion.apt_unit
                          ? `${toTitleCase(suggestion.apt_unit)}, `
                          : "") +
                        toTitleCase(suggestion.city) +
                        ", " +
                        suggestion.state_abbreviation +
                        ", " +
                        suggestion.zip.substring(0, 5)}
                    </Typography>
                  </Typography>
                );
              } else {
                return null;
              }
            })}

            {(location.pathname === "/" || location.pathname === "/home") && (
              <Typography
                component={"div"}
                sx={{
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <LocationOffIcon className={styles.location_icon} />

                <Typography
                  component={"span"}
                  sx={{
                    whiteSpace: "nowrap",
                    display: "flex",
                    px: "5px",
                    fontSize: "16px",
                    fontFamily: "var(--font_family_Semibold)",
                  }}
                >
                  If your service address is not listed, please &nbsp;
                  <Typography
                    sx={{
                      color: "var(--primary_color)",
                      textDecoration: "underline",
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Semibold)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenLeadGenerationModal(true);
                      setValue("");
                      setDisplay(false);
                    }}
                  >
                    Click Here
                  </Typography>
                </Typography>
              </Typography>
            )}
          </Container>
        )}
      </Container>
    </Stack>
  );
};
