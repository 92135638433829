import { ButtonCust, SimpleModal } from "../../../../../widgets";
import styles from "./FinalSuccessModal.module.scss";
import Config from "../../../../../../config/env.config.json";
import { Box } from "@mui/material";
import clsx from "clsx";

type Props = {
  finalSuccessModal?: any;
  signal5G?: any;
  signal4G?: any;
  isIMEISkipped?: any;
  imeiData?: any;
  checkAgain?: any;
  setFinalSuccessModal?: any;
  navigate?: any;
  finalContent?: any;
};

export const FinalSuccessModal = (props: Props) => {
  const proceedToCheckout = () => {
    props.navigate("/checkout");
    props.setFinalSuccessModal(false);
  };
  const {
    coverage_modal,
    final_heading,
    final_success_modal_one,
    final_coverage_box,
    final_check_again,
    coverage_text,
    final_device_heading,
    coverage_image_blk,
    final_IMEI_box,
    final_device_img_blk,
    final_device_details,
    final_action_blk,
    make_model,
    border_left,
  } = styles;

  const {
    final_result_title,
    final_result_coverage,
    final_result_coverage1,
    final_result_coverage2,
    final_result_coverage3,
    final_result_coverage4,
    final_result_img1,
    final_result_img2,
    final_result_img3,
    final_result_img4,
    final_result_img5,
    comp_result_heading,
    comp_result_subhead1,
    comp_result_subhead2,
    comp_result_subhead3,
    comp_result_subhead4,
    comp_result_subhead5,
    procced_btn,
    check_again,
  } = props.finalContent;

  return (
    props.finalContent && (
      <SimpleModal
        isShowModal={props.finalSuccessModal}
        onCloseModal={() => props.setFinalSuccessModal(false)}
        showClose={true}
        className={coverage_modal}
      >
        <Box style={{ width: "100%" }}>
          <Box component={"p"} className={final_heading}>
            {final_result_title}
          </Box>
          <Box className={final_success_modal_one}>
            {Config.ADDRESS_CHECK_DURING_PURCHASE && (
              <Box className={final_coverage_box}>
                {props.signal4G !== "no service" ||
                props.signal5G !== "no service" ? (
                  <Box sx={{ textAlign: "center" }}>
                    <Box className={coverage_image_blk}>
                      <Box
                        component={"img"}
                        alt="success"
                        sx={{ width: "40px", mt: "10px" }}
                        src={
                          props.signal4G === "fair"
                            ? final_result_img1?.data?.attributes?.url
                            : props.signal4G === "good"
                            ? final_result_img2?.data?.attributes?.url
                            : props.signal4G === "great"
                            ? final_result_img3?.data?.attributes?.url
                            : final_result_img4?.data?.attributes?.url
                        }
                      />
                      <Box className={coverage_text}>
                        <Box component={"p"} sx={{ fontFamily: "inherit" }}>
                          {final_result_coverage}
                          {props.signal4G === "fair"
                            ? final_result_coverage1
                            : props.signal4G === "good"
                            ? final_result_coverage2
                            : props.signal4G === "great"
                            ? final_result_coverage3
                            : final_result_coverage4}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            )}
            {!props.isIMEISkipped && Config.DEVICE_CHECK_DURING_PURCHASE && (
              <Box
                className={clsx(
                  final_IMEI_box,
                  Config.ADDRESS_CHECK_DURING_PURCHASE ? border_left : ""
                )}
              >
                <Box className={final_device_img_blk}>
                  <Box
                    sx={{ width: "40px", mt: "10px" }}
                    component={"img"}
                    src={final_result_img5?.data?.attributes?.url}
                    alt="img"
                  />
                </Box>
                <Box
                  component={"p"}
                  className={final_device_heading}
                  sx={{ fontFamily: "inherit" }}
                >
                  {comp_result_heading}
                </Box>
                <Box>
                  <Box className={final_device_details}>
                    <Box>
                      <Box component={"span"}>{comp_result_subhead1}</Box>
                    </Box>
                    <Box>&nbsp;{props.imeiData.carriers}</Box>
                  </Box>
                  <Box className={final_device_details}>
                    <Box>
                      <Box component={"span"}>{comp_result_subhead2}</Box>
                    </Box>
                    <Box className={make_model}>{props.imeiData.makeModel}</Box>
                  </Box>
                  <Box className={final_device_details}>
                    <Box>
                      <Box component={"span"}>{comp_result_subhead3}</Box>
                    </Box>
                    <Box>{props.imeiData.mode}</Box>
                  </Box>
                  <Box className={final_device_details}>
                    <Box>
                      <Box component={"span"}>{comp_result_subhead4}</Box>
                    </Box>
                    <Box>{props.imeiData.wifiCalling}</Box>
                  </Box>
                  <Box className={final_device_details}>
                    <Box>
                      <Box component={"span"}>{comp_result_subhead5}</Box>
                    </Box>
                    <Box>{props.imeiData.isLostOrStolen}</Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box className={final_action_blk} sx={{ textAlign: "center" }}>
            <ButtonCust
              sx={{ my: 2 }}
              variantType={Config.PRIMARY_BUTTON_TYPE}
              onClick={proceedToCheckout}
            >
              {procced_btn}
            </ButtonCust>
            <Box style={{ marginTop: "10px" }}>
              <Box
                component={"span"}
                className={final_check_again}
                onClick={props.checkAgain}
              >
                {check_again}
              </Box>
            </Box>
          </Box>
        </Box>
      </SimpleModal>
    )
  );
};
