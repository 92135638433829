import { CheckCoverageProps } from "./CheckCoverage";
import styles from "./S1_CheckCoverage.module.scss";
import { ButtonCust, AutoComplete, SimpleModal } from "../../widgets";
import Config from "../../../config//env.config.json";
import { Box, Container, Grid, Typography } from "@mui/material";

const CheckCoverageComponent = (props: CheckCoverageProps) => {
  const {
    checkAddressHandler,
    handleSelect,
    checkCoverageHandler,
    coverageContent,
    checkAddress,
    coverageLoading,
    coverageAddress,
    signal4G,
    signal5G,
    coverageError,
    navigateCheckCoverage,
    coverageModal,
    checkDisableHandler,
    signalModal,
    checkCoverageHome,
    openCoverageModal,
    closeModalHandler,
    searchAgainHandler,
    exploreHandler,
  } = props;

  const {
    heading,
    sub_heading,
    modal_heading,
    modal_subheading,
    place_holder,
    signal_note1,
    signal_note2,
    button_1,
    button_2,
    button_3,
    button_4,
    button_5,
    error,
    coverage_1,
    coverage_2,
    signal_strength1,
    signal_strength2,
    signal_strength3,
    signal_strength4,
    fair_signal,
    good_signal,
    great_signal,
    no_coverage,
  } = coverageContent || {};
  return (
    coverageContent && (
      <Box
      >
        {coverageModal && (
          <SimpleModal
            isShowModal={coverageModal}
            onCloseModal={closeModalHandler}
            showClose={true}
            className={styles.coverage_modal}
          >
            <Typography
              variant="h5"
              sx={{ mb: 0.75, fontSize: "22px" }}
              className={styles.coverage_check_heading}
            >
              {modal_heading}
            </Typography>
            <Typography
              variant="subtitle2"
              className={styles.coverage_check_subheading}
            >
              {modal_subheading}
            </Typography>
            <Typography
              sx={{ width: "25%", my: 2.75 }}
              className={styles.greenline}
            ></Typography>

            <AutoComplete
              value={checkAddress}
              onChange={checkAddressHandler}
              onSelect={(e: any) => {
                handleSelect(e);
              }}
              placeholder={place_holder}
            />

            {coverageError && (
              <Container
                style={{ padding: 0 }}
                className={styles.coverage_error}
              >
                {error}
              </Container>
            )}
            <ButtonCust
              sx={{ my: 1, width: "100%" }}
              variantType={Config.PRIMARY_BUTTON_TYPE}
              onClick={() => checkCoverageHandler(coverageAddress)}
              disabled={checkDisableHandler()}
              loading={coverageLoading}
            >
              {button_1}
            </ButtonCust>
          </SimpleModal>
        )}
        <Grid
          container
          className={styles.home_coverage_block}
          py={{ xs: 3, lg: 3.75 }}
          px={{ xs: 3, lg: 7.5 }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={9}
            lg={9.69}
            mb={{ xs: 2.5, sm: 0 }}
            display={{ xs: "flex", sm: "block" }}
            flexDirection={{ xs: "column" }}
            alignItems={{ xs: "center" }}
          >
            <Typography
              variant="h5"
              className={styles.home_coverage_head}
              sx={{ mb: 2 }}
            >
              {heading}
            </Typography>
            <Typography
              variant="h6"
              component={"p"}
              className={styles.home_coverage_text}
              textAlign={{ xs: "center", sm: "start" }}
            >
              {sub_heading}
            </Typography>
          </Grid>
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            item
            xs={12}
            sm={4}
            md={3}
            lg={2.31}
          >
            <ButtonCust
              variantType={Config.PRIMARY_BUTTON_TYPE}
              onClick={openCoverageModal}
            >
              {button_1}
            </ButtonCust>
          </Grid>
        </Grid>

        {signalModal && checkCoverageHome && (
          <SimpleModal
            isShowModal={signalModal}
            onCloseModal={closeModalHandler}
            showClose={true}
            className={styles.coverage_modal}
          >
            <Typography
              variant="h5"
              sx={{ mb: 0.75, fontSize: "22px" }}
              className={styles.coverage_check_heading}
            >
              {modal_heading}
            </Typography>
            <Typography
              variant="subtitle2"
              className={styles.coverage_check_subheading}
            >
              {modal_subheading}
            </Typography>
            <Typography
              sx={{ width: "25%", my: 2.75 }}
              className={styles.greenline}
            ></Typography>
            {signal4G !== "no service" || signal5G !== "no service" ? (
              <Container
                sx={{ display: "flex", justifyContent: "center", width: "60%" }}
              >
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    width="40px"
                    height="30px"
                    alt={
                      signal4G === "fair"
                        ? fair_signal?.data?.attributes?.alternativeText
                        : signal4G === "good"
                        ? good_signal?.data?.attributes?.alternativeText
                        : signal4G === "great"
                        ? great_signal?.data?.attributes?.alternativeText
                        : no_coverage?.data?.attributes?.alternativeText
                    }
                    src={
                      signal4G === "fair"
                        ? fair_signal?.data?.attributes?.url
                        : signal4G === "good"
                        ? good_signal?.data?.attributes?.url
                        : signal4G === "great"
                        ? great_signal?.data?.attributes?.url
                        : no_coverage?.data?.attributes?.url
                    }
                  />
                  <Typography variant="h6" className={styles.coverage_text1}>
                    {coverage_1.slice(0, 2)}
                  </Typography>
                </Container>
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    width="40px"
                    height="30px"
                    alt={
                      signal4G === "fair"
                        ? fair_signal?.data?.attributes?.alternativeText
                        : signal4G === "good"
                        ? good_signal?.data?.attributes?.alternativeText
                        : signal4G === "great"
                        ? great_signal?.data?.attributes?.alternativeText
                        : no_coverage?.data?.attributes?.alternativeText
                    }
                    src={
                      signal5G === "fair"
                        ? fair_signal?.data?.attributes?.url
                        : signal5G === "good"
                        ? good_signal?.data?.attributes?.url
                        : signal5G === "great"
                        ? great_signal?.data?.attributes?.url
                        : no_coverage?.data?.attributes?.url
                    }
                  />
                  <Typography variant="h6" className={styles.coverage_text1}>
                    {coverage_2.slice(0, 3)}
                  </Typography>
                </Container>
              </Container>
            ) : (
              <Box
                component="img"
                width="40px"
                height="30px"
                alt={no_coverage?.data?.attributes?.alternativeText}
                className="modalImage"
                src={no_coverage?.data?.attributes?.url}
              />
            )}

            {signal4G !== "no service" || signal5G !== "no service" ? (
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  my: 2.5,
                }}
                className={styles.coverage_final_text}
              >
                <Typography variant="subtitle1" component={"p"}>
                  {coverage_1}
                  {signal4G === "fair"
                    ? signal_strength1
                    : signal4G === "good"
                    ? signal_strength2
                    : signal4G === "great"
                    ? signal_strength3
                    : signal_strength4}
                </Typography>
                <Typography variant="subtitle1" component={"p"}>
                  {coverage_2}
                  {signal5G === "fair"
                    ? signal_strength1
                    : signal5G === "good"
                    ? signal_strength2
                    : signal5G === "great"
                    ? signal_strength3
                    : signal_strength4}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component={"p"}
                  sx={{ textAlign: "center", my: 1.25 }}
                >
                  {signal_note1}
                </Typography>
              </Container>
            ) : (
              <Container
                sx={{ textAlign: "center", my: 2.5 }}
                className={styles.coverage_final_text}
              >
                {signal_note2}
              </Container>
            )}
            {signal4G !== "no service" || signal5G !== "no service" ? (
              <ButtonCust
                sx={{ my: 0.75, width: "100%" }}
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={searchAgainHandler}
              >
                {button_2}
              </ButtonCust>
            ) : (
              ""
            )}
            <ButtonCust
              sx={{ my: 0.75, width: "100%" }}
              variantType={Config.PRIMARY_BUTTON_TYPE}
              onClick={exploreHandler}
            >
              {signal4G !== "no service" || signal5G !== "no service"
                ? button_3
                : button_4}
            </ButtonCust>
            <Typography
              variant="subtitle1"
              sx={{ mt: 2 }}
              className={styles.detailed_coverage}
              onClick={navigateCheckCoverage}
            >
              {button_5}
            </Typography>
          </SimpleModal>
        )}
      </Box>
    )
  );
};

export default CheckCoverageComponent;
