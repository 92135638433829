import { InternalBannerProps } from "./InternalBanner";
import { Box, Grid, Paper, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";

const InternalBannerT1 = (props: InternalBannerProps) => {
  const { heading, banner_web, banner_ipad } = props?.bannerContent || {};

  const background_box = {
    display: { xs: "block", sm: "none" },
    width: "100%",
    minHeight: "60px",
    backgroundColor: "var(--primary_brand_color)",
    opacity: 0.2,
  };

  const grid_wrapper = {
    position: "absolute",
    textAlign: { xs: "center", sm: "left", lg: "center" },
    color: { xs: "var(--primary_brand_color)", sm: "inherit" },
    width: { xs: "80%", sm: "85%", lg: "60%" },
    transform: {
      xs: "translate(-50%, -50%)",
      sm: "translateY(-50%)",
      lg: "translate(-50%, -50%)",
    },
    top: "50%",
    left: { xs: "50%", sm: "40px", lg: "50%" },
  };

  const { largeDesktop, desktop, ipad } = getResolutionDetails();
  return (
    props?.bannerContent && (
      <Container maxWidth={false} disableGutters sx={{ position: "relative" }}>
        <Paper sx={{ padding: 0, boxShadow: "unset" }}>
          {(largeDesktop || desktop) &&
            generateImageContainer({
              data: banner_web, attrs: {
                  width: "100%",
                  height: "300px",
                }
              })}
          {ipad &&
            generateImageContainer({
              data: banner_ipad, attrs: {
                width: "100%",
                height: "300px",
              }
            })}
        </Paper>
        <Box sx={background_box} />
        <Grid sx={grid_wrapper}>
          <Grid item>
            <Box>
              {heading && (
                <Typography
                  component="h3"
                  variant="h3"
                  gutterBottom
                  sx={{
                    margin: { xs: 0 },
                  }}
                  className="internal_banner_title"
                >
                  {heading}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  );
};

export default InternalBannerT1;
