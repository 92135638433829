import { useState } from "react";
import styles from "./S1_PosResultBottom.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { ButtonCust } from "../../widgets";
import Config from "../../../config/env.config.json";
import { PlanCard } from "../PlanCard";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { VideoDialog } from "../VideoDialog";
import { useAppSelector } from "../../../app/hooks";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";

const T1_PosResultBottom = (p: any) => {
  const props = p?.content;

  const [gbmodalShow, setgbmodalShow] = useState(false);
  const navigate = useNavigate();

  const gbmodalHide = () => {
    setgbmodalShow(false);
  };
  const gbmodalHandler = (_: any, items: any) => {
    GAAndFBEventsHandler(gaCategory.billCalculator, items.video_text);
    setgbmodalShow(true);
  };

  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.BillCalculator,
      category: category,
      buttonValue: value,
    });
  };

  const onClickHandler = (
    navigationPath: string,
    scrollToPath: string,
    categyName: string,
    gaName: string
  ) => {
    navigate(navigationPath);
    setTimeout(function () {
      scroller.scrollTo(scrollToPath, {
        duration: 1000,
        delay: 0,
        offset: -85,
        smooth: true,
      });
    }, 1500);
    GAAndFBEventsHandler(categyName, gaName);
  };

  const { planData, saveAmt } = useAppSelector(
    (state: any) => state.billCalculator
  );

  const cardDescriptions = (item: any) => {
    return (
      <>
        <Grid item xs={3} sm={1} spacing={1} key={item?.id}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              lineHeight: "1.8",
              letterSpacing: "normal",
              width: "50px",
              height: "50px",
              marginRight: "20px",
            }}
            mr="10px"
            component="img"
            src={item?.image?.data?.attributes?.url}
            alt={item?.image?.data?.attributes?.alternativeText}
            className={styles.planImg}
          />
        </Grid>
        <Grid item xs={9} sm={11}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              fontSize: "22px",
              fontWeight: "700",
              fontFamily: "var(--font_family_Bold)",
            }}
          >
            {item?.title}
          </Box>
          <Box
            className={styles.sub_title}
            sx={{
              marginBottom: "0px",
              fontSize: "16px",
              lineHeight: "28px",
              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {item?.description}
          </Box>
          <Box>
            <Box
              sx={{
                lineHeight: "1.8",
                letterSpacing: "normal",
                fontFamily: "var(--font_family_Semibold)",
                color: "var(--primary_color)",
                fontSize: "16px",
                fontWeight: "var(--font_weight_2)",
                width: "fit-content",
                cursor: "pointer",
              }}
              margin="15px 0 0"
              onClick={(e: any) => gbmodalHandler(e, item)}
            >
              <Box
                component="img"
                mb="-4px"
                src={item?.video_img?.data?.attributes?.url}
                alt={item?.video_img?.data?.attributes?.alternativeText}
                className={styles.planImg}
              />
              <Box component="span" ml="5px">
                {item.video_text}
              </Box>
            </Box>
          </Box>
          {gbmodalShow && (
            <VideoDialog
              open={gbmodalShow}
              onClose={() => gbmodalHide()}
              videoUrl={item?.video_link}
            />
          )}
        </Grid>
      </>
    );
  };
  return (
    <>
      <Box
        sx={{
          width: { md: "60%", sm: "(100%-60px)", xs: "90%" },
        }}
        margin={{ sm: "0px auto", xs: "15px" }}
      >
        <Box
          textAlign={{ xs: "center" }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mt: { xs: "50px", sm: "45px" },
          }}
        >
          <Typography
            variant="h3"
            component={"h3"}
            fontSize="23px"
            className={styles.page_title}
          >
            {props?.title}
          </Typography>
          <Typography
            variant="body1"
            className={styles.sub_title}
            sx={{ lineHeight: "30px" }}
          >
            {props?.sub_title}
          </Typography>
          <Box className={styles.page_line}></Box>
        </Box>
        <PlanCard content={props} plan={planData} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            fontSize: "14px",
            fontFamily: "var(--font_family_Medium)",
            fontWeight: "500",
          }}
          margin={{
            md: "0 0 30px",
          }}
        >
          {planData?.addLineSplitPrice ? (
            <>
              {props?.save_text}&nbsp;
              <Box
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  color: "var(--text_color)",
                  fontSize: "14px",
                  fontWeight: "var(--font_weight_2)",
                }}
                component="span"
              >
                {GetCurrencyFixedToTwo(saveAmt, planData?.currency)}&nbsp;
                {props?.per_line}
              </Box>
              &nbsp;{props?.every_line}
            </>
          ) : (
            <>
              {props?.add_line}&nbsp;
              <Box
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  color: "var(--text_color)",
                  fontSize: "14px",
                  fontWeight: "var(--font_weight_2)",
                }}
                component="span"
              >
                {GetCurrencyFixedToTwo(saveAmt, planData?.currency)}
                {props?.per_month_short}
              </Box>
            </>
          )}
        </Box>
        <Box
          textAlign={{ xs: "center" }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            flexDirection: "row",
            fontSize: "14px",
            color: "var(--grey_shade)",
            width: { sm: "450px", xs: "100%" },
          }}
          margin={{
            xs: "20px auto 30px",
          }}
        >
          <Box
            textAlign={{ xs: "center" }}
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--text_color)",
              fontSize: "14px",
              display: "flex",
              fontWeight: "var(--font_weight_3)",
              marginTop: "-20px",
            }}
            component="span"
          >
            {props?.note}:
          </Box>
          {props?.note_desc}
        </Box>

        <Box className={styles.btn}>
          <ButtonCust
            style={{ width: "100%" }}
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={() => {
              navigate(RoutingURLs.checkout);
            }}
          >
            {props?.btn_text}
          </ButtonCust>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "var(--primary_color)",
          }}
          margin={{
            xs: "25px 0px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "var(--font_family_Bold)",
              fontWeight: "var(--font_weight_5)",
              width: "fit-content",
              cursor: "pointer",
            }}
            onClick={() =>
              onClickHandler(
                RoutingURLs.default,
                "bestCellPhonePlan",
                "plans",
                props?.view_plans
              )
            }
          >
            {props?.view_plans}
          </Typography>
        </Box>
        <Box
          className={styles.font_weight}
          textAlign={{ xs: "center" }}
          sx={{
            color: "var(--text_color)",
            fontSize: "14px",
            fontWeght: "400",
            fontFamily: "var(--font_family_Medium)",
          }}
        >
          {props?.compatibility_desc}
          <Box
            sx={{
              color: "var(--primary_color)",
              fontSize: "14px",
              fontFamily: "var(--font_family_Bold)",
              cursor: "pointer",
              fontWeight: "700",
            }}
            component="span"
            onClick={() =>
              onClickHandler(
                "/imei-compatibility-check",
                "",
                "checkCompatibility",
                props?.check_compatibility
              )
            }
          >
            &nbsp; {props?.check_compatibility}
          </Box>
        </Box>

        <Grid
          container
          justifyContent={{
            xs: "center",
          }}
          margin={{
            xs: "30px auto",
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {props?.bill_res_comp?.map((items: any) => {
            return cardDescriptions(items);
          })}
        </Grid>
      </Box>
    </>
  );
};

export default T1_PosResultBottom;
