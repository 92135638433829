import { Box, Typography } from "@mui/material";
import styles from "./ManageCards.module.scss";
import { ButtonCust } from "../Button/ButtonCust";
import { getIsCardExpired } from "../../../utils/commonFunctions/ReusableFunctions";
import { Divider } from "../Divider";

type ManageProps = {
  manageCardDetails?: any;
  isDescription?: any;
  getCustomer?: any;
  cardData?: any;
  payType?: any;
  selectOpenHandler?: any;
  openHandler?: any;
  addNewCardHandler?: any;
  getCardsInOrder?: any;
  isLoading?: boolean;
};

export const CardChangeModal = (props: ManageProps) => {
  const {
    manageCardDetails,
    isDescription,
    cardData,
    payType,
    selectOpenHandler,
    addNewCardHandler,
    getCardsInOrder,
    openHandler,
    getCustomer,
    isLoading
  } = props;
  return (
    <Box className={styles.manage_card_container}>
      <Typography className={styles.card_heading}>
        {manageCardDetails?.heading}{" "}
      </Typography>
      <Divider styles={{ my: "20px" }} />
      {isDescription ? (
        <Typography className={styles.description}>
          {manageCardDetails?.description?.replace(
            "{0}",
            `${new Date(getCustomer?.nextBillingDate).getDate()}`
          )}
        </Typography>
      ) : null}
      <Box className={styles.bottom_section}>
        <Typography className={styles.save_card_text}>
          {manageCardDetails?.save_cards}
        </Typography>
        <>
          {cardData?.length > 0 &&
            getCardsInOrder(cardData).map((each: any, ind: number) => {
              let cardIsExpired = getIsCardExpired(each);
              return (
                <Box className={styles.number_container} key={ind}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      component="img"
                      alt="visa_image"
                      sx={{
                        width: "30px",
                        height: "20px",
                        marginRight: "10px",
                        alignSelf: "flex-start",
                      }}
                      src={
                        each?.type?.toLowerCase() === "visa"
                          ? manageCardDetails?.visa?.data?.attributes?.url
                          : each?.type?.toLowerCase() === "mastercard"
                          ? manageCardDetails?.master_card?.data?.attributes
                              ?.url
                          : each?.type?.toLowerCase() === "jcb"
                          ? manageCardDetails?.jcb?.data?.attributes?.url
                          : manageCardDetails?.american_express?.data
                              ?.attributes?.url
                      }
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        pl: "5px",
                      }}
                    >
                      <Typography className={styles.card_number}>
                        XXXX-XXXX-XXXX- {each.ccNumberMask}
                      </Typography>
                      <Typography
                        className={styles.expiry}
                        sx={{
                          fontWeight: "var(--font_weight_0)",
                          textAlign: "left !important",
                        }}
                        mb="3px"
                      >
                        {each && each.nameOnCard} |{" "}
                        {cardIsExpired ? (
                          <Box component={"span"} sx={{ color: "red" }}>
                            Expired date{" "}
                            {each &&
                              each.dateOfExpiry &&
                              each.dateOfExpiry.slice(0, 2)}
                            /
                            {each &&
                              each.dateOfExpiry &&
                              each.dateOfExpiry.slice(2)}
                          </Box>
                        ) : (
                          <Box component={"span"} className="cardNotExpired">
                            Expiration date{" "}
                            {each &&
                              each.dateOfExpiry &&
                              each.dateOfExpiry.slice(0, 2)}
                            /
                            {each &&
                              each.dateOfExpiry &&
                              each.dateOfExpiry.slice(2)}
                          </Box>
                        )}
                      </Typography>
                      {payType ? (
                        <Box display="flex" alignItems="center">
                          {!each.isAutoPay && (
                            <Typography
                              onClick={() => {
                                if (!cardIsExpired)
                                  selectOpenHandler(
                                    manageCardDetails?.set_default,
                                    each,
                                    true
                                  );
                              }}
                              className={styles.update_text}
                              sx={{
                                opacity: cardIsExpired ? 0.5 : 1,
                                cursor: `${
                                  cardIsExpired
                                    ? "not-allowed !important"
                                    : "pointer"
                                }`,
                              }}
                            >
                              {manageCardDetails?.set_default}
                            </Typography>
                          )}
                          <Typography
                            onClick={() => openHandler(each)}
                            className={styles.update_text}
                          >
                            {manageCardDetails?.update}
                          </Typography>
                          {!each.isAutoPay && (
                            <Typography
                              onClick={() =>
                                selectOpenHandler(
                                  manageCardDetails?.remove_card,
                                  each,
                                  false
                                )
                              }
                              className={styles.update_text}
                            >
                              {manageCardDetails?.remove}
                            </Typography>
                          )}
                        </Box>
                      ) : (
                        !cardIsExpired && (
                          <Typography
                            className={styles.update_text}
                            onClick={() => selectOpenHandler("", each, true)}
                          >
                            {manageCardDetails?.pay_using}
                          </Typography>
                        )
                      )}
                    </Box>
                  </Box>
                  {each.isDefault && payType && (
                    <Box
                      component="img"
                      src={manageCardDetails?.right_mark?.data?.attributes?.url}
                      alt="tick_icon"
                      sx={{
                        width: "20px",
                        height: "15px",
                        alignSelf: "center",
                        margin: "0px 10px 0px 10px",
                      }}
                    />
                  )}
                </Box>
              );
            })}
        </>
        <Box
          sx={{
            opacity: "0.3",
            borderTop: "1px solid var(--grey_color_1)",
            margin: "0 auto 15px",
          }}
        ></Box>
      </Box>
      <ButtonCust
        sx={{ padding: "13px 23px 12px" }}
        onClick={addNewCardHandler}
        loading={isLoading}
      >
        <Typography
          fontSize="25px"
          mr="5px"
          fontFamily="var(--font_family_Bold)"
        >
          {manageCardDetails?.plus}
        </Typography>
        <Typography className={styles.add_card}>
          {manageCardDetails?.add_new}
        </Typography>
      </ButtonCust>
    </Box>
  );
};
