import styles from "./S1_CompareGraph.module.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/material";
import clsx from "clsx";
import { ComparePlanGraph } from "../../widgets";

export const CompareGraph = (props: any) => {
  let { carrierCost, reachCost, data, pageHandler } = props;
  return (
    <Box>
      <Box
        sx={{ m: { xs: "0 15px", lg: "0 30px" } }}
        className={styles.main_container}
      >
        <Box sx={{ marginTop: "20px" }}>
          <Box
            className={styles.back_button}
            onClick={() => {
              pageHandler();
            }}
          >
            <ArrowBackIcon className={styles.arrow_icon} />
            {data?.back_text}
          </Box>
          <Box className={styles.sub_main_container}>
            <div className={clsx(styles.title_main, styles.title_common)}>
              {data?.title1}
              <span>
                {" "}
                $
                {reachCost && carrierCost
                  ? Math.ceil(carrierCost - reachCost) * 12
                  : 0}{" "}
              </span>
              {data?.title2}
            </div>
            <div className={clsx(styles.sub_title_main, styles.title_common)}>
              {data?.sub_title1}
              <span>
                {" "}
                $
                {reachCost && carrierCost
                  ? Math.ceil(carrierCost - reachCost)
                  : 0}{" "}
              </span>
              {data?.sub_title2}
            </div>
            <ComparePlanGraph carrierCost={carrierCost} reachCost={reachCost} data={data}/>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompareGraph;
