import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./ActivationStep1.module.scss";
import { useFormik } from "formik";
import { object, string } from "yup";
import { getImeiCheck } from "../../../../features/activation/activationSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { countryList } from "../../../../config/USAStateList";
import { globalVal } from "../../../../utils/globalValues/globalvalues";
import { hubspotPost } from "../../../../features/billCalculator/billCalculatorSlice";
import { IMEIFailureModal } from "./IMEIFailureModal";
import {
  NotificationCust,
  TextFieldCust,
  LinearProgressBarCust,
  ButtonCust,
  ManageCard,
} from "../../../widgets";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import OneTimeCharge from "./OneTimeCharge";
import { getCC } from "../../../../features/account/accountSlice";
import { AddShippingAddressDialog } from "../../Account/AddLinesDialog/AddShippingAddressDialog/AddShippingAddressDialog";
import EnvConfig from "../../../../config/env.config.json";
import { ChangeLinePreferenceDialog } from "../../../ChangeLinePreferenceDialog/ChangeLinePreferenceDialog";
import { SimTypes } from "../../../../features/checkout/Types";
import firebase from "../../../../adapters/firebase";

interface formikintitaltypes {
  imei: string;
}
export const ActivationStep1 = (props: any) => {
  const {
    handleStep,
    currentLine,
    gaHandler,
    numChangeHandler,
    updatingCustomer,
    content,
    simPrefenceContent,
  } = props;
  const provideEdit: boolean = currentLine?.esim ? true : false;
  const customerDetails: any = JSON.parse(
    localStorage?.getItem("customerDetail") || "null"
  );
  const [addressData, setAddressData] = useState<any>(null);
  const [simType, setSimType] = useState<SimTypes>(SimTypes.eSim);

  const [deviceComp, setDeviceComp] = useState(
    currentLine?.imei ? true : false
  );
  const [btnLoader, setBtnLoader] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);

  const [currentModal, setCurrentModal] = useState("");
  const [selectedCard, setSelectedCard] = useState<any>(null);
  // const [simCost, setSimCost] = useState<number>(0);

  const [kitBasePrice, setKitBasePrice] = useState<any>(null);
  const [sendSimOption, setSimOption] = useState([]);

  const dispatch = useAppDispatch();
  const { imeiData, imeiNumber } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const { getCCDetails } = useAppSelector((state: any) => state?.account || {});

  const { imei_view, Iemi_con, imei_form, sp1_title, step_txt, msgs } =
    content || {};
  const { isValid, model, make } = imeiData || {};

  const initialformstep1data: formikintitaltypes = {
    imei: currentLine?.imei || "",
  };
  const activationStep1Schema = object({
    imei: string().min(15, msgs?.valid_msg),
  });
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.activation,
      category: category,
      buttonValue: value,
    });
  };
  const hubspotPy = {
    emailId: currentLine?.emailId,
    hubspotMap: {
      device_imei: imeiNumber,
      device_make: make,
      device_model: model,
      app_imei_incompatibility_reason: "",
      is_test: globalVal.hubspot_is_test,
      is_qa: globalVal.hubspot_is_qa,
    },
  };
  const formik = useFormik({
    initialValues: initialformstep1data,
    validationSchema: activationStep1Schema,
    onSubmit: (values: any) => {
      setBtnLoader(true);
      const py: any = {
        countryCode: countryList?.[0]?.value?.toLowerCase(),
        imei: values?.imei,
      };

      gaHandler("check");
      dispatch(getImeiCheck({ payload: py })).then((res: any) => {
        const data = res?.payload?.data?.data;
        const { isValid, make, model } = data || {};
        if (isValid && make && model) {
          NotificationCust({
            message: msgs.imei_noti,
            type: "info",
            duration: 2000,
            id: "activationIMEISuccess",
            showIcon: true,
          });

          currentLine?.esim !== true && handleStep(true);
          dispatch(hubspotPost(hubspotPy));
          updatingCustomer({
            imei: res?.meta?.arg?.payload?.imei.toString(),
            make: data?.make,
            model: data?.model,
          });
          setCurrentModal("chooseType");
        } else {
          setModalStatus(true);
        }
        setBtnLoader(false);
      });
    },
    enableReinitialize: true,
  });
  //  later trigger based on sim change..
  useEffect(() => {
    dispatch(getCC({ customerId: customerDetails?.id }));
    fetchData();
  }, []);
  const fetchData = async () => {
    let firstObj: any;
    const shippingRate: any = await firebase.database().ref("/shippingRates");
    shippingRate.on("value", (snapshot: any) => {
      const data = snapshot.val();
      let sendSimOptions: any = [];
      data?.map((el: any) => {
        return sendSimOptions.push({
          label: `${el?.name} - $${el?.shippingAmount}`,
          value: el?.serviceType,
          cost: el?.shippingAmount,
          ...el,
        });
      });
      firstObj = sendSimOptions?.[0];
      setSimOption(sendSimOptions);
    });

    const perCost: any = await firebase.database().ref("/perSimCost");
    let kitBasePrice = 0;

    perCost.on("value", (snapshot: any) => {
      const data = snapshot.val();
      kitBasePrice =
        data?.USA && ((100 - data.USA.discountPctg) * data.USA.cost) / 100;
      setKitBasePrice(kitBasePrice);
    });
  };

  useEffect(() => {
    if (isValid && make && model && currentLine.esim !== true) {
      setDeviceComp(true);
    }
  }, [imeiData, isValid]);
  const changeModal = (val?: string) => {
    setCurrentModal(val ?? "");
  };
  const closeOneTimeCharge = () => {
    changeModal();
  };
  const showCardChangeModalHandler = () => {
    changeModal("oneTimeChrg");
  };
  const ProceedPayment = () => {};
  const changeCard = () => {
    changeModal("cardsModal");
  };
  const changeAddress = () => {
    changeModal("addressDetails");
  };
  const SaveAddress = (e: any) => {
    changeModal("oneTimeChrg");
    setAddressData(e.billingAddress);
  };

  const chooseModalResult = () => {
    if (simType === SimTypes.sim) {
      changeModal("oneTimeChrg");
    } else {
      handleStep(true);
      changeModal();
    }
    setSimType(SimTypes.eSim);
  };

  return (
    <Box className={styles.step1_main}>
      <Box className={styles.heading_container}>
        <ArrowBackIcon
          sx={{ ml: { md: "30px", sm: "25px", xs: "4%" } }}
          className={styles.arrow_icon}
          onClick={() => handleStep(false)}
        />
        <Typography className={styles.title_main}>{sp1_title}</Typography>
      </Box>
      <Box className={styles.stepper_content}>
        <Typography className={styles.stepper_txt}>{step_txt}</Typography>
        <Box width={{ xs: "90%", sm: "50%", md: "35%" }} mb="25px">
          <LinearProgressBarCust value={25} variant={"determinate"} />
        </Box>
      </Box>
      {!deviceComp ? (
        <>
          <Box width={{ xs: "90%", sm: "65%" }} m="0 auto 40px">
            <Box
              className={styles.inner_container}
              p={{ xs: "30px 15px", sm: "30px 50px" }}>
              <Grid item md={5.5}>
                <Typography className={styles.activation_heading}>
                  {imei_form?.desc}
                </Typography>
              </Grid>
              <Box
                className={styles.activation_iemi_number}
                width={{
                  md: "50%",
                  sm: "70%",
                  xs: "95%",
                }}>
                <TextFieldCust
                  value={formik.values.imei}
                  name="imei"
                  onChange={(e: any) => numChangeHandler(e, formik)}
                  onBlur={formik.handleBlur}
                  type={"text"}
                  label={imei_form?.txt_ph}
                  maxlength={15}
                  error={formik.errors?.imei ? true : false}
                  helperText={formik.errors?.imei}
                />
                <Typography className={styles.activation_limit_div}>
                  {formik.values.imei.length}/15
                </Typography>
              </Box>
              <Box className={styles.activation_btn}>
                <ButtonCust
                  disabled={
                    formik.values?.imei.length === 0 ? true : !formik?.isValid
                  }
                  onClick={() => formik.handleSubmit()}
                  loading={btnLoader}>
                  {imei_form?.check_btn}
                </ButtonCust>
              </Box>
              <Grid item>
                <Typography className={styles.activation_paid_desc}>
                  {imei_form?.note}
                </Typography>
              </Grid>
            </Box>
          </Box>
          <Box className={styles.activation_bottom}>
            <Box>
              <Typography
                className={styles.activation_bottom_text1}
                sx={{ mb: "5px", fontFamily: " var(--font_family_Semibold)" }}>
                {Iemi_con?.title}
              </Typography>
              <Typography
                className={styles.activation_bottom_text1}
                sx={{ fontFamily: " var(--font_family_Medium)" }}>
                {Iemi_con?.desc}
              </Typography>
            </Box>

            <Box
              sx={{
                margin: "20px 0 50px 0",
              }}
              width={{ xs: "90%", sm: "fit-content" }}>
              {Iemi_con?.iemiContent.map((data: any) => {
                return (
                  <Box
                    className={styles.activation_version_container}
                    mt={"20px"}
                    key={data.desc1}>
                    <img
                      width={16}
                      height={23.13}
                      className={styles.os_img}
                      src={data.img?.data?.attributes?.url}
                      alt={data.img?.data?.attributes?.alternativeText}
                    />
                    <Box>
                      <Typography className={styles.os_text_1}>
                        {data.desc1}
                      </Typography>
                      <Typography className={styles.os_text_2}>
                        {data.desc2}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </>
      ) : (
        <Box width={{ xs: "90%", sm: "65%" }} m="0 auto 40px">
          <Box
            className={styles.step1_container}
            p={{ xs: "30px 15px", sm: "30px 50px" }}>
            <>
              <Typography className={styles.step1_heading}>
                {imei_view?.desc}
              </Typography>
              <Box className={styles.device_text_container}>
                <Box>
                  <Box
                    className={styles.device_container}
                    alignItems={{ xs: "center" }}>
                    <Box
                      component="img"
                      className={styles.device_icon}
                      src={imei_view?.dev_img?.data?.attributes?.url}
                      alt={
                        imei_view?.dev_img?.data?.attributes?.alternativeText
                      }
                    />
                    <Box className={styles.text_container}>
                      <Typography className={styles.text1}>
                        {imei_view?.device}
                      </Typography>
                      <Typography className={styles.text2}>
                        {currentLine?.model}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    className={styles.device_container}
                    alignItems={{ xs: "center" }}>
                    <Box
                      component="img"
                      className={styles.device_icon}
                      src={imei_view?.dev_id_img?.data?.attributes?.url}
                      alt={
                        imei_view?.dev_id_img?.data?.attributes?.alternativeText
                      }
                    />
                    <Box className={styles.text_container}>
                      <Typography className={styles.text1}>
                        {imei_view?.deviceID}
                        {provideEdit && (
                          <Box
                            component="span"
                            className={styles.edit_text}
                            onClick={() => {
                              setDeviceComp(false);
                              GAAndFBEventsHandler(
                                gaCategory.activation,
                                imei_view?.edd_txt
                              );
                            }}>
                            {imei_view?.edd_txt}
                          </Box>
                        )}
                      </Typography>
                      <Typography className={styles.text2}>
                        {currentLine?.imei}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <ButtonCust
                sx={{ mt: "25px" }}
                onClick={() => {
                  handleStep(true),
                    GAAndFBEventsHandler(
                      gaCategory.activation,
                      imei_view?.nxt_btn
                    );
                }}>
                {imei_view?.nxt_btn}
              </ButtonCust>
              {/* <Typography
                className={styles.edit_text}
                onClick={() => {
                  setDeviceComp(false);
                  GAAndFBEventsHandler(
                    gaCategory.activation,
                    imei_view?.edd_txt
                  );
                }}
              >
                {imei_view?.edd_txt}
              </Typography> */}
            </>
          </Box>
        </Box>
      )}
      {modalStatus && (
        <IMEIFailureModal
          modalFg={modalStatus}
          closeHandler={() => setModalStatus(false)}
          content={content}
        />
      )}
      {currentModal === "oneTimeChrg" && (
        <OneTimeCharge
          onClose={closeOneTimeCharge}
          changeCard={changeCard}
          changeAddress={changeAddress}
          latestAddress={addressData}
          loaderHandler={setBtnLoader}
          btnLoader={btnLoader}
          currentLine={currentLine}
          content={content}
          sendSimOptions={sendSimOption}
          kitBasePrice={kitBasePrice}
        />
      )}
      {currentModal === "cardsModal" && (
        <ManageCard
          cardData={getCCDetails}
          showCardChangeModal={true}
          setShowCardChangeModal={showCardChangeModalHandler}
          isModalRequired={true}
          pageName={"activation"}
          isDescription={false}
          payType={false}
          ProceedPayment={ProceedPayment}
          isPayConfirm={true}
          setSelectedCard={setSelectedCard}
        />
      )}
      {currentModal === "addressDetails" && (
        <AddShippingAddressDialog
          open={true}
          onClose={SaveAddress}
          updateAddress={(e: any) => {
            SaveAddress(e);
          }}
          pageName="activation"
        />
      )}

      {currentModal === "chooseType" && (
        <ChangeLinePreferenceDialog
          open={true}
          onClose={closeOneTimeCharge}
          content={simPrefenceContent}
          onConfirm={chooseModalResult}
          simType={simType}
          setSimType={setSimType}
          pageName={"activation"}
          simCost={kitBasePrice}
        />
      )}
    </Box>
  );
};
