import { Button, Grid, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  gaBrandNames,
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { displayPhoneNumberFormat } from "../../../utils/commonFunctions/ReusableFunctions";
import styles from "./S1_HelpContent.module.scss";

/**
 * @param props title
 * @returns a grid component with title and line
 */

type Props = {
  helpContent: any;
  onClickHandler: any;
};

const AnalyticsHandler = (label: string) => {
  pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
    appName: gaBrandNames.reach,
    screenName: gaScreenNames.helpCenter,
    category: gaCategory.support,
    buttonValue: label,
  });
};

const emailHandler = (label: string) => {
  pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
    appName: gaBrandNames.reach,
    screenName: gaScreenNames.helpCenter,
    category: gaCategory.support,
    buttonValue: label,
  });
};
const Header = (props: any) => {
  return (
    <Grid>
      <Typography variant="h2" sx={{ fontSize: "18px", fontWeight: "bold" }}>
        {props.Title}
      </Typography>
      <Box
        sx={{
          width: "80px",
          height: "5px",
          m: "20px auto",
          backgroundColor: "var(--primary_brand_color)",
          borderRadius: "4px",
        }}
      ></Box>
    </Grid>
  );
};
/**
 *
 * @param props text,subtext,image
 * @returns a subcomponent of support section
 */
const SupportSubCards = (props: any) => {
  const { text, subtext, image, targetLink, rel, actionHandler } = props;
  return (
    <Grid sx={{ mb: { md: "20px" } }}>
      <Grid
        sx={{
          pb: "25px",
          justifyContent: {
            sm: "flex-start",
            md: "center",
            flexWrap: "wrap",
          },
        }}
        container
        display="flex"
        textAlign={"left"}
      >
        <Grid item display={"flex"} justifyContent={"center"}>
          <Grid>
            <Box
              sx={{ m: "7px" }}
              component="img"
              width="30px"
              src={image}
            ></Box>
          </Grid>

          <Grid onClick={() => actionHandler(text)}>
            <Link
              sx={{
                "&:hover": {
                  color: "var(--primary_color)",
                },
              }}
              href={targetLink}
              underline={"none"}
              color={"var(--dark_blue)"}
              fontSize={"16px"}
              fontFamily="var(--font_family_Semibold)"
              rel={rel ? rel : null}
            >
              {text}
            </Link>
            <Typography
              fontSize={"14px"}
              fontFamily=" var(--font_family_Medium)"
            >
              {subtext}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const HelpContentCmp = (props: Props) => {
  const { helpContent, onClickHandler } = props;
  const { help_center, support, chat_icon, mail_icon, phone_icon } =
    helpContent;
  const { hc_header, hc_description, hc_button } = help_center || {};
  const { navigationPath, page, text } = hc_button || {};
  const { s_header1, s_header2, phone_number, chat_text, timings, mail_id } =
    support || {};
  const ChatIcon = {
    img: chat_icon?.data?.attributes?.url,
    alt: chat_icon?.data?.attributes?.alternativeText,
  };
  const phoneIcon = {
    img: phone_icon?.data?.attributes?.url,
    alt: phone_icon?.data?.attributes?.alternativeText,
  };
  const emailIcon = {
    img: mail_icon?.data?.attributes?.url,
    alt: mail_icon?.data?.attributes?.alternativeText,
  };

  return (
    <Grid
      container
      sx={{
        textAlign: "center",
        mt: "30px",
      }}
      display={"flex"}
    >
      <Grid xs={12} sm={6} item sx={{ p: "10px" }}>
        <Grid
          sx={{
            p: "25px 15%",
            height: "100%",
            backgroundColor: "var(--white_1)} ",
          }}
        >
          <Grid sx={{ pt: "25px" }}>
            {hc_header && <Header Title={hc_header} />}
            <Typography
              sx={{ fontSize: "14px", fontFamily: "var(--font_family_Medium)" }}
            >
              {hc_description}
            </Typography>
            {hc_button && (
              <Button
                onClick={() =>
                  onClickHandler(text, navigationPath, page)
                }
                variant="outlined"
                sx={{
                  my: "25px",
                  width: "200px",
                  height: "45px",
                  color: "var(--primary_color)",
                  textTransform: "none",
                  border: "1px solid var(--primary_color)",
                  background: "white",
                  borderRadius: "22.5px",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                {text}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} sm={6} item sx={{ p: "10px" }}>
        <Grid
          container
          display={"flex"}
          direction={"column"}
          justifyContent={"center"}
          sx={{
            height: "100%",
            p: "30px 15%",
            backgroundColor: "var(--white_1)}",
          }}
        >
          {s_header1 && <Header Title={s_header1} />}
          <Grid
            container
            sx={{
              display: "flex",
              direction: "column",
              width: { xs: "300px", sm: "100%" },
              mx: { xs: "auto", sm: "0" },
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                direction: { md: "row", xs: "column" },
                justifyContent: "space-between",
              }}
            >
              <SupportSubCards
                text={displayPhoneNumberFormat(phone_number)}
                subtext={timings}
                image={phoneIcon.img || ""}
                targetLink={`tel:${phone_number}`}
                actionHandler={AnalyticsHandler}
              />
              <SupportSubCards
                text={chat_text}
                subtext={s_header2}
                image={ChatIcon.img || ""}
                targetLink={"#hs-chat-open"}
                rel={"noopener noreferrer"}
                actionHandler={AnalyticsHandler}
              />
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: { sm: "flex-start", md: "center" },
              }}
              onClick={() => emailHandler(mail_id)}
            >
              <Grid
                className={styles.support_container}
                container
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", md: "center" },
                }}
              >
                <Grid item display={"flex"} justifyContent={"center"}>
                  <Box
                    sx={{ m: "7px" }}
                    component="img"
                    width="30px"
                    src={emailIcon.img || ""}
                  ></Box>
                  <Grid>
                    <Link
                      sx={{
                        "&:hover": {
                          color: "var(--primary_color)",
                        },
                      }}
                      href={`mailto:${mail_id}`}
                      underline={"none"}
                      color={"var(--dark_blue)"}
                      fontSize={"16px"}
                      fontFamily="var(--font_family_Semibold)"
                    >
                      {mail_id}
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HelpContentCmp;
