import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { ButtonCust, SimpleModal, TextFieldCust } from "../../../widgets";
import styles from "./S1_LineComponent.module.scss";
import EditIcon from "@mui/icons-material/EditOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { LineComponentProps } from "./LineComponent";
import { CustomerStatus } from "../../../../enums";
import {
  displayPhoneNumberFormat,
  getNumberWithOrdinal,
} from "../../../../utils/commonFunctions/ReusableFunctions";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { useEffect, useState } from "react";
import { updateSecondaryUser } from "../../../../features/checkout/checkoutSlice";
import clsx from "clsx";
import { clearStepsData } from "../../../../features/activation/activationSlice";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import EnvConfig from "../../../../config/env.config.json";
import { SimTypes } from "../../../../features/checkout/Types";
const GAAndFBEventsHandler = (category: string, value: string) => {
  pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
    appName: EnvConfig.brand,
    screenName: gaScreenNames.account,
    category: category,
    buttonValue: value,
  });
};
const SingleLine = (props: any) => {
  const {
    lineimage,
    lineimg,
    linenumber,
    linemail,
    statusimg,
    status,
    handleClickopen,
    handleActivateModal,
    eachUser,
    content,
    orderId,
    setSecCustomerPayload,
    groupData,
    data,
  } = props;
  const primaryStatus = groupData?.find(
    (item: { isPrimary: any }) => item?.isPrimary
  )?.status;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleActivateSecondary = (user: any) => {
    localStorage.setItem("secondaryCustomerDetail", JSON.stringify(user));
    dispatch(updateSecondaryUser(user));
    dispatch(clearStepsData());
    navigate(RoutingURLs.activation);
  };
  return (
    <>
      <Box
        className={styles.main}
        sx={{
          width: { sm: "92%", xs: "92%", md: "50%" },
          cursor: `${eachUser?.isPrimary && "pointer"}`,
        }}
        onClick={() => {
          eachUser && dispatch(updateSecondaryUser(eachUser));
          GAAndFBEventsHandler(gaCategory.manageLines, lineimg);
          if (eachUser?.isPrimary) {
            navigate(RoutingURLs.profile);
          } else if (!eachUser?.isPrimary && eachUser?.emailId) {
            handleClickopen(eachUser);
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
        >
          <Box
            component="img"
            className={styles.img}
            src={lineimage}
            alt={content?.active_img?.data?.attributes?.alternativeText}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box className={styles.owner}>
              {lineimg}&nbsp;
              {eachUser && (
                <Box component="span" className={styles.simType}>
                  {eachUser && eachUser?.esim ? "(eSIM)" : "(SIM)"}
                </Box>
              )}
              {data && (
                <Box component="span" className={styles.simType}>
                  {data?.simPreference === SimTypes.phySim ? "(SIM)" : "(eSIM)"}
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "row", xs: "column" },
              }}
            >
              {linenumber && (
                <Typography className={styles.owner_mail}>
                  {linenumber} | &nbsp;
                </Typography>
              )}
              {linemail && (
                <Typography className={styles.owner_mail}>
                  {linemail}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            cursor: "pointer",
          }}
        >
          {status === CustomerStatus.ACTIVE ? (
            <>
              <Box
                component="img"
                className={styles.img_active}
                sx={{
                  margin: { xs: "0px 5px 0px 15px", md: "5px 10px 0px 0px" },
                }}
                src={statusimg}
                alt={content?.active_img?.data?.attributes?.alternativeText}
              />
              <Typography className={styles.active}>
                {content?.active}
              </Typography>
            </>
          ) : status === CustomerStatus.DISCONNECTED ? (
            <Typography className={styles.active}>
              {content?.disconnected}
            </Typography>
          ) : status === CustomerStatus.SUSPENDED ? (
            <Typography className={styles.active}>
              {content?.suspended}
            </Typography>
          ) : status === CustomerStatus.HOTLINED ? (
            <Typography className={styles.active}>
              {content?.hotlined}
            </Typography>
          ) : status === CustomerStatus.REJECTED ? (
            <Typography className={styles.active}>
              {content?.rejected}
            </Typography>
          ) : status === CustomerStatus.DISABLED ? (
            <Typography className={styles.active}>
              {content?.disabled}
            </Typography>
          ) : status === CustomerStatus.PORTING_FAILED ? (
            <Typography className={styles.active}>
              {content?.port_fail}
            </Typography>
          ) : status === CustomerStatus.PORTING_IN_PROGRESS ? (
            <Typography className={styles.active}>
              {content?.port_inprog}
            </Typography>
          ) : status === CustomerStatus.ACTIVATION_FAILED ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box className={styles.active}>{content?.activation_fail}</Box>
              <Box
                style={{
                  cursor: "pointer",
                  color: "var(--dusk)",
                }}
                className={styles.active}
                onClick={() => {
                  navigate(RoutingURLs.help);
                }}
              >
                {content?.contact_cust}
              </Box>
            </Box>
          ) : status === CustomerStatus.INACTIVE ? (
            <Typography
              className={styles.active}
              onClick={() => {
                const secondaryCustomer = groupData?.find(
                  (item: { id: any }) => item.id === eachUser.id
                );
                localStorage.setItem(
                  "secondaryCustomerDetail",
                  JSON.stringify(secondaryCustomer)
                );
                navigate(
                  eachUser.esim
                    ? RoutingURLs?.activateEsim
                    : RoutingURLs?.trackOrder,
                  {
                    state: {
                      orderId: orderId,
                      secondaryCustomerID: eachUser.id,
                      isSecondary: true,
                      emailId: eachUser.emailId,
                    },
                  }
                );
              }}
            >
              {content?.track_order}
            </Typography>
          ) : (
            <Box
              className={
                primaryStatus === CustomerStatus.SUSPENDED ||
                primaryStatus === CustomerStatus.DISCONNECTED
                  ? styles.disabled_btn
                  : ""
              }
              onClick={
                primaryStatus === CustomerStatus.SUSPENDED ||
                primaryStatus === CustomerStatus.DISCONNECTED
                  ? () => null
                  : () => {
                      if (!eachUser?.emailId) {
                        handleActivateModal(true);
                        const payload = {
                          esim:
                            data?.simPreference === SimTypes?.eSim.toUpperCase()
                              ? true
                              : false,
                          imei: data?.imei ? data.imei : "",
                          make: data?.make ? data.make : "",
                          model: data?.model ? data.model : "",
                        };
                        setSecCustomerPayload(payload);
                      } else if (
                        eachUser?.status === CustomerStatus?.INACTIVE
                      ) {
                        GAAndFBEventsHandler(
                          gaCategory.manageLines,
                          content?.act_now
                        );
                        handleActivateSecondary(eachUser);
                      }
                    }
              }
            >
              <Typography
                className={clsx(
                  styles.click,
                  eachUser?.emailId ? styles.click_secondary_line : ""
                )}
              >
                {content?.act_now}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

const LineListComponent = (props: LineComponentProps) => {
  const {
    user,
    customerForm,
    handleChangeEmail,
    updateSecondaryEmail,
    handleClose,
    handleClickOpen,
    activateModal,
    open,
    value,
    openUser,
    emailErr,
    updateEmailErr,
    edit,
    customerErr,
    customerDetailBtn,
    setCustomerErr,
    handleClickEdit,
    handleActivateModal,
    content,
    setStopAddLinesFlow,
    extraLinesData,
    groupData,
    setSecCustomerPayload,
  } = props;
  const { getShipmentID } = useAppSelector((state: any) => state.trackorder);

  const handleClickopen = (user: any) => {
    handleClickOpen(user);
  };
  const hanldeEdit = () => {
    handleClickEdit();
  };
  return (
    <>
      <SimpleModal
        isShowModal={activateModal}
        onCloseModal={handleClose}
        showClose={true}
        className={styles.secondary_line_dialog}
        sx={{
          borderRadius: "4.8px",
          maxWidth: { xs: "95%", sm: "550px" },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Grid
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
        >
          <TextFieldCust
            name="firstName"
            onChange={customerForm.handleChange}
            onBlur={customerForm.handleBlur}
            sx={{ m: "24px 0 8px 0" }}
            error={
              customerForm.touched?.firstName && customerForm.errors?.firstName
                ? true
                : false
            }
            helperText={
              customerForm.touched?.firstName && customerForm.errors?.firstName
            }
            label={content?.fst_name}
          />
          <TextFieldCust
            name="lastName"
            onChange={customerForm.handleChange}
            onBlur={customerForm.handleBlur}
            sx={{ mb: "8px" }}
            error={
              customerForm.touched?.lastName && customerForm.errors?.lastName
                ? true
                : false
            }
            helperText={
              customerForm.touched?.lastName && customerForm.errors?.lastName
            }
            label={content?.lst_name}
          />
          <TextFieldCust
            name="email"
            onChange={(e: any) => {
              customerForm.handleChange(e), setCustomerErr("");
            }}
            onBlur={customerForm.handleBlur}
            sx={{ mb: "8px" }}
            error={
              customerForm.touched?.email && customerForm.errors?.email
                ? true
                : false
            }
            helperText={
              customerForm.touched?.email && customerForm.errors?.email
            }
            label={content?.email}
          />
          {customerErr !== "" && (
            <Typography className={styles.secondary_email_err}>
              {customerErr}
            </Typography>
          )}
          <ButtonCust
            sx={{ width: "100%" }}
            loading={customerDetailBtn}
            onClick={() => {
              customerForm.handleSubmit();
              GAAndFBEventsHandler(gaCategory.manageLines, content?.submit);
            }}
          >
            {content?.submit}
          </ButtonCust>
        </Grid>
      </SimpleModal>
      <SimpleModal
        isShowModal={open}
        onCloseModal={handleClose}
        showClose={true}
        className={styles.secondary_line_dialog_email}
        sx={{
          borderRadius: "4.8px",
          maxWidth: { xs: "95%", sm: "500px" },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Grid
          item
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          className={styles.inner_container}
        >
          <Box sx={{ margin: "0px -12px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "0px 12px",
              }}
            >
              <Box
                component="img"
                src={
                  openUser?.status === CustomerStatus.ACTIVE
                    ? content?.active_img?.data?.attributes?.url
                    : content?.inactive_img?.data?.attributes?.url
                }
                alt={content?.inactive_img?.data?.attributes?.alternativeText}
                width="34px"
              />
            </Box>
            <Typography
              sx={{
                fontSize: "22px",
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--h3_font_weight)",
                margin: "15px 0px 5px",
                padding: "0px 12px",
              }}
            >
              {openUser?.firstName}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "var(--font_family_Medium)",
                margin: "10px 0 5px 0",
                padding: "0px 12px",
                color: "var(--grey_shade_3)",
              }}
            >
              {content?.secondary}
            </Typography>
          </Box>
          {openUser?.status === CustomerStatus.ACTIVE ? (
            <Typography className={styles.line_details}>
              {displayPhoneNumberFormat(openUser?.reachNumber)}&nbsp;
              {content?.sim_id}&nbsp;{openUser?.simId}
            </Typography>
          ) : (
            ""
          )}
          <Box
            sx={{
              margin: "15px 0px 33px",
              width: "100%",
            }}
          >
            <TextFieldCust
              variant="standard"
              label={content?.email}
              value={value}
              sx={{ width: "100%" }}
              onChange={(e: any) => {
                if (!edit) {
                  handleChangeEmail(e);
                }
              }}
              disabled={edit}
              className={styles.email_textfield_cust}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={hanldeEdit}>
                    {edit ? <EditIcon /> : <MoreHorizIcon />}
                  </IconButton>
                </InputAdornment>
              }
              error={emailErr !== ""}
              helperText={emailErr}
            />
          </Box>
          {updateEmailErr !== "" && (
            <Typography
              textAlign="center"
              fontFamily="var(--font_family_Bold)"
              fontSize="16px"
              color="var(--danger)"
              my="8px"
            >
              {updateEmailErr}
            </Typography>
          )}
          <ButtonCust
            variantType="primary_outlined_rounded"
            sx={{ width: { xs: "200px", sm: "100%" } }}
            onClick={updateSecondaryEmail}
            loading={customerDetailBtn}
          >
            {content?.update}
          </ButtonCust>
        </Grid>
      </SimpleModal>
      {groupData &&
        groupData?.length > 0 &&
        groupData.map((user: any) => {
          let orderId: any;
          let secondaryCustomerId: any;
          getShipmentID?.data?.map((ship: any) => {
            if (user.id == ship.secondaryCustomers) {
              orderId = ship.orderId;
              secondaryCustomerId = ship.secondaryCustomers;
              secondaryCustomerId = ship.secondaryCustomers[0];
            } else {
              secondaryCustomerId = user.id;
            }
          });
          return (
            <SingleLine
              lineimage={
                user.status === CustomerStatus.ACTIVE
                  ? content?.active_img?.data?.attributes?.url
                  : content?.inactive_img?.data?.attributes?.url
              }
              lineimg={
                user.isPrimary
                  ? `${user.firstName} ${content?.owner}`
                  : `${user.firstName}`
              }
              linenumber={
                user && user.reachNumber
                  ? displayPhoneNumberFormat(user.reachNumber)
                  : ""
              }
              linemail={user && user.emailId ? user.emailId : ""}
              statusimg={
                user.status === CustomerStatus.ACTIVE
                  ? content?.tick_icon?.data?.attributes?.url
                  : ""
              }
              status={user.status}
              handleClickopen={handleClickopen}
              handleActivateModal={handleActivateModal}
              eachUser={user}
              content={content}
              key={user?.id}
              orderId={orderId}
              groupData={groupData}
            />
          );
        })}
      {user?.extraLines > user?.additionalLines && extraLinesData?.length > 0
        ? extraLinesData.map((data: any, idx: number) => {
            return (
              <SingleLine
                lineimage={content?.inactive_img?.data?.attributes?.url}
                lineimg={
                  getNumberWithOrdinal(user.additionalLines + 1 + (idx + 1)) +
                  ` ${content?.line}`
                }
                // handleClickopen={handleClickopen}
                handleActivateModal={handleActivateModal}
                content={content}
                key={idx}
                setSecCustomerPayload={setSecCustomerPayload}
                data={data}
              />
            );
          })
        : null}
      {user?.extraLines > user?.additionalLines && !EnvConfig?.IS_ESIM_SUPPORTED
        ? new Array(user?.extraLines - user?.additionalLines)
            .fill(1)
            .map((_, idx: number) => {
              return (
                <SingleLine
                  lineimage={content?.inactive_img?.data?.attributes?.url}
                  lineimg={
                    getNumberWithOrdinal(user.additionalLines + 1 + (idx + 1)) +
                    ` ${content?.line}`
                  }
                  // handleClickopen={handleClickopen}
                  handleActivateModal={handleActivateModal}
                  content={content}
                  key={idx}
                />
              );
            })
        : null}
    </>
  );
};

export default LineListComponent;
