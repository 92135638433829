export const RoutingURLs = {
  default: "/",
  plans: "/plans",
  dataPlans: "/data-plans",
  devices: "/devices",
  aboutUs: "/about-us",
  profile: "/profile",
  help: "/help-center",
  signUp: "/signup",
  login: "/login",
  recover: "/recover",
  home: "/home",
  settings: "/settings",
  activation: "/activation",
  billCalculator: "/bill-calculator",
  internationalServices: "/international_services",
  buyPhone: "/buy_a_phone",
  imeiCheck: "/imei-compatibility-check",
  cellPhonePlans: "/cell-phone-plans",
  bestfit: "/bestfit",
  giveBack: "/giveback",
  checkout: "/checkout",
  postActivationHome: "/post-activation-home",
  myAccount: "/my-account",
  trackOrder: "/track-order",
  billing: "/billing",
  account: "/account",
  secondarylogin: "/secondarylogin",
  steps: "/steps",
  reviews: "/reviews",
  terms: "/legal/terms",
  privacy: "/legal/privacy",
  usepolicy: "/legal/use-policy",
  copyrightNotice: "/legal/copyright-notice",
  antiVirusInformation: "/legal/anti-virus-information",
  spamInformation: "/legal/spam-information",
  supportedModems: "/legal/supported-modems",
  networkManagementDisclosureStatement:
    "/legal/network-management-disclosure-statement",
  contactUs: "/contact-us",
  coverageCheck: "/coverage-check",
  discover: "/discover",
  services: "/services",
  loader: "/loader",
  activateEsim: "/activate-esim",
  paymentLanding: "/payment-landing",
  installationTracker: "/installation-tracker",
  fieldServices: "/field-services",
};

export const version = "v0";
export const versionNew = "v1";
