import { ButtonCust, TextFieldCust } from "../widgets";
import { RecoverProps } from ".";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import EnvConfig from "../../config/env.config.json";
import { T3_LoginAndSignUp } from "../common";
import BubbleChat from "../../assets/images/Recover/Bubble_chat.svg";
import TickIcon from "../../assets/images/Recover/TickIcon.svg";

const RecoverT3 = (props: RecoverProps) => {
  const {
    content,
    password,
    onChangeHandler,
    isContinueClicked,
    handleEnter,
    handleResetPassword,
    showSuccessScreen,
    handleLogin,
  } = props;
  const { card_bg_img } = content || {};
  const theme = useTheme();

  return (
    <Grid container onKeyDown={handleEnter}>
      <Grid p={{ sm: "90px" }} item md={5} width="100%">
        {showSuccessScreen ? (
          <>
            <Box
              component="img"
              alt={"Tick Icon"}
              height="76px"
              width="auto"
              src={TickIcon}
              sx={{ display: "block", margin: "auto" }}
            />
            <Typography
              component={"h5"}
              variant={"h3"}
              fontSize="38px"
              color={"var(--text_color)"}
              fontFamily={"var(--font_family_Medium)"}
              pt={{ xs: "25px", sm: "10px" }}
              pb={{ xs: "25px", sm: "28px" }}
              textAlign={"center"}
            >
              Password Reset!
            </Typography>
            <Typography
              component={"h5"}
              variant={"h3"}
              fontSize="20px"
              color={"var(--text_color)"}
              fontFamily={"var(--font_family_Semibold)"}
              pb={{ xs: "25px", sm: "40px" }}
              textAlign={"center"}
            >
              Your new password has been set successfully!
            </Typography>

            <Container
              disableGutters={!useMediaQuery(theme.breakpoints.only("xs"))}
            >
              <ButtonCust
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                fullWidth
                sx={{ my: "15px" }}
                onClick={handleLogin}
              >
                login
              </ButtonCust>
            </Container>
          </>
        ) : (
          <>
            <Typography
              component={"h5"}
              variant={"h3"}
              fontSize="38px"
              color={"var(--text_color)"}
              fontFamily={"var(--font_family_Medium)"}
              pt={{ xs: "25px", sm: 0 }}
              pb={{ xs: "25px", sm: "28px" }}
              justifyContent={{ xs: "center", sm: "unset" }}
              display={"flex"}
            >
              Set a New Password
            </Typography>
            <Typography
              component={"h5"}
              variant={"h3"}
              fontSize="20px"
              color={"var(--text_color)"}
              fontFamily={"var(--font_family_Semibold)"}
              pb={{ xs: "25px", sm: "40px" }}
              justifyContent={{ xs: "center", sm: "unset" }}
              display={"flex"}
            >
              The more complex, the better!
            </Typography>

            <Container
              disableGutters={!useMediaQuery(theme.breakpoints.only("xs"))}
            >
              <Grid container sx={{ gap: "30px", marginBottom: "30px" }}>
                <Grid item xs={12} sm={12}>
                  <TextFieldCust
                    name="forgotPassword"
                    type="password"
                    value={password.forgotPassword}
                    placeholder={"Enter New Password"}
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextFieldCust
                    name="confirmForgotPassword"
                    type="password"
                    value={password.confirmForgotPassword}
                    placeholder={"Re-enter New Password"}
                    onChange={onChangeHandler}
                  />
                </Grid>
              </Grid>

              <ButtonCust
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                fullWidth
                sx={{ my: "15px" }}
                loading={isContinueClicked}
                onClick={
                  !isContinueClicked ? () => handleResetPassword() : () => null
                }
              >
                continue
              </ButtonCust>
              <Typography
                component={"span"}
                onClick={() => {
                  window.open(EnvConfig.SUPPORT_URL);
                }}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  width: "max-content",
                  mx: "auto",
                  my: "30px",
                }}
              >
                <Box
                  component="img"
                  alt={"Bubble Chat"}
                  height="15px"
                  width="auto"
                  src={BubbleChat}
                />
                <Typography
                  sx={{
                    cursor: "pointer",
                    fontSize: "16px",
                    color: "var(--primary_color)",
                    textDecoration: "underline",
                    textDecorationThickness: "1px",
                  }}
                >
                  Need help
                </Typography>
              </Typography>

              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "var(--font_family_Semibold)",
                  color: "var(--text_color)",
                  textAlign: "center",
                }}
              >
                Use a combination of upper and lower case letters, numbers, and
                special characters (such as !@#$%^&*()_+).
              </Typography>
            </Container>
          </>
        )}
      </Grid>

      <Grid item md={7}>
        {content && <T3_LoginAndSignUp bgImage={card_bg_img} isRecover />}
      </Grid>
    </Grid>
  );
};

export default RecoverT3;
