import getSymbolFromCurrency from "currency-symbol-map";
import planData from "../../config/data.json";

export function GetCurrencyFixedToTwo(value: any, currency?: string) {
  let planCurrency = planData.data.map((el: any) => el.currency);
  let Symbol = getSymbolFromCurrency(currency ? currency : planCurrency[0]);
  if(value !== undefined && value !== null){
    if(value >= 0){
      return `${Symbol}${value?.toFixed(2)}`
    }else if( value < 0){
      return String(value).replace("-",`-${Symbol}`)
    }
  }
  else{
    return null
  }
}
