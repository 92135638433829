import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AddressInfo } from "./AddressInfo";
import { ReviewPlanDetails } from "./ReviewPlansDetails";
import { getInTouchApi } from "../../features/homepage/homepageSlice";
import {
  CreateCustomer,
  UpdateCustomer,
  UpdateCustomerSource,
  CouponReserve,
  Coupon,
  GetCustomer,
  Eligibility,
  getCustomerCouponReserve,
  updateFormValues,
  updatePlanDetails,
  setOpenAutopayConsent,
} from "../../features/checkout/checkoutSlice";
import firebase from "../../adapters/firebase";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { NotificationCust } from "../widgets";
import { useFormik } from "formik";
import { boolean, object, string } from "yup";
import { ReachPlan } from "../../features/trackorder/trackorderSlice";
import styles from "./checkout.module.scss";
import { Buffer } from "buffer";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { addressType, SimTypes } from "../../features/checkout/Types";
import {
  getAddressInfoContent,
  getReviewContent,
} from "../../features/strapi/checkoutSlice";
import { namePattern, numberInStringPattern } from "../../utils/regexPatterns";
import { RoutingURLs } from "../../config/RoutingURLs";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";
import Config from "../../config/env.config.json";
import PlanData from "../../config/data.json";
import { ActivationStatus } from "../../enums";
import { AutopayConsent } from "./AutopayConsent/AutoPayConsent";
import { globalVal } from "../../utils/globalValues/globalvalues";
import EnvConfig from "../../config/env.config.json";
import _ from "lodash";
import { PaymentButtons } from "./PaymentButtons/PaymentButtons";
export const Checkout = () => {
  const dispatch = useAppDispatch();
  const {
    createCustomer,
    errorCreateCustomer,
    updateCustomer,
    errorUpdateCustomer,
    getCustomer,
    errorGetCustomer,
    formValues,
    coupon,
    updateCustomerSource,
    eligibility,
    isLoaded,
    isLoadedPlanTaxCharges,
    isLoadedShippingTaxes,
    planDetails,
    oneTimeShippingTaxCharges,
  } = useAppSelector((state: any) => state.checkout);

  const { addressInfoContent, reviewContent } = useAppSelector(
    (state: any) => state.strapi.checkout
  );
  const [cacheAddressInfo, setCacheAddressInfo] = useState();
  const [cachePlanReview, setCachePlanReview] = useState();
  const addressContentState = cacheAddressInfo
    ? cacheAddressInfo
    : addressInfoContent;
  const reviewContentState = cachePlanReview ? cachePlanReview : reviewContent;
  const navigate = useNavigate();
  const [newUser, setNewUser] = useState(true);
  const [paymentClicked, setPaymentClicked] = useState(false);
  const [user, setuser] = useState<any>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location: any = useLocation();
  const formRef = useRef<HTMLFormElement>(null);
  const [simPaid, setSimPaid] = useState(false);
  const [sendSimOptions, setSendSimOptions] = useState<any>([]);
  const [customerReservedCoupon, setCustomerReservedCoupon] = useState<any>();
  const [isAutopayConsent, setIsAutopayConsent] = useState<boolean>(false);

  const [customerAddress, setCustomerAddress] = useState<any>();
  const [marketingComEnabled, setMarketingComEnabled] = useState(false);
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = currentdetails ? JSON.parse(currentdetails) : null;
  let customerAddressObj: any = localStorage.getItem("CustomerAddressObj");
  if (customerAddressObj) customerAddressObj = JSON.parse(customerAddressObj);
  else {
    let shippingAddress = currentdetails?.addresses.filter(
      (value: any) => value.type === "shipping"
    );
    customerAddressObj = shippingAddress[0];
  }
  /**
   * Strapi content cache storage functions
   */

  useEffect(() => {
    getCache(
      "AddressInfo",
      getAddressInfoContent,
      setCacheAddressInfo,
      dispatch
    );

    getCache("PlanReview", getReviewContent, setCachePlanReview, dispatch);
    if (Config.BROADBAND) {
      let shippingAddress = {
        address1: customerAddressObj?.address1,
        address2: customerAddressObj?.address2,
        city: customerAddressObj?.city,
        state: customerAddressObj?.state,
        country: customerAddressObj?.country,
        zip: customerAddressObj?.zip,
      };
      setCustomerAddress(shippingAddress);
      // if address is present in localstorage then update formik
      formik.setFieldValue("shippingAddress", {
        ...shippingAddress,
        type: addressType.shipping,
      });
      formik.setFieldValue("billingAddress", {
        ...shippingAddress,
        type: addressType.billing,
      });
      formik.setFieldValue("isShippingSameAsBilling", true);
      let addresses = {
        billingAddress: {
          ...shippingAddress,
          type: addressType.billing,
        },
        shippingAddress: {
          ...shippingAddress,
          type: addressType.shipping,
        },
      };
      dispatch(
        updateFormValues({
          isShippingSameAsBilling: true,
          ...addresses,
        })
      );
    }
    if (Config.IS_AUTOPAY_ENABLED) {
      formik.setFieldValue("autopay", true);
    }
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("AddressInfo", addressInfoContent);
  }, [addressInfoContent]);
  useNonInitialEffect(() => {
    addMultipleCacheData("PlanReview", reviewContent);
  }, [reviewContent]);
  // validation

  const billingAndShippingSchema = object().shape({
    isShippingSameAsBilling: boolean(),
    firstName: string()
      .trim()
      .required(addressContentState?.fname_err)
      .matches(namePattern, addressContentState?.fname_err),
    lastName: string()
      .trim()
      .required(addressContentState?.lname_err)
      .matches(namePattern, addressContentState?.lname_err),
    phone: string()
      .trim()
      .required(addressContentState?.phone_err1)
      .min(10, addressContentState?.phone_err2),

    shipmentPlan: string()
      .trim()
      .when("broadband", {
        is: false,
        then: string().trim().required(addressContentState?.shipment_err),
      }),

    billingAddress: object({
      address1: Config.BROADBAND
        ? string()
        : string().trim().required(addressContentState?.shipping?.address1_err),
      city: Config.BROADBAND
        ? string()
        : string().trim().required(addressContentState?.shipping?.city_err),
      state: Config.BROADBAND
        ? string()
        : string().trim().required(addressContentState?.shipping?.state_err),
      zip: Config.BROADBAND
        ? string()
        : string()
            .trim()
            .required(addressContentState?.shipping?.zip_err1)
            .matches(/^[0-9]+$/, addressContentState?.shipping?.zip_err2)
            .min(5, addressContentState?.shipping?.zip_err3)
            .max(5, addressContentState?.shipping?.zip_err3),
    }),
    shippingAddress: object({
      address1: Config.BROADBAND
        ? string()
        : string().trim().required(addressContentState?.shipping?.address1_err),
      city: Config.BROADBAND
        ? string()
        : string().trim().required(addressContentState?.shipping?.city_err),
      state: Config.BROADBAND
        ? string()
        : string().trim().required(addressContentState?.shipping?.state_err),
      zip: Config.BROADBAND
        ? string()
        : string()
            .trim()
            .required(addressContentState?.shipping?.zip_err1)
            .matches(/^[0-9]+$/, addressContentState?.shipping?.zip_err2)
            .min(5, addressContentState?.shipping?.zip_err3)
            .max(5, addressContentState?.shipping?.zip_err3),
    }),
  });
  const [simPreference, setSimPreference] = useState<any>([]);
  const [modemInfo, setModemInfo] = useState<any>(null);
  useEffect(() => {
    let user1: any = localStorage.getItem("currentuser");
    user1 = JSON.parse(user1);
    if (user1) {
      if (user1.isAnonymous) {
        setuser(user1);
        setIsLoggedIn(false);
      } else {
        setuser(user1);
        setIsLoggedIn(true);
        getMarketingState(user1?.email);
      }
    }
  }, []);

  useEffect(() => {
    if (EnvConfig.IS_FREE_MODEM || EnvConfig.IS_STARTER_KIT) {
      let modemDetails: any = localStorage.getItem("modemPreference");
      if (modemDetails !== null || modemDetails !== undefined)
        modemDetails = JSON.parse(modemDetails);
      setModemInfo(modemDetails);
    }
  }, []);

  const getMarketingState = async (email?: any) => {
    await firebase
      .database()
      .ref("marketingComEnabled/" + window.btoa(email))
      .once("value")
      .then((snapshot: any) => {
        let source = snapshot.val();
        setMarketingComEnabled(source);
      })
      .catch((_error: any) => {
        return;
      });
  };

  useEffect(() => {
    let customerDetails: any = localStorage.getItem("customerDetail");
    customerDetails = JSON.parse(customerDetails);

    let simPrefStore: any = localStorage.getItem("simPreference");
    if (customerDetails?.extraLines >= 0) {
      let planData: any = PlanData.data.find(
        (plan) => plan.name === customerDetails?.reachPlanId
      );
      const lines = parseInt(customerDetails.extraLines) + 1;
      localStorage.setItem(
        "reachSelectedPlanDetail",
        JSON.stringify({ numberOfLines: lines, selectedPlan: planData })
      );
      localStorage.setItem("reachSelectedPlanLine", String(lines));
      dispatch(
        updatePlanDetails({ numberOfLines: lines, selectedPlan: planData })
      );
    }
    if (Config.IS_ESIM_SUPPORTED) {
      if (!simPrefStore || customerDetails?.linePreferences) {
        const linePreference: any = [];
        const simDataPref: any = [];
        const linePrefObjKeys = customerDetails?.linePreferences
          ? Object.keys(customerDetails?.linePreferences)
          : [];
        linePrefObjKeys.forEach((key) =>
          linePreference.push({
            name: key,
            value: customerDetails?.linePreferences[key],
          })
        );
        linePreference.forEach((data: any, index: number) =>
          simDataPref.push({
            id: index,
            eSimOpted: data.value.simPreference === "ESIM" ? true : false,
            eSimCompatible: data.value.simPreference === "ESIM" ? true : false,
            imei: data.value.simPreference === "ESIM" ? data.value.imei : false,
            value:
              data.value.simPreference === "ESIM"
                ? SimTypes.eSim
                : SimTypes.sim,
            make: data.value.make ? data.value.make : "",
            model: data.value.model ? data.value.model : "",
            isPrimaryLine: data.name === "line1" ? true : false,
          })
        );
        localStorage.setItem("simPreference", JSON.stringify(simDataPref));
        setSimPreference(simDataPref);
      } else {
        let simPreferenceStorage = JSON.parse(
          localStorage.getItem("simPreference") || "[]"
        );
        setSimPreference(simPreferenceStorage);
      }
    }
  }, [getCustomer]);
  const setUserDetails = (user: any) => {
    setuser(user);
    setIsLoggedIn(true);
  };

  /**
   * Checking coupon code and updating customer data in hubspot
   */
  useEffect(() => {
    if (
      createCustomer &&
      createCustomer.status === "SUCCESS" &&
      createCustomer.data
    ) {
      dispatch(
        UpdateCustomerSource({
          customerId: createCustomer.data && createCustomer.data.id,
          data: { source: Config.BROADBAND ? "CABO" : "TMOBILE" },
        })
      );
      let address =
        createCustomer.data &&
        createCustomer.data.addresses &&
        createCustomer.data.addresses[0];
      dispatch(
        getInTouchApi({
          data: {
            emailId: createCustomer.data && createCustomer.data.emailId,
            hubspotMap: {
              email: createCustomer.data && createCustomer.data.emailId,
              firstname: createCustomer.data && createCustomer.data.firstName,
              lastname: createCustomer.data && createCustomer.data.lastName,
              is_test: globalVal.hubspot_is_test,
              is_qa: globalVal.hubspot_is_qa,
              address: address && address.address1,
              city: address && address.city,
              state: address && address.state,
              zip: address && address.zip,
              country: address && address.country,
              website_journey_score: 7,
              web_address_validation_failed: "",
            },
          },
        })
      );
      if (EnvConfig?.IS_PROMO_ENABLED && coupon?.coupon) {
        dispatch(
          CouponReserve({
            couponId: coupon.coupon,
            customerId: createCustomer.data && createCustomer.data.id,
          })
        );
      }
    }
  }, [createCustomer]);

  const initialRender2 = useRef(true);
  useEffect(() => {
    if (initialRender2.current) {
      initialRender2.current = false;
    } else {
      if (
        updateCustomer &&
        updateCustomer.status === "SUCCESS" &&
        updateCustomer.data
      ) {
        if (EnvConfig?.IS_PROMO_ENABLED && coupon && coupon.coupon) {
          dispatch(
            CouponReserve({
              couponId: coupon && coupon.coupon,
              customerId: updateCustomer.data && updateCustomer.data.id,
            })
          );
        }
        let address =
          updateCustomer.data &&
          updateCustomer.data.addresses &&
          updateCustomer.data.addresses[0];

        dispatch(
          getInTouchApi({
            data: {
              emailId: updateCustomer.data && updateCustomer.data.emailId,
              hubspotMap: {
                email: updateCustomer.data && updateCustomer.data.emailId,
                firstname: updateCustomer.data && updateCustomer.data.firstName,
                lastname: updateCustomer.data && updateCustomer.data.lastName,
                is_test: globalVal.hubspot_is_test,
                is_qa: globalVal.hubspot_is_qa,
                address: address && address.address1,
                city: address && address.city,
                state: address && address.state,
                zip: address && address.zip,
                country: address && address.country,
                website_journey_score: 7,
                web_address_validation_failed: "",
              },
            },
          })
        );
        if (formRef.current) {
          localStorage.setItem("userPaymentStatus", "yes");
          formRef.current.submit();
        }
      }
    }
  }, [updateCustomer]);

  const initialRender3 = useRef(true);
  useEffect(() => {
    if (initialRender3.current) {
      initialRender3.current = false;
    } else {
      if (
        errorGetCustomer &&
        errorGetCustomer.status === "FAILURE" &&
        planDetails
      ) {
        setNewUser(true);
        let user1: any = localStorage.getItem("currentuser");

        user1 = JSON.parse(user1);
        let name: string = user1 && user1.displayName;
        let firstName: string =
          name && name.split(" ") && name.split(" ")[0]
            ? name.split(" ")[0]
            : "";
        let lastName: string =
          name && name.split(" ") && name.split(" ")[1]
            ? name.split(" ")[1]
            : "";
        let email: string = user1 && user1.email ? user1.email : "";
        getUserDetailsFromFirebase(email, firstName, lastName);
      }
    }
  }, [errorGetCustomer, planDetails]);

  const createUserInFirebaseDatabase = (
    email: string,
    firstName: string,
    lastName: string
  ) => {
    const user = firebase
      .database()
      .ref("userWebJourneyData")
      .child(Buffer.from(email).toString("base64"));
    let sendOption: any = sendSimOptions.find(
      (el: any) => (el && el.value) === formik.values.shipmentPlan
    );
    let selectedPlanObj: any = JSON.parse(
      localStorage.getItem("reachSelectedPlanLine") || ""
    );
    let userData = {
      emailId: email,
      firstName: firstName,
      lastName: lastName,
      extraLines: planDetails.numberOfLines - 1,
      reachPlanId: Config.BROADBAND
        ? selectedPlanObj?.name
        : planDetails?.selectedPlan?.name,
      selectedPlan: {
        line: planDetails.numberOfLines,
        plan: planDetails?.selectedPlan,
      },
      addresses: Config.BROADBAND ? [{}, customerAddress] : [],
      deliveryOption: formik.values.shipmentPlan,
      flowVersion: 1,
      isMNP: false,
      isPrimary: true,
      isSimInHand: false,
      primaryNumber: formik.values.phone
        ? formik.values.phone.match(numberInStringPattern).join("")
        : "",
      selectedShippingMethod: sendOption?.label,
      status: 0,
      x_description: null,
      zipcode: "",
    };
    user.set(userData);
    if (Config.BROADBAND) {
      setFieldUpdates(userData, userData.selectedShippingMethod);
    }
  };

  const getPlanObject = () => {
    let selectedPlanObj: any = localStorage.getItem("reachSelectedPlanLine");
    let selectedPlanDetail: any = localStorage.getItem(
      "reachSelectedPlanDetail"
    );
    if (selectedPlanDetail) selectedPlanDetail = JSON.parse(selectedPlanDetail);
    if (selectedPlanObj) selectedPlanObj = JSON.parse(selectedPlanObj);
    else selectedPlanObj = selectedPlanDetail?.plan;
    return selectedPlanObj;
  };

  const getUserDetailsFromFirebase = (
    email: string,
    firstName: string,
    lastName: string
  ) => {
    firebase
      .database()
      .ref("userWebJourneyData/" + Buffer.from(email).toString("base64"))
      .once("value")
      .then(
        async function (snapshot) {
          if (!snapshot.val()) {
            createUserInFirebaseDatabase(email, firstName, lastName);
          } else {
            let userData = snapshot.val();
            setFieldUpdates(userData, userData?.deliveryOption);
          }
        },
        function (error) {
          console.log(error);
        }
      );
  };
  const initialRender1 = useRef(true);
  useEffect(() => {
    if (initialRender1.current) {
      initialRender1.current = false;
    } else {
      if (
        updateCustomerSource &&
        updateCustomerSource.status === "SUCCESS" &&
        formRef.current
      ) {
        localStorage.setItem("userPaymentStatus", "yes");
        formRef.current.submit();
      }
    }
  }, [updateCustomerSource]);

  useEffect(() => {
    if (errorCreateCustomer && errorCreateCustomer.status === "FAILURE") {
      if (
        errorCreateCustomer?.resCode === 40305 ||
        errorCreateCustomer?.resCode === 40020
      ) {
        NotificationCust({
          message: addressContentState?.user_cre_err,
          type: "danger",
          duration: 0,
          id: "errorCreateCustomerId",
          showIcon: true,
        });
      }
      setPaymentClicked(false);
    }
  }, [errorCreateCustomer]);

  useEffect(() => {
    if (errorUpdateCustomer && errorUpdateCustomer.status === "FAILURE") {
      setPaymentClicked(false);
    }
  }, [errorUpdateCustomer]);

  useEffect(() => {
    let planDetails: any = localStorage.getItem("reachSelectedPlanDetail");
    planDetails = JSON.parse(planDetails);
    if (getCustomer) {
      if (getCustomer?.modemInfo) {
        localStorage.setItem(
          "modemPreference",
          JSON.stringify(getCustomer?.modemInfo)
        );
        setModemInfo(getCustomer?.modemInfo);
      }
      if (EnvConfig?.IS_PROMO_ENABLED) {
        dispatch(getCustomerCouponReserve(getCustomer.id)).then(
          (response: any) => {
            const couponId: string = response?.payload?.data?.data?.couponId;
            if (response?.payload?.status === 200 && couponId) {
              setCustomerReservedCoupon(response?.payload?.data?.data);
              dispatch(Coupon({ couponCode: couponId }));
            }
          }
        );
      }

      if (currentdetails) {
        if (currentdetails.simPaid && currentdetails.status == 0) {
          Config.BROADBAND
            ? navigate(RoutingURLs.paymentLanding)
            : navigate(RoutingURLs.myAccount);
        } else if (!currentdetails.simPaid) {
          formik.setFieldValue(
            "shipmentPlan",
            currentdetails.selectedShippingMethod.serviceCode
          );
          dispatch(
            updateFormValues({
              shipmentPlan: currentdetails.selectedShippingMethod.serviceCode,
            })
          );
        } else if (
          currentdetails.simPaid &&
          (currentdetails.status === 1 ||
            currentdetails.status === 2 ||
            currentdetails.status === 4 ||
            currentdetails.status === 5 ||
            currentdetails.status === 8 ||
            currentdetails.status === 9 ||
            currentdetails.status === 10)
        ) {
          Config.BROADBAND
            ? navigate(RoutingURLs.account)
            : navigate(RoutingURLs.postActivationHome);
        }
        setSimPaid(currentdetails.simPaid ? currentdetails.simPaid : false);
      }
      window.scroll(0, 0);
      let selectedPlanObj = getPlanObject();
      dispatch(
        ReachPlan({
          plan: Config.BROADBAND
            ? selectedPlanObj?.name
            : currentdetails.reachPlanId,
        })
      );
      setFieldUpdates(
        getCustomer,
        getCustomer?.selectedShippingMethod?.serviceCode
      );
      setNewUser(false);
    }
    if (!Config.BROADBAND && isEmpty(planDetails)) {
      navigate(RoutingURLs.default);
    }
  }, [getCustomer]);

  const setFieldUpdates = (data: any, shipmentPlan?: any) => {
    let formState: any = {
      firstName:
        data?.firstName !== undefined
          ? data?.firstName
          : formik.values.firstName,
      lastName:
        data?.lastName !== undefined ? data?.lastName : formik.values.lastName,
      shipmentPlan: shipmentPlan,
      phone: data?.primaryNumber || formik.values.phone,
    };
    formik.setFieldValue(
      "firstName",
      data?.firstName !== undefined ? data?.firstName : formik.values.firstName
    );
    formik.setFieldValue(
      "lastName",
      data?.lastName !== undefined ? data?.lastName : formik.values.lastName
    );
    if (shipmentPlan) formik.setFieldValue("shipmentPlan", shipmentPlan);

    formik.setFieldValue("phone", data?.primaryNumber || formik.values.phone);
    const billingAddressData =
      data?.addresses && data?.addresses[0]
        ? cloneDeep(data?.addresses[0])
        : null;
    const shippingAddressData =
      data?.addresses && data?.addresses[1]
        ? cloneDeep(data?.addresses[1])
        : null;
    if (Config.BROADBAND) {
      let shippingAddress = {
        address1: customerAddressObj?.address1,
        address2: customerAddressObj?.address2,
        city: customerAddressObj?.city,
        state: customerAddressObj?.state,
        country: customerAddressObj?.country,
        zip: customerAddressObj?.zip,
      };
      setCustomerAddress(shippingAddress);
      // if address is present in localstorage then update formik
      formik.setFieldValue("shippingAddress", {
        ...shippingAddress,
        type: addressType.shipping,
      });
      formik.setFieldValue("billingAddress", {
        ...shippingAddress,
        type: addressType.billing,
      });
      formik.setFieldValue("isShippingSameAsBilling", true);
    }
    if (Config.IS_AUTOPAY_ENABLED) {
      formik.setFieldValue("autopay", true);
    }
    if (Config.BROADBAND) {
      if (shippingAddressData) {
        formik.setFieldValue("shippingAddress", {
          ...shippingAddressData,
          type: addressType.shipping,
        });
        formik.setFieldValue("billingAddress", {
          ...shippingAddressData,
          type: addressType.billing,
        });
        formState = {
          ...formState,
          shippingAddress: {
            ...shippingAddressData,
            type: addressType.shipping,
          },
        };
      }
    }
    if (billingAddressData && shippingAddressData) {
      delete billingAddressData.type;
      delete shippingAddressData.type;
      formik.setFieldValue("billingAddress", {
        ...billingAddressData,
        type: addressType.billing,
      });
      formik.setFieldValue("shippingAddress", {
        ...shippingAddressData,
        type: addressType.shipping,
      });
      formState = {
        ...formState,
        billingAddress: {
          ...billingAddressData,
          type: addressType.billing,
        },
        shippingAddress: {
          ...shippingAddressData,
          type: addressType.shipping,
        },
      };
      if (
        JSON.stringify(billingAddressData) ===
        JSON.stringify(shippingAddressData)
      ) {
        formik.setFieldValue("isShippingSameAsBilling", true);
        formState = {
          ...formState,
          isShippingSameAsBilling: true,
        };
      }
    }
    dispatch(
      updateFormValues({
        ...formState,
      })
    );
  };

  const handlePaymentFailure = (user: any) => {
    if (location.state && location.state.message && location.state.code) {
      NotificationCust({
        message: location.state.message,
        type: "danger",
        duration: 2000,
        id: "LoginNotification",
        showIcon: true,
      });

      dispatch(
        getInTouchApi({
          data: {
            emailId: user && user.email,
            hubspotMap: {
              email: user && user.email,
              is_test: globalVal.hubspot_is_test,
              is_qa: globalVal.hubspot_is_qa,
              website_purchase_failed: true,
            },
          },
        })
      );
    }
  };
  const getLoggedUser = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        setuser(user);
        if (user?.displayName) {
          setIsLoggedIn(true);
          let name: string = user.displayName;
          let firstName: string =
            name && name.split(" ") && name.split(" ")[0]
              ? name.split(" ")[0]
              : "";
          let lastName: string =
            name && name.split(" ") && name.split(" ")[1]
              ? name.split(" ")[1]
              : "";
          formik.setFieldValue("firstName", firstName);
          formik.setFieldValue("lastName", lastName);
          dispatch(
            updateFormValues({
              firstName,
              lastName,
            })
          );
          let email: string = user && user.email ? user.email : "";
          email &&
            dispatch(
              GetCustomer({
                username: encodeURIComponent(email),
              })
            );
          email &&
            dispatch(
              Eligibility({
                emailId: encodeURIComponent(email),
              })
            );
          handlePaymentFailure(user);
        } else {
          setIsLoggedIn(false);
        }
      }
    });
  };
  useEffect(() => {
    getLoggedUser();
    fetchShipmentPlans();
  }, [isLoggedIn]);
  const fetchShipmentPlans = async () => {
    const shippingRate: any = await firebase.database().ref("/shippingRates");
    shippingRate.on("value", (snapshot: any) => {
      const data = snapshot.val();
      let shipmentPlansList: any = [];
      data.length > 0 &&
        data.map((el: any) => {
          return shipmentPlansList.push({
            label: `${el && el.name} - $${el && el.shippingAmount}`,
            value: el && el.serviceCode,
            cost: el && el.shippingAmount,
            ...el,
          });
        });
      setSendSimOptions(shipmentPlansList);
      const shippingMethod = formik.values.shipmentPlan
        ? formik.values.shipmentPlan
        : shipmentPlansList[0]?.value;
      formik.setFieldValue("shipmentPlan", shippingMethod);
      dispatch(updateFormValues({ shipmentPlan: shippingMethod }));
    });
  };

  /**
   * Dispatching coupon code when only customer is eligeble
   */
  useEffect(() => {
    if (
      EnvConfig?.IS_PROMO_ENABLED &&
      eligibility &&
      eligibility.status === "SUCCESS" &&
      eligibility.data
    ) {
      if (eligibility.data.coupon && !eligibility.data.expired) {
        dispatch(
          Coupon({
            couponCode: eligibility.data.coupon,
          })
        );
      }
    }
  }, [eligibility]);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: billingAndShippingSchema,
    onSubmit: () => {
      // form submit action
      if (Config.IS_AUTOPAY_ENABLED) {
        dispatch(setOpenAutopayConsent(true));
      } else {
        confirmToPayment();
      }
    },
    enableReinitialize: true,
  });

  const confirmToPayment = () => {
    proceedToPaymentHandler(formik.values);
    firebaseNameUpdateHandler();
    updateUserInFirebaseDatabase(formik.values);
  };

  const updateUserInFirebaseDatabase = async (values?: any) => {
    await firebase
      .database()
      .ref("userWebJourneyData/" + window.btoa(user && user.email))
      .once("value")
      .then(() => {
        let shipmentPlanObj: any = sendSimOptions.find(
          (el: any) => (el && el.value) === values.shipmentPlan
        );

        let selectedPlanObj = getPlanObject();
        const updatedNewUserData = {
          extraLines: planDetails?.numberOfLines - 1,
          reachPlanId: Config.BROADBAND
            ? selectedPlanObj?.name
            : planDetails?.selectedPlan?.name,
          selectedShippingMethod: shipmentPlanObj,
          selectedPlan: {
            line: planDetails.numberOfLines,
            plan: planDetails?.selectedPlan,
          },
          addresses: [values.billingAddress, values.shippingAddress],
          primaryNumber: values.phone,
        };
        firebase
          .database()
          .ref("userWebJourneyData")
          .child(window.btoa(user && user.email))
          .update(updatedNewUserData);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const proceedToPaymentHandler = (data: any) => {
    let sendOption: any = sendSimOptions.find(
      (el: any) => (el && el.value) === formik.values.shipmentPlan
    );
    let address: any = Config.BROADBAND
      ? [
          {
            name: `${data.firstName} ${data.lastName}`,
            address1: data.isShippingSameAsBilling
              ? data.shippingAddress.address1
              : data.billingAddress.address1,
            address2: data.isShippingSameAsBilling
              ? data.shippingAddress.address2
              : data.billingAddress.address2,
            city: data.isShippingSameAsBilling
              ? data.shippingAddress.city
              : data.billingAddress.city,
            country: "USA",
            phone: data.phone
              ?.split(" ")
              .join("")
              .replace("(", "")
              .replace(")", "")
              .replace("-", ""),
            state: data.isShippingSameAsBilling
              ? data.shippingAddress.state
              : data.billingAddress.state,
            type: data.billingAddress.type,
            zip: data.isShippingSameAsBilling
              ? data.shippingAddress.zip
              : data.billingAddress.zip,
          },
          {
            address1: data.shippingAddress.address1,
            address2: data.shippingAddress.address2,
            city: data.shippingAddress.city,
            country: data.shippingAddress.country,
            name: `${data.firstName} ${data.lastName}`,
            phone: data.phone
              ?.split(" ")
              .join("")
              .replace("(", "")
              .replace(")", "")
              .replace("-", ""),
            state: data.shippingAddress.state,
            type: data.shippingAddress.type,
            zip: data.shippingAddress.zip,
          },
        ]
      : [
          {
            name: `${data.firstName} ${data.lastName}`,
            address1: data.billingAddress.address1,
            address2: data.billingAddress.address2,
            city: data.billingAddress.city,
            country: "USA",
            phone: data.phone,
            state: data.billingAddress.state,
            type: data.billingAddress.type,
            zip: data.billingAddress.zip,
          },
          {
            address1: data.isShippingSameAsBilling
              ? data.billingAddress.address1
              : data.shippingAddress.address1,
            address2: data.isShippingSameAsBilling
              ? data.billingAddress.address2
              : data.shippingAddress.address2,
            city: data.isShippingSameAsBilling
              ? data.billingAddress.city
              : data.shippingAddress.city,
            country: data.billingAddress.country,
            name: `${data.firstName} ${data.lastName}`,
            phone: data.phone,
            state: data.isShippingSameAsBilling
              ? data.billingAddress.state
              : data.shippingAddress.state,
            type: data.shippingAddress.type,
            zip: data.isShippingSameAsBilling
              ? data.billingAddress.zip
              : data.shippingAddress.zip,
          },
        ];
    let selectedShippingMethod: any = {
      carrierCode: sendOption && sendOption.carrierCode,
      deliveryDays: sendOption && sendOption.deliveryDays,
      name: sendOption && sendOption.name,
      reachCarrierId: sendOption && sendOption.reachCarrierId,
      serviceCode: sendOption && sendOption.serviceCode,
      serviceType: sendOption && sendOption.serviceType,
      shippingAmount: sendOption && sendOption.shippingAmount,
      taxAmount: oneTimeShippingTaxCharges?.totalTax,
    };
    let simPreferenceStoreData: any = [];
    let linePreferenceData: any = {};
    let numOfEsimOrdered = 0;
    let numOfPsimOrdered = 0;
    if (Config.IS_ESIM_SUPPORTED) {
      simPreferenceStoreData = JSON.parse(
        localStorage.getItem("simPreference") || "[]"
      );
      linePreferenceData = {};
      simPreferenceStoreData.map((data: any, index: any) => {
        linePreferenceData["line" + (index + 1)] = {
          imei: data?.imei?.toString(),
          simPreference:
            data?.value === SimTypes.eSim ? "ESIM" : "PHYSICAL_SIM",
          make: data?.make,
          model: data?.model,
        };
      });
      numOfEsimOrdered = simPreferenceStoreData?.filter(
        (data: any) => data?.eSimCompatible
      ).length;
      numOfPsimOrdered = simPreferenceStoreData?.filter(
        (data: any) => !data?.eSimCompatible
      ).length;
    }
    let selectedPlanObj = getPlanObject();
    if (newUser) {
      let customerData: any = {
        countryCode: Config.BROADBAND ? "USA" : data.billingAddress.country,
        firstName: data.firstName,
        lastName: data.lastName,
        isMNP: false,
        isPrimary: true,
        primaryNumber: data.phone.match(/\d/g).join(""),
        addresses: address,
        flowVersion: 1,
        status: 0,
        emailId: user && user.email,
        reachPlanId: Config.BROADBAND
          ? selectedPlanObj?.name
          : planDetails?.selectedPlan?.name,
        isSimInHand: false,
        extraLines: planDetails.numberOfLines - 1,
        selectedShippingMethod: selectedShippingMethod,
        zipcode: Config.BROADBAND
          ? data.shippingAddress.zip
          : data.billingAddress.zip,
      };
      if (Config.IS_ESIM_SUPPORTED) {
        customerData = {
          ...customerData,
          esim: simPreferenceStoreData[0]?.eSimCompatible,
          activationJourneyStatus: ActivationStatus.NOT_STARTED,
          physicalSimsOrdered: numOfPsimOrdered,
          eSimsOrdered: numOfEsimOrdered,
          linePreferences: linePreferenceData,
          imei: simPreferenceStoreData[0]?.imei,
          make: simPreferenceStoreData[0]?.make,
          model: simPreferenceStoreData[0]?.model,
        };
      }
      if (Config.BROADBAND) {
        customerData = {
          ...customerData,
          source: "CABO",
          marketingComEnabled: marketingComEnabled,
          meta: {
            homesPassedId: customerAddressObj?.homesPassedId,
            installation_type: "Self Install",
            fta_code: customerAddressObj?.fta_code,
            oc_code: customerAddressObj?.oc_code,
          },
          modemInfo: modemInfo ? modemInfo : {},
          freeModem:
            modemInfo?.name === "Sparklight Modem" && modemInfo?.unitPrice === 0
              ? true
              : false,
        };
      }
      dispatch(CreateCustomer(customerData));
    } else {
      let userData: any = getCustomer ? cloneDeep(getCustomer) : null;
      let selectedPlanObj = getPlanObject();
      if (userData) {
        userData["addresses"] = address;
        userData["lastName"] = data.lastName;
        userData["firstName"] = data.firstName;
        userData["primaryNumber"] = data.phone.match(/\d/g).join("");
        userData["reachPlanId"] = Config.BROADBAND
          ? selectedPlanObj?.name
          : planDetails?.selectedPlan?.name;
        (userData["zipcode"] = Config.BROADBAND
          ? data.shippingAddress.zip
          : data.billingAddress.zip),
          (userData["source"] = "TMOBILE");
        userData["extraLines"] = planDetails.numberOfLines - 1;
        userData["isSimInHand"] = false;
        userData["selectedShippingMethod"] = selectedShippingMethod;
        let updatedUserData = {
          customerId: userData && userData.id,
          ...userData,
        };
        if (Config.IS_ESIM_SUPPORTED) {
          updatedUserData = {
            ...updatedUserData,
            physicalSimsOrdered: numOfPsimOrdered,
            eSimsOrdered: numOfEsimOrdered,
            linePreferences: linePreferenceData,
          };
        }
        if (Config.BROADBAND) {
          updatedUserData = {
            ...updatedUserData,
            source: "CABO",
            meta: {
              homesPassedId: customerAddressObj?.homesPassedId
                ? customerAddressObj?.homesPassedId
                : currentdetails?.meta?.homesPassedId,
              installation_type: "Self Install",
              fta_code: customerAddressObj?.fta_code
                ? customerAddressObj?.fta_code
                : currentdetails?.meta?.fta_code,
              oc_code: customerAddressObj?.oc_code
                ? customerAddressObj?.oc_code
                : currentdetails?.meta?.oc_code,
            },
            modemInfo: modemInfo ? modemInfo : {},
            freeModem:
              modemInfo?.name === "Sparklight Modem" &&
              modemInfo?.unitPrice === 0
                ? true
                : false,
          };
        }
        dispatch(UpdateCustomer(updatedUserData)).then((response: any) => {
          if (
            EnvConfig?.IS_PROMO_ENABLED &&
            response?.payload?.status === 200 &&
            coupon &&
            customerReservedCoupon &&
            customerReservedCoupon.couponId !== coupon.coupon
          ) {
            dispatch(
              CouponReserve({
                couponId: coupon.coupon,
                customerId: userData.id,
              })
            );
          }
        });
      }
    }
    // pushTrackingEventsToGAAndFB({
    //   category: "Swift Purchase",
    //   actionType: "Proceed To Payment",
    //   label: couponCode,
    // });
    localStorage.removeItem("isWowDiscountEligible");
    setPaymentClicked(true);
  };

  const firebaseNameUpdateHandler = () => {
    const user: any = firebase.auth().currentUser;
    user
      .updateProfile({
        displayName: `${formik.values.firstName} ${formik.values.lastName}`,
        phoneNumber: formik.values.phone.replace(/^\D+/g, ""),
      })
      .then(async () => {
        let user1: any = firebase.auth().currentUser;
        if (user1) {
          let details = JSON.stringify(user1);
          localStorage.setItem("currentuser", details);
          details = JSON.parse(details);
          setuser(details);
        }
      })
      .catch((error: any) => {
        NotificationCust({
          message: error && error.message,
          type: "danger",
          duration: 2000,
          id: "UserUpdateInFirebase",
          showIcon: true,
        });
      });
  };

  return addressContentState && reviewContentState ? (
    <Grid
      container
      sx={{
        width: "90%",
        mx: "auto",
        flexDirection: {
          xs: "column-reverse",
          md: "column-reverse",
          lg: "row",
        },
      }}
    >
      <form
        ref={formRef}
        method="post"
        name="paymentForm"
        id="paymentForm"
        action={Config.CHECKOUT_PAYMENT}
      >
        <input
          type="hidden"
          name="emailId"
          value={user?.email ? user.email : ""}
        />
        <input
          type="hidden"
          name="redirectUrl"
          value={`${window.location.origin}/loader`}
        />
        {/* {EnvConfig?.IS_PROMO_ENABLED && coupon && coupon?.coupon && (
          <input type="hidden" name="promoCode" value={coupon?.coupon} />
        )} */}
        {Config.IS_AUTOPAY_ENABLED && (
          <>
            <input
              type="hidden"
              name="saveCard"
              value={String(isAutopayConsent)}
            />
            <input
              type="hidden"
              name="autoPay"
              value={String(isAutopayConsent)}
            />
            <input
              type="hidden"
              name="defaultCard"
              value={String(isAutopayConsent)}
            />
          </>
        )}
        {(EnvConfig.IS_FREE_MODEM || EnvConfig.IS_STARTER_KIT) && modemInfo && (
          <input type="hidden" name="modemId" value={modemInfo?.modemId} />
        )}
        {EnvConfig.IS_FSM_ENABLED && formik.values.isInstallSelected && (
          <input type="hidden" name="activityType" value={"INSTALL"} />
        )}
      </form>
      {isLoaded || isLoadedPlanTaxCharges || isLoadedShippingTaxes ? (
        <div className={styles.loader_checkOut}>
          <div>{addressContentState?.loader_txt}</div>
        </div>
      ) : null}
      <Grid item xs={12} lg={6} order={{ md: 1, xs: 2 }}>
        <AddressInfo
          formik={formik}
          sendSimOptions={sendSimOptions}
          paymentClicked={paymentClicked}
          proceedToPaymentHandler={proceedToPaymentHandler}
          navigate={navigate}
          user={user}
          isLoggedIn={isLoggedIn}
          line={planDetails?.numberOfLines}
          setUserDetails={setUserDetails}
          content={addressContentState}
          simPreference={simPreference}
          setSimPreference={setSimPreference}
          updateUserInFirebaseDatabase={updateUserInFirebaseDatabase}
        />
      </Grid>
      <Grid item xs={12} lg={6} order={{ md: 2, xs: 1 }}>
        <ReviewPlanDetails
          content={reviewContentState}
          formik={formik}
          shipmentPlans={sendSimOptions}
          simPreference={simPreference}
          setSimPreference={setSimPreference}
          modemInfo={modemInfo}
        />
        <Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
          {" "}
          <PaymentButtons
            paymentClicked={paymentClicked}
            formik={formik.handleSubmit}
            navigate={navigate}
            content={{
              ...addressContentState,
              submit_btn: addressContentState?.submit_btn,
            }}
          />
          {/* Payment Methods */}
          <Box sx={{ marginTop: "30px" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "var(--font_family_Medium)",
                color: "var(--text_color_8)",
                fontSize: "18px",
              }}
            >
              {addressContentState?.payment_methods}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                component={"img"}
                alt={
                  addressContentState?.payment_imgs?.data[0]?.attributes
                    ?.alternativeText
                }
                src={
                  addressContentState?.payment_imgs?.data[0]?.attributes?.url
                }
              />
              <Box
                component={"img"}
                alt={
                  addressContentState?.payment_imgs?.data[1]?.attributes
                    ?.alternativeText
                }
                src={
                  addressContentState?.payment_imgs?.data[1]?.attributes?.url
                }
              />
              <Box
                component={"img"}
                alt={
                  addressContentState?.payment_imgs?.data[2]?.attributes
                    ?.alternativeText
                }
                src={
                  addressContentState?.payment_imgs?.data[2]?.attributes?.url
                }
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <AutopayConsent
        formik={formik}
        confirmToPayment={confirmToPayment}
        setIsAutopayConsent={setIsAutopayConsent}
      />
    </Grid>
  ) : null;
};
