import { ServiceCheckProps } from "./ServiceCheck";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import styles from "./S1_ServiceCheck.module.scss";
import { AutoComplete_T3, ButtonCust_T2 } from "../../widgets";
import { LeadGenerationModal } from "../../home";
import { useState } from "react";

const CheckCoverageComponent = (props: ServiceCheckProps) => {
  const {
    checkAddressHandler,
    handleSelect,
    checkCoverageHandler,
    coverageContent,
    showCoverage,
    checkAddress,
    coverageLoading,
    coverageAddress,
    searchAgainHandler,
    coverageError,
  } = props;

  const {
    heading,
    place_holder,
    check_btn,
    try_again_btn,
    error_1,
    error_2,
    errorImg,
    or,
    share_email,
  } = coverageContent || {};

  const [openLeadGenerationModal, setOpenLeadGenerationModal] = useState(false);
  const [openLeadGenSuccessModal, setOpenLeadGenSuccessModal] = useState(false);
  const [openLeadGenErrorModal, setOpenLeadGenErrorModal] = useState(false);

  const onCloseLeadGenerationModal = () => {
    setOpenLeadGenerationModal(false);
  };
  const onCloseLeadGenSuccessModal = () => {
    setOpenLeadGenSuccessModal(false);
  };
  const onCloseLeadGenErrorModal = () => {
    setOpenLeadGenErrorModal(false);
  };

  const openSuccessModal = () => {
    setOpenLeadGenSuccessModal(true);
  };
  const openErrorModal = () => {
    setOpenLeadGenerationModal(false);
    setOpenLeadGenSuccessModal(false);
    setOpenLeadGenErrorModal(true);
  };

  return (
    coverageContent && (
      <>
        <Grid
          sx={{
            backgroundColor: {
              xs: "var(--primary_background)",
              sm: "var(--primary_background)",
              md: "none !important",
              lg: "none !important",
              width: "100%",
              height: {
                xs: "140px",
                sm: "140px",
                md: 0,
                lg: 0,
              },
            },
          }}
          className={styles.coverage_wrap}
        >
          <Grid
            sx={{
              position: "absolute",
              textAlign: { xs: "center", sm: "left" },
              top: { md: "492px", lg: "725px" },
            }}
            className={styles.coverage_check}
          >
            <Grid
              item
              sx={{
                gap: "20px",
              }}
            >
              <Box>
                {heading && (
                  <Typography gutterBottom className={styles.coverage_heading}>
                    {heading}
                  </Typography>
                )}
                {!showCoverage && !coverageError && (
                  <div
                    className={styles.address_holder}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        checkCoverageHandler(coverageAddress);
                      }
                    }}
                  >
                    <AutoComplete_T3
                      value={checkAddress}
                      onChange={checkAddressHandler}
                      onSelect={(e: any) => {
                        handleSelect(e);
                      }}
                      placeholder={place_holder}
                      className={styles.textField}
                      setOpenLeadGenerationModal={setOpenLeadGenerationModal}
                    />
                    <ButtonCust_T2
                      variantType="primary_underlined"
                      className={styles.submit_btn}
                      startIcon={
                        coverageLoading ? <CircularProgress /> : check_btn
                      }
                      onClick={() => checkCoverageHandler(coverageAddress)}
                      disabled={checkAddress === "" ? true : false}
                    ></ButtonCust_T2>
                  </div>
                )}
                {coverageError && (
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "16px",
                    }}
                    mt={2}
                  >
                    <Box
                      component="img"
                      alt={errorImg?.data?.attributes?.alternativeText}
                      src={errorImg?.data?.attributes?.url}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <Box
                        component={"span"}
                        sx={{
                          display: { xs: "block", sm: "flex" },
                          flexDirection: "row",
                          alignItems: "baseline",
                        }}
                      >
                        <button
                          onClick={searchAgainHandler}
                          className={styles.try_again_btn}
                        >
                          {try_again_btn}
                        </button>
                        <Typography
                          gutterBottom
                          component={"span"}
                          className="coverage_error_msg"
                          sx={{
                            fontFamily: "var(--font_family_Semibold)",
                            fontSize: "18px",
                          }}
                        >
                          &nbsp;{or}&nbsp;
                        </Typography>
                        <button
                          onClick={() => setOpenLeadGenerationModal(true)}
                          className={styles.try_again_btn}
                        >
                          {share_email}
                        </button>
                        <Typography
                          gutterBottom
                          className="coverage_error_msg"
                          sx={{
                            textAlign: { xs: "left", md: "center" },
                            fontFamily: "var(--font_family_Semibold)",
                            fontSize: "18px",
                            margin: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          &nbsp;{error_1}
                        </Typography>
                      </Box>
                      <Typography
                        gutterBottom
                        className="coverage_error_msg"
                        sx={{
                          textAlign: "left",
                          fontFamily: "var(--font_family_Semibold)",
                          fontSize: "18px",
                        }}
                      >
                        {error_2}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {openLeadGenSuccessModal && (
          <LeadGenerationModal
            open={openLeadGenSuccessModal}
            onClose={onCloseLeadGenSuccessModal}
            isSuccessModal={true}
          />
        )}
        {openLeadGenErrorModal && (
          <LeadGenerationModal
            open={openLeadGenErrorModal}
            onClose={onCloseLeadGenErrorModal}
            isErrorModal={true}
          />
        )}
        {openLeadGenerationModal && (
          <LeadGenerationModal
            open={openLeadGenerationModal}
            onClose={onCloseLeadGenerationModal}
            isSuccessModal={false}
            isErrorModal={false}
            openSuccessHandler={openSuccessModal}
            openErrorHandler={openErrorModal}
          />
        )}
      </>
    )
  );
};

export default CheckCoverageComponent;
