import { TextFieldCust, ButtonCust } from "../widgets";
import { SignUpProps } from ".";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Stack,
  Container,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import { T3_LoginAndSignUp, T3_LoginAndSignUpList } from "../";
import EnvConfig from "../../config/env.config.json";
import { Link } from "react-router-dom";
import { RoutingURLs } from "../../config/RoutingURLs";
import TagManager from "react-gtm-module";

const Cursor = "pointer";

const SignUpT3 = (props: SignUpProps) => {
  const theme = useTheme();
  const {
    content,
    onAppleSignUp,
    onGoogleSignUp,
    onFacebookSignUp,
    isSignUpClicked,
    formik,
    handleFormikChange,
    showLogin,
    formSubmit,
    isCheckout,
  } = props;

  const {
    heading_signup,
    signup_desc,
    signup_logos,
    fname_label,
    lname_label,
    email_label,
    pwd_label,
    signup_btn,
    terms1,
    terms_btn1,
    terms2,
    terms_btn2,
    policy1,
    policy_btn1,
    news_update,
    social_login_text,
    already_registered,
    card_heading,
    card_list1,
    card_list2,
    card_list3,
    card_list4,
    already_acc_text,
    already_acc_btn,
    card_bg_img,
  } = content || {};

  const getLogo = (index: number) => signup_logos?.data[index]?.attributes?.url;
  const getAltText = (index: number) =>
    signup_logos?.data[index]?.attributes?.alternativeText;
  const GAAndFBEventsHandler = (
    category: string,
    value: string,
    custEventName?: string,
    extraParams?: any
  ) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.signup,
      category: category,
      ...(value && { buttonValue: value }),
      ...(custEventName && { custEventName: custEventName }),
      ...(extraParams && { additionalParams: extraParams }),
    });
  };
  const handleSignup = (category: any, value: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "sign_up",
        signup_method: "email",
      },
    });
    EnvConfig.IS_GA_EVENTS_REQUIRED &&
      GAAndFBEventsHandler(category, value, "sign_up", {
        signup_method: "email",
      });
  };

  return (
    <Grid container>
      <Grid
        p={{ sm: isCheckout ? "10px" : "100px" }}
        item
        md={isCheckout ? 12 : 5}
      >
        <Typography
          fontSize="38px"
          color={"var(--text_color)"}
          fontFamily={"var(--font_family_Medium)"}
          pt={{ xs: "20px", sm: 0 }}
          pb={{ xs: "20px", sm: "20px" }}
          justifyContent={{ xs: "center", sm: "unset" }}
          display={"flex"}
          sx={{
            textAlign: { xs: "center", sm: "left" },
            fontSize: { xs: "26px", sm: "28px" },
            color: "var(--text_color_8)",
          }}
        >
          {heading_signup}
        </Typography>
        <Typography
          color={"var(--text_color)"}
          fontFamily={"var(--font_family_Semibold)"}
          pb={{ xs: "25px", sm: "40px" }}
          justifyContent={{ xs: "center", sm: "unset" }}
          display={"flex"}
          sx={{ fontSize: { xs: "18px", sm: "20px" } }}
        >
          {signup_desc}
        </Typography>

        <Container
          disableGutters={!useMediaQuery(theme.breakpoints.only("xs"))}
          sx={{ padding: { xs: 0 } }}
        >
          <form
            noValidate
            onKeyDown={(e) => {
              if (e.key.toLocaleLowerCase() === "enter") formik.handleSubmit(e);
            }}
            className="signin-form-fields"
            data-testid="location-input"
            onSubmit={(e) => formSubmit(e)}
          >
            <Grid
              container
              sx={{
                gap: "30px",
                marginBottom: "30px",
                padding: isCheckout ? { xs: 0 } : "10px",
              }}
            >
              <Grid item xs={12} sm={12}>
                <TextFieldCust
                  value={formik.values.firstName}
                  label={fname_label}
                  name="firstName"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik.touched?.firstName && formik.errors.firstName
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched?.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextFieldCust
                  value={formik.values.lastName}
                  label={lname_label}
                  name="lastName"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik.touched?.lastName && formik.errors.lastName
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched?.lastName && formik.errors.lastName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextFieldCust
                  value={formik.values.email}
                  label={email_label}
                  name="email"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik.touched?.email && formik.errors.email ? true : false
                  }
                  helperText={formik.touched?.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextFieldCust
                  value={formik.values.password}
                  label={pwd_label}
                  type="password"
                  name="password"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik.touched?.password && formik.errors.password
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched?.password && formik.errors.password
                  }
                />
              </Grid>
            </Grid>

            <FormGroup row>
              <FormControlLabel
                sx={{
                  mr: "0px",
                  padding: isCheckout ? { xs: 0 } : { xs: "10px" },
                }}
                control={
                  <Checkbox
                    style={{
                      color: "var(--primary_color)",
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue("checked", e.target.checked);
                      formik.setTouched({
                        ...formik.touched,
                        ["checked"]: false,
                      });
                    }}
                  />
                }
                label={
                  <Typography>
                    <Typography
                      component={"span"}
                      sx={{ fontFamily: "var(--font_family_Semibold)" }}
                    >
                      {terms1}&nbsp;
                    </Typography>
                    <Link
                      to={RoutingURLs.terms}
                      target="_blank"
                      style={{
                        color: "var(--primary_color)",
                        fontFamily: "var(--font_family_Semibold)",
                        fontSize: "16px",
                      }}
                    >
                      {terms_btn1}
                    </Link>
                    <Typography
                      component={"span"}
                      sx={{ fontFamily: "var(--font_family_Semibold)" }}
                    >
                      {" "}
                      &nbsp;{terms2}&nbsp;
                    </Typography>
                    <Link
                      to={RoutingURLs.privacy}
                      target="_blank"
                      style={{
                        color: "var(--primary_color)",
                        fontFamily: "var(--font_family_Semibold)",
                        fontSize: "16px",
                      }}
                    >
                      {terms_btn2}
                    </Link>
                  </Typography>
                }
              />
            </FormGroup>
            {formik.touched?.checked && formik.errors.checked && (
              <Typography
                component={"div"}
                sx={{
                  color: "var(--danger)",
                  textAlign: "left",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: "0.75rem",
                  paddingLeft: "32px",
                }}
              >
                {formik.errors.checked}
              </Typography>
            )}
            <FormGroup row>
              <FormControlLabel
                sx={{
                  mr: "0px",
                  padding: isCheckout ? { xs: 0 } : { xs: "10px" },
                }}
                control={
                  <Checkbox
                    style={{
                      color: "var(--primary_color)",
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue("usePolicy", e.target.checked);
                      formik.setTouched({
                        ...formik.touched,
                        ["usePolicy"]: false,
                      });
                    }}
                  />
                }
                label={
                  <Typography>
                    <Typography
                      component={"span"}
                      sx={{ fontFamily: "var(--font_family_Semibold)" }}
                    >
                      {policy1}&nbsp;
                    </Typography>
                    <Link
                      to={RoutingURLs.usepolicy}
                      target="_blank"
                      style={{
                        color: "var(--primary_color)",
                        fontFamily: "var(--font_family_Semibold)",
                      }}
                    >
                      {policy_btn1}
                    </Link>
                  </Typography>
                }
              />
            </FormGroup>
            {formik.touched?.usePolicy && formik.errors.usePolicy && (
              <Typography
                component={"div"}
                sx={{
                  color: "var(--danger)",
                  textAlign: "left",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: "0.75rem",
                  paddingLeft: "32px",
                }}
              >
                {formik.errors.usePolicy}
              </Typography>
            )}
            {EnvConfig.MARKETING_COM_ENABLED && (
              <FormControlLabel
                sx={{ padding: isCheckout ? { xs: 0 } : { xs: "10px" } }}
                control={
                  <Checkbox
                    style={{
                      color: "var(--primary_color)",
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue("marketingCheck", e.target.checked);
                      formik.setTouched({
                        ...formik.touched,
                        ["marketingCheck"]: false,
                      });
                    }}
                  />
                }
                label={
                  <Typography
                    component={"span"}
                    sx={{ fontFamily: "var(--font_family_Semibold)" }}
                  >
                    {news_update}
                  </Typography>
                }
              />
            )}

            <ButtonCust
              variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
              fullWidth
              sx={{ my: "15px" }}
              loading={!isSignUpClicked}
              type={"submit"}
              onClick={() => handleSignup(gaCategory.signup, signup_btn)}
            >
              {signup_btn}
            </ButtonCust>
          </form>
          <Typography textAlign={"center"}>
            <Typography
              component={"span"}
              onClick={showLogin}
              sx={{
                cursor: Cursor,
                fontSize: "16px",
                fontFamily: "var(--font_family_Semibold)",
                color: "var(--primary_color)",
                textDecoration: "underline",
              }}
            >
              {already_registered}
            </Typography>
          </Typography>
        </Container>
        <Divider
          sx={{
            "&.MuiDivider-root": {
              "&::before, &::after": {
                borderTop: `solid 2px var(--ford-grey-color)`,
              },
            },
            padding: "20px 0px",
          }}
        >
          <Typography
            component={"div"}
            fontFamily={"var(--font_family_Semibold)"}
          >
            {social_login_text}
          </Typography>
        </Divider>
        <Stack
          direction={{ xs: "row" }}
          spacing={{ xs: 2, sm: 2 }}
          alignItems={"center"}
          justifyContent={"center"}
          my={{ xs: 2 }}
          gap="65px"
        >
          {EnvConfig.APPLE_SIGN_IN_ENABLED ? (
            <Box
              component="img"
              sx={{ cursor: Cursor }}
              alt={getAltText(0)}
              src={getLogo(0)}
              onClick={onAppleSignUp}
            />
          ) : null}
          {EnvConfig.GOOGLE_SIGN_IN_ENABLED ? (
            <Box
              component="img"
              sx={{ cursor: Cursor }}
              alt={getAltText(1)}
              src={getLogo(1)}
              onClick={onGoogleSignUp}
            />
          ) : null}
          {EnvConfig.FACEBOOK_SIGN_IN_ENABLED ? (
            <Box
              component="img"
              sx={{ cursor: Cursor }}
              alt={getAltText(2)}
              src={getLogo(2)}
              onClick={onFacebookSignUp}
            />
          ) : null}
        </Stack>
      </Grid>
      {!isCheckout && (
        <Grid item md={7}>
          {content && (
            <T3_LoginAndSignUp bgImage={card_bg_img}>
              <Grid
                p={{ xs: "10px", sm: "30px" }}
                mt={{ xs: "15px", sm: "0px" }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color={"white"}
                  fontSize="22px"
                >
                  {card_heading}
                </Typography>
                <Stack spacing={{ xs: 1, sm: 1 }} pt={{ xs: 1, sm: 3 }}>
                  <T3_LoginAndSignUpList content={card_list1} />
                  <T3_LoginAndSignUpList content={card_list2} />
                  <T3_LoginAndSignUpList content={card_list3} />
                  <T3_LoginAndSignUpList content={card_list4} />
                </Stack>
                <Divider
                  sx={{
                    bgcolor: "var(--white)",
                    mt: "15px",
                    width: "15%",
                    height: "1px",
                  }}
                />
                <Typography
                  gutterBottom
                  variant="h1"
                  component="h5"
                  sx={{ fontSize: "22px", color: "var(--white)", my: "20px" }}
                >
                  {already_acc_text}
                </Typography>
                <ButtonCust
                  fullWidth
                  variantType={EnvConfig.SECONDARY_BUTTON_TYPE}
                  sx={{ my: "15px" }}
                  onClick={showLogin}
                >
                  {already_acc_btn}
                </ButtonCust>
              </Grid>
            </T3_LoginAndSignUp>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SignUpT3;
