import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  scheduleInstallationContent: any;
  errorScheduleInstallationContent: any;
};

const initialState: InitialState = {
  scheduleInstallationContent: null,
  errorScheduleInstallationContent: null,
};

// Generates pending, fulfilled and rejected action types
export const getScheduleInstallationContent = createAsyncThunk(
  "fsm/getScheduleInstallationContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.PaymentLanding.ScheduleInstallation.template}${StrapiApiEndPoints.scheduleInstallation}`,
      isStrapiApi: true,
    });
  }
);

const strapiScheduleInstallationSlice = createSlice({
  name: "fsm",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      getScheduleInstallationContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.scheduleInstallationContent = action.payload;
        state.errorScheduleInstallationContent = null;
      }
    );
    builder.addCase(
      getScheduleInstallationContent.rejected,
      (state: InitialState, action: any) => {
        state.scheduleInstallationContent = null;
        state.errorScheduleInstallationContent =
          action.error || "Something went wrong";
      }
    );
  },
});

export default strapiScheduleInstallationSlice.reducer;
