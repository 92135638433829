import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import firebase from "./adapters/firebase";
import { useEffect, useState } from "react";
import {
  getSeoDataContent,
  notificationsContent,
} from "./features/strapi/componentSlice";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "./utils/commonFunctions";
import TagManager from "react-gtm-module";

function App() {
  const dispatch = useAppDispatch();
  const { seoData, notification } = useAppSelector(
    (state: any) => state?.strapi?.commonPage || {}
  );
  const [cacheSeoData, setSeoData] = useState<any>(null);
  const [cacheNotificationsContent, setNotificationsContent] =
    useState<any>(null);
  useEffect(() => {
    if (
      !(
        localStorage.getItem("accessToken") &&
        localStorage.getItem("currentuser")
      )
    ) {
      firebase
        .auth()
        .signInAnonymously()
        .then(async (user) => {
          await localStorage.setItem(
            "currentuser",
            JSON.stringify(user && user.user)
          );
          let user1: any = await localStorage.getItem("currentuser");
          user1 = JSON.parse(user1);
          let token =
            user1 &&
            user1.uid &&
            user1.stsTokenManager &&
            user1.stsTokenManager.accessToken;
          await localStorage.setItem("accessToken", token);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  useEffect(() => {
    getCache("seoData", getSeoDataContent, setSeoData, dispatch);
    getCache(
      "notificationData",
      notificationsContent,
      setNotificationsContent,
      dispatch
    );
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY || "",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("seoData", seoData);
    addMultipleCacheData("notificationData", notification);
  }, [seoData, notification]);
  return <AppRoutes />;
}

export default App;
