import { HomeBannerProps } from "./HomeBanner";
import { Box, Grid, Paper, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import Styles from "./S1_HomeBanner.module.scss";
import config from "../../../config/env.config.json";
import clsx from "clsx";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";
import { CustomerStatus as CS } from "../../../enums/CustomerStatus";

const HomeBannerT1 = (props: HomeBannerProps) => {
  const {
    heading,
    sub_heading,
    button_text,
    banner_web,
    banner_mobile,
    banner_ipad,
    button_text2,
  } = props?.bannerContent || {};
  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  const { pageName } = props;
  const { content_carousel, ticks_main_div, button2 } = Styles;
  let customerDetails: any = localStorage?.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);
  const { status } = customerDetails || {};

  return (
    props?.bannerContent && (
      <Container maxWidth={false} disableGutters sx={{ position: "relative" }}>
        <Paper sx={{ padding: 0 }} elevation={0}>
          {(largeDesktop || desktop) &&
            generateImageContainer({
              data: banner_web, attrs: {
                width: "100%",
                height: "500px",
              }
            })}
          {ipad &&
            generateImageContainer({
              data: banner_ipad, attrs: {
                width: "100%",
                height: "400px",
              }
            })}
          {mobile &&
            generateImageContainer({
              data: banner_mobile, attrs: {
                width: "100%",
                height: "500px",
              }
            })}
        </Paper>
        <Grid
          sx={{
            position: "absolute",
            textAlign: { xs: "center", sm: "left" },
          }}
          className={content_carousel}
        >
          <Grid item>
            <Box>
              {heading && (
                <Typography
                  component="h3"
                  variant="h3"
                  gutterBottom
                  className="main_banner_title"
                >
                  {heading}
                </Typography>
              )}
              {sub_heading && (
                <Typography
                  variant="h4"
                  component="h4"
                  className={clsx(ticks_main_div, "main_banner_sub_title")}
                >
                  {sub_heading}
                </Typography>
              )}
              {button_text && (
                <ButtonCust
                  variantType={config.PRIMARY_BUTTON_TYPE}
                  onClick={props.onButton1Click}
                  sx={{ position: "relative" }}
                  disabled={
                    pageName === "PostActivationHome" &&
                      status !== CS.ACTIVE &&
                      status !== CS.SUSPENDED
                      ? true
                      : false
                  }
                >
                  {button_text}
                </ButtonCust>
              )}
              {button_text2 && (
                <Box
                  className={button2}
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    justifyContent: { xs: "center", sm: "start" },
                    width: { sm: "max-content" },
                    position: { sm: "absolute" },
                    left: { sm: "6%" },
                  }}
                >
                  <Box
                    component="span"
                    sx={{ fontFamily: "inherit" }}
                    onClick={() => props.onButton2Click()}
                  >
                    {button_text2}
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  );
};

export default HomeBannerT1;
