import { Grid, Link, Typography, Box } from "@mui/material";
import { ButtonCust, SimpleModal } from "../../../widgets";
import config from "../../../../config/env.config.json";
import classes from "./PortReqestRejected.module.scss";

export const PortRequestRejected = (props: any) => {
  const {
    modalData,
    changeModal,
    smsHandler,
    retryActivationFn,
    buttonLoaderFg,
  } = props;
  const { title, desc_text, btn, btn1, btn2, cancel, m_img = null } = modalData;
  return (
    <>
      <SimpleModal
        isShowModal={true}
        onCloseModal={false}
        showClose={false}
        sx={{ width: { xs: "90%" } }}
      >
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            {m_img && (
              <Box
                component="img"
                alignSelf={"center"}
                height={"50px"}
                width={"50px"}
                src={m_img?.data?.attributes?.url || ""}
                alt={m_img?.data?.attributes?.alternativeText || ""}
              />
            )}
            <Grid
              item
              xs={12}
              textAlign="center"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  pt: "10px",
                  fontSize: "22px",
                  fontWeight: "var(--font_weight_4)",
                  fontFamily: "var(--font_family)",
                }}
              >
                {title}
              </Typography>
              {btn2 && (
                <Box
                  sx={{
                    border: "3px solid var(--primary_brand_color)",
                    width: "80px",
                    borderRadius: "6px",
                    margin: "12px",
                  }}
                />
              )}
            </Grid>
            <Typography
              sx={{
                padding: "10px 0px",
                fontSize: "14px",
                color: "var(--text_color)",
                fontFamily: "var(--font_family_Medium)",
              }}
            >
              {desc_text}
            </Typography>
            {btn && (
              <ButtonCust
                variantType={config.PRIMARY_BUTTON_TYPE}
                sx={{ m: "10px auto", width: "200px", height: "45px" }}
                onClick={() => retryActivationFn()}
                loading={buttonLoaderFg}
              >
                {btn}
              </ButtonCust>
            )}
            {btn1 && (
              <ButtonCust
                className={classes.btn}
                variantType={config.PRIMARY_BUTTON_TYPE}
                sx={{ m: "10px auto", width: "250px", height: "45px" }}
                onClick={() => smsHandler("smsReceived")}
              >
                {btn1}
              </ButtonCust>
            )}
            {btn2 && (
              <ButtonCust
                className={classes.btn}
                variantType={config.PRIMARY_BUTTON_TYPE}
                onClick={() => smsHandler("smsNotReceived")}
                sx={{ m: "10px auto", width: "250px", height: "45px" }}
              >
                {btn2}
              </ButtonCust>
            )}
            {cancel && (
              <Link
                onClick={() => changeModal()}
                sx={{
                  color: " var(--primary_color)",
                  fontFamily: " var(--font_family)",
                  fontWeight: "var(--font_weight_2)",
                  textDecoration: "none",
                  cursor: "pointer",
                  fontSize: "14px",
                  m: "10px auto",
                }}
              >
                Cancel
              </Link>
            )}
          </Box>
        </>
      </SimpleModal>
    </>
  );
};
