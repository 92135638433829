import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ourPartnersContent } from "../../../features/strapi/giveBackslice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
export type ourPartnerProps = {
  content?: any;
};
const OurPartnersComponent = isTemplateExisits(Config.Home.PlanFeatures)
  ? require(`./${Config.Home.PlanFeatures.template}_OurPartners`).default
  : null;

export const OurPartners = () => {
  const { ourPartners } = useAppSelector((state: any) => state.strapi.giveBack);
  const [cacheDataGbOurPartners, setCacheDataGbOurPartners] = useState<any>(null);
  const dispatch = useAppDispatch();
    useEffect(() => {
      getCache("OurPartnersGiveBack", ourPartnersContent, setCacheDataGbOurPartners, dispatch)
    }, []);
    useNonInitialEffect(() => {
      addMultipleCacheData('OurPartnersGiveBack', ourPartners)
    }, [ourPartners])
  return (cacheDataGbOurPartners || ourPartners ) && 
    <OurPartnersComponent content={cacheDataGbOurPartners ? cacheDataGbOurPartners : ourPartners} />;
};
