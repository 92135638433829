import { Box, Typography, Grid } from "@mui/material";
import styles from "./ActivationStatusSuccess.module.scss";
import config from "../../../../config/env.config.json";
import { useState } from "react";
import {
  gaBrandNames,
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import { useNavigate } from "react-router-dom";
import { VideoDialogCust, SimpleModal, ButtonCust } from "../../../widgets";

type Props = { closeHandler: any; modalData: any; customerDetails?: any };

export function ActivationStatusSuccess(props: Props) {
  const { closeHandler, modalData, customerDetails } = props;
  const { title, btnText, subtext, video_txt } = modalData || {};
  let navigate = useNavigate();
  const [gbmodalShow, setgbmodalShow] = useState(false);

  const gbmodalHide = () => {
    setgbmodalShow(false);
  };
  const gbmodalHandler = (_: any) => {
    GAAndFBEventsHandler(gaCategory.activation, "Watch video");
    setgbmodalShow(true);
  };
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: gaBrandNames.reach,
      screenName: gaScreenNames.activation,
      category: category,
      buttonValue: value,
    });
  };
  return (
    <>
      {gbmodalShow ? (
        <VideoDialogCust
          open={gbmodalShow}
          onClose={() => gbmodalHide()}
          videoUrl="https://d1w56w19nsq45n.cloudfront.net/videos/sim_ios.mp4"
        />
      ) : (
        <SimpleModal
          onCloseModal={closeHandler}
          showClose={true}
          isShowModal={true}
          sx={{
            width: { xs: "95%", sm: "50%", md: "50%", lg: "40%" },
            borderRadius: "6px",
          }}
        >
          <Grid
            container
            display="flex"
            flexDirection="column"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            className={styles.ac_status_succes}
          >
            <Typography variant="body2" className={styles.title}>
              {title}
            </Typography>
            <Typography variant="body2" className={styles.subtitle}>
              {subtext}
            </Typography>
            <Box
              sx={{
                lineHeight: "1.8",
                letterSpacing: "normal",
                fontFamily: "var(--font_family_Semibold)",
                color: "var(--primary_color)",
                fontSize: "16px",
                fontWeight: "var(--font_weight_2)",
                width: "fit-content",
                cursor: "pointer",
              }}
              margin="15px 0"
              onClick={(e: any) => gbmodalHandler(e)}
            >
              <Box
                component="img"
                mb="-4px"
                src="https://strapi-config-images.s3.amazonaws.com/singleWebsite-dev/play_icon_176e416089.svg"
                alt="watch vodeo"
                className={styles.planImg}
              />
              <Box component="span" ml="5px">
                {video_txt}
              </Box>
            </Box>
            {customerDetails?.extraLines >= 1 && customerDetails?.isPrimary && (
              <Box className={styles.button_contact}>
                <ButtonCust
                  variantType={config.PRIMARY_BUTTON_TYPE}
                  onClick={() => navigate("/account")}
                >
                  {btnText}
                </ButtonCust>
              </Box>
            )}
          </Grid>
        </SimpleModal>
      )}
    </>
  );
}
