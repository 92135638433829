import styles from "./S1_Services.module.scss";
import { Box, Grid } from "@mui/material";
import { Divider, MobileStoreButton } from "../widgets";

type Props = {
  content: any;
};

const ServiceComponent = (props: Props) => {
  const { content } = props;
  const {
    bm_desc1,
    is_desc1,
    is_desc2,
    is_desc3,
    sub_head1,
    sub_head2,
    bm_desc2,
    androidMobileStore,
    iosMobileStore,
    ios,
    android,
  } = content;

  const {
    service_content,
    service_content_main,
    service_main,
    service_header_text,
    service_header,
    is_paragraph,
    dot,
    mobile_button_store_main,
    mobile_button_ios,
  } = styles;

  return (
    <Grid
      container
      className={service_content}
    >
      <Grid
        container
        display="flex"
        flexDirection="column"
        sx={{ backgroundColor: "var(--white)" }}
        pt={{ md: 5, xs: 2 }}
      >
        <Grid
          container
          sx={{ pt: { xs: 3, sm: 3.5, md: 6 } }}
          pl={3}
          pr={3}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Grid
            item
            sm={6}
            xs={12}
            className={service_content_main}
            sx={{ mb: { xs: 2, md: 0 } }}
            p={2}
          >
            <Box className={service_header} textAlign={{ xs: "center" }}>
              {sub_head1}
              <Box sx={{ pt: { lg: 2, sm: 3, xs: 3 } }}>
                <Divider />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mt={4}>
              <Box className={dot}></Box>
              <Box className={is_paragraph}>{is_desc1}</Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box className={dot}></Box>
              <Box className={is_paragraph}>{is_desc2}</Box>
            </Box>
            <Box
              className={is_paragraph}
              sx={{ pl: { lg: 2.5, sm: 5, xs: 5 } }}
            >
              {is_desc3}
            </Box>
            <Grid
              item
              display="flex"
              paddingLeft={{ sm: 7, md: 1, xs: 4, lg: 20 }}
              paddingTop={2}
              className={mobile_button_store_main}
            >
              {androidMobileStore && (
                <Grid item>
                  <MobileStoreButton
                    width={100}
                    height={35}
                    store={androidMobileStore.storeName}
                    url={androidMobileStore.navigationPath}
                    linkprops={{ title: androidMobileStore.title }}
                    imagelink={android?.data?.attributes?.url}
                  />
                </Grid>
              )}
              {iosMobileStore && (
                <Grid
                  item
                  paddingLeft={{ xs: 4, sm: 2, md: 1, lg: 3 }}
                  className={mobile_button_ios}
                >
                  <MobileStoreButton
                    width={100}
                    height={35}
                    store={iosMobileStore.storeName}
                    url={iosMobileStore.navigationPath}
                    linkprops={{ title: iosMobileStore.title }}
                    imagelink={ios?.data?.attributes?.url}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item sm={5.8} xs={12} className={service_main} p={2}>
            <Box
              className={service_header_text}
              pb={0.6}
              textAlign={{ xs: "center" }}
            >
              {sub_head2}
              <Box sx={{ pt: { lg: 2, sm: 3, xs: 3 } }}>
                <Divider />
              </Box>
            </Box>
            <Box className={is_paragraph} textAlign="center">
              {bm_desc1}
            </Box>
            <Box className={is_paragraph} textAlign="center">
              {bm_desc2}
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          display="flex"
          flexDirection="column"
          alignItems="center"
          pt={10}
          pb={5}
        />
      </Grid>
    </Grid>
  );
};
export default ServiceComponent;
