import { styled, Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

export const CustToolTip_T2 = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "var(--text_color_1)",
    maxWidth: "285px",
    boxShadow: theme.shadows[1],
    padding: "0px",
    borderRadius: "0",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      backgroundColor: "var(--primary_brand_color)",
      boxShadow: "rgba(0,0,0,0.08)",
      fontSize: "17px",
      borderRadius: "none",
    },
  },
}));
