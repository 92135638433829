import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RoutingURLs } from "../config/RoutingURLs";

const ProtectedRoutes = () => {
  const location = useLocation();
  let user: any = localStorage.getItem("currentuser");
  const currentuser: any = user ? JSON.parse(user) : null;

  return currentuser && !currentuser.isAnonymous ? (
    <Outlet />
  ) : (
    <Navigate to={RoutingURLs.default} replace state={{ from: location }} />
  );
};

export default ProtectedRoutes;
