import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";

type InitialState = {
  paymentData: any;
  errorPaymentData: any;
  isPaymentDataLoading: boolean;
  emailBillStatus: any;
  errorEmailBillStatus: any;
  updateCardExpiry: any;
  errorUpdateCardExpiry: any;
  defaultCard: any;
  errorDefaultCard: any;
  removeCard: any;
  errorRemoveCard: any;
  updateAutoPayCard: any;
  errorUpdateAutoPayCard: any;
  billAutoPay: any;
  errorBillAutoPay: any;
  creditCardData: {
    ccuuid: any;
    expiryDate: any;
    isUpdateCard: boolean;
    errorExpiryDate: any;
    setAsDefault: boolean;
    removeCreditCard: boolean;
    isLoading: boolean;
  };
  customerBilling: any;
  errorCustomerBilling: any;
  referalDetails: any;
  errorReferalDetails: any;
  billingForecast: any;
  errorBillingForecast: any;
  customerPlanDetails: any;
  errorCustomerPlanDetails: any;
  openPayBillModal: boolean;
};
const initialState: InitialState = {
  paymentData: [],
  errorPaymentData: null,
  emailBillStatus: null,
  isPaymentDataLoading: false,
  errorEmailBillStatus: null,
  updateCardExpiry: null,
  errorUpdateCardExpiry: null,
  defaultCard: null,
  errorDefaultCard: null,
  removeCard: null,
  errorRemoveCard: null,
  updateAutoPayCard: null,
  errorUpdateAutoPayCard: null,
  billAutoPay: null,
  errorBillAutoPay: null,
  creditCardData: {
    ccuuid: null,
    expiryDate: null,
    isUpdateCard: false,
    errorExpiryDate: "",
    setAsDefault: false,
    removeCreditCard: false,
    isLoading: false,
  },
  customerBilling: null,
  errorCustomerBilling: null,
  referalDetails: null,
  errorReferalDetails: null,
  billingForecast: null,
  errorBillingForecast: null,
  customerPlanDetails: null,
  errorCustomerPlanDetails: null,
  openPayBillModal: false,
};

export const getPurchaseHistory = createAsyncThunk(
  "billing/getPurchaseHistory",
  async (action: any, { rejectWithValue }) => {
    const { customerId, startDate, endDate } = action;

    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.paymentHistory}/${customerId}?startDate=${startDate}&endDate=${endDate}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const emailBill = createAsyncThunk(
  "billing/emailBill",
  async (action: any, { rejectWithValue }) => {
    const { emailId, invoiceUrl, invoiceType } = action;

    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.emailBill}${emailId}&invoiceUrl=${invoiceUrl}&invoiceType=${invoiceType}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const currentBillPay = createAsyncThunk(
  "billing/currentBillPay",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.currentBillPay}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

// update cc expiry
export const updateCardExpiry = createAsyncThunk(
  "billing/updateCardExpiry",
  async (action: any, { rejectWithValue }) => {
    const { cardId, expiryDate } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "put",
        url: `${ApiEndPoints.updateCardExpiry}${cardId}/expiry/${expiryDate}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);
export const getBillingData = createAsyncThunk(
  "billing/getBillingData",
  async (action: any, { rejectWithValue }) => {
    const { customerId } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.billing}/${customerId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getReferalDetails = createAsyncThunk(
  "billing/referalDetails",
  async (action: any, { rejectWithValue }) => {
    const { customerId } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.referalDetails}/${customerId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const billForecast = createAsyncThunk(
  "billing/billingForecast",
  async (action: any, { rejectWithValue }) => {
    const { customerId } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.billingForecast}${customerId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getCustomerPlan = createAsyncThunk(
  "billing/getCustomerPlan",
  async (action: any, { rejectWithValue }) => {
    const { customerId } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.customerPlan}/${customerId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

// autopay cc
export const autoPayCreditCard = createAsyncThunk(
  "billing/autoPayCreditCard",
  async (action: any, { rejectWithValue }) => {
    const { cardId } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "put",
        url: `${ApiEndPoints.autoPayCreditCard}${cardId}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

// remove cc
export const deleteCreditCard = createAsyncThunk(
  "billing/deleteCreditCard",
  async (action: any, { rejectWithValue }) => {
    const { cardId } = action;
    try {
      const response = await apiJunction.makeRequest({
        method: "delete",
        url: `${ApiEndPoints.removeCreditCard}${cardId}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    manageCardsData: (state, action) => {
      (state.creditCardData.ccuuid = action.payload.ccuuid),
        (state.creditCardData.expiryDate = action.payload.expiryDate),
        (state.creditCardData.isUpdateCard = action.payload.isUpdateCard),
        (state.creditCardData.errorExpiryDate = action.payload.errorExpiryDate),
        (state.creditCardData.removeCreditCard =
          action.payload.removeCreditCard),
        (state.creditCardData.setAsDefault = action.payload.setAsDefault),
        (state.creditCardData.isLoading = action.payload.isLoading);
    },
    setOpenPayBillModal: (state, action) => {
      state.openPayBillModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getPurchaseHistory.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.paymentData = action.payload?.data?.data || null;
        state.errorPaymentData = null;
        state.isPaymentDataLoading = false;
      }
    );
    builder.addCase(getPurchaseHistory.pending, (state) => {
      state.isPaymentDataLoading = true;
    });
    builder.addCase(getPurchaseHistory.rejected, (state, action) => {
      state.paymentData = null;
      state.errorPaymentData = action.payload;
      state.isPaymentDataLoading = false;
    });
    builder.addCase(
      emailBill.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.emailBillStatus = action.payload?.data || null;
        state.errorEmailBillStatus = null;
      }
    );
    builder.addCase(emailBill.rejected, (state, action) => {
      state.emailBillStatus = null;
      state.errorEmailBillStatus = action.payload;
    });
    builder.addCase(
      updateCardExpiry.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateCardExpiry = action.payload?.data?.data || null;
        state.errorUpdateCardExpiry = null;
      }
    );
    builder.addCase(
      updateCardExpiry.rejected,
      (state, action: PayloadAction<any>) => {
        state.updateCardExpiry = null;
        state.errorUpdateCardExpiry = action.payload;
      }
    );
    builder.addCase(
      autoPayCreditCard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateAutoPayCard = action.payload?.data?.data || null;
        state.errorUpdateAutoPayCard = null;
      }
    );
    builder.addCase(
      autoPayCreditCard.rejected,
      (state, action: PayloadAction<any>) => {
        state.updateAutoPayCard = null;
        state.errorUpdateAutoPayCard = action.payload;
      }
    );
    builder.addCase(
      deleteCreditCard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.removeCard = action.payload || null;
        state.errorRemoveCard = null;
      }
    );
    builder.addCase(
      deleteCreditCard.rejected,
      (state, action: PayloadAction<any>) => {
        state.removeCard = null;
        state.errorRemoveCard = action.payload;
      }
    );
    builder.addCase(
      getBillingData.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerBilling = action.payload?.data?.data || null;
        state.errorCustomerBilling = null;
      }
    );
    builder.addCase(
      getBillingData.rejected,
      (state, action: PayloadAction<any>) => {
        state.customerBilling = null;
        state.errorCustomerBilling = action.payload;
      }
    );
    builder.addCase(
      getReferalDetails.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.referalDetails = action.payload?.data?.data || null;
        state.errorReferalDetails = null;
      }
    );
    builder.addCase(
      getReferalDetails.rejected,
      (state, action: PayloadAction<any>) => {
        state.referalDetails = null;
        state.errorReferalDetails = action.payload;
      }
    );
    builder.addCase(
      billForecast.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.billingForecast = action.payload?.data?.data || null;
        state.errorBillingForecast = null;
      }
    );
    builder.addCase(
      billForecast.rejected,
      (state, action: PayloadAction<any>) => {
        state.billingForecast = null;
        state.errorBillingForecast = action.payload;
      }
    );
    builder.addCase(
      getCustomerPlan.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerPlanDetails = action.payload?.data?.data || null;
        state.errorCustomerPlanDetails = null;
      }
    );
    builder.addCase(
      getCustomerPlan.rejected,
      (state, action: PayloadAction<any>) => {
        state.customerPlanDetails = null;
        state.errorCustomerPlanDetails = action.payload;
      }
    );
    builder.addCase(
      currentBillPay.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.billAutoPay = action.payload?.data || null;
        state.errorBillAutoPay = null;
      }
    );
    builder.addCase(
      currentBillPay.rejected,
      (state, action: PayloadAction<any>) => {
        state.billAutoPay = null;
        state.errorBillAutoPay = action.payload;
      }
    );
  },
});

export const { manageCardsData, setOpenPayBillModal } = billingSlice.actions;

export default billingSlice.reducer;
