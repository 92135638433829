import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { getCustomerPlan } from "../../../features/billing/billingSlice";
import _ from "lodash";
// import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import firebase from "../../../adapters/firebase";
import { checkCompatibility } from "../../../features/homepage/homepageSlice";
import { useNavigate } from "react-router-dom";
import { addMultipleCacheData, getCache } from "../../../utils/commonFunctions";
import { EsimIMEIModalContent } from "../../../features/strapi/homePageSlice";
import { SimTypes } from "../../../features/checkout/Types";

const EsimIMEIComponent = isTemplateExisits(Config.Home.EsimImeiCheck)
  ? require(`./${Config.Home.EsimImeiCheck.template}_EsimIMEI`).default
  : null;

export type EsimIMEIProps = {
  showCheckIMEIModal: boolean;
  hideCheckIMEIModal: any;
  fromAddLine?: boolean;
  numOfNewLines?: number;
  showReviewModal?: any;
  simPreference?: any;
  changeSimSelectionHandler?: any;
  fromCheckoutPage?: boolean;
  fromHomePage?: boolean;
  increaseLine?: boolean;
  esimClickedIndex?: any;
  showStep2?: any;
  isAccountScreen?: boolean;
  changePreferenceHandler?: any;
  increaseHandle?: any;
  taxCalculationType2?: any;
  sameArr?: any;
  setShowReviewModal1?: any;
  setEsimTaxCall?: any;
  loader?: boolean;
};
export interface EsimIMEIComponentProps extends EsimIMEIProps {
  closeCompatModal: any;
  handleEnter: any;
  isCompatible: boolean;
  eSimCompatiblity: boolean;
  retry: boolean;
  checkHandler: any;
  imeiNumbers: any;
  imeiHandler: any;
  checkClick: boolean;
  currentFieldIndex: number;
  isImeiUsed: boolean;
  inputDisabledIndex: any;
  imeiData: any;
  errorInCompat: boolean;
  multipleImeiCheck: any;
  imeiNumber: string;
  onChangeHandler: any;
  imeiErr: string;
  inputDisabled: boolean;
  haveTroubleHandler: any;
  moveToCheckoutPage: any;
  setDefaultSimPreference: any;
  closeTroubleHandler: any;
  target: any;
  haveTrouble: any;
  content: any;
}
const EsimIMEI = (props: EsimIMEIProps) => {
  const {
    hideCheckIMEIModal,
    fromAddLine,
    increaseLine,
    numOfNewLines,
    fromCheckoutPage,
    showReviewModal,
    fromHomePage,
    esimClickedIndex,
    showStep2,
    isAccountScreen,
    sameArr,
  } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [imeiNumbers, setImeiNumbers] = useState<any>([]);
  const [imeiNumber, setimeiNumber] = useState("");
  const [imeiErr, setimeiErr] = useState("");
  const [checkClick, setCheckClick] = useState(false);
  const [isCompatible, setisCompatible] = useState(false);
  const [retry, setRetry] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [eSimCompatiblity, setESimCompatiblity] = useState(false);
  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
  const [inputDisabledIndex, setInputDisabledIndex] = useState<number[]>([0]);
  const [imeiData, setImeiData] = useState<any>([]);
  const [currentIMEI, setCurrentIMEI] = useState<any>("");
  const [currentIndex, setCurrentIndex] = useState<any>("");
  const [isImeiUsed, setIsImeiUsed] = useState(false);
  const [haveTrouble, setHaveTrouble] = useState(false);
  const [target, setTarget] = useState(null);
  const [user, setUser] = useState<any>(null);
  const [plan, setPlan] = useState<any>(null);
  const [line, setLine] = useState<any>(null);
  const [increaseLineIMEI, setIncreaseLineIMEI] = useState<any>("");
  const [increaseLineESIM, setIncreaseLineESIM] = useState(false);
  const [increaseLineMake, setIncreaseLineMake] = useState<any>("");
  const [increaseLineModel, setIncreaseLineModel] = useState<any>("");
  const [errorInCompat, setErrorInCompat] = useState(false);
  const { customerPlanDetails } = useAppSelector((state: any) => state.billing);
  const [cacheEsimIMEI, setCacheEsimIMEI] = useState<any>(null);
  const { compatibility } = useAppSelector((state: any) => state.homepage);
  const { esimIMEIContent } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  useEffect(() => {
    let arr: any = [];
    let simDataPref: any = [];
    let reachSelectedPlanLine: any = localStorage.getItem(
      "reachSelectedPlanLine"
    );
    reachSelectedPlanLine = JSON.parse(reachSelectedPlanLine);
    props?.simPreference?.map((line: any) => {
      if (line.eSimOpted && !line.eSimCompatible) {
        arr.push({ imeiNum: "", imeiNumErr: "" });
      }
    });
    if (fromHomePage) {
      new Array(reachSelectedPlanLine).fill(0).forEach((_, index: number) =>
        simDataPref.push({
          id: index,
          eSimOpted: false,
          eSimCompatible: false,
          imei: "",
          value: "Sim",
          make: "",
          model: "",
          isPrimaryLine: index === 0 ? true : false,
        })
      );
      localStorage.setItem("simPreference", JSON.stringify(simDataPref));
    }
    if (fromAddLine || increaseLine) {
      new Array(numOfNewLines)
        .fill(0)
        .forEach(() => arr.push({ imeiNum: "", imeiNumErr: "" }));
    }
    if (fromAddLine && !increaseLine) {
      new Array(numOfNewLines).fill(0).forEach((_, index: number) =>
        simDataPref.push({
          id: index,
          eSimOpted: false,
          eSimCompatible: false,
          imei: "",
          value: "Sim",
          make: "",
          model: "",
          isPrimaryLine: index === 0 ? true : false,
        })
      );
      localStorage.setItem("simPreference", JSON.stringify(simDataPref));
    }
    setImeiNumbers([...imeiNumbers, ...arr]);
    let user1: any = localStorage.getItem("currentuser");
    user1 = JSON.parse(user1);
    if (user1) {
      setUser(user1);
    }
    let planDetails: any = localStorage.getItem("reachSelectedPlanDetail");
    let line: any = localStorage.getItem("reachSelectedPlanLine");
    line = JSON.parse(line);
    planDetails = planDetails ? JSON.parse(planDetails) : null;
    if (planDetails) {
      setPlan(planDetails.plan);
    }
    if (line) {
      setLine(parseInt(line));
    }
    let currentUser: any = localStorage.getItem("customerDetail");
    if (currentUser) {
      currentUser = JSON.parse(currentUser);
      dispatch(getCustomerPlan({ customerId: currentUser?.id }));
    }
    getCache("EsimIMEIModal", EsimIMEIModalContent, setCacheEsimIMEI, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("EsimIMEIModal", esimIMEIContent?.attributes);
  }, [esimIMEIContent]);

  useNonInitialEffect(() => {
    if (
      customerPlanDetails &&
      customerPlanDetails.status === "SUCCESS" &&
      customerPlanDetails.data
    ) {
      let currentUser: any = localStorage.getItem("customerDetail");
      currentUser = JSON.parse(currentUser);
      if (fromAddLine) {
        let additionalLines: any =
          imeiNumbers.length + currentUser.additionalLines;
        let amount: any =
          additionalLines * customerPlanDetails?.data?.additionalLinePrice +
          customerPlanDetails?.data?.baseLinePrice;
        props.taxCalculationType2("billing", amount, additionalLines);
      }
    }
  }, [customerPlanDetails]);

  useNonInitialEffect(() => {
    if (
      compatibility?.data?.status === "SUCCESS" &&
      compatibility?.data?.data
    ) {
      setCheckClick(false);
      if (compatibility?.data?.data?.isValid) {
        setErrorInCompat(false);
      } else {
        setErrorInCompat(true);
      }
      setRetry(true);
      let simPreference: any = localStorage.getItem("simPreference");
      simPreference = JSON.parse(simPreference);
      if (
        !fromHomePage &&
        !isAccountScreen &&
        !increaseLine &&
        esimClickedIndex &&
        fromAddLine
      ) {
        let sameArrIndex: any = Object.keys(sameArr)[currentIndex];
        simPreference[sameArrIndex].eSimOpted = compatibility?.data?.data
          ?.esimAvailable
          ? true
          : false;
        simPreference[sameArrIndex].eSimCompatible = compatibility?.data?.data
          ?.esimAvailable
          ? true
          : false;
        simPreference[sameArrIndex].imei = currentIMEI && currentIMEI;
        simPreference[sameArrIndex].make = compatibility?.data?.data?.make;
        simPreference[sameArrIndex].model = compatibility?.data?.data?.model;
        simPreference[sameArrIndex].value = compatibility?.data?.data
          ?.esimAvailable
          ? "eSim"
          : "Sim";
        simPreference[sameArrIndex].isPrimaryLine =
          simPreference[sameArrIndex]?.id === 0 ? true : false;
        localStorage.setItem("simPreference", JSON.stringify(simPreference));
      } else if (
        !fromHomePage &&
        isAccountScreen &&
        !increaseLine &&
        !fromAddLine
      ) {
        let simData = {
          id: 0,
          eSimOpted: compatibility?.data?.data?.esimAvailable ? true : false,
          eSimCompatible: compatibility?.data?.data?.esimAvailable
            ? true
            : false,
          imei: currentIMEI && currentIMEI,
          make: compatibility?.data?.data?.make,
          model: compatibility?.data?.data?.model,
          value: compatibility?.data?.data?.esimAvailable ? "eSim" : "Sim",
          isPrimaryLine: false,
        };

        if (simPreference === null) {
          simPreference = [];

          simPreference.push({
            ...simData,
          });
        } else {
          simPreference.push({
            ...simData,
            id: simPreference.length,
          });
        }
        localStorage.setItem("simPreference", JSON.stringify(simPreference));
      } else if (increaseLine) {
        setIncreaseLineIMEI(currentIMEI);
        setIncreaseLineMake(compatibility?.data?.data?.make);
        setIncreaseLineModel(compatibility?.data?.data?.model);
        if (compatibility?.data?.data?.esimAvailable) {
          setIncreaseLineESIM(true);
        }
      } else {
        let simPreferenceStorage: any = localStorage.getItem("simPreference");
        simPreferenceStorage = JSON.parse(simPreferenceStorage);
        if (sameArr && simPreferenceStorage) {
          let sameArrIndex: any = Object.keys(sameArr)[currentIndex];
          // To do verify sameArr, currentIndex from checkout
          simPreferenceStorage[sameArrIndex].eSimOpted = compatibility?.data
            ?.data?.esimAvailable
            ? true
            : false;
          simPreferenceStorage[sameArrIndex].eSimCompatible = compatibility
            ?.data?.data?.esimAvailable
            ? true
            : false;
          simPreferenceStorage[sameArrIndex].imei = currentIMEI;
          simPreferenceStorage[sameArrIndex].make =
            compatibility?.data?.data?.make;
          simPreferenceStorage[sameArrIndex].model =
            compatibility?.data?.data?.model;
          simPreferenceStorage[sameArrIndex].value = compatibility?.data?.data
            ?.esimAvailable
            ? "eSim"
            : "Sim";
          simPreferenceStorage[sameArrIndex].isPrimaryLine =
            simPreferenceStorage[sameArrIndex]?.id === 0 ? true : false;
          localStorage.setItem(
            "simPreference",
            JSON.stringify(simPreferenceStorage)
          );
        } else if (!sameArr && simPreferenceStorage && fromAddLine) {
          simPreferenceStorage[currentIndex].eSimOpted = compatibility?.data
            ?.data?.esimAvailable
            ? true
            : false;
          simPreferenceStorage[currentIndex].eSimCompatible = compatibility
            ?.data?.data?.esimAvailable
            ? true
            : false;
          simPreferenceStorage[currentIndex].imei = currentIMEI;
          simPreferenceStorage[currentIndex].make =
            compatibility?.data?.data?.make;
          simPreferenceStorage[currentIndex].model =
            compatibility?.data?.data?.model;
          simPreferenceStorage[currentIndex].value = compatibility?.data?.data
            ?.esimAvailable
            ? SimTypes.eSim
            : SimTypes.sim;
          simPreferenceStorage[currentIndex].isPrimaryLine =
            simPreferenceStorage[currentIndex]?.id === 0 ? true : false;
          localStorage.setItem(
            "simPreference",
            JSON.stringify(simPreferenceStorage)
          );
        } else {
          let updatedPrimarySIMPrefenceData = {
            id: 0,
            eSimOpted: compatibility?.data?.data.esimAvailable ? true : false,
            eSimCompatible: compatibility?.data?.data.esimAvailable
              ? true
              : false,
            imei: currentIMEI,
            make: compatibility?.data?.data?.make,
            model: compatibility?.data?.data?.model,
            value: compatibility?.data?.data.esimAvailable ? "eSim" : "Sim",
            isPrimaryLine: true,
          };
          simPreference[0] = updatedPrimarySIMPrefenceData;
          localStorage.setItem("simPreference", JSON.stringify(simPreference));
        }
      }

      if (inputDisabledIndex.length < imeiNumbers?.length) {
        setInputDisabledIndex([
          ...inputDisabledIndex,
          inputDisabledIndex[inputDisabledIndex.length - 1] + 1,
        ]);
      }
      [imeiData].forEach((d: any) => {
        if (d[currentFieldIndex]) {
          setImeiData((prevState: any) =>
            prevState.map((data: any) => {
              if (data?.id === currentFieldIndex) {
                return {
                  ...data,
                  isValid: compatibility?.data?.data.isValid,
                  esimAvailable: compatibility?.data?.data.esimAvailable,
                  imei: imeiNumber,
                };
              }
              return data;
            })
          );
        } else {
          setImeiData([
            ...imeiData,
            {
              id: currentFieldIndex,
              isValid: compatibility?.data?.data.isValid,
              esimAvailable: compatibility?.data?.data.esimAvailable,
              imei: imeiNumber,
            },
          ]);
        }
      });
      if (compatibility?.data.data.isValid) {
        if (compatibility?.data?.data.esimAvailable) {
          setESimCompatiblity(true);
        }
        setisCompatible(true);
        setInputDisabled(true);
      }
      if (currentIndex === 0) {
        localStorage.setItem(
          "simSelection",
          JSON.stringify(
            compatibility?.data?.data?.esimAvailable ? true : false
          )
        );
      }
      setCurrentFieldIndex((prevState) => prevState + 1);
    }
  }, [compatibility]);
  const closeCompatModal = () => {
    hideCheckIMEIModal(false);
    setCheckClick(false);
    setimeiErr("");
    setimeiNumber("");
    setDefaultSimPreference();
    if (!fromAddLine) {
      props.changePreferenceHandler();
    } else {
      localStorage.removeItem("simPreference");
    }
  };
  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      checkHandler();
    }
  };
  const imeiHandler = (e: any, i: any) => {
    let customerDetail: any = localStorage.getItem("customerDetail");
    customerDetail = JSON.parse(customerDetail);
    let newArr: any = [];
    if (customerDetail?.linePreferences) {
      Object.values(customerDetail.linePreferences).map((el: any) => {
        if (el.imei) {
          newArr.push(el.imei);
        }
      });
    }
    let simPreference: any = localStorage.getItem("simPreference");
    if (simPreference) {
      simPreference = JSON.parse(simPreference);
      Object.values(simPreference).map((el: any) => {
        if (el.imei) {
          newArr.push(el.imei);
        }
      });
    }
    const checkDuplicateIMEI = newArr?.find((el: any) => {
      return el === e.target.value;
    });
    setIsImeiUsed(checkDuplicateIMEI ? true : false);
    setimeiNumber(e.target.value);
    let imeiNumber = [...imeiNumbers];
    imeiNumber[i][e.target.name] = e.target.value;
    setImeiNumbers(imeiNumber);
  };
  const onChangeHandler = (e: any) => {
    let simPreference: any = localStorage.getItem("simPreference");
    simPreference = JSON.parse(simPreference);
    const checkDuplicateIMEI = simPreference?.find(
      (el: any) => el.imei === e.target.value && el.imei !== ""
    );
    setIsImeiUsed(checkDuplicateIMEI ? true : false);
    setimeiNumber(e.target.value);
    setimeiErr("");
  };

  const multipleImeiCheck = async (index: any) => {
    setCurrentIndex(index);
    setCurrentIMEI(imeiNumbers[index].imeiNum);
    if (
      imeiNumbers[index].imeiNum &&
      imeiErr === "" &&
      imeiNumbers[index].imeiNum.length === 15 &&
      /^\d+$/.test(imeiNumbers[index].imeiNum)
    ) {
      setCurrentFieldIndex(index);
      await setCheckClick(true);
      dispatch(
        checkCompatibility({
          imeiNumber: imeiNumbers[index].imeiNum,
          // countryCode: 'usa',
        })
      );
    } else {
      if (imeiNumbers[index].imeiNum === "") {
        let imeiNumError: any = [...imeiNumbers];
        imeiNumError[index] = "This field is required.";
        setImeiNumbers(imeiNumbers);
      } else if (
        imeiNumbers[index].imeiNum !== "" &&
        imeiNumbers[index].imeiNum.length !== 15
      ) {
        let imeiNumError: any = [...imeiNumbers];
        imeiNumError[index] = "Enter valid IMEI Number";
        setImeiNumbers(imeiNumbers);
      } else if (
        imeiNumbers[index].imeiNum !== "" &&
        !/^\d+$/.test(imeiNumbers[index].imeiNum)
      ) {
        let imeiNumError: any = [...imeiNumbers];
        imeiNumError[index] = "Please enter only digits";
        setImeiNumbers(imeiNumbers);
      }
    }
  };

  const checkHandler = async () => {
    if (
      imeiNumber &&
      imeiErr === "" &&
      imeiNumber.length === 15 &&
      /^\d+$/.test(imeiNumber)
    ) {
      setCurrentIndex(0);
      setCurrentIMEI(imeiNumber);
      await setCheckClick(true);
      dispatch(checkCompatibility({ imeiNumber: imeiNumber }));
    } else {
      if (imeiNumber === "") {
        setimeiErr("This field is required.");
      } else if (imeiNumber !== "" && imeiNumber.length !== 15) {
        setimeiErr("Enter valid IMEI Number");
      } else if (imeiNumber !== "" && !/^\d+$/.test(imeiNumber)) {
        setimeiErr("Please enter only digits");
      }
    }
  };
  const moveToCheckoutPage = (simPreference?: string) => {
    localStorage.setItem(
      "simSelection",
      JSON.stringify({
        esim: eSimCompatiblity,
      })
    );
    if (fromAddLine) {
      if (eSimCompatiblity) {
        showReviewModal();
      } else {
        let simPreference: any = localStorage.getItem("simPreference");
        simPreference = JSON.parse(simPreference);
        if (_.isEmpty(simPreference)) {
          simPreference = [];
          imeiNumbers.map(() => {
            simPreference.push({
              id: 0,
              eSimOpted: false,
              eSimCompatible: false,
              imei: "",
              make: "",
              model: "",
              value: "Sim",
              isPrimaryLine: false,
            });
          });
          localStorage.setItem("simPreference", JSON.stringify(simPreference));
        }
        showStep2();
      }
      if (!fromAddLine) hideCheckIMEIModal(false);
    } else {
      if (fromCheckoutPage) {
        setDefaultSimPreference();
        props.changeSimSelectionHandler();
      }
      if (simPreference === "SIM") {
        let simPreference: any = localStorage.getItem("simPreference");
        simPreference = JSON.parse(simPreference);
        if (simPreference) {
          simPreference[0].eSimOpted = false;
          simPreference[0].eSimCompatible = false;
          simPreference[0].value = "Sim";
          simPreference[0].imei = "";
          simPreference[0].make = "";
          simPreference[0].model = "";
          localStorage.setItem("simPreference", JSON.stringify(simPreference));
        }
      }
      if (!fromCheckoutPage) {
        navigate("/checkout");
      }
      hideCheckIMEIModal(false);
    }
  };

  //Set Default Sim Preference if skip checkout clicked
  const setDefaultSimPreference = () => {
    let simPreference: any = localStorage.getItem("simPreference");
    simPreference = JSON.parse(simPreference);
    if (_.isEmpty(simPreference)) {
      simPreference = [];
      simPreference.push({
        id: 0,
        eSimOpted: false,
        eSimCompatible: false,
        imei: "",
        make: "",
        model: "",
        value: "Sim",
        isPrimaryLine: true,
      });
      localStorage.setItem("simPreference", JSON.stringify(simPreference));
    } else {
      simPreference.map((data: any, idx: number) => {
        simPreference[idx].eSimOpted = data?.eSimCompatible ? true : false;
        simPreference[idx].eSimCompatible = data?.eSimCompatible ? true : false;
        simPreference[idx].imei = data?.imei || "";
        simPreference[idx].make = data?.make || "";
        simPreference[idx].model = data?.model || "";
        simPreference[idx].value = data?.eSimCompatible ? "eSim" : "Sim";
        simPreference[idx].isPrimaryLine = data?.isPrimaryLine;
      });
      localStorage.setItem("simPreference", JSON.stringify(simPreference));
    }
  };
  const haveTroubleHandler = async (e: any) => {
    setHaveTrouble(true);
    setTarget(e.target);
    // pushTrackingEventsToGAAndFB({
    //   //   category: "Check Compatibility",
    //   //   actionType: "Having Trouble",
    //   // });
  };
  const closeTroubleHandler = () => {
    setTarget(null);
    setHaveTrouble(false);
  };
  const updateUserInFirebaseDatabase = async (type: string, value: any) => {
    await firebase
      .database()
      .ref("userWebJourneyData/" + btoa(user?.email))
      .once("value")
      .then((snapshot: any) => {
        let userData = snapshot.val();
        userData[type] = value;
        if (type === "selectedPlan") {
          userData["extraLines"] = value && value.line - 1;
          userData["reachPlanId"] = value && value.plan && value.plan.name;
        }
        firebase
          .database()
          .ref("userWebJourneyData")
          .child(btoa(user?.email))
          .update(userData);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const increaseHandle = () => {
    let planDetails: any = localStorage.getItem("reachSelectedPlanDetail");
    planDetails = planDetails ? JSON.parse(planDetails) : null;
    const numberOfLines = parseInt(planDetails?.line);
    const updatedNumberOfLines = numberOfLines + 1;
    if (planDetails) {
      setPlan(planDetails.plan);
    }
    /**
     * In checkout page, Without signed number of lines get changes this firebase will through error.
     * To handle this issue plan and line details are getting updated during customer creation call.
     */
    if (!fromCheckoutPage) {
      updateUserInFirebaseDatabase("selectedPlan", {
        line: updatedNumberOfLines,
        plan: planDetails && planDetails.plan,
      });
    }
    // pushTrackingEventsToGAAndFB({
    //   category: "Swift Purchase",
    //   actionType: "Add Lines",
    //   label: line + 1,
    // });
    localStorage.setItem(
      "reachSelectedPlanLine",
      JSON.stringify(updatedNumberOfLines)
    );
    let simPreferenceStorage: any = localStorage.getItem("simPreference");
    if (simPreferenceStorage) {
      simPreferenceStorage = JSON.parse(simPreferenceStorage);
      simPreferenceStorage.push({
        id: simPreferenceStorage.length,
        eSimOpted: increaseLineESIM ? true : false,
        eSimCompatible: increaseLineESIM ? true : false,
        value: increaseLineESIM ? SimTypes.eSim : SimTypes.sim,
        imei: increaseLineIMEI && increaseLineIMEI,
        make: increaseLineMake && increaseLineMake,
        model: increaseLineModel && increaseLineModel,
        isPrimaryLine: false,
      });
      localStorage.setItem(
        "simPreference",
        JSON.stringify(simPreferenceStorage)
      );
    }
    if (fromCheckoutPage) {
      props.increaseHandle(updatedNumberOfLines);
    }
    setLine(updatedNumberOfLines);
    closeCompatModal();
  };

  return (
    <EsimIMEIComponent
      {...props}
      closeCompatModal={closeCompatModal}
      handleEnter={handleEnter}
      isCompatible={isCompatible}
      eSimCompatiblity={eSimCompatiblity}
      retry={retry}
      checkHandler={checkHandler}
      imeiNumbers={imeiNumbers}
      imeiHandler={imeiHandler}
      checkClick={checkClick}
      currentFieldIndex={currentFieldIndex}
      isImeiUsed={isImeiUsed}
      inputDisabledIndex={inputDisabledIndex}
      imeiData={imeiData}
      errorInCompat={errorInCompat}
      multipleImeiCheck={multipleImeiCheck}
      imeiNumber={imeiNumber}
      onChangeHandler={onChangeHandler}
      imeiErr={imeiErr}
      inputDisabled={inputDisabled}
      haveTroubleHandler={haveTroubleHandler}
      increaseHandle={increaseHandle}
      moveToCheckoutPage={moveToCheckoutPage}
      setShowReviewModal1={props.setShowReviewModal1}
      closeTroubleHandler={closeTroubleHandler}
      target={target}
      haveTrouble={haveTrouble}
      content={cacheEsimIMEI ? cacheEsimIMEI : esimIMEIContent?.attributes}
    />
  );
};

export default EsimIMEI;
