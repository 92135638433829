import { TextFieldCust, ButtonCust } from "../widgets";
import { SignUpProps } from ".";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Stack,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoginAndSignUp, SwitchCust } from "../";
import { numberInStringPattern } from "../../utils/regexPatterns";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../config/env.config.json";
const Cursor = "pointer";

const SignUpT1 = (props: SignUpProps) => {
  const theme = useTheme();
  const {
    content,
    onAppleSignUp,
    onFacebookSignUp,
    onGoogleSignUp,
    isSignUpClicked,
    formik,
    handleFormikChange,
    showLogin,
    formSubmit,
  } = props;

  const {
    heading_logo,
    heading_signup,
    signup_logos,
    fname_label,
    lname_label,
    email_label,
    pwd_label,
    pass_info_main,
    pass_info_1,
    pass_info_2,
    btn_signup,
    terms,
    already_acc1,
    already_acc2,
  } = content || {};

  const passwordLengthCheck = () =>
    formik.values.password &&
    formik.values.password !== "" &&
    formik.values.password.length >= 8;

  const passwordPatternCheck = () =>
    formik.values.password &&
    formik.values.password !== "" &&
    numberInStringPattern.test(formik.values.password.toString());

  const getLogo = (index: number) => signup_logos?.data[index].attributes.url;
  const getAltText = (index: number) =>
    signup_logos?.data[index].attributes.alternativeText;
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.signup,
      category: category,
      buttonValue: value,
    });
  };
  const handleSignup = (category: any, value: any) => {
    GAAndFBEventsHandler(category, value);
  };
  return (
    <LoginAndSignUp>
      <Box>
        <Typography
          component={"h5"}
          variant={"h5"}
          fontSize="20px"
          fontFamily={"var(--font_family_Bold)"}
          pt={{ xs: "25px", sm: 0 }}
          pb={{ xs: "25px", sm: "15px" }}
          textAlign={{ xs: "center", sm: "unset" }}
          display={"flex"}
        >
          <Box
            component="img"
            alt={heading_logo?.data.attributes?.alternativeText}
            src={heading_logo?.data.attributes?.url}
            width="26px"
            height="26px"
            mr="10px"
          />
          {heading_signup}
        </Typography>

        <Divider
          sx={{
            bgcolor: "var(--ford-grey-color)",
            opacity: "0.5",
          }}
        />

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 2 }}
          alignItems={"center"}
          justifyContent={"center"}
          pt={{ xs: 0, sm: 3 }}
        >
          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(0)}
            src={getLogo(0)}
            width="162px"
            height="50px"
            onClick={onAppleSignUp}
          />

          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(1)}
            src={getLogo(1)}
            width="162px"
            height="50px"
            onClick={onFacebookSignUp}
          />

          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(2)}
            src={getLogo(2)}
            width="162px"
            height="50px"
            onClick={onGoogleSignUp}
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(3)}
            src={getLogo(3)}
            width="fit-content"
            height="50px"
            onClick={onAppleSignUp}
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(4)}
            src={getLogo(4)}
            width="fit-content"
            height="50px"
            onClick={onFacebookSignUp}
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(5)}
            src={getLogo(5)}
            width="fit-content"
            height="50px"
            onClick={onGoogleSignUp}
          />
        </Stack>

        <Container
          disableGutters={!useMediaQuery(theme.breakpoints.only("xs"))}
        >
          <Divider
            sx={{
              "&.MuiDivider-root": {
                "&::before, &::after": {
                  borderTop: `dotted 1px var(--ford-grey-color)`,
                },
              },
              padding: "20px 0px",
            }}
          >
            <Typography
              component={"div"}
              fontFamily={"var(--font_family_Bold)"}
            >
              or
            </Typography>
          </Divider>
          <form
            noValidate
            onKeyDown={(e) => {
              /* form will be submitted on press enter key  */
              if (e.key.toLocaleLowerCase() === "enter") formik.handleSubmit(e);
            }}
            className="signin-form-fields"
            data-testid="location-input"
            onSubmit={(e) => formSubmit(e)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextFieldCust
                  value={formik.values.firstName}
                  label={fname_label}
                  name="firstName"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik.touched?.firstName && formik.errors.firstName
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched?.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldCust
                  value={formik.values.lastName}
                  label={lname_label}
                  name="lastName"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik.touched?.lastName && formik.errors.lastName
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched?.lastName && formik.errors.lastName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldCust
                  value={formik.values.email}
                  label={email_label}
                  name="email"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik.touched?.email && formik.errors.email ? true : false
                  }
                  helperText={formik.touched?.email && formik.errors.email}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextFieldCust
                  value={formik.values.password}
                  label={pwd_label}
                  type="password"
                  name="password"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik.touched?.password && formik.errors.password
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched?.password && formik.errors.password
                  }
                />
              </Grid>
            </Grid>
            <Stack
              display={"flex"}
              alignItems={{ xs: "unset", sm: "center" }}
              mt={"22px"}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <Typography
                component={"div"}
                sx={{
                  height: "20px",
                  fontSize: "13px",
                  fontFamily: "var(--font_family_Bold)",
                  color: "var(--grey_shade)",
                }}
              >
                {pass_info_main}
              </Typography>
              <Typography
                component={"div"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  component="div"
                  sx={{
                    fontSize: "11px",
                    ml: "5px",
                    color: `${
                      passwordLengthCheck()
                        ? "var(--primary_brand_color)"
                        : "var(--ford-grey-color)"
                    }`,
                  }}
                >
                  {passwordLengthCheck() ? "✓" : "✕"}
                </Typography>
                <Typography
                  component={"div"}
                  sx={{
                    marginLeft: "7px",
                    fontSize: "13px",
                    fontWeight: "var(--font_weight_1)",
                    fontFamily: "var(--font_family_Semibold)",
                    color: `${
                      passwordLengthCheck()
                        ? "var(--primary_brand_color)"
                        : "var(--ford-grey-color)"
                    }`,
                  }}
                >
                  {pass_info_1}
                </Typography>
              </Typography>
              <Typography
                component={"div"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  component="div"
                  sx={{
                    fontSize: "11px",
                    ml: "5px",
                    color: passwordPatternCheck()
                      ? "var(--primary_brand_color)"
                      : "var(--ford-grey-color)",
                  }}
                >
                  {passwordPatternCheck() ? "✓" : "✕"}
                </Typography>

                <Typography
                  component={"div"}
                  sx={{
                    marginLeft: "7px",
                    fontSize: "13px",
                    fontWeight: "var(--font_weight_1)",
                    fontFamily: "var(--font_family_Semibold)",
                    color: `${
                      passwordPatternCheck()
                        ? "var(--primary_brand_color)"
                        : "var(--ford-grey-color)"
                    }`,
                  }}
                >
                  {pass_info_2}
                </Typography>
              </Typography>
            </Stack>

            <SwitchCust
              handleSwitchToggle={(e: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue("checked", e.target.checked);
                formik.setTouched({
                  ...formik.touched,
                  ["checked"]: false,
                });
              }}
              checked={formik.values.checked}
              label={
                <Typography
                  component={"span"}
                  onClick={() => window.open("/terms", "_blank")}
                  sx={{
                    cursor: Cursor,
                    fontSize: "14px",
                    color: "var(--primary_color)",
                    fontFamily: "var(--font_family_Semibold)",
                  }}
                >
                  {terms}
                </Typography>
              }
              styles={{ marginInline: "auto", marginTop: "15px" }}
            />

            {formik.touched?.checked && formik.errors.checked && (
              <Typography
                component={"div"}
                sx={{
                  color: "var(--danger)",
                  textAlign: "center",
                  fontFamily: "var(--font_family_Bold)",
                  fontSize: "0.75rem",
                }}
              >
                {formik.errors.checked}
              </Typography>
            )}
            <ButtonCust
              fullWidth
              sx={{ my: "15px" }}
              loading={!isSignUpClicked}
              type={"submit"}
              onClick={() => handleSignup(gaCategory.signup, btn_signup)}
            >
              {btn_signup}
            </ButtonCust>
          </form>
          <Typography
            component={"div"}
            textAlign="center"
            fontSize={"14px"}
            fontFamily={"var(--font_family_Semibold)"}
          >
            {already_acc1}&nbsp;
            <Typography
              component={"span"}
              fontSize={"inherit"}
              color={"var(--primary_color)"}
              fontFamily={"inherit"}
              sx={{ cursor: Cursor }}
              onClick={showLogin}
            >
              {already_acc2}
            </Typography>
          </Typography>
        </Container>
      </Box>
    </LoginAndSignUp>
  );
};

export default SignUpT1;
