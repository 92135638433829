import { ButtonCust, TextFieldCust } from "../widgets";
import { LoginProps } from ".";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Stack,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoginAndSignUp } from "../common";

const LoginT1 = (props: LoginProps) => {
  const {
    email,
    password,
    emailError,
    passwordError,
    onChangeHandler,
    handleToggle,
    isLoginClicked,
    handleLogIn,
    onGoogleLogin,
    onAppleLogin,
    onFacebookLogin,
    handleEnter,
    content,
    showSignUp,
  } = props;
  const {
    heading_logo,
    heading_login,
    login_logos,
    email_label_login,
    pwd_label,
    btn_login,
    forgot_pwd,
    no_account1,
    no_account2,
  } = content;
  const theme = useTheme();

  const getLogo = (index: number) => login_logos?.data[index].attributes.url;
  const getAltText = (index: number) =>
    login_logos?.data[index].attributes.alternativeText;
  const Cursor = "pointer";

  return (
    <LoginAndSignUp>
      <Box onKeyDown={handleEnter}>
        <Typography
          component={"h5"}
          variant={"h5"}
          fontSize="20px"
          fontFamily={"var(--font_family_Bold)"}
          pt={{ xs: "25px", sm: 0 }}
          pb={{ xs: "25px", sm: "15px" }}
          textAlign={{ xs: "center", sm: "unset" }}
          display={"flex"}
        >
          <Box
            component="img"
            alt={heading_logo?.data.attributes.alternativeText}
            src={heading_logo?.data.attributes.url}
            width="26px"
            height="26px"
            mr="10px"
          />
          {heading_login}
        </Typography>

        <Divider
          sx={{
            bgcolor: "var(--ford-grey-color)",
            opacity: "0.5",
          }}
        />

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 2 }}
          alignItems={"center"}
          justifyContent={"center"}
          pt={{ xs: 0, sm: 3 }}
        >
          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(0)}
            src={getLogo(0)}
            width="162px"
            height="50px"
            onClick={onAppleLogin}
          />

          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(1)}
            src={getLogo(1)}
            width="162px"
            height="50px"
            onClick={onFacebookLogin}
          />

          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(2)}
            src={getLogo(2)}
            width="162px"
            height="50px"
            onClick={onGoogleLogin}
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(3)}
            src={getLogo(3)}
            width="fit-content"
            height="50px"
            onClick={onAppleLogin}
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(4)}
            src={getLogo(4)}
            width="fit-content"
            height="50px"
            onClick={onFacebookLogin}
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(5)}
            src={getLogo(5)}
            width="fit-content"
            height="50px"
            onClick={onGoogleLogin}
          />
        </Stack>

        <Container
          disableGutters={!useMediaQuery(theme.breakpoints.only("xs"))}
        >
          <Divider
            sx={{
              "&.MuiDivider-root": {
                "&::before, &::after": {
                  borderTop: `dotted 1px var(--ford-grey-color)`,
                },
              },
              padding: "20px 0px",
            }}
          >
            <Typography
              component={"div"}
              fontFamily={"var(--font_family_Bold)"}
            >
              or
            </Typography>
          </Divider>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextFieldCust
                value={email}
                placeholder={email_label_login}
                name="email"
                onChange={onChangeHandler}
                error={emailError !== "" ? true : false}
                helperText={emailError ? emailError : ""}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextFieldCust
                value={password}
                placeholder={pwd_label}
                type="password"
                name="password"
                onChange={onChangeHandler}
                error={passwordError !== "" ? true : false}
                helperText={passwordError ? passwordError : ""}
              />
            </Grid>
          </Grid>
          <ButtonCust
            fullWidth
            sx={{ my: "25px" }}
            loading={!isLoginClicked}
            onClick={isLoginClicked ? () => handleLogIn() : () => null}
          >
            {btn_login}
          </ButtonCust>
          <Typography textAlign={"center"}>
            <Typography
              component={"span"}
              onClick={handleToggle}
              sx={{
                cursor: Cursor,
                fontSize: "14px",
                fontFamily: "var(--font_family_Semibold)",
                color: "var(--primary_color)",
              }}
            >
              {forgot_pwd}
            </Typography>
          </Typography>

          <Typography
            component={"div"}
            textAlign="center"
            fontSize={"14px"}
            pt="15px"
            fontFamily={"var(--font_family_Semibold)"}
          >
            {no_account1}&nbsp;
            <Typography
              component={"span"}
              fontSize={"inherit"}
              color={"var(--primary_color)"}
              fontFamily={"inherit"}
              sx={{ cursor: Cursor }}
              onClick={showSignUp}
            >
              {no_account2}
            </Typography>
          </Typography>
        </Container>
      </Box>
    </LoginAndSignUp>
  );
};

export default LoginT1;
