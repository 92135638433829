import { Box, Typography } from "@mui/material";
import { ButtonCust, SimpleModal } from "../../../widgets";
import styles from "./ContactCustomer.module.scss";
import { useNavigate } from "react-router-dom";

type Props = {
  modalData: any;
  closeHandler: any;
};
export const ContactCustomerDialog = (props: Props) => {
  const { modalData, closeHandler } = props;
  const { title, desc_text, btn, m_img } = modalData || {};
  const {
    contact_customer,
    mobile_img,
    dialog_main,
    dialog_title,
    dialog_desc_text,
  } = styles;
  const navigate = useNavigate();

  return (
    <>
      <SimpleModal
        isShowModal={true}
        onCloseModal={closeHandler}
        showClose={true}
        className={contact_customer}
        sx={{
          borderRadius: "4.8px",
          maxWidth: { xs: "95%", sm: "525px" },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box className={dialog_main}>
          <img
            src={m_img?.data?.attributes?.url || ""}
            alt={m_img?.data?.attributes?.alternativeText || ""}
            className={mobile_img}
          />
          <Typography className={dialog_title}>{title}</Typography>
          <Typography className={dialog_desc_text}>{desc_text}</Typography>
          <ButtonCust fullWidth onClick={() => navigate("/help-center")}>
            {btn}
          </ButtonCust>
        </Box>
      </SimpleModal>
    </>
  );
};
