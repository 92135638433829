const internalBanner = "?populate=banner.banner_web,banner.banner_ipad";
const StrapiApiEndPoints: any = {
  networkCoverage: "-coverage?populate=*",
  header:
    "-header?&populate=*&populate=brand_logo&populate=public_primary_items.subMenu&populate=public_secondary_items.subMenu&populate=nav_link1&populate=mobile_logo&populate=web_logo&populate=tab_logo&populate=login_item.subMenu&populate=pl_primary_items.subMenu&populate=pl_secondary_items.subMenu&populate=c_login_item.subMenu&populate=activation_secondary_items",
  headerCustomer:
    "-header-cust?&populate=*&populate=pra_primary_items.subMenu&populate=pra_secondary_items.subMenu&populate=poa_primary_items.subMenu&populate=poa_secondary_items.subMenu&populate=pra_login_item.subMenu&populate=poa_login_item.subMenu&populate=s_u_p_h_i.subMenu&populate=s_u_s_h_i.subMenu&populate=s_u_login_i.subMenu&populate=user_logo",
  t1_footer:
    "-footer?populate=footerList1&populate=footerList2&populate=footerList3&populate=footerList4&populate=logo_desktop&populate=logo_mobile&populate=logo_tab&populate=assuredCard&populate=socialMediaIons.icon&populate=mobileStore.img&populate=iosMobileStore.img&populate=androidMobileStore.img",
  t2_footer: "-footer?populate=*",
  t3_footer: "-footer?populate=*",
  serviceCheck: "-service-check?populate=*",
  compatibleDevices: "-compatible-device?populate=*",
  hmBestfit: "-hm-bestfit?populate[0]=*",
  leadGeneration: "-lead-generation?populate=*",
  leadGenModal: "-lead-gen-modal?populate=*",
  FAQ: "-faq?populate=*",
  planCard:
    "-plancard?populate=*&populate[0]=lines_tab_component.image&populate[1]=shareable_image&populate[2]=add_line_box&populate[3]=icon_offers.image",
  dataCard: "-dynamic-plancard?populate=*",
  planPage_content: "-plan-page-content?populate=*",
  planFeatures: "-plan-feature?populate[0]=*&populate[1]=plan_feature.image",
  paymentLanding: "-payment-landing?populate=*",
  installationTracker: "-installation-tracker?populate=*",
  cancelInstallation: "-cancel-installation?populate=*",
  device: "-device?populate=*",
  byod: "-byod?populate[0]=*&populate[1]=image",
  billCalculatorBanner: "-billcalculator-banner?populate=*",
  appSection: "-app-section?populate=*",
  bestFitFeatures:
    "-bestfit-feature?populate[0]=*&populate[1]=bestfit_features.image",
  bestFitSavings: "-bestfit-bsaving?populate[0]=*&populate[1]=image",
  bestFitPotentialSavings:
    "-bestfit-psaving?populate[0]=*&populate[1]=image_1,image_2",
  tpBestFit: "-tp-bestfit?populate[0]=*",
  tpHome: "-tp-home?populate[0]=*",
  brandFeature:
    "-brand-feature?populate[0]=*&populate[1]=brandFeatureImg&populate[2]=featureDetails.featureImg&populate[3]=additionalFeature.featureImg&populate[4]=brandFeatureImg_ipad&populate[5]=brandFeatureImg_mobile&populate[6]=additionalFeature.featureImg_mobile&populate[7]=additionalFeature.featureImg_ipad",
  missionList: "-missions-list?populate[0]=*&populate[1]=image_content.image",
  brandList: "-brands-list?populate=*",
  reviews: "-review?populate[0]=*",
  ourPartners: "-ptr?populate[0]=*&populate[1]=ptr_list.logo",
  helpSupport:
    "-help-support?populate=help_center&populate=help_center.hc_button&populate=support&populate=mail_icon&populate=logo&populate=phone_icon&populate=chat_icon&populate=address_details&populate=hs_helmet",
  purchaseFlow: "purchase-journey?populate=*",
  privacy: "-privacy?populate[0]=*&populate[1]=privacy_content",
  usepolicy: "-use-policy?populate=*",
  ceoLetter:
    "-ceo-letter?populate[0]=*&populate[1]=image_desktop,image_ipad,image_mobile",
  teamIntro: "-team-intro?populate=*",
  teamFeedBack: "-team-feed-back?populate=feedback_content.profile_icon",
  compatibilityCheck: "-imei-compatibility?populate=*",
  howItWorks: "-how-it-work?populate=*",
  terms: "-terms-list?populate[0]=*&populate[1]=Terms_content",
  services: "-service?populate=*",
  primaryValues: "-pr-va?populate=*",
  tpGiveBack: "-tp-giveback?populate=*",
  internationalServices:
    "-international-service?&populate=*&populate=alphabets&populate=r_type1.roaming_steps&populate=r_type2.roaming_steps",
  detailCoverage: "-details-coverage?populate=*",
  meetTheRecipients: "-meet-recipient?populate=v_card.v_img",
  login: "-login?populate=*",
  signup: "-sign-up?populate=*",
  compareGraph: "-bill-graph?populate[0]=*&populate[1]=months",
  negResultTop:
    "-bill-n-res-tp?populate[0]=image&populate[1]=bill_res_comp.image&populate[2]=bill_res_comp.video_img",
  discoverContent: "-discover?populate=*",
  donations: "-donation?populate=*",
  stories:
    "-story?populate=pre_i,nxt_i&populate=stry.img&populate=stry.mdl&populate=stry.mdl.img,stry.mdl.shr_i,stry.mdl.fb_i,stry.mdl.tw_i,stry.mdl.ma_i",
  posResultBottom:
    "-bill-p-res-bt?populate[0]=plan_image&populate[1]=plan_image2&populate[2]=bill_res_comp.image&populate[3]=bill_res_comp.video_img",
  netResultsBottom:
    "-bill-n-res-bt?populate[0]=plan_image&populate[1]=plan_image2",
  comparePlans: "cmp-pln?populate=*",
  help: "-help?populate=help_center&populate=help_center.hc_button&populate=support&populate=mail_icon&populate=phone_icon&populate=chat_icon",
  profileDetails: "-profile?populate=*",
  // "-profile?populate[0]=profile_img&populate[1]=edit_icon&populate[2]=prf_err&populate[3]=notifications,&populate[4]=left_bg_img",
  //--------------------------------activation-------------------------------------
  activationFlow: "-activation?populate=*",
  activationImages: "-acimg?populate=*",
  postActivationModals:
    "act-st?populate=newNumSuc.title,newNumSuc.video_img, portSuccess.video_img,zipCode,verizonPin,pinNum,accNum,acivationFailCC.m_img,portingFailCC.m_img,portReject1.m_img,portReject2,smsReceived,smsNotReceived",
  activationIntro: "-actintr?populate=*",
  activationStep2:
    "-act-stp2?populate[0]=*&populate[1]=extra_info.points,sup_md.chat_img,sup_md.call_img,sup_md.mail_img,num_pref,zp_md,c_num.chat_img",
  activationStep3:
    "-actst3?populate=*&populate=pay_now.promo_img,pay_now.visa,pay_now.american_ex,sim_check,pay_fail_md.pay_fail_img",
  activationStep1:
    "-actstp1?populate=*,tm_chgs.visa,tm_chgs.american_exp,imei_form,imei_fail_md,imei_view.dev_img,imei_view.dev_id_img,Iemi_con.iemiContent.img",
  activationStep4:
    "-actstp4?populate=*,act_fail,act_fail.m_img,step4,step4.dev_img,step4.devId_img,step4.cur_no_img,step4.simId_img,step4.acc_num_img,step4.pin_img,step4.zip_code_img,act_isMNP.content,act_isMNP.content.icon",
  activationSteps:
    "-actstep?populate=*,ios_img1,ios_img2,and_img1,and_img2,eSimIOSStep3.steps,eSimAndroidStep3.steps,send_modal,unsure_stps.ios,unsure_stps.android,unsure_stps.ios_images,unsure_stps.ios_images_mweb,unsure_stps.android_images,unsure_stps.android_images_mweb",

  //-------------------------------------------------------------------------------

  trackOrder: "-track-order?populate=*,cancel_img,track_img,image,to_esim.img",
  billingDetails: "-billing?populate=*",
  preSimBanner: "-presim-banner?populate=*",
  postSimBanner: "-postsim-banner?populate=*",
  phoneUnlockModal: `ph-unlock-modal`,
  addressInfo: "-address-info?populate=*",
  reviewPlan: "-review-plan?populate=*",
  mabf: "-mabf?populate=*",
  giveBack: "-my-acc-gp?populate=*",
  bwm: "-gmmwr?populate=*&populate[1]=comp.img",
  dataUsage: "-data-usage?populate=*&populate[1]=Usage_table.amount3",
  PADataUsage: "-data-usg?populate=*",
  autoPayment: "-auto-pay?populate=*",
  AutopayConsent: "-autopay-consent?populate=*",
  paymentHistory: "-payment-history?populate=*",
  manageCard: "-manage-card?populate=*",
  currentBill: "-current-bill?populate=*",
  currentPlan: "-current-plan?populate=*",
  BillingBestFit: "-best-fit?populate=*",
  manageLines: "-mng-line?populate=*",
  outOfData: "-out-of-data?populate=*",
  invoiceDetails: "-inv-detail?populate=*",
  totalCreditsCard: "-tc-card",
  referralCreditsCards: "-referral-credit",
  pendingCreditCard: "-pc-card?populate=*",
  dataDetails: "-data-detail?populate=*",
  cpbdp: "-cpbdp?populate=*",
  cppd: "cppd?populate=*",
  accountPrimaryCard: "-ac-primary-card?populate=*",
  esimCoverageModal: "-esim-coverage-modal?populate=*",
  esimIMEIModal: "-esim-imei-check?populate=*",
  simPreference: "-esim-review-plan?populate=*",
  accountLandingPage: "-acc-land?&populate=*",
  secondaryUserLogin: "-sl",
  shipAdd: "-ship-addline?populate=*",
  reviewAdd: "-review-add?populate=*",
  estBillAdd: "-est-bill-add?populate=*",
  addLineComp: "-add-line-comp?populate=*",
  seoData: "-sdata?populate=*",
  notifications: "-notification",
  selectCardDialog: "sec-card-dlg?populate=*",
  //-------------------------------Internal Banners-------------------------------------//
  bestFitBanner: `-best-fit-banner${internalBanner}`,
  giveBackBanner: `-giveback-banner${internalBanner}`,
  compatibilityCheckBanner: `-imei-compatibility-banner${internalBanner}`,
  ISBanner: `-is-banner${internalBanner}`,
  ISBanner2: `-isbnr2${internalBanner}`,
  billCalBanner: `-billcal-banner${internalBanner}`,
  discoverBanner: `-discover-banner${internalBanner}`,
  servicesBanner: `-service-banner${internalBanner}`,
  billingBanner: `-billing-banner${internalBanner}`,
  accountBanner: `-account-banner${internalBanner}`,
  helpBanner: `-help-ib${internalBanner}`,
  profileBanner: `-prf-banner${internalBanner}`,
  activationBanner: `act-bn${internalBanner}`,

  //-------------------------------Home Banners----------------------------------------//
  homePageBanner: "-home-banner?populate=*",
  paHomeBanner: "-pa-h-b?populate=*",

  //-------------------------------FSM-------------------------------------------------//
  scheduleInstallation: "-schedule-installation?populate=*",
  fieldServices: "-field-service?populate=*",
  bookService: "-book-service?populate=*",
};

export default StrapiApiEndPoints;
