import { Grid, Modal, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import styles from "./S2_HeaderModal.module.scss";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

interface ModalProps {
  display: boolean;
  onClose: any;
  headerContent: any;
  handleNavigation: any;
  authUser?: any;
  isCheckoutHeader?: boolean;
  primaryHeaders?: Array<any>;
  secondaryHeaders?: Array<any>;
  loginItem: any;
  logoutHandler?: any;
  cacheCustomHeader?: any;
}
interface LinkItemI {
  children: any;
  onClickItem: any;
  showActiveIndicator: boolean;
  addClass?: any;
}
const {
  link_item,
  active_link_item,
  header_modal,
  dialog_container,
  header_container,
  secondary_header,
  secondary_header_text_style,
} = styles;

const LinkItem = (props: LinkItemI) => {
  const { children, onClickItem, showActiveIndicator, addClass } = props;
  return (
    <>
      <Box
        className={
          showActiveIndicator
            ? `${link_item} ${active_link_item} ${addClass}`
            : `${link_item} ${addClass}`
        }
        py={1.5}
        onClick={onClickItem}
      >
        {children}
      </Box>
    </>
  );
};
const ExpandableLinkItem = (props: any) => {
  const { handleToggleItem, itemName, menuItems, isToggeledItem, closeMenu } =
    props;
  return (
    <>
      <Box
        className={
          menuItems
            .map((item: any) => item.navigationPath)
            .includes(window.location.pathname)
            ? `${link_item} ${active_link_item}`
            : link_item
        }
        onClick={handleToggleItem}
        py={1.5}
        sx={
          isToggeledItem
            ? { backgroundColor: "#F1F1F1" }
            : { backgroundColor: "transparent" }
        }
      >
        {itemName}
        {isToggeledItem ? (
          <ArrowDropUpIcon onClick={closeMenu} />
        ) : (
          <ArrowDropDownIcon />
        )}
      </Box>
      {isToggeledItem && (
        <Box
          sx={{
            borderBottom: "1px solid var(--ford-grey-color)",
            backgroundColor: "#F1F1F1",
          }}
        >
          {menuItems.length > 0 &&
            menuItems.map((item: any, index: number) => {
              return (
                <Box
                  key={index}
                  className={
                    window.location.pathname === item.navigationPath
                      ? `${link_item} ${active_link_item}`
                      : link_item
                  }
                  py={1.5}
                  onClick={item.action}
                  sx={{
                    border: "none !important",
                    fontFamily: "var(--font_family_Medium) !important",
                  }}
                >
                  {item.label}
                </Box>
              );
            })}
        </Box>
      )}
    </>
  );
};
const HeaderModal = (props: ModalProps) => {
  const { seoData } = useAppSelector(
    (state: any) => state?.strapi?.commonPage || {}
  );

  const {
    display,
    onClose,
    headerContent = {},
    handleNavigation,
    primaryHeaders,
    secondaryHeaders,
    cacheCustomHeader,
  } = props;
  const [selectedItem, setSelectedItem] = useState<string | null>("");
  const navigate = useNavigate();
  const { mobile_logo, web_logo, tab_logo } = headerContent;
  const { user_logo } = cacheCustomHeader;
  const renderHeaderItemContent = (
    item: any,
    index: number,
    showActiveIndicator: boolean = true,
    addClass?: any
  ) => {
    if (item.subMenu && item.subMenu.length > 0) {
      const menuActionItems = item.subMenu.map((subItem: any) => ({
        label: subItem.itemName,
        action: () => {
          setSelectedItem(null);
          handleNavigation(subItem);
        },
        navigationPath: subItem.navigationPath,
      }));
      return (
        <ExpandableLinkItem
          key={`header-item-${index}`}
          itemName={item.itemName}
          isToggeledItem={item.itemName === selectedItem}
          handleToggleItem={() => {
            if (selectedItem) {
              setSelectedItem(null);
            } else {
              setSelectedItem(item.itemName);
            }
          }}
          handleNavigation={handleNavigation}
          menuItems={menuActionItems}
          closeMenu={() => {
            setSelectedItem(null);
          }}
          buttonId={`btn-key${index}`}
          menuId={`menu-key${index}`}
        />
      );
    } else {
      return (
        <LinkItem
          onClickItem={() => {
            handleNavigation(item);
          }}
          showActiveIndicator={
            showActiveIndicator &&
            window.location.pathname === item.navigationPath
          }
          key={`header-item-${index}`}
          addClass={addClass}
        >
          {item.itemName}
        </LinkItem>
      );
    }
  };

  return (
    <Modal
      open={display}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={header_modal}
      sx={{ ouline: "none", overflow: "scroll" }}
      disableAutoFocus
    >
      <Grid
        display="flex"
        flexDirection="column"
        className={header_container}
        justifyContent="space-between"
        padding="0 10px"
      >
        <Grid sx={{ height: "104px", display: "flex", alignItems: "center" }}>
          <Close
            sx={{
              float: "left",
              height: "25px",
              width: "25px",
              cursor: "pointer",
              color: "var(--primary_color)",
              marginLeft: "10px",
            }}
            onClick={onClose}
          />
          <Box sx={{ width: "90%" }}>
            <Box
              component="img"
              src={tab_logo?.data?.attributes?.url || ""}
              alt={tab_logo?.data?.attributes?.alternativeText || ""}
              sx={{
                display: { xs: "block", sm: "block", lg: "none" },
                cursor: "pointer",
                width: "144px",
                margin: "auto",
              }}
              onClick={() => {
                navigate(RoutingURLs.home);
              }}
            ></Box>
          </Box>
        </Grid>
        <Grid
          container
          display="flex"
          flexDirection="column"
          className={dialog_container}
        >
          <Grid item>
            {(primaryHeaders &&
              primaryHeaders.map((headerItem: any, index: number) => {
                return renderHeaderItemContent(headerItem, index);
              })) ||
              null}
          </Grid>
          <Grid item className={secondary_header}>
            {(secondaryHeaders &&
              secondaryHeaders?.map((headerItem: any, index: number) =>
                renderHeaderItemContent(
                  headerItem,
                  index,
                  false,
                  secondary_header_text_style
                )
              )) ||
              null}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default HeaderModal;
