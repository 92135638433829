import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Config from "../../../../config/app.config.json";
import {
  getCustomerBillCycleDataUsage,
  getCustomerDataUsageById,
} from "../../../../features/customer/customerSlice";
import { subMonths, subDays, addDays, parseISO } from "date-fns";
import { GetCustomer } from "../../../../features/checkout/checkoutSlice";
import { getUTCDate } from "../../../../utils/commonFunctions/Date";
import { sortObjectByDates } from "../../../../utils/commonFunctions/SortByKey";
import ConfigJSON from "../../../../config/env.config.json";
import { getDataUsageContent } from "../../../../features/strapi/postActivationHomeSlice";
import { CustomerStatus } from "../../../../enums";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
const DataUsageComponent = isTemplateExisits(
  Config.PostActivationHome.DataUsage
)
  ? require(`./${Config.PostActivationHome.DataUsage.template}_DataUsage`)
      .default
  : null;

export type dataUsageProps = {
  content?: any;
};

export const DataUsage = () => {
  const dispatch = useAppDispatch();
  const [dataUsageInfo, setDataUsageInfo] = useState<any>();
  const [displayStartDate, setDisplayStartDate] = useState<any>();
  const [displayEndDate, setDisplayEndDate] = useState<any>();
  const [selectedDateKeyIndex, setSelectedDateKeyIndex] = useState<number>(0);
  const [totalNumberOfKeys, setTotalNumberOfKeys] = useState<number>(0);
  const [consolidatedBillCycyle, setConsolidatedBillCycle] = useState<any>();
  const { customerUsageData, customerBillCycleDataUsage } = useAppSelector(
    (state: any) => state.customer
  );

  const [cacheContent, setCacheContent] = useState<any>(null);
  const { dataUsage } = useAppSelector(
    (state: any) => state.strapi.postActivation
  );

  useEffect(() => {
    getCache("DataUsageHome", getDataUsageContent, setCacheContent, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("DataUsageHome", dataUsage);
  }, [dataUsage]);

  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const isValidBillCyclePayload = () => {
    return (
      getCustomer?.id &&
      getCustomer.nextBillingDate &&
      getCustomer?.activationDate
    );
  };
  useEffect(() => {
    fetchCustomerDetails();
  }, []);
  useEffect(() => {
    if (getCustomer?.id && isValidBillCyclePayload()) {
      loadCurrentDataUsageData();
      if (isValidBillCyclePayload()) {
        const startDate = parseISO(getCustomer?.activationDate);
        const endDate = parseISO(getCustomer?.billStartDate);
        fetchBillCycleDataUsage(getUTCDate(startDate), getUTCDate(endDate));
      }
    }
  }, [getCustomer]);
  useEffect(() => {
    if (customerUsageData && customerBillCycleDataUsage) {
      fetchDataUsageDetails();
    }
  }, [customerUsageData, customerBillCycleDataUsage]);
  const fetchCustomerDetails = () => {
    let loggedUser: any = localStorage.getItem("currentuser");
    loggedUser = loggedUser ? JSON.parse(loggedUser) : null;
    if (loggedUser?.email) {
      dispatch(GetCustomer({ username: encodeURIComponent(loggedUser.email) }));
    }
  };
  const loadCurrentDataUsageData = () => {
    dispatch(getCustomerDataUsageById(getCustomer?.id));
  };
  const fetchBillCycleDataUsage = (startDate: string, endDate: string) => {
    const payload = {
      userId: getCustomer.id,
      startDate,
      endDate,
    };
    dispatch(getCustomerBillCycleDataUsage(payload));
  };
  const fetchDataUsageDetails = () => {
    const initialDisplayStartDate = parseISO(getCustomer?.billStartDate);
    const initialDisplayEndDate = parseISO(getCustomer?.billEndDate);
    setDisplayStartDate(initialDisplayStartDate);
    setDisplayEndDate(initialDisplayEndDate);
    const formatedEndDate = getUTCDate(parseISO(getCustomer?.billEndDate));
    const latestRecordInCurrentDataUsage = {
      [formatedEndDate]: customerUsageData[formatedEndDate],
    };
    const completeBillCycleDataUsage = {
      ...customerBillCycleDataUsage,
      ...latestRecordInCurrentDataUsage,
    };
    const sortedDataUsage: any = sortObjectByDates(completeBillCycleDataUsage);
    setConsolidatedBillCycle(sortedDataUsage);
    setTotalNumberOfKeys(Object.keys(sortedDataUsage).length);
    setSelectedDateKeyIndex(Object.keys(sortedDataUsage).length - 1);
    const endDate = subDays(parseISO(getCustomer.nextBillingDate), 1);
    const uDataInfo = sortedDataUsage[getUTCDate(endDate)];
    setDataUsageInfo(uDataInfo);
  };
  const handleBillCycleChange = (type: string) => {
    let newSelectedKey: number = selectedDateKeyIndex;
    if (type === "decrement") {
      newSelectedKey = selectedDateKeyIndex - 1;
    } else {
      newSelectedKey = selectedDateKeyIndex + 1;
    }
    setSelectedDateKeyIndex(newSelectedKey);
    handleDateKeyChange(newSelectedKey);
  };
  const handleDateKeyChange = (newSelectedKey: number) => {
    let newEndDate: any = Object.keys(consolidatedBillCycyle)[newSelectedKey];
    let newStartDate = null;
    if (!ConfigJSON.FIXED_DAYS_BILLING) {
      // newStartDate = addDays(subMonths(parseISO(newEndDate), 1),1)
      newStartDate = addDays(subMonths(parseISO(newEndDate), 1), 1);
    } else {
      newStartDate = null; // Need to config the value in config.json
    }
    if (newSelectedKey === totalNumberOfKeys - 1) {
      // For handling current details bill cycle dates
      setDisplayStartDate(parseISO(getCustomer?.billStartDate));
    } else {
      setDisplayStartDate(newStartDate);
    }
    setDisplayStartDate(newStartDate);
    setDisplayEndDate(parseISO(newEndDate));
    setDataUsageInfo(consolidatedBillCycyle[newEndDate]);
  };

  return getCustomer?.status === CustomerStatus.ACTIVE ||
    getCustomer?.status === CustomerStatus.SUSPENDED ||
    getCustomer?.status === CustomerStatus.HOTLINED ||
    getCustomer?.status === CustomerStatus.REJECTED ||
    getCustomer?.status === CustomerStatus.DISABLED ? (
    <DataUsageComponent
      dataUsage={dataUsageInfo}
      startDate={displayStartDate}
      endDate={displayEndDate}
      handleBillCycleChange={handleBillCycleChange}
      selectedDateKeyIndex={selectedDateKeyIndex}
      totalNumberOfKeys={totalNumberOfKeys}
      content={cacheContent ? cacheContent : dataUsage}
      fetchDataUsageDetails={loadCurrentDataUsageData}
    />
  ) : null;
};
