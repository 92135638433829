import { useEffect, useState } from "react";
import Config from "../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { termsContent } from "../../features/strapi/termSlice";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
const TermsComponent = isTemplateExisits(Config.Terms.TermsOfUse)
  ? require(`./${Config.Terms.TermsOfUse.template}_Terms`).default
  : null;

export const TermsOfUse = () => {
  const [cacheTerms, setCacheTerms] = useState<any>(null);
  const { termsData, errorTermsData } = useAppSelector(
    (state: any) => state.strapi.terms
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache('Terms', termsContent, setCacheTerms, dispatch)
 },[]);

 useNonInitialEffect(() => {
  addMultipleCacheData('Terms',termsData);
}, [termsData]);

  return (termsData || cacheTerms) ? (
    <TermsComponent content={cacheTerms ? cacheTerms : termsData} />
  ) : errorTermsData ? (
    <div>{errorTermsData}</div>
  ) : null;
};
