import "./TextFieldCust.scss";
import TextField, { TextFieldProps } from "@mui/material/TextField";

type ITextFieldProps = TextFieldProps & {
  maxlength?: number;
  minLength?: number;
  className?: string;
  readOnly?: boolean;
  endAdornment?: any;
  sx?: any;
};

export const TextFieldCust: React.FC<ITextFieldProps> = (props) => {
  return (
    <TextField
      onBlur={props.onBlur}
      className={`TextField ${props.className ? props.className : ""}`}
      label={props.label}
      type={props.type}
      placeholder={props.placeholder}
      {...(props.id && { id: props.id })}
      onChange={props.onChange}
      variant={props.variant || "filled"}
      value={props.value}
      error={props.error}
      helperText={props.helperText}
      disabled={props.disabled}
      inputProps={{
        maxLength: props.maxlength,
        minLength: props.minLength,
        readOnly: props.readOnly,
      }}
      InputProps={{ endAdornment: props.endAdornment }}
      multiline={props.multiline}
      rows={props.rows}
      maxRows={props.maxRows}
      name={props.name}
      sx={props.sx}
      autoComplete="off"
    />
  );
};
