export enum activityType {
  Install = "INSTALL",
  TroubleCall = "TROUBLE_CALL",
}

export enum serviceMap {
  INSTALL = "Modem Installation",
  TROUBLE_CALL = "Troubleshooting",
}

export enum activitySubType {
  firstType = "+I",
}
export enum createdBy {
  self = "self",
}
export enum activityTitle {
  IntallActivityByReach = "Install Activity By Reach",
  TroubleCallActivityByReach = "Trouble Call Activity By Reach",
}
export enum installationStatus {
  cancelled = "cancelled",
  pending = "pending",
  suspended = "suspended",
  started = "started",
  complete = "complete",
}

export enum installationSubject {
  unAssigned = "unassigned",
  assigned = "assigned",
  started = "started",
  completed = "completed",
  suspended = "suspended",
  cancelled = "cancelled",
}

export enum uiStatuses {
  scheduled = "scheduled",
  reOpening = "reOpening",
  reOpened = "reOpened",
  suspended = "suspended",
  cancelled = "cancelled",
}

export enum activityContentType {
  INSTALLISCOMPLETED = "installIsCompleted",
  INSTALLINPROGRESS = "installInProgress",
  TROUBLE_CALL = "troubleCall",
}
