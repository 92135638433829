import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";

type InitialState = {
  hubspotData: any;
  errorHubspot: any;
  planData: any;
  planCost: number | any;
  isExpensive: boolean;
  comparePlanFormValues: any;
  saveAmt: number;
};
const initialState: InitialState = {
  hubspotData: null,
  errorHubspot: null,
  planData: null,
  planCost: null,
  isExpensive: false,
  comparePlanFormValues: {
    dataUsage: "",
    currentAmount: "",
    mailID: "",
    userName: "",
    rangeSlider: 1,
  },
  saveAmt: 0,
};

export const hubspotPost = createAsyncThunk(
  "billCalculator/hubspotPost",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.hubspot}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const billCalculatorSlice = createSlice({
  name: "billCalculator",
  initialState,
  reducers: {
    suitablePlanData: (state, action) => {
      state.planData = action.payload.planData;
      state.planCost = action.payload.planCost;
      state.isExpensive = action.payload.isExpensive;
      state.saveAmt = action.payload.saveAmt;
    },
    storeFormData: (state, action) => {
      state.comparePlanFormValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      hubspotPost.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.hubspotData = action.payload.data;
        state.errorHubspot = null;
      }
    );
    builder.addCase(hubspotPost.rejected, (state, action) => {
      state.hubspotData = null;
      state.errorHubspot = action.payload;
    });
  },
});

export const { suitablePlanData, storeFormData } = billCalculatorSlice.actions;

export default billCalculatorSlice.reducer;
