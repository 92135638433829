declare global {
  interface Window {
    gtag: any;
  }
}
export function pushTrackingEventsToGAAndFB(
  event: string,
  eventName: string,
  eventInfo: {
    appName: any;
    screenName: any;
    category?: any;
    buttonValue?: any;
    custEventName?: string;
    additionalParams?: any;
  }
) {
  try {
    window.gtag(
      event,
      eventInfo.custEventName ? eventInfo.custEventName : eventName,
      {
        app_name: eventInfo.appName,
        screen_name: eventInfo.screenName,
        category: eventInfo.category || "",
        button_value: eventInfo.buttonValue || "",
        ...(eventInfo?.additionalParams && eventInfo?.additionalParams),
      }
    );
  } catch (_e) {
    return null;
  }
}
