import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import { version } from "../../config/RoutingURLs";

type InitialState = {
  data: any;
  errorData: any;
  openCoverageModal: boolean;
  dynamicPlansData: any;
  dynamicPlansLoading: boolean;
  errorDynamicPlansData: any;
  deviceList: any;
  errorDeviceList: any;
  deviceListLoading: boolean;
};
const initialState: InitialState = {
  data: {},
  errorData: null,
  openCoverageModal: false,
  dynamicPlansData: {},
  dynamicPlansLoading: false,
  errorDynamicPlansData: null,
  deviceList: [],
  errorDeviceList: null,
  deviceListLoading: false,
};

export const reservePromoCoupon = createAsyncThunk(
  "planpage/reservePromoCoupon",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "post",
      url: `/catalogsvc/${version}/coupon/email/mapping`,
      body: action.payload.data,
    });
  }
);

export const getPlansByAddress = createAsyncThunk(
  "planpage/getPlansByAddress",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/catalogsvc/${version}/reachplans/active?address=${action}`,
    });
  }
);

export const getDeviceList = createAsyncThunk(
  "planpage/getDeviceList",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/srchsvc/${version}/device/list?from=${action.from}&size=${action.size}&q=${action.searchquery}`,
    });
  }
);
const planpageSlice = createSlice({
  name: "planpage",
  initialState,
  reducers: {
    setOpenCoverageModal: (state, action) => {
      state.openCoverageModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      reservePromoCoupon.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.data = action.payload;
        state.errorData = null;
      }
    );
    builder.addCase(reservePromoCoupon.rejected, (state, action) => {
      state.data = {};
      state.errorData = action.error || "Something went wrong";
    });
    builder.addCase(
      getPlansByAddress.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.dynamicPlansData = action.payload?.data?.data[0];
        state.errorDynamicPlansData = null;
        state.dynamicPlansLoading = false;
      }
    );
    builder.addCase(getPlansByAddress.pending, (state) => {
      state.dynamicPlansData = {};
      state.errorDynamicPlansData = null;
      state.dynamicPlansLoading = true;
    });
    builder.addCase(getPlansByAddress.rejected, (state, action) => {
      state.dynamicPlansData = {};
      state.errorDynamicPlansData = action.error || "Something went wrong";
      state.dynamicPlansLoading = false;
    });
    builder.addCase(
      getDeviceList.fulfilled,
      (state, action: PayloadAction<any>) => {
        // let newList = [...(state.deviceList || []), action.payload?.data?.data];
        state.deviceList = action.payload?.data?.data;
        state.errorDeviceList = null;
        state.deviceListLoading = false;
      }
    );
    builder.addCase(
      getDeviceList.pending,
      (state, action: PayloadAction<any>) => {
        state.deviceList = null;
        state.errorDeviceList = null;
        state.deviceListLoading = true;
      }
    );
    builder.addCase(
      getDeviceList.rejected,
      (state, action: PayloadAction<any>) => {
        state.deviceList = null;
        state.errorDeviceList = action.payload || "Something went wrong";
        state.deviceListLoading = false;
      }
    );
  },
});
export const { setOpenCoverageModal } = planpageSlice.actions;
export default planpageSlice.reducer;
