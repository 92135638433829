import { Box, Typography } from "@mui/material";
import {
  AutoComplete,
  ButtonCust,
  ButtonCust_T2,
  SimpleModal,
  TextFieldCust,
} from "../../widgets";
import { LeadGenModalProps } from "./LeadGenerationModal";
import styles from "./S1_LeadGenerationModal.module.scss";
const LeadGenerationModalComponent = (props: LeadGenModalProps) => {
  const {
    open,
    isSuccessModal = false,
    isErrorModal = false,
    loading,
    handleChange,
    handleSelect,
    submitHandler,
    closeHandler,
    checkAddressHandler,
    email,
    checkAddress,
    emailError,
    leadContent,
    isLoggedIn = false,
    resetErrorHandler = () => null,
  } = props || {};

  return (
    leadContent && (
      <SimpleModal
        isShowModal={open}
        onCloseModal={closeHandler}
        showClose={isErrorModal ? false : true}
        className={styles.modal}
        style={{ maxWidth: "90%" }}
      >
        {isSuccessModal && (
          <img
            src={leadContent?.success_img?.data?.attributes?.url}
            alt="success icon"
            className={styles.top_image}
          />
        )}
        {isErrorModal && (
          <img
            src={leadContent?.error_img?.data?.attributes?.url}
            alt="success icon"
            className={styles.top_image}
          />
        )}

        {!isErrorModal && (
          <Typography
            variant="h5"
            sx={{
              fontSize: { xs: "20px", sm: "26px" },
            }}
            className={`${
              isSuccessModal ? styles.success_heading : styles.heading
            }`}
          >
            {isSuccessModal ? leadContent?.success_text : leadContent?.heading}
          </Typography>
        )}
        <Typography
          variant="subtitle2"
          className={`${
            isSuccessModal || isErrorModal
              ? styles.success_subheading
              : styles.subheading
          }`}
          sx={{
            my: isErrorModal ? 1 : 2,
            fontFamily: "var(--font_family_Medium)",
            lineHeight: isSuccessModal || isErrorModal ? "23px" : "20px",
          }}
        >
          {isSuccessModal
            ? leadContent?.intouch_text
            : isErrorModal
            ? leadContent?.error_text
            : leadContent?.sub_heading}
        </Typography>
        {!isSuccessModal && !isErrorModal && (
          <>
            <Box sx={{ width: "100%", my: 1 }}>
              <AutoComplete
                value={checkAddress}
                onChange={checkAddressHandler}
                onSelect={(e: any) => {
                  handleSelect(e);
                }}
                placeholder={leadContent?.address_ph}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextFieldCust
                label={leadContent?.email_ph}
                value={email}
                onChange={handleChange}
                type="text"
                disabled={isLoggedIn}
              />
              <Typography color="red">{emailError}</Typography>
            </Box>
            <Box mt={4} mb={2}>
              <ButtonCust
                disabled={email === "" || checkAddress === ""}
                sx={{
                  width: "300px",
                  borderRadius: "0 !important",
                  fontSize: "18px !important",
                }}
                type="submit"
                variantType={"primary_contained_square"}
                onClick={submitHandler}
                loading={loading}
              >
                {leadContent?.submit_btn}
              </ButtonCust>
            </Box>
            <ButtonCust_T2
              variantType="primary_underlined"
              className={styles.cancel_btn}
              onClick={closeHandler}
            >
              {leadContent?.cancel_btn}
            </ButtonCust_T2>
          </>
        )}
        {isErrorModal && (
          <Box mt={3} mb={2}>
            <ButtonCust
              sx={{
                width: "180px",
                borderRadius: "0 !important",
                fontSize: "18px !important",
              }}
              variantType={"primary_contained_square"}
              onClick={() => {
                resetErrorHandler();
                closeHandler();
              }}
            >
              {leadContent?.error_btn}
            </ButtonCust>
          </Box>
        )}
      </SimpleModal>
    )
  );
};

export default LeadGenerationModalComponent;
