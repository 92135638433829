import { validateEmail } from "../../../utils/commonFunctions/ValidateEmail";
import { ButtonCust, TextFieldCust } from "../../widgets";
import Config from "../../../config/env.config.json";
import { CircularProgress } from "@mui/material";
import "./S2_LeadGeneration.scss";
import { LeadGenProps } from "./LeadGeneration";

const LeadGenerationComponent = (props: LeadGenProps) => {
  const {
    heading,
    sub_heading,
    place_holder,
    button_text,
    success_text,
    intouch_text,
    check_icon,
    three_lines,
    three_lines_mobile,
  } = props.leadContent || {};
  return (
    <div className="container-fluid pt-5 pb-5 lead_generation">
      <div
        data-testid="title"
        className="lead_generation_title h3-custom text-center"
      >
        {heading}
      </div>
      <div className="lead_generation_line"></div>
      <div className="lead_generation_subtitle mt-3 text-center">
        {sub_heading}
      </div>
      {props.emailSharedCoverage ? (
        <>
          <div className="col-md-5 mx-auto text-center">
            <div className="email_success mx-auto d-flex">
              {check_icon?.data?.attributes?.url && (
                <img
                  src={check_icon?.data?.attributes?.url}
                  alt=""
                  className="email_success_img"
                />
              )}
              <div className="email_success_text">{success_text}</div>
            </div>
            <div className="keep_in_touch_text text-center mx-auto">
              {intouch_text}
            </div>
          </div>
        </>
      ) : (
        <div
          className="col-md-7 mx-auto d-md-flex d-xs-block d-sm-block justify-content-evenly lead_generation_text_field px-3 px-md-0"
          onKeyPress={(e: any) => {
            if (e.key === "Enter") {
              props.submitHandler(e);
            }
          }}
        >
          <div className="col-xs-12 col-md-7 lead_generation_text_field_container">
            <img
              src={three_lines?.data?.attributes?.url}
              className="three_line web d-none d-sm-none d-md-block"
            />
            <img
              src={three_lines_mobile?.data?.attributes?.url}
              className="three_line mobile d-md-none d-sm-clock d-block"
            />
            <TextFieldCust
              value={props.email}
              label={place_holder}
              onChange={props.emailHandler}
              error={
                (props.submitClick && !props.email) ||
                (props.email !== "" && !validateEmail(props.email))
              }
              helperText={props.helperTextHandler()}
            />
          </div>
          <div className="col-xs-12 col-md-4 mt-3 mt-sm-3 mt-md-0">
            <ButtonCust
              className="lead_generate_t1"
              variantType={Config.PRIMARY_BUTTON_TYPE}
              onClick={(e: any) => props.submitHandler(e)}
            >
              {!props.submitClick ? (
                button_text
              ) : (
                <CircularProgress className="loaderCheck" />
              )}
            </ButtonCust>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadGenerationComponent;
