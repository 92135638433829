export const CardTypes = {
  VISA: "VISA",
};
export enum ShipmentTypes {
  MODEM = "MODEM",
  BYOD = "BYOD",
}
export enum OrderStatuses {
  DELIVERED = "DELIVERED",
  ORDERED = "ORDERED",
}
