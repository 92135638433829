import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import _ from "lodash";
import { format } from "date-fns";
import { getScheduleInstallationContent } from "../../../features/strapi/fsmSlice";

const ScheduleInstallationTemplate = isTemplateExisits(
  Config.PaymentLanding.ScheduleInstallation
)
  ? require(`./${Config.PaymentLanding.ScheduleInstallation.template}_ScheduleInstallation`)
      .default
  : null;

type Props = {
  selectedDate: string;
  setSelectedDate: any;
  selectedTime: string;
  setSelectedTime: any;
  selectedDateCal: string;
  setSelectedDateCal: any;
  callInstallationActivity?: any;
  loader: boolean;
  isReschedule?: boolean;
  closeRescheduleModal?: any;
  callRescheduleOrReOpenInstallActivity?: any;
  isReopening?: boolean;
  closeScheduleModal?: any;
  isFieldService?: boolean;
  isModal?: boolean;
  isTroubleCall?: boolean;
};

export interface ScheduleInstallationProps extends Props {
  content: any;
  startDate: any;
  isOpen: boolean;
  handleChange: any;
  handleClick: any;
}

const ScheduleInstallationComponent = (props: Props) => {
  const dispatch = useAppDispatch();

  const { scheduleInstallationContent } = useAppSelector(
    (state: any) => state.strapi.fsm
  );
  const [cacheContent, setCacheContent] = useState<any>(null);
  const [startDate, setStartDate] = useState(
    new Date(Date.now() + 3600 * 1000 * 24)
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getCache(
      "ScheduleInstallation",
      getScheduleInstallationContent,
      setCacheContent,
      dispatch
    );
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData(
      "ScheduleInstallation",
      scheduleInstallationContent?.data?.data?.attributes
    );
  }, [scheduleInstallationContent]);

  const handleChange = (e: any) => {
    setIsOpen(!isOpen);
    props.setSelectedDateCal(format(e, "yyyy-MM-dd"));
    props.setSelectedDate("");
    props.setSelectedTime("");
    setStartDate(e);
  };
  const handleClick = (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    (cacheContent || scheduleInstallationContent) && (
      <ScheduleInstallationTemplate
        content={
          cacheContent
            ? cacheContent
            : scheduleInstallationContent?.data?.data?.attributes
        }
        startDate={startDate}
        isOpen={isOpen}
        handleChange={handleChange}
        handleClick={handleClick}
        {...props}
      />
    )
  );
};

export default ScheduleInstallationComponent;
