import { ByodProps } from "./Byod";
import styles from "./S1_Byod.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import config from "../../../config/env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";

const ByodComponent = (props: ByodProps) => {
  const {
    title,
    description,
    button_1,
    button_2,
    image,
    shop_new_phones_link,
  } = props?.byodContent || {};
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: config.brand,
      screenName: gaScreenNames.plan,
      category: category,
      buttonValue: value,
    });
  };
  return (
    props?.byodContent && (
      <Grid
        container
        className={styles.byod}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          p: {
            xs: "calc(var(--mobile_section_padding)/2) 0",
            sm: "calc(var(--ipad_section_padding)/2) 0",
            md: "calc(var(--desktop_section_padding)/2) 0",
          },
        }}
      >
        <Grid
          item
          md={6}
          className={styles.content_section}
          sx={{
            m: "20px 0",
            display: { xs: "flex", md: "block" },
            justifyContent: { md: "space-around", xs: "center" },
            alignItems: "center",
            flexDirection: { xs: "column" },
          }}
          order={{ xs: 3, md: 2 }}
          px={{ xs: 0, sm: "35px" }}
        >
          <Typography variant="h3" textAlign={{ xs: "center", md: "justify" }}>
            {title}
          </Typography>
          <Grid item xs={12}>
            <Box
              className={styles.byod_line}
              sx={{ m: { sm: "20px 0" }, my: 2.5 }}
            ></Box>
          </Grid>
          <Typography
            variant="body1"
            className="description_text"
            sx={{ mb: { sm: "25px" } }}
            textAlign={{ xs: "center", md: "justify" }}
          >
            {description}
          </Typography>
          <Grid
            item
            container
            xs={12}
            className={styles.btn_section}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { xs: "center", md: "start" },
            }}
          >
            <Box
              sx={{ mr: { sm: "20px" }, my: { xs: "10px", lg: "0" } }}
              display="flex"
              justifyContent="center"
            >
              {button_1 && (
                <ButtonCust
                  variantType={config.PRIMARY_BUTTON_TYPE}
                  onClick={() => {
                    props.onImeiCheckClick();
                    GAAndFBEventsHandler(
                      gaCategory.networkCompatible,
                      button_1
                    );
                  }}
                  className={styles.btn_cmp1}
                >
                  {button_1}
                </ButtonCust>
              )}
            </Box>
            <Box
              sx={{ mr: { sm: "20px", md: "0" }, my: { xs: "10px", lg: "0" } }}
              display="flex"
              justifyContent="center"
            >
              {button_2 && (
                <ButtonCust
                  variantType={config.PRIMARY_BUTTON_TYPE}
                  onClick={() => {
                    props.onShopNewPhonesClick(shop_new_phones_link);
                    GAAndFBEventsHandler(
                      gaCategory.networkCompatible,
                      button_2
                    );
                  }}
                  className={styles.btn_cmp2}
                >
                  {button_2}
                </ButtonCust>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item md={6} order={{ XS: 2, md: 3 }}>
          <Box
            component="img"
            alt={image?.data?.attributes?.alternativeText}
            src={image?.data?.attributes?.url || ""}
            className={styles.byod_image}
            width={"100%"}
            height={"100%"}
          ></Box>
        </Grid>
      </Grid>
    )
  );
};
export default ByodComponent;
