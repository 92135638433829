import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { CustToolTip_T2, TextFieldCust, TextFieldCust_T2 } from "../../widgets";
import styles from "./S5_AddressInfo.module.scss";
import { AddressField } from "../AddressField/AddressField";
import { PaymentButtons } from "../PaymentButtons/PaymentButtons";
import { AddressFieldProps } from "../AddressInfo/AddressInfo";
import { geocodeByAddress } from "react-places-autocomplete";
import { addressType } from "../../../features/checkout/Types";
import {
  displayPhoneNumberFormat,
  toTitleCase,
} from "../../../utils/commonFunctions/ReusableFunctions";
import { PhoneNoPattern } from "../../../utils/regexPatterns";
import { SignUpAndLogin } from "../SignUpAndLogin";
import { countryList } from "../../../config/USAStateList";
import { useAppDispatch } from "../../../app/hooks";
import { updateFormValues } from "../../../features/checkout/checkoutSlice";
import EnvConfig from "../../../config/env.config.json";

const T5_AddressInfo = (props: AddressFieldProps) => {
  const {
    formik,
    isLoggedIn,
    line,
    setUserDetails,
    content,
    simPreference,
    setSimPreference,
  } = props;
  const dispatch = useAppDispatch();
  const { firstName, lastName, email, phone, isShippingSameAsBilling } =
    formik.values;
  const handleShippingAddress = (value: any, name: string) => {
    formik.setFieldValue(`shippingAddress.${name}`, value);
  };
  const constructAddress2 = (data: any) => {
    let address2 = "";
    if (data?.apt_des) address2 = address2 + toTitleCase(data?.apt_des) + " ";
    if (data?.apt_unit) address2 = address2 + data?.apt_unit;
    return address2;
  };
  const handleBillingAddress = (value: any, name: string) => {
    let addressObj = "billingAddress";
    formik.setTouched({
      ...formik.touched,
      [addressObj]: {
        ...formik.touched[addressObj],
        city: true,
        state: true,
        zip: true,
        country: true,
      },
    });
    let billAddress = {
      address1: `${value?.street_number} ${toTitleCase(
        value?.prefix_direction
      )} ${toTitleCase(value?.street_name)} ${toTitleCase(
        value?.suffix_direction
      )}`,
      address2: constructAddress2(value),
      city: toTitleCase(value?.city),
      state: value?.state_abbreviation,
      zip: value?.zip,
      country: "USA",
      type: addressType.billing,
    };
    formik.setFieldValue("billingAddress", billAddress);
    dispatch(updateFormValues({ billingAddress: billAddress }));
  };
  const handleAddressSearchSelection = async (
    address: any,
    addressObj: "shippingAddress" | "billingAddress"
  ) => {
    const results = await geocodeByAddress(address);
    let city: string = "",
      state: string = "",
      zip: string = "",
      country: string = "",
      route: string = "",
      streetNumber: string = "";
    results &&
      results[0] &&
      results[0].address_components &&
      results[0].address_components.map((el: any) => {
        state = el.types.includes("administrative_area_level_1")
          ? el.short_name
          : state;
        zip = el.types.includes("postal_code") ? el.short_name : zip;
        city = el.types.includes("locality")
          ? el.long_name
          : el.types.includes("sublocality")
          ? el.long_name
          : city;
        streetNumber = el.types.includes("street_number")
          ? el.short_name
          : streetNumber;
        route = el.types.includes("route") ? el.long_name : route;
        country = el.types.includes("country") ? el.short_name : country;
        return null;
      });
    const addressData = {
      address1: `${streetNumber} ${route}`,
      city: city,
      state: state,
      zip: zip,
      country: countryList?.[0]?.value,
    };
    if (country === "US") {
      formik.setFieldValue(
        `${addressObj}.address1`,
        `${streetNumber} ${route}`
      );
      formik.setFieldValue(`${addressObj}.city`, city);
      formik.setFieldValue(`${addressObj}.state`, state);
      formik.setFieldValue(`${addressObj}.zip`, zip);
      formik.setFieldValue(`${addressObj}.country`, "USA");
      formik.setTouched({
        ...formik.touched,
        [addressObj]: {
          ...formik.touched[addressObj],
          city: true,
          state: true,
          zip: true,
          country: true,
        },
      });
      if (formik.values.isShippingSameAsBilling) {
        formik.setFieldValue("shippingAddress", {
          ...addressData,
          type: addressType.shipping,
        });
        formik.setTouched({
          ...formik.touched,
          shippingAddress: {
            ...formik.touched["shippingAddress"],
            city: true,
            state: true,
            zip: true,
            country: true,
          },
        });
      }
    }
    let addresses = {
      [addressObj]: {
        ...addressData,
        type:
          addressObj === "billingAddress"
            ? addressType.billing
            : addressType.shipping,
      },
    };
    if (formik.values.isShippingSameAsBilling) {
      addresses = {
        billingAddress: {
          ...addressData,
          type: addressType.billing,
        },
        shippingAddress: {
          ...addressData,
          type: addressType.shipping,
        },
      };
    }
    dispatch(
      updateFormValues({
        ...addresses,
      })
    );
  };
  const updateFieldsState = (
    e: any,
    addressObjName: "shippingAddress" | "billingAddress"
  ) => {
    dispatch(
      updateFormValues({
        [addressObjName]: {
          ...formik.values[addressObjName],
          [e.target.name]: e.target.value,
        },
      })
    );
  };
  const {
    left_sec_heading,
    email_id_text,
    line_checkout,
    text,
    same_billing_address,
    radio_option_note,
    shipping_options,
    note_head,
    note_div,
    checkout_left,
    bill_content_subtext,
    billing_content,
    bill_content_title,
    text_fields_main,
    text_fields_div,
    payment_preference,
    preference_title,
    preference_desc,
    payment_methods,
    methods_title,
    payment_cards,
    account_labels,
  } = styles;
  let simPreferenceStore: any = JSON.parse(
    localStorage.getItem("simPreference") || "[]"
  );
  const alleSimCompatible: boolean = simPreferenceStore.every(
    (sim: any) => sim?.eSimCompatible === true
  );
  const totalNumberOfPhysicalSims: number = simPreferenceStore.filter(
    (sim: any) => !sim?.eSimCompatible
  ).length;

  return (
    <Box className={checkout_left}>
      {!isLoggedIn ? (
        <SignUpAndLogin line={line} setUserDetails={setUserDetails} />
      ) : (
        <>
          {content?.title && (
            <Box className={left_sec_heading}>
              <Box
                component={"img"}
                height="26px"
                width="21px"
                alt={content?.location_img?.data?.attributes?.alternativeText}
                src={content?.location_img?.data?.attributes?.url}
              />
              <Box>{content?.title}</Box>
            </Box>
          )}
          {/* Personal Details */}
          <Box className={bill_content_title}>
            {content?.personal_details_title}
          </Box>
          <Box className={billing_content}>
            <Box>
              <Box className={text_fields_main}>
                <Box className={text_fields_div}>
                  <TextFieldCust_T2
                    disabled
                    value={firstName}
                    name="firstName"
                    label={content.fn_ph}
                    onChange={(e: any) => {
                      if (
                        /^[a-zA-Z]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    helperText={
                      formik.touched?.firstName && formik.errors?.firstName
                    }
                    error={
                      formik.touched?.firstName && formik.errors?.firstName
                        ? true
                        : false
                    }
                  />
                </Box>
                <Box className={text_fields_div}>
                  <TextFieldCust_T2
                    disabled
                    value={lastName}
                    name="lastName"
                    label={content?.ln_ph}
                    onChange={(e: any) => {
                      if (
                        /^[a-zA-Z]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    helperText={
                      formik.touched?.lastName && formik.errors?.lastName
                    }
                    error={
                      formik.touched?.lastName && formik.errors?.lastName
                        ? true
                        : false
                    }
                  />
                </Box>
              </Box>
              <Box className={text_fields_main}>
                <Box className={text_fields_div}>
                  <TextFieldCust_T2
                    disabled
                    value={
                      props.user && props.user.user && props.user.user.email
                        ? props.user.user.email
                        : props.user && props.user.email
                        ? props.user.email
                        : ""
                    }
                    name="email"
                    label={content?.email}
                    onChange={(e: any) => {
                      if (
                        /^[a-zA-Z]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    helperText={formik.touched?.email && formik.errors?.email}
                    error={
                      formik.touched?.email && formik.errors?.email
                        ? true
                        : false
                    }
                  />
                </Box>
                <Box className={text_fields_div}>
                  <TextFieldCust
                    value={phone ? displayPhoneNumberFormat(phone) : ""}
                    name="phone"
                    maxlength={10}
                    label={content?.phone_ph}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (
                        (PhoneNoPattern.test(value) && Number(value) >= 0) ||
                        value === ""
                      ) {
                        if (value.length === 10) {
                          e.target.value = value
                            .replace(/\D+/g, "")
                            .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                        }
                        formik.handleChange(e);
                      }
                      if (value.length > 10) {
                        const contactNumber: string = String(
                          value.match(/\d/g)?.join("") || ""
                        );
                        e.target.value = contactNumber;
                        formik.handleChange(e);
                      }
                    }}
                    helperText={formik.touched?.phone && formik.errors?.phone}
                    error={
                      formik.touched?.phone && formik.errors?.phone
                        ? true
                        : false
                    }
                    onBlur={(e) =>
                      dispatch(updateFormValues({ phone: e.target.value }))
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Service Address */}
          <Box className={bill_content_title}>{content?.shipping_title}</Box>
          <AddressField
            content={content?.shipping}
            formik={formik}
            handleSelect={(e: any) =>
              handleAddressSearchSelection(e, "shippingAddress")
            }
            handleAddressChange={handleShippingAddress}
            addressObjName="shippingAddress"
            onBlurCallback={updateFieldsState}
          />
          {/* Billing Address */}
          <Box>
            <Box className={same_billing_address}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      color: "var(--primary_color)",
                    }}
                    checked={isShippingSameAsBilling}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue(
                        "isShippingSameAsBilling",
                        e.target.checked
                      );
                      if (e.target.checked) {
                        let billAddress = {
                          ...formik.values.shippingAddress,
                          type: addressType.billing,
                        };
                        formik.setFieldValue("billingAddress", billAddress);
                        dispatch(
                          updateFormValues({ billingAddress: billAddress })
                        );
                      } else {
                        formik.setTouched({}, false);
                      }
                      dispatch(
                        updateFormValues({
                          isShippingSameAsBilling: e.target.checked,
                        })
                      );
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{ fontFamily: "var(--font_family_Semibold)" }}
                  >
                    {content?.add_check_l}
                  </Typography>
                }
              />
            </Box>
            {!isShippingSameAsBilling && (
              <Box>
                <Box className={bill_content_title}>
                  {content?.billing_title}
                </Box>
                <AddressField
                  content={content?.billing}
                  formik={formik}
                  handleSelect={(e: any) =>
                    handleAddressSearchSelection(e, "billingAddress")
                  }
                  handleAddressChange={handleBillingAddress}
                  addressObjName="billingAddress"
                  onBlurCallback={updateFieldsState}
                />
              </Box>
            )}
          </Box>
          {/* Payment Preference */}
          <Box className={payment_preference}>
            <Typography className={preference_title}>
              {content?.payment_preference}
            </Typography>
            <Box className={preference_desc}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formik.values.autopay !== undefined
                        ? formik.values.autopay
                        : true
                    }
                    style={{
                      color: "var(--primary_color)",
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue("autopay", e.target.checked);
                      formik.setTouched({
                        ...formik.touched,
                        ["autopay"]: false,
                      });
                    }}
                  />
                }
                label={
                  <Typography component="div" sx={{ display: "flex" }}>
                    <Typography
                      sx={{ fontFamily: "var(--font_family_Semibold)" }}
                    >
                      {content?.payment_desc}
                    </Typography>
                    <CustToolTip_T2
                      title={
                        <Typography
                          variant="subtitle2"
                          color={"var(--text_color_8)"}
                          fontSize={"16px"}
                          fontFamily={"var(--font_family_Semibold)"}
                          padding={"10px"}
                          borderTop={"4px solid var(--primary_brand_color)"}
                        >
                          {content?.tooltip_desc}
                        </Typography>
                      }
                      arrow
                    >
                      <Box
                        component="img"
                        src={content?.tooltip_icon.data.attributes.url}
                        alt={
                          content?.tooltip_icon?.data.attributes.alternativeText
                        }
                        ml={"5px"}
                        sx={{ cursor: "pointer" }}
                      />
                    </CustToolTip_T2>
                  </Typography>
                }
              />
            </Box>
          </Box>

          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            {" "}
            <PaymentButtons
              paymentClicked={props.paymentClicked}
              formik={formik.handleSubmit}
              navigate={props.navigate}
              content={{
                ...content,
                submit_btn:
                  EnvConfig.IS_ESIM_SUPPORTED && alleSimCompatible
                    ? content?.add_card_btn
                    : content?.submit_btn,
              }}
            />
            {/* Payment Methods */}
            <Box className={payment_methods}>
              <Typography className={methods_title}>
                {content?.payment_methods}
              </Typography>
              <Box className={payment_cards}>
                <Box
                  component={"img"}
                  alt={
                    content?.payment_imgs?.data[0]?.attributes?.alternativeText
                  }
                  src={content?.payment_imgs?.data[0]?.attributes?.url}
                />
                <Box
                  component={"img"}
                  alt={
                    content?.payment_imgs?.data[1]?.attributes?.alternativeText
                  }
                  src={content?.payment_imgs?.data[1]?.attributes?.url}
                />
                <Box
                  component={"img"}
                  alt={
                    content?.payment_imgs?.data[2]?.attributes?.alternativeText
                  }
                  src={content?.payment_imgs?.data[2]?.attributes?.url}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default T5_AddressInfo;
