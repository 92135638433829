import styles from "./S1_HowItWorks.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { hiwProps } from "./HowItWorks";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";
export const T1_HowItWorks = (props: hiwProps) => {
  const { title, image_web, image_ipad, image_mobile } = props.content || {};
  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  return (
    <Grid
      container
      className={styles.how_it_works}
      sx={{
        p:{
          xs: "calc(var(--mobile_section_padding)/2) 0",
          sm: "calc(var(--ipad_section_padding)/2) 0",
          md: "calc(var(--desktop_section_padding)/2) 0",
        }
      }}
    >
      <Grid
        item
        xs={12}
        textAlign="center"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h3">{title || ""}</Typography>
        <Box
          className={styles.how_it_works_line}
          sx={{ margin: "20px 0px 25px" }}
        />
      </Grid>
      <Grid item xs={12}>
        {(largeDesktop || desktop) &&
          generateImageContainer({
            data: image_web, attrs: { height: "492px", width: "100%" }
          })}
        {ipad &&
          generateImageContainer(
            {
              data: image_ipad,
              attrs: { height: "1834px", width: "100%" },
              overrides: { sx: { padding: "0px 15px 0px 17px" } }
            }
          )}
        {mobile &&
          generateImageContainer(
            {
              data: image_mobile,
              attrs: { height: "989px", width: "100%" },
              overrides: { sx: { padding: "0px 15px 0px 17px" } }
            }
          )}
      </Grid>
    </Grid>
  );
};

export default T1_HowItWorks;
