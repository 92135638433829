import { useState, useEffect } from "react";
import styles from "./S1_ReviewPlanDetails.module.scss";
import { Typography, Grid, Box } from "@mui/material";
import { OfferCodeCmp } from "../../common";
import { PlanChangeDialog } from "../../PlanSelection";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getAdditionalLineCharges,
  getLineCharges,
  getPlanTaxableAmount,
} from "./BillService";
import firebase from "../../../adapters/firebase";
import {
  getOneTimeShippingTaxCharges,
  getOneTimeSimTaxCharges,
  getPlanTaxCharges,
  resetCoupon,
  updateCouponDetails,
} from "../../../features/checkout/checkoutSlice";
import { addressType, SimTypes } from "../../../features/checkout/Types";
import {
  isInValidAddressInfo,
  isInvalid,
} from "../../../utils/commonFunctions/ReusableFunctions";
import { NotificationCust } from "../../widgets/NotificationCust/NotificationCust";
import EnvConfig from "../../../config/env.config.json";
import { CouponTypes } from "../../../features/checkout/CouponTypes";
import { MIASection } from "./MIASection";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { ChangeSimPreferenceDialog } from "../../ChangeSimPreferenceDialog/ChangeSimPreferenceDialog";

type Props = {
  formik: any;
  shipmentPlans: any;
  content: any;
  simPrefenceContent?: any;
  simPreference: any;
  setSimPreference: any;
};

const T1_ReviewPlan = ({
  formik,
  shipmentPlans,
  content,
  simPrefenceContent,
  simPreference,
  setSimPreference,
}: Props) => {
  const [simCharges, setSimCharges] = useState(0);
  const dispatch = useAppDispatch();
  const {
    planDetails,
    planTaxCharges,
    oneTimeSIMTaxCharges,
    oneTimeShippingTaxCharges,
    errorgetOneTimeSimTaxCharges,
    errorOneTimeShippingTaxCharges,
    coupon,
    errorCoupon,
    errorUpdateCustomerSource,
  } = useAppSelector((state: any) => state.checkout);
  let location: any = new URLSearchParams(document.location.search);
  const [appliedCouponCharges, setAppliedCouponCharges] = useState<number>(0);
  const [showSimChangePrefernceDialog, setShowSimChangePrefernceDialog] =
    useState(false);
  const [eSimKitBasePrice, setESimKitBasePrice] = useState(0);
  const [eSimSecondarySimPrice, setESimSecondarySimPrice] = useState(0);
  const [welcomeKitCost, setWelcomeKitCost] = useState(0);
  useEffect(() => {
    getSIMCharges();
  }, [planDetails.numberOfLines,simPreference]);
  const getShipmentPlanByLabel = (label: string) => {
    return shipmentPlans.find((sPlan: any) => sPlan.value === label);
  };
  const shipmentType =
    getShipmentPlanByLabel(formik.values.shipmentPlan)?.name || "";
  const shipmentCharges = getShipmentPlanByLabel(
    formik.values.shipmentPlan
  )?.shippingAmount;
  let taxableAmount = getPlanTaxableAmount(planDetails);
  let simPreferenceStorage: any = JSON.parse(
    localStorage.getItem("simPreference") || "[]"
  );
  const isEsimSelected =
    simPreferenceStorage.length>0 &&
    simPreferenceStorage.every((data: any) => data.value === SimTypes.eSim);
  const isPhysicalSelected =
    simPreferenceStorage.length>0 &&
    simPreferenceStorage.every((data: any) => data.value === SimTypes.sim);

  const numOfEsims = simPreferenceStorage?.filter(
    (data: any) => data?.eSimCompatible && !data?.isPrimaryLine
  ).length;

  const numOfPsims = simPreferenceStorage?.filter(
    (data: any) => !data?.eSimCompatible && !data?.isPrimaryLine
  ).length;

  const totalSimLines = simPreferenceStorage?.filter(
    (data: any) => !data?.eSimCompatible
  ).length;

  const totalEsimLines = simPreferenceStorage?.filter(
    (data: any) => data?.eSimCompatible
  ).length;
  /**
   * To fetch plan tax charges need billing address and customer fname and lname is mandatory
   */
  useEffect(() => {
    if (appliedCouponCharges >= 0) {
      taxableAmount = taxableAmount - appliedCouponCharges;
    }
    if (
      !isInValidAddressInfo(formik.values.billingAddress) &&
      taxableAmount >= 0
    ) {
      dispatch(
        getPlanTaxCharges({
          address: {
            ...formik.values.billingAddress,
            name: `${formik.values.firstName} ${formik.values.lastName}`,
          },
          reachPlanId: planDetails?.selectedPlan?.name,
          amount: taxableAmount,
        })
      );
    }
  }, [formik.values.billingAddress, planDetails, appliedCouponCharges]);
  useEffect(() => {
    let shippingAddressInfo = formik.values.shippingAddress;
    if (formik.values.isShippingSameAsBilling) {
      shippingAddressInfo = {
        ...formik.values.billingAddress,
        type: addressType.shipping,
      };
    }
    if (
      !isInValidAddressInfo(shippingAddressInfo) &&
      !isInvalid(formik.values.shipmentPlan) &&
      simCharges >= 0 &&
      shipmentCharges >= 0
    ) {
      fetchOneTimeTaxCharges(shippingAddressInfo);
    }
  }, [
    formik.values.shipmentPlan,
    formik.values.isShippingSameAsBilling,
    formik.values.billingAddress,
    formik.values.shippingAddress,
    simCharges,
    shipmentType,
  ]);
  const fetchOneTimeTaxCharges = (address: any) => {
    let shipmentAmountCost = EnvConfig.INSTANT_DELIVERY_VIA_EMAIL_CHARGES;
    if (EnvConfig.IS_ESIM_SUPPORTED && isEsimSelected) {
      // to do shipment charges when all selected sims are eSim type
    }
    dispatch(
      getOneTimeSimTaxCharges({
        address: {
          ...address,
          name: `${formik.values.firstName} ${formik.values.lastName}`,
        },
        amount: simCharges,
      })
    );
    dispatch(
      getOneTimeShippingTaxCharges({
        address: {
          ...address,
          name: `${formik.values.firstName} ${formik.values.lastName}`,
        },
        amount: !isEsimSelected ? shipmentCharges : 0,
      })
    );
  };
  /**
   * Error handling for one-time tax calculation get api failure
   */
  useEffect(() => {
    if (
      (errorgetOneTimeSimTaxCharges &&
        errorgetOneTimeSimTaxCharges.status === "FAILURE") ||
      (errorOneTimeShippingTaxCharges &&
        errorOneTimeShippingTaxCharges.status === "FAILURE")
    ) {
      if (errorgetOneTimeSimTaxCharges.resCode === 100001) {
        NotificationCust({
          message: content?.ot_tax_add_err,
          type: "danger",
          duration: 0,
          id: "addressFailure",
          showIcon: true,
        });
      }
    }
  }, [errorgetOneTimeSimTaxCharges, errorOneTimeShippingTaxCharges]);

  const getSIMCharges = async () => {
    const perCost: any = await firebase.database().ref("/perSimCost");
    let kitBasePrice = 0;
    let secondarySimPrice = 0;
    perCost.on("value", (snapshot: any) => {
      const data = snapshot.val();
      // physical sim cost calculations
      kitBasePrice =
        data &&
        data.USA &&
        ((100 - data.USA.discountPctg) * data.USA.cost) / 100;
      secondarySimPrice = kitBasePrice;
      if (data && data.USA && data.USA.subsequentCost) {
        secondarySimPrice =
          ((100 - data.USA.discountPctg) * data.USA.subsequentCost) / 100;
      }
      let simPref: any = JSON.parse(
        localStorage.getItem("simPreference") || "null"
      );

      let totalCost =
        kitBasePrice + secondarySimPrice * (planDetails.numberOfLines - 1);
      if (EnvConfig.IS_ESIM_SUPPORTED && simPref) {
        let numberOfPhysicalSIMs = simPref.filter((simItem: any) => {
          return simItem.value === SimTypes.sim;
        }).length;
        totalCost =
          kitBasePrice + secondarySimPrice * (numberOfPhysicalSIMs - 1);
      }
      setSimCharges(totalCost);
      // eSim cost calculations
      if (EnvConfig.IS_ESIM_SUPPORTED) {
        let eSimKitBasePrice = 0;
        let eSimSecondarySimPrice = 0;
        eSimKitBasePrice =
          data &&
          data.USA &&
          ((100 - data.USA.eSimDiscountPctg) * data.USA.eSimCost) / 100;
        eSimSecondarySimPrice = eSimKitBasePrice;
        if (data && data.USA && data.USA.eSimSubsequentCost) {
          eSimSecondarySimPrice =
            ((100 - data.USA.eSimDiscountPctg) * data.USA.eSimSubsequentCost) /
            100;
        }
        setESimKitBasePrice(eSimKitBasePrice);
        setESimSecondarySimPrice(eSimSecondarySimPrice);
        setWelcomeKitCost(totalCost);
      }
    });
  };
  useEffect(() => {
    if (coupon) {
      costCalculation(coupon);
    }
  }, [coupon, planDetails]);
  useEffect(() => {
    let couponDetails = localStorage.getItem("couponDetails");
    if (couponDetails && !coupon) {
      couponDetails = JSON.parse(couponDetails);
      dispatch(updateCouponDetails(couponDetails));
    }
  }, []);
  const costCalculation = (data: any = null) => {
    let couponData: any = data ? data : null;
    let coupontype: any = CouponTypes;
    let plan: any = planDetails?.selectedPlan;
    let line: number = planDetails.numberOfLines;
    let basePrice: number = plan && plan.baseLinePrice;
    let additionalPrice: number =
      line > 1 &&
      plan &&
      plan.addLineSplitPrice &&
      plan.addLineSplitPrice[line - 1]
        ? plan.addLineSplitPrice[line - 1] * (line - 1)
        : line > 1 && plan && plan.additionalLinePrice
        ? plan.additionalLinePrice * (line - 1)
        : 0;
    let totalPrice: number = basePrice + additionalPrice;
    let discount: number = 0;
    if (couponData) {
      if (couponData && couponData.expired) {
        NotificationCust({
          message: content?.invalid_coupon_err,
          type: "danger",
          duration: 2000,
          id: "CouponExpired",
          showIcon: true,
        });
      } else {
        localStorage.setItem("couponDetails", JSON.stringify(coupon));
        if (coupontype[couponData && couponData.type]?.type === "PROMO") {
          //promo
          if (couponData && couponData.discountInDollar) {
            if (totalPrice > couponData.discountInDollar) {
              discount = couponData.discountInDollar;
            } else {
              discount = totalPrice;
            }
          }
          if (couponData && couponData.planDiscount) {
            discount += (couponData.planDiscount * basePrice) / 100;
            if (couponData && couponData.secondaryDiscount) {
              discount +=
                (couponData.secondaryDiscount * additionalPrice) / 100;
            }
            if (
              couponData.maxDiscountInDollar &&
              discount > couponData.maxDiscountInDollar
            ) {
              discount = couponData.maxDiscountInDollar;
            }
          }
        } else if (
          coupontype[couponData && couponData.type]?.type === "REFERRAL"
        ) {
          //referal
          let coupon1: any = { couponCode: couponData.coupon, type: 3 };
          let coupon2: any = { couponCode: couponData.coupon };

          localStorage.setItem("reachPurhcaseReferralUserCoupon", coupon1);
          localStorage.setItem("reachPurhcaseUserCoupon", coupon2);
          if (couponData && couponData.refereeDiscInDollar) {
            discount = couponData.refereeDiscInDollar;
          } else {
            if (couponData && couponData.refereeDiscount) {
              discount = (couponData.refereeDiscount * basePrice) / 100;
            }
            // if (couponData && couponData.maxDiscountInDollar) {
            //   discount = Math.min(couponData.maxDiscountInDollar, discount);
            // }
            if (
              couponData.maxDiscountInDollar &&
              discount > couponData.maxDiscountInDollar
            ) {
              discount = couponData.maxDiscountInDollar;
            }
          }
        } else {
          if (couponData && couponData?.planDiscount) {
            discount += (couponData.planDiscount * basePrice) / 100;
            if (couponData && couponData.secondaryDiscount) {
              discount +=
                (couponData.secondaryDiscount * additionalPrice) / 100;
            }
          }
        }
      }
    }
    setAppliedCouponCharges(discount);
    return {
      discountAmount: discount,
      totalCost: totalPrice,
      basePrice: basePrice,
      additionalPrice: additionalPrice,
      totalWithDiscount: totalPrice - discount,
    };
  };
  /**
   * Error notifications handlers
   */
  useEffect(() => {
    if (errorCoupon) {
      NotificationCust({
        message: errorCoupon,
        type: "danger",
        duration: 2000,
        id: "CouponFailureNotifier",
        showIcon: true,
      });
    }
  }, [errorCoupon]);
  useEffect(() => {
    if (location?.message && location?.code) {
      NotificationCust({
        message: location?.message,
        type: "danger",
        duration: 2000,
        id: "PaymentGatewayFailure",
        showIcon: true,
      });
    }
  }, [location]);
  useEffect(() => {
    if (errorUpdateCustomerSource) {
      NotificationCust({
        message: content.user_upd_err,
        type: "danger",
        duration: 2000,
        id: "UserUpdateFailure",
        showIcon: true,
      });
    }
  }, [errorUpdateCustomerSource]);
  const lineCharges = getLineCharges(planDetails);
  const additionalLineCharges = getAdditionalLineCharges(planDetails);
  const planChargesTaxAmount = planTaxCharges || 0;
  const estimatedMonthlyBillAmount =
    taxableAmount + planChargesTaxAmount - appliedCouponCharges;
  let eSimCostPerLine: number = 0;
  if (EnvConfig.IS_ESIM_SUPPORTED) {
    eSimCostPerLine =
      eSimKitBasePrice + eSimSecondarySimPrice * (totalEsimLines - 1);
  }
  let totalDueAmount: number = 0;
  const oneTimeTotalTaxChargesAmount: number =
    (oneTimeSIMTaxCharges?.totalTax || 0) +
    (oneTimeShippingTaxCharges?.totalTax || 0);
  if (EnvConfig.IS_ESIM_SUPPORTED) {
    totalDueAmount =
      !isEsimSelected && isPhysicalSelected
        ? parseFloat(
            (
              oneTimeTotalTaxChargesAmount +
              welcomeKitCost +
              shipmentCharges
            ).toFixed(2)
          )
        : isEsimSelected && !isPhysicalSelected
        ? parseFloat(
            (oneTimeTotalTaxChargesAmount + eSimCostPerLine).toFixed(2)
          )
        : parseFloat(
            (
              eSimCostPerLine +
              oneTimeTotalTaxChargesAmount +
              welcomeKitCost +
              shipmentCharges
            ).toFixed(2)
          );
  } else {
    totalDueAmount =
      simCharges + shipmentCharges + oneTimeTotalTaxChargesAmount;
  }
  const handleCouponRemove = () => {
    setAppliedCouponCharges(0);
    localStorage.removeItem("couponDetails");
    dispatch(resetCoupon());
  };
  const personIcon = content?.person_ico?.data?.attributes?.url;
  const personIcon_alt = content?.person_ico?.data?.attributes?.alternativeText;
  const giftBoxIcon = content?.gift_box_ico?.data?.attributes?.url;
  const giftBoxIcon_alt =
    content?.gift_box_ico?.data?.attributes?.alternativeText;
  const openSimChangePrefernceDialog = () => {
    setShowSimChangePrefernceDialog(true);
  };
  const onCloseSimChangePrefernceDialog = () => {
    setShowSimChangePrefernceDialog(false);
  };
  const getPrimaryUserSimType = () => {
    return simPreferenceStorage[0]?.eSimCompatible
      ? `(${getLabel(simPrefenceContent?.esim_txt)})`
      : `(${getLabel(simPrefenceContent?.sim_txt)})`;
  };
  const getLabel = (text: string, value: number = 0) => {
    return text + (value > 1 ? "'s" : "");
  };
  // To show banner at offer info
  // const textBannerImg = "https://strapi-config-images.s3.amazonaws.com/singleWebsite-dev/rectangle_d43e16f69d.webp"// content?.text_banner?.data?.attributes?.url;
  return (
    <Grid
      container
      className={styles.Review_plan}
      sx={{ p: { sm: "30px 15px 20px", xs: "25px 0" } }}
    >
      <Grid item xs={12}>
        <Typography
          variant="body1"
          component="p"
          sx={{
            mb: { md: "14px", sm: "15px", xs: "10px" },
            px: { xs: 0, md: "40px" },
          }}
          className={styles.review_header}
        >
          {content?.title}
        </Typography>
      </Grid>
      <Grid
        item
        container
        display="flex"
        sx={{
          justifyContent: { sm: "space-between", xs: "initial", ms: "initial" },
          px: { xs: 0, md: "40px" },
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={12}
          sx={{ pr: { sm: "13px", md: "0px", xs: "0px" } }}
        >
          <Grid item xs={12}>
            <Typography
              variant="body1"
              component="p"
              sx={{ mb: { md: "12px", xs: "10px" } }}
              className={styles.review_sub_header}
            >
              {content?.r_sub_h1}
            </Typography>
            <Grid
              item
              container
              xs={12}
              className={styles.change_plan_container}
              sx={{ mb: { md: "9px", sm: "27px", xs: "22px" } }}
            >
              <PlanChangeDialog
                content={content}
                simPrefenceContent={simPrefenceContent}
                showSimChangePrefernceDialog={showSimChangePrefernceDialog}
                onCloseSimChangePrefernceDialog={
                  onCloseSimChangePrefernceDialog
                }
                simPreference={simPreference}
                setSimPreference={setSimPreference}
                simCharges={simCharges + shipmentCharges}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            textAlign="left"
            sx={{ mb: { md: "15px", xs: "20px" } }}
          >
            {EnvConfig.IS_BONUS_DATA &&
            !planDetails?.selectedPlan?.isUnlimited ? (
              <>
                {planDetails.numberOfLines <= 2 ? (
                  <Typography
                    variant="body1"
                    component="p"
                    dangerouslySetInnerHTML={{ __html: content?.promo_txt1 }}
                    className={styles.offer_details}
                  ></Typography>
                ) : (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box>
                      <img
                        src={giftBoxIcon}
                        alt={giftBoxIcon_alt}
                        width="42"
                        height="30"
                      />
                    </Box>
                    <Typography
                      variant="body1"
                      component="p"
                      className={styles.offer_details}
                      dangerouslySetInnerHTML={{
                        __html: content?.promo_txt2,
                      }}
                    ></Typography>
                  </Box>
                )}{" "}
              </>
            ) : null}
          </Grid>
          {EnvConfig.IS_ESIM_SUPPORTED ? (
            <Grid item container xs={12}>
              <Grid
                item
                xs={8}
                textAlign="left"
                className={styles.change_line_lable}
              >
                {simPrefenceContent?.line_pre}
              </Grid>
              <Grid
                item
                xs={4}
                className={styles.change_pre_link}
                onClick={openSimChangePrefernceDialog}
              >
                {simPrefenceContent?.change_pre}
              </Grid>
            </Grid>
          ) : null}
          <Grid item container xs={12} className={styles.your_line}>
            <Grid item xs={8} textAlign="left">
              {content?.due_l1}
              {EnvConfig.IS_ESIM_SUPPORTED ? (
                <span className={styles.primary_line_type}>
                  &nbsp;{getPrimaryUserSimType()}
                </span>
              ) : null}
            </Grid>
            <Grid item xs={4} textAlign="right">
              {GetCurrencyFixedToTwo(lineCharges)}
            </Grid>
          </Grid>
          {additionalLineCharges !== 0 ? (
            <Grid item container xs={12} className={styles.additional_lines}>
              <Grid item xs={8} textAlign="left">
                {content?.due_l2}({planDetails.numberOfLines - 1}){" "}
                {EnvConfig.IS_ESIM_SUPPORTED ? (
                  <span className={styles.primary_line_type}>
                    (
                    {numOfEsims > 0 &&
                      numOfEsims +
                        getLabel(simPrefenceContent?.esim_txt, numOfEsims)}
                    {numOfEsims > 0 && numOfPsims > 0 && "+"}
                    {numOfPsims > 0 &&
                      numOfPsims +
                        getLabel(simPrefenceContent?.sim_txt, numOfPsims)}
                    )
                  </span>
                ) : null}
              </Grid>
              <Grid item xs={4} textAlign="right">
                {GetCurrencyFixedToTwo(additionalLineCharges)}
              </Grid>
            </Grid>
          ) : null}
          <Grid item container xs={12} className={styles.tax_and_fee}>
            <Grid item xs={8} textAlign="left">
              {content?.due_l3}
            </Grid>
            <Grid item xs={4} textAlign="right">
              {GetCurrencyFixedToTwo(planChargesTaxAmount) || "-"}
            </Grid>
          </Grid>
          {coupon && appliedCouponCharges && appliedCouponCharges > 0 ? (
            <Grid item container xs={12} className={styles.tax_and_fee}>
              <Grid
                item
                xs={8}
                textAlign="left"
                style={{ color: "var(--primary_brand_color)" }}
              >
                {CouponTypes[coupon?.type].message}
              </Grid>
              <Grid
                item
                xs={4}
                textAlign="right"
                style={{ color: "var(--primary_brand_color)" }}
              >
                {appliedCouponCharges !== 0
                  ? `-${GetCurrencyFixedToTwo(appliedCouponCharges)}`
                  : "-"}
              </Grid>
            </Grid>
          ) : null}
          <Grid
            item
            container
            xs={12}
            className={styles.estimated_bill}
            sx={{ mb: { md: "10px", sm: "11px", xs: "16px" } }}
          >
            <Grid item xs={8} textAlign="left">
              {content?.due_l4}
            </Grid>
            <Grid item xs={4} textAlign="right">
              {GetCurrencyFixedToTwo(estimatedMonthlyBillAmount)}
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={styles.charge_per_person}
            sx={{ mb: { md: "14px", xs: "15px" } }}
          >
            <Box display="flex" justifyContent="left" alignItems="center">
              <Box
                component={"img"}
                alt={personIcon_alt}
                width="20px"
                height="20px"
                sx={{ mr: "11px" }}
                src={personIcon}
              />
              <Box>
                {content?.p_p1} $
                {Math.ceil(
                  estimatedMonthlyBillAmount / planDetails.numberOfLines
                )}{" "}
                {content?.p_p2}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            className={styles.offer_code_section}
            sx={{ mt: "14px" }}
          >
            <OfferCodeCmp
              content={content}
              removeCouponCallback={handleCouponRemove}
              appliedCouponAmount={appliedCouponCharges}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={12}
          sx={{ pl: { sm: "13px", md: "0px", xs: "0px" } }}
        >
          <Typography
            variant="body2"
            component="p"
            sx={{ my: "15px" }}
            className={styles.one_time_charges}
          >
            {content?.r_sub_h2}
          </Typography>
          {EnvConfig.IS_ESIM_SUPPORTED &&
          (totalEsimLines > 1 ||
            (totalEsimLines >= 1 && totalSimLines >= 1)) ? (
            <Grid item container xs={12} className={styles.welcome_kit_charge}>
              <Grid item xs={9.5} textAlign="left">
                {simPrefenceContent?.kit_sent_bill_txt} ({totalEsimLines})
              </Grid>
              <Grid item xs={2.5} textAlign="right">
                {GetCurrencyFixedToTwo(eSimCostPerLine)}
              </Grid>
            </Grid>
          ) : null}
          {EnvConfig.IS_ESIM_SUPPORTED &&
          totalEsimLines === 1 &&
          totalSimLines < 1 ? (
            <Grid item container xs={12} className={styles.welcome_kit_charge}>
              <Grid item xs={9.5} textAlign="left">
                {simPrefenceContent?.digital_kit}
              </Grid>
              <Grid item xs={2.5} textAlign="right">
                {GetCurrencyFixedToTwo(eSimCostPerLine)}
              </Grid>
            </Grid>
          ) : null}
          {EnvConfig.IS_ESIM_SUPPORTED && totalSimLines === 0 ? null : (
            <Grid item container xs={12} className={styles.welcome_kit_charge}>
              <Grid item xs={9.5} textAlign="left">
                {content?.otc_l1} (
                {EnvConfig.IS_ESIM_SUPPORTED
                  ? totalSimLines
                  : planDetails.numberOfLines}
                )
              </Grid>
              <Grid item xs={2.5} textAlign="right">
                {GetCurrencyFixedToTwo(simCharges)}
              </Grid>
            </Grid>
          )}
          <Grid item container xs={12} className={styles.shipping_charge}>
            <Grid item xs={9.5} textAlign="left">
              {EnvConfig.IS_ESIM_SUPPORTED && isEsimSelected
                ? simPrefenceContent?.instant_del
                : shipmentType}
            </Grid>
            <Grid item xs={2.5} textAlign="right">
              {EnvConfig.IS_ESIM_SUPPORTED && isEsimSelected
                ? GetCurrencyFixedToTwo(
                    EnvConfig.INSTANT_DELIVERY_VIA_EMAIL_CHARGES
                  )
                : shipmentCharges
                ? GetCurrencyFixedToTwo(shipmentCharges)
                : "-"}
            </Grid>
          </Grid>
          <Grid item container xs={12} className={styles.tax_and_fee}>
            <Grid item xs={9.5} textAlign="left">
              {content?.otc_l2}
            </Grid>
            <Grid item xs={2.5} textAlign="right">
              {oneTimeSIMTaxCharges?.totalTax >= 0 &&
              oneTimeShippingTaxCharges?.totalTax >= 0
                ? GetCurrencyFixedToTwo(oneTimeTotalTaxChargesAmount)
                : "-"}
            </Grid>
          </Grid>
          <Grid item container xs={12} className={styles.total_due}>
            <Grid item xs={8} textAlign="left">
              {content?.otc_l3}
            </Grid>
            <Grid item xs={4} textAlign="right">
              {totalDueAmount >= 0
                ? GetCurrencyFixedToTwo(totalDueAmount)
                : "-"}
            </Grid>
          </Grid>
          {content?.otc_note_txt && (
            <Typography
              variant="body2"
              component="p"
              className={styles.note_header}
              sx={{ my: "10px" }}
            >
              {content?.otc_note_txt}
            </Typography>
          )}
          {content?.otc_note_desc && (
            <Typography
              variant="body2"
              component="p"
              className={styles.note_description}
              sx={{ mb: "5px" }}
            >
              {content?.otc_note_desc}
            </Typography>
          )}
        </Grid>
        {EnvConfig.IS_MIA_CHECKOUT && (
          <MIASection
            content={content}
            totalDueAmount={totalDueAmount}
            estimatedMonthlyBillAmount={estimatedMonthlyBillAmount}
          />
        )}
      </Grid>
      {/* <ChangeSimPreferenceDialog
        open={showSimChangePrefernceDialog}
        onClose={onCloseSimChangePrefernceDialog}
        content={simPrefenceContent}
      /> */}
    </Grid>
  );
};
export default T1_ReviewPlan;
