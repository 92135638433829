import { useState, useEffect, Fragment } from "react";
import { Typography, Box, Link, LinearProgress, Skeleton } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import { convertDataFromBytes } from "../../utils/commonFunctions/ConvertDataFromBytes";
import { format } from "date-fns";
import { RoutingURLs } from "../../config/RoutingURLs";
import "./DataUsageInfo.scss";
import { useAppSelector } from "../../app/hooks";
import { SecondaryLinesDataUsage } from "./SecondaryLinesDataUsage";
import { CustomerStatus } from "../../enums";
import { NotificationCust } from "../widgets";

interface IDataUsage {
  dataUsage: any;
  startDate: Date | number;
  endDate: Date | number;
  handleBillCycleChange?: any;
  selectedDateKeyIndex: number;
  totalNumberOfKeys: number;
  content: any;
  fetchDataUsageDetails: any;
  showSecondaryLinesUsage: boolean;
  showDataUsageForUnlimitedPack: boolean;
  callBackGetCustomerGroup: any;
  handleBuyData?: any;
  showDataExceedingNotification?: boolean;
  loading?: boolean;
  setLoadingData?: any;
  isCardExpired: any;
}
export const DataUsageInfo = (props: IDataUsage) => {
  const {
    dataUsage,
    startDate,
    endDate,
    handleBillCycleChange,
    selectedDateKeyIndex,
    totalNumberOfKeys,
    content,
    fetchDataUsageDetails,
    showSecondaryLinesUsage,
    showDataUsageForUnlimitedPack,
    callBackGetCustomerGroup,
    handleBuyData,
    showDataExceedingNotification,
    loading,
    setLoadingData,
    isCardExpired,
  } = props;
  const { group } = useAppSelector((state: any) => state.account);
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const [groupData, setGroupData] = useState<Array<any>>([]);
  const [primaryCustomer, setPrimaryCustomer] = useState();
  const currentPath = window.location.href;

  const getDataUsageValue = (usageData: any, totalPlanData: any) => {
    let usageDataValue = parseInt(usageData, 10);
    let totalPlanDataValue = parseInt(totalPlanData, 10);

    if (usageDataValue >= totalPlanDataValue) {
      return totalPlanData;
    } else {
      return usageData;
    }
  };
  useEffect(() => {
    if (group?.data && group?.data.length > 0) {
      let sortedGroupUsersByDate = group?.data
        ?.slice()
        .sort((a: any, b: any) => {
          return (
            new Date(a.createdDate).getTime() -
            new Date(b.createdDate).getTime()
          );
        });
      setGroupData(sortedGroupUsersByDate);
      const primaryUser = sortedGroupUsersByDate.find(
        (user: any) => user.isPrimary === true
      );
      setPrimaryCustomer(primaryUser);
    }
  }, [group]);
  const isEligebleUser = (status: number) => {
    return [
      CustomerStatus.ACTIVE,
      CustomerStatus.SUSPENDED,
      CustomerStatus.DISCONNECTED,
      CustomerStatus.HOTLINED,
      CustomerStatus.REJECTED,
      CustomerStatus.DISABLED,
    ].includes(status);
  };
  let usageData = 0;
  let totalPlanData = 0;
  const isSharablePlan = dataUsage?.group;
  if (isSharablePlan) {
    usageData = dataUsage?.group?.bytes;
    totalPlanData = dataUsage?.group?.maxBytes;
  } else {
    /**
     * For unlimited pack, if atleast one user consumes more then 90% data usage then considering that user for showing data usage alert
     */
    const nearCrossingCustomerDataUsage = dataUsage?.users.find(
      (user: any) => ((user.bytes / user.maxBytes) * 100 || 0) >= 90
    );
    usageData = nearCrossingCustomerDataUsage?.bytes || 0;
    totalPlanData = nearCrossingCustomerDataUsage?.maxBytes || 0;
  }
  const getDataUsagePercentage = () => {
    if (usageData <= totalPlanData) {
      return (usageData / totalPlanData) * 100 || 0;
    } else {
      return 100 || 0;
    }
  };
  useEffect(() => {
    if (
      getDataUsagePercentage() >= 90 &&
      getDataUsagePercentage() < 100 &&
      showDataExceedingNotification &&
      content?.d_u_n_e
    ) {
      NotificationCust({
        message: content?.d_u_n_e,
        type: "info",
        id: "dataUsageNotification",
        showIcon: true,
      });
    }
  }, [usageData]);
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      className="data_usage_wrapper"
    >
      <Typography className="data_usage_label">{content?.data_usg}</Typography>
      {dataUsage ? (
        <>
          <Box className="bill_cycle_label">
            {currentPath.includes(RoutingURLs.account) &&
            totalNumberOfKeys > 0 &&
            selectedDateKeyIndex > 0 ? (
              <Box
                style={{ cursor: "pointer" }}
                onClick={() => handleBillCycleChange("decrement")}
              >
                &lt;
              </Box>
            ) : null}
            &nbsp;
            {startDate && endDate
              ? `${format(startDate, "MMM dd, yyyy")} - ${format(
                  endDate,
                  "MMM dd, yyyy"
                )}`
              : null}
            &nbsp;
            {totalNumberOfKeys > 0 &&
            selectedDateKeyIndex !== totalNumberOfKeys - 1 ? (
              <Box
                style={{ cursor: "pointer" }}
                onClick={() => handleBillCycleChange("increment")}
              >
                &gt;
              </Box>
            ) : null}
          </Box>
          {(isSharablePlan || showDataUsageForUnlimitedPack) && dataUsage && (
            <>
              <Box className="data_usage_txt">
                <Typography
                  component="p"
                  className="data_usage_txt"
                  color={"var(--primary_brand_color)"}
                >
                  {getDataUsageValue(
                    convertDataFromBytes(usageData),
                    convertDataFromBytes(totalPlanData)
                  )}
                </Typography>
                <Typography component="p" className="data_usage_txt">
                  &nbsp;{content?.of}&nbsp;
                </Typography>
                <Typography component="p" className="data_usage_txt">
                  {convertDataFromBytes(totalPlanData)}
                </Typography>
                {selectedDateKeyIndex === totalNumberOfKeys - 1 && (
                  <Link
                    display="flex"
                    alignItems="center"
                    justifyItems="center"
                  >
                    {loading ? (
                      <>
                        <img
                          src={content.loader.data.attributes.url}
                          alt="img"
                          className="load_icon_gif"
                        />
                      </>
                    ) : (
                      <CachedIcon
                        className="load_icon"
                        onClick={() => {
                          setLoadingData(true);
                          fetchDataUsageDetails();
                        }}
                        style={{ cursor: "pointer" }}
                        fontSize="large"
                      />
                    )}
                  </Link>
                )}
              </Box>
              <Box
                className="progress_bar"
                width={"500px"}
                sx={{
                  maxWidth: {
                    xs: "90%",
                    md: "100%",
                  },
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={getDataUsagePercentage()}
                  sx={{
                    mt: "8px",
                    m: "10px auto 15px",
                    height: "11px",
                    width: { xs: "60%", sm: "100%" },
                    borderRadius: "5.5px",
                    backgroundColor: "hsla(0,0%,6.8%,.10196078431372549)",
                  }}
                />
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <Skeleton variant="rounded" width={210} />
          <Skeleton animation="wave" width="100%" />
          <Skeleton animation="wave" width="100%" />
        </>
      )}
      {showSecondaryLinesUsage &&
        groupData &&
        dataUsage?.users.length > 0 &&
        groupData.map((groupUser: any) => {
          const customer = dataUsage?.users.find(
            (dataUsageUser: any) => dataUsageUser.userId === groupUser.id
          );
          if (customer && isEligebleUser(groupUser.status)) {
            return (
              <Fragment key={groupUser.id}>
                <SecondaryLinesDataUsage
                  isCardExpired={isCardExpired}
                  user={customer}
                  groupUser={groupUser}
                  isSharablePlan={isSharablePlan}
                  isCurrentDataUsage={
                    selectedDateKeyIndex === totalNumberOfKeys - 1
                  }
                  primaryCustomer={primaryCustomer}
                  callBackGetCustomerGroup={callBackGetCustomerGroup}
                  content={content}
                  handleBuyData={handleBuyData}
                />
              </Fragment>
            );
          }
        })}
    </Box>
  );
};
