import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import EnvConfig from "../../../config/env.config.json";
import { serviceCheckContent } from "../../../features/strapi/homePageSlice";
import {
  checkCoverage,
  setCustomerAddress,
} from "../../../features/homepage/homepageSlice";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { scroller } from "react-scroll";
import { geocodeByAddress } from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { toTitleCase } from "../../../utils/commonFunctions";
import TagManager from "react-gtm-module";

let CheckCoverageComponent1: any = isTemplateExisits(Config.Home?.ServiceCheck)
  ? require(`./${Config.Home.ServiceCheck.template}_ServiceCheck`).default
  : null;

let CheckCoverageComponent2: any = isTemplateExisits(Config.Plans?.ServiceCheck)
  ? require(`./${Config.Plans.ServiceCheck.template}_ServiceCheck`).default
  : null;

export type ServiceCheckProps = {
  checkAddressHandler?: any;
  handleSelect?: any;
  checkCoverageHandler?: any;
  coverageContent?: any;
  checkAddress?: any;
  coverageLoading?: any;
  coverageAddress?: any;
  signal4G?: any;
  signal5G?: any;
  coverageError?: any;
  navigateCheckCoverage?: any;
  coverageModal?: any;
  checkDisableHandler?: any;
  signalModal?: any;
  checkCoverageHome?: any;
  openCoverageModal?: any;
  closeModalHandler?: any;
  searchAgainHandler?: any;
  exploreHandler?: any;
  getStarted?: any;
  showCoverage?: any;
  setShowCoverage?: any;
  setCoverageError?: any;
  coverageResponse?: any;
  setCoverageResponse?: any;
};

export const ServiceCheck = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [cacheDataCoverage, setCacheDataCoverage] = useState<any>(null);
  const { coverageStatus, coverageLoading, errorCoverageStatus } =
    useAppSelector((state: any) => state.homepage);
  const { networkCoverageContent } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  const [showCoverage, setShowCoverage] = useState(false);
  const [coverageError, setCoverageError] = useState(false);
  const [checkAddress, setCheckAddress] = useState("");
  const coverageAddressObj = {
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    homesPassedId: "",
    fta_code: "",
    oc_code: "",
  };
  const [coverageAddress, setCoverageAddress] = useState(coverageAddressObj);
  const [signal4G, setSignal4G] = useState("");
  const [signal5G, setSignal5G] = useState("");
  const [coverageModal, setCoverageModal] = useState(false);
  const [checkCoverageHome, setCheckCoverageHome] = useState(false);
  const [signalModal, setSignalModal] = useState(false);
  const [coverageResponse, setCoverageResponse] = useState(null);
  const [serviceable, setServiceable] = useState(false);

  useEffect(() => {
    getCache(
      `${
        Config.Plans.ServiceCheck.template
          ? "CheckServicePlan"
          : "CheckServiceHome"
      }  `,
      serviceCheckContent,
      setCacheDataCoverage,
      dispatch
    );
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("CheckServiceHome", networkCoverageContent);
  }, [networkCoverageContent]);

  useNonInitialEffect(() => {
    if (!isEmpty(coverageStatus)) {
      EnvConfig.IS_GA_EVENTS_REQUIRED &&
        GAAndFBEventsHandler(
          gaCategory.networkCoverage,
          "check",
          "coverage_check_success",
          {
            service_address: checkAddress,
          }
        );
      TagManager.dataLayer({
        dataLayer: {
          event: "coverage_check_success",
          service_address: checkAddress,
        },
      });
      if (EnvConfig.BROADBAND && coverageStatus?.data?.data?.isValid) {
        if (checkAddress !== "") {
          if (coverageStatus) {
            setCoverageResponse(coverageStatus);
          }
          if (location.pathname === "/home" || location.pathname === "/") {
            dispatch(setCustomerAddress(checkAddress));
            localStorage.setItem("CustomerAddress", checkAddress);
            localStorage.setItem(
              "CustomerAddressObj",
              JSON.stringify(coverageAddress)
            );
          }

          navigate("/data-plans");
        }
      } else {
        setCheckAddress("");
        setCoverageAddress(coverageAddressObj);
        setSignal4G(coverageStatus?.data?.data?.signal4g);
        setSignal5G(coverageStatus?.data?.data?.signal5g);
        setShowCoverage(true);
        setCoverageModal(false);
        setSignalModal(true);
        setCoverageError(true);
      }
    }
  }, [coverageStatus]);

  useEffect(() => {
    if (!isEmpty(errorCoverageStatus)) {
      EnvConfig.IS_GA_EVENTS_REQUIRED &&
        GAAndFBEventsHandler(
          gaCategory.networkCoverage,
          "",
          "coverage_check_failed",
          {
            service_address: checkAddress,
          }
        );
      TagManager.dataLayer({
        dataLayer: {
          event: "coverage_check_failed",
          service_address: checkAddress,
        },
      });
      setCheckAddress("");
      setCoverageError(true);
      setSignal4G("");
      setSignal5G("");
    }
  }, [errorCoverageStatus]);

  const constructAddress2 = (data: any) => {
    let address2 = "";
    if (data?.apt_des) address2 = address2 + toTitleCase(data?.apt_des) + " ";
    if (data?.apt_unit) address2 = address2 + data?.apt_unit;
    return address2;
  };

  const checkAddressHandler = (e: any) => {
    let coverageAddressNew = {
      address1:
        e?.street_number +
        " " +
        e?.prefix_direction +
        " " +
        toTitleCase(e?.street_name) +
        (e?.suffix_direction ? " " + e?.suffix_direction : ""),
      address2: constructAddress2(e),
      city: toTitleCase(e?.city),
      country: "USA",
      state: e?.state_abbreviation,
      zip: e?.zip?.substring(0, 5),
      homesPassedId: e?.homes_passed_id,
      fta_code: e?.fta_code,
      oc_code: e?.oc_code,
    };
    setServiceable(e?.serviceable);
    const { address1, address2, city, country, state, zip } =
      coverageAddressNew;
    setCheckAddress(
      `${address1}, ${`${
        address2 !== "" ? address2 + ", " : ""
      }`}${city}, ${state}, ${country}, ${zip}`
    );
    setCoverageError(false);
    setCoverageAddress(coverageAddressNew);
  };

  const handleSelect = (address: any) => {
    geocodeByAddress(address)
      .then(async (results: any) => {
        let city: string = "",
          state: string = "",
          zip: string = "",
          country: string = "",
          route: string = "",
          streetNumber: string = "",
          homesPassedId = "";
        results &&
          results[0] &&
          results[0].address_components &&
          results[0].address_components.map((el: any) => {
            state = el.types.includes("administrative_area_level_1")
              ? el.short_name
              : state;
            zip = el.types.includes("postal_code") ? el.short_name : zip;
            city = el.types.includes("locality")
              ? el.long_name
              : el.types.includes("sublocality")
              ? el.long_name
              : city;
            streetNumber = el.types.includes("street_number")
              ? el.short_name
              : streetNumber;
            route = el.types.includes("route") ? el.long_name : route;
            country = el.types.includes("country") ? el.short_name : country;
            return null;
          });
        if (streetNumber && route) {
          setCoverageAddress((prevState) => ({
            ...prevState,
            address1: streetNumber + " " + route,
            address2: "",
            city,
            state,
            country,
            zip,
            homesPassedId: "",
            fta_code: "",
            oc_code: "",
          }));
          setCheckAddress(
            streetNumber +
              " " +
              route +
              ", " +
              city +
              ", " +
              state +
              ", " +
              country +
              ", " +
              zip
          );
          setCoverageError(false);
        } else {
          setCoverageAddress((prevState) => ({
            ...prevState,
            address1: "",
            address2: "",
            city,
            state,
            country,
            zip,
            homesPassedId,
            fta_code: "",
            oc_code: "",
          }));
          setCheckAddress(city + ", " + state + ", " + country + ", " + zip);
          EnvConfig.IS_GA_EVENTS_REQUIRED &&
            GAAndFBEventsHandler(
              gaCategory.networkCoverage,
              "",
              "coverage_check_failed"
            );
          TagManager.dataLayer({
            dataLayer: {
              event: "coverage_check_failed",
              service_address: checkAddress,
            },
          });
          setCoverageError(true);
        }
      })
      .catch((error) => console.error("error", error));
  };

  const getStarted = (_: any) => {
    scroller.scrollTo("bestCellPhonePlan", {
      duration: 1000,
      delay: 0,
      offset: -100,
      smooth: true,
    });
  };
  const GAAndFBEventsHandler = (
    categoryName: string,
    value?: string,
    custEventName?: string,
    extraParams?: any
  ) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.home,
      category: categoryName,
      ...(value && { buttonValue: value }),
      ...(custEventName && { custEventName: custEventName }),
      ...(extraParams && { additionalParams: extraParams }),
    });
  };

  const checkCoverageHandler = (address: any) => {
    setCoverageError(false);
    setSignal4G("");
    setSignal5G("");
    if (serviceable) dispatch(checkCoverage(address));
    else {
      TagManager.dataLayer({
        dataLayer: {
          event: "coverage_check_failed",
          service_address: checkAddress,
        },
      });
      EnvConfig.IS_GA_EVENTS_REQUIRED &&
        GAAndFBEventsHandler(
          gaCategory.networkCoverage,
          "",
          "coverage_check_failed"
        );
      setCoverageError(true);
    }
    TagManager.dataLayer({
      dataLayer: {
        event: "coverage_check",
      },
    });
    EnvConfig.IS_GA_EVENTS_REQUIRED &&
      GAAndFBEventsHandler(
        gaCategory.networkCoverage,
        networkCoverageContent?.button_1,
        "coverage_check"
      );
  };

  const navigateCheckCoverage = () => {
    setShowCoverage(false);
    setSignalModal(false);
    setSignal4G("");
    setSignal5G("");
    navigate("/coverage-check");
  };
  const checkDisableHandler = () => {
    return (
      checkAddress === "" &&
      (!coverageAddress.address1 ||
        !coverageAddress.address2 ||
        !coverageAddress.city ||
        !coverageAddress.state ||
        !coverageAddress.country ||
        !coverageAddress.zip ||
        coverageLoading)
    );
  };

  const openCoverageModal = () => {
    EnvConfig.IS_GA_EVENTS_REQUIRED &&
      GAAndFBEventsHandler(
        gaCategory.checkCoverage,
        networkCoverageContent?.button_1
      );
    setCoverageModal(true);
    setCheckCoverageHome(true);
    setCoverageError(false);
  };

  const closeModalHandler = () => {
    setCoverageModal(false);
    setCoverageError(false);
    setCheckAddress("");
    setSignalModal(false);
    setSignal4G("");
    setSignal5G("");
  };

  const searchAgainHandler = () => {
    setSignalModal(false);
    setShowCoverage(false);
    setCoverageModal(signal4G !== "no service" || signal5G !== "no service");
    setSignal4G("");
    setSignal5G("");
    setCoverageError(false);
    setCoverageAddress(coverageAddressObj);
    setCheckAddress("");
  };

  const exploreHandler = () => {
    setSignalModal(false);
    setCoverageModal(signal4G === "no service" || signal5G === "no service");
    setSignal4G("");
    setSignal5G("");
    setCoverageError(false);
    setCoverageAddress(coverageAddressObj);
    setCheckAddress("");
    if (signal4G !== "no service" || signal5G !== "no service") {
      scroller.scrollTo("bestCellPhonePlan", {
        duration: 1000,
        delay: 0,
        offset: -100,
        smooth: true,
      });
    }
  };
  return EnvConfig.BROADBAND && location.pathname === "/data-plans" ? (
    <CheckCoverageComponent2
      checkAddressHandler={checkAddressHandler}
      handleSelect={handleSelect}
      getStarted={getStarted}
      checkCoverageHandler={checkCoverageHandler}
      openCoverageModal={openCoverageModal}
      closeModalHandler={closeModalHandler}
      searchAgainHandler={searchAgainHandler}
      exploreHandler={exploreHandler}
      navigateCheckCoverage={navigateCheckCoverage}
      checkDisableHandler={checkDisableHandler}
      setShowCoverage={setShowCoverage}
      setCoverageError={setCoverageError}
      setCoverageResponse={setCoverageResponse}
      coverageContent={
        cacheDataCoverage ? cacheDataCoverage : networkCoverageContent
      }
      coverageError={coverageError}
      showCoverage={showCoverage}
      checkAddress={checkAddress}
      signal4G={signal4G}
      signal5G={signal5G}
      coverageLoading={coverageLoading}
      coverageAddress={coverageAddress}
      coverageModal={coverageModal}
      signalModal={signalModal}
      checkCoverageHome={checkCoverageHome}
      coverageResponse={coverageResponse}
    />
  ) : (
    <CheckCoverageComponent1
      checkAddressHandler={checkAddressHandler}
      handleSelect={handleSelect}
      getStarted={getStarted}
      checkCoverageHandler={checkCoverageHandler}
      openCoverageModal={openCoverageModal}
      closeModalHandler={closeModalHandler}
      searchAgainHandler={searchAgainHandler}
      exploreHandler={exploreHandler}
      navigateCheckCoverage={navigateCheckCoverage}
      checkDisableHandler={checkDisableHandler}
      setShowCoverage={setShowCoverage}
      setCoverageError={setCoverageError}
      setCoverageResponse={setCoverageResponse}
      coverageContent={
        cacheDataCoverage ? cacheDataCoverage : networkCoverageContent
      }
      coverageError={coverageError}
      showCoverage={showCoverage}
      checkAddress={checkAddress}
      signal4G={signal4G}
      signal5G={signal5G}
      coverageLoading={coverageLoading}
      coverageAddress={coverageAddress}
      coverageModal={coverageModal}
      signalModal={signalModal}
      checkCoverageHome={checkCoverageHome}
      coverageResponse={coverageResponse}
    />
  );
};
