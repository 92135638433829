import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Config from "../../config/app.config.json";
import { getFAQContent } from "../../features/strapi/homePageSlice";
import {
  addMultipleCacheData,
  getCache,
  isTemplateExisits,
  useNonInitialEffect,
} from "../../utils/commonFunctions";

const FAQ_template = isTemplateExisits(Config.Home.FAQ)
  ? require(`./${Config.Home.FAQ.template}_FAQ`).default
  : null;

export type FAQTemplateProps = {
  content?: any;
};

export const FAQ = () => {
  const dispatch = useAppDispatch();
  const [cacheFAQContent, setFAQContent] = useState<any>();
  const { FAQContent } = useAppSelector((state: any) => state.strapi.homepage);
  useEffect(() => {
    getCache("FAQ", getFAQContent, setFAQContent, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("FAQ", FAQContent);
  }, [FAQContent]);

  return (
    (cacheFAQContent || FAQContent) && (
      <FAQ_template content={cacheFAQContent ? cacheFAQContent : FAQContent} />
    )
  );
};
