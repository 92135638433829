import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import styles from "./HeaderItem.module.scss";
interface IProps {
  itemName: string;
  isToggeledItem: any;
  onRequestOpenMenu: any;
  menuItems: Array<any>;
  closeMenu: any;
  buttonId?: string;
  menuId?: string;
  anchorEl: null | HTMLElement;
  showArrowIcon?: any;
  headerStyle?: any;
  showActiveIndicator?: boolean;
  userIcon?: any;
}
export const HeaderToggleMenuItem = (props: IProps) => {
  const {
    itemName,
    isToggeledItem,
    onRequestOpenMenu,
    menuItems,
    closeMenu,
    buttonId,
    menuId,
    anchorEl,
    showArrowIcon,
    headerStyle,
    showActiveIndicator,
    userIcon,
  } = props;
  return (
    <>
      <div
        className={
          showActiveIndicator &&
          menuItems
            ?.map((item) => item.navigationPath)
            .includes(window.location.pathname)
            ? `${styles.item_container} ${styles.active_header_item}`
            : styles.item_container
        }
      >
        {userIcon ? (
          <Button
            className={
              anchorEl && isToggeledItem
                ? `${styles.active_toggle_button} ${styles.header_btn_txt}`
                : styles.header_btn_txt
            }
            onClick={onRequestOpenMenu}
            id={buttonId}
            aria-controls={isToggeledItem ? menuId : undefined}
            aria-haspopup="true"
            aria-expanded={isToggeledItem ? "true" : undefined}
            style={headerStyle}
          >
            <Box
              component="img"
              src={userIcon?.data?.attributes?.url}
              alt={userIcon?.data?.attributes?.alternativeText}
              sx={{
                display: { xs: "block", sm: "block", lg: "none" },
                cursor: "pointer",
                margin: "20px",
              }}
            ></Box>
            {showArrowIcon ? (
              isToggeledItem ? (
                <KeyboardArrowUp className={styles.arrow_up_key} />
              ) : (
                <KeyboardArrowDown />
              )
            ) : null}
          </Button>
        ) : (
          <Button
            className={
              anchorEl && isToggeledItem
                ? `${styles.active_toggle_button} ${styles.header_btn_txt}`
                : styles.header_btn_txt
            }
            onClick={onRequestOpenMenu}
            id={buttonId}
            aria-controls={isToggeledItem ? menuId : undefined}
            aria-haspopup="true"
            aria-expanded={isToggeledItem ? "true" : undefined}
            style={headerStyle}
          >
            {itemName}
            {showArrowIcon ? (
              isToggeledItem ? (
                <KeyboardArrowUp className={styles.arrow_up_key} />
              ) : (
                <KeyboardArrowDown />
              )
            ) : null}
          </Button>
        )}

        <Menu
          id={menuId}
          className={styles.header_menu}
          onClose={closeMenu}
          anchorEl={anchorEl}
          open={Boolean(isToggeledItem)}
          keepMounted
          MenuListProps={{
            "aria-labelledby": buttonId,
          }}
          disablePortal={true}
        >
          {menuItems?.length > 0 &&
            menuItems?.map((item: any, index: number) => {
              return (
                <MenuItem
                  key={index}
                  className={styles.menu_item}
                  onClick={item.action}
                >
                  {item.label}
                </MenuItem>
              );
            })}
        </Menu>
      </div>
    </>
  );
};
HeaderToggleMenuItem.defaultProps = {
  showArrowIcon: true,
};
