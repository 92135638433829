import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import EnvConfig from "../../../config/env.config.json";
import { checkCoverageContent } from "../../../features/strapi/homePageSlice";
import { checkCoverage } from "../../../features/homepage/homepageSlice";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { scroller } from "react-scroll";
import { geocodeByAddress } from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";

const CheckCoverageComponent: any = isTemplateExisits(
  Config.Home?.CheckCoverage
)
  ? require(`./${Config.Home.CheckCoverage.template}_CheckCoverage`).default
  : null;

export type CheckCoverageProps = {
  checkAddressHandler?: any;
  handleSelect?: any;
  checkCoverageHandler?: any;
  coverageContent?: any;
  checkAddress?: any;
  coverageLoading?: any;
  coverageAddress?: any;
  signal4G?: any;
  signal5G?: any;
  coverageError?: any;
  navigateCheckCoverage?: any;
  coverageModal?: any;
  checkDisableHandler?: any;
  signalModal?: any;
  checkCoverageHome?: any;
  openCoverageModal?: any;
  closeModalHandler?: any;
  searchAgainHandler?: any;
  exploreHandler?: any;
  getStarted?: any;
  showCoverage?: any;
  setShowCoverage?: any;
  setCoverageError?: any;
};

export const CheckCoverage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [cacheDataCoverage, setCacheDataCoverage] = useState<any>(null);
  const { coverageStatus, coverageLoading, errorCoverageStatus } =
    useAppSelector((state: any) => state.homepage);
  const { networkCoverageContent } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  const [showCoverage, setShowCoverage] = useState(false);
  const [coverageError, setCoverageError] = useState(false);
  const [checkAddress, setCheckAddress] = useState("");
  const [coverageAddress, setCoverageAddress] = useState({
    address1: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });
  const [signal4G, setSignal4G] = useState("");
  const [signal5G, setSignal5G] = useState("");
  const [coverageModal, setCoverageModal] = useState(false);
  const [checkCoverageHome, setCheckCoverageHome] = useState(false);
  const [signalModal, setSignalModal] = useState(false);

  useEffect(() => {
    getCache(
      "CheckCoverageHome",
      checkCoverageContent,
      setCacheDataCoverage,
      dispatch
    );
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("CheckCoverageHome", networkCoverageContent);
  }, [networkCoverageContent]);

  useEffect(() => {
    if (!isEmpty(coverageStatus)) {
      setCheckAddress("");
      setCoverageAddress({
        address1: "",
        city: "",
        state: "",
        country: "",
        zip: "",
      });
      setSignal4G(coverageStatus?.data?.data?.signal4g);
      setSignal5G(coverageStatus?.data?.data?.signal5g);
      setShowCoverage(true);
      setCoverageModal(false);
      setSignalModal(true);
    }
  }, [coverageStatus]);

  useEffect(() => {
    if (!isEmpty(errorCoverageStatus)) {
      setCheckAddress("");
      setCoverageError(true);
      setSignal4G("");
      setSignal5G("");
    }
  }, [errorCoverageStatus]);

  const checkAddressHandler = (e: any) => {
    let checkAddArray = e.split(",");
    let coverageAddressNew = {
      address1: "",
      city: "",
      country: "",
      state: "",
      zip: "",
    };
    if (e.includes(",")) {
      coverageAddressNew = {
        address1: checkAddArray[0],
        city: checkAddArray[1],
        country: checkAddArray[2],
        state: checkAddArray[3],
        zip: checkAddArray[4],
      };
    }

    setCheckAddress(e);
    setCoverageAddress(
      !isEmpty(coverageAddress) ? coverageAddressNew : coverageAddress
    );
  };

  const handleSelect = (address: any) => {
    geocodeByAddress(address)
      .then(async (results: any) => {
        let city: string = "",
          state: string = "",
          zip: string = "",
          country: string = "",
          route: string = "",
          streetNumber: string = "";
        results &&
          results[0] &&
          results[0].address_components &&
          results[0].address_components.map((el: any) => {
            state = el.types.includes("administrative_area_level_1")
              ? el.short_name
              : state;
            zip = el.types.includes("postal_code") ? el.short_name : zip;
            city = el.types.includes("locality")
              ? el.long_name
              : el.types.includes("sublocality")
              ? el.long_name
              : city;
            streetNumber = el.types.includes("street_number")
              ? el.short_name
              : streetNumber;
            route = el.types.includes("route") ? el.long_name : route;
            country = el.types.includes("country") ? el.short_name : country;
            return null;
          });
        if (streetNumber && route) {
          setCoverageAddress({
            address1: streetNumber + " " + route,
            city,
            state,
            country,
            zip,
          });
          setCheckAddress(
            streetNumber +
              " " +
              route +
              ", " +
              city +
              ", " +
              state +
              ", " +
              country +
              ", " +
              zip
          );
          setCoverageError(false);
        } else {
          setCoverageAddress({
            address1: "",
            city,
            state,
            country,
            zip,
          });
          setCheckAddress(city + ", " + state + ", " + country + ", " + zip);
          setCoverageError(true);
        }
      })
      .catch((error) => console.error("error", error));
  };

  const getStarted = (_: any) => {
    scroller.scrollTo("bestCellPhonePlan", {
      duration: 1000,
      delay: 0,
      offset: -100,
      smooth: true,
    });
  };
  const GAAndFBEventsHandler = (categoryName: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.home,
      category: categoryName,
      buttonValue: value,
    });
  };

  const checkCoverageHandler = (address: any) => {
    setCoverageError(false);
    setSignal4G("");
    setSignal5G("");
    dispatch(checkCoverage(address));
    GAAndFBEventsHandler(
      gaCategory.networkCoverage,
      networkCoverageContent?.button_1
    );
  };

  const navigateCheckCoverage = () => {
    setShowCoverage(false);
    setSignalModal(false);
    setSignal4G("");
    setSignal5G("");
    navigate("/coverage-check");
  };
  const checkDisableHandler = () => {
    return (
      checkAddress === "" &&
      (!coverageAddress.address1 ||
        !coverageAddress.city ||
        !coverageAddress.state ||
        !coverageAddress.country ||
        !coverageAddress.zip ||
        coverageLoading)
    );
  };

  const openCoverageModal = () => {
    GAAndFBEventsHandler(
      gaCategory.checkCoverage,
      networkCoverageContent?.button_1
    );
    setCoverageModal(true);
    setCheckCoverageHome(true);
    setCoverageError(false);
  };

  const closeModalHandler = () => {
    setCoverageModal(false);
    setCoverageError(false);
    setCheckAddress("");
    setSignalModal(false);
    setSignal4G("");
    setSignal5G("");
  };

  const searchAgainHandler = () => {
    setSignalModal(false);
    setCoverageModal(signal4G !== "no service" || signal5G !== "no service");
    setSignal4G("");
    setSignal5G("");
    setCoverageError(false);
    setCoverageAddress({
      address1: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    });
    setCheckAddress("");
  };

  const exploreHandler = () => {
    setSignalModal(false);
    setCoverageModal(signal4G === "no service" || signal5G === "no service");
    setSignal4G("");
    setSignal5G("");
    setCoverageError(false);
    setCoverageAddress({
      address1: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    });
    setCheckAddress("");
    if (signal4G !== "no service" || signal5G !== "no service") {
      scroller.scrollTo("bestCellPhonePlan", {
        duration: 1000,
        delay: 0,
        offset: -100,
        smooth: true,
      });
    }
  };
  return (
    <CheckCoverageComponent
      checkAddressHandler={checkAddressHandler}
      handleSelect={handleSelect}
      getStarted={getStarted}
      checkCoverageHandler={checkCoverageHandler}
      openCoverageModal={openCoverageModal}
      closeModalHandler={closeModalHandler}
      searchAgainHandler={searchAgainHandler}
      exploreHandler={exploreHandler}
      navigateCheckCoverage={navigateCheckCoverage}
      checkDisableHandler={checkDisableHandler}
      setShowCoverage={setShowCoverage}
      setCoverageError={setCoverageError}
      coverageContent={
        cacheDataCoverage ? cacheDataCoverage : networkCoverageContent
      }
      coverageError={coverageError}
      showCoverage={showCoverage}
      checkAddress={checkAddress}
      signal4G={signal4G}
      signal5G={signal5G}
      coverageLoading={coverageLoading}
      coverageAddress={coverageAddress}
      coverageModal={coverageModal}
      signalModal={signalModal}
      checkCoverageHome={checkCoverageHome}
    />
  );
};
