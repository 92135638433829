import { Box } from "@mui/material";
import { renderCommonStyle } from "../../../utils/commonFunctions";
import CSS_KEYWORDS from "../../../utils/globalValues/CSSKeyWords";

type Props = {
  steps: any;
  stepperBg: string;
  successFill: string;
  fadedFill: string;
  errorFill: string;
};
const {
  FLEX,
  UNSET,
  AUTO,
  SPACE_BETWEEN,
  COLUMN,
  COLUMN_REVERSE,
  COLUMN_NOWRAP,
  CENTER,
  LEFT,
  ABSOLUTE,
  RELATIVE,
} = CSS_KEYWORDS;

/* Note: Pass data to the component in below format as required */
/* const TestData = [
  {
    title: "Service Assigned",
    subTitle: "Your service request is assigned to Rosej",
    isTickEnabled: true,
    color: "errorFill",
    levels: 4,
    currentLevel: 2,
    filling: "fadedFill",
  },
  {
    title: "En Route",
    subTitle: "Your service request is assigned to Rosej",
    isTickEnabled: false,
    color: "successFill",
    levels: 4,
    currentLevel: 2,
    filling: "errorFill",
  },
  {
    title: "Installation Started",
    subTitle: "Your service request is assigned to Rosej",
    color: "errorFill",
    isTickEnabled: true,
    levels: 4,
    currentLevel: 3,
    filling: "successFill",
  },
  {
    title: "Service Completed",
    subTitle: "Your service request is assigned to Rosej",
    color: "fadedFill",
    isTickEnabled: false,
    levels: 4,
    currentLevel: 2,
    filling: "successFill",
  },
]; */

/* Component should be called like this */

/* <DynamicStepper
  steps={TestData}
  stepperBg="var(--soft-peach)"
  successFill="var(--text_color_9)"
  fadedFill="var(--faded_grey1)"
  errorFill="var(--shocking-orange)"
/>; */

const DynamicStepper = (props: Props) => {
  const { steps, stepperBg, successFill, fadedFill, errorFill } = props;

  const RenderCircle = (props: any) => {
    const { color, isTickEnabled } = props;
    const renderColor = `1.5px solid ${eval(color)}`;
    return (
      <Box
        sx={{
          border: renderColor,
          width: "27px",
          height: "27px",
          position: RELATIVE,
          background: "var(--white)",
          borderRadius: "100%",
        }}
      >
        {isTickEnabled && (
          <Box
            sx={{
              position: ABSOLUTE,
              transform: "rotate(50deg) translate(-50%, -50%)",
              borderBottom: renderColor,
              borderRight: renderColor,
              left: "20%",
              top: "40%",
              height: "15px",
              width: "5px",
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        mt: renderCommonStyle("50px", UNSET),
        mx: AUTO,
        display: renderCommonStyle(FLEX, UNSET),
      }}
    >
      <Box
        sx={{
          p: renderCommonStyle("0 40px", "0 75px"),
          display: FLEX,
          justifyContent: SPACE_BETWEEN,
          flexDirection: renderCommonStyle(COLUMN, UNSET),
          zIndex: 10,
        }}
      >
        {steps.slice(0, steps.length - 1).map((step: any, _i: number) => {
          const { title, levels, currentLevel, filling } = step;
          return (
            <Box
              key={title}
              sx={{
                display: renderCommonStyle(FLEX, UNSET),
                flexDirection: renderCommonStyle(COLUMN_REVERSE, UNSET),
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  backgroundColor: stepperBg,
                  width: renderCommonStyle("5px", "100%"),
                  height: renderCommonStyle("180px", "5px"),
                  borderRadius: renderCommonStyle(UNSET, "5px"),
                }}
              />
              <Box
                sx={{
                  width: renderCommonStyle(
                    "5px",
                    `${(100 / levels) * currentLevel}%`
                  ),
                  height: renderCommonStyle(UNSET, "5px"),
                  minHeight: renderCommonStyle(
                    `${(100 / levels) * currentLevel}%`,
                    UNSET
                  ),
                  borderRadius: renderCommonStyle(UNSET, "5px"),
                  marginTop: renderCommonStyle(UNSET, "-5px"),
                  transition: renderCommonStyle(UNSET, "all 200ms"),
                  backgroundColor: eval(filling),
                }}
              />
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          mt: renderCommonStyle("-5px", "-18px"),
          mb: renderCommonStyle("-5px", UNSET),
          ml: renderCommonStyle("-58px", UNSET),
          display: FLEX,
          justifyContent: SPACE_BETWEEN,
          flexDirection: renderCommonStyle(COLUMN, UNSET),
          zIndex: renderCommonStyle(15, -1),
        }}
      >
        {steps.map((step: any, _i: number) => {
          const { color, isTickEnabled, title, subTitle } = step;
          return (
            <Box
              key={title}
              sx={{
                display: FLEX,
                alignItems: CENTER,
                flexFlow: renderCommonStyle(UNSET, COLUMN_NOWRAP),
                gap: renderCommonStyle("20px", UNSET),
                width: renderCommonStyle(UNSET, "180px"),
              }}
            >
              <RenderCircle color={color} isTickEnabled={isTickEnabled} />
              <Box sx={{ my: renderCommonStyle(UNSET, "15px") }}>
                <Box
                  sx={{
                    mb: renderCommonStyle(UNSET, "10px"),
                    color: "var(--text_color_8)",
                    fontSize: renderCommonStyle("18px", "16px", "20px"),
                    fontFamily: "var(--font_family_Medium)",
                    textAlign: renderCommonStyle(LEFT, CENTER),
                  }}
                >
                  {title}
                </Box>
                <Box
                  sx={{
                    color: "var(--text_color_8)",
                    fontSize: renderCommonStyle("14px", "16px"),
                    fontFamily: "var(--font_family_Semibold)",
                    textAlign: renderCommonStyle(LEFT, CENTER),
                    width: { xs: "250px", sm: "unset" },
                  }}
                >
                  {subTitle}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default DynamicStepper;
