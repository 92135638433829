import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import { Menu } from "@mui/icons-material";
import styles from "./S1_Header.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getCustomerHeaderContent,
  strapiHeaderContent,
} from "../../../features/strapi/componentSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { HeaderToggleMenuItem } from "./HeaderToggleMenuItem";
import { HeaderNavigateItem } from "./HeaderNavigateItem";
import { HeaderModal } from "./HeaderModal";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { scroller } from "react-scroll";
import { AccountCircle } from "@mui/icons-material";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import {
  GetCustomer,
  resetDataGetCustomer,
} from "../../../features/checkout/checkoutSlice";
import { CustomerStatus } from "../../../enums";
import { HeaderProps } from "./Header";
const HeaderComponent = ({
  cacheHeader,
  cacheCustomHeader,
  logoutHandler,
}: HeaderProps) => {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isRenderLogin, getCustomer } = useAppSelector(
    (state: any) => state.checkout
  );
  const [isButtonToggeled, setIsButtonToggeled] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [authUser, setAuthUser] = useState<any>(null);
  const [primaryHeaders, setPrimaryHeaders] = useState([]);
  const [secondaryHeaders, setSecondaryHeaders] = useState([]);
  const [loginItem, setLoginItem] = useState();
  useEffect(() => {
    return () => {
      setDisplayModal(false);
    };
  }, [location]);

  useEffect(() => {
    getAuthData();
  }, [isRenderLogin]);
  let customer: any = localStorage.getItem("customerDetail");
  customer = customer ? JSON.parse(customer) : null;
  useEffect(() => {
    getHeaders();
  }, [location, cacheHeader, cacheCustomHeader, getCustomer]);

  const renderHeaderItemContent = (
    item: any,
    index: number,
    showActiveIndicator: boolean = true,
    itemName?: string
  ) => {
    if (item?.subMenu && item?.subMenu.length > 0) {
      const headerItemName = item?.itemName || itemName?.trim()?.split(" ")[0];
      const menuActionItems = item?.subMenu.map((subItem: any) => ({
        label: subItem?.itemName,
        action: () => {
          setAnchorEl(null);
          setIsButtonToggeled("");
          handleNavigation(subItem);
          if (subItem.itemName.toLowerCase() === "logout") {
            localStorage.clear();
            logoutHandler(RoutingURLs.home);
            setAuthUser(null);
            dispatch(resetDataGetCustomer());
          }
        },
        navigationPath: subItem.navigationPath,
      }));
      return (
        <HeaderToggleMenuItem
          key={`header-item-${index}`}
          itemName={headerItemName}
          isToggeledItem={headerItemName === isButtonToggeled}
          anchorEl={anchorEl}
          onRequestOpenMenu={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            setIsButtonToggeled(headerItemName);
          }}
          menuItems={menuActionItems}
          closeMenu={() => {
            setAnchorEl(null);
            setIsButtonToggeled("");
          }}
          buttonId={`btn-key${index}`}
          menuId={`menu-key${index}`}
          headerStyle={itemName ? { color: "var(--text_color)" } : null}
          showArrowIcon={itemName ? false : true}
          showActiveIndicator={!itemName}
        />
      );
    } else {
      return (
        <HeaderNavigateItem
          onClickItem={() => {
            handleNavigation(item);
          }}
          showActiveIndicator={
            showActiveIndicator &&
            window.location.pathname === item?.navigationPath
          }
          key={`header-item-${index}`}
        >
          {item?.itemName}
        </HeaderNavigateItem>
      );
    }
  };
  const handleNavigation = (item: any) => {
    if (item?.isNewWindow) {
      window.open(item.navigationPath, "_blank");
    } else {
      if (item?.itemName?.toLowerCase() === "plans") {
        navigate(item.navigationPath);
        setTimeout(function () {
          scroller.scrollTo("bestCellPhonePlan", {
            duration: 1000,
            delay: 0,
            offset: -100,
            smooth: true,
          });
        }, 1000);
      } else {
        navigate(item.navigationPath);
      }
    }
  };
  const showHeaderModal = () => {
    setDisplayModal(true);
  };
  const hideHeaderModal = () => {
    setDisplayModal(false);
  };
  const getAuthData = () => {
    const auth = getAuth();
    /**
     * The onAuthStateChanged function will be executed when firebase auth gets changed
     * @param1 auth - Firebase configuration
     * @param2 callback function - @param1 user - logged user data
     */
    onAuthStateChanged(auth, (user: any) => {
      if (user?.displayName) {
        setAuthUser(user);
        dispatch(GetCustomer({ username: encodeURIComponent(user.email) }));
      }
    });
  };
  const isCheckoutHeader = location.pathname === RoutingURLs.checkout;
  const isMyAccountPage = location.pathname === RoutingURLs.myAccount;
  const isTrackOrderPage = location.pathname === RoutingURLs.trackOrder;
  const isActivationPage = location.pathname === RoutingURLs.activation;
  const isSecondaryloginPage = location.pathname === RoutingURLs.secondarylogin;
  const isActiationSteps = location.pathname === RoutingURLs.steps;
  const { mobile_logo, web_logo, tab_logo } = cacheHeader || {};
  let currentUser: any = JSON.parse(
    localStorage.getItem("currentuser") || "null"
  );
  const getHeaders = () => {
    let customer: any = localStorage.getItem("customerDetail");
    customer = customer ? JSON.parse(customer) : null;
    let loginHeaderItem = null,
      primaryItems = [],
      secondaryItems = [];
    if (
      isCheckoutHeader ||
      isTrackOrderPage ||
      isSecondaryloginPage ||
      isActiationSteps
    ) {
      loginHeaderItem = cacheHeader?.c_login_item;
    } else if (isMyAccountPage) {
      primaryItems = cacheCustomHeader?.pra_primary_items;
      secondaryItems = cacheCustomHeader?.pra_secondary_items;
      loginHeaderItem = cacheCustomHeader?.pra_login_item;
    } else if (isActivationPage) {
      secondaryItems = cacheHeader?.activation_secondary_items;
      loginHeaderItem = cacheHeader?.login_item;
    } else if (
      currentUser &&
      customer?.isPrimary === false &&
      (customer?.status === CustomerStatus.ACTIVE ||
        customer?.status === CustomerStatus.PORTING_FAILED ||
        customer?.status === CustomerStatus.ACTIVATION_FAILED ||
        customer?.status === CustomerStatus.DISCONNECTED ||
        customer?.status === CustomerStatus.SUSPENDED)
    ) {
      primaryItems = cacheCustomHeader?.s_u_p_h_i;
      secondaryItems = cacheCustomHeader?.s_u_s_h_i;
      loginHeaderItem = cacheCustomHeader?.s_u_login_i;
    } else if (
      currentUser &&
      (customer?.status === CustomerStatus.ACTIVE ||
        customer?.status === CustomerStatus.PORTING_FAILED ||
        customer?.status === CustomerStatus.ACTIVATION_FAILED ||
        customer?.status === CustomerStatus.DISCONNECTED ||
        customer?.status === CustomerStatus.SUSPENDED)
    ) {
      primaryItems = cacheCustomHeader?.poa_primary_items;
      secondaryItems = cacheCustomHeader?.poa_secondary_items;
      loginHeaderItem = cacheCustomHeader?.poa_login_item;
    } else if (currentUser && customer?.simPaid) {
      primaryItems = cacheCustomHeader?.pra_primary_items;
      loginHeaderItem = cacheCustomHeader?.pra_login_item;
      secondaryItems = cacheHeader?.pl_secondary_items;
    } else if (currentUser && customer && !customer?.simPaid) {
      primaryItems = cacheHeader?.public_primary_items;
      secondaryItems = cacheHeader?.public_secondary_items;
      loginHeaderItem = cacheHeader?.login_item;
    } else if (!currentUser) {
      primaryItems = cacheHeader?.public_primary_items;
      secondaryItems = cacheHeader?.public_secondary_items;
      loginHeaderItem = cacheHeader?.login_item;
    } else if (currentUser && !customer) {
      primaryItems = cacheHeader?.pl_primary_items;
      secondaryItems = cacheHeader?.pl_secondary_items;
      loginHeaderItem = cacheHeader?.login_item;
    }
    setPrimaryHeaders(primaryItems);
    setSecondaryHeaders(secondaryItems);
    setLoginItem(loginHeaderItem);
  };
  return (
    <div className={styles.Header_Wrapper}>
      <AppBar position="fixed" className={`${styles.Header_Container}`}>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          disableGutters
        >
          <Box pl={{ md: 3 }}>
            <Box
              component="img"
              src={web_logo?.data?.attributes?.url || ""}
              alt={web_logo?.data?.attributes?.alternativeText || ""}
              width={"104px"}
              height={"45px"}
              sx={{ display: { xs: "none", md: "block" }, cursor: "pointer" }}
              onClick={() => {
                if (
                  currentUser &&
                  (customer?.status === CustomerStatus.ACTIVE ||
                    customer?.status === CustomerStatus.PORTING_FAILED ||
                    customer?.status === CustomerStatus.ACTIVATION_FAILED ||
                    customer?.status === CustomerStatus.DISCONNECTED ||
                    customer?.status === CustomerStatus.SUSPENDED)
                ) {
                  navigate(RoutingURLs.postActivationHome);
                } else if (currentUser && customer?.simPaid) {
                  navigate(RoutingURLs.myAccount);
                } else {
                  navigate(RoutingURLs.home);
                }
              }}
            ></Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {isCheckoutHeader ? (
              <Box className={styles.header_txt}>{cacheHeader?.header1}</Box>
            ) : null}
            {primaryHeaders?.length > 0 &&
              primaryHeaders.map((headerItem: any, index: number) => {
                return renderHeaderItemContent(headerItem, index);
              })}
          </Box>
          <Box
            className={styles.secondary_header}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {secondaryHeaders?.length > 0 &&
              secondaryHeaders.map((headerItem: any, index: number) =>
                renderHeaderItemContent(headerItem, index, true)
              )}
            {authUser?.displayName ? (
              <Box display="flex" alignItems="center" mr="15px">
                <AccountCircle
                  style={{ color: "var(--primary_brand_color)" }}
                />
                {renderHeaderItemContent(
                  loginItem,
                  1,
                  false,
                  authUser.displayName || ""
                )}
              </Box>
            ) : (
              !isCheckoutHeader &&
              cacheHeader?.nav_link1 && (
                <HeaderNavigateItem
                  onClickItem={() => {
                    handleNavigation(cacheHeader.nav_link1);
                  }}
                  showActiveIndicator={false}
                >
                  {cacheHeader?.nav_link1?.itemName}
                </HeaderNavigateItem>
              )
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <AppBar position="fixed" className={styles.d_view_port}>
        <Toolbar disableGutters>
          <Box>
            <Menu className={styles.menu_icon} onClick={showHeaderModal} />
          </Box>
          <Box pl={1.2}>
            <Box
              component="img"
              src={tab_logo?.data?.attributes?.url || ""}
              alt={tab_logo?.data?.attributes?.alternativeText || ""}
              height="33px"
              width="76px"
              sx={{
                display: { xs: "none", sm: "block", lg: "none" },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(RoutingURLs.home);
              }}
            ></Box>
            <Box
              component="img"
              src={mobile_logo?.data?.attributes?.url || ""}
              alt={mobile_logo?.data?.attributes?.alternativeText || ""}
              height="33px"
              width="auto"
              sx={{ display: { xs: "block", sm: "none" }, cursor: "pointer" }}
              onClick={() => {
                navigate(RoutingURLs.home);
              }}
            ></Box>
          </Box>
        </Toolbar>
      </AppBar>
      <HeaderModal
        display={displayModal}
        onClose={hideHeaderModal}
        headerContent={cacheHeader}
        handleNavigation={handleNavigation}
        authUser={authUser}
        primaryHeaders={primaryHeaders}
        secondaryHeaders={secondaryHeaders}
        loginItem={loginItem}
        isCheckoutHeader={isCheckoutHeader}
        logoutHandler={logoutHandler}
      />
    </div>
  );
};

export default HeaderComponent;
