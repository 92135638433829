import { deleteMultipleCache } from "./deleteMultipleCache";
import { getMultipleCacheData } from "./getMultipleCacheData";

function AddMinutesToDate(date: any, minutes: any) {
  return new Date(date).getTime() + minutes * 60000;
}

export const getCache = async (
  cacheContentName: any,
  strapiCall: any,
  setState: any,
  dispatch: any,
  payload = null
) => {
  const cachesToFetch = {
    cacheName: cacheContentName,
    url: window.location.origin,
  };
  try {
    const response = await getMultipleCacheData(cachesToFetch);
    if (response) {
      let next = AddMinutesToDate(response.date, 1);
      if (next < new Date(Date.now()).getTime()) {
        deleteMultipleCache([cacheContentName]);
        dispatch(strapiCall(payload));
      } else {
        setState(response.content);
      }
    } else {
      dispatch(strapiCall(payload));
    }
  } catch (e) {
    console.error(e);
  }
};
