import { useEffect, useState } from "react";
import { ButtonCust, SimpleModal } from "../../../../widgets";
import { Grid, Typography, Box } from "@mui/material";
import Config from "../../../../../config/env.config.json";
import styles from "./EstimatedMonthlyBillDialog.module.scss";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "../../../../../utils/commonFunctions";
import { estimatedBillSectionContent } from "../../../../../features/strapi/accountSlice";
import { CardTypes } from "../../../../../enums";

type EstimatedMonthlyBillDialogProps = {
  showEstBillModal: boolean;
  reviewDetails: any;
  currentPlan: any;
  pricingTax: any;
  oneTmeCost: number;
  counter: number;
  oneTimeSIMTaxCharges: any;
  shipPlan: any;
  setShowEstBillModal: any;
  estBillLoader: boolean;
  confirmPurchaseHandler: any;
  showManageCard: any;
  numOfEsim: number;
  numOfPsim: number;
  eSimCostPerLine: number;
};

export const EstimatedMonthlyBillDialog = (
  props: EstimatedMonthlyBillDialogProps
) => {
  const {
    showEstBillModal,
    reviewDetails,
    currentPlan,
    pricingTax,
    oneTmeCost,
    counter,
    oneTimeSIMTaxCharges,
    shipPlan,
    setShowEstBillModal,
    estBillLoader,
    confirmPurchaseHandler,
    showManageCard,
    numOfEsim,
    numOfPsim,
    eSimCostPerLine,
  } = props;
  const dispatch = useAppDispatch();
  const { estMonthlyAddContent } = useAppSelector(
    (state: any) => state.strapi.account
  );
  const { getCCDetails } = useAppSelector((state: any) => state.account);
  const [mainCard, setMainCard] = useState<any>(null);
  const [isCardExpired, setIsCardExpired] = useState(false);
  const [estBillCacheContent, setEstBillCacheContent] = useState<any>(null);

  useEffect(() => {
    let main: any =
      getCCDetails?.length > 0 &&
      getCCDetails.find((el: any) => el.isAutoPay === true);
    if (main) {
      setMainCard(main);
      let today, someday;
      let exMonth = main.dateOfExpiry && main.dateOfExpiry.substring(0, 2);
      let exYear = main.dateOfExpiry && main.dateOfExpiry.substring(2);
      exYear = exYear && `20${exYear}`;
      today = new Date();
      someday = new Date();
      someday.setFullYear(exYear, exMonth, 1);
      if (someday < today) {
        setIsCardExpired(true);
      } else {
        setIsCardExpired(false);
      }
    }
  }, [getCCDetails]);

  useEffect(() => {
    getCache(
      "EstimatedBillAdd",
      estimatedBillSectionContent,
      setEstBillCacheContent,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("EstimatedBillAdd", estMonthlyAddContent);
  }, [estMonthlyAddContent]);
  const content = estBillCacheContent
    ? estBillCacheContent
    : estMonthlyAddContent;
  return (
    content && (
      <SimpleModal
        onCloseModal={() => setShowEstBillModal(false)}
        showClose={true}
        isShowModal={showEstBillModal}
        sx={{
          width: { xs: "94%", sm: "70%", md: "70%", lg: "40%", xl: "40%" },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "4.8px",
        }}
      >
        <Grid className={styles.new_monthly_estimated_modal_block}>
          <Typography className={styles.new_monthly_estimated_modal_heading}>
            {content?.heading}
          </Typography>
          <Box className={styles.reach_border_line}></Box>
          <Box className={styles.estimated_pricing}>
            <Typography className={styles.estimated_pricing_text}>
              {content?.the}
              {reviewDetails.selectedPlanName}(
              {reviewDetails.currentAdditioinalLines + 1} @{" "}
              {GetCurrencyFixedToTwo(currentPlan?.baseLinePrice)})
            </Typography>
            <Typography className={styles.estimated_price_value}>
              {GetCurrencyFixedToTwo(reviewDetails.newPricing)}
            </Typography>
          </Box>
          <Box className={styles.monthly_estimated_bill_line}></Box>
          <Box className={styles.estimated_pricing}>
            <Typography className={styles.estimated_pricing_text}>
              {content?.tax_fees}
            </Typography>
            <Typography className={styles.estimated_price_value}>
              {GetCurrencyFixedToTwo(pricingTax.newPricingTax)}
            </Typography>
          </Box>
          <Box className={styles.monthly_estimated_bill_line}></Box>

          <Box className={styles.estimated_pricing}>
            <Typography className={styles.estimated_pricing_text}>
              {content?.heading}
            </Typography>
            <Typography className={styles.estimated_price_value}>
              {GetCurrencyFixedToTwo(
                reviewDetails.newPricing + pricingTax.newPricingTax
              )}
            </Typography>
          </Box>
          <Box className={styles.one_time_charges_text}>
            {content?.one_time}
          </Box>

          <Box className={styles.monthly_estimated_bill_line}></Box>
          {numOfEsim >= 1 && (
            <Box className={styles.estimated_pricing}>
              <Typography className={styles.estimated_pricing_text}>
                {content?.esim_kit}({numOfEsim})
              </Typography>
              <Typography className={styles.estimated_price_value}>
                {GetCurrencyFixedToTwo(eSimCostPerLine)}
              </Typography>
            </Box>
          )}
          {numOfPsim >= 1 && (
            <Box className={styles.estimated_pricing}>
              <Typography className={styles.estimated_pricing_text}>
                {content?.welcome_kit}({numOfPsim})
              </Typography>
              <Typography className={styles.estimated_price_value}>
                {GetCurrencyFixedToTwo(oneTmeCost)}
              </Typography>
            </Box>
          )}
          <Box className={styles.monthly_estimated_bill_line}></Box>
          <Box className={styles.estimated_pricing}>
            <Typography className={styles.estimated_pricing_text}>
              {numOfPsim >= 1 ? shipPlan.label : content?.instant_delivery}
            </Typography>
            <Typography className={styles.estimated_price_value}>
              {numOfPsim >= 1
                ? GetCurrencyFixedToTwo(shipPlan?.cost)
                : GetCurrencyFixedToTwo(
                    Config.INSTANT_DELIVERY_VIA_EMAIL_CHARGES
                  )}
            </Typography>
          </Box>
          <Box className={styles.monthly_estimated_bill_line}></Box>
          <Box className={styles.estimated_pricing}>
            <Typography className={styles.estimated_pricing_text}>
              {content?.tax_fees}
            </Typography>
            <Typography className={styles.estimated_price_value}>
              {oneTimeSIMTaxCharges?.totalTax
                ? GetCurrencyFixedToTwo(oneTimeSIMTaxCharges?.totalTax)
                : GetCurrencyFixedToTwo(0)}
            </Typography>
          </Box>
          <Box className={styles.monthly_estimated_bill_line}></Box>
          <Box className={styles.estimated_pricing}>
            <Typography className={styles.one_time_charges_text}>
              {content?.total_due}
            </Typography>
            <Typography className={styles.one_time_charges_text}>
              {oneTmeCost + shipPlan?.cost + oneTimeSIMTaxCharges?.totalTax
                ? GetCurrencyFixedToTwo(
                    oneTmeCost + shipPlan?.cost + oneTimeSIMTaxCharges?.totalTax
                  )
                : GetCurrencyFixedToTwo(0)}
            </Typography>
          </Box>
          <Box className={styles.default_card}>
            <Typography className={styles.one_time_charges_text}>
              {content?.payment}
            </Typography>
            <Box className={styles.account_review_change_card}>
              <span className={styles.review_card_img}>
                <img
                  alt="card_icon"
                  src={
                    mainCard?.type === CardTypes?.VISA
                      ? content?.visa_img?.data?.attributes?.url
                      : content?.review_card?.data?.attributes?.url
                  }
                ></img>
              </span>
              <Typography
                className={styles.cc_mask}
                sx={{
                  color: isCardExpired ? "var(--danger)" : "var(--text_color)",
                }}
              >
                {content?.card_num}
                {mainCard && mainCard.ccNumberMask}
              </Typography>
            </Box>
          </Box>

          <Box className={styles.new_monthly_estimated_modal_btn}>
            <ButtonCust
              variantType={Config.SECONDARY_BUTTON_TYPE}
              loading={estBillLoader}
              onClick={confirmPurchaseHandler}
            >
              {content?.button_1}
            </ButtonCust>
          </Box>
          <Typography
            className={styles.manage_cards_for_secondary_lines}
            onClick={showManageCard}
          >
            {content?.manage_cards}
          </Typography>
        </Grid>
      </SimpleModal>
    )
  );
};
