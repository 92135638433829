import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  addMultipleCacheData,
  getCache,
  isTemplateExisits,
  useNonInitialEffect,
} from "../../../utils/commonFunctions";
import Config from "../../../config/app.config.json";
import { getInstallTrackerContent } from "../../../features/strapi/installationTrackerSlice";
import {
  getActivityStatus,
  resetActivityStatus,
} from "../../../features/fsm/fsmSlice";

const InstallationTrackerTemplate = isTemplateExisits(
  Config.InstallationTracker.InstallationTracker
)
  ? require(`./${Config.InstallationTracker.InstallationTracker.template}_InstallationTracker`)
      .default
  : null;

interface Props {
  isFromFieldServices?: boolean;
  serviceInfo?: any;
  setTrackerData?: any;
}

export interface InstallationTrackerProps extends Props {
  content: any;
  activityStatus: any;
  stepperData: any;
  setStepperData: any;
  disableHandler: any;
  setDisableHandler: any;
  completedHandler: boolean;
  SetCompletedhandler: any;
}

const InstallationTrackerComp = (props: Props) => {
  const { isFromFieldServices, serviceInfo } = props;
  const dispatch = useAppDispatch();

  const { activityStatus, errActivityStatus } = useAppSelector(
    (state: any) => state.fsm
  );
  const { installTrackerContent } = useAppSelector(
    (state: any) => state.strapi.installTracker
  );
  let activityInfo: any = localStorage.getItem("activityInfo");
  if (activityInfo) activityInfo = JSON.parse(activityInfo);

  const [cacheContent, setCacheContent] = useState<any>();
  const [disableHandler, setDisableHandler] = useState({
    status: false,
    isDisabled: false,
  });
  const [completedHandler, SetCompletedhandler] = useState(false);
  const [stepperData, setStepperData] = useState<any>(null);
  useEffect(() => {
    getCache(
      "InstallationTracker",
      getInstallTrackerContent,
      setCacheContent,
      dispatch
    );
  }, []);

  useEffect(() => {
    if (isFromFieldServices) {
      dispatch(getActivityStatus({ activityId: serviceInfo?.reachActivityId }));
    } else if (activityInfo) {
      dispatch(
        getActivityStatus({ activityId: activityInfo?.data?.reachActivityId })
      );
    }
    return () => {
      dispatch(resetActivityStatus());
    };
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("InstallationTracker", installTrackerContent);
  }, [installTrackerContent]);

  return cacheContent || installTrackerContent ? (
    <InstallationTrackerTemplate
      content={cacheContent ? cacheContent : installTrackerContent}
      activityStatus={activityStatus}
      stepperData={stepperData}
      setStepperData={setStepperData}
      disableHandler={disableHandler}
      setDisableHandler={setDisableHandler}
      completedHandler={completedHandler}
      SetCompletedhandler={SetCompletedhandler}
      {...props}
    />
  ) : null;
};

export default InstallationTrackerComp;
