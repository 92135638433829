import { Box, RadioGroup, Typography } from "@mui/material";
import { ButtonCust, Divider, SimpleModal, RadioCust } from "../widgets";
import { ChangeSimPreferenceProps } from "./ChangeSimPreferenceDialog";
import classes from "./T1_ChangeSimPreferenceDialog.module.scss";
import Config from "../../config/env.config.json";
import { useAppSelector } from "../../app/hooks";
import { SimTypes } from "../../features/checkout/Types";
import { GetCurrencyFixedToTwo } from "../../utils/commonFunctions/GetCurrencyFixedToTwo";

const T1_ChangeSimPreferenceDialog = (props: ChangeSimPreferenceProps) => {
  const {
    open,
    onClose,
    content,
    onChangeSimPreferenceHandler,
    simPreference,
    eSimClicked,
    showConfirm,
    same,
    simClicked,
    confirmHandler,
    checkCompatibility,
    simCharges,
  } = props;
  const { planDetails } = useAppSelector((state: any) => state.checkout);
  return (
    <SimpleModal
      isShowModal={Boolean(open)}
      onCloseModal={onClose}
      showClose={true}
      sx={{ width: { xs: "90%", sm: "60%", md: "80%", lg: "35%", xl: "35%" } }}
    >
      <Box style={{ width: "100%" }}>
        <Typography className={classes.title}>{content?.cl_title}</Typography>
        <Box style={{ margin: "15px auto" }}>
          <Divider />
        </Box>
        {simPreference?.length > 0 &&
          simPreference.map((simPreferenceItem, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: { sm: "330px", xs: "100%" },
                  margin: "auto",
                }}
                key={index}
              >
                <Box className={classes.label_txt}>
                  {planDetails.numberOfLines > 1
                    ? content?.line_items[index]?.item
                    : content?.single_line}
                </Box>
                <Box sx={{ marginLeft: "auto" }}>
                  <RadioGroup
                    defaultValue={
                      simPreferenceItem?.eSimCompatible
                        ? SimTypes.eSim
                        : SimTypes.sim
                    }
                    onChange={(e: any, name: any) => {
                      onChangeSimPreferenceHandler(e, name, index);
                    }}
                    style={{ display: "flex" }}
                    row
                  >
                    <RadioCust
                      value={SimTypes.eSim}
                      label={content?.esim_lbl}
                    />
                    <RadioCust value={SimTypes.sim} label={content?.sim_lbl} />
                  </RadioGroup>
                </Box>
              </Box>
            );
          })}
        {simClicked ? (
          <Box display="flex">
            <Typography className={classes.note}>
              {content?.note_txt}
            </Typography>
            &ensp;
            <Box>
              {content?.note_desc &&
                content?.note_desc.map((descItem: any) => (
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.note}
                    key={descItem?.id}
                  >
                    {descItem?.item.replace(
                      "${cost}",
                      GetCurrencyFixedToTwo(simCharges)
                    )}
                  </Typography>
                ))}
            </Box>
          </Box>
        ) : null}
        <Box className={classes.btn_container}>
          {eSimClicked && !showConfirm && !same ? (
            <ButtonCust
              variantType={Config.PRIMARY_BUTTON_TYPE}
              sx={{
                my: "10px",
                width: {
                  xs: "95%",
                  md: "300px",
                },
                height: "45px",
              }}
              onClick={checkCompatibility}
            >
              {content?.ctx_c_c_btn}
            </ButtonCust>
          ) : (
            <ButtonCust
              variantType={Config.PRIMARY_BUTTON_TYPE}
              sx={{
                my: "10px",
                width: {
                  xs: "95%",
                  md: "240px",
                },
                height: "45px",
              }}
              onClick={confirmHandler}
            >
              {content?.cl_btn_ctx}
            </ButtonCust>
          )}
        </Box>
      </Box>
    </SimpleModal>
  );
};

export default T1_ChangeSimPreferenceDialog;
