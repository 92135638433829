import styles from "./InfoCard.module.scss";
import { ButtonCust } from "../../widgets";
import Config from "../../../config//env.config.json";
import { Box, Grid } from "@mui/material";

type initialProps = {
  is_right?: boolean;
  image: any;
  info: string;
  title: string;
  onclick?: any;
  btnTxt?: string;
  btnTxt1?: string;
  maxImgWidth?: string;
  maxImgHeight?: string;
  btnWidth?: string;
  btn1Width?: string;
  subInfo?: string;
  onClick2?: any;
  alt?: string;
  className?: string;
  isSecondSecondary?: boolean;
};

export const InfoCard = (props: initialProps) => {
  return (
    <Grid
      container
      sx={{
        p: { xs: 1, sm: 4 },
        display: "flex",
        flexDirection: props.is_right ? "row-reverse" : " ",
      }}
      className={props.className ? props.className : ""}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <img
          src={props.image}
          style={{
            maxHeight: props.maxImgHeight ? props.maxImgHeight : "",
            maxWidth: props.maxImgWidth ? props.maxImgWidth : "",
            width: "100%",
          }}
          alt={props.alt ? props.alt : ""}
        />
      </Grid>
      <Grid
        container
        xs={12}
        md={6}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Grid
          container
          xs={12}
          lg={11}
          sx={{
            p: 2,
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
            flexDirection: "column",
            alignItems: { xs: "center", md: "start" },
          }}
        >
          <Box className={styles.infocard_detail_title}>{props.title}</Box>
          <Box className={styles.infocard_border_bottom}></Box>
          <Box
            sx={{ textAlign: { xs: "center", md: "start" } }}
            className={styles.infocard_detail_data}
          >
            {props.info}
          </Box>
          {props.subInfo && (
            <Box className={styles.infocard_detail_subdata}>
              {props.subInfo}
            </Box>
          )}

          <Box className={styles.infocard_detail_btn}>
            <Box
              className={styles.infocard_detail_btn1}
              style={{ width: props.btnWidth ? props.btnWidth : "" }}
            >
              {props.btnTxt && (
                <ButtonCust
                  className={styles.infocard_detail_btn1}
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={props.onclick}
                >
                  {props.btnTxt}
                </ButtonCust>
              )}
            </Box>
            <Box
              className={styles.infocard_detail_btn2}
              style={{ width: props.btnWidth ? props.btnWidth : "" }}
            >
              {props.btnTxt1 && (
                <ButtonCust
                  className={styles.infocard_detail_btn2}
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={props.onClick2}
                >
                  {props.btnTxt1}
                </ButtonCust>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
