import { ButtonCust, SimpleModal } from "../../../../widgets";
import { Grid, Box, Typography } from "@mui/material";
import styles from "./ReviewMonthlyStat.module.scss";
import Config from "../../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "../../../../../utils/commonFunctions";
import { reviewStatSectionContent } from "../../../../../features/strapi/accountSlice";

type ReviewMonthlyStatProps = {
  showReviewModal1: boolean;
  pricingTax: any;
  reviewDetails: any;
  setShowReviewModal1: any;
  reviewModalLoader: boolean;
  gotItHandler: any;
};

export const ReviewMonthlyStat = (props: ReviewMonthlyStatProps) => {
  const dispatch = useAppDispatch();
  const { reviewAddContent } = useAppSelector(
    (state: any) => state.strapi.account
  );
  const [reviewCacheContent, setReviewCacheContent] = useState<any>(null);
  const {
    showReviewModal1,
    pricingTax,
    reviewDetails,
    setShowReviewModal1,
    reviewModalLoader,
    gotItHandler,
  } = props;
  useEffect(() => {
    getCache(
      "ReviewMonthlyAdd",
      reviewStatSectionContent,
      setReviewCacheContent,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("ReviewMonthlyAdd", reviewAddContent);
  }, [reviewAddContent]);
  const content = reviewCacheContent ? reviewCacheContent : reviewAddContent;
  return (
    content && (
      <SimpleModal
        onCloseModal={() => setShowReviewModal1(false)}
        showClose={true}
        isShowModal={showReviewModal1}
        sx={{
          width: { xs: "90%", sm: "70%", md: "70%", lg: "40%", xl: "40%" },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "4.8px",
        }}
      >
        <Grid className={styles.new_monthly_statement_modal_block}>
          <Typography className={styles.new_monthly_statement_modal_heading}>
            {content?.review_text}
          </Typography>
          <Box className={styles.reach_border_line}></Box>
          <Box className={styles.current_pricing}>
            <Box className={styles.current_pricing_text}>
              {content?.current_price}
              <br />
              <Typography className={styles.current_plan_details}>
                {content?.the}
                {reviewDetails.selectedPlanName} {content?.for}{" "}
                {reviewDetails.previousAdditionalLines + 1}
                {content?.plus_tax}
              </Typography>
            </Box>
            <Typography className={styles.current_price_value}>
              {GetCurrencyFixedToTwo(
                reviewDetails.currentPricing + pricingTax.currentPricingTax
              )}
            </Typography>
          </Box>
          <Box className={styles.reach_border_line_new}></Box>
          <Box className={styles.current_pricing}>
            <Box className={styles.current_pricing_text}>
              {content?.new_price}
              <br />
              <Typography className={styles.current_plan_details}>
                {content?.the}
                {reviewDetails.selectedPlanName}
                {content?.for}
                {reviewDetails.currentAdditioinalLines + 1}
                {content?.plus_tax}
              </Typography>
            </Box>
            <Typography className={styles.current_price_value}>
              {GetCurrencyFixedToTwo(
                reviewDetails.newPricing + pricingTax.newPricingTax
              )}
            </Typography>
          </Box>
          <Box className={styles.reach_border_line_new}></Box>

          <>
            <Box className={styles.new_monthly_statement_modal_btn}>
              <ButtonCust
                variantType={Config.SECONDARY_BUTTON_TYPE}
                loading={reviewModalLoader}
                onClick={gotItHandler}
              >
                {content?.button_1}
              </ButtonCust>
            </Box>
          </>
        </Grid>
      </SimpleModal>
    )
  );
};
