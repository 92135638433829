import { Typography } from "@mui/material";
import { Formik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setOpenAutopayConsent,
  // setIsAutopay,
} from "../../../features/checkout/checkoutSlice";
import { ButtonCust_T2, SimpleModal } from "../../widgets";
import { AutopayConsentProps } from "./AutoPayConsent";
import styles from "./S1_AutopayConsent.module.scss";

const AutopayConsentT1 = (props: AutopayConsentProps) => {
  const dispatch = useAppDispatch();
  const { formik, content, setIsAutopayConsent, confirmToPayment } = props;
  const { openAutopayConsent } = useAppSelector((state: any) => state.checkout);
  const closeModal = () => {
    dispatch(setOpenAutopayConsent(false));
  };

  return (
    content && (
      <SimpleModal
        isShowModal={openAutopayConsent}
        onCloseModal={closeModal}
        showClose={true}
        sx={{
          padding: {
            xs: "0 !important",
            sm: "20px !important",
            md: "40px !important",
          },
          width: {
            xs: "90% !important",
            sm: "60% !important",
            md: "40% !important",
          },
          minWidth: {
            xs: "90% !important",
            sm: "60% !important",
            md: "40% !important",
          },
        }}
      >
        <Typography
          sx={{
            mb: 3,
            fontSize: "26px",
            color: "var(--text_color_8)",
            fontFamily: "var(--font_family_Bold)",
          }}
        >
          {content?.heading}
        </Typography>
        {formik.values.autopay ? (
          <>
            <Typography
              sx={{
                mb: 2,
                fontSize: "16px",
                color: "var(--text_color)",
                fontFamily: "var(--font_family_Semibold)",
                textAlign: "center",
              }}
            >
              {content?.autopay_desc}
            </Typography>

            <ButtonCust_T2
              sx={{ my: 1, width: "225px", margin: "20px" }}
              variantType={"primary_contained_square"}
              onClick={() => {
                setIsAutopayConsent(true);
                confirmToPayment();
                closeModal();
              }}
            >
              {content?.consent_btn}
            </ButtonCust_T2>
            <ButtonCust_T2
              variantType="primary_underlined"
              onClick={closeModal}
            >
              {content?.cancel_btn}
            </ButtonCust_T2>
          </>
        ) : (
          <>
            <Typography
              sx={{
                mb: 2,
                fontSize: "16px",
                color: "var(--text_color)",
                fontFamily: "var(--font_family_Semibold)",
                textAlign: "center",
              }}
            >
              {content?.no_autopay_desc}
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: "16px",
                color: "var(--text_color)",
                fontFamily: "var(--font_family_Semibold)",
                textAlign: "center",
              }}
            >
              {content?.note}
            </Typography>

            <ButtonCust_T2
              sx={{ my: 1, width: "300px", margin: "20px" }}
              variantType={"primary_contained_square"}
              onClick={() => {
                setIsAutopayConsent(false);
                confirmToPayment();
                closeModal();
              }}
            >
              {content?.no_consent_btn}
            </ButtonCust_T2>
            <ButtonCust_T2
              variantType="primary_underlined"
              onClick={closeModal}
            >
              {content?.go_back_btn}
            </ButtonCust_T2>
          </>
        )}
      </SimpleModal>
    )
  );
};

export default AutopayConsentT1;
