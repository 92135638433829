import { Typography, Box } from "@mui/material";
import { geocodeByAddress } from "react-places-autocomplete";
import { ButtonCust, SimpleModal, TextFieldCust } from "../../../../widgets";
import { object, string } from "yup";
import styles from "./AddShippingAddressDialog.module.scss";
import Config from "../../../../../config/env.config.json";
import { useFormik } from "formik";
import { AddressField } from "../../../../checkout/AddressField/AddressField";
import { namePattern } from "../../../../../utils/regexPatterns";
import { addressType } from "../../../../../features/checkout/Types";

type Props = {
  open: boolean;
  onClose?: any;
  updateAddress: any;
  pageName?: any;
};

export function AddShippingAddressDialog(props: Props) {
  const { open, onClose, updateAddress, pageName } = props;

  const billing = {
    address1_err: "Address cannot be empty",
    address1_ph: "Street",
    address2_ph: "Apt, unit or floor (Optional)",
    city_err: "City cannot be empty",
    city_ph: "City",
    country_err: "Country cannot be empty",
    country_ph: "United States",
    id: 1,
    state_err: "State cannot be empty",
    state_ph: "Select State",
    zip_err1: "Zip code cannot be empty",
    zip_err2: "Zip code must be 5 characters long",
    zip_err3: "Zip code must be 5 characters long",
    zip_ph: "ZIP Code",
  };
  interface formikintitaltypes {
    firstName: string;
    lastName: string;
    billingAddress: any;
  }
  const initialformstep1data: formikintitaltypes = {
    firstName: "",
    lastName: "",
    billingAddress: {
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      type: addressType.billing,
    },
  };
  const billingAddressVal = object({
    address1: string().trim().required(billing?.address1_err),
    city: string().trim().required(billing?.city_err),
    state: string().trim().required(billing?.state_err),
    zip: string()
      .trim()
      .required(billing?.zip_err1)
      .matches(/^[0-9]+$/, billing?.zip_err2)
      .min(5, billing?.zip_err3)
      .max(5, billing?.zip_err3),
  })
  const shipmingFormSchema = object({
    firstName: string().trim().required("First Name cannot be empty"),
    lastName: string().trim().required("Last Name cannot be empty"),
    billingAddress: billingAddressVal
  });
  const shipmingFormSchema2 = object({
    billingAddress: billingAddressVal
  });
  const formik: any = useFormik({
    initialValues: initialformstep1data,
    validationSchema: pageName !== "activation" ? shipmingFormSchema : shipmingFormSchema2,
    onSubmit: (values: any) => {
      updateAddress(formik.values);
    },
    enableReinitialize: true,
  });
  const handleBillingAddress = (value: any, name: string) => {
    formik.setFieldValue(`billingAddress.${name}`, value);
  };
  const handleAddressSearchSelection = async (
    address: any,
    addressObj: "billingAddress"
  ) => {
    const results = await geocodeByAddress(address);
    let city: string = "",
      state: string = "",
      zip: string = "",
      country: string = "",
      route: string = "",
      streetNumber: string = "";
    results &&
      results[0] &&
      results[0].address_components &&
      results[0].address_components.map((el: any) => {
        state = el.types.includes("administrative_area_level_1")
          ? el.short_name
          : state;
        zip = el.types.includes("postal_code") ? el.short_name : zip;
        city = el.types.includes("locality")
          ? el.long_name
          : el.types.includes("sublocality")
            ? el.long_name
            : city;
        streetNumber = el.types.includes("street_number")
          ? el.short_name
          : streetNumber;
        route = el.types.includes("route") ? el.long_name : route;
        country = el.types.includes("country") ? el.short_name : country;
        return null;
      });
    if (country === "US") {
      formik.setFieldValue(
        `${addressObj}.address1`,
        `${streetNumber} ${route}`
      );
      formik.setFieldValue(`${addressObj}.city`, city);
      formik.setFieldValue(`${addressObj}.state`, state);
      formik.setFieldValue(`${addressObj}.zip`, zip);
      formik.setFieldValue(`${addressObj}.country`, "USA");
      const touchedBilling: any = formik.touched.billingAddress;
      formik.setTouched({
        ...formik.touched,
        billingAddress: {
          ...touchedBilling,
          city: "",
          state: "",
          zip: "",
        },
      });
    }
  };

  return (
    <SimpleModal
      isShowModal={open}
      onCloseModal={onClose}
      showClose={true}
      sx={{ width: { xs: "95%", sm: "70%", md: "70%", lg: "40%", xl: "30%" } }}
      style={{ borderRadius: "4px" }}
    >
      <Box className={styles.container}>
        <Box
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            className={styles.title_main}
            sx={{
              fontSize: "22px",
              fontFamily: "var(--font_family_Bold)",
              lineHeight: 1,
            }}
          >
            Enter shipping address
          </Typography>
          <Box className={styles.ship_page_line}></Box>
        </Box>
        <Box>
          {pageName !== "activation" && (<Box className={styles.text_fields_main}>
            <Box className={styles.text_fields_div}>
              <TextFieldCust
                value={formik.values.firstName}
                name="firstName"
                label="First name"
                onChange={(e: any) => {
                  if (
                    namePattern.test(e.target.value || e.target.value === "")
                  ) {
                    formik.handleChange(e);
                  }
                }}
                helperText={
                  formik.touched?.firstName && formik.errors?.firstName
                }
                error={
                  formik.touched?.firstName && formik.errors?.firstName
                    ? true
                    : false
                }
              />
            </Box>
            <Box className={styles.text_fields_div}>
              <TextFieldCust
                value={formik.values.lastName}
                name="lastName"
                label="Last name"
                onChange={(e: any) => {
                  if (
                    namePattern.test(e.target.value || e.target.value === "")
                  ) {
                    formik.handleChange(e);
                  }
                }}
                helperText={formik.touched?.lastName && formik.errors?.lastName}
                error={
                  formik.touched?.lastName && formik.errors?.lastName
                    ? true
                    : false
                }
              />
            </Box>
          </Box>)}
          <Box>
            <AddressField
              content={billing}
              formik={formik}
              handleSelect={(e: any) =>
                handleAddressSearchSelection(e, "billingAddress")
              }
              handleAddressChange={handleBillingAddress}
              addressObjName="billingAddress"
            />
          </Box>
        </Box>
        <Box
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "24px 0 0 0",
          }}
        >
          <ButtonCust
            variantType={Config.SECONDARY_BUTTON_TYPE}
            onClick={() => formik.handleSubmit()}
          >
            Save
          </ButtonCust>
        </Box>
      </Box>
    </SimpleModal>
  );
}
