import { Box, CircularProgress, Typography } from "@mui/material";
import { ButtonCust, ButtonCust_T2, SimpleModal } from "../../widgets";
import { CancelInstallationProps } from "./CancelInstallation";
import EnvConfig from "../../../config/env.config.json";
import { useAppSelector } from "../../../app/hooks";
import { useNonInitialEffect } from "../../../utils/commonFunctions";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../config/RoutingURLs";

const ScheduleInstallation = (props: CancelInstallationProps) => {
  const navigate = useNavigate();
  const { cancelInstall, cancelInstallPending } = useAppSelector(
    (state: any) => state.fsm
  );
  const {
    openCancelModal,
    setOpenCancelModal,
    cancelInstallActivity,
    content,
    isFromFieldServices,
    activityInfo,
  } = props;

  const {
    title,
    desc,
    confirm,
    cancel,
    refund_desc,
    success_title,
    success_desc,
    okay,
    tick,
  } = content;

  useNonInitialEffect(() => {
    if (cancelInstall?.status === "SUCCESS") {
      closeModal();
      localStorage.setItem("installationStatus", "cancelled");
      isFromFieldServices ? null : navigate(RoutingURLs.installationTracker);
    }
  }, [cancelInstall]);

  const closeModal = () => {
    setOpenCancelModal(false);
  };

  const handleConfirm = () => {
    cancelInstallActivity();
  };

  // const successModal = () => {
  //   return (
  //     <SimpleModal
  //       isShowModal={openSuccessModal}
  //       onCloseModal={() => {
  //         setOpenSuccessModal(false);
  //       }}
  //       showClose={true}
  //     >
  //       <Typography
  //         sx={{
  //           fontSize: { xs: "16px", sm: "24px", md: "26px" },
  //           fontFamily: "var(--font_family_Bold)",
  //           color: "var(--text_color_8)",
  //           height: "61px",
  //           padding: "0 25px",
  //           display: "flex",
  //           alignItems: "center",
  //           boxSizing: "border-box",
  //         }}
  //       >
  //         {success_title}
  //       </Typography>
  //       <Typography
  //         sx={{
  //           fontSize: "16px !important",
  //           fontFamily: "var(--font_family_Semibold)",
  //           color: "var(--text_color_8)",
  //           lineHeight: "26px",
  //           display: "flex",
  //           alignItems: "center",
  //           textAlign: "center",
  //           //   boxSizing: "border-box",
  //         }}
  //       >
  //         {success_desc}
  //       </Typography>
  //       <Box sx={{ textAlign: "center", mt: "45px" }}>
  //         <ButtonCust
  //           variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
  //           onClick={() => setOpenSuccessModal(false)}
  //         >
  //           {okay}
  //         </ButtonCust>
  //       </Box>
  //     </SimpleModal>
  //   );
  // };

  return (
    <SimpleModal
      isShowModal={openCancelModal}
      onCloseModal={closeModal}
      showClose={true}
    >
      <Typography
        sx={{
          fontSize: { xs: "16px", sm: "24px", md: "26px" },
          fontFamily: "var(--font_family_Bold)",
          color: "var(--text_color_8)",
          height: "61px",
          padding: "0 25px",
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: "16px !important",
          fontFamily: "var(--font_family_Semibold)",
          color: "var(--text_color_8)",
          lineHeight: "26px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          //   boxSizing: "border-box",
        }}
      >
        {desc} {activityInfo?.amount > 0 ? refund_desc : ""}
      </Typography>
      <Box sx={{ textAlign: "center", mt: "45px" }}>
        <ButtonCust_T2
          variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
          onClick={handleConfirm}
          startIcon={cancelInstallPending ? <CircularProgress /> : confirm}
          sx={{ width: "180px" }}
        ></ButtonCust_T2>
      </Box>
      <Box sx={{ textAlign: "center", mt: "25px" }}>
        <Typography
          component={"span"}
          sx={{
            fontSize: "18px",
            lineHeight: "28px",
            color: "var(--primary_brand_color)",
            fontFamily: "var(--font_family_Semibold)",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => setOpenCancelModal(false)}
        >
          {cancel}
        </Typography>
      </Box>
    </SimpleModal>
  );
};

export default ScheduleInstallation;
