import { SelectCust } from "../../../widgets";
import styles from "./BuyDataDropDown.module.scss";
import { Grid } from "@mui/material";

type BuyDataProps = {
  group: any;
  selectedLine: any;
  setSelectedLine: any;
};
export const BuyDataDropDown = (props: BuyDataProps) => {
  const { group, selectedLine, setSelectedLine } = props;
  const options = group?.data?.map((el: any) => {
    if (el.status === 2) {
      return {
        label: {
          name: el?.firstName,
          phone: el.reachNumber,
        },
        value: el?.id,
      };
    }
  });

  return (
    <>
      <Grid width="90%" margin="0px auto">
        <SelectCust
          name="dataPlan"
          value={selectedLine}
          options={options}
          variant="standard"
          label="Select Line"
          onChange={(e: any) => {
            setSelectedLine(e.target.value);
          }}
          className={styles.container}
          secondary={true}
        />
      </Grid>
    </>
  );
};
