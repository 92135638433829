import { Box } from "@mui/material";
import { Divider } from "../../widgets";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";

export const T1_AppSection = (props: any) => {
  const { appSection } = props;
  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  return (
    appSection && (
      <Box
        sx={{
          p: {
            xs: "calc(var(--mobile_section_padding)/2) 0",
            sm: "calc(var(--ipad_section_padding)/2) 0",
            md: "calc(var(--desktop_section_padding)/2) 0",
          },
        }}
      >
        <Box py={1.2} sx={{ mt: { md: "40px" } }}>
          <h3 style={{ textAlign: "center" }}>{appSection?.title}</h3>
        </Box>
        <Divider />
        {(largeDesktop || desktop) &&
          generateImageContainer(
            {
              data: appSection?.image_web,
              attrs: { height: "100%", width: "100%" },
              overrides: { sx: { mb: "40px" } }
            }
          )}
        {ipad &&
          generateImageContainer(
            {
              data: appSection?.image_tab,
              attrs: { height: "100%", width: "100%" },
              overrides: { sx: { mb: "40px" } }
            })}
        {mobile &&
          generateImageContainer(
            {
              data: appSection?.image_mob,
              attrs: { height: "100%", width: "100%" },
              overrides: { sx: { mb: "40px" } }
            })}
      </Box>
    )
  );
};

export default T1_AppSection;
