import { useState, useEffect } from "react";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import Config from "../../config/app.config.json";
import { SimTypes } from "../../features/checkout/Types";
import { useAppSelector } from "../../app/hooks";

const ChangeSimPreferenceCmp = isTemplateExisits(
  Config.Checkout.ChangeSimPreference
)
  ? require(`./${Config.Checkout.ChangeSimPreference.template}_ChangeSimPreferenceDialog`)
      .default
  : null;

export interface ChangeSimPreferenceProps {
  open?: boolean;
  onClose: any;
  content: any;
  simPreference: Array<any>;
  onChangeSimPreferenceHandler?: any;
  eSimClicked?: boolean;
  showConfirm?: boolean;
  same?: boolean;
  simClicked?: boolean;
  confirmHandler?: any;
  changeSimSelectionHandler?: any;
  setSimPreference: any;
  sameArr?: any;
  setSameArr?: any;
  checkCompatibility: any;
  simCharges: number;
}
export const ChangeSimPreferenceDialog = (props: ChangeSimPreferenceProps) => {
  const {
    changeSimSelectionHandler,
    simPreference,
    setSimPreference,
    onClose,
    sameArr,
    setSameArr,
  } = props;
  const { planDetails } = useAppSelector((state: any) => state.checkout);
  const [same, setSame] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(true);
  const [eSimClicked, setESimClicked] = useState<boolean>(false);
  const [simClicked, setSimClicked] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    const isEsimSelected = simPreference.some(
      (data: any) => data.value === SimTypes.eSim
    );
    const isSimSelected = simPreference.some(
      (data: any) => data.value === SimTypes.sim
    );
    setESimClicked(isEsimSelected);
    setSimClicked(isSimSelected);
  }, [simPreference]);
  useEffect(() => {
    let length: number = Object.keys(sameArr).length;
    for (let i = 0; i < length; i++) {
      if (Object.values(sameArr)[i] === false) {
        setSame(false);
      }
    }
  }, [sameArr]);
  const onChangeSimPreferenceHandler = (e: any, _name: any, index: any) => {
    let simPreferenceStorage: any = localStorage.getItem("simPreference");
    simPreferenceStorage = JSON.parse(simPreferenceStorage);

    let obj: any = {};
    let localState: boolean = true;
    simPreferenceStorage.map((el: any) => {
      if (el.id === index) {
        if (el.value === e.target.value) obj = { [index]: true };
        else {
          if (e.target.value === SimTypes.sim) obj = { [index]: true };
          else obj = { [index]: false };
        }
      }
    });
    setSameArr({ ...sameArr, ...obj });
    setSame(localState);

    if (simPreferenceStorage[index].value !== e.target.value) {
      setShowConfirm(false);
    }
    [simPreference].forEach(() => {
      if (simPreferenceStorage[index] !== e.target.value) {
        setShowConfirm(false);
      }
      setSimPreference((prevState: any) =>
        prevState.map((data: any) => {
          if (data.id === index) {
            setCount(count + 1);
            return {
              ...data,
              eSimOpted: _name === SimTypes.eSim ? true : false,
              eSimCompatible: false,
              value: _name,
              imei:
                _name === SimTypes.eSim
                  ? simPreferenceStorage[index]?.imei
                  : "",
              make:
                _name === SimTypes.eSim
                  ? simPreferenceStorage[index]?.make
                  : "",
              model:
                _name === SimTypes.eSim
                  ? simPreferenceStorage[index]?.model
                  : "",
            };
          }
          return data;
        })
      );
    });
  };
  const confirmHandler = () => {
    let simPreferenceStorage: any = localStorage.getItem("simPreference");
    simPreferenceStorage = JSON.parse(simPreferenceStorage);
    localStorage.setItem("simPreference", JSON.stringify(simPreference));
    localStorage.setItem("simSelection", JSON.stringify({ esim: false }));
    changeSimSelectionHandler();
    onClose();
  };
  return (
    <ChangeSimPreferenceCmp
      onChangeSimPreferenceHandler={onChangeSimPreferenceHandler}
      eSimClicked={eSimClicked}
      same={same}
      showConfirm={showConfirm}
      simClicked={simClicked}
      confirmHandler={confirmHandler}
      {...props}
    />
  );
};
