import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";
import { version } from "../../config/RoutingURLs";

type InitialState = {
  pickupDetails: any;
  pickupDetailsPending: boolean;
  errPickupDetails: any;
  productInfo: any;
  productInfoPending: boolean;
  errProductInfo: any;
};

const initialState: InitialState = {
  pickupDetails: null,
  pickupDetailsPending: false,
  errPickupDetails: null,
  productInfo: null,
  productInfoPending: false,
  errProductInfo: null,
};

export const getPickupDetails = createAsyncThunk(
  "devices/getPickupDetails",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.pickupDetails}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProductInfo = createAsyncThunk(
  "devices/getProductInfo",
  async () => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.productInfo}`,
      });
      return response;
    } catch (err: any) {
      return err?.response?.data;
    }
  }
);

const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getPickupDetails.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.pickupDetails = action.payload.data;
        state.pickupDetailsPending = false;
        state.errPickupDetails = null;
      }
    );
    builder.addCase(
      getPickupDetails.pending,
      (state, action: PayloadAction<any>) => {
        state.pickupDetails = null;
        state.pickupDetailsPending = true;
        state.errPickupDetails = null;
      }
    );
    builder.addCase(getPickupDetails.rejected, (state, action) => {
      state.pickupDetails = null;
      state.pickupDetailsPending = false;
      state.errPickupDetails = action.error || "Something went wrong";
    });
    builder.addCase(
      getProductInfo.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.productInfo = action.payload.data;
        state.productInfoPending = false;
        state.errProductInfo = null;
      }
    );
    builder.addCase(
      getProductInfo.pending,
      (state, action: PayloadAction<any>) => {
        state.productInfo = null;
        state.productInfoPending = true;
        state.errProductInfo = null;
      }
    );
    builder.addCase(getProductInfo.rejected, (state, action) => {
      state.productInfo = null;
      state.productInfoPending = false;
      state.errProductInfo = action.error || "Something went wrong";
    });
  },
});

export default deviceSlice.reducer;
