import { Grid, Typography, Box, CircularProgress } from "@mui/material";
import styles from "./S2_DataDetails.module.scss";
import { dataDetailsProps } from "./DataDetails";
import {
  ButtonCust,
  CustToolTip_T2,
  TextFieldCust,
  SimpleModal,
  NotificationCust,
  ButtonCust_T2,
} from "../../../../components";
import EnvConfig from "../../../../config/env.config.json";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  getDeviceStatus,
  rebootDevice,
} from "../../../../features/account/accountSlice";
import {
  modemFormattedVal,
  pushTrackingEventsToGAAndFB,
  useNonInitialEffect,
} from "../../../../utils/commonFunctions";
import {
  GetCustomer,
  UpdateMacAddress,
} from "../../../../features/checkout/checkoutSlice";
import { getCustomerPlan } from "../../../../features/billing/billingSlice";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import { Link, useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { Store } from "react-notifications-component";
import {
  activateLine,
  activationStatusCheck,
} from "../../../../features/activation/activationSlice";
import { CustomerStatus } from "../../../../enums";
import { ReachPlan } from "../../../../features/trackorder/trackorderSlice";

const DataDetails = (props: dataDetailsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getCustomer, updateMacSuccess, updateMacFailure } = useAppSelector(
    (state: any) => state.checkout
  );
  const { deviceStatus, reboot, rebootLoader } = useAppSelector(
    (state: any) => state.account
  );
  const {
    activateLineStatus,
    activateLineError,
    activationStatus,
    errorActivationStatus,
  } = useAppSelector((state: any) => state?.activation || {});
  const { reachPlan } = useAppSelector((state: any) => state?.trackorder || {});
  const [changeModemModal, setChangeModemModal] = useState(false);
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [showActivationFailedModal, setShowActivationFailedModal] =
    useState<any>(false);
  const [loaderModal, setLoaderModal] = useState<any>(false);
  const [confirmedClicked, setConfirmedClicked] = useState(false);
  const [rebootModal, setRebootModal] = useState(false);
  const [iccId, setIccId] = useState("");
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [customerData, setCustomerData] = useState<any>(null);
  let intervalId: any;
  const {
    title,
    account_id,
    your_plan,
    pricing,
    device_status,
    reboot_text,
    mac_address,
    modem_image,
    bullet_points,
    online_logo,
    modal_title,
    input_label,
    confirm_btn,
    cancel_btn,
    edit_icon,
    label_icon,
    activate_desc,
    activate_title,
    activating,
    wait,
    enter_mac,
    button_text,
    activate,
    please_wait,
    loader_icon,
    please_wait_desc,
    offline_logo,
    reboot_modal_title,
    reboot_modal_desc,
    tooltip_title,
    tooltip_desc,
    download_text,
    upload_text,
    unlimited_text,
    speed_unit,
    list_points,
    activation_fail_title,
    cross,
    activation_fail_desc,
    chat_now,
    inactive_notification,
    activate_notify,
    book_service,
  } = props.content || {};

  let customerDetail: any = localStorage.getItem("customerDetail");
  customerDetail = JSON.parse(customerDetail);
  useEffect(() => {
    dispatch(
      getDeviceStatus({
        operator: "CABO",
        iccId: customerDetail?.iccId,
      })
    );
    dispatch(ReachPlan({ plan: customerDetail?.reachPlanId }));
    if (customerDetail?.status !== CustomerStatus.INACTIVE)
      dispatch(getCustomerPlan({ customerId: customerDetail?.id }));
  }, []);

  useEffect(() => {
    if (getCustomer?.status === 4) {
      if (getCustomer?.lastDisconnectedReason === 3)
        handleDisconnectNotification("manual");
      else handleDisconnectNotification();
    }

    return () => Store.removeNotification("DisconnectNotificationAccount");
  }, [getCustomer]);

  useNonInitialEffect(() => {
    if (getCustomer) {
      setConfirmedClicked(false);
      setChangeModemModal(false);
      setOpenActivateModal(false);
      setCustomerData(getCustomer);
      setIccId(getCustomer.iccId);
      if (getCustomer.iccId) getDevStatus(getCustomer.iccId);
    }
  }, [getCustomer]);

  useNonInitialEffect(() => {
    if (updateMacSuccess) {
      const formatIccid = iccId?.split(":").join("");
      let email: string =
        customerData && customerData.emailId ? customerData.emailId : "";
      email &&
        dispatch(
          GetCustomer({
            username: encodeURIComponent(email),
          })
        );
      dispatch(
        getDeviceStatus({
          operator: "CABO",
          iccId: formatIccid,
        })
      );
    }
  }, [updateMacSuccess]);

  const getDevStatus = (iddID?: any) => {
    intervalId = setInterval(() => {
      dispatch(
        getDeviceStatus({
          operator: "CABO",
          iccId: iddID,
        })
      );
    }, 30000);
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    handleNotifications();
  }, []);

  useNonInitialEffect(() => {
    if (updateMacFailure) {
      setConfirmedClicked(false);
    }
  }, [updateMacFailure]);

  useNonInitialEffect(() => {
    setRebootModal(false);
  }, [reboot]);

  useNonInitialEffect(() => {
    if (activateLineError) {
      setLoaderModal(false);
      setShowActivationFailedModal(true);
    }
  }, [activateLineError]);

  useNonInitialEffect(() => {
    if (activationStatus?.status === 2) {
      setLoaderModal(false);
      setTimeout(() => {
        handleActivationNotification();
      }, 1000);
    } else {
      let customerDetails: any = localStorage.getItem("customerDetail");
      customerDetails = JSON.parse(customerDetails);
      dispatch(
        activationStatusCheck({
          payload: { customerId: customerDetails?.id },
        })
      );
    }
  }, [activationStatus]);

  useNonInitialEffect(() => {
    if (
      activateLineStatus &&
      activateLineStatus?.code === 200 &&
      activateLineStatus?.msg === "Activation In Progress"
    ) {
      let customerDetails: any = localStorage.getItem("customerDetail");
      customerDetails = JSON.parse(customerDetails);
      dispatch(
        activationStatusCheck({
          payload: { customerId: customerDetails?.id },
        })
      );
    }
    // else {
    //   setTimeout(() => {
    //     handleActivationNotification();
    //   }, 1000);
    // }
  }, [activateLineStatus]);

  const handleNotifications = () => {
    let message = "",
      type = "";

    if (getCustomer?.status === CustomerStatus.INACTIVE) {
      message = inactive_notification;
      type = "info";
    }
    Store.removeAllNotifications();
    return message !== ""
      ? NotificationCust({
          message: (
            <Typography
              sx={{
                color: "var(--text_color_11) !important",
                fontFamily: "var(--font_family_Medium) !important",
                fontSize: "16px",
                paddingTop: "50px",
              }}
            >
              {message}
            </Typography>
          ),
          duration: 0,
          id: "activationSuccessNotification",
          type: type,
        })
      : null;
  };
  const rebootHandler = () => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.account,
      category: gaCategory.reboot,
      buttonValue: confirm_btn,
    });
    const formatIccid = iccId?.split(":").join("");
    dispatch(
      rebootDevice({
        operator: "CABO",
        iccId: formatIccid,
      })
    );
  };

  const toolTipData = () => {
    return (
      <>
        <Typography
          variant="subtitle2"
          color={"var(--primary_brand_color)"}
          fontSize={"17px"}
          fontFamily={"var(--font_family_Semibold)"}
          padding={"10px"}
          borderTop={"4px solid var(--primary_brand_color)"}
          borderBottom={"1px solid rgba(118,118,118,0.7)"}
        >
          {tooltip_title}
        </Typography>
        <Typography
          variant="body2"
          color={"var(--text_color_8)"}
          fontSize={"15px"}
          fontFamily={"var(--font_family_Semibold)"}
          padding={"10px"}
        >
          {tooltip_desc}
        </Typography>
      </>
    );
  };

  const onChangeHandler = (e: any) => {
    // setIccId(e.target.value);
    setIccId(e.target.value.toUpperCase());
  };

  const confirmHandler = () => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.account,
      category: gaCategory.changeModem,
      buttonValue: confirm_btn,
    });
    const formatIccid = iccId?.split(":").join("");
    dispatch(
      UpdateMacAddress({
        customerId: customerData.id,
        iccId: formatIccid,
      })
    );
    setConfirmedClicked(true);
    setIsEditClicked(false);
  };

  const list = [
    list_points[0]?.desc,
    `${download_text} ${reachPlan?.data?.data?.dlspeed} ${speed_unit}`,
    `${upload_text} ${reachPlan?.data?.data?.ulspeed} ${speed_unit}`,
    list_points[3]?.desc,
  ];

  const handleActivationNotification = () => {
    let message = activate_notify;
    Store.removeAllNotifications();

    return NotificationCust({
      message: (
        <Typography
          sx={{
            color: "var(--text_color_11) !important",
            fontFamily: "var(--font_family_Medium) !important",
            fontSize: "16px",
            paddingTop: "50px",
          }}
        >
          {message}
        </Typography>
      ),
      duration: 0,
      id: "activationSuccessNotification",
      type: "success",
    });
  };
  const handleDisconnectNotification = (disconnectReason?: string) => {
    let custAdd: any = [];
    for (const key in getCustomer?.addresses[1]) {
      if (key !== "name" && key !== "type" && key !== "phone")
        custAdd.push(getCustomer?.addresses[1][key]);
    }
    custAdd = custAdd.join(", ");
    let message =
      disconnectReason === "manual" ? (
        <Typography
          sx={{
            color: "var(--text_color_11) !important",
            fontFamily: "var(--font_family_Medium) !important",
            fontSize: "16px",
            marginTop: "50px",
          }}
        >
          As requested, your service has been disconnected. Please{" "}
          <Link
            to={`${EnvConfig.SUBMIT_TICKET_URL}?firstname=${getCustomer?.firstName}&lastname=${getCustomer?.lastName}&address=${custAdd}&reach_number=${getCustomer?.primaryNumber}&email=${getCustomer?.emailId}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "var(--primary_color)" }}
          >
            Raise a Ticket
          </Link>
          &nbsp; if you feel this is a mistake.
        </Typography>
      ) : (
        <Typography
          sx={{
            color: "var(--text_color_11) !important",
            fontFamily: "var(--font_family_Medium) !important",
            fontSize: "16px",
            marginTop: "50px",
          }}
        >
          Your service has been disconnected due to nonpayment of dues. Please
          purchase a plan and &nbsp;
          <Link to={RoutingURLs.billing} state={{ showPayBillModal: true }}>
            Pay Bill
          </Link>
          &nbsp; to resume service.
        </Typography>
      );
    Store.removeAllNotifications();

    return NotificationCust({
      message: message,
      duration: 0,
      id: "DisconnectNotificationAccount",
      type: "danger",
    });
  };

  const GAAndFBEventsHandler = (categoryName: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.activation,
      category: categoryName,
      buttonValue: value,
    });
  };

  const handleActivation = () => {
    GAAndFBEventsHandler(gaCategory.activation, activate);
    setOpenActivateModal(false);
    setShowActivationFailedModal(false);
    setLoaderModal(true);

    let customerDetails: any = localStorage.getItem("customerDetail");
    customerDetails = JSON.parse(customerDetails);
    const formatIccid = iccId.split(":").join("");

    const payload = {
      customerId: customerDetails.id,
      reachPlanId: customerDetails.reachPlanId,
      iccId: formatIccid,
    };
    dispatch(activateLine({ payload: payload }));
  };

  return (
    <>
      {props.content && (
        <Box className={styles.caboAccContainer}>
          <Grid className={styles.caboGrid}>
            <Box sx={{ display: "flex", gap: { xs: "60px", sm: "120px" } }}>
              <Typography className={styles.heading}>{title}</Typography>
              <Box className={styles.idContainer}>
                {account_id}{" "}
                <Typography component="span">
                  {customerDetail?.accountNumber}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ float: "right" }}>
              <ButtonCust_T2
                variantType="primary_contained_square"
                sx={{
                  width: "200px",
                  height: "40px",
                  fontSize: "20px !important",
                  cursor: "pointer",
                  margin: "auto",
                  color: "var(--white)",
                  textDecoration: "none",
                }}
                onClick={() => {
                  navigate(RoutingURLs.fieldServices);
                  localStorage.setItem("showBookServiceModal", "true");
                }}
              >
                {book_service}
              </ButtonCust_T2>
            </Box>
          </Grid>
          <Grid className={styles.boxContainer}>
            {/* Plan Details */}
            <Box className={styles.detailBox}>
              <Box className={styles.innerBox}>
                <Typography component="div" className={styles.innerContainer}>
                  <Typography component="div" className={styles.boxHeading}>
                    {your_plan}
                  </Typography>
                </Typography>
                <Typography component="div" className={styles.detailsCont}>
                  {
                    <Typography
                      component="div"
                      className={styles.detailsHeadCont}
                    >
                      <Typography
                        component="div"
                        className={styles.detailsHead}
                      >
                        {reachPlan?.data?.data?.name}
                      </Typography>
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "18px",
                          fontFamily: "var(--font_family_Bold)",
                        }}
                      >
                        ${reachPlan?.data?.data?.baseLinePrice}
                        <Typography
                          component="span"
                          sx={{
                            fontSize: "14px",
                            fontFamily: "var(--font_family_Medium)",
                          }}
                        >
                          {pricing}
                        </Typography>
                      </Typography>
                    </Typography>
                  }
                  <Typography
                    component="div"
                    className={styles.hLine}
                  ></Typography>
                  <Typography component="div" className={styles.info}>
                    {list.map((el: any, id: any) => (
                      <Typography component="div" key={id}>
                        <Box
                          component="img"
                          src={bullet_points?.data.attributes.url}
                          alt={bullet_points?.data.attributes.alternativeText}
                        />
                        {el}
                      </Typography>
                    ))}
                  </Typography>
                </Typography>
              </Box>
            </Box>
            {/* Modem Details */}
            <Box className={styles.detailBox}>
              <Box className={styles.innerBox}>
                <Typography component="div" className={styles.innerContainer}>
                  <Typography component="div" className={styles.boxHeading}>
                    {device_status}
                    <Typography
                      component="span"
                      className={styles.onlineStatus}
                    >
                      <Box
                        component="img"
                        src={
                          deviceStatus?.data.online
                            ? online_logo?.data.attributes.url
                            : offline_logo?.data.attributes.url
                        }
                        alt={
                          deviceStatus?.data.online
                            ? online_logo?.data.attributes.alternativeText
                            : offline_logo?.data.attributes.alternativeText
                        }
                      />
                      <Typography
                        component="span"
                        style={{
                          color: deviceStatus?.data.online
                            ? "#7ac666"
                            : "#EF4E3E",
                        }}
                      >
                        {deviceStatus?.data.online ? `Online` : `Offline`}
                      </Typography>
                    </Typography>
                  </Typography>
                  <Typography
                    component="div"
                    className={styles.rebootText}
                    onClick={() => setRebootModal(true)}
                  >
                    {reboot_text}
                  </Typography>
                </Typography>
                <Typography component="div" className={styles.detailsCont}>
                  <Typography
                    component="div"
                    className={styles.detailsHeadCont}
                  ></Typography>
                  <Typography component="div" className={styles.modemDetails}>
                    <Box
                      component="img"
                      src={modem_image?.data?.attributes?.url}
                      alt={modem_image?.data.attributes.alternativeText}
                    />
                    {customerData && (
                      <Typography component="div" className={styles.macAdd}>
                        {mac_address}
                        <Typography component="span">{":"}</Typography>
                        <Typography
                          component="span"
                          className={styles.macAddress}
                        >
                          {customerData?.iccId
                            ? customerData?.iccId?.match(/.{1,2}/g).join(":")
                            : ` - `}
                        </Typography>
                      </Typography>
                    )}
                    <ButtonCust
                      className={styles.changeModemBtn}
                      variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                      onClick={() => {
                        customerData?.iccId
                          ? setChangeModemModal(true)
                          : setOpenActivateModal(true);
                      }}
                    >
                      {customerData?.iccId ? button_text : activate}
                    </ButtonCust>
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* Change Modem Modal */}
          <SimpleModal
            isShowModal={changeModemModal}
            onCloseModal={() => {
              setChangeModemModal(false);
              setIsEditClicked(false);
              setIccId(getCustomer.iccId);
            }}
            showClose={true}
            sx={{
              width: {
                xs: "95%",
                sm: "70%",
                md: "80%",
                lg: "45%",
                xl: "30%",
                padding: "30px",
              },
            }}
            style={{ borderRadius: "4px" }}
          >
            <Box className={styles.modalContainer}>
              <Typography className={styles.modalTitle}>
                {modal_title}
              </Typography>
              {confirmedClicked ? (
                <Box className={styles.loadingBox}>
                  <Box
                    component="img"
                    src={loader_icon?.data.attributes.url}
                    alt={loader_icon?.data.attributes.alternativeText}
                  />
                  <Typography sx={{ margin: "15px 0px 26px 0px" }}>
                    {please_wait}
                  </Typography>
                  <Typography>{please_wait_desc}</Typography>
                </Box>
              ) : (
                <>
                  <Box className={styles.textFieldCont}>
                    <Typography className={styles.label}>
                      {input_label}
                      <CustToolTip_T2 title={toolTipData()} arrow>
                        <Box
                          component="img"
                          src={label_icon.data.attributes.url || ""}
                          alt={label_icon?.data.attributes.alternativeText}
                        />
                      </CustToolTip_T2>
                    </Typography>
                    <Typography component="div" className={styles.textField}>
                      <TextFieldCust
                        // value={iccId ? iccId : ""}
                        value={modemFormattedVal(iccId?.toString())}
                        name="macAddress"
                        maxlength={17}
                        // label={iccId ? iccId : "MAC Address"}
                        label={"MAC Address"}
                        onChange={(e: any) => onChangeHandler(e)}
                        className={styles.acc_modal_textfield}
                        sx={{ width: "320px" }}
                        disabled={isEditClicked ? false : true}
                      />
                      {!isEditClicked && (
                        <Box
                          component="img"
                          src={edit_icon?.data.attributes.url}
                          alt={edit_icon?.data.attributes.alternativeText}
                          onClick={() => {
                            setIsEditClicked(true);
                          }}
                        />
                      )}
                    </Typography>
                  </Box>
                  <ButtonCust
                    className={styles.confirmBtn}
                    variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                    onClick={confirmHandler}
                    disabled={isEditClicked ? false : true}
                  >
                    {confirm_btn}
                  </ButtonCust>
                  <Typography
                    onClick={() => {
                      setChangeModemModal(false);
                      setIsEditClicked(false);
                    }}
                    className={styles.cancel}
                  >
                    {cancel_btn}
                  </Typography>
                </>
              )}
            </Box>
          </SimpleModal>
          {/* Reboot Modal */}
          <SimpleModal
            isShowModal={rebootModal}
            onCloseModal={() => setRebootModal(false)}
            showClose={true}
            sx={{
              width: {
                xs: "95%",
                sm: "70%",
                md: "80%",
                lg: "45%",
                xl: "30%",
                padding: "30px",
              },
            }}
            style={{ borderRadius: "4px" }}
          >
            <Box className={styles.modalContainer}>
              <Typography className={styles.modalTitle}>
                {reboot_modal_title}
              </Typography>
              <Box className={styles.rebootModalCont}>
                <Typography className={styles.rebootDesc}>
                  {reboot_modal_desc}
                </Typography>
              </Box>
              <ButtonCust
                className={styles.confirmBtn}
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                onClick={rebootHandler}
                startIcon={rebootLoader ? <CircularProgress /> : reboot_text}
              ></ButtonCust>
              <Typography
                onClick={() => setRebootModal(false)}
                className={styles.cancel}
              >
                {cancel_btn}
              </Typography>
            </Box>
          </SimpleModal>
          {/* Activate Modem Modal */}
          <SimpleModal
            isShowModal={openActivateModal}
            onCloseModal={() => setOpenActivateModal(false)}
            showClose={true}
            sx={{
              width: {
                xs: "90% !important",
                sm: "70% !important",
                md: "45% !important",
              },
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "25px",
              }}
            >
              <Box
                sx={{
                  color: "var(--text_color_8)",
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: "26px",
                }}
              >
                {activate_title}
              </Box>
              <Typography
                sx={{
                  color: "var(--text_color)",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: { xs: "14px", sm: "16px" },
                  margin: { xs: "auto", sm: "auto 20px" },
                }}
              >
                {activate_desc}
              </Typography>
              <Box
                sx={{
                  width: { xs: "90%", sm: "80%", md: "60%" },
                  margin: "auto",
                }}
              >
                <Box
                  sx={{
                    textAlign: "left",
                    marginBottom: "15px",
                    fontFamily: "var(--font_family_Semibold)",
                  }}
                >
                  {enter_mac}{" "}
                  <CustToolTip_T2 title={toolTipData()} arrow>
                    <Box
                      component="img"
                      src={label_icon.data.attributes.url || ""}
                      alt={label_icon?.data.attributes.alternativeText}
                    />
                  </CustToolTip_T2>
                </Box>
                <TextFieldCust
                  value={modemFormattedVal(iccId?.toString())}
                  name="macAddress"
                  maxlength={17}
                  label={mac_address}
                  onChange={(e: any) => onChangeHandler(e)}
                  className={styles.textField}
                  onBlur={(e) => null}
                />
              </Box>
              <ButtonCust_T2
                variantType={"primary_contained_square"}
                sx={{ width: "180px", margin: "auto" }}
                disabled={!iccId}
                onClick={handleActivation}
              >
                {confirm_btn}
              </ButtonCust_T2>
              <Typography
                sx={{
                  color: "var(--primary_color)",
                  textDecoration: "underline",
                  fontFamily: "var(--font_family_Semibold)",
                }}
                onClick={() => setOpenActivateModal(false)}
              >
                {cancel_btn}
              </Typography>
            </Box>
          </SimpleModal>
          {/* Loader Modal */}
          <SimpleModal
            isShowModal={loaderModal}
            onCloseModal={null}
            showClose={false}
            sx={{
              width: { xs: "50%", sm: "20%", md: "10%" },
              backgroundColor: "var(--white)",
              display: "flex",
              flexDirection: "column",
              gap: "25px",
            }}
          >
            <Box sx={{ fontFamily: "var(--font_family_Bold)" }}>
              {activating}
            </Box>
            <Box
              component="img"
              src={loader_icon?.data.attributes.url}
              alt={loader_icon?.data.attributes.alternativeText}
            />
            <Box className={styles.loader_status}>{wait}</Box>
          </SimpleModal>
          {/* Activation Fail Modal */}
          <SimpleModal
            isShowModal={showActivationFailedModal}
            onCloseModal={() => setShowActivationFailedModal(false)}
            showClose={true}
            className={styles.act_fail_modal_wrapper}
          >
            <Box
              sx={{
                textAlign: "center",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: { sm: "16px", md: "26px" },
                  color: "var(--text_color_8)",
                }}
              >
                {activation_fail_title}
              </Typography>
              <Box
                component="img"
                alt="cross_icon"
                src={cross.data.attributes.url || ""}
                sx={{ width: "40px", margin: "auto" }}
              ></Box>
              <Box
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: "16px",
                  color: "var(--text_color_8)",
                }}
              >
                {activation_fail_desc}
              </Box>
              <ButtonCust_T2
                variantType={"primary_contained_square"}
                sx={{ width: "200px", margin: "auto" }}
                onClick={() => setShowActivationFailedModal(false)}
              >
                <a
                  href="#hs-chat-open"
                  rel="noopener noreferrer"
                  style={{ color: "var(--white)", textDecoration: "none" }}
                >
                  {chat_now}
                </a>
              </ButtonCust_T2>
            </Box>
          </SimpleModal>
        </Box>
      )}
    </>
  );
};

export default DataDetails;
