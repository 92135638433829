import { Box } from "@mui/material";
import { usaState, countryList } from "../../../config/USAStateList";
import {
  AutoComplete,
  TextFieldCust,
  SelectCust,
  AutoComplete_T3,
} from "../../widgets";
import EnvConfig from "../../../config/env.config.json";
import styles from "./AddressField.module.scss";

type Props = {
  handleSelect?: any;
  formik: any;
  handleAddressChange?: any;
  addressObjName: string;
  content?: any;
  onBlurCallback?: any;
};

export const AddressField = (props: Props) => {
  const {
    formik,
    addressObjName,
    handleSelect,
    handleAddressChange,
    content,
    onBlurCallback,
  } = props;
  const { billing_name_div, text_fields_main, text_fields_div } = styles;

  const customerAddressObj: any = JSON.parse(
    localStorage.getItem("CustomerAddressObj") || "{}"
  );

  return EnvConfig.BROADBAND && addressObjName === "shippingAddress" ? (
    <Box className={billing_name_div}>
      <Box className={text_fields_main}>
        <Box className={text_fields_div}>
          <TextFieldCust
            disabled
            name="address1"
            value={
              formik.values.shippingAddress?.address1 ||
              customerAddressObj?.address1
            }
            onBlur={(e) => onBlurCallback(e, addressObjName)}
            onChange={(e: any) => {
              handleAddressChange(e.target.value, "address1");
            }}
          />
        </Box>
        <Box className={text_fields_div}>
          <TextFieldCust
            disabled
            name="address2"
            value={formik.values.shippingAddress?.address2 || ""}
            onBlur={(e) => onBlurCallback(e, addressObjName)}
            onChange={(e: any) => {
              handleAddressChange(e.target.value, "address2");
            }}
          />
        </Box>
      </Box>
      <Box className={text_fields_main}>
        <Box className={text_fields_div}>
          <TextFieldCust
            disabled
            name="city"
            value={
              formik.values.shippingAddress?.city || customerAddressObj?.city
            }
            onChange={(e: any) => {
              handleAddressChange(e.target.value, "city");
            }}
            helperText={
              formik.touched[addressObjName]?.city &&
              formik.errors[addressObjName]?.city
            }
            error={
              formik.touched[addressObjName]?.city &&
              formik.errors[addressObjName]?.city
                ? true
                : false
            }
            onBlur={(e) => onBlurCallback(e, addressObjName)}
          />
        </Box>
        <Box className={text_fields_div}>
          <TextFieldCust
            disabled
            name="state"
            value={
              formik.values.shippingAddress?.state || customerAddressObj?.state
            }
            onChange={(e: any) => {
              handleAddressChange(e.target.value, "state");
            }}
            helperText={
              formik.touched[addressObjName]?.state &&
              formik.errors[addressObjName]?.state
            }
            error={
              formik.touched[addressObjName]?.state &&
              formik.errors[addressObjName]?.state
                ? true
                : false
            }
            onBlur={(e) => onBlurCallback(e, addressObjName)}
          />
        </Box>
      </Box>
      <Box className={text_fields_main}>
        <Box className={text_fields_div}>
          <TextFieldCust
            disabled
            value={
              formik.values.shippingAddress?.zip || customerAddressObj?.zip
            }
            name="zip"
            maxlength={5}
            onChange={(e: any) => {
              if (/^[0-9]+$/.test(e.target.value) || e.target.value === "") {
                handleAddressChange(e.target.value, "zip");
              }
            }}
            helperText={
              formik.touched[addressObjName]?.zip &&
              formik.errors[addressObjName]?.zip
            }
            error={
              formik.touched[addressObjName]?.zip &&
              formik.errors[addressObjName]?.zip
                ? true
                : false
            }
            onBlur={(e) => onBlurCallback(e, addressObjName)}
          />
        </Box>
        <Box className={text_fields_div}>
          <TextFieldCust
            disabled
            value={
              formik.values.shippingAddress?.country ||
              customerAddressObj?.country
            }
            onChange={() => null}
            helperText={
              formik.touched[addressObjName]?.country &&
              formik.errors[addressObjName]?.country
            }
            error={
              formik.touched[addressObjName]?.country &&
              formik.errors[addressObjName]?.country
                ? true
                : false
            }
            onBlur={(e) => onBlurCallback(e, addressObjName)}
          />
        </Box>
      </Box>
    </Box>
  ) : (
    <Box className={billing_name_div}>
      <Box className={text_fields_main}>
        <Box className={text_fields_div}>
          {!EnvConfig.BROADBAND ? (
            <AutoComplete
              value={formik.values[addressObjName].address1}
              onChange={(e: any) => {
                handleAddressChange(e, "address1");
              }}
              onSelect={(e: any) => {
                handleSelect(e);
              }}
              autoComplete={"off"}
              placeholder={content?.address1_ph}
              helperText={
                formik.touched[addressObjName]?.address1 &&
                formik.errors[addressObjName]?.address1
              }
              error={
                formik.touched[addressObjName]?.address1 &&
                formik.errors[addressObjName]?.address1
                  ? true
                  : false
              }
            />
          ) : (
            <Box>
              <AutoComplete_T3
                value={formik.values[addressObjName].address1}
                onChange={(e: any) => {
                  handleAddressChange(e, "address1");
                }}
                placeholder={content?.address1_ph}
                fromCheckout
                fromProfile
                helperText={
                  formik.touched[addressObjName]?.address1 &&
                  formik.errors[addressObjName]?.address1
                }
                error={
                  formik.touched[addressObjName]?.address1 &&
                  formik.errors[addressObjName]?.address1
                    ? true
                    : false
                }
              />
            </Box>
          )}
        </Box>
        <Box className={text_fields_div}>
          <TextFieldCust
            name="address2"
            value={formik.values[addressObjName].address2}
            onBlur={(e) => onBlurCallback(e, addressObjName)}
            placeholder={content?.address2_ph}
            onChange={(e: any) => {
              handleAddressChange(e.target.value, "address2");
            }}
          />
        </Box>
      </Box>
      <Box className={text_fields_main}>
        <Box className={text_fields_div}>
          <TextFieldCust
            name="city"
            value={formik.values[addressObjName].city}
            placeholder={content?.city_ph}
            onChange={(e: any) => {
              handleAddressChange(e.target.value, "city");
            }}
            disabled={EnvConfig.DISABLE_ADDRESS_FIELDS_CHECKOUT}
            helperText={
              formik.touched[addressObjName]?.city &&
              formik.errors[addressObjName]?.city
            }
            error={
              formik.touched[addressObjName]?.city &&
              formik.errors[addressObjName]?.city
                ? true
                : false
            }
            onBlur={(e) => onBlurCallback(e, addressObjName)}
          />
        </Box>
        <Box className={text_fields_div}>
          {!EnvConfig.BROADBAND ? (
            <SelectCust
              name="state"
              value={formik.values[addressObjName].state}
              options={usaState}
              placeholder={content?.state_ph}
              disabled={EnvConfig.DISABLE_ADDRESS_FIELDS_CHECKOUT}
              onChange={(e: any) => {
                handleAddressChange(e.target.value, "state");
              }}
              helperText={
                formik.touched[addressObjName]?.state &&
                formik.errors[addressObjName]?.state
              }
              error={
                formik.touched[addressObjName]?.state &&
                formik.errors[addressObjName]?.state
                  ? true
                  : false
              }
              onBlur={(e) => onBlurCallback(e, addressObjName)}
            />
          ) : (
            <TextFieldCust
              value={formik.values[addressObjName].state}
              name="state"
              placeholder={content?.state_ph}
              disabled={true}
              helperText={
                formik.touched[addressObjName]?.state &&
                formik.errors[addressObjName]?.state
              }
              error={
                formik.touched[addressObjName]?.state &&
                formik.errors[addressObjName]?.state
                  ? true
                  : false
              }
            />
          )}
        </Box>
      </Box>
      <Box className={text_fields_main}>
        <Box className={text_fields_div}>
          <TextFieldCust
            value={formik.values[addressObjName].zip?.substring(0, 5)}
            name="zip"
            placeholder={content?.zip_ph}
            disabled={EnvConfig.DISABLE_ADDRESS_FIELDS_CHECKOUT}
            maxlength={5}
            onChange={(e: any) => {
              if (/^[0-9]+$/.test(e.target.value) || e.target.value === "") {
                handleAddressChange(e.target.value, "zip");
              }
            }}
            helperText={
              formik.touched[addressObjName]?.zip &&
              formik.errors[addressObjName]?.zip
            }
            error={
              formik.touched[addressObjName]?.zip &&
              formik.errors[addressObjName]?.zip
                ? true
                : false
            }
            onBlur={(e) => onBlurCallback(e, addressObjName)}
          />
        </Box>
        <Box className={text_fields_div}>
          {!EnvConfig.BROADBAND ? (
            <SelectCust
              disabled
              value={formik.values[addressObjName].country}
              options={countryList}
              placeholder={content?.country_ph}
              onChange={() => null}
              helperText={
                formik.touched[addressObjName]?.country &&
                formik.errors[addressObjName]?.country
              }
              error={
                formik.touched[addressObjName]?.country &&
                formik.errors[addressObjName]?.country
                  ? true
                  : false
              }
              onBlur={(e) => onBlurCallback(e, addressObjName)}
              className={styles.container}
            />
          ) : (
            <TextFieldCust
              value={formik.values[addressObjName].country}
              placeholder={content?.country_ph}
              disabled={true}
              helperText={
                formik.touched[addressObjName]?.country &&
                formik.errors[addressObjName]?.country
              }
              error={
                formik.touched[addressObjName]?.country &&
                formik.errors[addressObjName]?.country
                  ? true
                  : false
              }
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
