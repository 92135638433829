import styles from "./S1_TrackOrder.module.scss";
import { useNavigate } from "react-router-dom";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { ButtonCust, StepperCust } from "../widgets";
import Config from "../../config/env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import {
  StepperStatus,
  OrderStatus,
} from "../../features/trackorder/trackOrder";
import { CustomerStatus } from "../../enums";
import EnvConfig from "../../config/env.config.json";
import { useAppSelector } from "../../app/hooks";
import { RoutingURLs } from "../../config/RoutingURLs";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const T1_TrackOrder = (props: any) => {
  const navigate = useNavigate();
  const { content, trackOrderData, planData, customerData } = props;
  const GAAndFBEventsHandler = () => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.trackOrder,
      category: gaCategory.activation,
      buttonValue: content.act_now,
    });
  };

  const { trackOrderStatus } = useAppSelector(
    (state: any) => state?.trackorder || {}
  );
  const onClickHandler = (navigationPath: string, redirectionPage: string) => {
    if (redirectionPage === "_self") {
      navigate(navigationPath);
    } else {
      window.open(navigationPath, redirectionPage);
    }
    GAAndFBEventsHandler();
  };
 
  const getSteps = () => {
    if (trackOrderData.cId && customerData.id) {
      let time = new Date(trackOrderData.orderDate);
      let time1 = time.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      let labeledTime: any = trackOrderData.labelledOn
        ? new Date(trackOrderData.labelledOn)
        : "";
      let labeled1: any = labeledTime
        ? labeledTime.toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
        : "";

      let dateDelivery: any = trackOrderData.deliveredOn
        ? new Date(trackOrderData.deliveredOn)
        : trackOrderData.estimatedDeliveryDate
        ? new Date(trackOrderData.estimatedDeliveryDate)
        : "";
      let dateDelivery1: any = dateDelivery
        ? dateDelivery.toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
        : "";

      let shippedTime: any = trackOrderData.shippedOn
        ? new Date(trackOrderData.shippedOn)
        : "";
      let shippedTime1: any = shippedTime
        ? shippedTime.toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
        : "";
      let cancelledTime: any = trackOrderData.orderCanceledOn
        ? new Date(trackOrderData.orderCanceledOn)
        : "";
      let cancelledTime1: any = cancelledTime
        ? cancelledTime.toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
        : "";
      let stepperObj = [
        {
          label: StepperStatus.OrderReceived,
          text: `${time1}${"\n"} ${
            months[time.getMonth()]
          } ${time.getDate()}, ${time.getFullYear()} `,
          time: time1,
          date: `${time.getDate()} ${
            months[time.getMonth()]
          }, ${time.getFullYear()} `,
          simcards: customerData.extraLines + 1,
          simCardLabel: `${content.sim_cards_text}`,
          keyProp: 0,
        },
        {
          label: StepperStatus.InProgress,
          text:
            labeledTime && labeled1
              ? `${
                  content.in_pro_text
                }${"\n"} ${labeled1}${"\n"} ${labeledTime.getDate()} ${
                  months[labeledTime.getMonth()]
                }, ${labeledTime.getFullYear()}`
              : `${content.in_pro_text}`,
          simCardLabel: null,
          simcards: null,
          keyProp: 1,
        },
        {
          label: StepperStatus.OrderShipped,
          text:
            shippedTime && shippedTime1
              ? `${
                  content.order_sh_text
                }${"\n"} ${shippedTime1}${"\n"} ${shippedTime.getDate()} ${
                  months[shippedTime.getMonth()]
                }, ${shippedTime.getFullYear()}`
              : `${content.order_sh_text}`,
          simCardLabel: null,
          simcards: null,
          keyProp: 2,
        },
        {
          label: StepperStatus.DELIVERED,
          text: `${dateDelivery1}${"\n"} ${
            months[dateDelivery.getMonth()]
          } ${dateDelivery.getDate()}, ${dateDelivery.getFullYear()}`,
          simCardLabel: null,
          simcards: null,
          keyProp: 3,
        },
      ];
      let finalStepperObj: any[] = [];
      if (trackOrderData.orderStatus === OrderStatus.Cancelled) {
        const cancelledObj = [
          {
            label: StepperStatus.Cancelled,
            text:
              cancelledTime && cancelledTime1
                ? `${
                    content.cancel_txt
                  }${"\n"} ${cancelledTime1}${"\n"} ${cancelledTime.getDate()} ${
                    months[cancelledTime.getMonth()]
                  }, ${cancelledTime.getFullYear()}`
                : `${content.cancel_txt}`,
            simCardLabel: null,
            simcards: null,
            keyProp: 4,
          },
        ];
        if (trackOrderData.lastOrderStatus === OrderStatus.Ordered) {
          finalStepperObj.push(stepperObj[0], ...cancelledObj);
        }
      } else {
        finalStepperObj = [...stepperObj];
      }
      return finalStepperObj;
    } else {
      return [{ label: StepperStatus.OrderReceived }, {}, {}, {}];
    }
  };

  let activeStep: number = 0;
  if (trackOrderData && trackOrderData.cId) {
    activeStep =
      trackOrderData.orderStatus === OrderStatus.Ordered
        ? 0
        : trackOrderData.orderStatus === OrderStatus.Labelled ||
          trackOrderData.orderStatus === OrderStatus.Cancelled
        ? 1
        : trackOrderData.orderStatus === OrderStatus.Shipped
        ? 2
        : trackOrderData.orderStatus === OrderStatus.Delivered
        ? 3
        : 0;
  }
  return (
    <Box>
      <Box
        className={styles.container}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          background: {
            xs: "linear-gradient( var(--white) 97%, var(--success) 0%)",
            sm:
              "linear-gradient( var(--lightgrey_3) 43.5%, var(--success) 43%)",
          },
          width: "100%",
        }}
      >
        <Paper
          sx={{
            padding: { sm: "20px 0" },
            margin: {
              xs: "25px 0 50px 0",
              sm: "50px 0",
            },
            boxShadow: {
              xs: "unset",
              sm: "0 0 4px 0 rgb(0 0 0 / 25%)",
            },
            background: "#fff",
            minWidth: { xs: "10%", sm: "70%", md: "50%" },
            maxWidth: { sm: "min-content" },
            height: "fit-content",
            textAlign: "center",
          }}
        >
          <Box textAlign={{ xs: "center" }}>
            <Typography
              component={"h5"}
              variant={"h5"}
              fontSize="18px"
              fontFamily={"var(--font_family_Bold)"}
              pb={{ xs: "25px", sm: "20px" }}
              borderBottom="6px solid var(--white_2)"
              textAlign={{ xs: "center" }}
            >
              {content.title}
            </Typography>

            <Divider
              sx={{
                bgcolor: "var(--lightgrey_2)",
                opacity: "0.5",
              }}
            />
            <Box textAlign={{ xs: "center" }}>
              <Box
                sx={{
                  width: "250px",
                  height: "195px",
                }}
                mt="30px"
                mb="15px"
                component="img"
                src={content?.image?.data?.attributes?.url || ""}
                alt={content?.image?.data?.attributes?.alternativeText}
              />
              {planData && planData.name && customerData && customerData.id ? (
                <Typography
                  component={"h5"}
                  variant={"h5"}
                  fontSize="18px"
                  color="var(--text_color_2)"
                  fontFamily={"var(--font_family_Bold)"}
                  pb={{ xs: "10px", sm: "5px" }}
                  textAlign={{ xs: "center" }}
                >
                  {`${planData.name} ${content.for_text} ${
                    customerData.extraLines + 1
                  }`}
                </Typography>
              ) : null}
              <Box
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: "14px",
                  fontWeight: "var(--font_weight_2)",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.43",
                  letterSpacing: " normal",
                  textAlign: "center",
                }}
              >
                {content.order}: &nbsp;
                {trackOrderStatus?.data?.orderNumber}
              </Box>
              <StepperCust
                activeStep={activeStep}
                steps={getSteps()}
                stepOneExtraContentTR={true}
                orderStatus={trackOrderData.orderStatus}
                {...props}
                sx={{ padding: "24px", textAlign: "center" }}
              ></StepperCust>
              <Box>
                <ButtonCust
                  disabled={activeStep === 3 ? false : true}
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={() =>
                    onClickHandler(RoutingURLs.activation, "_self")
                  }
                >
                  {content.btn_text}
                </ButtonCust>
              </Box>
              {trackOrderData?.orderStatus !== OrderStatus.Cancelled && (
                <>
                  <Box
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontSize: "14px",
                      fontWeight: "var(--font_weight_0)",
                      letterSpacing: " normal",
                      textAlign: "center",
                      color: "var(--text_color_5)",
                    }}
                    mt="20px"
                  >
                    {content.rec_kit}
                  </Box>
                  <Box
                    textAlign={{ xs: "center" }}
                    sx={{
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Bold)",
                      fontWeight: "var(--font_weight_5)",
                      cursor: "pointer",
                      color: "var(--primary_color)",
                      pt: "5px",
                    }}
                    onClick={() =>
                      onClickHandler(RoutingURLs.activation, "_self")
                    }
                  >
                    {content.act_now}
                  </Box>
                  {Config.FREE_SHIPPING_ACTIVATED &&
                  customerData.status === CustomerStatus.INACTIVE ? (
                    <>
                      <Box
                        sx={{
                          width: "40px",
                          height: "35px",
                        }}
                        mt="30px"
                        mb="10px"
                        component="img"
                        src={content?.track_img?.data?.attributes?.url || ""}
                        alt={
                          content?.track_img?.data?.attributes?.alternativeText
                        }
                      />
                      <Box
                        sx={{
                          fontFamily: "var(--font_family_Semibold)",
                          fontSize: "14px",
                          fontWeight: "var(--font_weight_0)",
                          letterSpacing: " normal",
                          lineHeight: "21px",
                          textAlign: "center",
                          color: "var(--text_color_5)",
                          margin: {
                            xs: "0 18px 0",
                            sm: "0 10% 0",
                            md: "0 16% 0",
                          },
                        }}
                      >
                        {content.act_your_line}
                      </Box>
                    </>
                  ) : null}
                </>
              )}
            </Box>
          </Box>
          {/* <Box className={styles.share_reach}>
            {content.share_reach}
            <Box component={"span"}>
              {content.reach_credits}
              <ArrowRightIcon className={styles.triangle} />
            </Box>
          </Box> */}
        </Paper>
      </Box>
    </Box>
  );
};

export default T1_TrackOrder;
