import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import styles from "./S1_DataUsageTable.module.scss";
import clsx from "clsx";

type Props = {
  dataUsageContent: any;
};

const T1_DataUsageTable = (props: Props) => {
  function createData(
    operators: string,
    plan1: string,
    plan2: string,
    usage: string,
    amount1: string,
    amount2: string,
    amount3: string,
    amount4: string,
    amount5: string
  ) {
    return {
      operators,
      plan1,
      plan2,
      usage,
      amount1,
      amount2,
      amount3,
      amount4,
      amount5,
    };
  }
  const rows = props.dataUsageContent.map((el: any) =>
    createData(
      el.operators,
      el.plan1,
      el.plan2,
      el.usage,
      el.amount1,
      el.amount2,
      el.amount3,
      el.amount4,
      el.amount5
    )
  );
  const {
    container_style,
    row_style,
    heading_style,
    plan_desc_style,
    amount2_desc_style,
    amount4_desc_style,
    last_cell_style,
    inner_last_cell_style,
    amount5_desc_style,
    table_cell,
    table_row,
    table_root,
  } = styles;
  return (
    <>
      <TableContainer component={Paper} className={container_style}>
        <Table sx={{ width: "95%", mx: "auto" }} aria-label="simple table">
          <TableBody className={table_root}>
            {rows.map((row: any) => (
              <TableRow
                key={row.operators}
                className={clsx(row_style, table_row)}
              >
                <TableCell
                  className={clsx(heading_style, table_cell)}
                  align="center"
                >
                  {row.operators}
                </TableCell>
                <TableCell align="center" className={clsx(table_cell)}>
                  <Box>
                    <Box className={heading_style}>{row.plan1}</Box>
                    <Box className={plan_desc_style}>{row.plan2}</Box>
                  </Box>
                </TableCell>
                <TableCell
                  className={clsx(heading_style, table_cell)}
                  align="center"
                >
                  {row.usage}
                </TableCell>
                <TableCell
                  sx={{ alignItems: { xs: "center", lg: "right" } }}
                  // align="right"
                  className={clsx(table_cell)}
                >
                  <Box className={last_cell_style}>
                    <Box className={inner_last_cell_style}>
                      <Box className={heading_style} component={"span"}>
                        {row.amount1}
                      </Box>
                      <Box component={"span"} className={amount2_desc_style}>
                        {row.amount2}
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        component={"img"}
                        src={row.amount3?.data?.attributes.url}
                      />
                      <Box component={"span"} className={amount4_desc_style}>
                        {row.amount4}
                      </Box>
                    </Box>
                    <Box
                      className={amount5_desc_style}
                      sx={{ width: "fit-content" }}
                      dangerouslySetInnerHTML={{
                        __html: row.amount5,
                      }}
                    ></Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default T1_DataUsageTable;
