import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  fieldServiceContent: any;
  errorFieldServiceContent: any;
  bookServiceContent: any;
  errorBookServiceContent: any;
};

const initialState: InitialState = {
  fieldServiceContent: null,
  errorFieldServiceContent: null,
  bookServiceContent: null,
  errorBookServiceContent: null,
};

export const getFieldServiceContent = createAsyncThunk("fieldServices", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.FieldServices.FieldServices.template}${StrapiApiEndPoints.fieldServices}`,
    isStrapiApi: true,
  });
});

export const getBookServiceContent = createAsyncThunk(
  "fieldServices/bookService",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.FieldServices.FieldServices.template}${StrapiApiEndPoints.bookService}`,
      isStrapiApi: true,
    });
  }
);

const fieldServiceSlice = createSlice({
  name: "fieldServices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getFieldServiceContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.fieldServiceContent =
          action?.payload?.data?.data?.attributes || null;
        state.errorFieldServiceContent = null;
      }
    );
    builder.addCase(
      getFieldServiceContent.rejected,
      (state: InitialState, action: any) => {
        state.fieldServiceContent = null;
        state.errorFieldServiceContent =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(
      getBookServiceContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.bookServiceContent =
          action?.payload?.data?.data?.attributes || null;
        state.errorBookServiceContent = null;
      }
    );
    builder.addCase(
      getBookServiceContent.rejected,
      (state: InitialState, action: any) => {
        state.bookServiceContent = null;
        state.errorBookServiceContent =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default fieldServiceSlice.reducer;
