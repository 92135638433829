import { Grid, Typography, Box } from "@mui/material";
import styles from "./ComparePlanDialog.module.scss";
import { Divider, SimpleModal, ButtonCust } from "../../../widgets";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";

type comparePlanDialogProps = {
  onClose: any;
  open: boolean;
  currentPlan: any;
  totalActivitedUsers: any;
  selectedPlan: any;
  confirmPlanChangeHandler: any;
  newBillStartMonth: any;
  mainCard: any;
  updateLaterHandler: any;
  planChangeLoader: any;
  cardChangeHandler: any;
  isCardExpired: any;
  ChangePlanDetails: any;
  content?: any;
  cppdContent?: any;
};

export const ComparePlanDialog = (props: comparePlanDialogProps) => {
  const {
    open,
    onClose,
    currentPlan,
    totalActivitedUsers,
    selectedPlan,
    confirmPlanChangeHandler,
    newBillStartMonth,
    mainCard,
    updateLaterHandler,
    planChangeLoader,
    cardChangeHandler,
    isCardExpired,
    ChangePlanDetails,
    content,
    cppdContent,
  } = props;
  const {
    change_plan,
    current_plan,
    new_plan,
    note,
    billing_cyle_note,
    upgrade_balance,
    plan_review,
    diff_amount,
    tax_fees,
    line_charges,
    update_now,
    card_num,
    update_later,
    card_charged,
  } = content?.compare_plan;
  const {
    planDiffAmount,
    diffTax,
    planUpgradeBalance,
    currentPlanCost,
    newPlanCost,
  } = ChangePlanDetails;
  const paymentModalData = [
    {
      text: diff_amount,
      price: planDiffAmount,
    },
    { text: tax_fees, price: diffTax },
    {
      text: upgrade_balance,
      price: planUpgradeBalance,
    },
  ];
  return (
    <>
      <SimpleModal
        isShowModal={open}
        onCloseModal={onClose}
        showClose={true}
        sx={{
          width: "500px !important",
          p: "16px",
          borderRadius: "4.8px",
          maxWidth: { xs: "95%", sm: "500px" },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        className={styles.payment_modal}
      >
        <Grid
          className={styles.inner_wrapper}
          sx={{
            p: { sm: "30px 30px 0px 30px", xs: "30px 0px 0px 0px" },
          }}
        >
          <Typography className={styles.modal_header}>{change_plan}</Typography>
          <Divider styles={{ mb: "30px" }} />
          <Grid className={styles.current_plan}>
            <Typography className={styles.plan_header}>
              {current_plan}
            </Typography>
            <Grid className={styles.plan_sub_content}>
              <Typography>
                {currentPlan}&nbsp;for&nbsp;{totalActivitedUsers}{" "}
              </Typography>
              <Typography>{GetCurrencyFixedToTwo(currentPlanCost)}</Typography>
            </Grid>
          </Grid>
          <hr />
          <Grid className={styles.new_plan}>
            <Typography className={styles.plan_header}>{new_plan}</Typography>
            <Grid className={styles.plan_sub_content}>
              <Typography>
                {" "}
                {selectedPlan?.name}&nbsp;for&nbsp;{totalActivitedUsers}{" "}
              </Typography>
              <Typography> {GetCurrencyFixedToTwo(newPlanCost)}</Typography>
            </Grid>
          </Grid>
          <hr />
          {paymentModalData.map((data: any) =>
            data.text === "Plan Upgrade Balance" ? (
              <>
                <Grid
                  className={styles.plan_sub_content}
                  sx={{ backgroundColor: "var(--white_1)", p: "15px 5px 3px" }}
                >
                  <Typography sx={{ color: "var(--success) !important" }}>
                    {data.text}
                  </Typography>
                  <Typography sx={{ color: "var(--success) !important" }}>
                    {data?.price}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid className={styles.plan_sub_content}>
                  <Typography>{data.text} </Typography>
                  <Typography>{data.price}</Typography>
                </Grid>
              </>
            )
          )}
          <Box className={styles.modal_content2}>
            <Typography className={styles.account_note}>
              <Box component="span" fontWeight="bold">
                {note}
              </Box>
              {billing_cyle_note}
              <Box component="span" fontWeight="bold">
                ({newBillStartMonth})&nbsp;
              </Box>
              {plan_review}
              <Box component="span" fontWeight="bold">
                {GetCurrencyFixedToTwo(newPlanCost)}&nbsp;
              </Box>
              {line_charges}
            </Typography>
            <Typography>{card_charged}</Typography>
            <Box className={styles.card_details} py="10px">
              <img
                src={
                  mainCard && mainCard.type === "VISA"
                    ? cppdContent?.visa_img?.data?.attributes?.url
                    : cppdContent?.other_card_img?.data?.attributes?.url
                }
                alt="visa"
              />
              <Typography px="10px">
                {card_num}
                {mainCard?.ccNumberMask}
              </Typography>
              <Typography
                sx={{
                  color: "var(--primary_color) !important",
                  cursor: "pointer",
                }}
                onClick={cardChangeHandler}
              >
                {cppdContent?.buy_data?.change_text}
              </Typography>
            </Box>
            <Grid container className={styles.button_wrapper} my="10px">
              <Grid item xs={6}>
                <Typography
                  className={styles.update_later}
                  onClick={isCardExpired ? null : updateLaterHandler}
                >
                  {update_later}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <ButtonCust
                  sx={{ minWidth: "100% !important" }}
                  onClick={() => {
                    confirmPlanChangeHandler("now");
                  }}
                  loading={planChangeLoader}
                  disabled={isCardExpired}
                >
                  {update_now}
                </ButtonCust>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </SimpleModal>
    </>
  );
};
