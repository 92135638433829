import { CheckCoverageProps } from "./CheckCoverage";
import { Button, CircularProgress } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styles from "./S2_CheckCoverage.module.scss";
import { AutoComplete } from "../../widgets";

const CheckCoverageComponent = (props: CheckCoverageProps) => {
  const {
    checkAddressHandler,
    handleSelect,
    getStarted,
    checkCoverageHandler,
    coverageContent,
    showCoverage,
    checkAddress,
    coverageLoading,
    coverageAddress,
    setShowCoverage,
    signal4G,
    signal5G,
    coverageError,
    navigateCheckCoverage,
  } = props;

  const {
    heading,
    sub_heading,
    place_holder,
    signal_note1,
    signal_note2,
    button_1,
    button_2,
    button_3,
    error,
    coverage_1,
    coverage_2,
    signal_strength1,
    signal_strength2,
    signal_strength3,
    signal_strength4,
    signal_strength5,
    close_icon,
    tick_icon,
  } = coverageContent || {};
  return (
    coverageContent && (
      <div className={`${styles.check_coverage_outer} container-fluid p-4`}>
        <div
          className={`${styles.coverage_check_heading}  text-md-start text-center`}
        >
          {heading}
        </div>
        <div
          className={`${styles.coverage_check_subheading} text-md-start text-center mb-3 mt-sm-0 mt-lg-3`}
        >
          {sub_heading}
        </div>
        {!showCoverage && (
          <div
            className={`${styles.address_holder} d-flex flex-column flex-md-row align-items-md-start align-items-center`}
          >
            <AutoComplete
              value={checkAddress}
              onChange={checkAddressHandler}
              onSelect={(e: any) => {
                handleSelect(e);
              }}
              disabled={coverageLoading}
              placeholder={place_holder}
              autoComplete={"off"}
            />

            <Button
              variant="outlined"
              className={styles.submit_btn}
              startIcon={
                coverageLoading ? <CircularProgress /> : <ArrowForwardIcon />
              }
              onClick={() => checkCoverageHandler(coverageAddress)}
              disabled={checkAddress === "" ? true : false}
            ></Button>
          </div>
        )}
        {showCoverage && (
          <div>
            {signal4G !== "no service" || signal5G !== "no service" ? (
              <div
                className={`${styles.coverage_final_text} text-center text-md-start`}
              >
                <img src={tick_icon?.data?.attributes?.url} alt="tickIcon" />
                <span>
                  {coverage_1}
                  {signal4G === "fair"
                    ? signal_strength1
                    : signal4G === "good"
                    ? signal_strength2
                    : signal4G === "great"
                    ? signal_strength3
                    : signal_strength4}
                </span>
                <span>
                  {" "}
                  {coverage_2}
                  {signal5G === "fair"
                    ? signal_strength1
                    : signal5G === "good"
                    ? signal_strength2
                    : signal5G === "great"
                    ? signal_strength3
                    : signal_strength5}
                </span>
                <p>{signal_note1}</p>
              </div>
            ) : (
              <div className={styles.coverage_final_text}>
                <img src={close_icon?.data?.attributes?.url} alt="closeIcon" />
                <span>{signal_note2}</span>
              </div>
            )}
            <div className={styles.coverage_buttons}>
              <Button onClick={() => setShowCoverage(false)}>{button_1}</Button>
              <Button onClick={getStarted}> {button_2}</Button>
              <Button onClick={navigateCheckCoverage}>{button_3}</Button>
            </div>
          </div>
        )}

        {coverageError && <div className={styles.coverage_error}>{error}</div>}
      </div>
    )
  );
};

export default CheckCoverageComponent;
