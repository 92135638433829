import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./T1_ScheduleInstallation.scss";
import EnvConfig from "../../../config/env.config.json";
import { ScheduleInstallationProps } from "./ScheduleInstallationComponent";
import { ButtonCust } from "../../widgets";
import { addDays, subDays } from "date-fns";
import CancelInstallation from "../CancelInstallation/CancelInstallation";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { useNonInitialEffect } from "../../../utils/commonFunctions";

const boxStyles = {
  fontFamily: "var(--font_family_Semibold)",
  fontSize: "12px",
  lineHeight: "10px",
};

const ScheduleInstallation = (props: ScheduleInstallationProps) => {
  const {
    content,
    startDate,
    isOpen,
    handleChange,
    handleClick,
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    selectedDateCal,
    setSelectedDateCal,
    callInstallationActivity,
    loader,
    isReschedule,
    closeRescheduleModal,
    callRescheduleOrReOpenInstallActivity,
    isReopening,
    isFieldService,
    closeScheduleModal,
    isModal,
    isTroubleCall,
  } = props || {};

  const {
    title1,
    title2,
    title3,
    pick_date,
    select_date,
    calendar_icon,
    select_time,
    confirm_btn,
    cancel1,
    cancel2,
    email_icon,
    end_note,
    schedule_later,
    slot_errors,
    sugg_date_title,
    divider_text,
  } = content || {};

  const {
    bookingSlotsList,
    errorBookingSlots,
    bookingSlotsWithDates,
    errorBookingSlotsWithDates,
  } = useAppSelector((state: any) => state.fsm);

  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [activityInfo, setActivityInfo] = useState<any>(null);
  const [slotErrors, setSlotErrors] = useState({
    suggestedSlots: "",
    manualDate: "",
  });

  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = JSON.parse(currentdetails);

  useEffect(() => {
    setActivityInfo({
      customerId: currentdetails?.id,
      activityId: currentdetails?.meta?.fsm_id,
      activityType: currentdetails?.meta?.fsm_type,
    });
  }, []);

  useNonInitialEffect(() => {
    if (errorBookingSlots) {
      setSlotErrors((prevState: any) => ({
        ...prevState,
        suggestedSlots: slot_errors?.suggestedSlots,
      }));
    }
  }, [errorBookingSlots]);

  useNonInitialEffect(() => {
    if (errorBookingSlotsWithDates)
      setSlotErrors((prevState: any) => ({
        ...prevState,
        manualDate: slot_errors?.manualDate,
      }));
  }, [errorBookingSlotsWithDates]);

  useNonInitialEffect(() => {
    if (isOpen || selectedDate || selectedDateCal)
      setSlotErrors((prevState: any) => ({
        ...prevState,
        manualDate: "",
      }));
  }, [isOpen, selectedDate, selectedDateCal]);

  const ShowTimeSlots = () => {
    let slotObject: any;
    if (selectedDate && bookingSlotsList) {
      slotObject = bookingSlotsList.data.find(
        (item: any) => item.date === selectedDate
      );
    } else if (selectedDateCal && bookingSlotsWithDates) {
      slotObject = bookingSlotsWithDates.data.find(
        (item: any) => item.date === selectedDateCal
      );
    }

    return (
      slotObject && (
        <>
          <Typography
            component="div"
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              fontSize: "14px",
              lineHeight: "14px",
              color: "var(--text_color_8)",
              opacity: "75%",
              mt: "25px",
              mb: "10px",
              textAlign: "left",
            }}
          >
            {select_time}
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{
              my: "10px",
            }}
          >
            {slotObject.timeSlots.map((time: any) => (
              <Grid
                item
                xs={4}
                sm={2.4}
                key={time.label}
                onClick={() => setSelectedTime(time.label)}
                sx={{ cursor: "pointer", textAlign: "center" }}
              >
                <Typography
                  component="div"
                  sx={{
                    ...boxStyles,
                    border:
                      selectedTime === time.label
                        ? "2px solid var(--primary_brand_color)"
                        : "2px solid rgba(112,118,121,0.4)",
                    backgroundColor:
                      selectedTime === time.label
                        ? "var(--primary_brand_color)"
                        : "unset",
                    color:
                      selectedTime === time.label
                        ? "var(--white)"
                        : "var(--text_color_8)",
                    p: "9px",
                  }}
                >
                  {time.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </>
      )
    );
  };

  const getSuggestedDates = (date: any) => {
    return (
      (new Date(date).getMonth() + 1).toString() +
      "-" +
      new Date(date).getDate().toString() +
      "-" +
      new Date(date).getFullYear().toString()
    );
  };

  return (
    <Container
      sx={{
        backGround: "var(--lead_generation_bg_color)",
        width: "100%",
        height: "100%",
        p: isModal
          ? 0
          : {
              xs: "10px 10px 100px",
              sm: "10px 70px 100px",
              md: "10px 251px 251px",
            },
      }}
    >
      <Paper
        elevation={isModal ? 0 : 3}
        square
        sx={{
          p: isModal
            ? 0
            : {
                xs: "12px",
                sm: "25px 70px",
              },
        }}
      >
        <Box className="schedule_installation_container">
          <Typography
            variant="h6"
            sx={{
              fontFamily: `${
                isModal
                  ? "var(--font_family_Bold)"
                  : "var(--font_family_Medium)"
              }`,
              fontSize: `${isModal ? "26px" : "18px"}`,
              lineHeight: "20px",
              color: `${
                isModal ? "var(--text_color_8)" : "var(--text_color_9)"
              }`,
              textAlign: "center",
              my: "15px",
            }}
          >
            {isReschedule ? title2 : isTroubleCall ? title3 : title1}
          </Typography>
          <Typography component={"div"} className="calender">
            <Typography
              component="div"
              sx={{
                fontFamily: "var(--font_family_Semibold)",
                fontSize: "16px",
                lineHeight: "17px",
                color: "var(--grey_shade_5)",
                mb: "10px",
                textAlign: "left",
              }}
            >
              {sugg_date_title}
            </Typography>
            {bookingSlotsList ? (
              <Grid
                container
                spacing={2}
                sx={{
                  mb: "10px",
                }}
              >
                {bookingSlotsList?.data.map((val: any, index: number) => {
                  if (index <= 4)
                    return (
                      <Grid
                        item
                        xs={4}
                        sm={2.4}
                        key={val.date}
                        onClick={() => {
                          setSelectedDate(val.date);
                          setSelectedTime("");
                          setSelectedDateCal("");
                        }}
                        sx={{ cursor: "pointer", textAlign: "center" }}
                      >
                        <Typography
                          component="div"
                          sx={{
                            ...boxStyles,
                            border:
                              selectedDate === val.date
                                ? "2px solid var(--primary_brand_color)"
                                : "2px solid rgba(112,118,121,0.4)",
                            backgroundColor:
                              selectedDate === val.date
                                ? "var(--primary_brand_color)"
                                : "unset",
                            color:
                              selectedDate === val.date
                                ? "var(--white)"
                                : "var(--text_color_8)",
                            p: "9px",
                          }}
                        >
                          {getSuggestedDates(val.date)}
                        </Typography>
                      </Grid>
                    );
                })}
              </Grid>
            ) : slotErrors.suggestedSlots ? (
              <Box sx={{ mt: "5px" }}>
                <Typography
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "var(--shocking-orange)",
                  }}
                >
                  {slotErrors.suggestedSlots}
                </Typography>
              </Box>
            ) : (
              <CircularProgress
                sx={{
                  color: "var(--primary_color)",
                  display: "flex",
                  margin: "10px auto",
                }}
              />
            )}
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  "&::before, &::after": {
                    borderTop: `2px solid var(--green_white1)`,
                  },
                },
                padding: "20px 0px",
              }}
            >
              <Typography
                component={"div"}
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: "16px",
                  color: "var(--text_color_9)",
                }}
              >
                {divider_text}
              </Typography>
            </Divider>
            <Typography
              component="div"
              sx={{
                fontFamily: "var(--font_family_Semibold)",
                fontSize: "16px",
                lineHeight: "17px",
                color: "var(--grey_shade_5)",
                mb: "10px",
                textAlign: "left",
              }}
            >
              {pick_date}
            </Typography>
            <Box
              sx={{
                height: "40px",
                border: "1px solid var(--grey_shade_5)",
                px: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleClick}
            >
              <Typography
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "var(--grey_shade_5)",
                }}
              >
                {selectedDateCal ? selectedDateCal : select_date}
              </Typography>
              <Box
                component="img"
                width={"15px"}
                alt={calendar_icon?.data?.attributes?.alternativeText}
                src={calendar_icon?.data?.attributes?.url}
              />
            </Box>
            {slotErrors.manualDate && (
              <Box sx={{ mt: "5px" }}>
                <Typography
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "var(--shocking-orange)",
                  }}
                >
                  {slotErrors.manualDate}
                </Typography>
              </Box>
            )}

            {isOpen && (
              <DatePicker
                showPopperArrow={false}
                selected={startDate}
                includeDateIntervals={[
                  {
                    start: subDays(new Date(), 0),
                    end: addDays(new Date(), EnvConfig.INSTALLATION_MAX_DAYS),
                  },
                ]}
                onChange={handleChange}
                dateFormat="YYYY-MM-dd"
                inline
              />
            )}
          </Typography>
          {(selectedDate || selectedDateCal) && <ShowTimeSlots />}
          <Box sx={{ textAlign: "center", my: "35px" }}>
            <ButtonCust
              variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
              onClick={
                isReschedule || isReopening
                  ? callRescheduleOrReOpenInstallActivity
                  : callInstallationActivity
              }
              loading={loader}
              disabled={!((selectedDate || selectedDateCal) && selectedTime)}
            >
              {confirm_btn}
            </ButtonCust>
          </Box>
          {isReopening ? null : (
            <Box sx={{ textAlign: "center", my: "35px" }}>
              <Typography
                component={"span"}
                sx={{
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: "var(--primary_brand_color)",
                  fontFamily: "var(--font_family_Semibold)",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  isFieldService
                    ? closeScheduleModal()
                    : isReschedule
                    ? closeRescheduleModal()
                    : setOpenCancelModal(true);
                }}
              >
                {isReschedule
                  ? cancel2
                  : isFieldService
                  ? schedule_later
                  : cancel1}
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
      {!isModal && (
        <Typography
          component="div"
          sx={{
            mt: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            width={"25px"}
            alt={email_icon?.data?.attributes?.alternativeText}
            src={email_icon?.data?.attributes?.url}
          />

          <Typography
            component="span"
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              fontSize: "16px",
              lineHeight: "17px",
              ml: "6px",
            }}
          >
            {end_note}
          </Typography>
        </Typography>
      )}
      <CancelInstallation
        openCancelModal={openCancelModal}
        setOpenCancelModal={setOpenCancelModal}
        activityInfo={activityInfo}
      />
    </Container>
  );
};

export default ScheduleInstallation;
