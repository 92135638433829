import { version, versionNew } from "../config/RoutingURLs";

const ApiEndPoints = {
  networkCoverage: "",
  loginWithNumber: `/custsvc/${version}/customers/reachnumber/`,
  resetPassword: `/custsvc/${version}/customer/reset/password/link/email`,
  countryList: `/provsvc/${version}/intl/roaming/countries/`,
  hubspot: `/custsvc/${version}/customers/hubspot`,
  customerDetails: `custsvc/${version}/customers/username?id=`,
  StateCityByZipcode: `/provsvc/${version}/mdn/zipcode/availability/`,
  createCustomer: `/custsvc/${version}/`,
  updateCustomer: `/custsvc/${version}/customers/`,
  updateMacAddress: `provsvc/${version}/update/customer/mac/id/`,
  taxCalculation: `/taxsvc/${version}/taxes/`,
  updateCustomerSource: `/custsvc/${version}/update/fields/customer/`,
  coupon: `/catalogsvc/${version}/coupon/`,
  couponReserve: `/catalogsvc/${version}/coupon/reserve/promo`,
  eligibility: `/catalogsvc/${version}/coupon/eligibility?emailId=`,
  reachPlan: `/catalogsvc/${version}/reachplans/`,
  checkIMEI: `/provsvc/${version}/imei/`,
  configKeys: `/confsvc/${version}/config/keys/`,
  SIMIdCheck: `/provsvc/${version}/icc/validate/`,
  trackOrderShipment: `/shipsvc/${version}/shipment/order/`,
  activateLine: `/provsvc/${version}/activation`,
  monthInAdvance: `/billsvc/${version}/month/in/advance/amount/`,
  creditCardDetails: `/custsvc/${version}/customer/cc/`,
  monthInAdvancePost: `billsvc/v0/month/in/advance/pay/`,
  topupPulse: `/custsvc/${version}/topup/pulse`,
  activationStatusCheck: `/provsvc/v0/activation/status/`,
  tax4Calculation: `/taxsvc/${version}/taxes/usa`,
  configInfo: `/confsvc/${version}/config/info`,
  topUpCustomer: `/custsvc/${version}/topup/customer/`,
  topUpCustomerStatus: `/custsvc/${version}/customer/operations/status/`,
  topUpGroup: `/custsvc/${version}/topup/group/`,
  currentDataUsage: `/cdrsvc/${version}/cdr/data/usage/customer/current/`,
  billCycleDataUsage: `/cdrsvc/${version}/cdr/data/usage/customer/overall/`,
  customerGroup: `/custsvc/${version}/customers/group/`,
  port: `/provsvc/${version}/port/validate/`,
  portResult: `/provsvc/${version}/port/validate/result/`,
  checkEmailAvailability: `/custsvc/${version}/customers/availability/`,
  secondaryCustomerUpdate: `/custsvc/${version}/customers/secondary/update`,
  changePlan: `/catalogsvc/${version}/reachplan/change`,
  PlanChangeCustomerStatus: `/custsvc/${version}/customer/operations/status/`,
  topUpUpdate: `/provsvc/${version}/auto/topup/update`,
  paymentHistory: `/billsvc/${version}/billing/customize/invoices`,
  dataCutOff: `/custsvc/${version}/data/cutoff/manage`,
  deviceStatus: `/devicesvc/${versionNew}/device/status`,
  deviceReboot: `/devicesvc/${versionNew}/device/reboot`,
  referralCredits: `/catalogsvc/${version}/coupon/referral/analytics/group/`,
  emailBill: `/custsvc/${version}/customer/send/invoice?emailId=`,
  updateCardExpiry: `/custsvc/${version}/cc/update/`,
  autoPayCreditCard: `/custsvc/${version}/customer/cc/autopay/`,
  removeCreditCard: `/custsvc/${version}/customer/cc/delete/`,
  billing: `/billsvc/${version}/billing/customer`,
  referalDetails: `/catalogsvc/${version}/coupon/referral/detail`,
  billingForecast: `/billsvc/${versionNew}/billing/forecast/customer/`,
  customerPlan: `custsvc/${version}/customer/plan`,
  resendEsimKit: `provsvc/${version}/resend/qr/`,
  currentBillPay: `billsvc/${version}/payment/customer`,
  getShipmentById: `/shipsvc/${version}/shipment/order/customer/`,
  lookUp: `srchsvc/${version}/addresses/lookup?q=`,
  pickupDetails: `shipsvc/${version}/shipment/pickup-details`,
  fsmBookingSlots: `fsmsvc/${version}/booking/timeslots/`,
  activityTypes: `/fsmsvc/${version}/activity-types`,
  cancelInstallation: `/fsmsvc/${version}/activities/cancel`,
  createInstallationActivity: `fsmsvc/${version}/activities`,
  createInstallationActivityWithPayment: `fsmsvc/${version}/activities`,
  createTroubleCallActivity: `fsmsvc/${version}/activities`,
  activityStatus: `/fsmsvc/${version}/activities/status?activityId=`,
  rescheduleInstallationActivity: `/fsmsvc/${version}/activities/re-schedule`,
  reOpenInstallationActivity: `/fsmsvc/${version}/activities/re-open`,
  getAllServiceOrderHistory: `fsmsvc/${version}/activities?customerId=`,
  createActivityWithPayment: `fsmsvc/${version}/activities`,
  productInfo: `/catalogsvc/${version}/product-info`,
};

export default ApiEndPoints;
