import { Box, CircularProgress, Popover, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { ButtonCust, Divider, SimpleModal, TextFieldCust } from "../../widgets";
import { EsimIMEIComponentProps } from "./EsimIMEI";
import Styles from "./ESimCheck.module.scss";
import { globalVal } from "../../../utils/globalValues/globalvalues";
import {
  digitsOnlyPattern,
  PhoneNoPattern,
} from "../../../utils/regexPatterns";
import { Close } from "@mui/icons-material";
import clsx from "clsx";

const T1_EsimIMEI = (props: EsimIMEIComponentProps) => {
  const {
    showCheckIMEIModal,
    fromAddLine,
    increaseLine,
    fromCheckoutPage,
    closeCompatModal,
    handleEnter,
    isCompatible,
    eSimCompatiblity,
    retry,
    checkHandler,
    imeiNumbers,
    imeiHandler,
    checkClick,
    currentFieldIndex,
    isImeiUsed,
    inputDisabledIndex,
    imeiData,
    errorInCompat,
    multipleImeiCheck,
    imeiNumber,
    onChangeHandler,
    imeiErr,
    inputDisabled,
    haveTroubleHandler,
    increaseHandle,
    moveToCheckoutPage,
    setDefaultSimPreference,
    setShowReviewModal1,
    haveTrouble,
    target,
    closeTroubleHandler,
    content,
    loader,
    isAccountScreen,
  } = props;
  const {
    skip_to_checkout,
    checkIMEI_modal_container,
    compatibility_plan_input,
    msg,
    limit_div,
    having_trouble_div,
    check_coverage_input_box,
    checkImei_loader,
    eSim_icon,
    retry_icon,
    failed,
    have_trouble_tool_tip,
    have_trouble_detail_div,
    have_trouble_header,
    close_icon,
    have_trouble_header_text,
    detail_content,
    detail_img_div,
    detail_head,
    detail_subtext,
    add_margin_bottom,
    detail_img,
  } = Styles;
  const {
    heading,
    sub_heading,
    Ph_success_msg,
    failure_msg,
    esim_success,
    imei_label,
    imei_err_1,
    imei_err_2,
    imei_err_3,
    imei_err_4,
    limit_imei,
    having_trouble,
    title_popover,
    pop_subtext_1,
    pop_subtext_2,
    pop_subtitle_1,
    pop_subtitle_2,
    button_1,
    shop_phones,
    skip_text,
    skip_checkout,
    preference_text,
    forward_icon,
    esim_img,
    phy_sim_img,
    forward_light_img,
    retry_img,
    popover_img1,
    popover_img2,
  } = content || {};
  return (
    content && (
      <>
        <SimpleModal
          isShowModal={showCheckIMEIModal}
          onCloseModal={() => closeCompatModal()}
          showClose={true}
          sx={{
            width: {
              xs: "95%",
              sm: "70%",
              md: "80%",
              lg: "45%",
              xl: "30%",
              padding: "30px",
            },
          }}
          style={{ borderRadius: "4px" }}
        >
          <Stack
            onKeyDown={handleEnter}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            className={checkIMEI_modal_container}
          >
            <Typography
              component="div"
              sx={{ fontSize: "22px", fontFamily: "var(--font_family_Bold)" }}
            >
              {heading}
            </Typography>
            <Typography
              component="div"
              sx={{
                fontSize: "15px",
                fontFamily: "var(--font_family_Bold)",
                color: "var(--grey_shade)",
              }}
            >
              {sub_heading}
            </Typography>
            <Divider styles={{ my: "20px" }} />

            {isCompatible && !eSimCompatiblity ? (
              <Typography
                component="div"
                sx={{
                  fontSize: "14px",
                  fontFamily: "var(--font_family_Medium)",
                  color: "var(--success)",
                  fontWeight: "var(--font_weight_2)",
                }}
              >
                {Ph_success_msg}
              </Typography>
            ) : retry && !isCompatible ? (
              <Typography
                component="div"
                sx={{
                  fontSize: "14px",
                  fontFamily: "var(--font_family_Medium)",
                  color: "var(--danger)",
                  fontWeight: "var(--font_weight_2)",
                }}
              >
                {failure_msg}
              </Typography>
            ) : eSimCompatiblity ? (
              <Typography
                component="div"
                sx={{
                  fontSize: "13px",
                  fontFamily: "var(--font_family_Bold)",
                  color: "var(--success)",
                  fontWeight: "var(--font_weight_2)",
                }}
              >
                {esim_success}
              </Typography>
            ) : null}
            <Box
              className={compatibility_plan_input}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  checkHandler();
                }
              }}
            >
              {(props.simPreference || fromAddLine) && !increaseLine ? (
                imeiNumbers.map((line: any, index: number) => (
                  <Box key={index}>
                    <Box className={check_coverage_input_box}>
                      <TextFieldCust
                        value={line.imeiNum}
                        type={window.screen.width <= 600 ? "number" : "text"}
                        label={imei_label}
                        name="imeiNum"
                        onChange={(e: any) => {
                          if (
                            PhoneNoPattern.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            imeiHandler(e, index);
                          }
                        }}
                        error={
                          line.imeiNumErr !== "" ||
                          (line.imeiNum !== "" &&
                            !digitsOnlyPattern.test(line.imeiNum)) ||
                          (line.imeiNum !== "" && line.imeiNum.length !== 15) ||
                          (checkClick &&
                            line.imeiNum === "" &&
                            currentFieldIndex === index) ||
                          (isImeiUsed && currentFieldIndex === index)
                        }
                        helperText={
                          line.imeiNumErr
                            ? line.imeiNumErr
                            : line.imeiNum !== "" &&
                              !digitsOnlyPattern.test(line.imeiNum)
                            ? imei_err_1
                            : line.imeiNum !== "" && line.imeiNum.length !== 15
                            ? imei_err_2
                            : checkClick &&
                              line.imeiNum === "" &&
                              currentFieldIndex === index
                            ? imei_err_3
                            : isImeiUsed && currentFieldIndex === index
                            ? imei_err_4
                            : ""
                        }
                        maxlength={15}
                        disabled={
                          inputDisabledIndex.length > index ? false : true
                        }
                      />
                      {checkClick && currentFieldIndex === index ? (
                        <Box className={checkImei_loader}>
                          <CircularProgress sx={{ color: "var(--white)" }} />
                        </Box>
                      ) : line.imeiNum.length === 15 &&
                        !imeiData[index] &&
                        !isImeiUsed &&
                        !errorInCompat ? (
                        <Box
                          component="img"
                          onClick={() => multipleImeiCheck(index)}
                          src={forward_icon?.data?.attributes?.url}
                        />
                      ) : line.imeiNum.length === 15 &&
                        imeiData[index]?.esimAvailable ? (
                        <Box
                          component="img"
                          className={eSim_icon}
                          src={esim_img?.data?.attributes?.url}
                        />
                      ) : line.imeiNum.length === 15 &&
                        imeiData[index]?.isValid &&
                        !imeiData[index]?.esimAvailable ? (
                        <Box
                          component="img"
                          className={eSim_icon}
                          src={phy_sim_img?.data?.attributes?.url}
                        />
                      ) : line.imeiNum.length < 15 || isImeiUsed ? (
                        <Box
                          component="img"
                          style={{ cursor: "auto" }}
                          src={forward_light_img?.data?.attributes?.url}
                        />
                      ) : (
                        !imeiData[index]?.isValid &&
                        errorInCompat && (
                          <Box
                            component="img"
                            className={retry_icon}
                            onClick={() => multipleImeiCheck(index)}
                            src={retry_img?.data?.attributes?.url}
                          />
                        )
                      )}
                    </Box>

                    <Stack
                      mb={"12px"}
                      pt="5px"
                      display={"flex"}
                      justifyContent="space-between"
                    >
                      <Typography
                        component="div"
                        sx={{ fontSize: "12px", color: "var(--text_color)" }}
                      >
                        {line.imeiNum && line.imeiNum.length
                          ? line.imeiNum.length
                          : "0"}
                        {limit_imei}
                      </Typography>
                    </Stack>
                  </Box>
                ))
              ) : (
                <Box className={check_coverage_input_box}>
                  <TextFieldCust
                    value={imeiNumber}
                    type={window.screen.width <= 600 ? "number" : "text"}
                    label={imei_label}
                    onChange={(e: any) => {
                      if (
                        PhoneNoPattern.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        onChangeHandler(e);
                      }
                    }}
                    error={
                      imeiErr !== "" ||
                      (imeiNumber !== "" &&
                        !digitsOnlyPattern.test(imeiNumber)) ||
                      (imeiNumber !== "" && imeiNumber.length !== 15) ||
                      (checkClick && imeiNumber === "") ||
                      isImeiUsed
                    }
                    helperText={
                      imeiErr
                        ? imeiErr
                        : imeiNumber !== "" &&
                          !digitsOnlyPattern.test(imeiNumber)
                        ? imei_err_1
                        : imeiNumber !== "" && imeiNumber.length !== 15
                        ? imei_err_2
                        : checkClick && imeiNumber === ""
                        ? imei_err_3
                        : isImeiUsed
                        ? imei_err_4
                        : ""
                    }
                    maxlength={15}
                    disabled={inputDisabled}
                    className={`(${retry} && !${isCompatible})? ${failed}:null`}
                  />
                  {checkClick ? (
                    <Box className={checkImei_loader}>
                      <CircularProgress sx={{ color: "var(--white)" }} />
                    </Box>
                  ) : imeiNumber.length === 15 && !retry && !isImeiUsed ? (
                    <Box
                      component="img"
                      onClick={checkHandler}
                      src={forward_icon?.data?.attributes?.url}
                    />
                  ) : imeiNumber.length === 15 && eSimCompatiblity ? (
                    <Box
                      component="img"
                      className={eSim_icon}
                      src={esim_img?.data?.attributes?.url}
                    />
                  ) : imeiNumber.length === 15 &&
                    !eSimCompatiblity &&
                    isCompatible ? (
                    <Box
                      component="img"
                      className={eSim_icon}
                      src={phy_sim_img?.data?.attributes?.url}
                    />
                  ) : imeiNumber.length < 15 || isImeiUsed ? (
                    <Box
                      component="img"
                      style={{ cursor: "auto" }}
                      src={forward_light_img?.data?.attributes?.url}
                    />
                  ) : (
                    retry &&
                    !isCompatible && (
                      <Box
                        component="img"
                        className={retry_icon}
                        onClick={checkHandler}
                        src={retry_img?.data?.attributes?.url}
                      />
                    )
                  )}
                </Box>
              )}
              {!props.simPreference && !fromAddLine && (
                <Box className={msg}>
                  <Box className={limit_div}>
                    {imeiNumber && imeiNumber.length ? imeiNumber.length : "0"}
                    {limit_imei}
                  </Box>
                  <>
                    <Box
                      className={having_trouble_div}
                      id="having-trouble-div"
                      onClick={haveTroubleHandler}
                    >
                      {having_trouble}
                    </Box>
                    <Popover
                      id={"popover-contained"}
                      open={haveTrouble}
                      anchorEl={target}
                      onClose={closeTroubleHandler}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Box className={have_trouble_tool_tip}>
                        <Box className={have_trouble_header}>
                          <Close
                            onClick={closeTroubleHandler}
                            className={close_icon}
                          />
                          <Box className={have_trouble_header_text}>
                            {title_popover}
                          </Box>
                        </Box>
                        <Box className={have_trouble_detail_div}>
                          <Box className={detail_img_div}>
                            <Box
                              component={"img"}
                              src={popover_img1?.data?.attributes?.url}
                              alt={
                                popover_img1?.data?.attributes?.alternativeText
                              }
                              className={detail_img}
                            />
                          </Box>
                          <Box className={detail_content}>
                            <Box className={detail_head}>{pop_subtitle_1}</Box>
                            <Box
                              className={clsx(
                                detail_subtext,
                                add_margin_bottom
                              )}
                            >
                              {pop_subtext_1}
                            </Box>
                          </Box>
                        </Box>
                        <Box className={have_trouble_detail_div}>
                          <Box className={detail_img_div}>
                            <Box
                              component={"img"}
                              src={popover_img2?.data?.attributes?.url}
                              alt={
                                popover_img2?.data?.attributes?.alternativeText
                              }
                              className={detail_img}
                            />
                          </Box>
                          <Box className={detail_content}>
                            <Box className={detail_head}>{pop_subtitle_2}</Box>
                            <Box className={detail_subtext}>
                              {pop_subtext_2}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Popover>
                  </>
                </Box>
              )}
            </Box>
            <Stack mt={"25px"}>
              {increaseLine ? (
                <ButtonCust
                  disabled={isCompatible ? false : true}
                  onClick={increaseHandle}
                >
                  {button_1}
                </ButtonCust>
              ) : (
                <ButtonCust
                  disabled={isCompatible ? false : true}
                  onClick={() => {
                    moveToCheckoutPage();
                    if (isAccountScreen && eSimCompatiblity)
                      setShowReviewModal1();
                  }}
                  loading={loader}
                >
                  {button_1}
                </ButtonCust>
              )}
            </Stack>

            {retry && !isCompatible && increaseLine ? (
              <Typography
                component="div"
                className={skip_to_checkout}
                onClick={() => window.open(globalVal.shopPhone, "_blank")}
              >
                {shop_phones}
              </Typography>
            ) : !retry && !fromAddLine && !increaseLine && !fromCheckoutPage ? (
              <Typography
                component="div"
                className={skip_to_checkout}
                onClick={() => {
                  moveToCheckoutPage();
                  setDefaultSimPreference();
                }}
              >
                {skip_checkout}
              </Typography>
            ) : !retry && fromAddLine ? (
              <Typography
                component="div"
                className={skip_to_checkout}
                onClick={() => moveToCheckoutPage()}
              >
                {skip_text}
              </Typography>
            ) : eSimCompatiblity &&
              !fromAddLine &&
              !increaseLine &&
              !fromCheckoutPage ? (
              <Typography
                component="div"
                className={skip_to_checkout}
                onClick={() => moveToCheckoutPage("SIM")}
              >
                {preference_text}
              </Typography>
            ) : null}
          </Stack>
        </SimpleModal>
      </>
    )
  );
};

export default T1_EsimIMEI;
