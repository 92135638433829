import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./PlanSection.module.scss";
import data from "../../../config/data.json";
import { sortByKey } from "../../../utils/commonFunctions/SortByKey";
import { PlanCard } from "./PlanCardsComponent";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PlanSectionContent } from "../../../features/strapi/homePageSlice";
import { LinesTab } from "./LinesTab";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import Config from "../../../config/env.config.json";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";

type Props = {
  isSelected?: boolean;
};

export const PlanSection = (props: Props) => {
  let details: any = localStorage.getItem("customerDetail");
  details = JSON.parse(details);
  let plandata =
    data && data.data && data.data.length > 0
      ? sortByKey(data && data.data, "planData")
      : [];
  const dispatch = useAppDispatch();
  const [cacheDataPlanSec, setCacheDataPlanSec] = useState<any>(null);
  const { planCardContent } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  const navigate = useNavigate();
  const [selectedLine, setSelectedLine] = useState(1);

  useEffect(() => {
    getCache("PlanSecHome", PlanSectionContent, setCacheDataPlanSec, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("PlanSecHome", planCardContent);
  }, [planCardContent]);

  const lineTabChangeHandler = (val: number) => {
    setSelectedLine(val);
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.home,
      category: gaCategory.lineSelection,
      buttonValue: val,
    });
  };

  const maxLinesArray = plandata.map((el: any) => {
    return el.maxLines;
  });
  const maxLinesCount = Math.max(...maxLinesArray);

  const { home_page_title, home_page_line, home_page_subtitle } = styles;
  const { isSelected } = props;
  let isSimPaid = details?.simPaid;
  return (
    (cacheDataPlanSec ? cacheDataPlanSec : planCardContent) && (
      <Box
        id="bestCellPhonePlan"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: { xs: "50px", sm: "70px" },
        }}
      >
        <Typography variant="h3" component={"h3"} className={home_page_title}>
          {isSimPaid
            ? `${
                cacheDataPlanSec
                  ? cacheDataPlanSec?.post_heading
                  : planCardContent?.post_heading
              }'${details.reachPlanId}' plan`
            : `${
                cacheDataPlanSec
                  ? cacheDataPlanSec?.heading
                  : planCardContent?.heading
              }`}
        </Typography>
        <Box className={home_page_line}></Box>
        {planCardContent?.sub_heading ||
          (cacheDataPlanSec?.sub_heading && (
            <Typography
              variant="h4"
              component={"h4"}
              className={home_page_subtitle}
              sx={{ mb: "10px" }}
            >
              {cacheDataPlanSec
                ? cacheDataPlanSec?.sub_heading
                : planCardContent?.sub_heading}
            </Typography>
          ))}
        {isSimPaid || maxLinesCount < 2 ? null : (
          <LinesTab
            value={selectedLine}
            handleChange={lineTabChangeHandler}
            totalCount={maxLinesCount}
            linesTabContent={
              cacheDataPlanSec
                ? cacheDataPlanSec?.lines_tab_component
                : planCardContent?.lines_tab_component
            }
          />
        )}
        <PlanCard
          plandata={plandata}
          navigate={navigate}
          isSimPaid={isSimPaid}
          planContent={cacheDataPlanSec ? cacheDataPlanSec : planCardContent}
          selectedLine={selectedLine}
          isSelected={isSelected}
        />
      </Box>
    )
  );
};