import { ButtonCust, TextFieldCust } from "../widgets";
import { ForgotPasswordProps } from ".";
import {
  Divider,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
  Grid,
  Stack,
  Box,
} from "@mui/material";
import EnvConfig from "../../config/env.config.json";
import { T3_LoginAndSignUp, T3_LoginAndSignUpList } from "../common";

const ForgotPasswordT3 = (props: ForgotPasswordProps) => {
  const theme = useTheme();

  const {
    email,
    emailError,
    onChangeHandler,
    handleToggle,
    handleForgotPassword,
    isForgotClicked,
    content,
  } = props;

  const {
    heading_fp,
    fp_desc,
    email_label_fp,
    btn_fp,
    fp_help_icon,
    fp_help,
    card_heading_fp,
    already_acc_text_fp,
    already_acc_btn_fp,
    card_list1,
    card_list2,
    card_list3,
    card_list4,
    card_bg_img,
  } = content || {};

  return (
    <Grid container>
      <Grid p={{ sm: "100px" }} item md={5}>
        <Typography
          component={"h5"}
          variant={"h3"}
          fontSize="38px"
          color={"var(--text_color)"}
          fontFamily={"var(--font_family_Medium)"}
          pt={{ xs: "25px", sm: 0 }}
          pb={{ xs: "25px", sm: "28px" }}
          justifyContent={{ xs: "center", sm: "unset" }}
          display={"flex"}
        >
          {heading_fp}
        </Typography>
        <Typography
          component={"h5"}
          variant={"h3"}
          fontSize="20px"
          color={"var(--text_color)"}
          fontFamily={"var(--font_family_Semibold)"}
          pb={{ xs: "25px", sm: "40px" }}
          justifyContent={{ xs: "center", sm: "unset" }}
          display={"flex"}
          textAlign={{ xs: "center", sm: "unset" }}
        >
          {fp_desc}
        </Typography>

        <Container
          disableGutters={!useMediaQuery(theme.breakpoints.only("xs"))}
        >
          <Grid container sx={{ gap: "30px", marginBottom: "30px" }}>
            <Grid item xs={12} sm={12}>
              <TextFieldCust
                value={email}
                placeholder={email_label_fp}
                name="email"
                onChange={onChangeHandler}
                error={emailError !== "" ? true : false}
                helperText={emailError ? emailError : ""}
              />
            </Grid>
          </Grid>

          <ButtonCust
            variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            fullWidth
            sx={{ my: "15px" }}
            onClick={handleForgotPassword}
            loading={isForgotClicked}
          >
            {btn_fp}
          </ButtonCust>
        </Container>

        <Typography
          component={"div"}
          sx={{
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            width: "max-content",
            margin: "auto",
          }}
          mt="20px"
          onClick={() => window.open(EnvConfig.SUPPORT_URL)}
        >
          <Box
            component={"img"}
            alt={fp_help_icon?.data.attributes.alternativeText}
            src={fp_help_icon?.data.attributes.url}
            width="25px"
            sx={{ mr: "10px" }}
          />
          <Typography
            component={"span"}
            onClick={() => null}
            sx={{
              fontSize: "16px",
              color: "var(--primary_color)",
              textDecoration: "underline",
            }}
          >
            {fp_help}
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={7}>
        {content && (
          <T3_LoginAndSignUp bgImage={card_bg_img}>
            <Grid p={{ xs: "10px", sm: "30px" }} mt={{ xs: "15px", sm: "0px" }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                color={"white"}
                fontSize="22px"
                fontFamily={"var(--font_family_Medium)"}
              >
                {card_heading_fp}
              </Typography>
              <Stack spacing={{ xs: 1, sm: 1 }} pt={{ xs: 1, sm: 3 }}>
                <T3_LoginAndSignUpList content={card_list1} />
                <T3_LoginAndSignUpList content={card_list2} />
                <T3_LoginAndSignUpList content={card_list3} />
                <T3_LoginAndSignUpList content={card_list4} />
              </Stack>
              <Divider
                sx={{
                  bgcolor: "var(--white)",
                  mt: "15px",
                  width: "15%",
                  height: "1px",
                }}
              />
              <Typography
                gutterBottom
                variant="h1"
                component="h5"
                sx={{
                  fontSize: "22px",
                  fontFamily: "var(--font_family_Medium)",
                  color: "var(--white)",
                  my: "20px",
                }}
              >
                {already_acc_text_fp}
              </Typography>
              <ButtonCust
                fullWidth
                variantType={EnvConfig.SECONDARY_BUTTON_TYPE}
                sx={{ my: "15px" }}
                onClick={handleToggle}
              >
                {already_acc_btn_fp}
              </ButtonCust>
            </Grid>
          </T3_LoginAndSignUp>
        )}
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordT3;
