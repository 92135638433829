import { ButtonCust, TextFieldCust } from "../widgets";
import { LoginProps } from ".";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Stack,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EnvConfig from "../../config/env.config.json";
import { T3_LoginAndSignUp, T3_LoginAndSignUpList } from "../common";

const LoginT3 = (props: LoginProps) => {
  const {
    email,
    password,
    emailError,
    passwordError,
    onChangeHandler,
    handleToggle,
    isLoginClicked,
    handleLogIn,
    onAppleLogin,
    onGoogleLogin,
    onFacebookLogin,
    handleEnter,
    content,
    showSignUp,
    isCheckout,
  } = props;
  const {
    heading_login,
    login_desc,
    login_logos,
    email_label_login,
    pwd_label,
    btn_login,
    forgot_pwd,
    social_login_text,
    signin_support,
    no_account,
    card_heading,
    card_sub_heading,
    card_list1,
    card_list2,
    card_list3,
    card_list4,
    create_acc_btn,
    card_bg_img,
  } = content || {};
  const theme = useTheme();

  const getLogo = (index: number) => login_logos?.data[index].attributes.url;
  const getAltText = (index: number) =>
    login_logos?.data[index].attributes.alternativeText;
  const Cursor = "pointer";

  return (
    <Grid container onKeyDown={handleEnter}>
      <Grid
        p={{ sm: isCheckout ? "10px" : "100px" }}
        item
        md={isCheckout ? 12 : 5}
        width="100%"
      >
        <Typography
          component={"h5"}
          variant={"h3"}
          fontSize="38px"
          color={"var(--text_color)"}
          fontFamily={"var(--font_family_Medium)"}
          pt={{ xs: "25px", sm: 0 }}
          pb={{ xs: "25px", sm: "28px" }}
          justifyContent={{ xs: "center", sm: "unset" }}
          display={"flex"}
        >
          {heading_login}
        </Typography>
        <Typography
          component={"h5"}
          variant={"h3"}
          fontSize="20px"
          color={"var(--text_color)"}
          fontFamily={"var(--font_family_Medium)"}
          pb={{ xs: "25px", sm: "40px" }}
          justifyContent={{ xs: "center", sm: "unset" }}
          display={"flex"}
        >
          {login_desc}
        </Typography>

        <Container
          disableGutters={!useMediaQuery(theme.breakpoints.only("xs"))}
        >
          <Grid container sx={{ gap: "30px", marginBottom: "30px" }}>
            <Grid item xs={12} sm={12}>
              <TextFieldCust
                value={email}
                placeholder={email_label_login}
                name="email"
                onChange={onChangeHandler}
                error={emailError !== "" ? true : false}
                helperText={emailError ? emailError : ""}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextFieldCust
                value={password}
                placeholder={pwd_label}
                type="password"
                name="password"
                onChange={onChangeHandler}
                error={passwordError !== "" ? true : false}
                helperText={passwordError ? passwordError : ""}
              />
            </Grid>
          </Grid>

          {isCheckout && (
            <Typography
              component={"span"}
              onClick={handleToggle}
              sx={{
                cursor: Cursor,
                fontSize: "16px",
                fontFamily: "var(--font_family_Semibold)",
                color: "var(--primary_color)",
                textDecoration: "underline",
              }}
            >
              {forgot_pwd}
            </Typography>
          )}

          <ButtonCust
            variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            fullWidth
            sx={{ my: "15px" }}
            loading={!isLoginClicked}
            onClick={isLoginClicked ? () => handleLogIn() : () => null}
          >
            {btn_login}
          </ButtonCust>
          <Typography textAlign={"center"}>
            <Typography
              component={"span"}
              onClick={isCheckout ? showSignUp : handleToggle}
              sx={{
                cursor: Cursor,
                fontSize: "16px",
                fontFamily: "var(--font_family_Semibold)",
                color: "var(--primary_color)",
                textDecoration: "underline",
              }}
            >
              {isCheckout ? no_account : forgot_pwd}
            </Typography>
          </Typography>
        </Container>
        <Divider
          sx={{
            "&.MuiDivider-root": {
              "&::before, &::after": {
                borderTop: `solid 2px var(--ford-grey-color)`,
              },
            },
            padding: "20px 0px",
          }}
        >
          <Typography
            component={"div"}
            fontFamily={"var(--font_family_Semibold)"}
          >
            {social_login_text}
          </Typography>
        </Divider>
        <Stack
          direction={{ xs: "row" }}
          spacing={{ xs: 2, sm: 2 }}
          alignItems={"center"}
          justifyContent={"center"}
          my={{ xs: 2 }}
          gap="65px"
        >
          {EnvConfig.APPLE_SIGN_IN_ENABLED ? (
            <Box
              component="img"
              sx={{ cursor: Cursor }}
              alt={getAltText(0)}
              src={getLogo(0)}
              onClick={onAppleLogin}
            />
          ) : null}
          {EnvConfig.GOOGLE_SIGN_IN_ENABLED ? (
            <Box
              component="img"
              sx={{ cursor: Cursor }}
              alt={getAltText(1)}
              src={getLogo(1)}
              onClick={onGoogleLogin}
            />
          ) : null}
          {EnvConfig.FACEBOOK_SIGN_IN_ENABLED ? (
            <Box
              component="img"
              sx={{ cursor: Cursor }}
              alt={getAltText(2)}
              src={getLogo(2)}
              onClick={onFacebookLogin}
            />
          ) : null}
        </Stack>
        <Typography textAlign={"center"} mt="60px">
          <Typography
            component={"span"}
            onClick={() => {
              window.open(EnvConfig.SUPPORT_URL);
            }}
            sx={{
              cursor: Cursor,
              fontSize: "16px",
              color: "var(--primary_color)",
              textDecoration: "underline",
            }}
          >
            {signin_support}
          </Typography>
        </Typography>
      </Grid>
      {!isCheckout && (
        <Grid item md={7}>
          {content && (
            <T3_LoginAndSignUp bgImage={card_bg_img}>
              <Grid
                p={{ xs: "10px", sm: "30px" }}
                mt={{ xs: "15px", sm: "0px" }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color={"white"}
                  fontSize="38px"
                  fontFamily={"var(--font_family_Bold)"}
                >
                  {card_heading}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color={"white"}
                  fontSize="22px"
                >
                  {card_sub_heading}
                </Typography>
                <Stack spacing={{ xs: 1, sm: 1 }} pt={{ xs: 1, sm: 1.5 }}>
                  <T3_LoginAndSignUpList content={card_list1} />
                  <T3_LoginAndSignUpList content={card_list2} />
                  <T3_LoginAndSignUpList content={card_list3} />
                  <T3_LoginAndSignUpList content={card_list4} />
                </Stack>

                <ButtonCust
                  fullWidth
                  variantType={EnvConfig.SECONDARY_BUTTON_TYPE}
                  sx={{ my: "20px" }}
                  onClick={showSignUp}
                >
                  {create_acc_btn}
                </ButtonCust>
              </Grid>
            </T3_LoginAndSignUp>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default LoginT3;
