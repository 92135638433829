import { useState } from "react";
import { Divider, Container } from "@mui/material";
import { useAppDispatch } from "../../../app/hooks";
import { getInTouchApi } from "../../../features/homepage/homepageSlice";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { globalVal } from "../../../utils/globalValues/globalvalues";
import { SignUpComponent, LoginComponent, NotificationCust } from "../../";

import {
  gaBrandNames,
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";

type Props = {
  line: any;
  setUserDetails: any;
};

export const SignUpAndLogin = (props: Props) => {
  const { line, setUserDetails } = props;

  const dispatch = useAppDispatch();
  const [isSignUp, setIsSignUp] = useState(true);

  const commonSocialRedirect = (
    user: any,
    provider: string,
    notification?: string
  ) => {
    let user1 = user;

    notification &&
      NotificationCust({
        message: notification,
        duration: 2000,
        id: "socialLoginNotification",
      });

    let name: string = user1 && user1.displayName;
    let firstName: string =
      name && name.split(" ") && name.split(" ")[0] ? name.split(" ")[0] : "";
    let lastName: string =
      name && name.split(" ") && name.split(" ")[1] ? name.split(" ")[1] : "";
    const hubspotPayload: any = {
      emailId: user1 && user1.email,
      hubspotMap: {
        email: user1 && user1.email,
        is_test: globalVal.hubspot_is_test,
        is_qa: globalVal.hubspot_is_qa,
        website_logged_in: true,
        website_login_date: new Date().getTime(),
        website_journey_score: 3,
        firstname: firstName,
        lastname: lastName,
      },
    };

    let planDetails: any = localStorage.getItem("reachSelectedPlanLine");
    planDetails = planDetails ? JSON.parse(planDetails) : null;
    isSignUp
      ? dispatch(
          getInTouchApi({
            data: {
              ...hubspotPayload,
              hubspotMap: {
                ...hubspotPayload.hubspotMap,
                login_provider: provider, // (“email, facebook, google, apple”)
                plan_selected: planDetails && planDetails.name,
                website_number_of_lines:
                  line !== null && line !== undefined ? line : 1,
              },
            },
          })
        )
      : dispatch(
          getInTouchApi({
            data: hubspotPayload,
          })
        );

    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: gaBrandNames.reach,
      screenName: gaScreenNames.checkout,
      category: gaCategory.swiftPurchase,
      buttonValue:
        provider === "Email"
          ? isSignUp
            ? "Create Account"
            : "Login"
          : provider,
    });
    setUserDetails(user1);
  };

  return (
    <Container sx={{ mt: "15px", padding: { xs: 0 } }}>
      {isSignUp ? (
        <SignUpComponent
          isCheckout={true}
          toggleForm={() => setIsSignUp((previousState) => !previousState)}
          commonSocialRedirect={commonSocialRedirect}
        />
      ) : (
        <LoginComponent
          isCheckout={true}
          toggleForm={() => setIsSignUp((previousState) => !previousState)}
          commonSocialRedirect={commonSocialRedirect}
        />
      )}
      <Divider
        sx={{
          bgcolor: "var(--ford-grey-color)",
          opacity: "0.5",
          mt: "15px",
        }}
      />
    </Container>
  );
};
