import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { object, string } from "yup";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Config from "../../../../config/app.config.json";
import {
  checkEmailAvailability,
  customerGroup,
  updateSecondaryCustomer,
} from "../../../../features/account/accountSlice";
import {
  CreateCustomer,
  GetCustomer,
} from "../../../../features/checkout/checkoutSlice";
import { validateEmail } from "../../../../utils/commonFunctions/ValidateEmail";
import { emailPattern, namePattern } from "../../../../utils/regexPatterns";
import firebase from "../../../../adapters/firebase";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../../Types/GoogleAnalytics";
import EnvConfig from "../../../../config/env.config.json";
import { CustomerStatus } from "../../../../enums";

const LineListComponent = isTemplateExisits(Config.PostActivationHome.DataUsage)
  ? require(`./${Config.PostActivationHome.DataUsage.template}_LineComponent`)
      .default
  : null;

export type LineComponentProps = {
  content?: any;
  user?: any;
  handleChangeEmail?: any;
  updateSecondaryEmail?: any;
  handleClose?: any;
  handleClickEdit?: any;
  customerForm?: any;
  value?: string;
  handleClickOpen?: any;
  updateEmailErr?: string;
  open: boolean;
  emailErr?: string;
  edit?: boolean;
  activateModal: boolean;
  customerErr?: string;
  customerDetailBtn: boolean;
  openUser?: any;
  setActivateModal?: any;
  setCustomerErr?: any;
  handleActivateModal?: any;
  setStopAddLinesFlow?: any;
  groupData?: any;
  extraLinesData?: any;
  setSecCustomerPayload?: any;
};

export const LineComponent = (props: any) => {
  const { setStopAddLinesFlow, content } = props;
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<any>(null);
  const [customerDetailBtn, setCustomerDetailBtn] = useState<boolean>(false);
  const [customerErr, setCustomerErr] = useState("");
  const [activateModal, setActivateModal] = useState<boolean>(false);
  const [edit, setEdit] = useState(true);
  const [emailErr, setEmailErr] = useState("");
  const [open, setOpen] = useState(false);
  const [updateEmailErr, setUpdateEmailErr] = useState("");
  const [value, setValue] = useState("");
  const [openUser, setOpenUser] = useState<any>("");
  const { group } = useAppSelector((state: any) => state.account);
  const [groupData, setGroupData] = useState<any>(null);
  const [extraLinesData, setExtraLinesData] = useState<any>(null);
  const [secCustomerPayload, setSecCustomerPayload] = useState<any>(null);

  useEffect(() => {
    let currentdetails: any = localStorage.getItem("customerDetail");
    currentdetails = JSON.parse(currentdetails);
    dispatch(
      customerGroup({
        groupId: currentdetails && currentdetails.groupId,
      })
    );
    setUser(currentdetails);
  }, []);

  //sorting the lines based on created date
  useEffect(() => {
    if (group && group?.data?.length > 0) {
      const data = group?.data?.slice().sort((a: any, b: any) => {
        return (
          new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
        );
      });
      setGroupData(data);
      let extraLines: any = [];
      let linePreference = user?.linePreferences;
      let myData = linePreference
        ? Object.keys(linePreference).map((key) => linePreference[key])
        : [];
      myData.sort((a, b) =>
        a.simPreference > b.simPreference
          ? 1
          : b.simPreference > a.simPreference
          ? -1
          : 0
      );
      extraLines.push(...myData);
      group.data.map((el: any) => {
        getNonDisconnectedCustomers(el);
        //filter data based imei if present
        if (user?.extraLines > group?.data.length - 1) {
          if (el?.imei) {
            const filteredData = extraLines.filter(
              (data: any) => data?.imei !== el?.imei
            );
            extraLines = [];
            extraLines.push(...filteredData);
          } else {
            if (
              extraLines[extraLines.length - 1]?.simPreference ===
              "PHYSICAL_SIM"
            ) {
              extraLines.pop();
            }
          }
        }
      });
      setExtraLinesData(extraLines);
    }
  }, [group]);
  let disconnectedUserStatus: any = [];

  const getNonDisconnectedCustomers = (user: any, maxlines: number = 6) => {
    if (
      user.status === CustomerStatus?.DISCONNECTED ||
      user.status === CustomerStatus?.PORTING_FAILED
    ) {
      disconnectedUserStatus.push(user.status);
    }
    let additionalLines: number;
    if (user.additionalLines >= 0) {
      additionalLines = user.additionalLines;
      if (
        !user.id ||
        !user.simPaid ||
        user?.extraLines -
          user?.additionalLines +
          additionalLines -
          disconnectedUserStatus.length >
          maxlines - 1 ||
        user?.extraLines > user?.additionalLines
      ) {
        setStopAddLinesFlow(true);
      }
    }
  };

  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.account,
      category: category,
      buttonValue: value,
    });
  };

  const customerSchema = object({
    email: string()
      .trim()
      .required(content?.email_valid)
      .matches(emailPattern, content?.email_valid),
    firstName: string()
      .trim()
      .required(content?.fst_valid)
      .matches(namePattern, content?.fst_valid)
      .min(2, content?.fst_valid)
      .max(24, content?.fst_valid),
    lastName: string()
      .trim()
      .required(content?.lst_valid)
      .matches(namePattern, content?.lst_valid)
      .min(2, content?.lst_valid)
      .max(24, content?.lst_valid),
  });

  const customerForm = useFormik({
    initialValues: { firstName: "", lastName: "", email: "" },
    validationSchema: customerSchema,
    onSubmit: (values: any) => {
      setCustomerDetailBtn(true);
      addLines(values);
    },
  });

  const handleClickOpen = (user: any) => {
    setOpen(true);
    setOpenUser(user);
    setValue(user?.emailId);
  };

  const handleClickEdit = () => {
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    setActivateModal(false);
    customerForm.resetForm();
    setCustomerErr("");
    setEmailErr("");
    setEdit(true);
    setCustomerDetailBtn(false);
    setUpdateEmailErr("");
  };
  const addLines = (values: any) => {
    const { firstName, lastName, email } = values;
    dispatch(checkEmailAvailability({ email }))
      .then((res: any) => {
        if (res?.payload?.data?.data) {
          setCustomerErr("");
          dispatch(
            CreateCustomer({
              zipcode: user?.zipcode,
              firstName: firstName,
              lastName: lastName,
              parentId: user?.id,
              isMNP: false,
              isPrimary: false,
              primaryNumber: user.billingNumber,
              addresses: user?.addresses,
              status: 0,
              emailId: email,
              reachPlanId: user.reachPlanId,
              isSimInHand: false,
              extraLines: 0,
              selectedShippingMethod: user.selectedShippingMethod,
              userName: email,
              imei: secCustomerPayload?.imei,
              esim: secCustomerPayload?.esim,
              make: secCustomerPayload?.make,
              model: secCustomerPayload?.model,
              activationJourneyStatus: "IN_PROGRESS",
              simPaid: true,
              flowVersion: 1,
            })
          )
            .then((res: any) => {
              if (res?.payload?.status === 200) {
                handleClose();
                dispatch(
                  GetCustomer({ username: encodeURIComponent(user?.emailId) })
                ).then((res: any) => {
                  if (res?.payload?.status === 200) {
                    setUser(res?.payload?.data?.data);
                    dispatch(customerGroup({ groupId: user?.groupId }));
                  }
                });
              } else {
                setCustomerErr(content?.oops_smtg);
                setCustomerDetailBtn(false);
              }
            })
            .catch(() => {
              setCustomerErr(content?.oops_smtg);
              setCustomerDetailBtn(false);
            });
        } else {
          setCustomerErr(content?.email_txt);
          setCustomerDetailBtn(false);
        }
      })
      .catch(() => {
        setCustomerDetailBtn(false);
      });
  };

  const handleChangeEmail = (e: any) => {
    setValue(e.target.value);
    setEmailErr("");
    setUpdateEmailErr("");
  };

  const updateSecondaryEmail = () => {
    GAAndFBEventsHandler(gaCategory.manageLines, content?.update);
    if (!validateEmail(value)) {
      setEmailErr(content?.vaild_eml);
    } else {
      setCustomerDetailBtn(true);
      setEmailErr("");
      dispatch(
        updateSecondaryCustomer({
          primaryCustomerId: user?.id,
          secondaryCustomerId: openUser?.id,
          currentSecondaryEmailId: openUser?.emailId,
          newSecondaryEmailId: value,
        })
      )
        .then((data: any) => {
          if (data?.payload?.status === 200) {
            handleClose();
            dispatch(customerGroup({ groupId: user?.groupId }));
            firebase
              .database()
              .ref("source/")
              .update({ [btoa(value)]: EnvConfig.OPERATOR }, () => {});
          } else {
            setUpdateEmailErr(content?.account_exists);
            setCustomerDetailBtn(false);
          }
        })
        .catch(() => {
          setCustomerDetailBtn(false);
          setUpdateEmailErr(content?.oops_smtg);
        });
    }
  };
  return (
    <LineListComponent
      user={user}
      handleChangeEmail={handleChangeEmail}
      updateSecondaryEmail={updateSecondaryEmail}
      handleClose={handleClose}
      handleClickEdit={handleClickEdit}
      customerForm={customerForm}
      value={value}
      handleClickOpen={handleClickOpen}
      updateEmailErr={updateEmailErr}
      open={open}
      emailErr={emailErr}
      edit={edit}
      activateModal={activateModal}
      customerErr={customerErr}
      customerDetailBtn={customerDetailBtn}
      openUser={openUser}
      setActivateModal={setActivateModal}
      setCustomerErr={setCustomerErr}
      handleActivateModal={setActivateModal}
      content={content}
      setStopAddLinesFlow={setStopAddLinesFlow}
      extraLinesData={extraLinesData}
      setSecCustomerPayload={setSecCustomerPayload}
      groupData={groupData}
    />
  );
};
