import { Box } from "@mui/material";
import "./T1_PlanItem.scss";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import Config from "../../config/env.config.json";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../Types/GoogleAnalytics";

interface T1_PlanItemProps {
  selectedPlan?: any;
  plan?: any;
  activePlanName?: any;
  key?: string | number;
  handlePlanSelection: any;
  content: any;
  currentPlanBorder: boolean;
  user: any;
}
export const T1_PlanItem = ({
  selectedPlan,
  plan,
  activePlanName,
  key,
  handlePlanSelection,
  content,
  currentPlanBorder,
  user,
}: T1_PlanItemProps) => {
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.account,
      category: category,
      buttonValue: value,
    });
  };
  return (
    <Box>
      {!user?.reachPlanChangeDate && user?.amendedReachPlanId === plan.name ? (
        <Box className="move_next_month_label">{content?.move_next_month}</Box>
      ) : activePlanName === plan.name ? (
        <Box className="active_item_label">{content?.active_label}</Box>
      ) : null}
      <Box
        key={key}
        onClick={
          activePlanName !== plan.name
          ? () =>{ handlePlanSelection(plan);GAAndFBEventsHandler(gaCategory.changePlan,plan.name); }
            : () => null
        }
        className={
          activePlanName === plan.name ? "active_plan" : "in_active_plan"
        }
        id={currentPlanBorder ? "actvie_card_border" : "none"}
      >
        <Box
          className={
            JSON.stringify(selectedPlan) === JSON.stringify(plan)
              ? "CheckoutCard ActiveCard"
              : "CheckoutCard"
          }
        >
          <Box className="leftDiv">
            <Box className="cardTitle">
              {plan && plan.name && plan.name.replace("The", "")}
            </Box>
          </Box>
          <Box className="rightDiv">
            <Box className="gbData">
              {`$${plan.baseLinePrice.toFixed(2)}`}
              <span
                className={
                  JSON.stringify(selectedPlan) === JSON.stringify(plan)
                    ? "monthDataActive"
                    : "monthData"
                }
              >
                {content?.line_unit}
              </span>
            </Box>
            <Box className="sharable">
              {plan && plan.isUnlimited
                ? `${content?.fixed_lable1} ${plan.maxLines} ${content?.fixed_lable2}`
                : content?.sharable_lbl}
            </Box>
            {plan.addLineSplitPrice ? null : (
              <Box className="addLine">
                {content?.add_line_label}{" "}
                <span className="perMonth">
                  ${plan.additionalLinePrice.toFixed(2)}
                  {content?.line_unit}
                </span>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default T1_PlanItem;
