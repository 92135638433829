import { useEffect, useState } from "react";
import Config from "../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import { getUsePolicyContent } from "../../features/strapi/usePolicySlice";

const UsePolicyComponent = isTemplateExisits(Config.AcceptUsePolicy.UsePolicies)
  ? require(`./${Config.AcceptUsePolicy.UsePolicies.template}_UsePolicy`)
      .default
  : null;

export type usePolicyProps = {
  content?: any;
};

export const UsePolicy = () => {
  const [cacheContent, setCacheContent] = useState<any>(null);
  const { usePolicyContent } = useAppSelector(
    (state: any) => state.strapi.usepolicy
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("UsePolicy", getUsePolicyContent, setCacheContent, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("UsePolicy", usePolicyContent?.data?.data?.attributes);
  }, [usePolicyContent]);

  return (
    (cacheContent || usePolicyContent) && (
      <UsePolicyComponent
        content={
          cacheContent ? cacheContent : usePolicyContent?.data?.data?.attributes
        }
      />
    )
  );
};
