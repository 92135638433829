import { Box, Typography } from "@mui/material";
import styles from "./S1_NegResultTop.module.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { VideoDialog } from "../VideoDialog";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../../config/env.config.json";
import clsx from "clsx";

export const NegResultTop = (props: any) => {
  const { data } = props;
  const [openModal, setOpenModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const handleClick = (val: any) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.BillCalculator,
      category: gaCategory.billCalculator,
      buttonValue: val,
    });
  };

  return (
    <Box>
      <Box
        sx={{ m: { xs: "0 15px", lg: "0 30px" } }}
        className={styles.main_container}
      >
        <Box sx={{ marginTop: "20px" }}>
          <Box
            className={styles.back_button}
            onClick={() => {
              props.pageHandler();
            }}
          >
            <ArrowBackIcon className={styles.arrow_icon} />
            {data?.back_text}
          </Box>
          <Box className={styles.sub_main_container}>
            <Typography className={styles.title}>{data?.title}</Typography>
            <img
              src={data?.image?.data?.attributes?.url}
              alt={data?.image?.data?.attributes?.alternativeText}
              style={{ width: "250px", height: "110px" }}
              className={styles.group_img}
            />
            <Typography className={styles.sub_title}>
              {data?.sub_title}
            </Typography>
            <Typography className={styles.description}>
              {data?.description}
            </Typography>
            <Box className={styles.bottom_div}>
              {data?.bill_res_comp &&
                data?.bill_res_comp?.length > 0 &&
                data?.bill_res_comp?.map((content: any) => {
                  return (
                    <Box key={content.id}>
                      <Box
                        className={styles.title}
                        display="flex"
                        justifyContent="center"
                      >
                        <img
                          src={content?.image?.data?.attributes?.url}
                          alt={
                            content?.image?.data?.attributes?.alternativeText
                          }
                          className={styles.neg_image}
                          style={{
                            width: content?.image?.data?.attributes?.width,
                            height: content?.image?.data?.attributes?.height,
                          }}
                        />
                        {content?.title}
                      </Box>
                      <Typography
                        className={clsx(
                          styles.description,
                          styles.bottom_description
                        )}
                      >
                        {content?.description}
                      </Typography>
                      {content?.video_text && (
                        <Box
                          display={"flex"}
                          alignContent={"center"}
                          justifyContent={"center"}
                          marginBottom={"40px"}
                        >
                          <Box
                            className={styles.video_div}
                            onClick={() => {
                              handleClick(content?.video_text),
                                setOpenModal(true),
                                setVideoUrl(content?.video_link);
                            }}
                          >
                            <img
                              src={content?.video_img?.data?.attributes?.url}
                              alt={
                                content?.video_img?.data?.attributes
                                  ?.alternativeText
                              }
                              className={styles.video_img}
                              style={{ width: "20px", height: "21px" }}
                            />
                            <Typography className={styles.video_content}>
                              {content?.video_text}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Box>
      {openModal && (
        <VideoDialog
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          videoUrl={videoUrl}
        />
      )}
    </Box>
  );
};

export default NegResultTop;
