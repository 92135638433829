import axios from "./axios";
import setAuthToken from "./auth";
import { v4 as uuidv4 } from "uuid";
import axiosStrapi from "./axiosStrapi";

type Params = {
  method: string;
  url: string;
  body?: any;
  token?: string;
  params?: any;
  headers?: any;
  auth?: boolean;
  other?: boolean;
  other1?: string;
  contentType?: string;
  isStrapiApi?: boolean;
};
class ApiJunction {
  async makeRequest(params: Params) {
    const resToken = await setAuthToken();
    if (params.isStrapiApi) {
      return axiosStrapi.get(params.url, { params: params.params });
    }
    let token = resToken;
    axios.defaults.headers.common["txnid"] = uuidv4();
    axios.defaults.headers.common["x-reach-mvne"] = "CABO";
    axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
    // axios.defaults.headers.common['authorization'] = '2e656744-dc7e-4d8a-bdec-6df49de51bb2';
    axios.defaults.headers.common[
      params.auth ? "x-account-src" : "x-reach-src"
    ] = params.auth ? `reach` : "web";
    if (params.contentType) {
      axios.defaults.headers.post["Content-Type"] = params.contentType;
    } else {
      // axios.defaults.headers.common[
      //   "Content-Type"
      // ] = `application/x-www-form-urlencoded; charset=UTF-8`;
      // axios.defaults.headers.patch[
      //   "Content-Type"
      // ] = `application/x-www-form-urlencoded; charset=UTF-8`;
      axios.defaults.headers.common["Content-Type"] = `application/json`;
    }
    if (params.other) {
      axios.defaults.headers.common["x-is-primary"] = params.other
        ? "Yes"
        : "No";
    }
    if (params.other1) {
      axios.defaults.headers.common["x-reach-mvne"] = params.other1
        ? params.other1
        : "";
    }

    if (params.method === "get") {
      return axios.get(params.url, { params: params.params });
    } else if (params.method === "post") {
      return axios
        .post(params.url, params.body)
        .then((res: any): any => {
          return res;
        })
        .then((err) => {
          return err;
        });
    } else if (params.method === "put") {
      return axios.put(params.url, params.body);
    } else if (params.method === "delete") {
      return axios.delete(params.url, params.body);
    } else if (params.method === "patch") {
      return axios.patch(params.url, params.body, params.headers);
    } else {
      return { success: false, msg: "No method provided, get, post?" };
    }
  }

  login(params: Params) {
    return axios.post(params.url, params.body);
  }
}

export default new ApiJunction();
