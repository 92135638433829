import { Box, Divider, Grid, Typography } from "@mui/material";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";
import styles from "./S3_Footer.module.scss";
type Props = {
  isPostActive: boolean;
  footerContent: any;
  onClickHandler: any;
  authUser?: any;
};
const { footer_t3, sub_links, logo_mobile_apps } = styles;

const FooterComponent = (props: Props) => {
  const { isPostActive, footerContent, onClickHandler, authUser } = props;

  const {
    footer_heading1,
    footer_heading2,
    footer_heading3,
    logo,
    footerList1,
    footerList2,
    footerList3,
    copyright,
  } = footerContent || {};

  const footerListFuntn = (val: any) => {
    if (authUser && val.name.toLowerCase() === "sign in") return null;
    return (
      <Box
        mt={1}
        key={val.keyProp}
        className={sub_links}
        color="var(--white)"
        sx={{ textDecoration: "underline" }}
        onClick={() =>
          onClickHandler(
            val.navigationPath,
            val.redirectionPage,
            val.gaName,
            val.name
          )
        }
      >
        {val.name}
      </Box>
    );
  };

  const RenderFooterHeading = ({ heading }: { heading: string }) => (
    <Grid width={{ xs: "100%", sm: "80%", md: "70%" }}>
      <Typography
        component={"div"}
        variant="h6"
        sx={{
          textTransform: "uppercase",
          color: "var(--white)",
          fontFamily: "var(--font_family_Medium)",
          fontSize: "18px",
        }}
      >
        {heading}
      </Typography>
      <Divider
        sx={{
          bgcolor: "var(--lightgrey_6)",
          mt: "6px",
          mb: "10px",
          borderBottomWidth: "1.5px",
        }}
      />
    </Grid>
  );

  return (
    <>
      <Grid
        container
        display={{ xs: "flex" }}
        flexDirection={{ xs: "column" }}
        position="static"
        className={footer_t3}
      >
        <Grid
          container
          display={{ sm: "flex" }}
          flexDirection={{ xs: "row" }}
          rowSpacing={1}
          justifyContent="space-between"
          marginTop={{ xs: 1, md: 3 }}
          marginLeft={{ xs: 0, md: 4 }}
          p={{
            xs: "0px 30px 10px 30px",
            sm: "0px 35px 30px 38px",
            md: "0px 20px 30px 20px",
          }}
        >
          <Grid
            item
            pr={2}
            xs={12}
            sm={12}
            md={2}
            display="flex"
            flexDirection={{ sm: "row" }}
            my={{ xs: "15px", sm: "5px", md: "unset" }}
            sx={{
              flexFlow: { xs: "row", md: "column" },
              justifyContent: { md: "space-between", xs: "flex-start" },
            }}
            className={logo_mobile_apps}
          >
            {/* Logo */}
            <Grid item>
              <Grid item>
                {generateImageContainer({
                  data: logo,
                  attrs: {
                    height: "35px",
                  },
                })}
              </Grid>
            </Grid>
          </Grid>
          {/* Footer List Section */}
          <Grid item sm={12} md={10} marginTop={{ md: "0px" }}>
            <Grid container spacing={{ xs: 3, sm: 0 }}>
              <Grid item xs={12} sm={4} md={4}>
                <RenderFooterHeading heading={footer_heading1} />
                {footerList1?.map((val: any) => {
                  return footerListFuntn(val);
                })}
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <RenderFooterHeading heading={footer_heading2} />
                {footerList2?.map((val: any) => {
                  return footerListFuntn(val);
                })}
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <RenderFooterHeading heading={footer_heading3} />
                {footerList3?.map((val: any) => {
                  return footerListFuntn(val);
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item py={"10px"} sx={{ backgroundColor: "var(--primary_color)" }}>
        <Box color={"var(--white)"} textAlign="center">
          {copyright}
        </Box>
      </Grid>
    </>
  );
};

export default FooterComponent;
