import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import { ButtonCust } from "../../../widgets/Button/ButtonCust";
import { PlanProps } from "./PlanCard";
import styles from "./T6_PlanCard.module.scss";
import Config from "../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";

const T6_PlanCard = (props: PlanProps) => {
  const {
    plandata,
    planContent,
    navigate,
    borderColorAdd,
    tagLabel,
    tagBgColor,
    tagTxtColor,
    selectedLine,
    isSelected,
    PurchaseJourneyCheck,
  } = props;
  const localPlanData = {
    name: "WBC15",
    dataUnit: "MB",
    talktime: -1,
    text: -1,
    currency: "USD",
    countryCode: "USA",
    baseLinePrice: 49.95,
    additionalLinePrice: 0.0,
    discountPctg: 0.0,
    planStatus: 1,
    maxLines: 0,
    isUnlimited: false,
    isLimitedOffer: false,
    addThrottle: false,
    addDataCutOff: false,
    downloadSpeed: 3,
    planType: "HSD",
  };
  const iconOffers = [
    {
      icon: "https://strapi-config-images.s3.amazonaws.com/singleWebsite-dev/One_90b60efccb.png",
      offer: "No long-term contracts",
    },
    {
      icon: "https://strapi-config-images.s3.amazonaws.com/singleWebsite-dev/One_90b60efccb.png",
      offer: "Unlimited Talk & Text",
    },

    {
      icon: "https://strapi-config-images.s3.amazonaws.com/singleWebsite-dev/One_90b60efccb.png",
      offer: "Mobile hotspot",
    },
    {
      icon: "https://strapi-config-images.s3.amazonaws.com/singleWebsite-dev/One_90b60efccb.png",
      offer: "Powerful 5G coverage",
    },
  ];

  const mouseHoverHandler = (index: any) => {
    let parent: any = document.getElementById(`home${index}`);
    parent.setAttribute(
      "class",
      `${home_page_plan_card} ${
        borderColorAdd ? apply_border123 : ""
      } ${hover_color}`
    );
  };
  const mouseOutHandler = (index: any) => {
    let parent: any = document.getElementById(`home${index}`);
    parent.setAttribute(
      "class",
      `${home_page_plan_card} ${borderColorAdd ? apply_border123 : ""}`
    );
  };

  const selectPlanHandler = () => {
    localStorage.setItem("reachSelectedPlanLine", JSON.stringify(selectedLine));
    let planDetails: any = JSON.stringify({
      line: selectedLine,
    });
    localStorage.setItem("reachSelectedPlanDetail", planDetails);
  };
  const {
    cards_div,
    plans_div,
    plan_page_plan_card,
    separator,
    fade_out,
    save_money_area,
    plan_page_offer,
    hover_color,
    apply_border123,
    view_detail,
    home_page_plan_card,
    tag,
    title,
    save_money_img,
    plan_cost_data,
    cost,
    cost1,
    plan_heading,
    plan_price,
    center,
    plan_feature,
    feature_content,
    line,
    proceed_btn,
  } = styles;
  const {
    bestfit_tag,
    button_text,
    fiveg_tag,
    per_month_text,
    save_money,
    view_details_text,
    shareable_image,
    icon_offers,
  } = planContent;

  return (
    <>
      <Box className={cards_div + " " + center}>
        <Typography
          variant="h3"
          component={"h3"}
          className={plan_heading + " " + center}
        >
          {planContent?.heading}
        </Typography>
        <Typography
          variant="h3"
          component={"h3"}
          className={plan_price + " " + center}
        >
          ${localPlanData.baseLinePrice}/Mo
        </Typography>
        <Box className={feature_content}>
          {planContent?.plan_features?.map((item: any, i: any) => {
            return (
              <div key={i}>
                <Typography className={plan_feature + " " + center}>
                  {item.feature}
                </Typography>
                {i !== 3 && <Box className={line + " " + center}></Box>}
              </div>
            );
          })}
        </Box>
        <Box className={center} mb={"30px"}>
          <ButtonCust
            className={proceed_btn}
            variantType="primary_contained_square"
            onClick={selectPlanHandler}
          >
            {planContent.button_text}
          </ButtonCust>
        </Box>
      </Box>
    </>
  );
};

export default T6_PlanCard;
