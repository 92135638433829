import { Box } from "@mui/material";
interface DisplayTableProps {
  data?: any
  attrs?: any
  overrides?: any
  className?:any
  onClick?: any
}

export const generateImageContainer = (
  props : DisplayTableProps  
) => {
  const{data, attrs, overrides,className, onClick} = props
  return (
    <Box
      component="img"
      src={data?.data?.attributes?.url || data}
      alt={data?.data?.attributes?.alternativeText}
      width={attrs?.width}
      height={attrs?.height}
      sx={overrides?.sx}
      className={className}
      onClick={() => {
        if (onClick) {
          onClick();
        }else {
          return false;
        }
      }}
    ></Box>
  );
};
