import { useState } from "react";
import { useNavigate } from "react-router";
import { InitialActivation } from "./InitialActivation/InitialActivation";
import { ActivationStep1 } from "./ActivationStep1/ActivationStep1";
import { ActivationStep2 } from "./ActivationStep2/ActivationStep2";
import { ActivationStep3 } from "./ActivationStep3/ActivationStep3";
import { ActivationStep4 } from "./ActivationStep4/ActivationStep4";
import { InitialSecondayLine } from "./InitialActivation/InitialSecondayLine";

export const ActivationSteps = (props: any) => {
  const {
    currentLine,
    activationIntroContent,
    step1Content,
    step2Content,
    step3Content,
    step4Content,
  } = props;
  const navigate = useNavigate();
  const currentStep = () => {
    let addCardActivation = localStorage.getItem("addCardActivation");
    if (addCardActivation) {
      return 3;
    } else if (currentLine?.esim) {
      return 0;
    } else {
      if (!currentLine?.imei) {
        return 0;
      } else if (!currentLine?.simId) {
        return 2;
      } else if (currentLine?.simId) {
        return 4;
      } else if (currentLine?.imei && currentLine?.zipcode) {
        return 3;
      } else if (currentLine?.imei) {
        return 2;
      } else {
        return 0;
      }
    }
  };
  const [step, setStep] = useState(() => currentStep());

  const handleStep = (type: boolean) => {
    const currentStepNo = type ? step + 1 : step > 0 ? step - 1 : navigate(-1);
    setStep(currentStepNo || 0);
  };

  return (
    <>
      {step === 0 && currentLine?.isPrimary ? (
        <InitialActivation
          handleStep={handleStep}
          content={activationIntroContent}
          {...props}
        />
      ) : (
        step === 0 && (
          <InitialSecondayLine
            handleStep={handleStep}
            content={activationIntroContent}
            {...props}
          />
        )
      )}

      {step === 1 && (
        <ActivationStep1
          handleStep={handleStep}
          content={step1Content}
          {...props}
        />
      )}
      {step === 2 && (
        <ActivationStep2
          handleStep={handleStep}
          content={step2Content}
          {...props}
        />
      )}
      {step === 3 && (
        <ActivationStep3
          handleStep={handleStep}
          content={step3Content}
          step4Content={step4Content}
          modalContent={step2Content}
          {...props}
        />
      )}
      {step === 4 && (
        <ActivationStep4
          handleStep={handleStep}
          content={step4Content}
          modalContent={step2Content}
          {...props}
        />
      )}
    </>
  );
};

export default ActivationSteps;
