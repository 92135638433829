import { useEffect, useState } from "react";
import {
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "../../../utils/commonFunctions";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAutoPayConsentContent } from "../../../features/strapi/checkoutSlice";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";

const AutoPayConsentComp = isTemplateExisits(Config.Checkout.AutopayConsent)
  ? require(`./${Config.Checkout.AutopayConsent.template}_AutopayConsent`)
      .default
  : null;

export type AutopayConsentProps = {
  formik?: any;
  content?: any;
  isShowModal?: boolean;
  confirmToPayment?: any;
  setIsAutopayConsent: any;
};
export type AutopayProps = {
  formik?: any;
  isShowModal?: boolean;
  confirmToPayment: any;
  setIsAutopayConsent: any;
};

export const AutopayConsent = (props: AutopayProps) => {
  const dispatch = useAppDispatch();

  const { autopayConsentContent } = useAppSelector(
    (state: any) => state.strapi.checkout
  );

  const [cacheAutopayConsent, setCacheAutopayConsent] = useState<any>(null);

  useEffect(() => {
    getCache(
      "AutopayConsent",
      getAutoPayConsentContent,
      setCacheAutopayConsent,
      dispatch
    );
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("AutopayConsent", autopayConsentContent);
  }, [autopayConsentContent]);

  return (
    <AutoPayConsentComp
      formik={props.formik}
      content={cacheAutopayConsent || autopayConsentContent}
      isShowModal={props.isShowModal}
      confirmToPayment={props.confirmToPayment}
      setIsAutopayConsent={props.setIsAutopayConsent}
    />
  );
};
