import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  hubspotPost,
  suitablePlanData,
  storeFormData,
} from "../../../features/billCalculator/billCalculatorSlice";
import { comparePlansStrapi } from "../../../features/strapi/billCalculatorSlice";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import EnvConfig from "../../../config/env.config.json";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
const ComparePlansComponent = isTemplateExisits(
  Config.BillCalculator.ComparePlans
)
  ? require(`./${Config.BillCalculator.ComparePlans.template}_ComparePlans`)
      .default
  : null;
type Props = {
  pageHandler: any;
};
export const ComparePlans = (props: Props) => {
  const { pageHandler } = props;
  const dispatch = useAppDispatch();
  const [userDetails, setUserDetails] = useState<any>({});
  const [cacheComparePlans, setCacheComparePlans] = useState<any>(null);
  const { comparePlanFormValues } = useAppSelector(
    (state: any) => state?.billCalculator || {}
  );
  const { comparePlansData } = useAppSelector(
    (state: any) => state?.strapi?.billCalculator || {}
  );
  const submitHandler = (py1: any, py2: any, formikVal: any) => {
    dispatch(storeFormData(formikVal.values));
    dispatch(hubspotPost(py1));
    dispatch(suitablePlanData(py2));
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.BillCalculator,
      category: gaCategory.comparePlans,
      buttonValue: comparePlansData?.btn_name,
    });
    pageHandler();
  };
  useEffect(() => {
    let user1: any = localStorage.getItem("currentuser");
    user1 = JSON.parse(user1);
    getCache(
      "comparePlansBillCalculator",
      comparePlansStrapi,
      setCacheComparePlans,
      dispatch
    );
    if (user1) {
      if (!user1.isAnonymous) {
        const userDt = {
          email: user1 && user1.email ? user1.email : "",
          name: user1 && user1.displayName ? user1.displayName : "",
          lastname: user1?.displayName
            ? user1.displayName.split(" ")[
                user1 && user1.displayName.split(" ").length - 1
              ]
            : "",
          firstname: user1?.displayName ? user1.displayName.split(" ")[0] : "",
        };
        setUserDetails(userDt);
      }
    }
  }, []);
  useEffect(() => {
    comparePlansData !== null &&
      addMultipleCacheData("comparePlansBillCalculator", comparePlansData);
  }, [comparePlansData]);
  return comparePlansData || cacheComparePlans ? (
    <ComparePlansComponent
      comparePlanFormValues={comparePlanFormValues}
      submitHandler={submitHandler}
      userDetails={userDetails}
      comparePlansData={
        cacheComparePlans ? cacheComparePlans : comparePlansData
      }
    />
  ) : null;
};
