import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  activationIntroData: any;
  errorActivationIntroData: any;
  activationStep1Data: any;
  errorActivationStep1Data: any;
  activationStep2Data: any;
  errorActivationStep2Data: any;
  activationStep3Data: any;
  errorActivationStep3Data: any;
  activationStep4Data: any;
  errorActivationStep4Data: any;
  activationStepsData: any;
  errorActivationStepsData: any;
  activationStepsDataT3: any;
  errorActivationStepsDataT3: any;
};

const initialState: InitialState = {
  activationIntroData: null,
  errorActivationIntroData: null,
  activationStep1Data: null,
  errorActivationStep1Data: null,
  activationStep2Data: null,
  errorActivationStep2Data: null,
  activationStep3Data: null,
  errorActivationStep3Data: null,
  activationStep4Data: null,
  errorActivationStep4Data: null,
  activationStepsData: null,
  errorActivationStepsData: null,
  activationStepsDataT3: null,
  errorActivationStepsDataT3: null,
};

export const activationStep3Content = createAsyncThunk(
  "activation/activationStep3Content",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Activation.ActivationFlow.template}${StrapiApiEndPoints.activationStep3}`,
      isStrapiApi: true,
    });
  }
);

export const activationStep2Content = createAsyncThunk(
  "activation/activationStep2Content",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Activation.ActivationFlow.template}${StrapiApiEndPoints.activationStep2}`,
      isStrapiApi: true,
    });
  }
);

export const activationStep1Content = createAsyncThunk(
  "activation/activationStep1Content",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Activation.ActivationFlow.template}${StrapiApiEndPoints.activationStep1}`,
      isStrapiApi: true,
    });
  }
);

export const activationIntroContent = createAsyncThunk(
  "activation/activationIntroContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Activation.ActivationFlow.template}${StrapiApiEndPoints.activationIntro}`,
      isStrapiApi: true,
    });
  }
);

export const activationStep4Content = createAsyncThunk(
  "activation/activationStep4Content",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Activation.ActivationFlow.template}${StrapiApiEndPoints.activationStep4}`,
      isStrapiApi: true,
    });
  }
);

export const activationStepsContent = createAsyncThunk(
  "activation/activationStepsContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Activation.ActivationFlow.template}${StrapiApiEndPoints.activationSteps}`,
      isStrapiApi: true,
    });
  }
);

export const activationStepsContentT3 = createAsyncThunk(
  "activation/activationStepsContentT3",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Activation.ActivationFlow.template}${StrapiApiEndPoints.activationFlow}`,
      isStrapiApi: true,
    });
  }
);

const strapiActivationSlice = createSlice({
  name: "activation",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      activationStep3Content.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.activationStep3Data = action?.payload?.data?.data?.attributes;
        state.errorActivationStep3Data = null;
      }
    );
    builder.addCase(
      activationStep3Content.rejected,
      (state: InitialState, action: any) => {
        state.activationStep3Data = null;
        state.errorActivationStep3Data = action.error || "Something went wrong";
      }
    );
    builder.addCase(
      activationStep2Content.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.activationStep2Data = action?.payload?.data?.data?.attributes;
        state.errorActivationStep2Data = null;
      }
    );
    builder.addCase(
      activationStep2Content.rejected,
      (state: InitialState, action: any) => {
        state.activationStep2Data = null;
        state.errorActivationStep2Data = action.error || "Something went wrong";
      }
    );
    builder.addCase(
      activationStep1Content.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.activationStep1Data = action?.payload?.data?.data?.attributes;
        state.errorActivationStep1Data = null;
      }
    );
    builder.addCase(
      activationStep1Content.rejected,
      (state: InitialState, action: any) => {
        state.activationStep1Data = null;
        state.errorActivationStep1Data = action.error || "Something went wrong";
      }
    );
    builder.addCase(
      activationIntroContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.activationIntroData = action?.payload?.data?.data?.attributes;
        state.errorActivationIntroData = null;
      }
    );
    builder.addCase(
      activationIntroContent.rejected,
      (state: InitialState, action: any) => {
        state.activationIntroData = null;
        state.errorActivationIntroData = action.error || "Something went wrong";
      }
    );
    builder.addCase(
      activationStep4Content.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.activationStep4Data = action?.payload?.data?.data?.attributes;
        state.errorActivationStep4Data = null;
      }
    );
    builder.addCase(
      activationStep4Content.rejected,
      (state: InitialState, action: any) => {
        state.activationStep4Data = null;
        state.errorActivationStep4Data = action.error || "Something went wrong";
      }
    );
    builder.addCase(
      activationStepsContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.activationStepsData = action?.payload?.data?.data?.attributes;
        state.errorActivationStepsData = null;
      }
    );
    builder.addCase(
      activationStepsContent.rejected,
      (state: InitialState, action: any) => {
        state.activationStepsData = null;
        state.errorActivationStepsData = action.error || "Something went wrong";
      }
    );
    builder.addCase(
      activationStepsContentT3.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.activationStepsDataT3 = action?.payload?.data?.data?.attributes;
        state.errorActivationStepsDataT3 = null;
      }
    );
    builder.addCase(
      activationStepsContentT3.rejected,
      (state: InitialState, action: any) => {
        state.activationStepsDataT3 = null;
        state.errorActivationStepsDataT3 = action.error || "Something went wrong";
      }
    );
  },
});

export default strapiActivationSlice.reducer;
