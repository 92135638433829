import { validateEmail } from "../../../utils/commonFunctions/ValidateEmail";
// import { ButtonCust_T2, TextFieldCust_T2 } from "../../widgets";
import Config from "../../../config/env.config.json";
import { Box, Grid } from "@mui/material";
import styles from "./S4_LeadGeneration.module.scss";
import { LeadGenProps } from "./LeadGeneration";
import { ButtonCust_T2, TextFieldCust_T2 } from "../../widgets";

const LeadGenerationComponent = (props: LeadGenProps) => {
  const {
    heading,
    sub_heading,
    place_holder,
    button_text,
    success_text,
    intouch_text,
    check_icon,
  } = props.leadContent || {};
  return (
    props.leadContent && (
      <Grid
        container
        id="leadGeneration"
        className={styles.lead_generation}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          py: 8.75,
        }}
      >
        <Box
          data-testid="title"
          className={styles.lead_generation_title}
          sx={{
            fontSize: { xs: "26px", sm: "38px" },
          }}
        >
          {heading}
        </Box>
        <Box
          className={styles.lead_generation_subtitle}
          sx={{
            my: 4,
            fontSize: { xs: "16px", sm: "20px" },
            width: { xs: "80%", md: "80%", lg: "88%" },
          }}
        >
          {sub_heading}
        </Box>

        {props.emailSharedCoverage ? (
          <>
            <Grid
              container
              xs={11}
              sm={6}
              md={4.5}
              lg={3.8}
              xl={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={styles.email_success}
              >
                {/* <Box
                  component={"img"}
                  sx={{ width: "20px", height: "20px", mx: 0.5 }}
                  src={check_icon?.data?.attributes?.url}
                  alt={check_icon?.data?.attributes?.alternativeText}
                /> */}
                <Box
                  className={styles.email_success_text}
                  sx={{ fontSize: "18px" }}
                >
                  {success_text}
                </Box>
              </Box>
              <Box
                className={styles.keep_in_touch_text}
                sx={{ fontSize: "14px", textAlign: "center" }}
              >
                {intouch_text}
              </Box>
            </Grid>
          </>
        ) : (
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: { xs: "row", sm: "row" },
              justifyContent: "center",
              alignItems: { xs: "center", sm: "start" },
            }}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                props.submitHandler(e);
              }
            }}
          >
            <Grid
              sx={{
                // mr: { sm: 2 },
                mb: { xs: 0, md: 0, lg: 2 },
                width: { xs: "60%", sm: "60%", md: "26%", lg: "26%" },
              }}
            >
              <TextFieldCust_T2
                value={props.email}
                label={place_holder}
                onChange={props.emailHandler}
                className={styles.input_field}
                error={
                  (props.submitBtnClick && !props.email) ||
                  (props.submitBtnClick &&
                    props.email !== "" &&
                    !validateEmail(props.email))
                }
                helperText={props.submitBtnClick && props.helperTextHandler()}
              />
            </Grid>
            <Box>
              <ButtonCust_T2
                // className={styles.lead_generate_t4}
                variantType={"primary_contained_square"}
                onClick={(e: any) => props.submitHandler(e)}
                loading={props.submitClick}
              >
                {button_text}
              </ButtonCust_T2>
            </Box>
          </Grid>
        )}
      </Grid>
    )
  );
};

export default LeadGenerationComponent;
