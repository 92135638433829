import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./ActivationStep3.module.scss";
import { useFormik } from "formik";
import { object, string } from "yup";
import {
  LinearProgressBarCust,
  TextFieldCust,
  ButtonCust,
  NotificationCust,
} from "../../../widgets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  SIMIdCheck,
  monthInAdvance,
  monthInAdvancePost,
  activateLine,
} from "../../../../features/activation/activationSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Store } from "react-notifications-component";
import { ActivationPayNow } from "./PayNow";
import { PayFailureModal } from "./PayFailureModal";
import { ActivationManageCard } from "./ManageCard";
import { PhoneNoPattern } from "../../../../utils/regexPatterns";
import { getCC } from "../../../../features/account/accountSlice";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import EnvConfig from "../../../../config/env.config.json";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { useNavigate } from "react-router-dom";
import { ActivationFailedModal } from "../ActivationStep4/ActivationFailedModal";
import { HelpSupportModal } from "../ActivationStep2/SupportModal";


export const ActivationStep3 = (props: any) => {
  const { handleStep, gaHandler, currentLine, updatingCustomer, content, modalContent, step4Content } =
    props;
  let customerDetails: any = localStorage?.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);
  const [btnLoader, setBtnLoader] = useState(false);
  const [payBtnLoader, setPayBtnLoader] = useState(false);
  const dispatch = useAppDispatch();
  const [btnClicked, setBtnClicked] = useState(false);
  const [payBtnClicked, setPayBtnClicked] = useState(false);
  const [payNowFailedModal, setPayNowFailedModal] = useState(false);
  const [showContent, setShowContent] = useState("main");
  const [showCardChangeModal, setShowCardChangeModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [openSupportDialog, setOpenSupportDialog] = useState(false);

  const {
    simIdCheckData,
    errorMonthInAdvance,
    monthInAdvancePostData,
    errorMonthInAdvancePostData,
  } = useAppSelector((state: any) => state?.activation || {});
  const { sp3_title, step_txt, sim_check, msgs } = content;
  const { isValid } = simIdCheckData || {};
  const { esim, id, reachPlanId, imei, make, model } =
    currentLine || {};
  const simID = localStorage.getItem("simId");
  interface formikintitaltypes {
    simId: string;
  }
  const initialformstep1data: formikintitaltypes = {
    simId: simID || currentLine?.simId || "",
  };
  const navigate = useNavigate();
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.activation,
      category: category,
      buttonValue: value,
    });
  };
  const activationStep3Schema = object({
    simId: string().trim().required(msgs?.empty_msg).min(19, msgs?.valid_msg),
  });
  const handleBack = () => {
    if (showContent === "pay" && !currentLine?.esim) {
      setShowContent("main");
    } else {
      handleStep(false);
    }
  };
  const manageCardFg = () => {
    setShowCardChangeModal(true);
  };
  const formik = useFormik({
    initialValues: initialformstep1data,
    validationSchema: activationStep3Schema,
    onSubmit: (values: any) => {
      setBtnLoader(true);
      setBtnClicked(true);
      const py: any = {
        customerId: currentLine?.id,
        iccid: values.simId,
      };
      localStorage.setItem("simId", values.simId);

      gaHandler("SIM ID check");
      dispatch(SIMIdCheck({ payload: py })).then((res) => {
        setBtnLoader(false);
        if (res?.payload?.data?.data?.isValid) {
          window.scroll(0, 0);
          if (!currentLine?.isMonthInAdvancePaid) {
            setShowContent("pay");
          } else {
            handleStep(true);
          }
        } else {
          setShowContent("main");
        }
      });
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    if (currentLine?.esim) {
      setShowContent("pay");
    } else {
      setShowContent("main");
    }
  }, []);
  useEffect(() => {
    if (simIdCheckData) {
      if (isValid && btnClicked) {
        setBtnLoader(false);
        setBtnClicked(false);

        NotificationCust({
          message: msgs?.simId_noti1,
          type: "success",
          duration: 2000,
          id: "activationSimCardSuccess",
          showIcon: true,
        });
        const py: any = {
          customerId: currentLine?.id,
        };
        dispatch(monthInAdvance({ payload: py }));

        dispatch(getCC({ customerId: customerDetails?.id }));
      }
      if (!isValid && btnClicked) {
        setBtnLoader(false);
        setBtnClicked(false);
        NotificationCust({
          message: msgs?.simId_noti2,
          type: "danger",
          duration: 2000,
          id: "activationSimCardInvalid",
          showIcon: true,
        });
      }
    }
    return () => {
      Store.removeNotification("activationSimCardSuccess");
      Store.removeNotification("activationSimCardInvalid");
    };
  }, [simIdCheckData, isValid, currentLine]);

  const payNowConfirm = () => {
    setPayBtnLoader(true);
    setPayBtnClicked(true);
    const py: any = {
      customerId: currentLine?.id,
    };
    dispatch(
      monthInAdvancePost({
        payload: py,
      })
    ).then((res: any) => {
      if (!esim) { setPayBtnLoader(false); }
      if (res?.payload?.data?.status === "SUCCESS") {
        updatingCustomer({ isMonthInAdvancePaid: true });
        // activation api if esim true
        if (esim) {
          const mnpPy = {
            esim: esim,
            customerId: id,
            reachPlanId: reachPlanId,
            imei: imei,
            make: make,
            model: model,
            skipHold: true,
          };
          dispatch(activateLine({ payload: mnpPy })).then(
            (res: any) => {
              setPayBtnLoader(false)
              if (res?.payload?.data?.status === "SUCCESS") {
                navigate(RoutingURLs.postActivationHome);
              } else {
                setShowModal(true);
              }
            }
          );
        }
      } else {
        setPayBtnLoader(false);
        setPayNowFailedModal(true);
      }
    });
  };

  useEffect(() => {
    let addCardActivation = localStorage.getItem("addCardActivation");
    if (addCardActivation) {
      if (currentLine?.imei && currentLine?.zipcode) {
        setShowContent("pay");
      } else {
        setShowContent("main");
      }
    }
  }, []);
  useEffect(() => {
    if (
      (monthInAdvancePostData && payBtnClicked && !esim) ||
      (currentLine.isMonthInAdvancePaid && payBtnClicked && !esim)
    ) {
      setPayBtnLoader(false);
      setPayBtnClicked(false);
      handleStep(true);
    }
    if (
      errorMonthInAdvancePostData &&
      errorMonthInAdvancePostData.status === "FAILURE" &&
      payBtnClicked && !esim
    ) {
      if (errorMonthInAdvancePostData.resCode === 40020) {
        setPayNowFailedModal(false);
        setPayBtnLoader(false);
        setPayBtnClicked(false);
        handleStep(true);
      } else {
        setPayBtnLoader(false);
        setPayBtnClicked(false);
        setPayNowFailedModal(true);
      }
    }
  }, [monthInAdvancePostData, errorMonthInAdvancePostData]);

  useEffect(() => {
    if (errorMonthInAdvance && errorMonthInAdvance.status === "FAILURE") {
      setShowContent("main");
      NotificationCust({
        message: msgs?.fail_noti,
        type: "danger",
        duration: 2000,
        id: "activationDefaultError",
        showIcon: true,
      });
    }
  }, [errorMonthInAdvance]);
  const showCardChangeModalHandle = () => {
    setShowCardChangeModal(false);
  };
  const closeActivationFailModal = () => {
    setShowModal(false);
  }
  const openSupportModal = () => {
    setShowModal(false);
    setOpenSupportDialog(true);
  };
  const onCloseSupportDialog = () => {
    setShowModal(true);
    setOpenSupportDialog(false);
  };
  return (
    <>
      <Box className={styles.step3_main}>
        <Box className={styles.heading_container}>
          <ArrowBackIcon
            sx={{ ml: { md: "30px", sm: "25px", xs: "4%" } }}
            className={styles.arrow_icon}
            onClick={handleBack}
          />
        </Box>

        {showContent === "main" && (
          <>
            <Box className={styles.heading_container}>
              <Typography className={styles.title_main}>{sp3_title}</Typography>
            </Box>
            <Box className={styles.stepper_content}>
              <Typography className={styles.stepper_txt}>{step_txt}</Typography>
              <Box width={{ xs: "90%", sm: "50%", md: "35%" }} mb="25px">
                <LinearProgressBarCust value={75} variant={"determinate"} />
              </Box>
            </Box>
            <Box width={{ xs: "90%", sm: "65%" }} m="0 auto 40px">
              <Box
                className={styles.inner_container}
                p={{ xs: "30px 15px", sm: "30px 50px" }}
              >
                <Typography className={styles.step3_heading}>
                  {sim_check?.desc}
                </Typography>
                <Box
                  className={styles.step3_sim_number}
                  width={{ md: "50%", sm: "80%", xs: "95%" }}
                >
                  <TextFieldCust
                    value={formik.values.simId}
                    name="simId"
                    onChange={(e: any) => {
                      if (
                        PhoneNoPattern.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        formik.setFieldValue(e.target.name, e.target.value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    type={window.screen.width <= 600 ? "number" : "text"}
                    label={sim_check?.sim_ph}
                    maxlength={19}
                    error={
                      formik.touched?.simId && formik.errors?.simId
                        ? true
                        : false
                    }
                    helperText={formik.touched?.simId && formik.errors?.simId}
                  />
                  <Typography className={styles.step3_limit_div}>
                    {formik.values.simId.length}/19
                  </Typography>
                </Box>
                <Box className={styles.step3_btn}>
                  <ButtonCust
                    disabled={
                      formik.values?.simId?.length === 0
                        ? true
                        : !formik?.isValid
                    }
                    onClick={() => {
                      formik.handleSubmit();
                      GAAndFBEventsHandler(
                        gaCategory.activation,
                        sim_check?.check_btn
                      );
                    }}
                    loading={btnLoader}
                  >
                    {sim_check?.check_btn}
                  </ButtonCust>
                </Box>
              </Box>
            </Box>
          </>
        )}
        {showContent === "pay" && (
          <ActivationPayNow
            payNowConfirm={payNowConfirm}
            payBtnLoader={payBtnLoader}
            manageCardFg={manageCardFg}
            content={content}
            {...props}
          />
        )}
        <ActivationManageCard
          showCardChangeModal={showCardChangeModal}
          setShowCardChangeModal={showCardChangeModalHandle}
          isModalRequired={true}
        />
        {payNowFailedModal && (
          <PayFailureModal
            modalFg={payNowFailedModal}
            closeHandler={() => setPayNowFailedModal(false)}
            content={content}
          />
        )}
        {showModal && (
          <ActivationFailedModal
            showModal={showModal}
            openSupportModal={openSupportModal}
            closeActivationFailModal={closeActivationFailModal}
            content={step4Content}
            currentLine={currentLine}
          />
        )}
        <HelpSupportModal
          open={openSupportDialog}
          onClose={() => onCloseSupportDialog()}
          title={step4Content?.c_sup_title}
          showClose={false}
          showBackBtn={true}
          content={modalContent}
        />
      </Box>
    </>
  );
};
