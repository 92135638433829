import { Button, ButtonProps, CircularProgress } from "@mui/material";
import styles from "./ButtonCust_T2.module.scss";

interface TButtonProps extends ButtonProps {
  variant?: any;
  className?: any;
  variantType?: string;
  loading?: boolean;
  children?: any;
}

export function ButtonCust_T2({
  variant = "outlined",
  className,
  variantType,
  loading,
  ...props
}: TButtonProps) {
  // cabo:
  // Primary:primary_contained_square
  // Secondary:primary_underlined
  // Tertiary:NA

  //Button Types:
  // primary_underlined
  // primary_contained_square

  const getButtonVariantClass = (variantTypeName: string | undefined) => {
    switch (variantTypeName) {
      case "primary_underlined": {
        return styles.custom_primary_underlined;
      }
      case "primary_contained_square": {
        return styles.custom_primary_contained_square;
      }
      default: {
        return styles.custom_primary_contained_square;
      }
    }
  };
  return (
    <Button
      {...props}
      className={`${className} ${
        props.disabled
          ? `${getButtonVariantClass(variantType)} ${
              styles.custom_disabled_button
            }`
          : getButtonVariantClass(variantType)
      }`}
      onClick={!loading ? props?.onClick : (e) => e.preventDefault()}
    >
      {loading ? <CircularProgress /> : props.children}
    </Button>
  );
}
