import { ChangeEvent, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import EnvConfig from "../../../config/env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { updateToHubspot } from "../../../utils/commonFunctions/hubspot";
import { leadGenModalContent } from "../../../features/strapi/homePageSlice";
import {
  getInTouchApi,
  resetGetInTouch,
  resetGetInTouchError,
} from "../../../features/homepage/homepageSlice";
import { validateEmail } from "../../../utils/commonFunctions/ValidateEmail";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { globalVal } from "../../../utils/globalValues/globalvalues";
import { geocodeByAddress } from "react-places-autocomplete";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import TagManager from "react-gtm-module";
const LeadGenerationModalComponent = isTemplateExisits(
  Config.Home.LeadGenerationModal
)
  ? require(`./${Config.Home.LeadGenerationModal.template}_LeadGenerationModal`)
      .default
  : null;

interface LeadGenerationModalProps {
  open: boolean;
  onClose: () => void;
  isSuccessModal?: boolean;
  isErrorModal?: boolean;
  openSuccessHandler?: () => void;
  openErrorHandler?: () => void;
}

export type LeadGenModalProps = {
  open: boolean;
  isLoggedIn: boolean;
  isSuccessModal: boolean;
  isErrorModal: boolean;
  heading: string;
  subHeading: string;
  loading: boolean;
  openSuccessHandler?: any;
  email: string;
  checkAddress: string;
  emailError: string;
  handleChange: any;
  handleSelect: any;
  submitHandler: any;
  closeHandler: any;
  checkAddressHandler: any;
  leadContent: any;
  resetErrorHandler?: any;
};

export const LeadGenerationModal = ({
  open,
  onClose,
  isSuccessModal = false,
  isErrorModal = false,
  openSuccessHandler = () => null,
  openErrorHandler = () => null,
}: LeadGenerationModalProps) => {
  const dispatch = useAppDispatch();
  const [cacheDataLead, setCacheDataLead] = useState<any>(null);
  const [email, setEmail] = useState<string>("");
  const [checkAddress, setCheckAddress] = useState("");
  const [emailError, setEmailError] = useState("");
  const [coverageError, setCoverageError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [coverageAddress, setCoverageAddress] = useState({
    address1: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });

  const { getInTouch, getInTouchError } = useAppSelector(
    (state: any) => state.homepage
  );
  const { leadGenModalContentSuccess } = useAppSelector(
    (state: any) => state.strapi.homepage
  );

  const { isRenderLogin } = useAppSelector((state: any) => state.checkout);

  useEffect(() => {
    getAuthData();
  }, [isRenderLogin]);

  useEffect(() => {
    getCache("LeadGenModal", leadGenModalContent, setCacheDataLead, dispatch);
    dispatch(resetGetInTouch());
    return () => {
      dispatch(resetGetInTouch());
    };
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("LeadGenModal", leadGenModalContentSuccess);
  }, [leadGenModalContentSuccess]);

  useNonInitialEffect(() => {
    if (!isEmpty(getInTouch) && getInTouch.data.status === "SUCCESS") {
      setLoading(false);
      closeHandler();
      openSuccessHandler();
    }
  }, [getInTouch]);

  useNonInitialEffect(() => {
    if (!isEmpty(getInTouchError)) {
      setLoading(false);
      openErrorHandler();
    }
  }, [getInTouchError]);

  const resetErrorHandler = () => {
    dispatch(resetGetInTouchError());
  };

  const getAuthData = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user: any) => {
      if (user?.displayName) {
        setEmail(user?.email || "");
        setIsLoggedIn(true);
      }
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const checkAddressHandler = (e: any) => {
    let checkAddArray = e.split(",");
    let coverageAddressNew = {
      address1: "",
      city: "",
      country: "",
      state: "",
      zip: "",
    };
    if (e.includes(",")) {
      coverageAddressNew = {
        address1: checkAddArray[0],
        city: checkAddArray[1],
        country: checkAddArray[2],
        state: checkAddArray[3],
        zip: checkAddArray[4],
      };
    }
    setCheckAddress(e);
    setCoverageAddress(
      !isEmpty(coverageAddress) ? coverageAddressNew : coverageAddress
    );
  };
  const handleSelect = (address: any) => {
    geocodeByAddress(address)
      .then(async (results: any) => {
        let city: string = "",
          state: string = "",
          zip: string = "",
          country: string = "",
          route: string = "",
          streetNumber: string = "";
        results &&
          results[0] &&
          results[0].address_components &&
          results[0].address_components.map((el: any) => {
            state = el.types.includes("administrative_area_level_1")
              ? el.short_name
              : state;
            zip = el.types.includes("postal_code") ? el.short_name : zip;
            city = el.types.includes("locality")
              ? el.long_name
              : el.types.includes("sublocality")
              ? el.long_name
              : city;
            streetNumber = el.types.includes("street_number")
              ? el.short_name
              : streetNumber;
            route = el.types.includes("route") ? el.long_name : route;
            country = el.types.includes("country") ? el.short_name : country;
            return null;
          });
        if (streetNumber && route) {
          setCoverageAddress({
            address1: streetNumber + " " + route,
            city,
            state,
            country,
            zip,
          });
          setCheckAddress(
            streetNumber +
              " " +
              route +
              ", " +
              city +
              ", " +
              state +
              ", " +
              country +
              ", " +
              zip
          );
          setCoverageError(false);
        } else {
          setCoverageAddress({
            address1: "",
            city,
            state,
            country,
            zip,
          });
          setCheckAddress(city + ", " + state + ", " + country + ", " + zip);
          setCoverageError(true);
        }
      })
      .catch((error) => console.error("error", error));
  };

  const submitHandler = (e: any) => {
    if (validateEmail(email)) {
      setLoading(true);
      setEmailError("");
      updateToHubspot(e, email);
      dispatch(
        getInTouchApi({
          data: {
            emailId: email,
            hubspotMap: {
              service_address: checkAddress,
              email: email,
              is_test: globalVal.hubspot_is_test,
              is_qa: globalVal.hubspot_is_qa,
              lead_gen_form_submitted: "True",
            },
          },
        })
      );

      TagManager.dataLayer({
        dataLayer: {
          event: "submit_email_address",
        },
      });
    } else {
      setEmailError(
        cacheDataLead?.email_error_2 ||
          leadGenModalContentSuccess?.email_error_2
      );
    }
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.home,
      category: gaCategory.leadGeneration,
      buttonValue: "Submit",
    });
  };

  const closeHandler = () => {
    onClose();
    setCheckAddress("");
    setEmail("");
    setEmailError("");
    setLoading(false);
  };

  return (
    <LeadGenerationModalComponent
      open={open}
      isLoggedIn={isLoggedIn}
      isSuccessModal={isSuccessModal}
      isErrorModal={isErrorModal}
      loading={loading}
      email={email}
      checkAddress={checkAddress}
      emailError={emailError}
      handleChange={handleChange}
      checkAddressHandler={checkAddressHandler}
      handleSelect={handleSelect}
      submitHandler={submitHandler}
      closeHandler={closeHandler}
      leadContent={cacheDataLead ? cacheDataLead : leadGenModalContentSuccess}
      resetErrorHandler={resetErrorHandler}
    />
  );
};
