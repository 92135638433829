import { useContext, useEffect, useState } from "react";
import { HomeBanner } from "../../../common/HomeBanner";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { SimpleModal, ButtonCust } from "../../../widgets";
import EnvConfig from "../../../../config/env.config.json";
import { Box } from "@mui/material";
import Styles from "./MyAccountBanner.module.scss";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
import { MyAccountWrapper } from "../MyAccountWrapper/MyAccountWrapper";
import { fetchPhoneModalContent } from "../../../../features/strapi/myAccountSlice";
import { RoutingURLs } from "../../../../config/RoutingURLs";

export const MyAccountBanner = () => {
  const dispatch = useAppDispatch();
  const isdelivered = useContext(MyAccountWrapper);
  const navigate = useNavigate();
  const [isActivate, setIsActivate] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<any>("");
  const { phoneUnlockContent } = useAppSelector(
    (state: any) => state.strapi.myAccount
  );
  useEffect(() => {
    dispatch(fetchPhoneModalContent());
  }, []);

  useNonInitialEffect(() => {
    setModalContent(phoneUnlockContent);
  }, [phoneUnlockContent]);

  const activateHandler = () => {
    setIsActivate(true);
  };
  const onHide = () => {
    setIsActivate(false);
  };
  const viewTrackingOrders = () => {
    const customerDetails = JSON.parse(
      localStorage?.getItem("customerDetail") || "null"
    );
    customerDetails?.esim
      ? navigate(RoutingURLs.activateEsim, { state: "isTrack" })
      : navigate(RoutingURLs.trackOrder, { state: "isTrack" });
  };
  const { modal_content, border_bottom, head, try_again, sub_head } = Styles;
  const {
    title,
    subheading,
    learn_text,
    learn_url,
    button_text,
  } = modalContent;

  return (
    <>
      {isdelivered !== undefined && isdelivered?.isdelivered ? (
        <HomeBanner
          pageName={"MyAccount"}
          apiEndPoint={"postSimBanner"}
          onButton1Click={activateHandler}
          onButton2Click={viewTrackingOrders}
        />
      ) : null}
      {isdelivered !== undefined && !isdelivered?.isdelivered ? (
        <HomeBanner
          pageName={"MyAccount"}
          apiEndPoint={"preSimBanner"}
          onButton1Click={viewTrackingOrders}
        />
      ) : null}
      {modalContent && (
        <SimpleModal
          isShowModal={isActivate}
          onCloseModal={() => onHide()}
          showClose={true}
        >
          <Box className={modal_content}>
            <Box className={head}>{title}</Box>
            <Box className={border_bottom}></Box>
            <Box className={sub_head}>{subheading}</Box>
            <Box
              className={try_again}
              onClick={() => window.open(learn_url, "_blank")}
            >
              {learn_text}
            </Box>
            <Box>
              <ButtonCust
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                onClick={() => navigate("/activation")}
              >
                {button_text}
              </ButtonCust>
            </Box>
          </Box>
        </SimpleModal>
      )}
    </>
  );
};
