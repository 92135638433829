import { Box } from "@mui/material";
import styles from "./LinesTab.module.scss";
type Props = {
  value: any;
  handleChange: any;
  totalCount: number;
  linesTabContent?: any;
};

export const LinesTab = (props: Props) => {
  const { value, handleChange, totalCount, linesTabContent } = props;
  const list: any = Array.from(Array(totalCount).keys());
  const altText = (index: any, imagesrc?: any) => {
    if (imagesrc)
      return (
        linesTabContent && linesTabContent[index]?.image?.data?.attributes.url
      );
    return linesTabContent && linesTabContent[index]?.alt_image_text;
  };
  const { tab_component, tab_div, tab_detail_active, tab_detail } = styles;

  return (
    linesTabContent && (
      <Box
        sx={{
          mx: "auto",
          mt: 5.7,
          px: 3.8,
          py: 1,
          borderRadius: 1,
          width: "fit-content",
          display: "flex",
        }}
        className={tab_component}
      >
        {list.map((number: number) => {
          return (
            <Box
              key={number}
              className={tab_div}
              onClick={() => handleChange(number + 1)}
            >
              <Box
                component={"img"}
                src={altText(number, "imagesrc")}
                alt={altText(number)}
                sx={{
                  height: "45px",
                  width: "auto",
                  position: "absolute",
                  top: "-52px",
                  display: value !== number + 1 ? "none" : "",
                }}
              />

              <Box
                sx={{ p: 0.25, fontFamily: "var(--font_family_Bold)" }}
                className={
                  value === number + 1 ? tab_detail_active : tab_detail
                }
              >
                {number + 1}
              </Box>
            </Box>
          );
        })}
      </Box>
    )
  );
};
