import { Box, Typography } from "@mui/material";
import styles from "./IncDecButton.module.scss";

interface IncDecButtonProps {
  unit?: string;
  step: number;
  maxValue: number;
  minValue: number;
  value: number;
  countHandler: Function;
  customStyles?: any;
  isUnitPlural?: boolean;
}

export const IncDecButtonCmp = ({
  unit,
  step,
  maxValue,
  minValue,
  value,
  countHandler,
  customStyles,
  isUnitPlural,
}: IncDecButtonProps) => {
  const handleLineSelection = (actionType: string) => {
    if (value < maxValue && actionType === "add") {
      value = value + step;
      countHandler(value);
    }
    if (value > minValue && actionType === "sub") {
      value = value - step;
      countHandler(value);
    }
  };
  return (
    <Box
      display="flex"
      component="span"
      justifyContent="center"
      alignItems="center"
      color="var(--primary_color)"
    >
      <button
        className={
          value <= minValue
            ? `${styles.line_count_button} ${styles.button_disable}`
            : `${styles.line_count_button}`
        }
        onClick={() => handleLineSelection("sub")}
        disabled={value <= minValue}
        style={
          customStyles && customStyles.buttonStyles
            ? customStyles.buttonStyles
            : {}
        }
      >
        -
      </button>
      <Typography
        sx={{ fontFamily: "var(--font_family_Semibold)" }}
        className={styles.inputValue}
        style={
          customStyles && customStyles.inputStyles
            ? customStyles.inputStyles
            : {}
        }
      >
        {value} &nbsp;
        {unit
          ? isUnitPlural
            ? value === 1
              ? `${unit}`
              : `${unit}s`
            : unit
          : ""}
      </Typography>
      <button
        className={
          value === maxValue
            ? `${styles.line_count_button} ${styles.button_disable}`
            : `${styles.line_count_button}`
        }
        onClick={() => handleLineSelection("add")}
        disabled={value === maxValue}
        style={
          customStyles && customStyles.buttonStyles
            ? customStyles.buttonStyles
            : {}
        }
      >
        &#43;
      </button>
    </Box>
  );
};
