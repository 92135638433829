import Config from "../../../config/env.config.json";

export const getLineCharges = (planDetails: any) => {
  if (Config.BROADBAND) {
    return Number(planDetails?.baseLinePrice);
  }
  if (planDetails?.numberOfLines && planDetails.selectedPlan?.baseLinePrice) {
    return Number(planDetails.selectedPlan.baseLinePrice);
  } else {
    return 0;
  }
};

export const getAdditionalLineCharges = (planDetails: any) => {
  const additionalNumberOfLines = planDetails?.numberOfLines - 1;
  if (
    planDetails?.numberOfLines > 1 &&
    planDetails?.selectedPlan?.additionalLinePrice
  ) {
    if (planDetails.selectedPlan.addLineSplitPrice) {
      return (
        Number(additionalNumberOfLines) *
        Number(
          planDetails?.selectedPlan?.addLineSplitPrice[additionalNumberOfLines]
        )
      );
    } else {
      return (
        Number(additionalNumberOfLines) *
        Number(planDetails?.selectedPlan?.additionalLinePrice)
      );
    }
  } else {
    return 0;
  }
};

export const getPlanTaxableAmount = (planDetails: any) => {
  return getLineCharges(planDetails) + getAdditionalLineCharges(planDetails);
};
