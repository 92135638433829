import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";

type InitialState = {
  bookingSlotsList: any;
  errorBookingSlots: any;
  cancelInstall: any;
  cancelInstallPending: any;
  errCancelInstall: any;
  bookingSlotsWithDates: any;
  errorBookingSlotsWithDates: any;
  createInstallationActivitySuccess: any;
  createInstallationActivityPending: any;
  createInstallationActivityError: any;
  createInstallationActivityWithPaySuccess: any;
  createInstallationActivityWithPayPending: any;
  createInstallationActivityWithPayError: any;
  activityStatus: any;
  errActivityStatus: any;
  rescheduleInstallationSuccess: any;
  rescheduleInstallationError: any;
  reOpenInstallationSuccess: any;
  reOpenInstallationError: any;
  serviceOrderHistory: any;
  serviceOrderHistoryPending: any;
  errorServiceOrderHistory: any;
  createTroubleCallActivitySuccess: any;
  createTroubleCallActivityError: any;
};
const initialState: InitialState = {
  bookingSlotsList: null,
  errorBookingSlots: null,
  cancelInstall: null,
  cancelInstallPending: null,
  errCancelInstall: null,
  bookingSlotsWithDates: null,
  errorBookingSlotsWithDates: null,
  createInstallationActivitySuccess: null,
  createInstallationActivityPending: null,
  createInstallationActivityError: null,
  createInstallationActivityWithPaySuccess: null,
  createInstallationActivityWithPayPending: null,
  createInstallationActivityWithPayError: null,
  activityStatus: null,
  errActivityStatus: null,
  rescheduleInstallationSuccess: null,
  rescheduleInstallationError: null,
  reOpenInstallationSuccess: null,
  reOpenInstallationError: null,
  serviceOrderHistory: null,
  serviceOrderHistoryPending: null,
  errorServiceOrderHistory: null,
  createTroubleCallActivitySuccess: null,
  createTroubleCallActivityError: null,
};

export const cancelInstallation = createAsyncThunk(
  "fsm/cancel",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "put",
        url: `${ApiEndPoints.cancelInstallation}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const bookingSlots = createAsyncThunk(
  "fsm/bookingSlots",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.fsmBookingSlots}${action.customerID}`,
        body: action.bookingSlotObject,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const bookingSlotsWithCustomDates = createAsyncThunk(
  "fsm/bookingSlotsWithCustomDates",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.fsmBookingSlots}${action.customerID}`,
        body: action.bookingSlotObject,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createInstallationActivity = createAsyncThunk(
  "fsm/createInstallationActivity",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.createInstallationActivity}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createInstallationActivityWithPayment = createAsyncThunk(
  "fsm/createInstallationActivityWithPayment",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.createInstallationActivityWithPayment}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createTroubleCallActivity = createAsyncThunk(
  "fsm/createTroubleCallActivity",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.createTroubleCallActivity}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getActivityStatus = createAsyncThunk(
  "fsm/getActivityStatus",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.activityStatus}${action.activityId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const rescheduleInstallationActivity = createAsyncThunk(
  "fsm/rescheduleInstallationActivity",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "put",
        url: `${ApiEndPoints.rescheduleInstallationActivity}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const reOpenInstallationActivity = createAsyncThunk(
  "fsm/reOpenInstallationActivity",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "put",
        url: `${ApiEndPoints.reOpenInstallationActivity}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
// Get all service order history
export const getAllServiceOrderHistory = createAsyncThunk(
  "fsm/getAllServiceOrderHistory",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.getAllServiceOrderHistory}${action.customerId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const fsmSlice = createSlice({
  name: "fsm",
  initialState,
  reducers: {
    resetActivityStatus: (state) => {
      state.activityStatus = null;
    },
    resetCancelState: (state) => {
      state.cancelInstall = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      bookingSlots.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.bookingSlotsList = action.payload.data;
        state.errorBookingSlots = null;
      }
    );
    builder.addCase(bookingSlots.rejected, (state, action) => {
      state.bookingSlotsList = null;
      state.errorBookingSlots = action.payload || "Something went wrong";
    });
    builder.addCase(
      cancelInstallation.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.cancelInstall = action?.payload?.data;
        state.cancelInstallPending = false;
        state.errCancelInstall = null;
      }
    );
    builder.addCase(cancelInstallation.pending, (state, action) => {
      state.cancelInstall = null;
      state.cancelInstallPending = true;
      state.errCancelInstall = null;
    });
    builder.addCase(cancelInstallation.rejected, (state, action) => {
      state.cancelInstall = null;
      state.cancelInstallPending = false;
      state.errCancelInstall = action?.payload || "Something went wrong";
    });
    builder.addCase(
      bookingSlotsWithCustomDates.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.bookingSlotsWithDates = action.payload.data;
        state.errorBookingSlotsWithDates = null;
      }
    );
    builder.addCase(bookingSlotsWithCustomDates.rejected, (state, action) => {
      state.bookingSlotsWithDates = null;
      state.errorBookingSlotsWithDates =
        action.payload || "Something went wrong";
    });
    builder.addCase(createInstallationActivity.pending, (state, action) => {
      state.createInstallationActivitySuccess = null;
      state.createTroubleCallActivitySuccess = null;
      state.createInstallationActivityPending = true;
      state.createInstallationActivityError = null;
    });
    builder.addCase(
      createInstallationActivity.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createInstallationActivitySuccess = action.payload.data;
        state.createInstallationActivityPending = false;
        state.createInstallationActivityError = null;
      }
    );
    builder.addCase(createInstallationActivity.rejected, (state, action) => {
      state.createInstallationActivitySuccess = null;
      state.createInstallationActivityPending = false;
      state.createInstallationActivityError =
        action.payload || "Something went wrong";
    });

    builder.addCase(
      createInstallationActivityWithPayment.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createInstallationActivityWithPaySuccess = action.payload.data;
        state.createInstallationActivityWithPayPending = false;
        state.createInstallationActivityWithPayError = null;
      }
    );
    builder.addCase(
      createInstallationActivityWithPayment.pending,
      (state, action) => {
        state.createInstallationActivityWithPaySuccess = null;
        state.createInstallationActivityWithPayPending = true;
        state.createInstallationActivityWithPayError = null;
      }
    );
    builder.addCase(
      createInstallationActivityWithPayment.rejected,
      (state, action) => {
        state.createInstallationActivityWithPaySuccess = null;
        state.createInstallationActivityWithPayPending = false;
        state.createInstallationActivityWithPayError =
          action.payload || "Something went wrong";
      }
    );
    builder.addCase(createTroubleCallActivity.pending, (state, action) => {
      state.createInstallationActivitySuccess = null;
      state.createTroubleCallActivitySuccess = null;
    });
    builder.addCase(
      createTroubleCallActivity.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createTroubleCallActivitySuccess = action.payload.data;
        state.createTroubleCallActivityError = null;
      }
    );

    builder.addCase(createTroubleCallActivity.rejected, (state, action) => {
      state.createTroubleCallActivitySuccess = null;
      state.createTroubleCallActivityError = action.payload;
    });

    builder.addCase(
      getActivityStatus.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.activityStatus = action.payload.data;
        state.errActivityStatus = null;
      }
    );
    builder.addCase(getActivityStatus.rejected, (state, action) => {
      state.activityStatus = null;
      state.errActivityStatus = action.payload || "Something went wrong";
    });
    builder.addCase(
      rescheduleInstallationActivity.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.rescheduleInstallationSuccess = action.payload.data;
        state.rescheduleInstallationError = null;
      }
    );
    builder.addCase(
      rescheduleInstallationActivity.rejected,
      (state, action) => {
        state.rescheduleInstallationSuccess = null;
        state.rescheduleInstallationError =
          action.payload || "Something went wrong";
      }
    );
    builder.addCase(
      reOpenInstallationActivity.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reOpenInstallationSuccess = action.payload.data;
        state.reOpenInstallationError = null;
      }
    );
    builder.addCase(reOpenInstallationActivity.rejected, (state, action) => {
      state.reOpenInstallationSuccess = null;
      state.reOpenInstallationError = action.payload || "Something went wrong";
    });

    builder.addCase(
      getAllServiceOrderHistory.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.serviceOrderHistory = action?.payload?.data;
        state.serviceOrderHistoryPending = false;
        state.errorServiceOrderHistory = null;
      }
    );
    builder.addCase(getAllServiceOrderHistory.pending, (state) => {
      state.serviceOrderHistory = null;
      state.serviceOrderHistoryPending = true;
      state.errorServiceOrderHistory = null;
    });
    builder.addCase(getAllServiceOrderHistory.rejected, (state, action) => {
      state.serviceOrderHistory = null;
      state.serviceOrderHistoryPending = false;
      state.errorServiceOrderHistory =
        action?.payload || "Something went wrong";
    });
  },
});

export const { resetActivityStatus, resetCancelState } = fsmSlice.actions;

export default fsmSlice.reducer;
