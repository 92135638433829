import filter from "lodash/filter";
import { useEffect, useRef, useState } from "react";
import styles from "./T1_PlanCard.module.scss";
import { ButtonCust } from "../../../widgets/Button/ButtonCust";
import Config from "../../../../config/env.config.json";
import { PlanProps } from "./PlanCard";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import { Box } from "@mui/material";
import clsx from "clsx";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../../Types/GoogleAnalytics";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";

export const calculateUnlimitedCost = (data: any, selectedLine: any) => {
  const SELECTED_LINE = selectedLine;

  let planCost = 0;
  let planPerLinesCost = 0;
  if (data?.addLineSplitPrice) {
    let linePrice1 = 0;
    for (let i = 1; i <= SELECTED_LINE - 1; i++) {
      linePrice1 = linePrice1 + data?.addLineSplitPrice[i];
    }
    planCost =
      ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 + linePrice1;
    planPerLinesCost = planCost / SELECTED_LINE;
  } else {
    planCost =
      ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
      (SELECTED_LINE - 1) * data?.additionalLinePrice;
    planPerLinesCost = Math.ceil(planCost / SELECTED_LINE);
  }
  return {
    planCost,
    planPerLinesCost,
  };
};

export const calculateCost = (data: any, selectedLine: any) => {
  const SELECTED_LINE = selectedLine;
  const planCost =
    ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
    (SELECTED_LINE - 1) * data?.additionalLinePrice;
  const planPerLinesCost = Math.ceil(planCost / SELECTED_LINE);
  return {
    planCost,
    planPerLinesCost,
  };
};
const T1_PlanCard = (props: PlanProps) => {
  const [planCost, setPlanCost] = useState(0);
  const [planPerLinesCost, setPlanPerLinesCost] = useState(0);
  const [planUnlimitedCost, setPlanUnlimitedCost] = useState(0);
  const [planUnlimitedPerLinesCost, setPlanUnlimitedPerLinesCost] = useState(0);
  const [shareablePlans, setShareablePlans] = useState<any>(null);
  const [unlimitedPlans, setUnlimitedPlans] = useState<any>(null);
  const [activePlan, setActivePlan] = useState(3);
  const [activeUnlPlan, setActiveUnlPlan] = useState(3);
  const {
    planContent,
    isSimPaid,
    plandata,
    selectedLine,
    PurchaseJourneyCheck,
  } = props;
  const {
    shareable_title,
    unlimited_title,
    unlimited_subtitle,
    shareable_subtitle_1,
    button_text,
    bonus_text_1,
    bonus_text_2,
    shareable_subtitle_2,
    unlimited_subtext_1,
    unlimited_subtext_2,
    shareable_tag,
    per_line_text,
    per_month_text,
    plus_taxes_text,
    add_line_box,
    shareable_img,
  } = planContent || {};

  useEffect(() => {
    const sharedPlanData = filter(plandata, { isUnlimited: false });
    const selectedPlanData = sharedPlanData[0];
    setSharableCost(selectedPlanData, 0);
    const unlimitedPlan = filter(plandata, { isUnlimited: true });
    setUnlimitedCost(unlimitedPlan[0], 0);
    setShareablePlans(selectedPlanData);
    setUnlimitedPlans(unlimitedPlan[0]);
  }, []);

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (shareablePlans || unlimitedPlans) {
        setSharableCost(shareablePlans, null);
        setUnlimitedCost(unlimitedPlans, null);
      }
    }
  }, [selectedLine]);
  const selectPlanHandler = (_: any, type: string) => {
    PurchaseJourneyCheck();
    let data;
    if (type === "shareable") {
      data = shareablePlans;
    } else {
      data = unlimitedPlans;
    }
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.plan,
      category: `${data && data?.name} ${selectedLine}`,
      buttonValue: button_text,
    });
    localStorage.setItem("reachSelectedPlanLine", JSON.stringify(selectedLine));
    localStorage.setItem(
      "reachSelectedPlanDetail",
      JSON.stringify({
        line: selectedLine,
        plan: data,
      })
    );
  };

  const setUnlimitedCost = (data: any, index: any) => {
    const { planCost, planPerLinesCost } = calculateUnlimitedCost(
      data,
      selectedLine
    );
    let activeUnliData =
      index != null ? unlimitedPlan[index]?.planData : activeUnlPlan;
    setPlanUnlimitedCost(planCost);
    setPlanUnlimitedPerLinesCost(planPerLinesCost);
    setUnlimitedPlans(data);
    setActiveUnlPlan(activeUnliData);
  };
  const setSharableCost = (data: any, index: any) => {
    const { planCost, planPerLinesCost } = calculateCost(data, selectedLine);
    let activeData = index != null ? plandata[index]?.planData : activePlan;
    setPlanCost(planCost);
    setPlanPerLinesCost(planPerLinesCost);
    setShareablePlans(data);
    setActivePlan(activeData);
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.plan,
      category: gaCategory.data,
      buttonValue: data?.name,
    });
  };

  const cardStyle = {
    borderRadius: "4px",
    border: "0.5px solid var(--chinese-silver)",
    boxShadow: "5px 5px 5px rgba(170, 170, 170, 0.1)",
    width: { xs: "90%", sm: "460px", md: "45%", lg: "460px" },
    m: { xs: "0 auto 20px", md: "0 2%", lg: "0 1%" },
  };
  const shareablePlan = filter(plandata, { isUnlimited: false });
  const unlimitedPlan = filter(plandata, { isUnlimited: true });
  const {
    plan_label,
    plan_border_bottom,
    active_plan,
    data_box,
    plan_offer_text,
    plan_offer_text_one,
    plan_offer_text_two,
    plan_per_month_cost,
    plan_per_line_cost,
    unlimited_plan_text,
    fade_out,
    scroll_div,
  } = styles;
  return (
    <>
      {planContent && (
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            justifyContent: "center",
            flexWrap: "wrap",
            m: "30px auto",
            width: "100%",
          }}
        >
          <Box sx={cardStyle}>
            <Box className={plan_label} sx={{ width: "auto" }}>
              <Box
                component={"img"}
                src={shareable_img?.data?.attributes?.url}
                alt={shareable_img?.data?.attributes?.alternativeText}
                width="22px"
                height="18.6px"
              />
              <Box component={"span"}>{shareable_tag}</Box>
            </Box>
            <Box sx={{ clear: "both" }}></Box>
            <Box sx={{ p: { xs: "0 10px 10px", sm: "0 30px 30px" } }}>
              <Box
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: { xs: "22px", md: "24px" },
                  color: "var(--text_color)",
                }}
              >
                {shareable_title}
              </Box>
              <Box
                className={scroll_div}
                sx={{ height: { md: "310px" }, overflow: "scroll" }}
              >
                <Box
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: { xs: "16px", md: "18px" },
                    color: "var(--text_color)",
                    margin: "10px 0",
                  }}
                >
                  {activePlan == 2
                    ? shareable_subtitle_1
                    : shareable_subtitle_2}
                </Box>
                <Box className={plan_border_bottom}></Box>
                <Box sx={{ display: "flex", flexWrap: "wrap", mt: "20px" }}>
                  {shareablePlan &&
                    shareablePlan.map((plan: any, index: number) => {
                      return (
                        <Box
                          key={index}
                          className={clsx(
                            activePlan === plan?.planData
                              ? `${data_box} ${active_plan}`
                              : data_box,
                            plan.maxLines >= selectedLine ? " " : fade_out
                          )}
                          onClick={() => {
                            plan.maxLines >= selectedLine
                              ? setSharableCost(plan, index)
                              : "";
                          }}
                        >
                          {plan?.displayNameWeb}
                        </Box>
                      );
                    })}
                </Box>
                {Config.IS_BONUS_DATA ? (
                  <Box className={plan_offer_text}>
                    {selectedLine <= 2 ? (
                      <Box
                        className={plan_offer_text_one}
                        sx={{ fontSize: { xs: "16px", md: "20px" } }}
                        dangerouslySetInnerHTML={{
                          __html: bonus_text_1,
                        }}
                      ></Box>
                    ) : (
                      <Box className={plan_offer_text_two}>
                        <Box
                          component={"img"}
                          src={add_line_box?.data?.attributes.url}
                          alt={add_line_box?.data?.attributes?.alternativeText}
                          width="42px"
                          height="30px"
                          sx={{ mb: "40px" }}
                        />
                        <Box
                          sx={{ fontSize: { xs: "16px", md: "20px" } }}
                          component={"p"}
                          dangerouslySetInnerHTML={{
                            __html: bonus_text_2,
                          }}
                        ></Box>
                      </Box>
                    )}
                  </Box>
                ) : null}
                <Box className={plan_per_month_cost}>
                  <Box component={"span"}>
                    {" "}
                    {GetCurrencyFixedToTwo(planCost, plandata[0]?.currency)}
                  </Box>
                  <Box component={"span"}> {per_month_text} </Box>
                  <Box component={"span"}> {plus_taxes_text}</Box>
                </Box>
                <Box
                  className={plan_per_line_cost}
                  sx={{ fontSize: { xs: "20px", md: "22px" } }}
                >
                  That's
                  <Box component={"span"}>
                    {" "}
                    {GetCurrencyFixedToTwo(
                      planPerLinesCost,
                      plandata[0]?.currency
                    )}
                  </Box>{" "}
                  {per_line_text}
                </Box>
              </Box>
              {isSimPaid ? null : (
                <Box
                  sx={{ mt: "30px" }}
                  className={clsx(
                    shareablePlans?.maxLines >= selectedLine ? " " : fade_out
                  )}
                >
                  <ButtonCust
                    style={{ width: "100%" }}
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    data-testid="selectPlanHandler"
                    onClick={(e: any) => {
                      shareablePlans?.maxLines >= selectedLine
                        ? selectPlanHandler(e, "shareable")
                        : " ";
                    }}
                    disabled={
                      shareablePlans?.maxLines >= selectedLine ? false : true
                    }
                  >
                    {button_text}
                  </ButtonCust>
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={cardStyle}>
            <Box sx={{ p: "15px" }}></Box>
            <Box sx={{ p: { xs: "0 10px 10px", sm: "0 30px 30px" } }}>
              <Box
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: { xs: "22px", md: "24px" },
                  color: "var(--text_color)",
                }}
              >
                {unlimited_title}
              </Box>
              <Box
                className={scroll_div}
                sx={{ height: { md: "310px" }, overflow: "scroll" }}
              >
                <Box
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: { xs: "16px", md: "18px" },
                    color: "var(--text_color)",
                    margin: "10px 0",
                  }}
                >
                  {unlimited_subtitle}
                </Box>
                <Box className={plan_border_bottom}></Box>
                {unlimitedPlan &&
                  unlimitedPlan.map((plan: any, index: number) => {
                    return (
                      <Box
                        sx={{ mt: "20px", display: "block", width: "100%" }}
                        key={index}
                      >
                        <Box
                          className={clsx(
                            activeUnlPlan === plan.planData
                              ? `${data_box} ${active_plan}`
                              : data_box,
                            plan.maxLines >= selectedLine ? " " : fade_out
                          )}
                          data-testid="setUnlimitedCost"
                          onClick={() => {
                            plan.maxLines >= selectedLine
                              ? setUnlimitedCost(plan, index)
                              : "";
                          }}
                        >
                          {plan?.displayNameWeb}
                        </Box>
                        <Box
                          sx={{
                            fontSize: { xs: "16px", md: "18px" },
                            mt: { xs: "15px", md: "25px" },
                            mb: { xs: "10px", md: "20px" },
                            ms: "0px",
                          }}
                          className={unlimited_plan_text}
                        >
                          {unlimited_subtext_1}
                          {plan.planData + plan.dataUnit}
                          {unlimited_subtext_2}
                        </Box>
                      </Box>
                    );
                  })}
                <Box className={plan_per_month_cost}>
                  <Box component={"span"}>
                    {" "}
                    {GetCurrencyFixedToTwo(
                      planUnlimitedCost,
                      plandata[0]?.currency
                    )}
                  </Box>
                  <Box component={"span"}> {per_month_text} </Box>
                  <Box component={"span"}> {plus_taxes_text}</Box>
                </Box>
                <Box
                  className={plan_per_line_cost}
                  sx={{ fontSize: { xs: "20px", md: "22px" } }}
                >
                  That's
                  <Box component={"span"}>
                    {" "}
                    {GetCurrencyFixedToTwo(
                      planUnlimitedPerLinesCost,
                      plandata[0]?.currency
                    )}{" "}
                  </Box>
                  {per_line_text}
                </Box>
              </Box>
              {isSimPaid ? null : (
                <Box
                  sx={{ mt: "30px" }}
                  className={clsx(
                    unlimitedPlans?.maxLines >= selectedLine ? " " : fade_out
                  )}
                >
                  <ButtonCust
                    style={{ width: "100%" }}
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    onClick={(e: any) => {
                      unlimitedPlans?.maxLines >= selectedLine
                        ? selectPlanHandler(e, "unlimited")
                        : " ";
                    }}
                    data-testid="selectPlanHandlerissimpaid"
                    disabled={
                      unlimitedPlans?.maxLines >= selectedLine ? false : true
                    }
                  >
                    {button_text}
                  </ButtonCust>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default T1_PlanCard;
