import { Grid, Typography } from "@mui/material";
import { SimpleModal } from "../../../../widgets";
import styles from "./OrderSuccessDialog.module.scss";

type OrderSuccessDialogProps = {
  purchaseSuccess: boolean;
  content: any;
};

export const OrderSuccessDialog = (props: OrderSuccessDialogProps) => {
  const { purchaseSuccess, content } = props;

  return (
    <SimpleModal
      onCloseModal={null}
      showClose={false}
      isShowModal={purchaseSuccess}
      sx={{
        width: { xs: "94%", sm: "70%", md: "70%", lg: "40%", xl: "40%" },
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "4.8px",
      }}
    >
      <Grid className={styles.success_modal_container}>
        <img
          alt="order_success"
          className={styles.success_image}
          src={content?.sucess_img?.data?.attributes?.url}
        ></img>
        <Typography className={styles.success_order_text}>
          {content?.order_succ_msg}
        </Typography>
      </Grid>
    </SimpleModal>
  );
};
