import { Box } from "@mui/material";
import { ButtonCust } from "../../widgets";
import EnvConfig from "../../../config/env.config.json";
import { PaymentProps } from "../PaymentButtons/PaymentButtons";

const T1_PaymentButtons = (props: PaymentProps) => {
  return (
    <Box
      sx={{ my: "10px" }}
      style={{
        cursor: props.paymentClicked ? "not-allowed" : "pointer",
      }}
    >
      <ButtonCust
        style={{ width: EnvConfig.IS_ESIM_SUPPORTED ? "fit-content" : "100%" }}
        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
        onClick={props.formik}
        disabled={props.paymentClicked}
        sx={{
          width: "45%",
          fontSize: "30px",
        }}
        loading={props.paymentClicked}
      >
        {props.submitBtnTxt}
      </ButtonCust>
    </Box>
  );
};

export default T1_PaymentButtons;
