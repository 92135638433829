import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";
import { version } from "../../config/RoutingURLs";

type InitialState = {
  plans: any;
  error: string | null;
  coveragePlans: any;
  coverageError: any;
  getInTouch: any;
  getInTouchError: any;
  plansEarthlink: any;
  errorPlansEarthlink: string | null;
  coverageLoading: boolean;
  coverageStatus: any | null;
  errorCoverageStatus: any | null;
  inviteRefereeStatus: any | null;
  errorInviteRefereeStatus: any | null;
  wowEligibility: any | null;
  wowEligibilityError: any;
  discountApplied: any;
  discountAppliedError: any;
  compatibility: any;
  errorCompatibility: any;
  compatibilityLoading: boolean;
  customerAddress: any;
  lookUpSuccess: any;
  lookUpLoading: boolean;
  lookUpError: any;
};
const initialState: InitialState = {
  plans: [],
  error: null,
  coveragePlans: [],
  coverageError: null,
  getInTouch: [],
  getInTouchError: null,
  plansEarthlink: [],
  errorPlansEarthlink: null,
  coverageLoading: false,
  coverageStatus: {},
  errorCoverageStatus: null,
  inviteRefereeStatus: null,
  errorInviteRefereeStatus: null,
  wowEligibility: {},
  wowEligibilityError: {},
  discountApplied: {},
  discountAppliedError: null,
  compatibility: {},
  errorCompatibility: null,
  compatibilityLoading: false,
  customerAddress: null,
  lookUpSuccess: null,
  lookUpLoading: false,
  lookUpError: null,
};

export const checkCoverage = createAsyncThunk(
  "homepage/checkCoverage",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `/provsvc/${version}/address/network/coverage`,
        body: action,
        contentType: "application/json",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getInTouchApi = createAsyncThunk(
  "homepage/getInTouchApi",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "post",
      url: `/custsvc/${version}/customers/hubspot`,
      body: action.data,
    });
  }
);

export const checkCompatibility = createAsyncThunk(
  "homepage/checkCompatibility",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/provsvc/${version}/imei/info/${action.imeiNumber}`,
      body: action,
    });
  }
);

export const lookUp = createAsyncThunk(
  "account/lookUp",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.lookUp}${action}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const homepageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    resetGetInTouch: (state) => {
      state.getInTouch = [];
    },
    resetGetInTouchError: (state) => {
      state.getInTouchError = null;
    },
    setCustomerAddress: (state, action) => {
      state.customerAddress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkCoverage.pending, (state) => {
      state.coverageStatus = {};
      state.errorCoverageStatus = "";
      state.coverageLoading = true;
    });
    builder.addCase(
      checkCoverage.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.coverageStatus = action.payload;
        state.errorCoverageStatus = null;
        state.coverageLoading = false;
      }
    );
    builder.addCase(checkCoverage.rejected, (state, action) => {
      state.coverageStatus = {};
      state.errorCoverageStatus = action.payload || "Something went wrong";
      state.coverageLoading = false;
    });
    builder.addCase(
      getInTouchApi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getInTouch = action.payload;
        state.getInTouchError = null;
      }
    );
    builder.addCase(getInTouchApi.rejected, (state, action) => {
      state.getInTouch = null;
      state.getInTouchError = action.error || "Something went wrong";
    });
    builder.addCase(checkCompatibility.pending, (state) => {
      state.compatibility = {};
      state.errorCompatibility = "";
      state.compatibilityLoading = true;
    });
    builder.addCase(
      checkCompatibility.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.compatibility = action.payload;
        state.errorCompatibility = null;
        state.compatibilityLoading = false;
      }
    );
    builder.addCase(checkCompatibility.rejected, (state, action) => {
      state.compatibility = {};
      state.errorCompatibility = action.error || "Something went wrong";
      state.compatibilityLoading = false;
    });
    builder.addCase(lookUp.pending, (state, action) => {
      state.lookUpSuccess = null;
      state.lookUpLoading = true;
      state.lookUpError = null;
    });
    builder.addCase(lookUp.fulfilled, (state, action: PayloadAction<any>) => {
      state.lookUpSuccess = action.payload;
      state.lookUpLoading = false;
      state.lookUpError = null;
    });
    builder.addCase(lookUp.rejected, (state, action) => {
      state.lookUpSuccess = null;
      state.lookUpLoading = false;
      state.lookUpError = action.error || "Something went wrong";
    });
  },
});
export const { resetGetInTouch, resetGetInTouchError, setCustomerAddress } =
  homepageSlice.actions;
export default homepageSlice.reducer;
