import { useState, useEffect, useRef } from "react";
import { UpdateCardDialog } from "../../postActivation/Billing/ManageCards/UpdateCardDialog";
import { SelectCardDialog } from "../../SelectCardDialog";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { manageCard } from "../../../features/strapi/manageCardSlice";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import {
  autoPayCreditCard,
  deleteCreditCard,
  manageCardsData,
  updateCardExpiry,
} from "../../../features/billing/billingSlice";
import { getCC } from "../../../features/account/accountSlice";
import { SimpleModal } from "../Modal/Modal";
import styles from "./ManageCards.module.scss";
import { useLocation } from "react-router";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { NotificationCust } from "../NotificationCust";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import Config from "../../../config/env.config.json";
import AppConfig from "../../../config/app.config.json";
import T2_ManageCards from "./T2_ManageCards";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import {
  isTemplateExisits,
  pushTrackingEventsToGAAndFB,
} from "../../../utils/commonFunctions";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../Types/GoogleAnalytics";
import { AutoPayConsentModal } from "./AutoPayConsentModal";

const CardChangeModal = isTemplateExisits(AppConfig.Billing.CardChangeModal)
  ? require(`./${AppConfig.Billing.CardChangeModal.template}_CardChangeModal`)
      .default
  : null;

type Props = {
  cardData?: any;
  payType?: boolean;
  isDescription?: boolean;
  setShowCardChangeModal?: any;
  showCardChangeModal?: any;
  isModalRequired?: any;
  setComparePlansDialog?: any;
  ProceedPayment?: any;
  activeTab?: any;
  setBuyDataDialog?: any;
  setSelectedCard?: any;
  pageName?: string;
  ChangePlanDetails?: any;
  selectedPlan?: any;
  selectedLine?: any;
  isPayConfirm?: boolean;
  manageCardContent?: any;
  manageCardDetails?: any;
  autoPaymentData?: any;
};

export type ManageProps = {
  manageCardDetails?: any;
  isDescription?: any;
  getCustomer?: any;
  cardData?: any;
  payType?: any;
  selectOpenHandler?: any;
  openHandler?: any;
  addNewCardHandler?: any;
  getCardsInOrder?: any;
  isLoading?: boolean;
  setShowAutoPayConsentModal: any;
};

export const ManageCard = (props: Props) => {
  const dispatch = useAppDispatch();
  const [openUpdate, setOpenUpdate] = useState(false);
  const [display, setDisplay] = useState(false);
  const [dialogHeading, setDialogHeading] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectCardData, setSelectCardData] = useState([]);
  const [showAutoPayConsentModal, setShowAutoPayConsentModal] = useState(false);
  const [autoPayConsent, setAutoPayConsent] = useState(true);
  const formRef = useRef<HTMLFormElement>(null);
  const { type4Tax } = useAppSelector((state: any) => state.account);
  const { autoPaymentDetails, errorAutoPayment } = useAppSelector(
    (state: any) => state.strapi.billing
  );
  const {
    createInstallationActivityPending,
    createInstallationActivitySuccess,
  } = useAppSelector((state: any) => state.fsm);
  const {
    cardData,
    payType,
    isDescription,
    setShowCardChangeModal,
    showCardChangeModal,
    isModalRequired,
    setComparePlansDialog,
    ProceedPayment,
    activeTab,
    setBuyDataDialog,
    setSelectedCard,
    pageName,
    ChangePlanDetails,
    selectedPlan,
    selectedLine,
    isPayConfirm,
    manageCardContent,
    manageCardDetails,
    autoPaymentData,
  } = props;

  const { creditCardData } = useAppSelector((state: any) => state.billing);
  const closeHandler = () => {
    setOpenUpdate(false);
  };
  const opneHandler = (each: any) => {
    setOpenUpdate(true);
    setSelectCardData(each);
    isPayConfirm && setSelectedCard(each);
  };
  const selectCloseHandler = () => {
    setDisplay(false);
    ManageCardCloseHandler();
  };
  const selectOpenHandler = (val: any, each: any, defaultCard?: boolean) => {
    isModalRequired && setShowCardChangeModal(false);
    setDisplay(true);
    setDialogHeading(val);
    setSelectCardData(each);
    isPayConfirm && setSelectedCard(each);
    if (defaultCard) {
      dispatch(
        manageCardsData({
          ...creditCardData,
          ccuuid: each?.uuid,
          setAsDefault: true,
          removeCreditCard: false,
        })
      );
    } else {
      dispatch(
        manageCardsData({
          ...creditCardData,
          ccuuid: each?.uuid,
          setAsDefault: false,
          removeCreditCard: true,
        })
      );
    }
  };
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const getCardsInOrder = (data: any) => {
    return data?.slice().sort((a: any) => (a?.isAutoPay ? -1 : 1));
  };
  useNonInitialEffect(() => {
    addMultipleCacheData("ManageCards", manageCardContent);
  }, [manageCardContent]);
  useNonInitialEffect(() => {
    let customerObj: any = localStorage.getItem("customerDetail");
    customerObj = JSON.parse(customerObj);
    // update credit card
    if (creditCardData?.isUpdateCard) {
      dispatch(
        updateCardExpiry({
          cardId: creditCardData?.ccuuid,
          expiryDate: creditCardData?.expiryDate,
        })
      )
        .then((res: any) => {
          if (res?.payload?.status === 200) {
            dispatch(getCC({ customerId: customerObj?.id }));
          }
        })
        .catch(() => {
          dispatch(
            manageCardsData({
              ...creditCardData,
              errorExpiryDate: "",
              isUpdateCard: false,
              ccuuid: null,
              expiryDate: null,
            })
          );
        });
    }
    // set as default (autopay) credit card
    else if (creditCardData?.setAsDefault && creditCardData?.isLoading) {
      dispatch(autoPayCreditCard({ cardId: creditCardData?.ccuuid }))
        .then((res: any) => {
          if (res?.payload?.status === 200) {
            dispatch(getCC({ customerId: customerObj?.id }));
          }
          dispatch(
            manageCardsData({
              ...creditCardData,
              ccuuid: null,
              isLoading: false,
              removeCreditCard: false,
              setAsDefault: false,
            })
          );
        })
        .catch(() => {
          dispatch(
            manageCardsData({
              ...creditCardData,
              ccuuid: null,
              isLoading: false,
              removeCreditCard: false,
              setAsDefault: false,
            })
          );
        });
    }
    // remove credit card
    else if (creditCardData?.removeCreditCard && creditCardData?.isLoading) {
      dispatch(deleteCreditCard({ cardId: creditCardData?.ccuuid }))
        .then((res: any) => {
          if (res?.payload?.status === 200) {
            dispatch(getCC({ customerId: customerObj?.id }));
          }
        })
        .catch(() => {
          dispatch(
            manageCardsData({
              ...creditCardData,
              ccuuid: null,
              isLoading: false,
              removeCreditCard: false,
              setAsDefault: false,
            })
          );
        });
    }
  }, [creditCardData]);

  useNonInitialEffect(() => {
    if (createInstallationActivitySuccess?.status === "SUCCESS") {
      setIsLoading(false);
      ManageCardCloseHandler();
    }
  }, [createInstallationActivitySuccess]);

  const addNewCardHandler = () => {
    setIsLoading(true);
    if (pageName === "account") {
      let data: any;
      if (type4Tax) {
        data = type4Tax;
      } else {
        let local: any = localStorage.getItem("tax");
        local = JSON.parse(local);
        data = local;
      }
      if (activeTab === 2) {
        localStorage.setItem("addCardAccount", "true");
        localStorage.setItem("activeTab", activeTab);
        localStorage.setItem("tax", JSON.stringify(data));
        localStorage.setItem("selectedLine", selectedLine);
      } else if (activeTab === 1) {
        let dataPlan: any;
        if (localStorage.getItem("amount")) {
          let localPlan: any = localStorage.getItem("amount");
          localPlan = JSON.parse(localPlan);
          dataPlan = localPlan;
        } else {
          dataPlan = ChangePlanDetails;
        }
        localStorage.setItem("addCardAccount", "true");
        localStorage.setItem("activeTab", activeTab);
        localStorage.setItem("selectedPlan", JSON.stringify(selectedPlan));
        localStorage.setItem("amount", JSON.stringify(dataPlan));
      }
    } else if (pageName === "billing") {
      localStorage.setItem("addCardBilling", "true");
    } else if (pageName === "addlines") {
      localStorage.setItem("addCardManageLines", "true");
    } else if (pageName === "activation") {
      localStorage.setItem("addCardActivation", "true");
    }
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.billing,
      category: gaCategory.add_card,
      buttonValue: "add_card",
    });
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const ManageCardCloseHandler = () => {
    setShowCardChangeModal(false);
    let activeTabSection: any = localStorage.getItem("activeTab");
    let activeSection = JSON.parse(activeTabSection);
    setShowCardChangeModal(false);
    if (pageName === "account") {
      if (activeSection) {
        if (Number(activeSection) === 1) {
          setComparePlansDialog(true);
        } else if (Number(activeSection) === 2) {
          setBuyDataDialog(true);
        }
      } else {
        if (activeTab === 1) {
          setComparePlansDialog(true);
        } else if (activeTab === 2) {
          setBuyDataDialog(true);
        }
      }
    }
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  let transId: any = urlSearchParams.get("transId");
  let resCode: any = urlSearchParams.get("x_response_code");
  useNonInitialEffect(() => {
    const { add_card_noti, add_card_fail } =
      manageCardContent || manageCardDetails || {};
    if (location.pathname === RoutingURLs.billing && transId) {
      NotificationCust({
        message: add_card_noti,
        type: "info",
        duration: 5000,
        id: "AddNewCardSuccess",
        showIcon: true,
      });
    } else if (location.pathname === RoutingURLs.billing && resCode) {
      NotificationCust({
        message: add_card_fail,
        type: "danger",
        duration: 5000,
        id: "AddNewCardFailure",
        showIcon: true,
      });
    }
  }, [manageCardContent]);

  const selectCardBackhandler = () => {
    setDisplay(false);
    setShowCardChangeModal(true);
  };

  return (
    <>
      <form
        ref={formRef}
        method="post"
        name="addCardForm"
        id="addCardForm"
        action={Config.ADD_CARD_ADDRESS}
      >
        <input
          type="hidden"
          name="emailId"
          value={getCustomer?.emailId ? getCustomer.emailId : ""}
        />
        <input
          type="hidden"
          name="redirectUrl"
          value={
            pageName === "addlines"
              ? `${window.location.origin}/account`
              : `${window.location.origin}/${pageName}`
          }
        />
        {Config.IS_AUTOPAY_ENABLED && (
          <>
            <input type="hidden" name="saveCard" value="true" />
            <input
              type="hidden"
              name="autoPay"
              value={String(autoPayConsent)}
            />
            <input
              type="hidden"
              name="defaultCard"
              value={String(autoPayConsent)}
            />
          </>
        )}
      </form>
      {isModalRequired
        ? showCardChangeModal && (
            <SimpleModal
              isShowModal={showCardChangeModal}
              onCloseModal={() => {
                ManageCardCloseHandler();
              }}
              showClose={true}
              style={{ borderRadius: "0.3rem" }}
              sx={{
                width: { xs: "90%", sm: "500px" },
                minWidth: { xs: "90%", sm: "500px" },
                maxWidth: "500px",
                borderRadius: "4.8px",
              }}
              className={styles.manage_cards_dialog}
            >
              <CardChangeModal
                manageCardDetails={
                  manageCardDetails ? manageCardDetails : manageCardContent
                }
                isDescription={isDescription}
                getCustomer={getCustomer}
                cardData={cardData}
                payType={payType}
                selectOpenHandler={selectOpenHandler}
                openHandler={opneHandler}
                addNewCardHandler={addNewCardHandler}
                getCardsInOrder={getCardsInOrder}
                isLoading={createInstallationActivityPending}
              />
            </SimpleModal>
          )
        : null}
      {!isModalRequired
        ? !showCardChangeModal && (
            <T2_ManageCards
              manageCardDetails={
                manageCardDetails ? manageCardDetails : manageCardContent
              }
              isDescription={isDescription}
              getCustomer={getCustomer}
              cardData={cardData}
              payType={payType}
              selectOpenHandler={selectOpenHandler}
              openHandler={opneHandler}
              setShowAutoPayConsentModal={setShowAutoPayConsentModal}
              getCardsInOrder={getCardsInOrder}
            />
          )
        : null}
      {openUpdate && (
        <UpdateCardDialog
          display={openUpdate}
          closeHandler={closeHandler}
          cardData={selectCardData}
        />
      )}
      {display && (
        <SelectCardDialog
          open={display}
          handleClose={selectCloseHandler}
          cardHeading={dialogHeading}
          cardData={selectCardData}
          dialogData={manageCardDetails ? manageCardDetails : manageCardContent}
          payConfirmHandler={isPayConfirm}
          ProceedPayment={ProceedPayment}
          setShowCardChangeModal={setShowCardChangeModal}
          pageName={pageName}
          backHandler={selectCardBackhandler}
        />
      )}
      {showAutoPayConsentModal && (
        <AutoPayConsentModal
          showAutoPayConsentModal={showAutoPayConsentModal}
          setShowAutoPayConsentModal={setShowAutoPayConsentModal}
          autoPaymentDetails={
            autoPaymentDetails ? autoPaymentDetails : autoPaymentData
          }
          autoPayConsent={autoPayConsent}
          setAutoPayConsent={setAutoPayConsent}
          isLoading={isLoading}
          addNewCardHandler={addNewCardHandler}
        />
      )}
    </>
  );
};
