import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import Config from "../../config/app.config.json";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";

type InitialState = {
  deviceContent: any;
  errorDeviceContent: any;
};
const initialState: InitialState = {
  deviceContent: null,
  errorDeviceContent: null,
};

export const getDeviceContent = createAsyncThunk("device", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Device.Devices.template}${StrapiApiEndPoints.device}`,
    isStrapiApi: true,
  });
});

const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getDeviceContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.deviceContent = action?.payload?.data?.data?.attributes || null;
        state.errorDeviceContent = null;
      }
    );
    builder.addCase(
      getDeviceContent.rejected,
      (state: InitialState, action: any) => {
        state.deviceContent = null;
        state.errorDeviceContent =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default deviceSlice.reducer;
