import { Box, Typography } from "@mui/material";
import { SimpleModal, ButtonCust } from "../../../widgets";

type Props = {
  display: boolean;
  isTopUpInitiated: boolean;
  cppdContent?: any;
  closeHandler?: any;
};

export const TopUpModal = (props: Props) => {
  const { display, closeHandler, isTopUpInitiated, cppdContent } = props;
  const { heading1, heading2, description, button_text, description2 } =
    cppdContent?.dialog_data;
  return (
    <SimpleModal
      isShowModal={display}
      onCloseModal={closeHandler}
      showClose={true}
      sx={{ width: { xs: "95%", sm: "50%", md: "45%", lg: "33%" } }}
      style={{ borderRadius: "4px" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        p={1}
        pt={0.5}
        alignItems="center"
        width="100%"
      >
        {heading1 && heading2 && (
          <Typography
            sx={{
              fontSize: "22px",
              color: "var(--text_color)",
              fontWeight: "var(--font_weight_4)",
            }}
          >
            {isTopUpInitiated ? heading1 : heading2}
          </Typography>
        )}
        <Box
          sx={{
            margin: "20px auto 25px",
            width: "80px",
            height: "5px",
            borderRadius: "2.5px",
            backgroundColor: "var(--primary_brand_color)",
          }}
        ></Box>
        <Box display="flex" alignItems="center">
          {
            <img
              src={cppdContent?.operation_img?.data?.attributes?.url}
              alt="visa_img"
              style={{ width: "42px", height: "27px" }}
            />
          }
        </Box>
        {description && (
          <Typography
            pt={2}
            sx={{
              fontSize: "14px",
              color: "var(--text_color)",
              textAlign: "center",
              fontFamily: "var(--font_family_Semibold)",
            }}
          >
            {isTopUpInitiated ? heading1 : description2}
            &nbsp;
            {description}
          </Typography>
        )}
        <Box pt={3} sx={{ width: "100%" }}>
          {button_text && (
            <ButtonCust onClick={closeHandler} sx={{ width: "100%" }}>
              {button_text}
            </ButtonCust>
          )}
        </Box>
      </Box>
    </SimpleModal>
  );
};
