import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { getPaymentLandingContent } from "../../../features/strapi/paymentLandingSlice";
import { getShipmentOrderById } from "../../../features/trackorder/trackorderSlice";
import { OrderStatuses, ShipmentTypes } from "../../../enums";
import _ from "lodash";
import {
  activitySubType,
  activityTitle,
  activityType,
  createdBy,
} from "../../../features/fsm/fsm";
import {
  bookingSlots,
  bookingSlotsWithCustomDates,
  createInstallationActivity,
} from "../../../features/fsm/fsmSlice";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../config/RoutingURLs";

const PaymentLandingTemplate = isTemplateExisits(
  Config.PaymentLanding.PaymentLanding
)
  ? require(`./${Config.PaymentLanding.PaymentLanding.template}_PaymentLanding`)
      .default
  : null;

export type paymentLandingProps = {
  content?: any;
  shipmentType: string;
  orderStatus: string;
  pickUpInfo: any;
  selectedDate: string;
  setSelectedDate: any;
  selectedTime: string;
  setSelectedTime: any;
  selectedDateCal: string;
  setSelectedDateCal: any;
  callInstallationActivity: any;
  loader: boolean;
};

export const PaymentLandingComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { paymentLandingContent } = useAppSelector(
    (state: any) => state.strapi.paymentLanding
  );
  const { getShipmentID } = useAppSelector((state: any) => state.trackorder);
  const { createInstallationActivitySuccess, createInstallationActivityError } =
    useAppSelector((state: any) => state.fsm);

  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = JSON.parse(currentdetails);

  const [cacheContent, setCacheContent] = useState<any>(null);
  const [shipmentType, setShipmentType] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [pickUpInfo, setPickUpInfo] = useState({
    pickupFrom: null,
    pickupWindow: null,
  });
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDateCal, setSelectedDateCal] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getCache(
      "PaymentLanding",
      getPaymentLandingContent,
      setCacheContent,
      dispatch
    );
  }, []);

  useEffect(() => {
    getShipmentData();
    fetchBookingSlots();
  }, []);

  useNonInitialEffect(() => {
    if (getShipmentID?.status === "SUCCESS" && getShipmentID?.data) {
      setShipmentType(getShipmentID?.data[0].shipmentType);
      if (getShipmentID?.data[0].shipmentType === ShipmentTypes.MODEM) {
        setOrderStatus(getShipmentID?.data[0].orderStatus);
        setPickUpInfo((prevState) => ({
          ...prevState,
          pickupFrom: getShipmentID?.data[0].pickupFrom,
          pickupWindow: getShipmentID?.data[0].pickupWindow,
        }));
      }
      if (getShipmentID?.data[0]?.orderStatus !== OrderStatuses.DELIVERED)
        setTimeout(() => {
          getShipmentData();
        }, 5 * 60 * 1000); //Calling shipment data for every five minutes until we get orderStatus as "DELIVERED"
    }
  }, [getShipmentID]);

  useNonInitialEffect(() => {
    addMultipleCacheData(
      "PaymentLanding",
      paymentLandingContent?.data?.data?.attributes
    );
  }, [paymentLandingContent]);

  useNonInitialEffect(() => {
    if (selectedDateCal) fetchBookingSlots(true);
  }, [selectedDateCal]);

  useNonInitialEffect(() => {
    if (createInstallationActivitySuccess?.status === "SUCCESS") {
      setLoader(false);
      localStorage.setItem(
        "activityInfo",
        JSON.stringify(createInstallationActivitySuccess)
      );
      navigate(RoutingURLs.installationTracker);
    }
  }, [createInstallationActivitySuccess]);

  const getShipmentData = () => {
    let currentdetails: any = localStorage.getItem("customerDetail");
    currentdetails = JSON.parse(currentdetails);
    dispatch(getShipmentOrderById({ id: currentdetails && currentdetails.id }));
  };

  const fetchBookingSlots = (isDate?: boolean) => {
    const payload = {
      customerID: currentdetails.id,
      bookingSlotObject: {
        activityType: activityType.Install,
        activitySubType: activitySubType.firstType,
        ...(isDate && { dates: selectedDateCal }),
      },
    };
    isDate
      ? dispatch(bookingSlotsWithCustomDates(payload))
      : dispatch(bookingSlots(payload));
  };

  const callInstallationActivity = () => {
    const metaData = currentdetails?.meta;
    setLoader(true);
    dispatch(
      createInstallationActivity({
        title:
          metaData?.fsm_type === activityType.Install
            ? activityTitle.IntallActivityByReach
            : activityTitle.TroubleCallActivityByReach,
        customerId: currentdetails.id,
        activityType: activityType.Install,
        createdBy: createdBy.self,
        bookingDate: selectedDate || selectedDateCal,
        bookingTimeSlot: selectedTime,
        activityId: metaData?.fsm_id,
        hubspotNotification: false,
        homePassId: metaData?.homesPassedId,
      })
    );
  };

  return (
    (cacheContent || paymentLandingContent) && (
      <PaymentLandingTemplate
        content={
          cacheContent
            ? cacheContent
            : paymentLandingContent?.data?.data?.attributes
        }
        shipmentType={shipmentType}
        orderStatus={orderStatus}
        pickUpInfo={pickUpInfo}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        selectedDateCal={selectedDateCal}
        setSelectedDateCal={setSelectedDateCal}
        callInstallationActivity={callInstallationActivity}
        loader={loader}
      />
    )
  );
};
