import { Grid, Box, Typography, Button, MobileStepper } from "@mui/material";
import { useState } from "react";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import styles from "./S1_PrimaryValues.module.scss";
import { ButtonCust } from "../../widgets";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../../config/env.config.json";

type Props = {
  content: any;
};

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const PrimaryValuesComponent = (props: Props) => {
  const { content } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [autoPlay, setAutoPlay] = useState<boolean>(true);
  const maxSteps = 2;
  const GAAndFBEventsHandler = (
    eventType: string,
    categoryName: string,
    value: string
  ) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, eventType, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.aboutUs,
      category: categoryName,
      buttonValue: value,
    });
  };
  const handleStepChange = (activeStep: number) => {
    setActiveStep(activeStep);
  };
  const handleBack = () => {
    GAAndFBEventsHandler(
      gaEventTypes.click,
      gaCategory.primaryValues,
      content?.previous
    );
    setActiveStep(activeStep - 1);
  };
  const handleNext = () => {
    GAAndFBEventsHandler(
      gaEventTypes.click,
      gaCategory.primaryValues,
      content?.next
    );
    setActiveStep(activeStep + 1);
  };
  const handleAutoPlay = (val: any) => {
    setAutoPlay(val);
  };
  const cardsComponent = (boxContent: any) => {
    return (
      <Box>
        <Box height="500px">
          <Box
            style={{
              height: "50%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
              flexWrap: "wrap",
            }}
            component="div"
          >
            <Box className={styles.individual_div}></Box>
            {boxContent.map((item: any) => {
              return (
                <Box
                  key={item.id}
                  className={styles.individual_div}
                  onMouseOver={() => {
                    GAAndFBEventsHandler(
                      gaEventTypes.hover,
                      gaCategory.primaryValues,
                      item.title
                    );
                  }}
                  style={{
                    backgroundColor:
                      boxContent.indexOf(item) % 2 !== 0
                        ? "var(--primary_brand_color)"
                        : "var(--lightgrey_6",
                    color:
                      boxContent.indexOf(item) % 2 !== 0
                        ? "var(--lightgrey_6"
                        : "var(--primary_brand_color)",
                  }}
                >
                  <Typography className={styles.title}>{item.title}</Typography>
                  <Typography className={styles.subtext}>
                    {item.subtext}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <Grid
      container
      spacing={3}
      className={styles.commited_awesome_div}
      sx={{
        p:{
          xs: "calc(var(--mobile_section_padding)/2) 0",
          sm: "calc(var(--ipad_section_padding)/2) 0",
          md: "calc(var(--desktop_section_padding)/2) 0",
        },
        margin: "0px",
      }}
    >
      <Grid item md={4} className={styles.commited_awesome_text_wrap}>
        <Typography
          sx={{
            fontFamily: "var(--font_family_bold)",
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "1.6",
            letterSpacing: "normal",
            color: "var(--black)",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {content?.title}
        </Typography>
        <Box
          mt={1}
          mb={2}
          sx={{
            height: "4px",
            width: "60px",
            borderRadius: "2.5px",
            backgroundColor: "var(--primary_brand_color)",
            alignSelf: { xs: "center", md: "flex-start" },
          }}
        ></Box>
        <Typography className={styles.commited_awesome_text}>
          {content?.description}
        </Typography>
        <ButtonCust
          sx={{
            width: "200px",
            marginTop: "20px",
            alignSelf: { xs: "center", md: "flex-start" },
          }}
          onClick={() => {
            window.open(
              content?.b_url,
              content?.is_new_tab ? "_blank" : "_self"
            );
            GAAndFBEventsHandler(gaEventTypes.click,gaCategory.primaryValues,content?.buttontext)
          }}
        >
          {content?.buttontext}
        </ButtonCust>
      </Grid>
      <Grid
        container
        item
        md={8}
        className={styles.commited_awesome_sliders}
        onMouseEnter={() => handleAutoPlay(false)}
        onMouseLeave={() => handleAutoPlay(true)}
      >
        <AutoPlaySwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
          interval={3000}
          autoplay={autoPlay}
        >
          {content?.box_content1 &&
            content?.box_content1.length > 0 &&
            cardsComponent(content?.box_content1)}
          {content?.box_content2 &&
            content?.box_content2.length > 0 &&
            cardsComponent(content?.box_content2)}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          sx={{ alignSelf: { md: "flex-end", sm: "center" } }}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              className={
                activeStep === maxSteps - 1
                  ? styles.slider_disabled_buttons
                  : styles.slider_active_buttons
              }
              disabled={activeStep === maxSteps - 1}
            >
              {content?.next}
            </Button>
          }
          backButton={
            <Button
              size="small"
              className={
                activeStep === 0
                  ? styles.slider_disabled_buttons
                  : styles.slider_active_buttons
              }
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {content?.previous}
            </Button>
          }
        />
      </Grid>
    </Grid>
  );
};

export default PrimaryValuesComponent;
