import { Box } from "@mui/material";
import refreshIcon1 from "../../../../assets/images/commonImages/refresh.svg";
import refreshIcon2 from "../../../../assets/images/commonImages/circularLoader.gif";

type Props = {
  isGetStatusClicked: boolean;
  getUpdatedStatus: any;
  inProgressNote: any;
};
export const InProgressNote = (props: Props) => {
  const { isGetStatusClicked, getUpdatedStatus, inProgressNote } = props;
  return (
    <Box
      className="dataTopInProgress"
      sx={{ m: "15px 0", textAlign: "center" }}
    >
      {!isGetStatusClicked && (
        <Box onClick={getUpdatedStatus}>
          <Box
            component={"img"}
            src={refreshIcon1}
            alt="img"
            width={40}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
      )}
      {isGetStatusClicked && (
        <Box>
          <Box component={"img"} src={refreshIcon2} alt="img" width={40} />
        </Box>
      )}
      <Box
        className="dataTopInProgressText"
        sx={{
          color: "var(--primary_color)",
          fontFamily: "var(--font_family_Semibold)",
          fontSize: "14px",
        }}
      >
        {inProgressNote}
      </Box>
    </Box>
  );
};
