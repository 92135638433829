import { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { PlansContainer } from "./PlansContainer";
import PlansResponse from "../../config/data.json";
import { sortByKey } from "../../utils/commonFunctions/SortByKey";
import classes from "./PlanChangeDialog.module.scss";
import Config from "../../config/env.config.json";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  updatePlanDetails,
  updatePrimaryLineType,
} from "../../features/checkout/checkoutSlice";
import { PopperCust, IncDecButtonCmp, ButtonCust } from "../widgets";
import { ChangeLinePreferenceDialog } from "../ChangeLinePreferenceDialog/ChangeLinePreferenceDialog";
import { SimTypes } from "../../features/checkout/Types";
import EsimIMEI from "../common/EsimIMEI/EsimIMEI";
import { ChangeSimPreferenceDialog } from "../ChangeSimPreferenceDialog/ChangeSimPreferenceDialog";
import firebase from "../../adapters/firebase";

interface IPlanChangeDialog {
  content: any;
  simPrefenceContent?: any;
  showSimChangePrefernceDialog?: boolean;
  onCloseSimChangePrefernceDialog?: any;
  simPreference: any;
  setSimPreference: any;
  simCharges: number;
}
const incDecCustomStyles = {
  inputStyles: {
    color: "var(--black)",
    fontWeight: "var(--font_weight_2)",
    fontFaamily: "var(--font_family_Semibold",
    padding: "0 8px",
  },
  buttonStyles: {
    fontFamily: "var(--font_family_Semibold)",
    fontWeight: "var(--font_weight_2)",
    fontSize: "17px",
    height: "24px",
    paddingTop: 0,
  },
};
export const PlanChangeDialog = ({
  content,
  simPrefenceContent,
  showSimChangePrefernceDialog,
  onCloseSimChangePrefernceDialog,
  simPreference,
  setSimPreference,
  simCharges
}: IPlanChangeDialog) => {
  let plandata =
    PlansResponse && PlansResponse.data && PlansResponse.data.length > 0
      ? sortByKey(PlansResponse && PlansResponse.data, "planData")
      : [];
  const dispatch = useAppDispatch();
  const [display, setDisplay] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<any>();
  const { planDetails } = useAppSelector((state) => state.checkout);
  const [popperTarget, setPopperTarget] = useState(null);
  const [showLineChangePrefernceDialog, setShowLineChangePrefernceDialog] =
    useState(false);
  const [numberOfLinesToBeUpdated, setNumberOfLinesToBeUpdated] = useState(0);
  const [displayImeiCheck, setDisplayImeiCheck] = useState(false);
  const [simType, setSimType] = useState<SimTypes>(SimTypes.sim);
  //For change plan
  const [esimClickedIndex, setEsimClickedIndex] = useState<any>([]);
  const [sameArr, setSameArr] = useState<any>({});
  const [increaseLine, setIncreaseLine] = useState(false);
  const [eSimClicked, setESimClicked] = useState<boolean>(false);
  const [simClicked, setSimClicked] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const handlePopOverOpen = (e: any) => {
    setPopperTarget(e.currentTarget);
  };
  const handlePopOverClose = () => {
    setPopperTarget(null);
  };
  useEffect(() => {
    let customerDetails: any = JSON.parse(
      localStorage.getItem("customerDetail") || "null"
    );
    if (!customerDetails) {
      let planData: any = localStorage.getItem("reachSelectedPlanDetail");
      const activePlan = JSON.parse(planData)?.plan;
      const lines = JSON.parse(planData)?.line;
      dispatch(
        updatePlanDetails({ numberOfLines: lines, selectedPlan: activePlan })
      );
    }
  }, []);
  const checkCompatibility = () => {
    localStorage.setItem("simPreference", JSON.stringify(simPreference));
    let id: any = [];
    simPreference.map((el: any) => {
      if (el?.value === SimTypes.eSim && !el?.isPrimaryLine) {
        id.push(el?.id);
      }
    });
    setEsimClickedIndex(id);
    setDisplayImeiCheck(true);
    onCloseSimChangePrefernceDialog();
  };
  const openDialog = () => {
    setDisplay(true);
  };
  const closeDialog = () => {
    setDisplay(false);
    setSelectedPlan(undefined);
  };
  const plans = plandata;
  const handlePlanSelection = (newPlan: any) => {
    setSelectedPlan(newPlan);
  };
  const updatePlan = () => {
    if (selectedPlan) {
      let numberOfLines = planDetails.numberOfLines;
      if (numberOfLines > selectedPlan.maxLines) {
        numberOfLines = selectedPlan.maxLines;
      }
      dispatch(
        updatePlanDetails({
          selectedPlan: selectedPlan,
          numberOfLines: numberOfLines,
        })
      );

      localStorage.setItem(
        "reachSelectedPlanDetail",
        JSON.stringify({
          line: numberOfLines,
          plan: selectedPlan,
        })
      );
      localStorage.setItem(
        "reachSelectedPlanLine",
        JSON.stringify(numberOfLines)
      );

      closeDialog();
    }
  };
  const handleLineChange = (numberOfLines: any) => {
    if (numberOfLines > planDetails.numberOfLines && Config.IS_ESIM_SUPPORTED) {
      setShowLineChangePrefernceDialog(true);
      setNumberOfLinesToBeUpdated(numberOfLines);
      setIncreaseLine(true);
    } else {
      if (
        numberOfLines < planDetails.numberOfLines &&
        Config.IS_ESIM_SUPPORTED
      ) {
        let simPreferenceStore: any = JSON.parse(
          localStorage.getItem("simPreference") || "[]"
        );
        simPreferenceStore.pop();
        setSimPreference(simPreferenceStore);
        localStorage.setItem(
          "simPreference",
          JSON.stringify(simPreferenceStore)
        );
      }
      updateLatestNumberOfLines(numberOfLines);
    }
  };
  const confirmUpdateLines = () => {
    if (simType === SimTypes.eSim) {
      setDisplayImeiCheck(true);
    } else {
      if (Config.IS_ESIM_SUPPORTED) {
        let simPreferenceStore: any = JSON.parse(
          localStorage.getItem("simPreference") || "[]"
        );
        simPreferenceStore.push({
          id: numberOfLinesToBeUpdated - 1, // For updating unique id
          eSimOpted: false,
          eSimCompatible: false,
          imei: "",
          make: "",
          model: "",
          value: SimTypes.sim,
          isPrimaryLine: false,
        });
        localStorage.setItem("simPreference", JSON.stringify(simPreferenceStore));
        setSimPreference(simPreferenceStore)
      }
      updateLatestNumberOfLines(numberOfLinesToBeUpdated);
    }
    onCloseLineChangePrefernceDialog();
  };
  const updateLatestNumberOfLines = (numberOfLines: number) => {
    setIncreaseLine(false);
    dispatch(updatePlanDetails({ numberOfLines: numberOfLines }));
    localStorage.setItem(
      "reachSelectedPlanDetail",
      JSON.stringify({
        line: numberOfLines,
        plan: planDetails.selectedPlan,
      })
    );
    localStorage.setItem(
      "reachSelectedPlanLine",
      JSON.stringify(numberOfLines)
    );
    setDisplayImeiCheck(false);
  };
  const onCloseLineChangePrefernceDialog = () => {
    setShowLineChangePrefernceDialog(false);
    setSimType(SimTypes.sim); // setting defualt sim type to physical sim
    if (simType === SimTypes.sim) setIncreaseLine(false);
  };
  const callbackImeiVerification = () => {
    updateSimPreference();
    setDisplayImeiCheck(false);
  };
  const updateSimPreference = () => {
    let simPreferenceStorage: any = JSON.parse(
      localStorage.getItem("simPreference") || "[]"
    );
    setSimPreference(simPreferenceStorage);
  };
  useEffect(() => {
    const isEsimSelected = simPreference.some(
      (data: any) => data.value === SimTypes.eSim
    );
    const isSimSelected = simPreference.some(
      (data: any) => data.value === SimTypes.sim
    );
    setESimClicked(isEsimSelected);
    setSimClicked(isSimSelected);
  }, [simPreference]);
  const changeSimSelectionHandler = () => {
    let simPreferenceStorage: any = localStorage.getItem("simPreference");
    simPreferenceStorage = JSON.parse(simPreferenceStorage);
    if (simPreferenceStorage && simPreferenceStorage[0]?.eSimCompatible) {
      dispatch(updatePrimaryLineType(true));
    }

    let simCount = simPreferenceStorage.filter((simItem: any) => {
      return simItem.value === SimTypes.sim;
    });
    //Set
    // this.fetchData(simCount.length);
    setSimPreference(simPreferenceStorage);
  };
  return (
    <>
      <Grid container>
        <Grid item container xs={6} md={8}>
          <Typography
            variant="body1"
            component="span"
            onMouseEnter={handlePopOverOpen}
            ref={popperTarget}
            onMouseLeave={handlePopOverClose}
            fontSize="14px"
            style={{
              textDecoration: "underline",
              color: "var(--primary_color)",
            }}
          >
            {planDetails?.selectedPlan?.name?.replace("The", "")}
          </Typography>
          &ensp;
          <Typography variant="body1" component="span">
            {content?.c_plan_for}
          </Typography>
          &ensp;
          <IncDecButtonCmp
            unit={content?.line}
            step={1}
            maxValue={planDetails?.selectedPlan?.maxLines}
            minValue={1}
            value={planDetails?.numberOfLines}
            countHandler={handleLineChange}
            customStyles={incDecCustomStyles}
            isUnitPlural={planDetails?.numberOfLines > 1}
          />
          <Box className={classes.review_popper}>
            <PopperCust
              open={Boolean(popperTarget)}
              anchorEl={popperTarget}
              placement="bottom"
            >
              <Paper
                elevation={0}
                style={{
                  border: "1px solid rgba(0,0,0,.2)",
                  backgroundColor: "var(--black)",
                  color: "var(--white)",
                  padding: "10px",
                  fontSize: "14px",
                }}
              >
                {planDetails && (
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ fontWeight: "var(--font_family_Semibold)" }}
                  >
                    {planDetails?.selectedPlan?.planData}
                    {planDetails?.selectedPlan?.dataUnit} {content?.speed_type}
                  </Typography>
                )}
                {content?.tooltip_options &&
                  content?.tooltip_options.map((el: any) => (
                    <Typography variant="body1" component="p" key={el?.id}>
                      {el?.item}
                    </Typography>
                  ))}
              </Paper>
            </PopperCust>
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box className={classes.toggle_item} onClick={openDialog}>
            {content?.c_plan_btn}
          </Box>
        </Grid>
      </Grid>
      <Dialog
        scroll="body"
        open={display}
        onClose={closeDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Close
            onClick={closeDialog}
            style={{
              color: "var(--balck)",
              opacity: "0.5",
              fontSize: "20px",
              cursor: "pointer",
            }}
          />
          <Box
            className="h4"
            lineHeight={1.5}
            textAlign="center"
            fontFamily="var(--font_family_Bold)"
            fontWeight="var(--font_weight_1)"
          >
            {content?.d_title}
          </Box>
          <Box className={classes.sub_title}>{content?.d_sub_title}</Box>
          <PlansContainer
            content={content}
            plans={plans}
            selectedPlan={selectedPlan}
            activePlanName={planDetails?.selectedPlan?.name}
            handlePlanSelection={handlePlanSelection}
          />
          <Stack
            margin={{ sm: "20px auto", xs: "0 auto" }}
            width={{ sm: "60%", xs: "80%" }}
          >
            <ButtonCust
              style={{ width: "100%" }}
              variantType={Config.PRIMARY_BUTTON_TYPE}
              onClick={updatePlan}
            >
              {content?.cp_action_btn}
            </ButtonCust>
          </Stack>
        </DialogContent>
      </Dialog>
      {Config.IS_ESIM_SUPPORTED ? (
        <>
          <ChangeLinePreferenceDialog
            open={showLineChangePrefernceDialog}
            onClose={onCloseLineChangePrefernceDialog}
            content={simPrefenceContent}
            onConfirm={confirmUpdateLines}
            simType={simType}
            setSimType={setSimType}
          />
          <ChangeSimPreferenceDialog
            open={showSimChangePrefernceDialog}
            onClose={onCloseSimChangePrefernceDialog}
            content={simPrefenceContent}
            changeSimSelectionHandler={changeSimSelectionHandler}
            simPreference={simPreference}
            setSimPreference={setSimPreference}
            eSimClicked={eSimClicked}
            simClicked={simClicked}
            sameArr={sameArr}
            setSameArr={setSameArr}
            checkCompatibility={checkCompatibility}
            simCharges={simCharges}
          />
          {displayImeiCheck ? (
            <EsimIMEI
              showCheckIMEIModal={displayImeiCheck}
              hideCheckIMEIModal={() => {
                setIncreaseLine(false);
                setDisplayImeiCheck(false);
              }}
              fromCheckoutPage={true}
              changeSimSelectionHandler={callbackImeiVerification}
              simPreference={simPreference}
              esimClickedIndex={esimClickedIndex}
              isAccountScreen={false}
              changePreferenceHandler={updateSimPreference}
              increaseLine={increaseLine}
              increaseHandle={updateLatestNumberOfLines}
              sameArr={sameArr}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};
