import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  usePolicyContent: any;
  errorusePolicyContent: any;
};

const initialState: InitialState = {
  usePolicyContent: null,
  errorusePolicyContent: null,
};

// Generates pending, fulfilled and rejected action types
export const getUsePolicyContent = createAsyncThunk("usePolicy", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.AcceptUsePolicy.UsePolicies.template}${StrapiApiEndPoints.usepolicy}`,
    isStrapiApi: true,
  });
});

const strapiUsePolicySlice = createSlice({
  name: "usePolicy",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      getUsePolicyContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.usePolicyContent = action.payload;
        state.errorusePolicyContent = null;
      }
    );
    builder.addCase(
      getUsePolicyContent.rejected,
      (state: InitialState, action: any) => {
        state.usePolicyContent = null;
        state.errorusePolicyContent = action.error || "Something went wrong";
      }
    );
  },
});

export default strapiUsePolicySlice.reducer;
