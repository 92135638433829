import { Box, Typography } from "@mui/material";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { ButtonCust, SimpleModal } from "../../../widgets";
import styles from "./ChangePlanDialogue.module.scss";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../../Types/GoogleAnalytics";
import EnvConfig from "../../../../config/env.config.json";

type ChangePlanDialogueProps = {
  onClose: any;
  open: boolean;
  currentBill1: any;
  currentBill2: any;
  currentPlan: any;
  totalActivitedUsers: any;
  currentPlanCost: any;
  currentPlanTax: any;
  newBillStartMonth: any;
  newBillEndMonth: any;
  selectedPlan: any;
  newPlanCost: any;
  newPlanTax: any;
  newBillDate: any;
  confirmPlanChangeHandler: any;
  planChangeLoader: boolean;
  content?: any;
};

export const ChangePlanDialogue = (props: ChangePlanDialogueProps) => {
  const {
    open,
    onClose,
    currentBill1,
    currentBill2,
    currentPlan,
    totalActivitedUsers,
    currentPlanCost,
    currentPlanTax,
    newBillStartMonth,
    newBillEndMonth,
    selectedPlan,
    newPlanCost,
    newPlanTax,
    newBillDate,
    confirmPlanChangeHandler,
    planChangeLoader,
    content,
  } = props;
  const TotalCost = parseFloat((newPlanCost + newPlanTax)?.toFixed(2));
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.account,
      category: category,
      buttonValue: value,
    });
  };
  const {
    change_plan,
    current_plan,
    new_plan,
    tax_fees,
    due_date,
    confirm_change,
  } = content?.compare_plan;
  return (
    <SimpleModal
      isShowModal={open}
      onCloseModal={onClose}
      showClose={true}
      sx={{ width: { xs: "90%", sm: "60%", md: "80%", lg: "35%", xl: "35%" } }}
    >
      <Box className={styles.account_change_new_plan_content}>
        <Typography className={styles.account_new_current_head}>
          {change_plan}
        </Typography>
        <Box className={styles.confirm_plan_line}></Box>
        <Box>
          <Box>
            <Box className={styles.account_new_current_cont}>
              <Box
                className={styles.account_cur_new_head}
                sx={{ paddingBottom: "15px" }}
              >
                {current_plan}
                <span className={styles.current_bill_date}>
                  &nbsp;({currentBill1} - {currentBill2})
                </span>
              </Box>
              <Box className={styles.account_cur_new_content}>
                <Typography
                  variant="body2"
                  className={styles.account_cur_new_head}
                >
                  {currentPlan}&nbsp;for&nbsp;{totalActivitedUsers}
                </Typography>
                <Typography
                  variant="body2"
                  className={styles.account_cur_new_head}
                >
                  {GetCurrencyFixedToTwo(currentPlanCost)}
                </Typography>
              </Box>
              <Box className={styles.account_cur_new_content}>
                <Typography
                  variant="body2"
                  className={styles.account_cur_new_head}
                >
                  {tax_fees}
                </Typography>
                <Typography
                  variant="body2"
                  className={styles.account_cur_new_head}
                >
                  {GetCurrencyFixedToTwo(currentPlanTax)}
                </Typography>
              </Box>
              <Box className={styles.account_cur_new_border}></Box>
            </Box>
            <Box className={styles.account_new_current_cont}>
              <Box
                className={`${styles.account_cur_new_head} ${styles.bold}`}
                sx={{ paddingBottom: "15px" }}
              >
                {new_plan}
                <span className={styles.current_bill_date}>
                  &nbsp;({newBillStartMonth} - {newBillEndMonth})
                </span>
              </Box>
              <Box
                className={`${styles.account_cur_new_head} ${styles.bold} ${styles.account_cur_new_content}`}
              >
                <Typography
                  variant="body2"
                  className={`${styles.account_cur_new_head} ${styles.bold}`}
                >
                  {selectedPlan?.name}&nbsp;for&nbsp;{totalActivitedUsers}
                </Typography>
                <Typography
                  variant="body2"
                  className={`${styles.account_cur_new_head} ${styles.bold}`}
                >
                  {GetCurrencyFixedToTwo(newPlanCost)}
                </Typography>
              </Box>
              <Box className={styles.account_cur_new_content}>
                <Typography
                  variant="body2"
                  className={`${styles.account_cur_new_head} ${styles.bold}`}
                >
                  {tax_fees}
                </Typography>
                <Typography
                  variant="body2"
                  className={`${styles.account_cur_new_head} ${styles.bold}`}
                >
                  {GetCurrencyFixedToTwo(newPlanTax)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#f5f5f5",
                  padding: "15px 5px 15px",
                  color: "#3a8580",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: "14px",
                  fontWeight: "var(--font_weight_2)",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: "14px",
                  }}
                >
                  {due_date} {newBillDate}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: "14px",
                  }}
                >
                  {TotalCost !== 0 ? GetCurrencyFixedToTwo(TotalCost) : "-"}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                width: "80%",
                margin: "auto",
                marginTop: "8px",
              }}
              width={{ xs: "300px" }}
            >
              <ButtonCust
                onClick={() => {
                  confirmPlanChangeHandler("later");
                  GAAndFBEventsHandler(
                    gaCategory.changePlan,
                    "Confirm Plan Change"
                  );
                }}
                loading={planChangeLoader}
              >
                {confirm_change}
              </ButtonCust>
            </Box>
          </Box>
        </Box>
      </Box>
    </SimpleModal>
  );
};
