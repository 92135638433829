import { Grid, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import styles from "./OfferCode.module.scss";
import { string, object } from "yup";
import { useFormik } from "formik";
import { TextFieldCust } from "../../widgets";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Coupon } from "../../../features/checkout/checkoutSlice";
import { CouponTypes } from "../../../features/checkout/CouponTypes";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";

type Props = {
  appliedCouponAmount: null | number;
  removeCouponCallback: any;
  content?: any;
};

export const OfferCodeCmp = (props: Props) => {
  const dispatch = useAppDispatch();
  const { coupon } = useAppSelector((state: any) => state.checkout);
  const [offer, setOffer] = useState<any>(false);
  const [result, setResult] = useState<any>(false);
  const [couponType, setCouponType] = useState(CouponTypes);

  const closeHandler = () => {
    setOffer(false);
    formik.resetForm();
  };
  useEffect(() => {
    if (coupon) {
      formik.resetForm();
      setResult(true);
      setOffer(false);
    }
  }, [coupon]);

  const validateOfferCodeSchema = object({
    referrelCoupon: string().trim().required(props.content?.promo_f_err),
  });
  const applyOffer = () => {
    localStorage.removeItem("reachCoupon");
    // pushTrackingEventsToGAAndFB({
    //   category: "Swift Purchase",
    //   actionType: "Apply Offer",
    //   label: "",
    // });
    dispatch(Coupon({ couponCode: formik.values.referrelCoupon }));
  };
  const formik: any = useFormik({
    initialValues: { referrelCoupon: "" },
    validationSchema: validateOfferCodeSchema,
    onSubmit: () => {
      applyOffer();
    },
  });
  const couponHandler = () => {
    setOffer(true);
    setResult(false);
    localStorage.removeItem("reachCoupon");
    localStorage.removeItem("lpCoupon");
    // pushTrackingEventsToGAAndFB({
    //   category: "Swift Purchase",
    //   actionType: "Already  Have An Offer Or Referral Code?",
    //   label: "",
    // });
    props.removeCouponCallback();
  };
  const savingsIcon = props.content?.savings_ico?.data?.attributes?.url;
  const SavingsTcon_alt =
    props.content?.savings_ico?.data?.attributes?.alternativeText;

  return (
    <Grid container>
      {!offer && !result && props.appliedCouponAmount === 0 ? (
        <Grid item xs={12}>
          <Grid item className={styles.referal} onClick={() => couponHandler()}>
            <Grid item className={styles.left_div}>
              <Box
                component="img"
                alt={SavingsTcon_alt}
                width="21px"
                height="22px"
                margin-right="10px"
                src={savingsIcon}
              />
              <Box className={styles.referal_text}>
                {props.content?.ref_label}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {offer && !result && props.appliedCouponAmount === 0 ? (
        <Grid sx={{ width: "100%" }}>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Grid item className={styles.input_group_bill} display="flex">
              <TextFieldCust
                name="referrelCoupon"
                label={props.content?.ref_field_lable}
                onChange={formik.handleChange}
                value={formik.values.referrelCoupon}
              />
              <Box
                className={styles.input_group_gb}
                onClick={formik.handleSubmit}
              >
                {props.content?.ref_btn_save_txt}
              </Box>
            </Grid>

            {/* <Grid margin="15px">
              <ClearIcon
                style={{ cursor: "pointer" }}
                onClick={() => closeHandler()}
              />
            </Grid> */}
          </Grid>
          <Box className={styles.error_message}>
            {formik.touched?.referrelCoupon && formik.errors?.referrelCoupon}
          </Box>
        </Grid>
      ) : null}
      {coupon && props.appliedCouponAmount && props.appliedCouponAmount > 0 ? (
        <Grid item xs={12}>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "16px",
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--text_color_8)",
              mb: "10px",
            }}
          >
            <Typography>{props.content?.discount_price_text}</Typography>
            <Typography>
              (-){GetCurrencyFixedToTwo(props.appliedCouponAmount)}
            </Typography>
          </Grid>
          <Grid item className={styles.referal}>
            <Grid item className={styles.left_div}>
              <img
                height="22px"
                width="21px"
                alt={SavingsTcon_alt}
                src={savingsIcon}
              />
              {coupon && coupon?.delayByMonths ? (
                <Box className={styles.delay_coupon_text}>
                  {couponType && couponType[coupon && coupon?.type].message}
                  {coupon && coupon?.coupon} {props.content?.ref_r1}{" "}
                  {coupon && coupon?.delayByMonths} {props.content?.ref_r2}{" "}
                </Box>
              ) : (
                <Box>
                  <Box className={styles.referal_text}>
                    {props.content?.savings_txt}
                  </Box>
                  <Box className={styles.referal_text_light}>
                    {couponType && couponType[coupon && coupon?.type]?.message}{" "}
                    {coupon && coupon?.name} {props.content?.applied_txt}
                  </Box>
                </Box>
              )}
            </Grid>
            <Box className={styles.right_div}>
              {/* {coupon &&
                props.appliedCouponAmount &&
                props.appliedCouponAmount > 0 && (
                  <div className={styles.cost}>
                    {GetCurrencyFixedToTwo(props.appliedCouponAmount)}
                  </div>
                )} */}
              <Box className={styles.remove} onClick={couponHandler}>
                {props.content?.remove_txt}
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
