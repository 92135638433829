import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import EnvConfig from "../../../config/env.config.json";
import { strapiBannerContent } from "../../../features/strapi/componentSlice";
import { scroller } from "react-scroll";
import { RoutingURLs } from "../../../config/RoutingURLs";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { NotificationCust } from "../../widgets";
import { Store } from "react-notifications-component";
import flatMap from "lodash/flatMap";
import get from "lodash/get";
import { Link, Box } from "@mui/material";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNavigate } from "react-router-dom";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";

export type HomeBannerProps = {
  bannerContent: any;
  onButton1Click?: any;
  onButton2Click?: any;
  pageName?: string;
};

type Props = {
  pageName: string;
  apiEndPoint: string;
  onButton1Click?: any;
  onButton2Click?: any;
};

export const HomeBanner = (props: Props) => {
  const { pageName, apiEndPoint, onButton1Click, onButton2Click } = props;
  const selectedLine = JSON.parse(
    localStorage.getItem("reachSelectedPlanDetail")!
  );
  let template = get(Config, `${pageName}.Banner.template`);
  const BannerComponent = isTemplateExisits(get(Config, `${pageName}.Banner`))
    ? require(`./${template}_HomeBanner`).default
    : null;
  const dispatch = useAppDispatch();
  const [bannerContent, setBannerContent] = useState<any>();
  const { BannerContent } = useAppSelector(
    (state: any) => state.strapi.commonPage
  );
  const navigate = useNavigate();
  const [cacheDataHomeBanner, setCacheDataHomeBanner] = useState<any>(null);
  const { notification } = useAppSelector(
    (state: any) => state?.strapi?.commonPage || {}
  );
  const initialRender1 = useRef(true);
  useEffect(() => {
    if (initialRender1.current) {
      let apiData: any = {
        pageName: pageName,
        apiEndPoint: apiEndPoint,
      };
      getCache(
        apiEndPoint,
        strapiBannerContent,
        setCacheDataHomeBanner,
        dispatch,
        apiData
      );
      initialRender1.current = false;
    } else {
      let bannerContent = BannerContent;
      setBannerContent(bannerContent);
      addMultipleCacheData(apiEndPoint, BannerContent);
      let customerDetail1 = localStorage.getItem("customerDetail") || "{}";
      let customerDetail = JSON.parse(customerDetail1);
      if (!EnvConfig.BROADBAND) {
        if (!Object.keys(customerDetail)?.length && !customerDetail.simPaid) {
          NotificationCust({
            message: notification.pageNotification.homePage,
            duration: 0,
            id: "HomeNotification",
            showIcon: true,
          });
          if (selectedLine?.line) {
            let notificationMessage: any =
              notification.pageNotification.planNotification
                .replace("{name}", selectedLine?.plan?.name)
                .replace("{line}", selectedLine?.line);
            notificationMessage = flatMap(
              notificationMessage.split("{clickHere}"),
              function (part) {
                return [
                  part,
                  <Link
                    underline="always"
                    color="inherit"
                    sx={{ fontWeight: "var(--font_weight_3)" }}
                    onClick={() => navigate(RoutingURLs.checkout)}
                  >
                    Click here
                  </Link>,
                ];
              }
            );
            notificationMessage.pop();
            // NotificationCust({
            //   message: (
            //     <Box
            //       sx={{
            //         fontSize: "14px",
            //         fontFamily: "var(--font_family_Semibold)",
            //       }}
            //     >
            //       {notificationMessage}
            //     </Box>
            //   ),
            //   duration: 0,
            //   id: "PlanNotification",
            //   showIcon: true,
            // });
          }
        }
      }
    }
    return () => {
      Store.removeNotification("HomeNotification");
      Store.removeNotification("PlanNotification");
    };
  }, [BannerContent]);

  const onButtonClick = () => {
    setTimeout(function () {
      scroller.scrollTo("bestCellPhonePlan", {
        duration: 1000,
        delay: 0,
        offset: -100,
        smooth: true,
      });
    }, 100);
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.home,
      category: gaCategory.plan,
      buttonValue: cacheDataHomeBanner
        ? cacheDataHomeBanner?.button_text
        : bannerContent?.button_text,
    });
  };

  return (
    BannerComponent && (
      <BannerComponent
        bannerContent={
          cacheDataHomeBanner ? cacheDataHomeBanner : bannerContent
        }
        onButton1Click={pageName === "Home" ? onButtonClick : onButton1Click}
        onButton2Click={onButton2Click}
        {...props}
      />
    )
  );
};
