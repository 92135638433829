import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  compDevicesContent: any;
  errorCompDevicesContent: any;
};

const initialState: InitialState = {
  compDevicesContent: null,
  errorCompDevicesContent: null,
};

export const getCompDevicesContent = createAsyncThunk(
  "planContent/compDevicesContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Plans.CompatibleDevices.template}${StrapiApiEndPoints.compatibleDevices}`,
      isStrapiApi: true,
    });
  }
);

const strapiPlanSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCompDevicesContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.compDevicesContent =
          action?.payload?.data?.data?.attributes || null;
        state.errorCompDevicesContent = null;
      }
    );
    builder.addCase(
      getCompDevicesContent.rejected,
      (state: InitialState, action: any) => {
        state.compDevicesContent = null;
        state.errorCompDevicesContent =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default strapiPlanSlice.reducer;
