import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import { ContactCustomerDialog } from "./ContactCustomer";
import {
  activationStatusCheck,
  activateLine,
} from "../../../../features/activation/activationSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { PortingDialogue } from "./PortingDialog";
import { NotificationCust } from "../../../widgets";
import { ActivationStatusSuccess } from "./ActivationStatusSuccess";
import { formatPhoneNumber } from "../../../../utils/commonFunctions/ReusableFunctions";
import { customerGroup } from "../../../../features/account/accountSlice";
import { PortRequestRejected } from "./PortReqestRejected";
import { CustomerStatus } from "../../../../enums";

type Props = {};

export const ActivationStatus = ({}: Props) => {
  const dispatch = useAppDispatch();
  let customerDetails: any = localStorage?.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);

  const [modalName, setModalName] = useState<any>("");
  const [modalData, setModalData] = useState<any>({});
  const [buttonLoaderFg, setButtonLoader] = useState<boolean>(false);
  const { activationModals } = useAppSelector(
    (state: any) => state.strapi.postActivation || {}
  );
  const { notifications: notificationMsg } = activationModals;
  const {
    zipCode,
    accNum,
    verizonPin,
    portingFailCC,
    pinNum,
    acivationFailCC,
    portReject1,
    portReject2,
    newNumSuc,
    portSuccess,
  } = activationModals;
  const navigate = useNavigate();

  const { activationStatus } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const { getCustomer } = useAppSelector((state: any) => state?.checkout || {});
  const { status: getCustomerStatus, isPrimary, eSimSetup } = getCustomer || {};

  const { group: groupData } = useAppSelector(
    (state: any) => state.account || {}
  );
  const { password } = activationStatus || {};
  const { id, reachPlanId, simId, imei, make, model } = customerDetails;
  const { mnpInfo } = getCustomer || {};
  const { oldNumber, operator, acctNum, oldZip } = mnpInfo || {};

  const activationPayload: any = {
    customerId: id,
    reachPlanId: reachPlanId,
    iccId: simId,
    imei: imei,
    make: make,
    model: model,
    isPort: true,
    numberToPort: oldNumber,
    oldCarrier: operator,
    portUpdate: true,
    oldCarrierAccountNumber: acctNum,
    oldZip: oldZip,
  };
  // shows port modals or activation success or failed modals  based on activation status
  const activationStatusResults = (res?: any) => {
    const resData = res?.payload?.data?.data || {};
    const { status: actStatus, esim, customerId } = resData;
    const { portFailureCode, oldCarrier } = resData;
    if (actStatus !== 9 && actStatus !== 1) {
      Store.removeNotification("portInProgressNoti");
      Store.removeNotification("actInProgressNoti");
    }
    const filteredCustObj = groupData?.data?.filter((item: any) => {
      return item.id === customerId;
    });
    const { eSimSetup: eSimSetupGrp, isPrimary: isPrimaryGrp } =
      filteredCustObj?.[0] || {};
    if (actStatus === 2 && esim && eSimSetupGrp === false) {
      const showMsg =
        isPrimaryGrp === true
          ? localStorage.setItem("showSuccessMessage", "true")
          : localStorage.setItem("secondaryUserId", JSON.stringify(customerId));

      navigate("/steps");
    } else if (
      actStatus === 2 &&
      groupData === null &&
      isPrimary === true &&
      eSimSetup === false &&
      esim === true
    ) {
      navigate("/steps");
      localStorage.setItem("showSuccessMessage", "true");
    }
    if (resData?.status === 10) {
      if (portFailureCode === 1011) {
        setModalName("zipCode");
        setModalData(zipCode);
      } else if (portFailureCode === 1010) {
        if ("VERIZON" === oldCarrier?.toUpperCase()) {
          setModalName("verizonPin");
          setModalData(verizonPin);
        } else {
          setModalName("pinNum");
          setModalData(pinNum);
        }
      } else if (portFailureCode === 1009) {
        setModalName("accNum");
        setModalData(accNum);
      } else if (portFailureCode === 1017) {
        const oldCarrierName = oldCarrier?.toLowerCase();
        if (oldCarrierName === "att" || oldCarrierName === "at&t") {
          setModalName("portRejected");
          setModalData(portReject1);
        } else {
          setModalName("portRejected");
          setModalData(portReject2);
        }
      } else {
        setModalName("cc");
        setModalData(portingFailCC);
      }
    } else if (actStatus === 2) {
      let Obj: any = {
        subtext:
          "If you haven't put in your new SIM yet, please do that now and restart your phone.",
        btnText: customerDetails?.isPrimary ? "Activate other lines" : "",
        noteText: "",
      };
      if (resData?.isPort) {
        Obj = portSuccess;
      } else {
        const { title: titleArr } = newNumSuc;
        Obj = {
          ...newNumSuc,
          title: `${titleArr?.[0]?.txt} ${
            customerDetails?.isPrimary
              ? titleArr?.[1]?.txt
              : `${customerDetails.firstName}'s`
          } ${titleArr?.[2]?.txt} '${formatPhoneNumber(resData?.mobileNo)}' ${
            titleArr?.[3]?.txt
          }`,
        };
      }
      setModalData(Obj);
      setModalName("statusSuccess");
    } else if (actStatus === 8) {
      setModalName("cc");
      setModalData(acivationFailCC);
    } else if (actStatus === 11) {
      navigate("/activation");
    }
  };

  // Displays and removes the notifications based on status
  const notificationSection = (st: number, msg?: string) => {
    if (st === 9) {
      NotificationCust({
        message: msg ?? notificationMsg.message1,
        type: "info",
        duration: 0,
        id: "portInProgressNoti",
        showIcon: true,
      });
    } else if (st === 1) {
      NotificationCust({
        message: msg ?? notificationMsg.message2,
        type: "info",
        duration: 0,
        id: "actInProgressNoti",
        showIcon: true,
      });
    }
    if (st !== 9) {
      Store.removeNotification("portInProgressNoti");
    }
    if (st !== 1) {
      Store.removeNotification("actInProgressNoti");
    }
  };

  useEffect(() => {
    const st: any = getCustomerStatus;
    notificationSection(st);
    const showMsg: any = JSON.parse(
      localStorage?.getItem("showSuccessMessage") || "null"
    );
    if (st == 1 || st == 8 || st === 9 || st === 10 || showMsg || st === 0) {
      showMsg == true && localStorage.removeItem("showSuccessMessage");
      callActivationStatus();
    }
  }, []);

  // sets the notification based on secondary line status
  const secondaryFn = (data: any) => {
    data?.map((item: any) => {
      if (item?.isPrimary !== true) {
        const idExists: any = JSON.parse(
          localStorage?.getItem("secondaryUserId") || "null"
        );
        if (
          item.status === 2 &&
          item?.eSimSetup === false &&
          item?.esim === true
        ) {
          navigate("/steps");
          item?.id &&
            localStorage.setItem("secondaryUserId", JSON.stringify(item?.id));
        }
        if (item.id == idExists) {
          localStorage.removeItem("secondaryUserId");
          callActivationStatus(item.id);
        }
      }

      if (!item?.isPrimary && (item?.status === 1 || item?.status === 9)) {
        window.scroll(0, 0);
        let message: string;
        if (item?.status === 9) {
          message = notificationMsg.message1.replace(
            "your",
            item?.firstName + "'s"
          );
        } else {
          message = notificationMsg.message3.replace("your", item?.firstName);
        }
        callActivationStatus(item.id);
        notificationSection(item?.status, message);
      }
    });
  };

  useEffect(() => {
    if (groupData === null && getCustomer?.groupId) {
      dispatch(
        customerGroup({
          groupId: getCustomer?.groupId,
        })
      ).then((res: any) => {
        if (res?.payload?.status === 200) {
          secondaryFn(res?.payload?.data?.data);
        }
      });
    } else if (getCustomer?.groupId && groupData !== null) {
      secondaryFn(groupData?.data);
    }
  }, [groupData]);

  // shows notifications if modal(1009, 1010, 1011) closed without submit
  const modalCloseFn = () => {
    setModalName("");
    if (modalName !== "cc") {
      let message: string = "";
      //error code 1011
      if (modalName === "zipCode") {
        message = notificationMsg.zipPortFail;
      }
      //error code  1010
      else if (modalName === "verizonPin") {
        message = notificationMsg.verizonPinPortFail;
      } else if (modalName === "pinNum") {
        message = notificationMsg.pinPortFail;
      }
      //error coee 1009
      else if (modalName === "accNum") {
        message = notificationMsg.accountPortFail;
      }
      NotificationCust({
        message: message,
        type: "info",
        duration: 0,
        id: "portSpecificErrorNotify",
        showIcon: true,
      });
    }
    Store.removeNotification("portInProgressNoti");
  };
  // 1009, 1010, 1011 form submit
  const submitHandler = (val: any) => {
    setButtonLoader(true);

    const payload: any = {
      ...activationPayload,
      password: val,
    };

    const pinPayload = { ...payload, password: val };
    const zipcodePayload = {
      ...payload,
      oldZip: val,
      password: password,
    };
    const accountPayload = {
      ...payload,
      oldCarrierAccountNumber: val,
      password: password,
    };
    let py: any = {};
    //error code  1011
    if (modalName === "zipCode") {
      py = zipcodePayload;
    }
    //error code 1010
    else if (modalName === "verizonPin" || modalName === "pinNum") {
      py = pinPayload;
    }
    //error code 1009
    else if (modalName === "accNum") {
      py = accountPayload;
    }
    callActivation(py);
  };
  const callActivation = (py: any) => {
    dispatch(activateLine({ payload: py })).then((res: any) => {
      setButtonLoader(false);
      if (res?.payload?.data?.status === "SUCCESS") {
        if (customerDetails?.isMNP === true) {
          notificationSection(CustomerStatus.PORTING_IN_PROGRESS);
        } else {
          notificationSection(CustomerStatus.ACTIVATION_IN_PROGRESS);
        }
        callActivationStatus();
        setModalName("");
      } else if (res.payload?.status === "FAILURE") {
        setModalName("cc");
        setModalData(acivationFailCC);
      }
    });
  };
  const callActivationStatus = (userId?: string) => {
    dispatch(
      activationStatusCheck({
        payload: { customerId: userId ? userId : customerDetails?.id },
      })
    ).then((res: any) => {
      const resAcStatus = res?.payload?.data?.data?.status || null;

      if (res?.payload?.status === 200) {
        if (
          userId &&
          (resAcStatus === 1 || resAcStatus === 9 || resAcStatus === 2)
        ) {
          activationStatusResults(res);
        } else if (!userId) {
          activationStatusResults(res);
        }
      }

      if (
        resAcStatus === 1 ||
        resAcStatus === 9 ||
        res?.payload?.status === "FAILURE"
      ) {
        callActivationStatus();
      }
    });
  };
  //closes the currently opened modal
  const closeModal = () => {
    setModalName("");
  };
  const changeModal = () => {
    setModalName("portRejected");
    setModalData(portReject1);
  };
  // changes modal content based on sms received and sms not received
  const smsHandler = (keyName: string) => {
    setModalName("portRejected");
    setModalData(activationModals[keyName]);
  };
  // triggered during retry activation in 1017 senario.
  const retryActivationFn = () => {
    setButtonLoader(true);
    const py = { ...activationPayload, password: password };
    callActivation(py);
  };
  return modalName ? (
    <>
      {/* status = 10 (1009, 1010, 1011) */}
      {modalName !== "cc" &&
        modalName !== "statusSuccess" &&
        modalName !== "portRejected" && (
          <PortingDialogue
            name={modalName}
            modalData={modalData}
            closeHandler={modalCloseFn}
            submitHandler={submitHandler}
            buttonLoaderFg={buttonLoaderFg}
            defaultError={notificationMsg.defaultError}
          />
        )}
      {/* status = 8||10 */}
      {modalName === "cc" && (
        <ContactCustomerDialog
          modalData={modalData}
          closeHandler={modalCloseFn}
        />
      )}
      {/* status = 2 */}
      {modalName === "statusSuccess" && (
        <ActivationStatusSuccess
          closeHandler={closeModal}
          modalData={modalData}
          customerDetails={customerDetails}
        />
      )}
      {/*  status = 10  (1017) */}
      {modalName === "portRejected" && (
        <PortRequestRejected
          modalData={modalData}
          changeModal={changeModal}
          smsHandler={smsHandler}
          retryActivationFn={retryActivationFn}
          buttonLoaderFg={buttonLoaderFg}
        />
      )}
    </>
  ) : null;
};
