import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Config from "../../config/app.config.json";
import EnvConfig from "../../config/env.config.json";
import { helpSupportStrapi } from "../../features/strapi/contactUsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";

const ContactUsComponent = isTemplateExisits(Config.ContactUs.HelpSupport)
  ? require(`./${Config.ContactUs.HelpSupport.template}_ContactUs`).default
  : null;

export const ContactUsComp = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { helpSupportContent, errorHelpSupportContent } = useAppSelector(
    (state: any) => state?.strapi?.contactUsPage || {}
  );
  useEffect(() => {
    dispatch(helpSupportStrapi());
  }, []);
  const onClickHandler = (
    gaName: string,
    _: any,
    navigationPath?: string,
    redirectionPage?: string
  ) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.contactUs,
      category: gaCategory.helpSupport,
      buttonValue: gaName,
    });
    if (redirectionPage === "_blank") {
      navigationPath && window.open(navigationPath, redirectionPage);
    } else {
      navigationPath && navigate(navigationPath);
    }
  };

  return helpSupportContent ? (
    <ContactUsComponent
      helpSupportContent={helpSupportContent}
      onClickHandler={onClickHandler}
    />
  ) : errorHelpSupportContent ? (
    <div>{errorHelpSupportContent}</div>
  ) : null;
};
