import { Box } from "@mui/material";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import styles from "./PlanCard.module.scss";

export const PlanCard = (props: any) => {
  const { plan, content } = props;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Box textAlign={{ xs: "center" }} className={styles.plan_card}>
          <Box
            sx={{
              fontSize: "20px",
              fontWeight: "var(--font_weight_4)",
              fontFamily: "var(--font_family_Bold)",
            }}
          >
            {plan?.name}
          </Box>
          <Box className={styles.planData}>
            <Box
              component="img"
              src={
                plan?.isUnlimited
                  ? content?.plan_image2?.data?.attributes?.url
                  : content?.plan_image?.data?.attributes?.url
              }
              alt={content?.plan_image?.data?.attributes?.alternativeText}
              className={styles.planImg}
            />

            <Box className={styles.planRate}>
              {" "}
              {plan?.planData}
              {plan?.dataUnit}{" "}
            </Box>
            <Box className={styles.planText}>
              {" "}
              -{" "}
              {plan?.isUnlimited
                ? content?.non_shareable_text
                : content?.shareable_text}{" "}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            textAlign={{ xs: "center" }}
            className={styles.separator}
            style={{ background: "rgb(169, 118, 162)" }}
          ></Box>
          <Box
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--text_color)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
            className={styles.planCostData}
          >
            <Box
              sx={{
                fontFamily: "var(--font_family_Bold)",
                color: "var(--text_color)",
                fontSize: "24px",
                fontWeight: "var(--font_weight_4)",
                mr: "6px",
              }}
              component="span"
            >
              {GetCurrencyFixedToTwo(plan?.baseLinePrice, plan?.currency)}
            </Box>
            <Box
              sx={{
                fontFamily: "var(--font_family_Semibold)",
                color: "var(--text_color)",
                fontSize: "14px",
                fontWeight: "var(--font_weight_5)",
              }}
              component="span"
            >
              {content?.per_month}&nbsp;
            </Box>
            <Box
              sx={{
                fontFamily: "var(--font_family_Semibold)",
                color: "var(--text_color)",
                fontSize: "14px",
                fontWeight: "var(--font_weight_5)",
              }}
              component="span"
            >
              {content?.tax_fees}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
