import { HomeBannerProps } from "./HomeBanner";
import { Box, Grid, Paper, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Styles from "./S2_HomeBanner.module.scss";
import envConfig from "../../../config/env.config.json";
import clsx from "clsx";
import { LazyLoadImageCust } from "../..";

const HomeBannerT6 = (props: HomeBannerProps) => {
  const {
    heading,
    sub_heading,
    button_text,
    banner_web,
    banner_mobile,
    banner_ipad,
    banner_ipad_pro,
    offer_img_web,
    offer_img_ipad,
    offer_img_mweb,
    desc,
    desc2,
    desc3,
  } = props?.bannerContent || {};
  const { pageName } = props;
  const { content_carousel, ticks_main_div, main_title, sub_title, msg_style } =
    Styles;
  let customerDetails: any = localStorage?.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);
  const { status } = customerDetails || {};
  return (
    props?.bannerContent && (
      <Container maxWidth={false} disableGutters sx={{ position: "relative" }}>
        <Paper sx={{ padding: 0 }} elevation={0}>
          <Box
            sx={{
              display: {
                xs: "none",
                md: "none",
                lg: "block",
                minHeight: "770px",
              },
            }}
          >
            <LazyLoadImageCust
              src={banner_web?.data?.attributes?.url}
              alt={banner_web?.data?.attributes?.alternativeText}
              width={"100%"}
              style={{ objectFit: "cover" }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "block", lg: "none" },
              marginTop: "76px",
            }}
          >
            <LazyLoadImageCust
              src={banner_ipad_pro?.data?.attributes?.url}
              alt={banner_ipad_pro?.data?.attributes?.alternativeText}
              width={"100%"}
              height={"400px"}
              style={{ objectFit: "cover" }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "block", md: "none" },
              marginTop: "76px",
            }}
          >
            <LazyLoadImageCust
              src={banner_ipad?.data?.attributes?.url}
              alt={banner_ipad?.data?.attributes?.alternativeText}
              width={"100%"}
              height={"465px"}
              style={{ objectFit: "cover" }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "none", md: "none", lg: "none" },
            }}
          >
            <LazyLoadImageCust
              src={banner_mobile?.data?.attributes?.url}
              alt={banner_mobile?.data?.attributes?.alternativeText}
              width={"100%"}
              style={{ objectFit: "cover" }}
            />
          </Box>
        </Paper>
        <Grid
          sx={{
            position: "absolute",
            textAlign: { xs: "center", sm: "left" },
          }}
          className={content_carousel}
        >
          <Grid item>
            <Box>
              {heading && (
                <Typography gutterBottom className={main_title}>
                  {heading}
                </Typography>
              )}
              {sub_heading && (
                <Typography
                  className={clsx(ticks_main_div, sub_title)}
                  sx={{ mt: "15px" }}
                >
                  {sub_heading}
                </Typography>
              )}

              {desc3 ? (
                <Typography className={clsx(ticks_main_div, sub_title)}>
                  {desc3}
                </Typography>
              ) : null}
              <Typography className={clsx(ticks_main_div, sub_title)}>
                {desc}
              </Typography>

              {desc2 && (
                <Typography
                  className={msg_style}
                  dangerouslySetInnerHTML={{
                    __html: desc2,
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        {envConfig.IS_FREE_MODEM && (
          <>
            <Grid
              sx={{
                display: { xs: "none", sm: "none", md: "block", lg: "block" },
                position: "absolute",
                width: "727px",
                height: "132px",
                top: "50%",
              }}
            >
              <LazyLoadImageCust
                src={offer_img_web?.data?.attributes?.url}
                alt={offer_img_web?.data?.attributes?.alternativeText}
              />
            </Grid>
            <Grid
              sx={{
                display: { xs: "none", sm: "block", md: "none", lg: "none" },
                position: "absolute",
                width: "340px",
                height: "62px",
                top: "72%",
              }}
            >
              <LazyLoadImageCust
                src={offer_img_ipad?.data?.attributes?.url}
                alt={offer_img_ipad?.data?.attributes?.alternativeText}
              />
            </Grid>
            <Grid
              sx={{
                display: { xs: "block", sm: "none", md: "none", lg: "none" },
                position: "absolute",
                width: "100%",
                height: "60px",
                top: "55%",
                textAlign: "center",
                backgroundColor: "#9794C5",
              }}
            >
              <LazyLoadImageCust
                src={offer_img_mweb?.data?.attributes?.url}
                alt={offer_img_mweb?.data?.attributes?.alternativeText}
                width={"100%"}
              />
            </Grid>
          </>
        )}
      </Container>
    )
  );
};

export default HomeBannerT6;
