import { useEffect, useState } from "react";
import styles from "./S3_activation.module.scss";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { activationStepsContentT3 } from "../../../features/strapi/activationSlice";
import {
  addMultipleCacheData,
  getCache,
  modemFormattedVal,
  pushTrackingEventsToGAAndFB,
  useNonInitialEffect,
} from "../../../utils/commonFunctions";
import Config from "../../../config/env.config.json";
import {
  activateLine,
  activationStatusCheck,
} from "../../../features/activation/activationSlice";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { Box, Typography } from "@mui/material";
import {
  ButtonCust_T2,
  CustToolTip_T2,
  NotificationCust,
  SimpleModal,
  TextFieldCust,
} from "../../widgets";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../Types/GoogleAnalytics";

export const T3_Activation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { activationStepsDataT3 } = useAppSelector(
    (state: any) => state?.strapi?.activation || {}
  );
  const {
    activateLineStatus,
    activateLineError,
    activationStatus,
    errorActivationStatus,
  } = useAppSelector((state: any) => state?.activation || {});
  const [cacheData, setCacheData] = useState<any>(null);
  const [stepsCount, setStepsCount] = useState<any>(0);
  const [showActivationModal, setShowActivationModal] = useState<any>(false);
  const [showActivationFailedModal, setShowActivationFailedModal] =
    useState<any>(false);
  const [loaderModal, setLoaderModal] = useState<any>(false);
  const [iccid, setIccid] = useState("");

  useEffect(() => {
    getCache(
      "ActivationStepsT3",
      activationStepsContentT3,
      setCacheData,
      dispatch
    );
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("ActivationSteps", activationStepsDataT3);
    if (!cacheData) {
      setCacheData(activationStepsDataT3);
    }
  }, [activationStepsDataT3]);

  const GAAndFBEventsHandler = (categoryName: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.activation,
      category: categoryName,
      buttonValue: value,
    });
  };

  const onLeftArrowClick = () => {
    if (stepsCount === 1) setStepsCount(0);
  };

  const onRightArrowClick = () => {
    if (stepsCount === 0) setStepsCount(1);
  };

  const onActivateSericeClick = () => {
    setShowActivationModal(true);
  };

  const onCloseActivationModal = () => {
    setShowActivationModal(false);
  };

  const onCloseActivationFailedModal = () => {
    setShowActivationFailedModal(false);
  };

  const handleActivation = () => {
    GAAndFBEventsHandler(
      gaCategory.activation,
      cacheData.act_modal_data.btn_txt
    );
    setShowActivationModal(false);
    setShowActivationFailedModal(false);
    setLoaderModal(true);

    let customerDetails: any = localStorage.getItem("customerDetail");
    customerDetails = JSON.parse(customerDetails);
    const formatIccid = iccid.split(":").join("");

    const payload = {
      customerId: customerDetails.id,
      reachPlanId: customerDetails.reachPlanId,
      iccId: formatIccid,
    };
    dispatch(activateLine({ payload: payload }));
  };

  const handleActivationSuccessNotification = () => {
    return NotificationCust({
      message: (
        <Typography
          sx={{
            color: "var(--text_color_11) !important",
            fontFamily: "var(--font_family_Medium) !important",
            fontSize: "16px",
            paddingTop: "50px",
          }}
        >
          Congratulations! Your modem is successfully installed and ready to
          provide you high-speed internet.
        </Typography>
      ),
      duration: 0,
      id: "activationSuccessNotification",
      type: "success",
    });
  };
  useNonInitialEffect(() => {
    if (
      activateLineStatus &&
      activateLineStatus?.code === 200 &&
      activateLineStatus?.msg === "Activation In Progress"
    ) {
      let customerDetails: any = localStorage.getItem("customerDetail");
      customerDetails = JSON.parse(customerDetails);
      dispatch(
        activationStatusCheck({
          payload: { customerId: customerDetails?.id },
        })
      );
    } else {
      setTimeout(() => {
        handleActivationSuccessNotification();
      }, 1000);
      navigate(RoutingURLs.account);
    }
  }, [activateLineStatus]);

  useNonInitialEffect(() => {
    if (activationStatus?.status === 2) {
      setTimeout(() => {
        handleActivationSuccessNotification();
      }, 1000);
      navigate(RoutingURLs.account);
    } else {
      let customerDetails: any = localStorage.getItem("customerDetail");
      customerDetails = JSON.parse(customerDetails);
      dispatch(
        activationStatusCheck({
          payload: { customerId: customerDetails?.id },
        })
      );
    }
  }, [activationStatus]);

  useNonInitialEffect(() => {
    if (activateLineError) {
      setLoaderModal(false);
      setShowActivationFailedModal(true);
    }
  }, [activateLineError]);

  const toolTipData = () => {
    return (
      <>
        <Typography
          variant="subtitle2"
          color={"var(--primary_brand_color)"}
          fontSize={"17px"}
          fontFamily={"var(--font_family_Semibold)"}
          padding={"10px"}
          borderTop={"4px solid var(--primary_brand_color)"}
          borderBottom={"1px solid rgba(118,118,118,0.7)"}
        >
          {cacheData.act_modal_data.tooltip_title}
        </Typography>
        <Typography
          variant="body2"
          color={"var(--text_color_8)"}
          fontSize={"15px"}
          fontFamily={"var(--font_family_Semibold)"}
          padding={"10px"}
        >
          {cacheData.act_modal_data.tooltip_desc}
        </Typography>
      </>
    );
  };

  const onChangeHandler = (e: any) => {
    setIccid(e.target.value.toUpperCase());
  };

  return (
    <>
      {cacheData && (
        <Box className={styles.activation_conatiner}>
          <Box className={styles.heading_conatiner}>
            <Box
              component="img"
              alt="back_arrow_icon"
              src={cacheData.back_arrow_icon.data.attributes.url || ""}
              className={styles.back_arrow_icon}
            ></Box>
            <Box className={styles.heading}>{cacheData.heading}</Box>
            {/* <Box className={styles.setup_video}>
              <Box
                component="img"
                alt="play_icon"
                src={cacheData.setup_video_icon.data.attributes.url || ""}
              ></Box>
              <Box className={styles.setup_video_txt}>
                {cacheData.setup_video}
              </Box>
            </Box> */}
          </Box>
          <Box className={styles.progress_bar_container}>
            <Box
              className={
                stepsCount === 1
                  ? styles.progress_bar + " " + styles.addOpacity
                  : styles.progress_bar
              }
            ></Box>
            <Box
              className={
                stepsCount === 0
                  ? styles.progress_bar + " " + styles.addOpacity
                  : styles.progress_bar
              }
            ></Box>
          </Box>

          <Box className={styles.steps_container}>
            <Box
              className={
                stepsCount === 0
                  ? styles.left_arrow_icon + " " + styles.disableIcon
                  : styles.left_arrow_icon
              }
              onClick={onLeftArrowClick}
            >
              <Box
                component="img"
                alt="left_arrow_icon"
                src={cacheData.left_arrow_icon.data.attributes.url || ""}
              ></Box>
            </Box>
            <Box
              className={
                stepsCount === 1
                  ? styles.right_arrow_icon + " " + styles.disableIcon
                  : styles.right_arrow_icon
              }
              onClick={onRightArrowClick}
            >
              <Box
                component="img"
                alt="right_arrow_icon"
                src={cacheData.right_arrow_icon.data.attributes.url || ""}
              ></Box>
            </Box>
            <Box className={styles.act_title}>
              {cacheData.act_data.title[stepsCount]}
            </Box>
            <Box className={styles.step_img}>
              <Box
                component="img"
                alt="steps_img"
                src={cacheData.steps_img.data[stepsCount].attributes.url || ""}
              ></Box>
            </Box>
            {cacheData.act_data[`step${stepsCount + 1}`].map(
              (data: any, idx: number) => {
                return (
                  <Box className={styles.act_steps} key={idx}>
                    <Box
                      component="img"
                      alt="tick_icon"
                      src={cacheData.tick_icon.data.attributes.url || ""}
                    ></Box>
                    <Box className={styles.act_step}>{data}</Box>
                  </Box>
                );
              }
            )}
            {stepsCount === 1 && (
              <Box className={styles.s2_note}>{cacheData.act_data.note_s2}</Box>
            )}
          </Box>

          <Box className={styles.activate_btn}>
            <ButtonCust_T2
              variantType={"primary_contained_square"}
              className={styles.activate_btn}
              onClick={onActivateSericeClick}
            >
              {cacheData.btn_text}
            </ButtonCust_T2>
          </Box>
        </Box>
      )}
      {showActivationModal && (
        <SimpleModal
          isShowModal={showActivationModal}
          onCloseModal={onCloseActivationModal}
          showClose={true}
          className={styles.act_modal_wrapper}
        >
          <Box className={styles.act_modal_conatiner}>
            <Box className={styles.act_modal_title}>
              {cacheData.act_modal_data.title}
            </Box>
            <Box className={styles.act_modal_desc}>
              {cacheData.act_modal_data.desc}
            </Box>
            <Box className={styles.act_modal_input_conatiner}>
              <Box className={styles.act_modal_input_title}>
                {cacheData.act_modal_data.input_title}{" "}
                <CustToolTip_T2 title={toolTipData()} arrow>
                  <Box
                    component="img"
                    alt="icon"
                    src={cacheData.details_icon.data.attributes.url || ""}
                  ></Box>
                </CustToolTip_T2>
              </Box>
              <TextFieldCust
                value={modemFormattedVal(iccid.toString())}
                name="macAddress"
                maxlength={17}
                label={cacheData.act_modal_data.input_label}
                onChange={(e: any) => onChangeHandler(e)}
                className={styles.act_modal_textfield}
                onBlur={(e) => null}
              />
            </Box>
            <ButtonCust_T2
              variantType={"primary_contained_square"}
              className={styles.confirm_btn}
              disabled={!iccid}
              onClick={handleActivation}
            >
              {cacheData.act_modal_data.btn_txt}
            </ButtonCust_T2>
            <Box className={styles.cancel_txt} onClick={onCloseActivationModal}>
              {cacheData.act_modal_data.cancel_txt}
            </Box>
          </Box>
        </SimpleModal>
      )}
      {showActivationFailedModal && (
        <SimpleModal
          isShowModal={showActivationFailedModal}
          onCloseModal={onCloseActivationFailedModal}
          showClose={true}
          className={styles.act_fail_modal_wrapper}
        >
          <Box className={styles.act_fail_modal_conatiner}>
            <Box className={styles.act_fail_modal_title}>
              {cacheData.act_modal_data.act_failed}
            </Box>
            <Box
              component="img"
              alt="cross_icon"
              src={cacheData.cross_icon.data.attributes.url || ""}
            ></Box>
            <Box className={styles.act_fail_modal_desc}>
              {cacheData.act_modal_data.act_failed_msg}
            </Box>
            <ButtonCust_T2
              variantType={"primary_contained_square"}
              className={styles.confirm_btn}
              sx={{ width: "200px" }}
            >
              <a
                href="#hs-chat-open"
                rel="noopener noreferrer"
                style={{ color: "var(--white)", textDecoration: "none" }}
              >
                {cacheData.act_modal_data.chat_now}
              </a>
            </ButtonCust_T2>
          </Box>
        </SimpleModal>
      )}
      {loaderModal && (
        <SimpleModal
          isShowModal={loaderModal}
          onCloseModal={null}
          showClose={false}
          className={styles.loader_container}
        >
          <Box className={styles.loader_title}>
            {cacheData.act_modal_data.loader_title}
          </Box>
          <Box className={styles.act_loader}>
            <img src={cacheData.act_loader.data.attributes.url} alt="loader" />
          </Box>
          <Box className={styles.loader_status}>
            {cacheData.act_modal_data.loader_status}
          </Box>
        </SimpleModal>
      )}
    </>
  );
};

export default T3_Activation;
