import { useState } from "react";
import { Box, RadioGroup, Typography } from "@mui/material";
import { ButtonCust, Divider, SimpleModal, RadioCust } from "../widgets";
import { ChangeLinePreProps } from "./ChangeLinePreferenceDialog";
import classes from "./T1_ChangeLinePreferenceDialog.module.scss";
import Config from "../../config/env.config.json";
import { SimTypes } from "../../features/checkout/Types";
import { GetCurrencyFixedToTwo } from "../../utils/commonFunctions/GetCurrencyFixedToTwo";

const T1_ChangeLinePreferenceDialog = (props: ChangeLinePreProps) => {
  const { open, onClose, content, onConfirm, simType, setSimType, pageName, simCost } =
    props;
  const handleSimTypeChange = (e: any) => {
    setSimType(e.target.value);
  };

  return (
    <SimpleModal
      isShowModal={open}
      onCloseModal={onClose}
      showClose={true}
      sx={{ width: { xs: "90%", sm: "60%", md: "80%", lg: "35%", xl: "35%" } }}>
      <Box style={{ width: "100%", alignItems: "center" }}>
        <Typography className={classes.title}>{content?.cl_title}</Typography>
        <Box style={{ margin: "15px auto 30px auto" }}>
          <Divider />
        </Box>

        {pageName === "activation" && (
          <Box
            style={{
              fontFamily: "var(--font_family_Bold)",
              fontSize: "var(--font_weight_0)",
              color: "var(--circular_loader_color)",
            }}>
           {content?.act_desc_txt}
          </Box>
        )}
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            width: "330px",
            margin: "auto",
          }}>
          <Box className={classes.label_txt}>
            {pageName === "activation" ? content?.single_line : content?.add_line_lbl}
          </Box>
          <Box sx={{ marginLeft: { sm: "auto", xs: "15px" } }}>
            <RadioGroup
              value={simType}
              onChange={(e: any) => handleSimTypeChange(e)}
              style={{ display: "flex" }}
              row>
              <RadioCust value={SimTypes.eSim} label={content?.esim_lbl} />
              <RadioCust value={SimTypes.sim} label={content?.sim_lbl} />
            </RadioGroup>
          </Box>
        </Box>
        {pageName === "activation" && simType === SimTypes.sim && (
          <Box
            style={{
              display: "flex",
              alignItems: "baseline",
              margin: "auto",
              marginTop: "30px",
            }}>
            <Box className={classes.note_style}>{content?.note_txt}</Box>
            <Box pl={1}>
              {content?.note_desc1 && content?.note_desc1?.map((val: any) => {
                return <Box className={classes.note_style}> {val?.item.replace(
                  "${0}",
                  GetCurrencyFixedToTwo(simCost)
                )}</Box>
              })}
            </Box>
          </Box>
        )}
        <Box className={classes.btn_container}>
          <ButtonCust
            variantType={Config.PRIMARY_BUTTON_TYPE}
            sx={{
              my: "10px",
              width: {
                xs: "95%",
                md: simType === SimTypes.eSim ? "300px" : "240px",
              },
              height: "45px",
            }}
            onClick={onConfirm}>
            {simType === SimTypes.eSim && pageName !== "activation"
              ? content?.ctx_c_c_btn
              : content?.cl_btn_ctx}
          </ButtonCust>
        </Box>
      </Box>
    </SimpleModal>
  );
};

export default T1_ChangeLinePreferenceDialog;
