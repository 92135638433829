import { Box } from "@mui/material";
import { ButtonCust, SimpleModal } from "../../../widgets";


type Props = {
  modalFg: boolean;
  closeHandler?: any;
  content:any;
};

export function IMEIFailureModal(props: Props) {
  const { modalFg, closeHandler, content } = props;
  const { title, desc, try_again, shop_btn_txt, shop_phones_url } =
    content?.imei_fail_md || {};
  return (
    <SimpleModal
      isShowModal={modalFg}
      onCloseModal={closeHandler}
      showClose={true}
      sx={{ width: { xs: "95%", sm: "60%", md: "55%", lg: "50%", xl: "45%" } }}
      style={{ borderRadius: "4px" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        p={1}
        pt={0.5}
        alignItems="center"
      >
        <Box
          sx={{
            fontSize: "22px",
            color: "var(--text_color)",
            fontWeight: "var(--font_weight_4)",
          }}
        >
          {title}
        </Box>
        <Box
          pt={2}
          sx={{
            fontSize: "14px",
            color: "var(--text_color)",
            textAlign: "center",
          }}
        >
          {desc}
        </Box>
        <Box
          pt={1}
          sx={{
            fontSize: "14px",
            color: "var(--primary_color)",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={closeHandler}
        >
          {try_again}
        </Box>
        <Box pt={3}>
          <ButtonCust onClick={() => window.open(shop_phones_url, "_blank")}>
            {shop_btn_txt}
          </ButtonCust>
        </Box>
      </Box>
    </SimpleModal>
  );
}
