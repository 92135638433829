import { Chart } from "react-google-charts";
import styles from "./ComparePlanGraph.module.scss";
import { Skeleton } from "@mui/material";

type comparPlanGraphProps = {
  carrierCost: number,
  reachCost: number, 
  data: any,
}

export const ComparePlanGraph = (props: comparPlanGraphProps) => {
  const { carrierCost, reachCost, data } = props;
  let dataArray: any = [];

  dataArray.push([
    data?.graphX,
    { type: "string", role: "tooltip", p: { html: true } },
    data?.brand_short,
    data?.your_carrier,
  ]);

  let currentMonth = new Date().getMonth();

  const months = data?.months;

  for (let index = 0; index < months.length; index++) {
    let val = months[(currentMonth + index) % 12].month_name;
    dataArray.push([
      val,
      `<div class="${styles.tooltip_content}">
        <div class="${styles.individual_div} ${styles.comparison_color1}">
          <div>${data?.your_carrier}</div>
          <div>${carrierCost}</div>
        </div>
        <div class="${styles.individual_div} ${styles.comparison_color2}">
          <div>${data?.brand_short}</div>
          <div>${reachCost}</div>
        </div>
        <div class="${styles.individual_div}">
          <div class="${styles.comparison_font}">${data?.total_saving}</div>
          <div class="${styles.comparison_font}">${
        carrierCost - reachCost
      }</div>
        </div>
      </div>`,
      parseInt(reachCost.toString()),
      parseInt(carrierCost.toString()),
    ]);
  }
  return (
    <div className={styles.graph}>
      <Chart
        height={"250px"}
        chartType="LineChart"
        loader={<Skeleton height="250px" width="100%" />}
        data={dataArray}
        options={{
          hAxis: {
            title: data?.graphX,
            viewWindow: { min: 0 },
          },
          vAxis: {
            title: data?.graphY,
            viewWindow: { min: 0, max: carrierCost },
          },
          legend: { position: "top", alignment: "end" },
          chartArea: {
            left: 60,
            bottom: 60,
            top: 40,
            right: 20,
            width: "100%",
            height: "100%",
          },
          colors: [data?.brand_color, data?.carrier_color],
          pointSize: 8,
          tooltip: { isHtml: true, trigger: "visible" },
          focusTarget: "category",
        }}
      />
    </div>
  )
}