import { Box, Link, Typography } from "@mui/material";
import { ButtonCust, SimpleModal, TextFieldCust } from "../../../widgets";
import styles from "./PortingDialog.module.scss";
import { useFormik } from "formik";
import { string, object } from "yup";
import {
  numberAlphabetPattern,
  PhoneNoPattern,
} from "../../../../utils/regexPatterns";
import { useAppSelector } from "../../../../app/hooks";

type Props = {
  name: any;
  modalData: any;
  closeHandler: any;
  submitHandler: any;
  buttonLoaderFg: boolean;
  defaultError: string;
};
export const PortingDialogue = (props: Props) => {
  const {
    name,
    modalData,
    closeHandler,
    submitHandler,
    buttonLoaderFg,
    defaultError,
  } = props;
  const { activateLineError } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const { validations } = useAppSelector(
    (state: any) => state?.strapi?.postActivation?.activationModals || {}
  );
  const { fieldReq, inValid, pinInValid, zipInValid } = validations || {};

  const { title, desc_text, desc_text2, txt2_link, Note, btn, placeholder } =
    modalData;

  const handleClose = () => {
    closeHandler();
    AccNumberformik.setErrors({});
    AccNumberformik.resetForm();
    Zipcodeformik.setErrors({});
    Zipcodeformik.resetForm();
    verizonpinformik.setErrors({});
    verizonpinformik.resetForm();
  };

  const formikintialvalues = {
    zipcode: "",
    accnum: "",
    verizonpin: "",
    pin: "",
  };
  const AccNumberSchema = object({
    accnum: string()
      .trim()
      .required(fieldReq)
      .matches(numberAlphabetPattern, inValid),
  });
  const VerizonSchema = object({
    verizonpin: string()
      .trim()
      .required(fieldReq)
      .matches(/(^\d{4}$)|(^\d{6}$)|(^\d{5}-\d{4}$)/, pinInValid),
  });
  const pinNumSchema = object({
    pin: string().trim().required(fieldReq),
  });

  const ZipcodeSchema = object({
    zipcode: string()
      .trim()
      .required(fieldReq)
      .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, zipInValid),
  });

  const AccNumberformik = useFormik({
    initialValues: formikintialvalues,
    validationSchema: AccNumberSchema,
    onSubmit: (values: any) => {
      submitHandler(values.accnum);
    },
    enableReinitialize: true,
  });
  const verizonpinformik = useFormik({
    initialValues: formikintialvalues,
    validationSchema: VerizonSchema,
    onSubmit: (values: any) => {
      submitHandler(values.verizonpin);
    },
    enableReinitialize: true,
  });
  const pinNumformik = useFormik({
    initialValues: formikintialvalues,
    validationSchema: pinNumSchema,
    onSubmit: (values: any) => {
      submitHandler(values.pin);
    },
    enableReinitialize: true,
  });
  const Zipcodeformik = useFormik({
    initialValues: formikintialvalues,
    validationSchema: ZipcodeSchema,
    onSubmit: (values: any) => {
      submitHandler(values.zipcode);
    },
    enableReinitialize: true,
  });

  const commonOnChangeFn = (e: any, formikFn: any, pt?: string) => {
    const regexPatterns =
      pt === "alphaNum" ? numberAlphabetPattern : PhoneNoPattern;
    if (regexPatterns.test(e.target.value || e.target.value === "")) {
      formikFn.handleChange(e);
    }
  };
  return (
    <>
      <SimpleModal
        isShowModal={true}
        onCloseModal={handleClose}
        showClose={true}
        className={styles.porting_dialog}
        sx={{
          borderRadius: "4.8px",
          maxWidth: { xs: "95%", sm: "525px" },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box className={styles.dialog_main}>
          <Typography className={styles.dialog_title}>{title}</Typography>
          <Typography className={styles.dialog_desc_text}>
            {desc_text}
          </Typography>
          {desc_text2 && (
            <Link
              onClick={() => window.open(txt2_link, "_blank")}
              underline="none"
              className={styles.link}
            >
              {desc_text2}
            </Link>
          )}
          <Box
            sx={{
              width: "100%",
            }}
            className={styles.input_field}
          >
            {name === "zipCode" && (
              <TextFieldCust
                name="zipcode"
                label={placeholder}
                maxlength={5}
                value={Zipcodeformik.values.zipcode}
                error={Zipcodeformik.errors?.zipcode ? true : false}
                helperText={<>{Zipcodeformik.errors?.zipcode}</>}
                onBlur={Zipcodeformik.handleBlur}
                onChange={(e: any) => commonOnChangeFn(e, Zipcodeformik)}
              />
            )}
            {name === "accNum" && (
              <TextFieldCust
                name="accnum"
                value={AccNumberformik.values.accnum}
                error={AccNumberformik.errors?.accnum ? true : false}
                helperText={<>{AccNumberformik.errors?.accnum}</>}
                onBlur={AccNumberformik.handleBlur}
                onChange={(e: any) => commonOnChangeFn(e, AccNumberformik)}
                label={placeholder}
                maxlength={20}
              />
            )}
            {name === "verizonPin" && (
              <TextFieldCust
                label={placeholder}
                name="verizonpin"
                value={verizonpinformik.values.verizonpin}
                error={verizonpinformik.errors?.verizonpin ? true : false}
                helperText={<>{verizonpinformik.errors?.verizonpin}</>}
                onBlur={verizonpinformik.handleBlur}
                onChange={(e: any) => commonOnChangeFn(e, verizonpinformik)}
                maxlength={6}
                minLength={4}
              />
            )}
            {name === "pinNum" && (
              <TextFieldCust
                label={placeholder}
                name="pin"
                value={pinNumformik.values.pin}
                error={pinNumformik.errors?.pin ? true : false}
                helperText={<>{pinNumformik.errors?.pin}</>}
                onBlur={pinNumformik.handleBlur}
                onChange={(e: any) => commonOnChangeFn(e, pinNumformik)}
                maxlength={15}
              />
            )}
            {Note && (
              <Typography
                sx={{
                  opacity: "0.6",
                  paddingTop: "5px",
                }}
              >
                {Note}
              </Typography>
            )}
            {activateLineError && (
              <Box className={styles.porting_failure}>{defaultError}</Box>
            )}
          </Box>

          <ButtonCust
            fullWidth={name === "verizonPin" ? false : true}
            loading={buttonLoaderFg}
            onClick={() =>
              (name === "zipCode" && Zipcodeformik.handleSubmit()) ||
              (name === "accNum" && AccNumberformik.handleSubmit()) ||
              (name === "verizonPin" && verizonpinformik.handleSubmit()) ||
              (name === "pinNum" && pinNumformik.handleSubmit())
            }
          >
            {btn}
          </ButtonCust>
        </Box>
      </SimpleModal>
    </>
  );
};
