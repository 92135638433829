import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  profileDetailsContent: any;
  errorProfileDetailsContent: any;
};

const initialState: InitialState = {
  profileDetailsContent: null,
  errorProfileDetailsContent: null,
};

// Generates pending, fulfilled and rejected action types
export const profileContent = createAsyncThunk("profile", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Profile.ProfileDetailsForm.template}${StrapiApiEndPoints.profileDetails}`,
    isStrapiApi: true,
  });
});

const strapiProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      profileContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.profileDetailsContent = action.payload?.data?.data?.attributes;
        state.errorProfileDetailsContent = null;
      }
    );
    builder.addCase(
      profileContent.rejected,
      (state: InitialState, action: any) => {
        state.profileDetailsContent = null;
        state.errorProfileDetailsContent =
          action.error || "Something went wrong";
      }
    );
  },
});

export default strapiProfileSlice.reducer;
