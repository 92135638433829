import { styled, LinearProgress, linearProgressClasses } from "@mui/material";

export const LinearProgressBarCust = styled(LinearProgress)(({ theme }) => ({
  height: "5px",
  borderRadius: "2.5px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "var(--bill_cal_input_bg_color)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "var(--success)",
  },
}));
