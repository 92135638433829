import styles from "./S1_BestFitSavings.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import config from "../../../config/env.config.json";
import { BestFitPotentialSavingsProps } from "./BestFitSavings";
import clsx from "clsx";
const BestFitSavingsComponent = (props: BestFitPotentialSavingsProps) => {
  const { title, description, image_1, image_2, button_text } =
    props.content || {};
  const { explorePlansHandler, isButtonRequired } = props;
  return (
    props.content && (
      <Grid
        container
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          mb: { xs: "10px", sm: "25px", md: "80px" },
          pt: { sm: "50px" },
        }}
        className={styles.best_fit_psavings}
      >
        <Grid
          item={true}
          xs={10}
          md={6}
          sx={{ px: { md: "50px" }, textAlign: { xs: "center", md: "left" } }}
          className={styles.best_fit_pcontent}
          order={{ xs: 3, md: 2 }}
        >
          <Typography
            variant="h3"
            sx={{ mb: "15px" }}
            className={styles.best_fit_features_title}
          >
            {title || ""}
          </Typography>
          <Typography
            variant="body1"
            className={clsx(
              "description_text",
              styles.best_fit_pcontent_description
            )}
            sx={{ mb: { md: "33px", xs: "25px" } }}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></Typography>
          <Box
            component="img"
            alt={image_1?.data?.attributes?.alternativeText}
            src={image_1?.data?.attributes?.url || ""}
            sx={{
              width: { xs: "100%", lg: "75%" },
              height: { xs: 164, md: 336, lg: "100%" },
            }}
          ></Box>
          {isButtonRequired && button_text && (
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  p: { sm: "0 15px", md: "0px", xs: "20px 0px 30px" },
                  my: { xs: "30px" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <ButtonCust
                  variantType={config.PRIMARY_BUTTON_TYPE}
                  onClick={explorePlansHandler}
                >
                  {button_text}
                </ButtonCust>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid
          item
          md={6}
          sx={{ px: "50px", mb: { xs: "70px", md: "0" } }}
          order={{ XS: 2, md: 3 }}
        >
          <Box
            component="img"
            alt={image_2?.data?.attributes?.alternativeText}
            src={image_2?.data?.attributes?.url || ""}
            sx={{
              width: { xs: "100%", lg: "75%" },
              height: { xs: 223, md: 477, lg: "100%" },
            }}
          ></Box>
        </Grid>
      </Grid>
    )
  );
};
export default BestFitSavingsComponent;
