import { Box, Grid, Typography } from "@mui/material";
import styles from "./S3_BrandFeatures.module.scss";
import { brandFeaturesProps } from "./BrandFeatures";
import { useLocation } from "react-router-dom";

const BrandFeaturesComponent = (props: brandFeaturesProps) => {
  const location = useLocation();
  const { featureMainContent } = props;

  const {
    brand_features,
    // feature_card,
    // high_light_cards,
    web_icons,
    // img_name,
    // featureMainImg,
    brandFeatureHeading,
    brandFeatureDescription,
    featureHeading,
    featureDescription,
  } = styles;
  const { featureDetails } = featureMainContent;
  return (
    <>
      {/* heading section */}
      {location.pathname === "/data-plans" && (
        <Grid
          item
          flexDirection="row"
          md={10}
          sx={{
            display: "block",
            mt: { md: 12, xs: 16 },
          }}
        >
          <Typography
            variant="h6"
            textAlign="center"
            className={brandFeatureHeading}
            sx={{ margin: "auto" }}
          >
            {featureMainContent?.brandFeatureHeading2}
          </Typography>
        </Grid>
      )}
      {location.pathname !== "/data-plans" && (
        <Grid
          item
          flexDirection="row"
          sx={{
            display: { xs: "block", md: "block", lg: "none" },
            width: "100%",
            textAlign: "center",
            backgroundColor: { xs: "var(--white)", sm: "var(--white)" },
            paddingTop: { xs: "50px", sm: "70px" },
          }}
        >
          <Typography
            textAlign="center"
            className={brandFeatureHeading}
            sx={{
              fontSize: { xs: "26px !important", md: "32px !important" },
              backgroundColor: { xs: "var(--white)", sm: "var(--white)" },
            }}
          >
            {featureMainContent?.brandFeatureHeading}
          </Typography>
          <Typography
            textAlign="center"
            className={brandFeatureDescription}
            sx={{ fontSize: { sm: "16px !important", md: "18px !important" } }}
          >
            {featureMainContent?.brandFeatureDesc}
          </Typography>
        </Grid>
      )}
      {/* feature seaction */}
      <Grid
        container
        className={brand_features}
        sx={{
          display: { md: "block", lg: "flex" },
          backgroundColor:
            location.pathname !== "/data-plans"
              ? {
                  xs: "var(--white)",
                  sm: "var(--white)",
                  md: "var(--lead_generation_bg_color)",
                }
              : "var(--lead_generation_bg_color)",
          mt: { md: 12, sm: 0, xs: 0 },
          flexDirection: "row",
          justifyContent:
            location.pathname !== "/data-plans"
              ? { xs: "center", md: "center", lg: "space-between" }
              : " center",
        }}
      >
        {/* main image */}
        {location.pathname !== "/data-plans" && (
          <Box
            sx={{
              height: { lg: 0, md: 0, sm: "70px", xs: "50px" },
              width: "100%",
              backgroundColor: "var(--white)",
            }}
          ></Box>
        )}
        {location.pathname !== "/data-plans" && (
          <Grid
            item
            lg={4.5}
            // md={4.5}
            sx={{ textAlign: { xs: "center", md: "center" } }}
            justifyContent="space-evenly"
          >
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "block" },
                width: "537px",
                height: "720px",
              }}
              component="img"
              src={featureMainContent?.brandFeatureImg?.data?.attributes?.url}
              alt={
                featureMainContent?.brandFeatureImg?.data?.attributes
                  ?.alternativeText
              }
            ></Box>
            <Box
              sx={{
                display: { xs: "none", sm: "block", md: "block", lg: "none" },
                width: "410px",
                margin: "auto",
              }}
              component="img"
              src={
                featureMainContent?.brandFeatureImg_ipad?.data?.attributes?.url
              }
              alt={
                featureMainContent?.brandFeatureImg_ipad?.data?.attributes
                  ?.alternativeText
              }
            ></Box>
            <Box
              sx={{
                display: { xs: "block", sm: "none", md: "none" },
                width: "100%",
              }}
              component="img"
              src={
                featureMainContent?.brandFeatureImg_mobile?.data?.attributes
                  ?.url
              }
              alt={
                featureMainContent?.brandFeatureImg_mobile?.data?.attributes
                  ?.alternativeText
              }
            ></Box>
          </Grid>
        )}
        {/* featureDetails */}
        <Grid
          item
          sx={{
            display: { md: "flex", xs: "block" },
            flexDirection: "column",
            width: "100%",
            margin: "auto",
            marginBottom:
              location.pathname === "/data-plans" ? "120px" : "auto",
          }}
          md={6}
        >
          {location.pathname !== "/data-plans" && (
            <Grid
              item
              flexDirection="row"
              m={3}
              sx={{ display: { xs: "none", md: "none", lg: "block" } }}
            >
              <Typography
                variant="h6"
                textAlign="left"
                className={brandFeatureHeading}
              >
                {featureMainContent?.brandFeatureHeading}
              </Typography>
              <Typography
                // variant="h6"
                textAlign="left"
                className={brandFeatureDescription}
              >
                {featureMainContent?.brandFeatureDesc}
              </Typography>
            </Grid>
          )}
          {/* row 1 */}
          <Grid
            item
            sx={{
              display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
              flexDirection: "row",
            }}
            md={12}
          >
            <Grid
              item
              key={featureDetails.keyProp}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems:
                  location.pathname === "/data-plans"
                    ? "center"
                    : { xs: "center", md: "center", lg: "unset" },
                padding: {
                  xs: "15px",
                  sm: "0px 20px",
                  md: "0px 20px",
                  lg: "0px",
                },
                margin: {
                  xs: "15px",
                  sm: "35px 0px",
                  md: "35px 0px",
                  lg: "35px",
                },
              }}
              md={6}
              // m={4}
            >
              <Box
                component="img"
                src={featureDetails[0]?.featureImg?.data?.attributes?.url}
                alt={
                  featureDetails[0]?.featureImg?.data?.attributes
                    ?.alternativeText
                }
                sx={{
                  width: "60px",
                  height: "58px",
                }}
                // className={web_icons}
              ></Box>

              <Typography
                variant="h6"
                textAlign="left"
                className={featureHeading}
                my={2}
              >
                {featureDetails[0]?.featureHeader}
              </Typography>
              <Typography
                variant="h6"
                textAlign={
                  location.pathname !== "/data-plans" ? "left" : "center"
                }
                className={featureDescription}
              >
                {featureDetails[0]?.featureDescription}
              </Typography>
            </Grid>
            <Grid
              item
              key={featureDetails.keyProp}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems:
                  location.pathname === "/data-plans"
                    ? "center"
                    : { xs: "center", md: "center", lg: "unset" },
                padding: {
                  xs: "15px",
                  sm: "0px 20px",
                  md: "0px 20px",
                  lg: "0px",
                },
                margin: {
                  xs: "15px",
                  sm: "35px 0px",
                  md: "35px 0px",
                  lg: "35px",
                },
              }}
              md={6}
              // m={4}
            >
              <Box
                component="img"
                src={featureDetails[1]?.featureImg?.data?.attributes?.url}
                alt={
                  featureDetails[1]?.featureImg?.data?.attributes
                    ?.alternativeText
                }
                sx={{
                  width: "45px",
                  height: "58px",
                }}
                // className={web_icons}
              ></Box>

              <Typography textAlign="left" className={featureHeading} my={2}>
                {featureDetails[1]?.featureHeader}
              </Typography>
              <Typography
                textAlign={
                  location.pathname !== "/data-plans" ? "left" : "center"
                }
                className={featureDescription}
              >
                {featureDetails[1]?.featureDescription}
              </Typography>
            </Grid>
          </Grid>
          {/* row 2 */}
          <Grid
            item
            sx={{
              display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
              flexDirection: "row",
            }}
            md={12}
          >
            <Grid
              item
              key={featureDetails.keyProp}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems:
                  location.pathname === "/data-plans"
                    ? "center"
                    : { xs: "center", md: " center", lg: " unset" },
                padding: {
                  xs: "15px",
                  sm: "0px 20px",
                  md: "0px 20px",
                  lg: "0px",
                },
                margin: {
                  xs: "35px 0px",
                  sm: "35px 0px",
                  md: "35px 0px",
                  lg: "35px",
                },
              }}
              md={6}
              // m={lg:"4"}
            >
              <Box
                component="img"
                src={featureDetails[2]?.featureImg?.data?.attributes?.url}
                alt={
                  featureDetails[2]?.featureImg?.data?.attributes
                    ?.alternativeText
                }
                sx={{
                  width: "45px",
                  height: "58px",
                }}
                // className={web_icons}
              ></Box>

              <Typography textAlign="left" className={featureHeading} my={2}>
                {featureDetails[2]?.featureHeader}
              </Typography>
              <Typography
                textAlign={
                  location.pathname !== "/data-plans" ? "left" : "center"
                }
                className={featureDescription}
              >
                {featureDetails[2]?.featureDescription}
              </Typography>
            </Grid>
            <Grid
              item
              key={featureDetails.keyProp}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems:
                  location.pathname === "/data-plans"
                    ? "center"
                    : { xs: "center", md: "center", lg: "unset" },
                padding: {
                  xs: "15px",
                  sm: "0px 20px",
                  md: "0px 20px",
                  lg: "0px",
                },
                margin: {
                  xs: "35px 0px",
                  sm: "35px 0px",
                  md: "35px 0px",
                  lg: "35px",
                },
              }}
              md={6}
              // m={4}
            >
              <Box
                component="img"
                src={featureDetails[3]?.featureImg?.data?.attributes?.url}
                alt={
                  featureDetails[3]?.featureImg?.data?.attributes
                    ?.alternativeText
                }
                sx={{
                  width: "50px",
                  height: "58px",
                }}
              ></Box>

              <Typography
                variant="h6"
                textAlign="left"
                className={featureHeading}
                my={2}
              >
                {featureDetails[3]?.featureHeader}
              </Typography>
              <Typography
                variant="h6"
                textAlign={
                  location.pathname !== "/data-plans" ? "left" : "center"
                }
                className={featureDescription}
              >
                {featureDetails[3]?.featureDescription}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* map section */}
      {location.pathname !== "/data-plans" && (
        <Grid
          py={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "var(--white)",
          }}
        >
          <Typography
            textAlign="center"
            className={brandFeatureHeading}
            my={2}
            sx={{
              fontSize: {
                xs: "26px !important",
                md: "32px !important",
                lg: "38px !important",
              },
            }}
          >
            {featureMainContent?.additionalFeature?.featureHeader}
          </Typography>
          <Typography
            textAlign={
              location.pathname !== "/data-plans"
                ? { xs: "center", md: "center" }
                : "center"
            }
            className={brandFeatureDescription}
            sx={{
              width: { xs: "100%", md: "400px" },
              margin: "auto",
              fontSize: { xs: "16px", md: "18px", lg: "22px" },
              fontFamily: "var(--font_family_Semibold) !important",
            }}
          >
            {featureMainContent?.additionalFeature?.featureDescription}
          </Typography>
          <Box
            component="img"
            src={
              featureMainContent?.additionalFeature?.featureImg_mobile?.data
                ?.attributes?.url
            }
            alt={
              featureMainContent?.additionalFeature?.featureImg_mobile?.data
                ?.attributes?.alternativeText
            }
            sx={{
              display: { xs: "block", sm: "none", md: "none", lg: "none" },
              marginBottom: "100px",
            }}
            mt={6}
          ></Box>
          <Box
            component="img"
            src={
              featureMainContent?.additionalFeature?.featureImg_ipad?.data
                ?.attributes?.url
            }
            alt={
              featureMainContent?.additionalFeature?.featureImg_ipad?.data
                ?.attributes?.alternativeText
            }
            sx={{
              display: { xs: "none", sm: "block", md: "block", lg: "none" },
              marginBottom: "100px",
            }}
            mt={6}
          ></Box>
          <Box
            component="img"
            src={
              featureMainContent?.additionalFeature?.featureImg?.data
                ?.attributes?.url
            }
            alt={
              featureMainContent?.additionalFeature?.featureImg?.data
                ?.attributes?.alternativeText
            }
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
              marginBottom: "100px",
            }}
            mt={6}
          ></Box>
        </Grid>
      )}
    </>
  );
};

export default BrandFeaturesComponent;
