import homepageReducer from "./homepage/homepageSlice";
import planpageReducer from "./planpage/planpageSlice";
import rootStrapiSlice from "./strapi/rootStrapiSlice";
import checkoutReducer from "./checkout/checkoutSlice";
import internationalServices from "./internationalService/internationalServiceSlice";
import trackorderSlice from "./trackorder/trackorderSlice";
import billCalculatorSlice from "./billCalculator/billCalculatorSlice";
import activationSlice from "./activation/activationSlice";
import customerSlice from "./customer/customerSlice";
import profileDetailsSlice from "./profile/profileSlice";
import accountSlice from "./account/accountSlice";
import billingSlice from "./billing/billingSlice";
import deviceSlice from "./device/deviceSlice";
import fsmSlice from "./fsm/fsmSlice";

const rootSlice: any = {
  homepage: homepageReducer,
  strapi: rootStrapiSlice,
  planpage: planpageReducer,
  checkout: checkoutReducer,
  trackorder: trackorderSlice,
  internationalService: internationalServices,
  billCalculator: billCalculatorSlice,
  activation: activationSlice,
  customer: customerSlice,
  profileDetails: profileDetailsSlice,
  account: accountSlice,
  billing: billingSlice,
  device: deviceSlice,
  fsm: fsmSlice,
};

export default rootSlice;
