import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  helpData: any;
  errorHelpData: any;
};

const initialState: InitialState = {
  helpData: null,
  errorHelpData: null,
};

// Generates pending, fulfilled and rejected action types
export const helpContent = createAsyncThunk("help/helpContent", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.HelpCenter.HelpContent.template}${StrapiApiEndPoints.help}`,
    isStrapiApi: true,
  });
});

const helpSlice = createSlice({
  name: "help",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      helpContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.helpData = action.payload?.data?.data?.attributes;
        state.errorHelpData = null;
      }
    );
    builder.addCase(helpContent.rejected, (state, action) => {
      state.helpData = null;
      state.errorHelpData = action.error || "Something went wrong";
    });
  },
});

export default helpSlice.reducer;
