import { Box, Paper } from "@mui/material";
import styles from "./PromoCodeComp.module.scss";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { Close } from "@mui/icons-material";
import { PopperCust } from "../widgets";

type Props = {
  imeiPormoCoupon?: any;
  imeiPormoHeading?: any;
  imeiPormoText?: any;
  shareHandler?: any;
  shareImage?: any;
  closeShareHandler?: any;
  share?: any;
  shareTarget?: any;
  imeiSocialText?: any;
  imeiMailText?: any;
  giftImage?: any;
  twitter?: any;
  facebook?: any;
  mail?: any;
};

export const PromoCodeComp = (props: Props) => {
  const {
    card_div,
    inner_div,
    left_div,
    image,
    text1,
    imei_mail_link,
    text2,
    share_container,
    text,
    individual_icon,
    icons,
    right_div,
    inner_right_div,
    code,
    share_header,
    close_icon,
    share_header_text,
  } = styles;
  return (
    <Box className={card_div}>
      <Box className={inner_div}>
        <Box className={left_div}>
          <Box
            component={"img"}
            alt={props?.giftImage?.alternativeText}
            className={image}
            src={props?.giftImage?.url}
            width="30px"
            height="30px"
          />
          <Box>
            <Box className={text1}>{props.imeiPormoHeading}</Box>

            <Box className={text2}>{props.imeiPormoText}</Box>
          </Box>
        </Box>
        {props.imeiPormoCoupon && (
          <Box className={right_div}>
            <Box className={inner_right_div}>
              <Box className={code}>{props.imeiPormoCoupon}</Box>
              <Box>
                <Box
                  component="img"
                  id="share"
                  onClick={props.shareHandler}
                  alt={props?.shareImage?.alternativeText}
                  height="30px"
                  width="30px"
                  className={image}
                  src={props.shareImage?.url}
                />
                <PopperCust
                  id={"share"}
                  open={props.share}
                  anchorEl={props.shareTarget}
                >
                  {props.share && (
                    <Paper
                      elevation={0}
                      style={{
                        border: "1px solid rgba(0,0,0,.2)",
                        backgroundColor: "white",
                      }}
                    >
                      <Box
                        className={share_container}
                        onClick={props.closeShareHandler}
                      >
                        <Box className={share_header}>
                          <Close
                            onClick={props.closeShareHandler}
                            className={close_icon}
                          />
                          <Box className={share_header_text}>
                            Invite Friends
                          </Box>
                        </Box>
                        <Box className={icons}>
                          <Box className={individual_icon}>
                            <FacebookShareButton
                              url={
                                "https://www.facebook.com/dialog/share?app_id=2511315725603016&display=popup"
                              }
                              quote={props.imeiSocialText}
                              hashtag={`#reach4good}`}
                            >
                              <Box
                                component={"img"}
                                src={props.facebook.url}
                                alt={props.facebook.alternativeText}
                                width="50px"
                                height="50px"
                              />
                            </FacebookShareButton>
                            <Box className={text}>Facebook</Box>
                          </Box>
                          <Box className={individual_icon}>
                            <TwitterShareButton
                              title={props.imeiSocialText}
                              hashtags={["reach4good"]}
                              url={"https://twitter.com/intent/tweet?"}
                            >
                              <Box
                                component={"img"}
                                src={props?.twitter?.url}
                                alt={props?.twitter?.alternativeText}
                                width="50px"
                                height="50px"
                              />
                            </TwitterShareButton>
                            <Box className={text}>Twitter</Box>
                          </Box>
                          <Box className={individual_icon}>
                            <a
                              className={imei_mail_link}
                              href={props.imeiMailText}
                            >
                              <Box
                                component="img"
                                src={props.mail.url}
                                alt={props.mail.alternativeText}
                                width="50px"
                                height="50px"
                              />
                              <Box className={text}>Mail</Box>
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  )}
                </PopperCust>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
