import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { strapiFooterContent } from "../../../features/strapi/componentSlice";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import EnvConfig from "../../../config/env.config.json";
import { gaEventTypes, gaScreenNames } from "../../../Types/GoogleAnalytics";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { GetCustomer } from "../../../features/checkout/checkoutSlice";

const FooterComponent = isTemplateExisits(Config.Footer)
  ? require(`./${Config.Footer.template}_Footer`).default
  : null;

export const Footer = () => {
  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();

  const [isPostActive, setPostActive] = useState<boolean>(false);
  const [cacheFooter, setCacheFooter] = useState<any>(null);
  const [authUser, setAuthUser] = useState<any>(null);

  let customerDetail: any = localStorage.getItem("customerDetail");
  customerDetail = JSON.parse(customerDetail);
  const { footerContent = {} } = useAppSelector(
    (state) => state?.strapi?.commonPage
  );
  const { isRenderLogin } = useAppSelector((state: any) => state.checkout);
  const { assuranceCardUrl = "", footerText } = footerContent || {};
  useEffect(() => {
    if (
      customerDetail &&
      customerDetail?.simPaid &&
      customerDetail?.status === 2
    ) {
      setPostActive(true);
    }
  }, [customerDetail]);
  useEffect(() => {
    getCache("Footer", strapiFooterContent, setCacheFooter, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("Footer", footerContent);
  }, [footerContent]);

  useEffect(() => {
    getAuthData();
  }, [isRenderLogin]);

  const handleAssuranceCard = () => {
    let assuranceCardURL: any = assuranceCardUrl;
    window.open(assuranceCardURL, "assurance_card", "height=450,width=450");
  };
  const GAAndFBEventsHandler = (category: string, actionType: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.home,
      category: category,
      buttonValue: actionType,
    });
  };
  const onClickHandler = (
    navigationPath: string,
    redirectionPage: string,
    gaName: string,
    name?: string
  ) => {
    if (redirectionPage === "_self") {
      navigate(navigationPath);
    } else {
      if (name && name?.toLowerCase() === "contact us") {
        let customerDetails: any = localStorage.getItem("customerDetail");
        if (customerDetails) customerDetails = JSON.parse(customerDetails);
        if (customerDetails) {
          let custAdd: any = [];
          for (const key in customerDetails?.addresses[1]) {
            if (key !== "name" && key !== "type" && key !== "phone")
              custAdd.push(customerDetails?.addresses[1][key]);
          }
          custAdd = custAdd.join(", ");
          window.open(
            `${navigationPath}?firstname=${customerDetails?.firstName}&lastname=${customerDetails?.lastName}&address=${custAdd}&reach_number=${customerDetails?.primaryNumber}&email=${customerDetails?.emailId}`,
            redirectionPage
          );
        } else if (authUser) {
          let firstName = authUser?.displayName?.split(" ")[0],
            lastName = authUser?.displayName?.split(" ")[1],
            email = authUser?.email;
          window.open(
            `${navigationPath}?firstname=${firstName}&lastname=${lastName}&email=${email}`,
            redirectionPage
          );
        } else window.open(navigationPath, redirectionPage);
      } else window.open(navigationPath, redirectionPage);
    }
    GAAndFBEventsHandler(footerText, gaName);
  };
  const getAuthData = () => {
    const auth = getAuth();
    /**
     * The onAuthStateChanged function will be executed when firebase auth gets changed
     * @param1 auth - Firebase configuration
     * @param2 callback function - @param1 user - logged user data
     */
    onAuthStateChanged(auth, (user: any) => {
      if (user?.displayName) {
        setAuthUser(user);
        dispatch(GetCustomer({ username: encodeURIComponent(user.email) }));
      }
    });
  };

  if ((cacheFooter || footerContent) && FooterComponent) {
    return (
      <FooterComponent
        isPostActive={isPostActive}
        handleAssuranceCard={handleAssuranceCard}
        footerContent={cacheFooter ? cacheFooter : footerContent}
        GAAndFBEventsHandler={GAAndFBEventsHandler}
        onClickHandler={onClickHandler}
        customerDetail={customerDetail}
        authUser={authUser}
      />
    );
  } else {
    return null;
  }
};
