import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Config from "../../config/app.config.json";
import { Store } from "react-notifications-component";
import { useLocation, useNavigate } from "react-router-dom";
import firebase from "../../adapters/firebase";
import { NotificationCust } from "../widgets";
import { strapiLoginContent } from "../../features/strapi/signinSlice";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import { RoutingURLs } from "../../config/RoutingURLs";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";

const RecoverTemplate = isTemplateExisits(Config.Recover.Recover)
  ? require(`./${Config.Recover.Recover.template}_Recover`).default
  : null;

export interface RecoverProps {
  content: any;
  onChangeHandler: any;
  password: any;
  isContinueClicked: any;
  handleEnter: any;
  handleResetPassword: any;
  showSuccessScreen: boolean;
  handleLogin: any;
}

type Props = {};
const notification: any = {
  fpPasswordSuccess: "Password Updated Successfully",
  fpPasswordMismatch: "The passwords don't match",
};

export const RecoverComponent = (props: Props) => {
  let navigate = useNavigate();
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const { loginContent } = useAppSelector(
    (state: any) => state.strapi.signinPage
  );
  const [password, setPassword] = useState({
    forgotPassword: "",
    confirmForgotPassword: "",
  });

  const [cacheContent, setCacheContent] = useState<any>(null);
  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  useEffect(() => {
    getCache("LogInComponent", strapiLoginContent, setCacheContent, dispatch);
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    return () => {
      Store.removeNotification("ErrorForgotPasswordNotification");
      Store.removeNotification("ForgotPasswordNotMatchedNotification");
      Store.removeNotification("ForgotPasswordLessLengthNotification");
      Store.removeNotification("ForgotPasswordEmptyNotification");
    };
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("LogInComponent", loginContent);
  }, [loginContent]);

  const onChangeHandler = (e: any) => {
    setPassword((prevProps) => ({
      ...prevProps,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      handleResetPassword();
    }
  };

  const handleResetPassword = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && Object.keys(params).length > 0 && params.oobCode) {
      if (
        password.forgotPassword === password.confirmForgotPassword &&
        password.forgotPassword.length >= 8 &&
        password.confirmForgotPassword.length >= 8
      ) {
        setIsContinueClicked(true);
        firebase
          .auth()
          .confirmPasswordReset(params.oobCode, password.forgotPassword)
          .then(async () => {
            setIsContinueClicked(false);

            // NotificationCust({
            //   message: notification && notification.fpPasswordSuccess,
            //   id: "ForgotPasswordNotification",
            //   duration: 3000,
            // });
            setShowSuccessScreen(true);
          })
          .catch((_error) => {
            setIsContinueClicked(false);
            NotificationCust({
              // message: error && error.message,
              message: "Your password rest link is no longer valid.",
              type: "danger",
              id: "ErrorForgotPasswordNotification",
            });
          });
      } else {
        if (
          password.forgotPassword === "" ||
          password.confirmForgotPassword === ""
        ) {
          NotificationCust({
            message: "Please enter the password",
            type: "danger",
            id: "ForgotPasswordEmptyNotification",
            duration: 3000,
          });
        } else if (password.forgotPassword !== password.confirmForgotPassword) {
          NotificationCust({
            message: notification && notification.fpPasswordMismatch,
            type: "danger",
            id: "ForgotPasswordNotMatchedNotification",
            duration: 3000,
          });
        } else if (
          password.forgotPassword.length < 8 ||
          password.confirmForgotPassword.length < 8
        ) {
          NotificationCust({
            message:
              "Your password must have a minimum 8 characters and contain at least one number",
            id: "ForgotPasswordLessLengthNotification",
            type: "danger",
          });
        }
      }
    }
  };

  const handleLogin = () => navigate(RoutingURLs.login);

  return (
    (cacheContent || loginContent) && (
      <RecoverTemplate
        content={cacheContent ? cacheContent : loginContent}
        password={password}
        onChangeHandler={(e: any) => onChangeHandler(e)}
        isContinueClicked={isContinueClicked}
        handleResetPassword={handleResetPassword}
        handleEnter={handleEnter}
        showSuccessScreen={showSuccessScreen}
        handleLogin={handleLogin}
      />
    )
  );
};
