import { Box, Card, Grid, Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { ReactNode } from "react";

type Props = { children?: ReactNode; bgImage: any; isRecover?: boolean };

export const T3_LoginAndSignUp = (props: Props) => {
  const { children, bgImage, isRecover } = props;

  return (
    <>
      <Grid container sx={{ position: "relative" }}>
        <Box
          component={"img"}
          alt={bgImage?.data.attributes.alternativeText}
          src={bgImage?.data.attributes.url}
          width="100%"
        />
        {!isRecover && (
          <Card
            sx={{
              position: "absolute",
              width: { xs: "100%", sm: "80%", md: "65%" },
              height: { xs: "90%", sm: "50%", md: "45%" },
              background:
                "linear-gradient(219.01deg, #885C95 0%, #7E6197 100%)",
              top: { xs: "50%", sm: "50%", md: "40%" },
              left: "50%",
              transform: " translate(-50%, -50%)",
            }}
            elevation={6}
          >
            {children}
          </Card>
        )}
      </Grid>
    </>
  );
};

export const T3_LoginAndSignUpList = (props: any) => {
  return (
    <Typography
      gutterBottom
      component="div"
      color={"white"}
      fontSize="20px"
      fontFamily={"var(--font_family_Semibold)"}
      sx={{ display: "flex", alignItems: "center" }}
    >
      <ArrowRightIcon /> {props.content}
    </Typography>
  );
};
