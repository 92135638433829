import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Config from "../../config/app.config.json";
import EnvConfig from "../../config/env.config.json";
import { Store } from "react-notifications-component";
import { useLocation, useNavigate } from "react-router-dom";
import firebase from "../../adapters/firebase";
import { NotificationCust } from "../widgets";
import { validateEmail } from "../../utils/commonFunctions/ValidateEmail";
import {
  loginWithNumber,
  resetPassword,
  storeLoginData,
} from "../../features/checkout/checkoutSlice";
import { strapiLoginContent } from "../../features/strapi/signinSlice";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import { emailPattern } from "../../utils/regexPatterns";
import { updateToHubspot } from "../../utils/commonFunctions/hubspot";
import { globalVal } from "../../utils/globalValues/globalvalues";
import { getInTouchApi } from "../../features/homepage/homepageSlice";
import { RoutingURLs } from "../../config/RoutingURLs";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";
import TagManager from "react-gtm-module";

const LoginTemplate = isTemplateExisits(Config.Login.Login)
  ? require(`./${Config.Login.Login.template}_Login`).default
  : null;
const ForgotPassword = isTemplateExisits(Config.ForgotPassword.ForgotPassword)
  ? require(`./${Config.ForgotPassword.ForgotPassword.template}_ForgotPassword`)
      .default
  : null;

type CommonProps = {
  email: string;
  emailError: string;
  onChangeHandler: any;
  handleToggle: any;
  content: any;
};

export interface LoginProps extends CommonProps {
  password: string;
  passwordError: string;
  isLoginClicked: boolean;
  handleLogIn: any;
  onGoogleLogin: any;
  onAppleLogin: any;
  onFacebookLogin: any;
  handleEnter: any;
  showSignUp?: any;
  isCheckout?: boolean;
}

export interface ForgotPasswordProps extends CommonProps {
  handleForgotPassword: any;
  isForgotClicked: boolean;
}
type Props = {
  isCheckout?: boolean;
  toggleForm?: any;
  commonSocialRedirect?: any;
};

export const LoginComponent = (props: Props) => {
  let navigate = useNavigate();
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const { isCheckout, toggleForm, commonSocialRedirect } = props;

  const [cacheContent, setCacheContent] = useState<any>(null);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [credentialErrors, setCredentialErrors] = useState({
    emailError: "",
    passwordError: "",
  });
  const [isLoginClicked, setIsLoginClicked] = useState(true);
  const [isForgotClicked, SetIsForgotClicked] = useState(false);
  const {
    loginNumberSuccess,
    errorLoginNumber,
    resetPasswordData,
    errorResetPasswordData,
  } = useAppSelector((state: any) => state.checkout);

  const { loginContent } = useAppSelector(
    (state: any) => state.strapi.signinPage
  );
  const GAAndFBEventsHandler = (
    category: string,
    value?: string,
    custEventName?: string,
    extraParams?: any
  ) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.login,
      category: category,
      ...(value && { buttonValue: value }),
      ...(custEventName && { custEventName: custEventName }),
      ...(extraParams && { additionalParams: extraParams }),
    });
  };

  useEffect(() => {
    getCache("LogInComponent", strapiLoginContent, setCacheContent, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("LogInComponent", loginContent);
  }, [loginContent]);

  useEffect(() => {
    window.scroll(0, 0);
    localStorage.removeItem("currentuser");
    localStorage.removeItem("accessToken");
    if (localStorage.getItem("customerDetail")) {
      let user: any = localStorage.getItem("customerDetail");
      let parsedUser = JSON.parse(user);
      if (parsedUser && parsedUser.status === 2) {
        !isCheckout &&
          (EnvConfig.BROADBAND
            ? navigate(RoutingURLs.account)
            : navigate(RoutingURLs.postActivationHome));
      } else if (parsedUser && parsedUser.status === 0) {
        !isCheckout && navigate(RoutingURLs.home);
      }
    } else {
      localStorage.removeItem("currentuser");
      localStorage.removeItem("accessToken");
    }
    if (location && location.state && location.state.isForgotPassword) {
      setIsResetPassword(true);
    }

    return () => {
      Store.removeNotification("LoginErrorNotification");
      Store.removeNotification("LoginForgotNotification");
      Store.removeNotification("LoginForgotErrorNotification");
    };
  }, []);

  useNonInitialEffect(() => {
    if (loginNumberSuccess?.status === "SUCCESS" && loginNumberSuccess.data) {
      let emailId = loginNumberSuccess.data.emailId;
      initUserSignUp(emailId);
    }
  }, [loginNumberSuccess]);

  useNonInitialEffect(() => {
    if (errorLoginNumber?.status === "FAILURE") {
      setIsLoginClicked(true);
      showErrorMessage("incorrect-number");
    }
  }, [errorLoginNumber]);

  const {
    notification,
    loginErrors,
    validation_errors,
    btn_forgot,
    btn_login,
  } = cacheContent ? cacheContent : loginContent || {};

  useNonInitialEffect(() => {
    if (resetPasswordData && resetPasswordData.status === "SUCCESS") {
      NotificationCust({
        message: notification && notification.passwordReset,
        duration: 2000,
        id: "LoginForgotNotification",
      });
    }
    setIsResetPassword(false);
    setCredentials((values) => ({
      ...values,
      email: "",
    }));
    SetIsForgotClicked(false);
  }, [resetPasswordData]);

  useNonInitialEffect(() => {
    if (errorResetPasswordData && errorResetPasswordData.status === "FAILURE") {
      NotificationCust({
        message: notification.emailError,
        duration: 2000,
        type: "danger",
        id: "LoginForgotErrorNotification",
      });
    }
    SetIsForgotClicked(false);
  }, [errorResetPasswordData]);

  function onChangeHandler(e: any) {
    setCredentials((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "email") {
      setCredentialErrors((values) => ({
        ...values,
        emailError: "",
      }));
    } else if (e.target.name === "password") {
      setCredentialErrors((values) => ({
        ...values,
        passwordError: "",
      }));
    }
  }

  const showErrorMessage = (code: any) => {
    let errorMsg = "";
    switch (code) {
      case "auth/email-already-in-use":
        errorMsg = loginErrors["signup"][code];
        break;
      case "auth/invalid-email":
        errorMsg = loginErrors["signup"][code];
        break;
      case "auth/operation-not-allowed":
        errorMsg = loginErrors["signup"][code];
        break;
      case "auth/weak-password":
        errorMsg = loginErrors["signup"][code];
        break;
      // eslint-disable-next-line no-duplicate-case
      case "auth/invalid-email":
        errorMsg = loginErrors["login"][code];
        break;
      case "auth/user-disabled":
        errorMsg = loginErrors["login"][code];
        break;
      case "auth/user-not-found":
        errorMsg = loginErrors["login"][code];
        break;
      case "auth/wrong-password":
        errorMsg = loginErrors["login"][code];
        break;
      case "auth/account-exists-with-different-credential":
        errorMsg = loginErrors["social_login"][code];
        break;
      case "auth/auth-domain-config-required":
        errorMsg = loginErrors["social_login"][code];
        break;
      case "auth/cancelled-popup-request":
        errorMsg = "";
        break;
      case "auth/operation-not-supported-in-this-environment":
        errorMsg = loginErrors["social_login"][code];
        break;
      case "auth/popup-blocked":
        errorMsg = loginErrors["social_login"][code];
        break;
      case "auth/popup-closed-by-user":
        errorMsg = "";
        break;
      case "auth/unauthorized-domain":
        errorMsg = loginErrors["social_login"][code];
        break;
      case "auth/user-cancelled":
        errorMsg = "";
        break;
      case "incorrect-number":
        errorMsg = loginErrors["login"][code];
        break;
      default:
        errorMsg = loginErrors["default"];
        break;
    }
    if (errorMsg)
      NotificationCust({
        message: errorMsg,
        type: "danger",
        duration: 2000,
        id: "LoginErrorNotification",
      });
  };
  const redirectionHandler = () => {
    navigate(RoutingURLs.home);
  };
  const onGoogleLogin = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "login",
        login_method: "Google",
      },
    });
    EnvConfig.IS_GA_EVENTS_REQUIRED &&
      GAAndFBEventsHandler(gaCategory.login, "Google Login", "login", {
        login_method: "Google",
      });
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        localStorage.setItem("currentuser", JSON.stringify(result.user));
        let user1: any = localStorage.getItem("currentuser");
        user1 = JSON.parse(user1);
        let token =
          user1 &&
          user1.uid &&
          user1.stsTokenManager &&
          user1.stsTokenManager.accessToken;
        localStorage.setItem("accessToken", token);
        if (EnvConfig.SOURCE_CHECK_ENABLED) {
          if (user1 && user1.email) {
            checkUserSource(result?.user?.email);
          }
        } else {
          !isCheckout && redirectionHandler();
        }
        isCheckout &&
          setTimeout(() => {
            commonSocialRedirect(user1, "google");
          }, 2000);
      })
      .catch((error) => {
        showErrorMessage(error.code);
      });
  };

  const onAppleLogin = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "login",
        login_method: "Apple",
      },
    });
    EnvConfig.IS_GA_EVENTS_REQUIRED &&
      GAAndFBEventsHandler(gaCategory.login, "Apple Login", "login", {
        login_method: "Apple",
      });
    let provider = new firebase.auth.OAuthProvider("apple.com");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        localStorage.setItem("currentuser", JSON.stringify(result.user));
        let user1: any = localStorage.getItem("currentuser");
        user1 = JSON.parse(user1);
        let token =
          user1 &&
          user1.uid &&
          user1.stsTokenManager &&
          user1.stsTokenManager.accessToken;
        localStorage.setItem("accessToken", token);
        if (EnvConfig.SOURCE_CHECK_ENABLED) {
          if (user1 && user1.email) {
            checkUserSource(result?.user?.email);
          }
        } else {
          !isCheckout && redirectionHandler();
        }
        isCheckout &&
          setTimeout(() => {
            commonSocialRedirect(user1, "apple");
          }, 2000);
      })
      .catch((error) => {
        showErrorMessage(error.code);
      });
  };

  const onFacebookLogin = () => {
    EnvConfig.IS_GA_EVENTS_REQUIRED &&
      GAAndFBEventsHandler(gaCategory.login, "Facebook Login");
    let provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let user1: any = localStorage.getItem("currentuser");
        user1 = JSON.parse(user1);
        let token =
          user1 &&
          user1.uid &&
          user1.stsTokenManager &&
          user1.stsTokenManager.accessToken;
        localStorage.setItem("accessToken", token);
        if (EnvConfig.SOURCE_CHECK_ENABLED) {
          if (user1 && user1.email) {
            checkUserSource(result?.user?.email);
          }
        } else {
          !isCheckout && redirectionHandler();
        }
        isCheckout &&
          setTimeout(() => {
            commonSocialRedirect(user1, "facebook");
          }, 2000);
      })
      .catch((error) => {
        showErrorMessage(error.code);
      });
  };

  const checkUserSource = async (email?: any) => {
    await firebase
      .database()
      .ref("source/" + window.btoa(email))
      .once("value")
      .then((snapshot: any) => {
        let source = snapshot.val();
        if (source && source === EnvConfig.OPERATOR) {
          !isCheckout && navigate(RoutingURLs.home);
        } else {
          firebase
            .auth()
            .signOut()
            .then(() => {
              localStorage.removeItem("currentuser");
              localStorage.removeItem("accessToken");
              localStorage.clear();
            });
          window.open(`${EnvConfig.REDIRECT_URL}`, "_self");
        }
      })
      .catch((_error: any) => {
        !isCheckout && navigate(RoutingURLs.home);
      });
  };

  const initUserSignUp = (email: string) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, credentials.password)
      .then(async (user) => {
        const userinfo = {
          displayName: user.user?.displayName,
          email: user.user?.email,
          emailVerified: user.user?.emailVerified,
          isAnonymous: user.user?.isAnonymous,
        };
        dispatch(storeLoginData({ userData: userinfo }));
        localStorage.setItem("currentuser", JSON.stringify(user && user.user));
        let user1: any = localStorage.getItem("currentuser");
        user1 = JSON.parse(user1);
        let token =
          user1 &&
          user1.uid &&
          user1.stsTokenManager &&
          user1.stsTokenManager.accessToken;
        localStorage.setItem("accessToken", token);
        isCheckout &&
          commonSocialRedirect(user1, "Email", notification?.loginSuccess);
        if (EnvConfig.SOURCE_CHECK_ENABLED) {
          if (user1 && user1.email) {
            checkUserSource(user?.user?.email);
          }
        } else {
          !isCheckout && redirectionHandler();
        }
      })
      .catch((error) => {
        setIsLoginClicked(true);
        showErrorMessage(error.code);
      });
  };

  const handleLogIn = (e: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "login",
        login_method: "Email",
      },
    });
    EnvConfig.IS_GA_EVENTS_REQUIRED &&
      GAAndFBEventsHandler(gaCategory.login, btn_login, "login", {
        login_method: "Email",
      });
    if (emailPattern.test(credentials.email) && isCheckout) {
      updateToHubspot(e, credentials.email);

      dispatch(
        getInTouchApi({
          data: {
            emailId: credentials.email,
            hubspotMap: {
              email: credentials.email,
              is_test: globalVal.hubspot_is_test,
              is_qa: globalVal.hubspot_is_qa,
            },
          },
        })
      );
    }
    setIsLoginClicked(false);

    if (credentials.email !== "" && credentials.password !== "") {
      if (/^[0-9() -]+$/.test(credentials.email)) {
        let phone = credentials.email.replace(/\D/g, "");

        dispatch(loginWithNumber({ loginNumber: phone }));
      } else {
        if (validateEmail(credentials.email)) {
          initUserSignUp(credentials.email);
        } else {
          setIsLoginClicked(true);
          setCredentialErrors((values) => ({
            ...values,
            emailError: validation_errors.invalidEmail,
          }));
        }
      }
    } else {
      setIsLoginClicked(true);
      if (credentials.email === "") {
        setCredentialErrors((values) => ({
          ...values,
          emailError: validation_errors.emptyEmail,
        }));
      } else if (
        credentials.email !== "" &&
        !validateEmail(credentials.email)
      ) {
        setCredentialErrors((values) => ({
          ...values,
          emailError: validation_errors.invalidEmail,
        }));
      }
      if (credentials.password === "") {
        setCredentialErrors((values) => ({
          ...values,
          passwordError: validation_errors.emptyPassword,
        }));
      }
    }
  };

  const handleForgotPassword = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "forgot_password",
      },
    });
    EnvConfig.IS_GA_EVENTS_REQUIRED &&
      GAAndFBEventsHandler(
        gaCategory.forgotPassword,
        btn_forgot,
        "forgot_password"
      );
    if (credentials.email !== "" && validateEmail(credentials.email)) {
      SetIsForgotClicked(true);
      dispatch(
        resetPassword({
          email: credentials.email,
          actionCodeSettings: {
            url: EnvConfig.RP_URL,
            handleCodeInApp: EnvConfig.RP_HANDLE_CODE_IN_APP,
            iOS: {
              bundleId: EnvConfig.RP_IOS_BUNDLE_ID,
            },
            android: {
              packageName: EnvConfig.RP_ANDROID_PACKAGE_NAME,
              installApp: EnvConfig.RP_ANDROID_INSTALL_APP,
              minimumVersion: EnvConfig.RP_ANDROID_MIN_VERSION,
            },
            dynamicLinkDomain: EnvConfig.RP_DYNAMIC_LINK_DOMAIN,
          },
        })
      );
    } else {
      if (credentials.email === "") {
        setCredentialErrors((values) => ({
          ...values,
          emailError: "Email address cannot be empty",
        }));
      } else if (
        credentials.email !== "" &&
        !validateEmail(credentials.email)
      ) {
        setCredentialErrors((values) => ({
          ...values,
          emailError: "Enter a valid email address",
        }));
      }
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogIn(e);
    }
  };

  const showSignUp = () => {
    EnvConfig.IS_GA_EVENTS_REQUIRED &&
      GAAndFBEventsHandler(
        gaCategory.login,
        cacheContent ? cacheContent?.no_account2 : loginContent?.no_account2
      );
    if (isCheckout) return toggleForm();
    navigate(RoutingURLs.signUp);
  };

  const forgotPassHandler = () => {
    if (isCheckout)
      return navigate(RoutingURLs.login, { state: { isForgotPassword: true } });
    if (
      credentialErrors.emailError !== "" ||
      credentialErrors.passwordError !== ""
    )
      setCredentialErrors((values) => ({
        ...values,
        passwordError: "",
        emailError: "",
      }));
    setIsResetPassword(true);
  };
  const { email, password } = credentials;
  const { emailError, passwordError } = credentialErrors;
  return (
    (cacheContent || loginContent) && (
      <>
        {isResetPassword ? (
          <ForgotPassword
            email={email}
            emailError={emailError}
            onChangeHandler={(e: any) => onChangeHandler(e)}
            handleToggle={() => {
              if (credentialErrors.emailError !== "")
                setCredentialErrors((values) => ({
                  ...values,
                  emailError: "",
                }));
              setIsResetPassword(false);
              EnvConfig.IS_GA_EVENTS_REQUIRED &&
                GAAndFBEventsHandler(gaCategory.login, "forgot password");
            }}
            isForgotClicked={isForgotClicked}
            handleForgotPassword={handleForgotPassword}
            content={cacheContent ? cacheContent : loginContent}
          />
        ) : (
          <LoginTemplate
            email={email}
            password={password}
            emailError={emailError}
            passwordError={passwordError}
            onChangeHandler={(e: any) => onChangeHandler(e)}
            handleToggle={forgotPassHandler}
            isLoginClicked={isLoginClicked}
            handleLogIn={handleLogIn}
            onGoogleLogin={onGoogleLogin}
            onAppleLogin={onAppleLogin}
            onFacebookLogin={onFacebookLogin}
            handleEnter={handleEnter}
            content={cacheContent ? cacheContent : loginContent}
            showSignUp={showSignUp}
            isCheckout={isCheckout}
          />
        )}
      </>
    )
  );
};
